import {useRedirect, useResourceContext, useUpdate} from 'react-admin'

const useSoftDelete = ({id, resource: r}: UseSoftDeleteProps) => {
  let resource = useResourceContext()
  resource = r ?? resource
  const redirect = useRedirect()
  const [update] = useUpdate<any>(
    resource, id,
    {isDeleted: true},
    undefined,
    {
      onSuccess: () => redirect(`/${resource}`),
    }
  )
  return update
}

interface UseSoftDeleteProps {
  id: string
  resource?: string
}

export default useSoftDelete
