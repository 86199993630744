import {useCallback, useContext} from 'react'
import {useTranslate} from 'react-admin'

import logo from '../assets/imgs/logo.png'
import {BrowserNotificationsContext} from '../contexts/BrowserNotificationsContext'

// TODO: Write blog post about this generic hook
const useBrowserNotifications = () => {
  const {
    disabled,
    hasPermission,
    isUserPromptOpen,
    requestPermission,
    shouldShowUserPrompt,
  } = useContext(BrowserNotificationsContext)
  const translate = useTranslate()
  const addEventListeners = useCallback((notification, eventListeners) => {
    Object.keys(eventListeners).forEach(eventName => {
      notification[eventName] = eventListeners[eventName]
    })
  }, [])
  const getEventListeners = useCallback(options => Object.entries(options)
    .filter(([, value]) => typeof value === 'function')
    .reduce((callbacks, [callbackName]) => {
      callbacks[callbackName] = options[callbackName]
      return callbacks
    }, {}), [])
  const showNotification = useCallback(
    ({
      title = translate('hooks.useBrowserNotifications.notificationTitle'),
      ...options
    }) => {
      if (disabled) return
      if (!hasPermission && !shouldShowUserPrompt) return requestPermission()
      const notification = new Notification(title, {icon: logo, ...options})
      const eventListeners = getEventListeners(options)
      addEventListeners(notification, eventListeners)
    },
    [
      addEventListeners,
      disabled,
      getEventListeners,
      hasPermission,
      requestPermission,
      shouldShowUserPrompt,
      translate,
    ]
  )
  return {isUserPromptOpen, showNotification}
}

export default useBrowserNotifications
