import {useMemo} from 'react'

import useSessionMerchantUser from './useSessionMerchantUser'

const useFeatureLock = (emailAddressRegexes: RegExp[]) => {
  const {merchantUser} = useSessionMerchantUser()
  return useMemo(
    () => (
      !!merchantUser && emailAddressRegexes.some(r => r.test(merchantUser.emailAddress))
    ),
    [emailAddressRegexes, merchantUser]
  )
}

export default useFeatureLock
