import {App} from '@capacitor/app'
import {AppUpdate, AppUpdateAvailability} from '@capawesome/capacitor-app-update'
import {useEffect, useState} from 'react'

import {isNativeMobileApp} from '../utils/platform'

const useGetAppUpdateInfo = () => {
  const [isAppUpdateAvailable, setIsAppUpdateAvailable] = useState(false)
  const [isImmediateUpdateAllowed, setIsImmediateUpdateAllowed] = useState(false)
  const checkForAppUpdateAvailability = async () => {
    const {
      immediateUpdateAllowed, updateAvailability,
    } = await AppUpdate.getAppUpdateInfo()
    setIsAppUpdateAvailable(
      updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE
    )
    setIsImmediateUpdateAllowed(immediateUpdateAllowed ?? false)
  }
  useEffect(() => {
    if (!isNativeMobileApp) return
    checkForAppUpdateAvailability()
    const appStateListener = App.addListener('appStateChange', ({isActive}) => {
      if (isActive) {
        checkForAppUpdateAvailability()
      }
    })
    return () => {
      appStateListener.remove()
    }
  })
  return {isAppUpdateAvailable, isImmediateUpdateAllowed}
}

export default useGetAppUpdateInfo
