import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Title, useTranslate} from 'react-admin'

import SandboxSettings from '../components/settings/SandboxSettings'
import useIsDesktop from '../hooks/useIsDesktop'
import useSessionMerchantUser from '../hooks/useSessionMerchantUser'

const SandboxPage = () => {
  const translate = useTranslate()
  const {merchantUser} = useSessionMerchantUser()
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  return (
    <div className={styles.root}>
      {isDesktop ? (
        <Typography variant="h2">
          {translate('pages.sandbox.title', {firstName: merchantUser?.firstName})}
        </Typography>
      ) : (
        <Title
          title={translate('pages.sandbox.title', {firstName: merchantUser?.firstName})}
        />
      )}
      <Typography variant="subtitle1">{translate('pages.sandbox.subtitle')}</Typography>
      <SandboxSettings />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiCard-root': {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      boxSizing: 'border-box',
      width: '66%',
    },
    '& > .MuiTypography-root:nth-child(2)': {
      color: theme.palette.secondary.main,
      marginTop: theme.remSpacing(1),
    },
    margin: theme.remSpacing(4),
  },
}))

export default SandboxPage
