import {Box, Typography, TypographyVariant} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC} from 'react'

import {initials} from '../../utils/strings'

const ProfileInitials: FC<ProfileInitialsProps> = ({
  backgroundColor = '#BADCF4', className, name, variant = 'h3',
}) => (
  <Box
    alignItems="center"
    className={`${useStyles().root} ${className ?? ''}`}
    display="flex"
    justifyContent="center"
    style={{backgroundColor}}
  >
    <Typography variant={variant}>{initials(name)}</Typography>
  </Box>
)

const useStyles = makeStyles(theme => ({
  root: {
    aspectRatio: '1/1',
    borderRadius: '50%',
    // TODO: add the color to the palette
    color: '#275C83',
    height: theme.remSpacing(14),
    width: theme.remSpacing(14),
  },
}))

interface ProfileInitialsProps {
  backgroundColor?: string
  className?: string
  name: string
  variant?: TypographyVariant
}

export default ProfileInitials
