import {useMutation} from '@apollo/client'
import {Fab} from '@material-ui/core'
import gql from 'graphql-tag'
import {useState} from 'react'
import {useRedirect, useTranslate} from 'react-admin'

import StartChatDialog from '../../components/chat/StartChatDialog'
import {EditFilledIcon} from '../../components/icons'
import {MutationRoot, MutationRootStartChatArgs} from '../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../utils/tracking'

const StartInternalChatButton = () => {
  const redirect = useRedirect()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [startChat, {loading: isLoadingChat}] = useMutation<
    MutationRoot['startChat'], MutationRootStartChatArgs
  >(
    START_CHAT_MUTATION,
    {
      onCompleted: ({startChat: {chatId} = {}}) => {
        setIsDialogOpen(false)
        redirect(`/team-chat/${chatId}`)
      },
    }
  )
  return (
    <>
      <Fab
        color="primary"
        onClick={() => {
          setIsDialogOpen(true)
          trackEvent('START_INTERNAL_CHAT', EventCategory.CHAT)
        }}
      >
        <EditFilledIcon />
      </Fab>
      <StartChatDialog
        actionButtonText={useTranslate()('dialogs.startChat.internal.actionButtonText')}
        loading={isLoadingChat}
        onClose={() => setIsDialogOpen(false)}
        onStartChat={({name, userIds}) => startChat({variables: {chat: {name, userIds}}})}
        open={isDialogOpen}
        title={useTranslate()('dialogs.startChat.internal.title')}
      />
    </>
  )
}

const START_CHAT_MUTATION = gql`mutation($chat: Chat!){startChat(chat: $chat){chatId}}`

export default StartInternalChatButton
