import {MutationResultWrapper, useMutation, useQuery} from '@apollo/client'
import {Box, Chip, Popover} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {FC, useCallback, useMemo, useState} from 'react'
import {useTranslate} from 'react-admin'

import {ChevronDownIcon, UserIcon} from '../../components/icons'
import {useUserDisplayName} from '../../hooks/useUserDisplayName'
import {AppTheme} from "../../theme"
import hexToRgb from '../../theme/hexToRgb'
import {Chats, MerchantUsers, MutationRoot, QueryRoot} from '../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../utils/tracking'
import {userInitials} from '../../utils/users'

const ChatAssignment: FC<ChatAssignmentProps> = ({chat, className}) => {
  const translate = useTranslate()
  const {
    data: {channel_merchant_users: channelMerchantUsers} = {},
  } = useQuery<QueryRoot['channel_merchant_users']>(
    CHANNEL_MERCHANT_USERS_QUERY,
    {skip: !chat?.channelId, variables: {channelId: chat?.channelId}}
  )
  const [mutate] = useMutation<MutationRoot['update_chats_by_pk']>(
    ASSIGN_CHAT_MUTATION, {variables: {chatId: chat?.id}}
  )
  const [anchorElement, setAnchorElement] = useState(null)
  const isChatAssigned = useMemo(() => !!chat?.assignedMerchantUser, [chat])
  const styles = useStyles({chat, isChatAssigned})
  const openPopover = e => setAnchorElement(e.currentTarget)
  const closePopover = () => setAnchorElement(null)
  const assignChat = useCallback(
    (merchantUser?: MerchantUsers) => mutate({
      optimisticResponse: {
        update_chats_by_pk: {
          __typename: 'chats',
          assignedMerchantUser: merchantUser ?? null,
          id: chat?.id ?? '',
        },
      } as MutationResultWrapper<MutationRoot['update_chats_by_pk']>,
      variables: {merchantUserId: merchantUser?.id},
    }).then(closePopover),
    [mutate, chat]
  )
  const userDisplayName = useUserDisplayName()
  return (
    <div className={`${styles.root} ${className ?? ''}`}>
      <Chip
        className={styles.assignedMerchantUserChip}
        clickable
        icon={
          <Box className={styles.assignedMerchantUserChipIconBox}>
            {isChatAssigned ?
              <span className={styles.assignedMerchantUserInitials}>
                {userInitials(chat.assignedMerchantUser as MerchantUsers)}
              </span> :
              <UserIcon color="secondary" size="small"/>
            }
          </Box>
        }
        label={
          <>
            <span
              className={`
                ${styles.merchantUserDisplayName}
                ${!isChatAssigned ? styles.unassignedMerchantUserDisplayName : ''}
              `}
            >
              {isChatAssigned ?
                userDisplayName({merchantUser: chat?.assignedMerchantUser}) :
                translate('chat.chatAssignment.unassigned')
              }
            </span>
            <ChevronDownIcon
              className={styles.assignedMerchantUserChipChevronDownIcon}
              color={isChatAssigned ? chat?.assignedMerchantUser?.userColor : 'secondary'}
              size="inherit"
            />
          </>
        }
        onClick={openPopover}
        size="medium"
        variant={isChatAssigned ? 'default' : 'outlined'}
      />
      <Popover
        anchorEl={anchorElement}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        className={styles.popover}
        onClose={closePopover}
        open={!!anchorElement}
        transformOrigin={{horizontal: 'left', vertical: 'top'}}
      >
        <Chip
          className={styles.unassignedMerchantUserChip}
          clickable
          icon={
            <Box className={styles.unassignedMerchantUserChipIconBox}>
              <UserIcon color="secondary" size="small"/>
            </Box>
          }
          label={translate('chat.chatAssignment.unassigned')}
          onClick={() => {
            assignChat()
            trackEvent('UNASSIGN_CHAT', EventCategory.CHAT)
          }}
          size="small"
          variant="outlined"
        />
        {channelMerchantUsers?.map(({merchantUser}) => (
          <Chip
            clickable
            icon={
              <Box
                alignItems="center"
                borderRadius="50%"
                display="flex"
                justifyContent="center"
                padding="5px"
                style={{background: `rgba(${hexToRgb(merchantUser.userColor)}, .2)`}}
              >
                <span style={{color: merchantUser.userColor}}>
                  {userInitials(merchantUser)}
                </span>
              </Box>
            }
            key={merchantUser.id}
            label={userDisplayName({merchantUser})}
            onClick={() => {
              assignChat(merchantUser)
              trackEvent('ASSIGN_CHAT', EventCategory.CHAT)
            }}
            size="medium"
            style={{
              background: `rgba(${hexToRgb(merchantUser.userColor)}, .1)`,
              border: `1px solid ${merchantUser.userColor}`,
              color: merchantUser.userColor,
            }}
          />
        ))}
      </Popover>
    </div>
  )
}

const useStyles = makeStyles<AppTheme, MakeStylesOptions>(theme => ({
  assignedMerchantUserChip: {
    [theme.breakpoints.down('sm')]: {
      width: theme.typography.pxToRem(100),
    },
    background: ({chat, isChatAssigned}) => isChatAssigned ?
      `rgba(${theme.hexToRgb(chat.assignedMerchantUser?.userColor as string)}, .1)` :
      '#fff',
    borderColor: theme.palette.disabled.main,
    color: ({chat}) => chat.assignedMerchantUser?.userColor,
    minHeight: theme.remSpacing(4),
    width: '100%',
  },
  assignedMerchantUserChipChevronDownIcon: {
    fontSize: theme.remSpacing(2),
  },
  assignedMerchantUserChipIconBox: ({chat, isChatAssigned}) => ({
    alignItems: 'center',
    background: isChatAssigned ?
      `rgba(${theme.hexToRgb(chat.assignedMerchantUser?.userColor as string)}, .2)` :
      undefined,
    border: isChatAssigned ? undefined : `2px solid ${theme.palette.disabled.main}`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    padding: isChatAssigned ? theme.remSpacing(.5) : '1px',
  }),
  assignedMerchantUserInitials: {
    color: ({chat}) => chat.assignedMerchantUser?.userColor,
  },
  merchantUserDisplayName: {
    display: 'inline-block',
    overflow: 'hidden',
    paddingLeft: '0.4em',
    paddingRight: '0.4em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    ...theme.typography.caption,
  },
  popover: {
    '& .MuiChip-label': {
      width: '100%',
      ...theme.typography.caption,
    },
    '& .MuiChip-root': {
      [theme.breakpoints.down('sm')]: {
        width: theme.typography.pxToRem(100),
      },
      borderColor: theme.palette.disabled.main,
      minHeight: theme.remSpacing(4),
      width: '100%',
    },
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '.75rem',
      maxHeight: 50 * 4.5,
      width: '20ch',
    },
  },
  root: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.typography.pxToRem(8),
      paddingTop: theme.typography.pxToRem(8),
    },
    '& .MuiChip-label': {
      display: 'flex',
      width: '100%',
      ...theme.typography.caption,
    },
    display: 'flex',
    gap: '.5rem',
    justifyContent: 'flex-end',
    paddingBottom: '.75rem',
    paddingTop: '.65rem',
  },
  unassignedMerchantUserChip: {
    background: theme.palette.background.main,
    border: `1px solid ${theme.palette.background.main}`,
    color: theme.palette.text.secondary,
  },
  unassignedMerchantUserChipIconBox: {
    alignItems: 'center',
    background: 'white',
    border: `2px solid ${theme.palette.disabled.main}`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1px',
  },
  unassignedMerchantUserDisplayName: {
    color: theme.palette.text.secondary,
  },
}))

const MERCHANT_USER_FIELDS = 'firstName id lastName userColor'
const ASSIGN_CHAT_MUTATION = gql`
  mutation($chatId: uuid!, $merchantUserId: uuid) {
    update_chats_by_pk(
      pk_columns: {id: $chatId}, _set: {assignedMerchantUserId: $merchantUserId}
    ) {assignedMerchantUser{${MERCHANT_USER_FIELDS}} id}
  }
`
const CHANNEL_MERCHANT_USERS_QUERY = gql`
  query($channelId: uuid) {
    channel_merchant_users(where: {
      channelId: {_eq: $channelId}
      merchantUser: {user: {isDeleted: {_eq: false}}}
    }){
      merchantUser{${MERCHANT_USER_FIELDS}}
    }
  }
`

interface ChatAssignmentProps {
  chat: Chats
  className?: string
}

interface MakeStylesOptions {
  chat: Chats
  isChatAssigned: boolean
}

export default ChatAssignment
