import {Box, Theme, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, useMemo} from 'react'
import {useTranslate} from 'react-admin'
import {Link} from 'react-router-dom'

import {useCompileChatMessageText} from '../../../hooks/useCompileChatMessageText'
import {useDelayRemoveChatMessageIdSearchParam} from '../../../hooks/useSearchParams'
import {useUserDisplayName} from '../../../hooks/useUserDisplayName'
import hexToRgb from '../../../theme/hexToRgb'
import {ChatMessages} from '../../../types/graphqlSchema'
import LineClamp from '../../LineClamp'
import ChatMessageFileAttachment from './ChatMessageFileAttachment'

const RepliedChatMessageBanner: FC<RepliedChatMessageBannerProps> = ({
  repliedChatMessage,
}) => {
  const {compileChatMessageText} = useCompileChatMessageText()
  const compiledText = useMemo(
    () => compileChatMessageText(repliedChatMessage),
    [compileChatMessageText, repliedChatMessage],
  )
  const userDisplayName = useUserDisplayName()
  const isRepliedMessageAuthoredByCustomerUser =
    !!repliedChatMessage.authorUser.customerUser
  const styles = useStyles({isRepliedMessageAuthoredByCustomerUser})
  const translate = useTranslate()
  const delayRemoveChatMessageIdSearchParam = useDelayRemoveChatMessageIdSearchParam()
  return (
    <Link
      className={styles.root}
      onClick={() => {delayRemoveChatMessageIdSearchParam()}}
      to={`?chatMessageId=${repliedChatMessage.id}`}
    >
      <Box maxWidth="80%">
        <Typography className={styles.authorUserName}>
          {isRepliedMessageAuthoredByCustomerUser ?
            userDisplayName(repliedChatMessage.authorUser) :
            translate(
              'chat.chatMessageBubble.repliedChatMessageBanner.' +
              'repliedMessageAuthoredByTheMerchantUserReading'
            )
          }
        </Typography>
        <Typography>
          <LineClamp>
            {compiledText ?? (
              !!repliedChatMessage.chatMessageFileAttachments.length &&
                repliedChatMessage.chatMessageFileAttachments[0].filename
            )}
          </LineClamp>
        </Typography>
      </Box>
      {!!repliedChatMessage.chatMessageFileAttachments.length && (
        <Box className={styles.fileAttachment}>
          <ChatMessageFileAttachment
            chatId={repliedChatMessage.chatId}
            isTranslationVisible={false}
            shouldDisplayFileName={false}
            shouldShowImagePreview={false}
            value={repliedChatMessage.chatMessageFileAttachments[0]}
          />
        </Box>
      )}
    </Link>
  )
}

const useStyles = makeStyles<Theme, MakeStyleProps>(theme => ({
  authorUserName: ({isRepliedMessageAuthoredByCustomerUser}) => ({
    color: isRepliedMessageAuthoredByCustomerUser ? theme.palette.text.primary :
      theme.palette.info.main,
    paddingBottom: theme.remSpacing(0.5),
  }),
  fileAttachment: {
    '& img': {
      maxHeight: theme.remSpacing(10),
    },
  },
  root: {
    alignItems: 'center',
    backgroundColor: `rgba(${hexToRgb(theme.palette.disabled.main)},0.3)`,
    borderLeft: ({isRepliedMessageAuthoredByCustomerUser}) => (
      isRepliedMessageAuthoredByCustomerUser ?
        `6px solid ${theme.palette.secondary.dark}` :
        `6px solid ${theme.palette.info.main}`
    ),
    borderRadius: '6px',
    color: theme.palette.text.secondary,
    columnGap: '0.5rem',
    cursor: 'pointer',
    display: 'flex',
    height: "min-content",
    justifyContent: 'space-between',
    padding: theme.remSpacing(1),
    rowGap: '0.5rem',
    textDecoration: 'none !important',
  },
}))

interface RepliedChatMessageBannerProps {
  repliedChatMessage: ChatMessages
}

interface MakeStyleProps {
  isRepliedMessageAuthoredByCustomerUser?: boolean
}

export default RepliedChatMessageBanner
