import {Box, Link} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {NotificationsNone as NotificationIcon} from '@material-ui/icons'
import {Alert} from '@material-ui/lab'
import {createContext, useCallback, useEffect, useState} from 'react'
import {useTranslate} from 'react-admin'

const BrowserNotificationsContext = createContext<BrowserNotificationsContextProps>({
  disabled: false,
  hasPermission: false,
  isUserPromptOpen: false,
  notificationsPermission: 'default',
  requestPermission: () => {},
  shouldShowUserPrompt: false,
})

const BrowserNotificationsProvider = (
  {children, disabled = false, shouldShowUserPrompt}
) => {
  const [notificationsPermission, setNotificationsPermission] =
    useState(hasBrowserSupport ? window.Notification.permission : undefined)
  const [isUserPromptDismissed, setUserPromptDismissed] = useState(false)
  const isUserPromptOpen = (
    !disabled &&
    hasBrowserSupport &&
    shouldShowUserPrompt &&
    (notificationsPermission === 'default') &&
    !isUserPromptDismissed
  )
  const requestPermission = useCallback(async () => {
    if (notificationsPermission === 'default') {
      setNotificationsPermission(await window.Notification.requestPermission())
    }
  }, [notificationsPermission, setNotificationsPermission])
  useEffect(
    () => {
      if (!disabled && hasBrowserSupport && !shouldShowUserPrompt) {
        requestPermission()
      }
    },
    [disabled, requestPermission, shouldShowUserPrompt],
  )
  useEffect(() => {
    document.body.style.setProperty(
      // TODO: Instead of hard coding the prompt height, have it measured it instead
      //       (We have a useMeasure() hook in Flinkit 1.0 which we could recycle)
      '--notification-prompt-height', isUserPromptOpen ? '50px' : '0px'
    )
  }, [isUserPromptOpen])
  return (
    <BrowserNotificationsContext.Provider
      value={{
        disabled: disabled || !hasBrowserSupport,
        hasPermission: notificationsPermission === 'granted',
        isUserPromptOpen,
        notificationsPermission,
        requestPermission,
        shouldShowUserPrompt,
      }}
    >
      {!disabled && isUserPromptOpen &&
        <UserPrompt
          onDismiss={() => setUserPromptDismissed(true)}
          onRequestPermission={requestPermission}
        />
      }
      {children}
    </BrowserNotificationsContext.Provider>
  )
}

const hasBrowserSupport = (() => {
  // https://developer.chrome.com/blog/notifying-you-of-changes-to-notifications/#android-notifications
  if (!window.Notification || !Notification.requestPermission) return false
  if (Notification.permission === 'granted') return true
  try {
    new Notification('')
  }
  catch (e) {
    if (e instanceof TypeError) return false
  }
  return true
})()

const UserPrompt = ({onDismiss, onRequestPermission}) => (
  <Alert
    className={useStyles().root}
    component={Box}
    icon={<NotificationIcon />}
    onClose={onDismiss}
    severity="info"
    variant="filled"
  >
    {useTranslate()('browserNotificationsProvider.enableBrowserNotifications')} - {' '}
    <Link onClick={onRequestPermission}>
      {useTranslate()(
        'browserNotificationsProvider.enableBrowserNotificationsButtonText'
      )}
    </Link>
  </Alert>
)

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiAlert-action': {
      margin: 0,
      position: 'absolute',
      right: theme.remSpacing(1),
    },
    '& .MuiLink-root': {
      color: 'inherit !important',
      cursor: 'pointer',
    },
    alignItems: 'center',
    border: 'none',
    borderRadius: 0,
    fontSize: theme.typography.body1.fontSize,
    justifyContent: 'center',
  },
}))

interface BrowserNotificationsContextProps {
  disabled: boolean,
  hasPermission: boolean,
  isUserPromptOpen: boolean,
  notificationsPermission?: NotificationPermission,
  requestPermission: () => void,
  shouldShowUserPrompt: boolean,
}

export {BrowserNotificationsContext, BrowserNotificationsProvider}
