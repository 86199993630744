import {TextField} from '@material-ui/core'
import {FC, InputHTMLAttributes, MutableRefObject} from 'react'
import {InputProps, useTranslate} from 'react-admin'
import {useField} from 'react-final-form'

import {isNativeIosApp} from '../../utils/platform'

const TimeInput: FC<TimeInputProps> = ({
  fullWidth = false,
  InputProps = {},
  label, // TODO: Use i18nProvider w/ resource/source name by default
  className,
  source,
  validate,
  variant = 'filled',
  inputRef,
  disabled = false,
}) => {
  const translate = useTranslate()
  const {
    input: {onChange, value},
    meta: {dirty, error},
  } = useField(source, {validate: (value, allValues, meta) => {
    if (!value && InputProps.required) return translate('validations.required')
    if (typeof validate === 'function') return validate(value, allValues, meta)
    if (Array.isArray(validate)) validate.forEach(f => f(value, allValues, meta))
  }})
  // Remove seconds from time string as we don't want them to be displayed
  const secondlessValue = value.replace(/^([0-9]{1,2}):([0-9]{1,2}):.*$/, '$1:$2')
  return (
    <TextField
      InputLabelProps={{shrink: !isNativeIosApp}}
      className={className}
      disabled={disabled}
      error={dirty && error}
      fullWidth={fullWidth}
      helperText={dirty && error}
      inputProps={{step: 300, ...InputProps}}
      inputRef={inputRef}
      label={label ?? source}
      name={source}
      onChange={onChange}
      type="time"
      value={secondlessValue}
      variant={variant}
    />
  )
}

export type ValidationFunction = (
  value: string, allValues?: Record<string, any>, meta?: any
) => any | Promise<any>

interface TimeInputProps {
  InputProps: Partial<InputProps>,
  className?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  inputRef?: MutableRefObject<InputHTMLAttributes<HTMLInputElement>>,
  label?: string,
  source: string,
  validate?: ValidationFunction | ValidationFunction[]
  variant?: 'filled' | 'outlined' | 'standard'
}

export default TimeInput
