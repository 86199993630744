import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC} from 'react'
import {useTranslate} from 'react-admin'

import {useUserDisplayName} from '../../../hooks/useUserDisplayName'
import {ChatMessages} from '../../../types/graphqlSchema'
import {userMetadata} from '../../../utils/users'

const ForwardedChatMessageBanner: FC<ForwardedChatMessageBannerProps> = ({
  forwardedChatMessage: {authorUser},
}) => (
  <div className={useStyles().root}>
    <Typography variant="caption">
      {useTranslate()('chat.chatMessageBubble.forwardedBy')}
    </Typography>
    <Typography variant="subtitle2">{useUserDisplayName()(authorUser)}</Typography>
    <Typography variant="caption">
      {authorUser.customerUser && userMetadata(authorUser)}
    </Typography>
  </div>
)

const useStyles = makeStyles(theme => ({
  root: {
    color: '#73718B', // TODO: Use value from theme's color palette
    marginBottom: theme.remSpacing(1),
    marginTop: theme.remSpacing(0.5),
  },
}))

interface ForwardedChatMessageBannerProps {
  forwardedChatMessage: ChatMessages
}

export default ForwardedChatMessageBanner
