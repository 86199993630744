import {ApolloCache, useMutation} from '@apollo/client'
import {ReferenceManyToManyField} from '@flinkit/ra-relationships'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  ClickAwayListener,
  InputAdornment,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import _ from 'lodash'
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  FunctionField,
  List,
  PaginationPayload,
  ReferenceField,
  ReferenceManyField,
  SearchInput,
  Show,
  SingleFieldList,
  TextField,
  useGetIdentity,
  useGetManyReference,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin'
import {useLocation} from 'react-router-dom'

import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import {useUserDisplayName} from '../../hooks/useUserDisplayName'
import {AppTheme} from '../../theme'
import {
  ChannelMerchantUsers,
  Channels,
  MerchantUsers,
  MutationRoot,
} from '../../types/graphqlSchema'
import AccordionWrapper from '../AccordionWrapper'
import {ChevronDownIcon, CloseFilledIcon, SearchIcon} from '../icons'
import {CHANNELS_QUERY} from '../SidebarMenu'

const ChannelsAndMerchantUsersManagementShow = () => {
  const {identity: {merchantId} = {}} = useGetIdentity()
  return (
    <Show
      actions={false}
      basePath="/"
      className={useStyles({}).channelsAndMerchantUsersManagementShowRoot}
      id={merchantId}
      resource="merchants"
    >
      <ReferenceManyField
        perPage={false as any}
        reference="channels"
        sort={{field: 'name', order: 'asc'}}
        target="merchantId"
      >
        <ChannelAccordionsList />
      </ReferenceManyField>
    </Show>
  )
}

const ChannelAccordionsList = ({...props}) => {
  const {data: channelIdToChannel} = props
  const translate = useTranslate()
  const styles = useStyles(props)
  const channels = useMemo<Channels[]>(
    () => Object.values(channelIdToChannel) as Channels[], [channelIdToChannel]
  )
  return (
    <AccordionWrapper>{
      channels.map(channel => (
        <Accordion
          TransitionProps={{unmountOnExit: true}}
          elevation={0}
          key={channel.id}
          name={channel.id}
        >
          <AccordionSummary
            className={styles.channelAccordionSummary}
            expandIcon={<ChevronDownIcon />}
          >
            <ReferenceField
              {...props}
              link={false}
              record={channel}
              reference="whatsapp_accounts"
              source="whatsappAccountId"
            >
              <div className={styles.channelName}>
                <TextField source="phoneNumber" variant="subtitle2" />
                <Typography component="span" variant="subtitle1">
                  ({channel.name})
                </Typography>
              </div>
            </ReferenceField>
          </AccordionSummary>
          <AccordionDetails>
            <Card key={channel.id}>
              <CardContent>
                <Typography>
                  {translate('dialogs.profile.companySettings.channelsAndUsers.admins')}
                </Typography>
                <ChannelMerchantUsersList
                  {...props}
                  channel={channel}
                  isChannelMerchantUsersAdminList
                />
                <Typography>
                  {translate('dialogs.profile.companySettings.channelsAndUsers.users')}
                </Typography>
                <ChannelMerchantUsersList {...props} channel={channel} />
              </CardContent>
            </Card>
          </AccordionDetails>
        </Accordion>
      ))
    }</AccordionWrapper>
  )
}

const ChannelMerchantUsersList: FC<ChannelMerchantUsersListProps> = ({
  channel, isChannelMerchantUsersAdminList = false, ...props
}) => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const updateChannelsQueryCache = useUpdateChannelsQueryCache()
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const styles = useStyles({isPopperOpen})
  const anchorElementRef = useRef<HTMLButtonElement>(null)
  const [deleteChannelMerchantUsers] = useMutation<
    MutationRoot['delete_channel_merchant_users']
  >(
    DELETE_ONE_CHANNEL_MERCHANT_USER,
    {
      onCompleted: () => refresh(),
      update: (
        cache,
        // @ts-ignore: delete_channel_merchant_users doesn't exist
        {data: {delete_channel_merchant_users: {returning: channelMerchantUsers}}}
      ) => updateChannelsQueryCache(
        cache, {
          channelId: channel.id,
          channelMerchantUserMutationType: 'DELETE',
          channelMerchantUsers,
        }
      ),
    }
  )
  const [removeChannelMerchantUserAdminPermission] = useMutation(
    REMOVE_CHANNEL_MERCHANT_USER_ADMIN_PERMISSION, {onCompleted: refresh}
  )
  const userDisplayName = useUserDisplayName()
  return (
    <Box>
      <>
        <Button
          className={styles.channelMerchantUsersListPopperButton}
          endIcon={<ChevronDownIcon color="info"/>}
          onClick={() => setIsPopperOpen(true)}
          ref={anchorElementRef}
          size="small"
          variant="outlined"
        >
          {isChannelMerchantUsersAdminList ?
            translate('dialogs.profile.companySettings.channelsAndUsers.addAdmins') :
            translate('dialogs.profile.companySettings.channelsAndUsers.addUsers')
          }
        </Button>
        <Popper
          anchorEl={anchorElementRef.current}
          className={styles.popper}
          open={isPopperOpen}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
            <Paper>
              <ReferenceManyField
                {...props}
                perPage={1000}
                record={channel}
                reference="merchant_users"
                sort={{field: 'firstName', order: 'ASC'}}
                target="merchantId"
              >
                <List
                  {...props}
                  actions={false}
                  bulkActionButtons={false}
                  filters={[
                    <SearchInput
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      alwaysOn
                      key="search-input"
                      source="firstName,lastName"
                      variant="outlined"
                    />,
                  ]}
                  pagination={false}
                >
                  <MerchantUsersList
                    {...props}
                    channel={channel}
                    isChannelMerchantUsersAdminList={isChannelMerchantUsersAdminList}
                    onApply={() => setIsPopperOpen(false)}
                    onClearAll={() => setIsPopperOpen(false)}
                  />
                </List>
              </ReferenceManyField>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
      <ReferenceManyToManyField
        {...props}
        filter={{isAdmin: isChannelMerchantUsersAdminList || undefined}}
        record={channel}
        reference="merchant_users"
        sort={{field: "merchantUserId", order: 'DESC'}}
        through="channel_merchant_users"
        using="channelId,merchantUserId"
      >
        <SingleFieldList className={styles.singleChipList} linkType={false}>
          <FunctionField<MerchantUsers>
            label="Name"
            render={record => (
              <Chip
                deleteIcon={<CloseFilledIcon color="info"/>}
                disabled={isChannelMerchantUsersAdminList && record?.isOwner}
                label={<Typography>{userDisplayName({merchantUser: record})}</Typography>}
                onDelete={() => {
                  isChannelMerchantUsersAdminList ?
                    removeChannelMerchantUserAdminPermission({
                      variables: {channelId: channel.id, merchantUserId: record?.id},
                    }) :
                    deleteChannelMerchantUsers({
                      variables: {channelId: channel.id, merchantUserId: record?.id},
                    })
                }}
                variant="outlined"
              />
            )}
            variant="body1"
          />
        </SingleFieldList>
      </ReferenceManyToManyField>
    </Box>
  )
}

const MerchantUsersList: FC<MerchantUsersListProps> = ({
  channel,
  isChannelMerchantUsersAdminList,
  onApply,
  onClearAll,
  ...props
}) => {
  const userDisplayName = useUserDisplayName()
  const {data: merchantUserIdToMerchantUsers} = props
  const styles = useStyles({})
  const refresh = useRefresh()
  const updateChannelsQueryCache = useUpdateChannelsQueryCache()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const {data: channelMerchantUserIdToChannelMerchantUsers} = useGetManyReference(
    'channel_merchant_users',
    'channelId',
    channel.id,
    {} as PaginationPayload,
    {field: 'merchantUserId', order: 'asc'},
    {},
    'channels'
  )
  const merchantUsers = useMemo<MerchantUsers[]>(
    () => Object.values(merchantUserIdToMerchantUsers) as MerchantUsers[],
    [merchantUserIdToMerchantUsers]
  )
  const channelMerchantUsers = useMemo<ChannelMerchantUsers[]>(
    () => Object.values(channelMerchantUserIdToChannelMerchantUsers),
    [channelMerchantUserIdToChannelMerchantUsers],
  )
  const isChannelMerchantUserAdmin = useCallback(
    (merchantUserId: string) =>
      !!channelMerchantUsers.find(c => c.merchantUserId === merchantUserId)?.isAdmin,
    [channelMerchantUsers],
  )
  const initiallySelectedMerchantUserIds = useMemo(() =>
    merchantUsers.filter(
      mu => isChannelMerchantUsersAdminList ?
        isChannelMerchantUserAdmin(mu.id) :
        channelMerchantUsers.some(c => c.merchantUserId === mu.id)
    ).map(mu => mu.id), [
    channelMerchantUsers,
    isChannelMerchantUserAdmin,
    isChannelMerchantUsersAdminList,
    merchantUsers,
  ])
  const unselectedMerchantUserIds = useRef<string[]>([])
  useEffect(() => {
    setSelectedIds(selectedIds => _.uniq([
      ...selectedIds,
      ...initiallySelectedMerchantUserIds.filter(
        id => !unselectedMerchantUserIds.current.includes(id)
      ),
    ]))
    return () => setSelectedIds([])
  }, [initiallySelectedMerchantUserIds, setSelectedIds])
  const [upsertChannelMerchantUsers] = useMutation<
    MutationRoot['insert_channel_merchant_users']
  >(
    UPSERT_CHANNEL_MERCHANT_USERS,
    {
      update: (
        cache,
        // @ts-ignore: insert_channel_merchant_users don't exist
        {data: {insert_channel_merchant_users: {returning: channelMerchantUsers}}}
      ) => updateChannelsQueryCache(
        cache, {
          channelId: channel.id,
          channelMerchantUserMutationType: 'UPSERT',
          channelMerchantUsers,
        }
      ),
      variables: {
        objects: selectedIds.map(merchantUserId => ({
          channelId: channel.id,
          isAdmin: isChannelMerchantUsersAdminList ||
            isChannelMerchantUserAdmin(merchantUserId),
          merchantUserId,
        })),
      },
    }
  )
  const [removeUnselectedChannelMerchantUsersAdminPermission] = useMutation(
    REMOVE_UNSELECTED_CHANNEL_MERCHANT_USERS_ADMIN_PERMISSION,
    {
      variables: {
        channelId: channel.id,
        unselectedMerchantUserIds: unselectedMerchantUserIds.current,
      },
    }
  )
  const [removeAllChannelMerchantUsersAdminPermission] = useMutation(
    REMOVE_ALL_CHANNEL_MERCHANT_USERS_ADMIN_PERMISSION,
    {variables: {channelId: channel.id}},
  )
  const [deleteAllChannelMerchantUsers] = useMutation<
    MutationRoot['delete_channel_merchant_users']
  >(
    DELETE_ALL_CHANNEL_MERCHANT_USERS,
    {
      onCompleted: () => refresh(),
      update: (
        cache, {
          // @ts-ignore: delete_channel_merchant_users doesn't exist
          data: {delete_channel_merchant_users: {returning: channelMerchantUsers}},
        }) => updateChannelsQueryCache(
        cache, {
          channelId: channel.id,
          channelMerchantUserMutationType: 'DELETE',
          channelMerchantUsers,
        }
      ),
      variables: {channelId: channel.id},
    },
  )
  const [deleteUnselectedChannelMerchantUsers] = useMutation<
    MutationRoot['delete_channel_merchant_users']
  >(
    DELETE_UNSELECTED_CHANNEL_MERCHANT_USERS,
    {
      update: (
        cache,
        // @ts-ignore: delete_channel_merchant_users doesn't exist
        {data: {delete_channel_merchant_users: {returning: channelMerchantUsers}}}
      ) => updateChannelsQueryCache(
        cache, {
          channelId: channel.id,
          channelMerchantUserMutationType: 'DELETE',
          channelMerchantUsers,
        }
      ),
      variables: {
        channelId: channel.id,
        unselectedMerchantUserIds: unselectedMerchantUserIds.current,
      },
    }
  )
  return (
    <>
      <div className={styles.merchantUsersList}>
        <Checkbox
          checked={merchantUsers.every(mu => selectedIds.includes(mu.id))}
          onChange={e => {
            if (e.target.checked) {
              _.pull(unselectedMerchantUserIds.current, ...merchantUsers.map(mu => mu.id))
              setSelectedIds(ids => _.uniq([...ids, ...merchantUsers.map(mu => mu.id)]))
            }
            else {
              setSelectedIds(ids => _.without(
                ids, ...merchantUsers.filter(mu => !mu.isOwner).map(mu => mu.id))
              )
              unselectedMerchantUserIds.current.push(
                ...merchantUsers.filter(mu => !mu.isOwner).map(mu => mu.id)
              )
            }
          }}
        />
        {merchantUsers.map(merchantUser => (
          <div key={merchantUser.id}>
            <Checkbox
              checked={selectedIds.some(id => id === merchantUser.id)}
              disabled={
                isChannelMerchantUsersAdminList &&
                merchantUser.isOwner &&
                isChannelMerchantUserAdmin(merchantUser.id)
              }
              onChange={e => {
                if (e.target.checked) {
                  _.pull(unselectedMerchantUserIds.current, merchantUser.id)
                  setSelectedIds(ids => [...ids, merchantUser.id])
                }
                else {
                  unselectedMerchantUserIds.current.push(merchantUser.id)
                  setSelectedIds(ids => ids.filter(id => id !== merchantUser.id))
                }
              }}
            />
            <Typography component="span">{userDisplayName({merchantUser})}</Typography>
          </div>
        ))}
      </div>
      <Box paddingRight=".5rem" paddingTop="1rem" textAlign="end">
        <Button
          onClick={async () => {
            isChannelMerchantUsersAdminList ?
              await removeAllChannelMerchantUsersAdminPermission() :
              await deleteAllChannelMerchantUsers()
            refresh()
            onClearAll()
          }}
          variant="outlined"
        >
          {useTranslate()('dialogs.profile.companySettings.channelsAndUsers.removeAll')}
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            await Promise.all([
              upsertChannelMerchantUsers(),
              isChannelMerchantUsersAdminList ?
                removeUnselectedChannelMerchantUsersAdminPermission() :
                deleteUnselectedChannelMerchantUsers(),
            ])
            refresh()
            onApply()
          }}
          variant="contained"
        >
          {useTranslate()('dialogs.profile.companySettings.channelsAndUsers.apply')}
        </Button>
      </Box>
    </>
  )
}

const useUpdateChannelsQueryCache = () => {
  const {merchantUser: {id: merchantUserId} = {}} = useSessionMerchantUser()
  const {pathname} = useLocation()
  const redirect = useRedirect()
  return useCallback<(cache: ApolloCache<unknown>,
    options: {
      channelId: string,
      channelMerchantUserMutationType: 'DELETE' | 'UPSERT',
      channelMerchantUsers: ChannelMerchantUsers[],
  }) => void>((
      cache, {channelId, channelMerchantUserMutationType, channelMerchantUsers}
    ) => {
      const {channels}: any = cache.readQuery({
        query: CHANNELS_QUERY,
        variables: {merchantUserId},
      })
      const channelMerchantUser = channelMerchantUsers.find(
        cmu => cmu.channelId === channelId && cmu.merchantUserId === merchantUserId
      )
      if (!channelMerchantUser) return
      if (channelMerchantUserMutationType === 'UPSERT') {
        cache.writeQuery({
          data: {
            channels: channels.some(c => c.id === channelId) ?
              channels :
              [channelMerchantUser.channel, ...channels],
          },
          query: CHANNELS_QUERY,
          variables: {merchantUserId},
        })
      }
      if (channelMerchantUserMutationType === 'DELETE') {
        cache.writeQuery({
          data: {
            channels: channels.filter(c => c.id !== channelId),
          },
          query: CHANNELS_QUERY,
          variables: {merchantUserId},
        })
        // We don't want the user to stay on a channel inbox he is no longer member of.
        if (pathname.startsWith(`/inbox/${channelId}`)) redirect('/inbox')
      }
    }, [merchantUserId, pathname, redirect])
}

const useStyles = makeStyles<AppTheme, MakeStylesOptions>(theme => ({
  channelAccordionSummary: {
    '& .MuiAccordionSummary-content': {
      width: `calc(100% - ${theme.remSpacing(12)})`,
    },
  },
  channelMerchantUsersListPopperButton: ({isPopperOpen}) => ({
    '& svg': {
      rotate: isPopperOpen ? '180deg' : '0deg',
      transitionDuration: '.2s',
      transitionProperty: 'rotate',
    },
    borderRadius: theme.remSpacing(2.5),
    height: theme.remSpacing(4),
  }),
  channelName: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as any,
  },
  channelsAndMerchantUsersManagementShowRoot: {
    '& .MuiChip-root': {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.info.main,
      color: theme.palette.primary.main,
      margin: theme.remSpacing(1),
      marginRight: theme.remSpacing(1),
    },
  },
  merchantUsersList: {
    '& > :hover': {
      background: theme.palette.background.main,
    },
    marginLeft: theme.remSpacing(.5),
    maxHeight: theme.remSpacing(40),
    overflow: 'auto',
  },
  popper: {
    '& .MuiToolbar-root': {
      marginLeft: theme.remSpacing(2),
      marginRight: theme.remSpacing(0),
    },
    '& > .MuiPaper-root': {
      boxShadow: '4px 4px 14px rgba(0, 0, 0, 0.15)',
      paddingBottom: theme.remSpacing(1),
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '80%',
    },
    zIndex: 10000,
  },
  singleChipList: {
    display: 'inline-block',
    marginBottom: theme.remSpacing(2),
    marginTop: theme.remSpacing(1),
  },
}))

const DELETE_ONE_CHANNEL_MERCHANT_USER = gql`
  mutation($channelId: uuid!, $merchantUserId: uuid!){
    delete_channel_merchant_users(
      where: {channelId: {_eq: $channelId}, merchantUserId: {_eq: $merchantUserId}}
    ){returning{channelId merchantUserId}}
  }
`

const DELETE_ALL_CHANNEL_MERCHANT_USERS = gql`
  mutation($channelId: uuid!){
    delete_channel_merchant_users(where: {channelId: {_eq: $channelId}}){
      returning{channelId merchantUserId}
    }
  }
`

const DELETE_UNSELECTED_CHANNEL_MERCHANT_USERS = gql`
  mutation($channelId: uuid!, $unselectedMerchantUserIds: [uuid!]){
    delete_channel_merchant_users(
      where: {
        channelId: {_eq: $channelId},
        merchantUserId: {_in: $unselectedMerchantUserIds}
      }
    ){returning{channelId merchantUserId}}
  }
`

const REMOVE_CHANNEL_MERCHANT_USER_ADMIN_PERMISSION = gql`
  mutation ($channelId: uuid!, $merchantUserId: uuid!){
    update_channel_merchant_users(
      where: {channelId: {_eq: $channelId}, merchantUserId: {_eq: $merchantUserId}},
      _set: {isAdmin: false}
    ){returning{channelId merchantUserId}}
  }
`

const REMOVE_ALL_CHANNEL_MERCHANT_USERS_ADMIN_PERMISSION = gql`
  mutation ($channelId: uuid!){
    update_channel_merchant_users(
      where: {channelId: {_eq: $channelId}, merchantUser: {isOwner: {_eq: false}}},
      _set: {isAdmin: false}
    ){returning{channelId merchantUserId}}
  }
`

const REMOVE_UNSELECTED_CHANNEL_MERCHANT_USERS_ADMIN_PERMISSION = gql`
  mutation ($channelId: uuid!, $unselectedMerchantUserIds: [uuid!]){
    update_channel_merchant_users(
      where: {
        channelId: {_eq: $channelId}, merchantUserId: {_in: $unselectedMerchantUserIds}
      },
      _set: {isAdmin: false}
    ){returning{channelId merchantUserId}}
  }
`

const UPSERT_CHANNEL_MERCHANT_USERS = gql`
  mutation($objects: [channel_merchant_users_insert_input!]!){
    insert_channel_merchant_users(
      objects: $objects,
      on_conflict: {constraint: channel_merchant_users_pkey, update_columns: isAdmin}
    ){returning{channelId merchantUserId, channel{id name whatsappAccount{phoneNumber}}}}
  }
`

interface ChannelMerchantUsersListProps {
  channel: Channels,
  isChannelMerchantUsersAdminList?: boolean
}

interface MerchantUsersListProps {
  [props: string]: any
  channel: Channels,
  isChannelMerchantUsersAdminList: boolean,
  onApply: VoidFunction,
  onClearAll: VoidFunction,
}

interface MakeStylesOptions {
  isPopperOpen?: boolean
}

export default ChannelsAndMerchantUsersManagementShow
