import {useCallback, useContext, useEffect, useState} from 'react'
import {ListControllerProps, useListContext} from 'react-admin'

import ApiUrlsContext from '../contexts/ApiUrlsContext'
import {WorkerContext} from '../contexts/WorkerContext'
import authProvider from '../utils/authProvider'

const useSelectAllRowIdsInAllPages = ({
  isEnabled,
  ...props
}: UseSelectAllRowIdsInAllPagesProps) => {
  const {
    filter,
    filterValues,
    onSelect: selectIds,
    resource,
    selectedIds,
  } = useListContext(props)
  const [ids, setIds] = useState<string[]>()
  const [isFetchingIds, setIsFetchingIds] = useState(false)
  const [
    isAllRowIdsInAllPagesSelected,
    setIsAllRowIdsInAllPagesSelected,
  ] = useState(false)
  const {graphqlApiUrl} = useContext(ApiUrlsContext)
  const worker = useContext(WorkerContext)
  const selectAllRowIdsInAllPages = useCallback(
    async () => ids && selectIds(await worker.uniq({allIds: ids, selectedIds})),
    [ids, selectedIds, selectIds, worker]
  )
  const unselectAllRowIdsInAllPages = useCallback(() => selectIds([]), [selectIds])
  useEffect(() => {
    if (!(isEnabled && ids)) return
    (async () => {
      setIsAllRowIdsInAllPagesSelected(
        await worker.difference({
          allIds: ids,
          selectedIds,
        })
      )
    })()
  }, [ids, isEnabled, selectedIds, worker])
  useEffect(() => {
    if (!(isEnabled && graphqlApiUrl)) return
    const timeout = setTimeout(
      async () => {
        try {
          setIsFetchingIds(true)
          const ids = await worker.fetchAllIds({
            apiUrl: graphqlApiUrl,
            authToken: await authProvider.getToken(),
            filters: {...filter, ...filterValues},
            resource,
          })
          setIds(ids)
        }
        finally {
          setIsFetchingIds(false)
        }
      },
      200,
    )
    return () => clearTimeout(timeout)
  }, [isEnabled, graphqlApiUrl, worker, filter, filterValues, resource])
  return {
    isAllRowIdsInAllPagesSelected,
    loading: !ids || isFetchingIds,
    selectAllRowIdsInAllPages,
    unselectAllRowIdsInAllPages,
  }
}

type UseSelectAllRowIdsInAllPagesProps = Partial<ListControllerProps> & {
  isEnabled: boolean
}

export default useSelectAllRowIdsInAllPages
