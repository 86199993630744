import {Box} from '@material-ui/core'
import {FC, ReactNode} from 'react'

import useIsDesktop from '../hooks/useIsDesktop'

const DisplayBox: FC<DisplayBoxProps> = (
  {children, desktop = true, keepMounted = false, mobile = true, ...props}
) => {
  const isDesktop = useIsDesktop()
  const mobileDisplay = mobile ? (mobile === true ? 'block' : mobile) : 'none'
  const desktopDisplay = desktop ? (desktop === true ? 'block' : desktop) : 'none'
  if (!keepMounted && ((isDesktop ? desktopDisplay : mobileDisplay) === 'none')) {
    return null
  }
  /* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */
  return (
    <Box
      display={{
        xs: mobileDisplay,
        sm: mobileDisplay,
        md: mobileDisplay,
        lg: desktopDisplay,
        xl: desktopDisplay,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

interface DisplayBoxProps {
  children: ReactNode
  desktop?: boolean|string
  keepMounted?: boolean
  mobile?: boolean|string
}

export default DisplayBox
