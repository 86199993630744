import {FC, ReactElement, useEffect, useRef} from 'react'
import {Edit, EditProps, hideNotification, useNotify} from 'react-admin'
import {useDispatch} from 'react-redux'

const ExtendedEdit: FC<EditProps & {children: ReactElement}> = ({children, ...props}) => {
  const notify = useNotify()
  const dispatch = useDispatch()
  const autoHideNotificationDuration = 3000
  const hideNotificationTimeout = useRef<NodeJS.Timeout>()
  useEffect(() => () => {
    dispatch(hideNotification())
    clearTimeout(hideNotificationTimeout.current)
  }, [dispatch])
  return (
    <Edit
      // We use pessimistic mutation mode to remove the ability to undo the changes.
      // Saving doesn't work otherwise.
      // reference: https://marmelab.com/react-admin/doc/3.17/CreateEdit.html#mutationmode
      mutationMode="pessimistic"
      onSuccess={() => {
        notify(
          'ra.notification.updated',
          'info',
          {smart_count: 1},
          false,
          autoHideNotificationDuration,
        )
        // React admin keeps the toast notification visible when the component has focus.
        // This leads to a bad UX as the user doesn't understand why the notification
        // doesn't disappear and on mobile the user can't click on the navigation back
        // icon hidden by the notification component. Now we manually hide the
        // notification no matter if the notification is focused or not.
        hideNotificationTimeout.current = setTimeout(
          () => dispatch(hideNotification()),
          autoHideNotificationDuration,
        )
      }}
      {...props}
    >
      {children}
    </Edit>
  )
}

export default ExtendedEdit
