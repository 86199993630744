import {useEffect} from 'react'
import {useLogout} from 'react-admin'

const LogoutPage = () => {
  const logout = useLogout()
  useEffect(() => {logout()}, [logout])
  return null
}

export default LogoutPage
