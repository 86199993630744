import {useSubscription} from '@apollo/client'
import gql from 'graphql-tag'

import {ChatTypesEnum, SubscriptionRoot} from '../../types/graphqlSchema'
import useSessionMerchantUser from '../useSessionMerchantUser'

const useHasUnreadChatMessages = ({
  channelId, chatType, isMineOnly, isUnassignedOnly, skip,
}: UseHasUnreadChatMessagesProps) => {
  const {merchantUser: {id: sessionMerchantUserId} = {}} = useSessionMerchantUser()
  const {data: {chats} = {}} = useSubscription<SubscriptionRoot['chats']>(
    MOST_RECENT_ACTIVITY_CHAT_WITH_UNREAD_CHAT_MESSAGES_SUBSCRIPTION,
    {
      skip: skip || !sessionMerchantUserId,
      variables: {
        assignedMerchantUserIdComparisonExp: isUnassignedOnly ? (
          {_is_null: true}
        ) : isMineOnly ? (
          {_eq: sessionMerchantUserId}
        ) : undefined,
        // XXX: A fallback uuid is still required because SQL cannot equal compare columns
        //      with `NULL`
        channelId: channelId ?? '00000000-0000-0000-0000-000000000000',
        chatType,
        sessionMerchantUserId,
      },
    }
  )
  return !!chats?.length
}

const commonUnreadChatMessageFilters = (
  {chatType}: CommonUnreadChatMessageFilters = {}
) => (
  `
  _or: [
      {_and: [
        {isArchived: {_eq: false}}
        {type: {_eq: EXTERNAL}}
        ${chatType === ChatTypesEnum.External ? '{channelId: {_eq: $channelId}}' : ''}
        {_or: [
          {assignedMerchantUserId: {_eq: $sessionMerchantUserId}}
          {assignedMerchantUserId: {_is_null: true}}
        ]}
      ]},
      {_and: [{type: {_eq: INTERNAL}},{channelId: {_is_null: true}}]}
    ]
`
)

const MOST_RECENT_ACTIVITY_CHAT_WITH_UNREAD_CHAT_MESSAGES_SUBSCRIPTION = gql`subscription(
  $assignedMerchantUserIdComparisonExp: uuid_comparison_exp = {},
  $channelId: uuid,
  $chatType: chat_types_enum!
  $sessionMerchantUserId: uuid!
){
  chats(
    limit: 1,
    where: {
      ${commonUnreadChatMessageFilters({chatType: ChatTypesEnum.External})}
      type: {_eq: $chatType},
      assignedMerchantUserId: $assignedMerchantUserIdComparisonExp,
      unreadMessageCount: {_gt: 0}
    }
  ){id}
}`

interface UseHasUnreadChatMessagesProps {
  channelId?: string
  chatType: ChatTypesEnum
  isMineOnly?: boolean,
  isUnassignedOnly?: boolean,
  skip?: boolean,
}

interface CommonUnreadChatMessageFilters {
  chatType?: ChatTypesEnum
}

export default useHasUnreadChatMessages
export {commonUnreadChatMessageFilters}
