import Hashids from 'hashids'

// From: https://stackoverflow.com/a/2117523/543875
// @ts-ignore
const uuidv4 = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
  (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
)

const hashIdFromUuid = (uuid: string) => {
  const uuidBytes = uuid.replace(/-/g, '')
  const uuidInt = BigInt(`0x${uuidBytes}`)
  const hashids = new Hashids()
  return hashids.encode(uuidInt)
}

export default uuidv4
export {hashIdFromUuid, uuidv4}
