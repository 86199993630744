// Return `true` if app is running within an iframe
// See also: https://stackoverflow.com/a/61596084/543875

const FLINKIT_EMBEDDED_DISPLAY_LOCAL_STORAGE_KEY = window.btoa(
  'FLINKIT_EMBEDDED_DISPLAY_LOCAL_STORAGE_KEY'
)
const embeddedMobileDisplay =
  new URLSearchParams(window.location.search).get('flinkit-embedded-display')
const isEmbedded = window !== window.parent

// We store the preferred "embedded" setting which is then used when the iframe is
// reloaded. When the user navigates in the app within the iframe, the initial query
// parameters are lost and if the iframe is reloaded in the meantime, the preferred
// display falls back to desktop. To keep the display, we use the local storage.
embeddedMobileDisplay &&
  localStorage.setItem(FLINKIT_EMBEDDED_DISPLAY_LOCAL_STORAGE_KEY, embeddedMobileDisplay)

const isEmbeddedMobileDisplay =
  localStorage.getItem(FLINKIT_EMBEDDED_DISPLAY_LOCAL_STORAGE_KEY) === 'mobile'

export {isEmbedded, isEmbeddedMobileDisplay}
