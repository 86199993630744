import {useMutation, useQuery} from '@apollo/client'
import gql from 'graphql-tag'
import {useEffect, useState} from 'react'

import {PushNotificationsProvider} from '../contexts/PushNotificationContext'
import useSessionMerchantUser from '../hooks/useSessionMerchantUser'
import {MobileAppPlatformsEnum, MutationRoot, QueryRoot} from '../types/graphqlSchema'
import {isNativeMobileApp, platform} from '../utils/platform'


const PushNotificationsHandler = ({children}) => {
  const [deviceToken, setDeviceToken] = useState<any>()
  const [pushNotification, setPushNotification] = useState<any>()
  const {merchantUser} = useSessionMerchantUser()
  const {data: {chat_messages_by_pk: chatMessage} = {}} = useQuery<
    QueryRoot['chat_messages_by_pk']
  >(
    CHAT_MESSAGE_QUERY,
    {
      skip: !(pushNotification && pushNotification.chatMessageId),
      variables: {chatMessageId: pushNotification?.chatMessageId},
    }
  )
  const [
    upsertDeviceToken,
  ] = useMutation<MutationRoot['insert_merchant_mobile_app_installations_one']>(
    UPSERT_DEVICE_TOKEN_MUTATION
  )
  useEffect(() => {
    if (!(deviceToken && merchantUser)) return
    upsertDeviceToken({variables: {
      deviceToken,
      merchantUserId: merchantUser.id,
      platform: platform.toUpperCase() as MobileAppPlatformsEnum,
    }})
  }, [deviceToken, merchantUser, upsertDeviceToken])
  useEffect(() => {
    if (!chatMessage) return
    const {chat: {channelId, id}} = chatMessage
    window.location.href = channelId ? `/inbox/${channelId}/${id}` : `/team-chat/${id}`
  }, [chatMessage])
  return (
    <PushNotificationsProvider
      disabled={!(isNativeMobileApp && merchantUser)}
      onBackgroundPushNotificationReceived={setPushNotification}
      onForegroundPushNotificationReceived={setPushNotification}
      onReceiveDeviceToken={setDeviceToken}
    >
      {children}
    </PushNotificationsProvider>
  )
}

const CHAT_MESSAGE_QUERY = gql`
  query($chatMessageId: uuid!) {
    chat_messages_by_pk(id: $chatMessageId) {
      chat{channelId id}
      id
    }
  }
`
// Updates `merchant_user_id` on device_identifier upsert conflict which occurs when the
// same flinkit app is used by different merchant users
// TODO: Rename `<table>.device_identifier` to `.push_notifications_device_token`
const UPSERT_DEVICE_TOKEN_MUTATION = gql`
  mutation (
    $deviceToken: String!
    $merchantUserId: uuid!
    $platform: mobile_app_platforms_enum!
  ) {
    insert_merchant_mobile_app_installations_one(
      object: {
        deviceIdentifier: $deviceToken
        merchantUserId: $merchantUserId
        platform: $platform
      }
      on_conflict: {
        constraint: merchant_mobile_app_installations_device_identifier_key
        update_columns: [merchantUserId]
      }
    ) {id}
  }
`

export default PushNotificationsHandler
