import {makeStyles} from '@material-ui/core/styles'
import {FC, useContext} from 'react'

import {ApiUrlsContext} from '../../../contexts/ApiUrlsContext'


const TemplateHeaderMedia: FC<TemplateHeaderMediaProps> = ({templateHeaderMediaKey}) => (
  <img
    alt="Template-Nachricht Titelbild"
    className={useStyles().root}
    loading="lazy"
    src={useContext(ApiUrlsContext).getFilesApiUrl(templateHeaderMediaKey)}
  />
)

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '5px',
    height: '185px',
    objectFit: 'cover',
    width: '100%',
  },
}))

interface TemplateHeaderMediaProps {
  templateHeaderMediaKey: string
}

export default TemplateHeaderMedia
