import {Grid, Link, Typography} from '@material-ui/core'
import {FORM_ERROR} from 'final-form'
import {Loading, useAuthProvider, useTranslate} from 'react-admin'
import {Redirect} from 'react-router-dom'

import EmptyLayout from '../components/EmptyLayout'
import {
  Form, PasswordField, PasswordFieldError, SubmitButton, SubmitErrorMessage, TextField,
} from '../components/ExtendedFinalForm'
import RichTranslationText from '../components/RichTranslationText'
import {useAuthCheck} from '../utils/authProvider'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'

const SignupPage = () => {
  const translate = useTranslate()
  const authProvider = useAuthProvider()
  const {isAuthenticated, isCheckingAuth} = useAuthCheck()
  const validate = values => ({
    ...(!EMAIL_ADDRESS_REGEX.test(values.emailAddress) && {
      emailAddress: translate('validations.emailAddress'),
    }),
    ...(!values.fullName && {fullName: translate('validations.fullName')}),
    ...(!(
      (/\d/).test(values.password) &&
      values.password.length >= 8 &&
      (/\p{Ll}/u).test(values.password) &&
      (/\p{Lu}/u).test(values.password)
    ) && {password: translate('validations.password.missingSomeChars')}),
  })
  const submit = values => {
    const {fullName, ...rest} = values
    const [firstName, lastName = ' '] = fullName.split(' ')
    return authProvider
      .signup({firstName, lastName, ...rest})
      .then(() => {window.location.href = "/company-details"})
      .catch(e => {
        const errorCode = e.errors?.[0]?.extensions?.[0]?.code
        const errorMessage = translate(
          errorCode ? `pages.signup.errors.${errorCode}` : 'ra.message.error'
        )
        return {[FORM_ERROR]: errorMessage}
      })
  }
  if (isCheckingAuth) {
    return (
      <EmptyLayout><Loading loadingPrimary={translate('ra.page.loading')} /></EmptyLayout>
    )
  }
  if (isAuthenticated) {
    return <Redirect to="/" />
  }
  return (
    <EmptyLayout>
      <Form submit={submit} validate={validate}>
        <Grid alignItems="stretch" container direction="column" item spacing={3}>
          <Typography align="center" gutterBottom variant="h2">
            {translate('pages.signup.title')}
          </Typography>
          <Typography align="center" color="textSecondary" gutterBottom>
            <RichTranslationText
              tags={{
                login: match => (
                  <Link href="/login" underline="none"> {match}</Link>
                ),
              }}
              translationKey="pages.signup.links.login"
            />
          </Typography>
        </Grid>
        <Grid alignItems="stretch" container direction="column" item spacing={3}>
          <SubmitErrorMessage />
          <Grid item>
            <TextField
              label={translate('pages.signup.inputs.fullName.label')}
              name="fullName"
              placeholder={translate('pages.signup.inputs.fullName.placeholder')}
              type="text"
            />
          </Grid>
          <Grid item>
            <TextField<string>
              label={translate('pages.signup.inputs.emailAddress.label')}
              name="emailAddress"
              parse={v => v?.toLowerCase() ?? ''}
              placeholder={translate('pages.signup.inputs.emailAddress.placeholder')}
              type="email"
            />
          </Grid>
          <Grid item>
            <PasswordField
              label={translate('pages.signup.inputs.password.label')}
              placeholder={translate('pages.signup.inputs.password.placeholder')}
            />
            <PasswordFieldError />
          </Grid>
          <Grid item>
            <SubmitButton>
              {translate('pages.signup.button')}
            </SubmitButton>
          </Grid>
        </Grid>
        <Grid item>
          <Typography align="center" color="textPrimary" variant="body1">
            <RichTranslationText
              tags={{
                forgot: match => (
                  <Link href="/forgot-password" underline="none"> {match}</Link>
                ),
              }}
              translationKey="pages.signup.links.forgotPassword"
            />
          </Typography>
        </Grid>
      </Form>
    </EmptyLayout>
  )
}

export default SignupPage
