import {useMutation} from '@apollo/client'
import gql from 'graphql-tag'
import {useEffect} from 'react'
import {useTranslate} from 'react-admin'

import ThirdPartyConnectionDialog from '../components/ThirdPartyConnectionDialog'
import useQueryParameters from '../hooks/useQueryParameters'
import {removeQueryParameters} from '../utils/queryParameters'

const SalesforceAuthenticationPage = () => {
  const {code} = useQueryParameters()
  const [authenticateSalesforce, {error, loading}] =
    useMutation(AUTHENTICATE_SALESFORCE_MUTATION, {variables: {code}})
  useEffect(() => {
    if (!code) return
    removeQueryParameters()
    authenticateSalesforce()
  }, [code, authenticateSalesforce])
  return (
    <ThirdPartyConnectionDialog
      error={error}
      errorText={useTranslate()('pages.salesforceAuthentication.connectionError')}
      loading={loading}
      successText={useTranslate()('pages.salesforceAuthentication.connectionSuccess')}
    />
  )
}

const AUTHENTICATE_SALESFORCE_MUTATION = gql`
  mutation($code: String!) {
    authenticateSalesforce(code: $code) {
      merchant{id salesforceConnectionStatus}
    }
  }
`

export default SalesforceAuthenticationPage
