import {useEffect} from 'react'
import {useRefresh} from 'react-admin'

const useIntervalRefresh = (intervalMilliseconds: number) => {
  const refresh = useRefresh()
  useEffect(() => {
    const intervalId = setInterval(refresh, intervalMilliseconds)
    return () => clearInterval(intervalId)
  }, [intervalMilliseconds, refresh])
}

export default useIntervalRefresh
