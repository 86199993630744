import hexToRgb from './hexToRgb'
import palette from './palette'

// TODO: Inline box shadow directly where used
const boxShadows = {
  buttonBoxShadow: `
    0 7px 14px rgba(
      ${hexToRgb(palette.secondary.dark)},0.1
    ), 0 3px 6px rgba(0, 0, 0, 0.08)
  `,
  inputBoxShadow: `
    0 1px 3px rgba(
      ${hexToRgb(palette.secondary.dark)},0.15
    ), 0 1px 0 rgba(0, 0, 0, 0.02)
  `,
  linearProgressBoxShadow: `inset 0 1px 2px rgba(0, 0, 0, 0.1)`,
  menuBoxShadow: `
    0 50px 100px rgba(${hexToRgb(palette.secondary.dark)},0.10), 0 15px 35px
    rgba(${hexToRgb(palette.secondary.dark)},0.15), 0 5px 15px rgba(0, 0, 0, 0.1)
  `,
  popoverBoxShadow: `0px 0.5rem 2rem 0px rgba(0, 0, 0, 0.2)`,
  sliderBoxShadow: `inset 0 1px 2px rgba(${hexToRgb(palette.secondary.dark)}, 0.1)`,
}

export default boxShadows
