import {useMutation} from '@apollo/client'
import {Button, Grid, Link, makeStyles, Typography} from '@material-ui/core'
import {FORM_ERROR} from 'final-form'
import gql from 'graphql-tag'
import {useState} from 'react'
import {Loading, useTranslate} from 'react-admin'
import {Redirect, useParams} from 'react-router-dom'

import EmptyLayout from '../components/EmptyLayout'
import {
  Form, PasswordField, PasswordFieldError, SubmitButton, SubmitErrorMessage,
} from '../components/ExtendedFinalForm'
import RichTranslationText from '../components/RichTranslationText'
import theme from '../theme'
import {useAuthCheck} from '../utils/authProvider'
import {actionErrorCode} from '../utils/errors'

const ResetPasswordPage = () => {
  const translate = useTranslate()
  const styles = useStyles()
  const {isAuthenticated, isCheckingAuth} = useAuthCheck()
  const {passwordResetCode} = useParams<{passwordResetCode: string}>()
  const [isPasswordReset, setIsPasswordReset] = useState(false)
  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION)
  const validate = values => ({
    ...(!(
      (/\d/).test(values.password) &&
      values.password.length >= 8 &&
      (/\p{Ll}/u).test(values.password) &&
      (/\p{Lu}/u).test(values.password)
    ) && {password: translate('validations.password.missingSomeChars')}),
    ...(values.repeatPassword !== values.password && {
      repeatPassword: translate('validations.password.doNotMatch'),
    }),
  })
  const submit = v => resetPassword({
    variables: {newPassword: v.password, passwordResetCode},
  })
    .then(() => setIsPasswordReset(true))
    .catch(e => {
      const errorCode = actionErrorCode(e)
      const errorMessage = translate(
        errorCode ? `pages.resetPassword.errors.${errorCode}` : 'ra.message.error'
      )
      return {[FORM_ERROR]: errorMessage}
    })
  if (isCheckingAuth) {
    return (
      <EmptyLayout><Loading loadingPrimary={translate('ra.page.loading')} /></EmptyLayout>
    )
  }
  if (isAuthenticated) {
    return <Redirect to="/" />
  }
  return (
    <EmptyLayout>
      {isPasswordReset ? (
        <Grid
          alignItems="stretch"
          className={styles.container}
          container
          direction="column"
          justifyContent="center"
          spacing={3}
        >
          <Grid item>
            <Typography align="center" color="primary" variant="h2">
              {translate('pages.resetPassword.passwordResetSuccess.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center" color="textSecondary">
              {translate('pages.resetPassword.passwordResetSuccess.subtitle')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center" color="textSecondary">
              {translate('pages.resetPassword.passwordResetSuccess.nextStep')}
            </Typography>
          </Grid>
          <Grid item>
            <Button color="primary" fullWidth href="/login" variant="contained">
              {translate('pages.resetPassword.passwordResetSuccess.login')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Form submit={submit} validate={validate}>
          <Grid alignItems="stretch" container direction="column" item spacing={3}>
            <Typography align="center" color="primary" variant="h2">
              {translate('pages.resetPassword.title')}
            </Typography>
            <Typography align="center" color="textSecondary" gutterBottom>
              <RichTranslationText
                tags={{
                  register: match => (
                    <Link href="/signup" underline="none" variant="body1">
                      {match}
                    </Link>
                  ),
                }}
                translationKey="pages.resetPassword.register"
              />
            </Typography>
          </Grid>
          <Grid alignItems="stretch" container direction="column" item spacing={3}>
            <SubmitErrorMessage />
            <Grid item>
              <PasswordField
                label={translate('pages.resetPassword.fields.newPassword.label')}
                name="password"
                placeholder={
                  translate('pages.resetPassword.fields.newPassword.placeholder')
                }
              />
              <PasswordFieldError/>
            </Grid>
            <Grid item>
              <PasswordField
                label={translate('pages.resetPassword.fields.repeatNewPassword.label')}
                name="repeatPassword"
                placeholder={
                  translate('pages.resetPassword.fields.repeatNewPassword.placeholder')
                }
              />
            </Grid>
            <Grid item>
              <SubmitButton>
                {translate('actions.confirm')}
              </SubmitButton>
            </Grid>
          </Grid>
          <Grid item>
            <Typography align="center" color="textPrimary" variant="body1">
              <RichTranslationText
                tags={{
                  login: match => (
                    <Link href="/login" underline="none"> {match}</Link>
                  ),
                }}
                translationKey="pages.resetPassword.login"
              />
            </Typography>
          </Grid>
        </Form>
      )}
    </EmptyLayout>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    height: '100%',
    margin: 'auto',
    width: theme.remSpacing(56),
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
}))

const RESET_PASSWORD_MUTATION = gql`
  mutation($newPassword: String!, $passwordResetCode: String!){
    resetPassword(newPassword: $newPassword, passwordResetCode: $passwordResetCode)
  }
`

export default ResetPasswordPage
