import {Button, CircularProgress} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const ProgressButton = ({children, disabled, isProgressing, ...props}) => {
  const styles = useStyles()
  return (
    <Button
      disabled={(disabled === undefined) ? isProgressing : disabled}
      {...props}
    >
      {children}
      {isProgressing &&
        <CircularProgress className={styles.circularProgress} color="inherit" size={16} />
      }
    </Button>
  )
}

const useStyles = makeStyles(() => ({
  circularProgress: {
    position: 'absolute',
  },
}))

export default ProgressButton
