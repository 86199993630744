import {makeStyles} from '@material-ui/core/styles'
import {required, TextInput, useTranslate} from 'react-admin'

import EditFormAutoSave from '../components/EditFormAutoSave'
import ExtendedEdit from '../components/ExtendedEdit'
import ExtendedSimpleForm from '../components/ExtendedSimpleForm'

const Edit = ({disabled, id}) => {
  const styles = useStyles()
  return (
    <>
      <ExtendedEdit
        basePath="/"
        className={styles.root}
        id={id}
        resource="channel_chat_bot_users"
        title={useTranslate()('channel_chat_bot_users.title')}
      >
        <ExtendedSimpleForm
          disabled={disabled}
          redirect={false}
          toolbar={<></>}
        >
          <EditFormAutoSave />
          <TextInput label="Opt-In Trigger" source="optInTrigger" validate={required()} />
          <TextInput
            disabled={disabled}
            multiline
            rows={5}
            source="optInTriggerAutoresponse"
            validate={required()}
          />
          <TextInput
            disabled={disabled}
            source="optOutTrigger"
            validate={required()}
          />
          <TextInput
            disabled={disabled}
            multiline
            rows={5}
            source="optOutTriggerAutoresponse"
            validate={required()}
          />
        </ExtendedSimpleForm>
      </ExtendedEdit>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-root': {
      paddingBottom: theme.remSpacing(1),
    },
  },
}))

export {Edit}
