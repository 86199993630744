import {Button, CircularProgress, makeStyles} from '@material-ui/core'
import {FC} from 'react'

import {UploadIcon} from '../components/icons'
import useFileSelection from '../hooks/useFileSelection'

const FileUploadButton: FC<FileUploadButtonProps> = ({
  children,
  encoding = 'base64',
  loading = false,
  mimeTypes,
  onSelectFiles,
  ...props
}) => {
  const styles = useStyles()
  const {input, openFileSelectionDialog} = useFileSelection({
    canDragAndDrop: false,
    encoding,
    mimeTypes,
    multiple: false,
    onSelectFiles,
  })
  return (
    <Button
      color="primary"
      disabled={loading}
      onClick={openFileSelectionDialog}
      {...props}
      variant="outlined"
    >
      <UploadIcon />
      {children}
      {input}
      {loading && (
        <CircularProgress
          className={styles.progress}
          color="inherit"
          size={20}
        />
      )}
    </Button>
  )
}

interface FileUploadButtonProps {
  [prop: string]: any
  encoding?: FileEncoding,
  loading?: boolean,
  mimeTypes: string | string[],
  onSelectFiles: (files: FileAttachment[]) => void,
}

const useStyles = makeStyles(() => ({
  progress: {
    position: 'absolute',
  },
}))

export default FileUploadButton
