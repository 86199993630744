import {Box, IconButton} from '@material-ui/core'
import {BoxProps} from '@material-ui/core/Box/Box'
import {makeStyles} from '@material-ui/core/styles'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import useFileSelection from '../hooks/useFileSelection'
import Theme from '../theme'
import {CloseIcon} from './icons'

const ImageFilePicker = forwardRef<ImageFilePickerForwardRef, ImageFilePickerProps>(({
  children,
  defaultImage,
  disableFileSelectionOnClickImage = false,
  encoding = 'base64',
  isRemoveButtonVisible = true,
  onChange,
  shouldCheckForThreeSixtyCloudApiMaxFileSize,
  value,
  ...props
}, ref) => {
  const styles = useStyles({disableFileSelectionOnClickImage})
  const [objectURL, setObjectURL] = useState<string>()
  const {
    files: [file = value],
    input,
    openFileSelectionDialog,
    setFiles,
  } = useFileSelection({
    canDragAndDrop: false,
    encoding: encoding,
    mimeTypes: 'image/png,image/jpeg',
    multiple: false,
    onSelectFiles: undefined,
    shouldCheckForThreeSixtyCloudApiMaxFileSize,
  })
  useImperativeHandle(ref, () => ({openFileSelectionDialog}))
  useEffect(() => {
    /*
      TODO: To properly work the default picker value we must transform
       this picker component to a controlled component instead.
     */
    onChange(file)
  }, [onChange, file])
  useEffect(() => {
    if (!file) return
    const objectURL = URL.createObjectURL(file.file)
    setObjectURL(objectURL)
    return () => {
      URL.revokeObjectURL(objectURL)
      setObjectURL(undefined)
    }
  }, [file, setObjectURL])
  const removeFile = useCallback(() => {
    onChange(undefined)
    setFiles([])
  }, [onChange, setFiles])
  return (
    <Box
      onClick={!disableFileSelectionOnClickImage ? openFileSelectionDialog : undefined}
      position="relative"
      {...props}
    >
      {input}
      {(objectURL || defaultImage) && (
        <>
          <div className={styles.imageContainer}>
            {objectURL ? (
              <img alt="Mediendatei" loading="lazy" src={objectURL} />
            ) : (
              (typeof defaultImage === 'string') ? (
                <img
                  alt="Mediendatei"
                  loading="lazy"
                  src={defaultImage}
                />
              ) : defaultImage
            )}
          </div>
          {(isRemoveButtonVisible && objectURL) && (
            <IconButton
              className={styles.removeIconButton}
              onClick={e => {
                removeFile()
                e.stopPropagation()
              }}
            >
              <CloseIcon color="secondary" />
            </IconButton>
          )}
        </>
      )}
      {children}
    </Box>
  )
})

const useStyles = makeStyles<typeof Theme, MakeStyleOptions>(theme => ({
  imageContainer: {
    '& img': {
      borderRadius: theme.typography.pxToRem(5),
      maxHeight: '40vh',
      objectFit: 'cover',
      width: '100%',
    },
    cursor: ({disableFileSelectionOnClickImage}) =>
      !disableFileSelectionOnClickImage ? 'pointer' : 'default',
  },
  removeIconButton: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `2px 4px 10px ${theme.palette.disabled.main}`,
    height: theme.typography.pxToRem(48),
    margin: theme.remSpacing(1),
    opacity: .8,
    position: 'absolute',
    right: 0,
    top: 0,
    width: theme.typography.pxToRem(48),
  },
}))

interface ImageFilePickerProps extends Omit<BoxProps, 'onChange'> {
  defaultImage: string|JSX.Element
  disableFileSelectionOnClickImage?: boolean
  encoding?: FileEncoding
  isRemoveButtonVisible?: boolean
  onChange: (file: FileAttachment|undefined) => void
  shouldCheckForThreeSixtyCloudApiMaxFileSize?: boolean,
  uploadedImageS3Key?: string,
  value: FileAttachment|undefined
}

interface MakeStyleOptions {
  disableFileSelectionOnClickImage: boolean
}

export interface ImageFilePickerForwardRef {
  openFileSelectionDialog: VoidFunction
}

export default ImageFilePicker
