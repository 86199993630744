import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC} from 'react'

import theme from '../../theme'
import {CameraIcon} from '../icons'
import ImageFilePicker from '../ImageFilePicker'

const ChatMessageTemplateHeaderMediaFilePicker:
  FC<ChatMessageTemplateHeaderMediaFilePickerProps> =
({
  defaultImage,
  isChangeButtonVisible = true,
  isRemoveButtonVisible = true,
  onChange,
  value,
  ...props
}) => {
  const styles = useStyles(theme)
  return (
    <ImageFilePicker
      defaultImage={defaultImage}
      isRemoveButtonVisible={isRemoveButtonVisible}
      onChange={onChange}
      shouldCheckForThreeSixtyCloudApiMaxFileSize
      value={value}
      {...props}
    >
      {isChangeButtonVisible && (
        <Button
          className={styles.openDialogButton}
          color="primary"
          fullWidth={true}
          startIcon={<CameraIcon className={styles.cameraIcon} />}
          variant="outlined"
        >
          Bild ändern
        </Button>
      )}
    </ImageFilePicker>
  )
}

const useStyles = makeStyles(theme => ({
  cameraIcon: {
    marginTop: theme.remSpacing(0.5),
  },
  image: {
  },
  openDialogButton: {
    margin: 0,
    marginTop: theme.remSpacing(0.5),
  },
}))

interface ChatMessageTemplateHeaderMediaFilePickerProps {
  defaultImage: string|JSX.Element,
  isChangeButtonVisible?: boolean,
  isRemoveButtonVisible?: boolean,
  onChange: (file: FileAttachment|undefined) => void,
  value: FileAttachment|undefined,
}

export default ChatMessageTemplateHeaderMediaFilePicker
