import _ from 'lodash'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

const useSearchParams = () => {
  const history = useHistory()
  const {hash, search} = useLocation()
  const searchParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(search)),
    [search],
  )
  const setSearchParams = useCallback(
    (params: Record<string, string>, strict = false) => {
      history.replace({
        hash,
        search: new URLSearchParams(strict ? params : {...searchParams, ...params})
          .toString(),
      })
    },
    [hash, history, searchParams]
  )
  const removeSearchParams = useCallback((paramNames: string[]) => {
    setSearchParams(_.omit(searchParams, paramNames), true)
  }, [searchParams, setSearchParams])
  return {removeSearchParams, searchParams, setSearchParams}
}

export const useDelayRemoveChatMessageIdSearchParam = (
  options: UseDelayRemoveChatMessageIdSearchParamProps = {
    shouldRemoveOnlyIfSameMessageId: true,
    timeout: 2500,
  }
) => {
  const {shouldRemoveOnlyIfSameMessageId, timeout} = options
  const {removeSearchParams, searchParams} = useSearchParams()
  const [shouldScheduleRemove, setShouldScheduleRemove] = useState(false)
  const timoutId = useRef<NodeJS.Timeout>()
  useEffect(
    () => {shouldRemoveOnlyIfSameMessageId && clearTimeout(timoutId.current)},
    [shouldRemoveOnlyIfSameMessageId, searchParams.chatMessageId],
  )
  useEffect(() => {
    if (!shouldScheduleRemove) return
    setShouldScheduleRemove(false)
    timoutId.current = setTimeout(
      () => removeSearchParams(['chatMessageId']),
      timeout,
    )
  }, [
    shouldScheduleRemove,
    removeSearchParams,
    timeout,
  ])
  return useCallback(() => setShouldScheduleRemove(true), [setShouldScheduleRemove])
}

interface UseDelayRemoveChatMessageIdSearchParamProps {
  shouldRemoveOnlyIfSameMessageId: boolean
  timeout: number
}

export default useSearchParams
