import {Typography} from '@material-ui/core/styles/createTypography'

import breakpoints from './breakpoints'

const typography = {
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '20px',
  },
  body2: {
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: '18px',
  },
  button: {
    fontSize: '.75rem',
    fontWeight: 700,
    lineHeight: '16px',
  },
  caption: {
    fontSize: '.75rem',
    fontWeight: 400,
    lineHeight: '16px',
  },
  fontFamily: `
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif
  `,
  h1: {
    fontSize: '4.5rem',
    fontWeight: 500,
    lineHeight: '80px',
  },
  h2: {
    fontSize: '2.25rem',
    fontWeight: 700,
    lineHeight: '44px',
    [breakpoints.down('md')]: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '28px',
  },
  h4: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '20px',
  },
  h5: {
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '18px',
  },
  h6: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '18px',
  },
  overline: {
    fontSize: '.625rem',
    fontWeight: 400,
    lineHeight: '12px',
  },
  subtitle1: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: '24px',
  },
  subtitle2: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '20px',
  },
} as Typography

export default typography
