import {Grid, Link, Typography} from '@material-ui/core'
import {FORM_ERROR} from 'final-form'
import {Loading, useAuthProvider, useTranslate} from 'react-admin'
import {Redirect} from 'react-router-dom'

import EmptyLayout from '../components/EmptyLayout'
import {
  Form, PasswordField, SubmitButton, SubmitErrorMessage, TextField,
} from '../components/ExtendedFinalForm'
import RichTranslationText from '../components/RichTranslationText'
import {useAuthCheck} from '../utils/authProvider'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'


const LoginPage = () => {
  const {isAuthenticated, isCheckingAuth} = useAuthCheck()
  const translate = useTranslate()
  const authProvider = useAuthProvider()
  const validate = values => ({
    ...(!EMAIL_ADDRESS_REGEX.test(values.emailAddress) && {
      emailAddress: translate('validations.emailAddress'),
    }),
    ...(!values.password && {password: translate('validations.password.required')}),
  })
  const submit = values => authProvider.login(values)
    .then(() => {window.location.href = "/inbox"})
    .catch(e => {
      const errorCode = e.errors?.[0]?.extensions?.[0]?.code
      const errorMessage = translate(
        errorCode ? `pages.login.errors.${errorCode}` : 'ra.message.error'
      )
      return {[FORM_ERROR]: errorMessage}
    })
  if (isCheckingAuth) {
    return (
      <EmptyLayout><Loading loadingPrimary={translate('ra.page.loading')} /></EmptyLayout>
    )
  }
  if (isAuthenticated) {
    return <Redirect to="/" />
  }
  return (
    <EmptyLayout>
      <Form submit={submit} validate={validate}>
        <Grid alignItems="stretch" container direction="column" item spacing={3}>
          <Typography align="center" gutterBottom variant="h2">
            {translate('pages.login.title')}
          </Typography>
          <Typography align="center" color="textSecondary" gutterBottom>
            <RichTranslationText
              tags={{
                signup: match => (
                  <Link href="signup" underline="none">{match}</Link>
                ),
              }}
              translationKey="pages.login.links.signup"
            />
          </Typography>
        </Grid>
        <Grid alignItems="stretch" container direction="column" item spacing={3}>
          <SubmitErrorMessage />
          <Grid item>
            <TextField
              label={translate('pages.login.inputs.emailAddress.label')}
              name="emailAddress"
              parse={v => v?.toLowerCase() ?? ''}
              placeholder={translate('pages.login.inputs.emailAddress.placeholder')}
              type="email"
            />
          </Grid>
          <Grid item>
            <PasswordField
              label={translate('pages.login.inputs.password.label')}
              placeholder={translate('pages.login.inputs.password.placeholder')}
            />
          </Grid>
          <Grid item>
            <SubmitButton>{translate('pages.login.button')}</SubmitButton>
          </Grid>
        </Grid>
        <Grid item>
          <Typography align="center" color="textPrimary" variant="body1">
            <RichTranslationText
              tags={{
                forgot: match => (
                  <Link href="/forgot-password" underline="none"> {match}</Link>
                ),
              }}
              translationKey="pages.login.links.forgotPassword"
            />
          </Typography>
        </Grid>
      </Form>
    </EmptyLayout>
  )
}

export default LoginPage
