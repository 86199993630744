import {makeStyles} from '@material-ui/core/styles'
import {createElement} from 'react'

import {AppTheme} from '../theme'

const LineClamp = ({children, clamp = 2, rootElement = 'span'}) => createElement(
  rootElement,
  {className: useStyles({clamp}).root},
  children,
)

const useStyles = makeStyles<AppTheme, MakeStyleProps>({
  root: {
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': ({clamp}) => clamp,
    display: '-webkit-box',
    overflow: 'hidden',
  },
})

interface MakeStyleProps {
  clamp: number
}

export default LineClamp
