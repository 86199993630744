import {ApolloError} from '@apollo/client'
import {
  Box,
  Button, DialogContent, DialogTitle, LinearProgress, makeStyles, Typography,
} from '@material-ui/core'
import {FC, ReactNode} from 'react'
import {useRedirect, useTranslate} from 'react-admin'

import DialogCloseButton from '../components/DialogCloseButton'
import ExtendedDialog from '../components/ExtendedDialog'

const ThirdPartyConnectionDialog: FC<ThirdPartyConnectionDialogProps> = ({
  error,
  errorDetails,
  errorText,
  loading,
  onRetry,
  primaryErrorMessage,
  successText,
}) => {
  const redirect = useRedirect()
  const translate = useTranslate()
  const styles = useStyles()
  return (
    <ExtendedDialog
      className={`${styles.root}`}
      keepMounted={false}
      maxWidth="xs"
      onClose={() => {if (!loading) redirect('/')}}
      open
    >
      <DialogCloseButton
        disabled={loading}
        onClick={() => {if (!loading) redirect('/')}}
      />
      <DialogTitle>
        {loading ?
          translate('dialogs.thirdPartyConnection.loading') :
          (error ?
            translate('dialogs.thirdPartyConnection.failed') :
            translate('dialogs.thirdPartyConnection.success')
          )
        }
      </DialogTitle>
      {loading ?
        (<LinearProgress />) :
        (
          <DialogContent>
            {error ?
              (<>
                <Typography color="error" component="p" paragraph variant="body1">
                  {errorText}
                </Typography>
                {primaryErrorMessage && (
                  <Typography color="textPrimary" component="p" variant="body1">
                    {primaryErrorMessage}
                  </Typography>
                )}
                {errorDetails && (
                  <Box component="ul" textAlign="start">
                    {errorDetails.map((detail, i) => (
                      <Typography component="li" key={i} variant="body1">
                        {detail}
                      </Typography>
                    ))}
                  </Box>
                )}
                {onRetry && (
                  <Button onClick={onRetry} variant="contained">
                    {translate('actions.retry')}
                  </Button>
                )}
              </>) :
              (<>
                <Typography color="textPrimary" variant="body1">
                  {successText}
                </Typography>
                <Button onClick={() => redirect('/')} variant="outlined">
                  {translate('actions.continue')}
                </Button>
              </>)
            }
          </DialogContent>
        )}
    </ExtendedDialog>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogTitle-root > .MuiTypography-h6': {
      color: theme.palette.info.main,
      fontWeight: theme.typography.h5.fontWeight,
    },
    '& .MuiPaper-rounded': {
      borderRadius: '10px',
      padding: `${theme.remSpacing(7)} ${theme.remSpacing(4)}`,
      textAlign: 'center',
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiPaper-rounded': {
        padding: `0 ${theme.remSpacing(4)} `,
      },
    },
  },
}))

interface ThirdPartyConnectionDialogProps {
  error: ApolloError | undefined
  errorDetails?: ReactNode[]
  errorText: string
  loading: boolean
  onRetry?: VoidFunction
  primaryErrorMessage?: ReactNode
  successText: string
}

export default ThirdPartyConnectionDialog
