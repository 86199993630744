import {Camera, CameraResultType, Photo} from '@capacitor/camera'
import {Buffer} from 'buffer'
import {useCallback} from 'react'
import {useTranslate} from 'react-admin'

import {assert} from '../utils/asserts'

const useTakePicture = ({encoding = 'base64'}: Pick<TakePictureOptions, 'encoding'>) => {
  const translate = useTranslate()
  return useCallback(() => takePicture({
    encoding,
    promptLabelCancel: translate('hooks.useTakePicture.promptLabelCancel'),
    promptLabelHeader: translate('hooks.useTakePicture.promptLabelHeader'),
    promptLabelPhoto: translate('hooks.useTakePicture.promptLabelPhoto'),
    promptLabelPicture: translate('hooks.useTakePicture.promptLabelPicture'),
  }), [encoding, translate])
}

const takePicture = async ({
  encoding = 'base64',
  promptLabelCancel,
  promptLabelHeader,
  promptLabelPhoto,
  promptLabelPicture,
}: TakePictureOptions): Promise<CameraFileAttachment | undefined> => {
  assert(['base64', 'hex'].includes(encoding), `Unsupported encoding: '${encoding}`)
  let picture: Photo | undefined
  try {
    picture = await Camera.getPhoto({
      // TODO: We could actually allow picture editing after it was taken, however the
      //       UX is a bit odd. Set it to `true` to find out. However discuss with UX team
      //       how we could turn this into a better UX as picture editing might be good to
      //       provide to the user.
      allowEditing: false,
      promptLabelCancel,
      promptLabelHeader,
      promptLabelPhoto,
      promptLabelPicture,
      quality: 100,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
    })
  }
  catch (e) {
    if (
      !['No image picked', 'User cancelled photos app'].includes((e as Error).message)
    ) {
      console.error(e)
    }
    return
  }
  return {
    encodedContent: {
      base64: picture.base64String,
      hex: Buffer.from(picture.base64String as string, 'base64').toString('hex'),
    }[encoding] as string,
    filename: `Picture-${Date.now() / 1000 | 0}.${picture.format}`,
    mimeType: `image/${picture.format}`,
  }
}

interface TakePictureOptions {
  encoding: FileEncoding
  promptLabelCancel: string
  promptLabelHeader: string
  promptLabelPhoto: string
  promptLabelPicture: string
}

export default useTakePicture
