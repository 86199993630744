import {GraphQLError} from 'graphql'

import {getGraphqlApiUrl} from '../contexts/ApiUrlsContext'
import {getReviewAppBaseHostName} from '../hooks/useBaseHostname'
import env from './env'
import {isNativeMobileApp} from './platform'

const fetchGraphQL = async <Data>(body: FetchGraphQLBody): Promise<Data> => {
  const baseHostName = (
    isNativeMobileApp && await getReviewAppBaseHostName()
  ) || env('BASE_HOSTNAME')
  const apiUrl = getGraphqlApiUrl(baseHostName)
  const response = await fetch(
    apiUrl,
    {
      body: JSON.stringify(body),
      headers: {'content-type': 'application/json'},
      method: 'POST',
    },
  )
  if (!response.ok) {
    throw new Error('not ok')
  }
  const {data, errors} = (await response.json()) as FetchGraphQLResponse<Data>
  if (errors) {
    const e = new Error(errors[0]?.message) as FetchGraphQLError
    e.errors = errors
    throw e
  }
  return data
}

interface FetchGraphQLBody {
  query: string,
  variables: Record<string, unknown>,
}

interface FetchGraphQLResponse<T>{
  data: T,
  errors: GraphQLError[]
}

interface FetchGraphQLError extends Error {
  errors: GraphQLError[]
}

export default fetchGraphQL
export type {FetchGraphQLError}
