import {PermissionState} from '@capacitor/core'
import {Badge} from '@capawesome/capacitor-badge'
import {useCallback, useEffect, useState} from 'react'

import {isNativeMobileApp} from '../utils/platform'
import useGetUnreadMessageCount from './chat/useGetUnreadMessageCount'

const useMobileAppBadge = () => {
  const [isSupported, setIsSupported] = useState<boolean>()
  const [permission, setPermission] = useState<PermissionState>()
  const unreadMessageCount = useGetUnreadMessageCount({disabled: !isNativeMobileApp})
  const updateMobileAppBadge = useCallback(count => {
    if (
      !isNativeMobileApp || permission === 'denied' || !isSupported
    ) return
    Badge.set({count})
  }, [permission, isSupported])
  useEffect(() => {
    if (!isNativeMobileApp) return
    (async () => {
      const {isSupported} = await Badge.isSupported()
      setIsSupported(isSupported)
      const permission = await Badge.checkPermissions()
      if (!['denied', 'granted'].includes(permission.display)) {
        const {display} = await Badge.requestPermissions()
        setPermission(display)
      }
      else {
        setPermission(permission.display)
      }
    })()
  }, [])
  useEffect(() => {
    updateMobileAppBadge(unreadMessageCount)
  }, [unreadMessageCount, updateMobileAppBadge])
}

export default useMobileAppBadge
