import {Palette} from '@material-ui/core/styles/createPalette'

const palette = {
  background: {
    default: '#F0F0F0',
    main: '#F0F0F0',
    paper: '#FAFAFA',
    secondary: '#E6E6E6',
  },
  chatBubble: {
    contrastText: '#16133E',
    main: '#CFEBFF',
  },
  disabled: {
    main: '#CDD5DF',
  },
  error: {
    contrastText: '#FFFFFF',
    dark: '#DA0000',
    light: '#FBEEEF',
    main: '#FF0000',
  },
  // Special use case: is it possible to replace this filter?
  filters: {
    dragAndDropGreen: `
      invert(32%)
      sepia(79%)
      saturate(280%)
      hue-rotate(108deg)
      brightness(104%)
      contrast(97%)
      opacity(1)
    `,
  },
  info: {
    contrastText: '#FAFAFA',
    dark: '#2543D3',
    light: '#F3F7FE',
    main: '#1667F2',
  },
  primary: {
    contrastText: '#FAFAFA',
    main: '#16133E',
  },
  secondary: {
    contrastText: '#FAFAFA',
    dark: '#454265',
    main: '#73718B',
  },
  success: {
    dark: '#16133E',
    light: '#F1FFF2',
    main: '#04AB0A',
  },
  text: {
    disabled: '#73718B',
    primary: '#16133E',
    secondary: '#73718B',
  },
  warning: {
    dark: '#D26C03',
    light: '#FFF9F4',
    main: '#F47D02',
  },
} as Palette

export default palette
