import {makeStyles} from '@material-ui/core/styles'

const Dot = ({className, ...props}) => {
  const styles = useStyles()
  return (
    <i className={`${styles.root} ${className ?? ''}`} {...props} />
  )
}

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '50%',
    display: 'inline-block',
    flexGrow: 0,
    flexShrink: 0,
    height: '0.8em',
    width: '0.8em',
  },
}))

export default Dot
