import {useQuery} from '@apollo/client'
import gql from "graphql-tag"
import {useCallback, useEffect, useMemo} from 'react'
import {usePageVisibility} from 'react-page-visibility'

import {QueryRoot} from '../types/graphqlSchema'


const useMostRecentInboundChatMessageElapsedIntervals:
  UseMostRecentInboundChatMessageElapsedIntervals = ({chatId} = {}) => {
    const isPageVisible = usePageVisibility() ?? true
    const {
      data: {chats_by_pk: chat} = {},
      error,
      loading,
      refetch,
    } = useQuery<QueryRoot['chats_by_pk']>(
      CHAT_ELAPSED_INTERVAL_QUERY, {skip: !chatId, variables: {chatId}},
    )
    const chatIdToElapsedInterval = useMemo(
      () => (chat ? {[chat.id]: chat.mostRecentInboundChatMessageElapsedInterval} : {}),
      [chat]
    )
    // Force-refetch when page becomes visible again to show up-to-date values immediately
    useEffect(
      () => {chatId && isPageVisible && refetch()},
      [chatId, isPageVisible, refetch]
    )
    const refresh = useCallback(async () => {await refetch()}, [refetch])
    return [chatIdToElapsedInterval, {error, loading, refresh}]
  }

const CHAT_ELAPSED_INTERVAL_QUERY = gql`
  query ($chatId: uuid!){chats_by_pk(id: $chatId){
    id
    mostRecentInboundChatMessageElapsedInterval
  }}
`

interface UseMostRecentInboundChatMessageElapsedIntervals {
  (props: {chatId?: string}):
    [
      Record<string, number | undefined>,
      {error: any | undefined, loading: boolean, refresh: () => Promise<void>}
    ]
}

export default useMostRecentInboundChatMessageElapsedIntervals
