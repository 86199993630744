import {NetworkStatus, useMutation, useQuery} from '@apollo/client'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Skeleton} from '@material-ui/lab'
import gql from 'graphql-tag'
import {isFinite, isNumber, pick, round} from 'lodash'
import {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {
  AutocompleteInput,
  BooleanField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  FilterLiveSearch,
  FunctionField,
  Identifier,
  Labeled,
  Link,
  List,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  useCreateSuggestionContext,
  useFormContext,
  useGetIdentity,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
  useShowContext,
  useTranslate,
  useUnselectAll,
} from 'react-admin'
import {useField, useForm, useFormState} from 'react-final-form'

import {
  ChatMessageTemplateFormPreview,
  ChatMessageTemplatePreview,
} from '../components/chatMessageTemplate/ChatMessageTemplateEditor'
import ConfirmationDialog from '../components/ConfirmationDialog'
import ExtendedSimpleForm from '../components/ExtendedSimpleForm'
import {
  AddIcon,
  ArrowRightIcon,
  ChevronRightIcon,
  ClockIcon,
  DownloadIcon,
  SearchIcon,
} from '../components/icons'
import RichTranslationText from '../components/RichTranslationText'
import SubtitledInput from '../components/SubtitledInput'
import ApiUrlsContext from '../contexts/ApiUrlsContext'
import {SandboxContext} from '../contexts/SandboxContext'
import useFormatTimestamp from '../hooks/dates/useFormatTimestamp'
import useIntervalRefresh from '../hooks/useIntervalRefresh'
import useIsDesktop from '../hooks/useIsDesktop'
import useIsOwner from '../hooks/useIsOwner'
import useQueryParameters from '../hooks/useQueryParameters'
import useSoftDelete from '../hooks/useSoftDelete'
import {
  Campaigns,
  MerchantCustomerUsers,
  MutationRoot,
  QueryRoot,
} from '../types/graphqlSchema'
import {isValidDateTimeString} from '../utils/dates'
import DeleteWithConfirmButton from './components/DeleteWithConfirmButton'
import ExtendedList from './components/ExtendedList'
import ExtendedPagination from './components/ExtendedPagination'
import {CreateHeader, ShowHeader} from './components/ResourceHeader'


const CampaignCreate = props => {
  const [insertCampaign] = useMutation(
    CAMPAIGN_INSERT_MUTATION,
    {
      onCompleted: () => {
        unselectCustomerUsers()
        notify('resources.campaigns.create.success')
        redirect(`/campaigns`)
      },
      onError: e => {
        console.error(e)
        notify('resources.campaigns.create.error', 'error')
      },
    },
  )
  const redirect = useRedirect()
  const notify = useNotify()
  const styles = useStyles()
  const translate = useTranslate()
  const isOwner = useIsOwner()
  const {permissions: {channelAdminChannelIds} = {}} = usePermissions<Permissions>()
  const unselectCustomerUsers = useUnselectAll('customer_users')
  const save = useCallback(async ({...formInputValues}) => {
    const formValues = pick(
      formInputValues, [
        'audienceType',
        'channelId',
        'chatMessageTemplateId',
        'name',
        'scheduledTimestamp',
      ]
    )
    const campaignCustomerUsers = (formInputValues.campaignCustomerUserIds ?? []).map(
      customerUserId => ({customerUserId})
    )
    await insertCampaign({variables: {...formValues, campaignCustomerUsers}})
  }, [insertCampaign])
  return (
    <Create actions={<CreateHeader />} className={styles.create} {...props}>
      <ExtendedSimpleForm
        initialValues={{
          // TODO: Remove `audienceType` references in hasura, database, events-api and
          //    of the web-app since we now use the audience table to select customers.
          audienceType: 'SELECTED_CUSTOMER_USERS',
        }}
        save={save}
        toolbar={<CreateToolbar />}
      >
        <SubtitledInput
          addLabel
          source="name"
          subtitle={translate('resources.campaigns.create.inputs.name.subtitle')}
        >
          {/* @ts-ignore: prop "source" is missing */}
          <TextInput
            label={translate('resources.campaigns.fields.name')}
            validate={required()}
          />
        </SubtitledInput>
        <SubtitledInput
          addLabel
          source="chatMessageTemplateId"
          subtitle={translate(
            'resources.campaigns.create.inputs.chatMessageTemplateId.subtitle'
          )}
        >
          {/* @ts-ignore: prop "source" is missing */}
          <ReferenceInput
            createLabel={translate('resources.campaigns.createTemplate')}
            filter={{isDeleted: false, status: 'APPROVED', type: 'MARKETING'}}
            initialValue={useQueryParameters().templateId}
            label={translate(
              'resources.campaigns.create.inputs.chatMessageTemplateId.label'
            )}
            perPage={false as any}
            reference="chat_message_templates"
          >
            <SelectInput
              create={<CreateTemplateDialog />}
              optionValue="id"
              translateChoice={false}
              validate={required()}
            />
          </ReferenceInput>
        </SubtitledInput>
        <ChatMessageTemplateFormPreview
          className={styles.chatMessageTemplateEditor}
          source="chatMessageTemplateId"
        />
        <SubtitledInput
          addLabel
          source="channelId"
          subtitle={translate('resources.campaigns.create.inputs.channelId.subtitle')}
        >
          {/* @ts-ignore: prop "source" is missing */}
          <ReferenceInput
            filter={!isOwner && {'id': channelAdminChannelIds}}
            label={translate('resources.campaigns.create.inputs.channelId.label')}
            // @ts-ignore -  Type 'false' is not assignable to type 'number | undefined'
            perPage={false}
            reference="channels"
          >
            <SelectInput
              optionValue="id"
              source="name"
              translateChoice={false}
              validate={required()}
            />
          </ReferenceInput>
        </SubtitledInput>
        <SubtitledInput
          addLabel
          source="scheduledTimestamp"
          subtitle={translate(
            'resources.campaigns.create.inputs.scheduledTimestamp.subtitle'
          )}
        >
          {/* @ts-ignore: prop "source" is missing */}
          <DateTimeInput
            defaultValue={new Date()}
            label={translate(
              'resources.campaigns.create.inputs.scheduledTimestamp.label'
            )}
            parse={v => !isValidDateTimeString(v) ? undefined : new Date(v)}
            validate={[
              v => (v === undefined) || isValidDateTimeString(v) ?
                undefined :
                translate('validations.date'),
            ]}
          />
        </SubtitledInput>
        <Typography gutterBottom variant="subtitle1">
          {translate('resources.campaigns.create.inputs.audienceType.subtitle')}
        </Typography>
        <MerchantCustomerUserList
          actions={null}
          bulkActionButtons={true}
          resource="customer_users"
          source="campaignCustomerUserIds"
        />
      </ExtendedSimpleForm>
    </Create>
  )
}

const MerchantCustomerUserList = ({source, ...props}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const {input: {onChange}} = useField(source)
  const {identity: {merchantId} = {}} = useGetIdentity()
  const {values: {audienceType}} = useFormState({subscription: {values: true}})
  const {
    data: {aggregated_merchant_customer_user_tags: tagNames = []} = {},
  } = useQuery<QueryRoot['merchants']>(
    DISTINCT_AGGREGATED_MERCHANT_CUSTOMER_USER_TAGS_QUERY,
  )
  // Looks like react-admin does not cache the filter prop.
  // This ends up making the "useSelectAllRowIdsInAllPages" hook defined on the
  // extended list fetching ALL the resource ids on every render cycle.
  const filter = useMemo(() => ({
    'merchantCustomerUsers#merchantId': merchantId,
  }), [merchantId])
  if (audienceType !== 'SELECTED_CUSTOMER_USERS') return null
  return (
    <Grid className={styles.customerUserListRoot} container item>
      <ExtendedList
        {...props}
        canSelectAllPages
        filter={filter}
        filters={[
          <FilterLiveSearch
            // @ts-ignore: 'InputProps' does not exist
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="secondary" />
                </InputAdornment>
              ),
              onKeyDown: e => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  e.stopPropagation()
                }
              },
            }}
            alwaysOn
            key="fullText"
            source={
              "merchantCustomerUsers#fullText@_ilike," +
              "merchantCustomerUsers#emailAddress@_ilike," +
              "merchantCustomerUsers#role@_ilike," +
              "merchantCustomerUsers#whatsappPhoneNumber@_ilike"
            }
          />,
          <AutocompleteInput
            choices={tagNames}
            key="merchantCustomerUsers#aggregatedMerchantCustomerUserTags#tagName"
            label={translate(
              'resources.aggregated_merchant_customer_user_tags.fields.tagName'
            )}
            optionText="tagName"
            optionValue="tagName"
            source="merchantCustomerUsers#aggregatedMerchantCustomerUserTags#tagName"
            translateChoice={false}
          />,
          <SelectInput
            choices={[
              {name: translate('ra.boolean.true'), value: 'true'},
              {name: translate('ra.boolean.false'), value: 'false'},
            ]}
            className={styles.customerUserFilterMarketingConsentFilter}
            falseLabel={translate(
              'resources.merchant_customer_users.inputs.isMarketingConsentGranted.' +
              'falseLabel'
            )}
            key="merchantCustomerUsers#isMarketingConsentGranted"
            label={translate(
              'resources.merchant_customer_users.fields.isMarketingConsentGranted'
            )}
            nullLabel={translate(
              'resources.merchant_customer_users.inputs.isMarketingConsentGranted.' +
              'nullLabel'
            )}
            optionValue="value"
            source="merchantCustomerUsers#isMarketingConsentGranted"
            trueLabel={translate(
              'resources.merchant_customer_users.inputs.isMarketingConsentGranted.' +
              'trueLabel'
            )}
          />,
          <ReferenceInput
            filterToQuery={q => ({'firstName@_ilike,lastName@_ilike': q})}
            key="merchantCustomerUsers#primaryResponsibleMerchantUserId"
            label={translate(
              'resources.merchant_customer_users.fields' +
              '.primaryResponsibleMerchantUserId'
            )}
            perPage={50}
            reference="merchant_users"
            sort={{field: 'firstName', order: 'ASC'}}
            source="merchantCustomerUsers#primaryResponsibleMerchantUserId"
          >
            <AutocompleteInput
              optionText={({firstName, lastName}) => `${firstName} ${lastName}`}
            />
          </ReferenceInput>,
        ]}
        pagination={
          <ExtendedPagination className={styles.pagination} rowsPerPageOptions={[]} />
        }
        sort={{field: 'merchantCustomerUsers_aggregate.min.firstName', order: 'asc'}}
      >
        <MerchantCustomerUserDatagrid onChange={onChange} />
      </ExtendedList>
    </Grid>
  )
}

const MerchantCustomerUserDatagrid: FC<MerchantCustomerUserDatagridProps> = ({
  onChange,
}) => {
  const {selectedIds} = useListContext()
  const translate = useTranslate()
  useEffect(() => onChange?.(selectedIds), [selectedIds, onChange])
  return (
    <Datagrid hasBulkActions={!!onChange}>
      <ReferenceManyField
        label={useTranslate()('resources.merchant_customer_users.fields.name')}
        reference="merchant_customer_users"
        sortBy="merchantCustomerUsers_aggregate.min.firstName"
        target="customerUserId"
      >
        <SingleFieldList>
          <FunctionField<MerchantCustomerUsers>
            render={u => [u?.firstName, u?.lastName].join(' ')}
          />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField
        label={useTranslate()(
          'resources.merchant_customer_users.fields.whatsappPhoneNumber'
        )}
        source="whatsappPhoneNumber"
      />
      <ReferenceManyField
        label={useTranslate()(
          'resources.merchant_customer_users.fields.isMarketingConsentGranted'
        )}
        reference="merchant_customer_users"
        sortBy="merchantCustomerUsers_aggregate.min.isMarketingConsentGranted"
        target="customerUserId"
      >
        <SingleFieldList>
          <BooleanField looseValue source="isMarketingConsentGranted"/>
        </SingleFieldList>
      </ReferenceManyField>
      {useIsDesktop() && (
        <ReferenceManyField
          label={translate(
            'resources.merchant_customer_users.fields.companyName'
          )}
          reference="merchant_customer_users"
          sortBy="merchantCustomerUsers_aggregate.min.companyName"
          target="customerUserId"
        >
          <SingleFieldList>
            <TextField source="companyName"/>
          </SingleFieldList>
        </ReferenceManyField>
      )}
      <ReferenceManyField
        label={useTranslate()(
          'resources.merchant_customer_users.fields.emailAddress'
        )}
        reference="merchant_customer_users"
        sortBy="merchantCustomerUsers_aggregate.min.emailAddress"
        target="customerUserId"
      >
        <SingleFieldList>
          <TextField source="emailAddress"/>
        </SingleFieldList>
      </ReferenceManyField>
    </Datagrid>
  )
}

const CreateTemplateDialog = () => {
  // TODO: Instead of loosing all form input, store the current form state into the
  //       current route state. Then when coming back here, automatically pick the
  //       new template, if even if not yet approved. In the future, we want users to
  //       create campaigns with yet pending templates so that the campaign can be sent
  //       once the template has been approved.
  const redirect = useRedirect()
  const {onCancel} = useCreateSuggestionContext()
  return (
    <ConfirmationDialog
      confirmationText={useTranslate()(
        'resources.campaigns.createTemplateDialog.confirmationText'
      )}
      onCancel={onCancel}
      onConfirm={() => redirect('create', '/chat_message_templates')}
      open
      title={useTranslate()('resources.campaigns.createTemplate')}
    >
      <Typography paragraph variant="body1">
        {useTranslate()('resources.campaigns.createTemplateDialog.content')}
      </Typography>
    </ConfirmationDialog>
  )
}

const CreateToolbar = props => {
  const {handleSubmitWithRedirect, invalid, saving} = props
  const [isOpen, setIsOpen] = useState(false)
  const form = useForm()
  const formContext = useFormContext()
  const formState = useFormState()
  const {
    values: {audienceType, campaignCustomerUserIds},
  } = useFormState({subscription: {values: true}})
  const customerUserFilter = {
    ALL: {},
    ALL_WITH_MARKETING_OPT_IN: {'merchantCustomerUsers#isMarketingConsentGranted': true},
    SELECTED_CUSTOMER_USERS: {id: campaignCustomerUserIds},
  }[audienceType]
  const formatTimestamp = useFormatTimestamp()
  return (
    <Toolbar {...props}>
      <Button
        color="primary"
        disabled={formState.invalid || !campaignCustomerUserIds?.length}
        onClick={() => setIsOpen(true)}
        variant="contained"
      >
        {useTranslate()('resources.campaigns.create.createAction')}
      </Button>
      <ConfirmationDialog
        confirmationText={useTranslate()('actions.send')}
        onCancel={() => setIsOpen(false)}
        onConfirm={() => {
          if (saving) return
          formContext.setOnSave?.()
          handleSubmitWithRedirect()
          setIsOpen(!invalid)
        }}
        open={isOpen}
        title={useTranslate()('resources.campaigns.create.toolbar.confirmDialog.title')}
      >
        {form.getFieldState('scheduledTimestamp')?.value ? (
          <Typography paragraph variant="body1">
            <RichTranslationText
              rootElement="span"
              translationKey={"resources.campaigns.create.toolbar.confirmDialog." +
                "scheduledCampaignMessage"
              }
              translationOptions={{
                name: form.getFieldState('name')?.value ?? '-',
                scheduledDatetime: formatTimestamp(
                  form.getFieldState('scheduledTimestamp')?.value?.toISOString(),
                ),
              }}
            />
          </Typography>
        ) : (
          <RichTranslationText
            rootElement="span"
            translationKey={"resources.campaigns.create.toolbar.confirmDialog." +
              "instantCampaignMessage"
            }
            translationOptions={{
              name: form.getFieldState('name')?.value ?? '-',
            }}
          />
        )}
        <List
          {...props}
          actions={false}
          bulkActionButtons={false}
          empty={
            <Typography color="error">{useTranslate()(
              'resources.campaigns.create.toolbar.confirmDialog.emptyContacts'
            )}</Typography>
          }
          filter={customerUserFilter}
          resource="customer_users"
          sort={{field: 'merchantCustomerUsers_aggregate.min.firstName', order: 'asc'}}
        >
          <MerchantCustomerUserDatagrid />
        </List>
        <br/>
        <Typography variant="body1">
          {useTranslate()(
            'resources.campaigns.create.toolbar.confirmDialog.termsAndConditions'
          )}
        </Typography>
      </ConfirmationDialog>
    </Toolbar>
  )
}

const CampaignShow = props => (
  <Show {...props} actions={<CampaignsShowHeader />}>
    <CampaignsSimpleShowLayout {...props} />
  </Show>
)

const CampaignsSimpleShowLayout = ({...props}) => {
  const translate = useTranslate()
  return (
    <SimpleShowLayout>
      <Box>
        <Box display="flex" justifyContent="space-between">
          <div>
            <Typography variant="body1">
              {translate('resources.campaigns.show.campaignPerformanceTitle')}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {translate('resources.campaigns.show.campaignPerformanceSubtitle')}
            </Typography>
          </div>
          <CampaignMessagesMetadataExportButton {...props} />
        </Box>
        <CampaignMessagesCount record={props.record} />
      </Box>
      <CampaignTimestampField />
      <FunctionField<Campaigns>
        label={translate('resources.campaigns.fields.insertionUserId')}
        render={
          ({
            insertionUser: {merchantUser: {firstName, lastName} = {} as Campaigns} = {},
          }: any) => (
            <Typography variant="body1">{firstName} {lastName}</Typography>
          )}
      />
      <ReferenceField
        label={translate('resources.campaigns.fields.channelId')}
        link={false}
        reference="channels"
        source="channelId"
      >
        <TextField source="name" variant="body1"/>
      </ReferenceField>
      <ReferenceField
        label={translate('resources.campaigns.fields.chatMessageTemplateId')}
        link="show"
        reference="chat_message_templates"
        source="chatMessageTemplateId"
        // @ts-ignore
        variant="body1"
      >
        <TextField source="name"/>
      </ReferenceField>
      <FunctionField<Campaigns>
        label={translate('resources.campaigns.show.templatePreview.label')}
        render={({chatMessageTemplateId} = {} as Campaigns) => (
          <ChatMessageTemplatePreview chatMessageTemplateId={chatMessageTemplateId} />
        )}
      />
      <ShowToolbar {...props as any} />
    </SimpleShowLayout>
  )
}

const CampaignMessagesCount = props => {
  const {record: {id, isDeleted, status: previousStatus}} = props
  const styles = useStyles()
  const refresh = useRefresh()
  const translate = useTranslate()
  const {
    data: {
      campaigns_by_pk: {
        deliveredMessageCount = 0,
        readMessageCount = 0,
        status = null,
        totalMessageCount = 1,
      } = {},
    } = {},
    networkStatus,
    refetch,
  } = useQuery<QueryRoot['campaigns_by_pk']>(
    CAMPAIGNS_MESSAGES_COUNT_QUERY,
    {notifyOnNetworkStatusChange: true, variables: {id}}
  )
  // Refetch only the message counts at an interval
  useEffect(() => {
    if (isDeleted || previousStatus === 'COMPLETED') return
    const intervalId = setInterval(() => refetch(), 60_000)
    return () => clearInterval(intervalId)
  }, [isDeleted, previousStatus, refetch])
  // Refresh entire view when the status changes; this is to make sure
  // other fields outside this component are only updated if status updates.
  useEffect(() => {
    if (!!status && status !== previousStatus) refresh()
  }, [previousStatus, refresh, status])
  return networkStatus === NetworkStatus.loading ? (
    <Box display="flex" flexDirection="column" gridGap=".5rem" paddingY="1rem">
      {[...Array(3).keys()]
        .map(i => <Skeleton height="1rem" key={i} variant="rect" />)}
    </Box>
  ) : (
    <Box className={styles.performance} display="flex" justifyContent="space-between">
      {[
        ['totalMessageCount', totalMessageCount],
        [
          'deliveredMessageCount',
          deliveredMessageCount,
          (deliveredMessageCount / totalMessageCount),
        ],
        [
          'readMessageCount',
          readMessageCount,
          (readMessageCount / deliveredMessageCount),
        ],
      ].map(([fieldName, value, rate]) => (
        <div key={fieldName}>
          <Typography variant="body1">
            {translate(`resources.campaigns.fields.${fieldName}`)}
          </Typography>
          <Typography variant="h2">{value}
          </Typography>
          {isNumber(rate) && (
            <Typography className={styles.performanceRate} variant="caption">
              <ArrowRightIcon size="inherit" />
              {isFinite(rate) ? round(rate * 100, 2) : 0}%
            </Typography>
          )}
        </div>
      ))}
    </Box>
  )
}

const CampaignTimestampField = props => {
  const translate = useTranslate()
  const record = useRecordContext<Campaigns>(props)
  const label = translate(`resources.campaigns.show.fields.${
    (
      record?.scheduledTimestamp &&
      (new Date(record.scheduledTimestamp) > new Date())
    ) ? 'scheduledFor' : 'sentOn'
  }`)
  return (
    <Labeled label={label}>
      <DateField
        addLabel={false}
        showTime
        source={record?.scheduledTimestamp ? "scheduledTimestamp" : "insertionTimestamp"}
        variant="body1"
      />
    </Labeled>
  )
}

const ShowToolbar = ({id, resource}) => {
  const {record: {name}} = useShowContext<any>()
  return (
    <Toolbar>
      <DeleteWithConfirmButton
        confirmContent={useTranslate()('resources.campaigns.show.confirmDelete')}
        confirmTitle={name}
        onConfirmDelete={useSoftDelete({id, resource})}
        resource={resource}
      />
    </Toolbar>
  )
}

const CampaignsShowHeader: FC<{data?: {name: string}}> = ({
  data: {name} = {},
}) => (
  <ShowHeader title={name} />
)

const CampaignMessagesMetadataExportButton = ({...props}) => {
  const {record: campaign} = props
  const {getFilesApiUrl} = useContext(ApiUrlsContext)
  const translate = useTranslate()
  const [
    exportCampaignMessageMetadata, {loading: isLoading},
  ] = useMutation<MutationRoot['exportCampaignMessagesMetadata']>(
    EXPORT_CAMPAIGN_MESSAGES_METADATA,
    {
      onCompleted({exportCampaignMessagesMetadata: {filename, key}}: any) {
        window.open(getFilesApiUrl(`${key}/${filename}`), '_self')
      },
      variables: {campaignId: campaign?.id},
    }
  )
  return (
    <Tooltip
      title={
        campaign.status !== 'COMPLETED' ? translate(
          'resources.campaigns.show.exportMessageMetadata.errors.' +
          `${{PENDING: 'PENDING_CAMPAIGN', SENDING: 'ONGOING_CAMPAIGN'}[campaign.status]}`
        ) : ''
      }
    >
      <div>
        <Button
          disabled={isLoading || campaign.status !== 'COMPLETED'}
          onClick={() => exportCampaignMessageMetadata()}
          startIcon={
            <DownloadIcon style={{visibility: isLoading ? 'hidden' : 'visible'}} />
          }
          variant="outlined"
        >
          <Box visibility={isLoading ? 'hidden' : 'visible'}>
            {translate('ra.action.export')}
          </Box>
          {isLoading && (
            <Box position="absolute"><CircularProgress size="18px" /></Box>
          )}
        </Button>
      </div>
    </Tooltip>
  )
}

const CampaignsList = props => {
  const {isSandbox} = useContext(SandboxContext)
  const isDesktop = useIsDesktop()
  useIntervalRefresh(60000)
  const translate = useTranslate()
  const {
    data: {merchant_users: merchantUsers = []} = {},
  } = useQuery<QueryRoot['merchant_users']>(
    MERCHANT_USERS_QUERY, {fetchPolicy: 'cache-first'},
  )
  const filter = useMemo(() => ({isDeleted: false, isListed: true}), [])
  return (
    <ExtendedList
      {...props}
      actionButtons={
        isDesktop && !isSandbox && (
          <Link to="/chat_message_templates/create">
            <Button startIcon={<AddIcon />} variant="outlined">
              {translate(`resources.chat_message_templates.action.create`)}
            </Button>
          </Link>
        )
      }
      bulkActionButtons={false}
      description={
        <Typography color="textSecondary" paragraph>
          {useTranslate()('resources.campaigns.list.description')}
        </Typography>
      }
      filter={filter}
      filters={[
        <FilterLiveSearch
          // @ts-ignore: 'InputProps' does not exist
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="secondary" />
              </InputAdornment>
            ),
            onKeyDown: e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                e.stopPropagation()
              }
            },
          }}
          alwaysOn
          key="name"
          source={"name@_ilike"}
        />,
        <ReferenceInput
          key="channelId"
          label={translate('resources.campaigns.fields.channelId')}
          perPage={100}
          reference="channels"
          sort={{field: 'name', order: 'ASC'}}
          source="channelId"
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>,
        <ReferenceInput
          filter={{isDeleted: false, isListed: true}}
          key="chatMessageTemplateId"
          label={translate('resources.campaigns.fields.chatMessageTemplateId')}
          perPage={100}
          reference="chat_message_templates"
          sort={{field: 'name', order: 'ASC'}}
          source="chatMessageTemplateId"
        >
          <SelectInput optionText="name"/>
        </ReferenceInput>,
        <AutocompleteInput
          choices={merchantUsers.map(m => ({
            id: m.user?.id,
            name: `${m.firstName} ${m.lastName}`,
          }))}
          key="insertionUserId"
          label={translate('resources.campaigns.fields.insertionUserId')}
          optionText="name"
          optionValue="id"
          source="insertionUserId"
        />,
      ]}
      pagination={<ExtendedPagination rowsPerPageOptions={[]} />}
      perPage={25}
      sort={{field: 'insertionTimestamp', order: 'DESC'}}
    >
      <CampaignDatagrid />
    </ExtendedList>
  )
}

const CampaignDatagrid = () => {
  const styles = useStyles()
  const {loading} = useListContext()
  const isDesktop = useIsDesktop()
  if (loading) return <LinearProgress />
  return (
    <Datagrid classes={{table: styles.campaignsTable}}>
      <TextField
        headerClassName={styles.nameListColumnHeader}
        label="Name"
        source="name"
      />
      {isDesktop && (
        <ReferenceField
          headerClassName={styles.chatMessageTemplatesListColumnHeader}
          link={false}
          reference="chat_message_templates"
          source={"chatMessageTemplateId"}
        >
          <TextField source="name" />
        </ReferenceField>
      )}
      <FunctionField<Campaigns>
        render={(r?: Campaigns) => {
          if (!r) return ''
          if (!r.scheduledTimestamp) {
            return new Date(r.insertionTimestamp).toLocaleString()
          }
          if (new Date().getTime() >= new Date(r.scheduledTimestamp).getTime()) {
            return new Date(r.scheduledTimestamp).toLocaleString()
          }
          return <ClockIcon color="secondary" />
        }}
        source="sentTimestamp"
      />
      <ShowButton
        icon={<ChevronRightIcon color="action" />}
        label=""
        // @ts-ignore
        source="showIconButton"
      />
    </Datagrid>
  )
}

const DISTINCT_AGGREGATED_MERCHANT_CUSTOMER_USER_TAGS_QUERY = gql`query{
  aggregated_merchant_customer_user_tags(distinct_on: tagName){tagName}
}`

const MERCHANT_USERS_QUERY = gql`query{
  merchant_users{firstName lastName user{id}}
}`

const CAMPAIGN_INSERT_MUTATION = gql`
  mutation (
    $audienceType: campaign_audience_types_enum!
    $campaignCustomerUsers: [campaign_customer_users_insert_input!]!
    $channelId: uuid!
    $chatMessageTemplateId: uuid!
    $name: String!
    $scheduledTimestamp: timestamptz
  ){
    insert_campaigns_one(
      object: {
        audienceType: $audienceType
        campaignCustomerUsers: {data: $campaignCustomerUsers}
        channelId: $channelId
        chatMessageTemplateId: $chatMessageTemplateId
        name: $name
        scheduledTimestamp: $scheduledTimestamp
      },
    ){id}
  }
`

const EXPORT_CAMPAIGN_MESSAGES_METADATA = gql`
  mutation($campaignId: uuid!){
    exportCampaignMessagesMetadata(campaignId: $campaignId){filename key}
  }
`

const CAMPAIGNS_MESSAGES_COUNT_QUERY = gql`
  query($id: uuid!) {
    campaigns_by_pk(id: $id){
      deliveredMessageCount
      readMessageCount
      status
      totalMessageCount
    }
  }
`

const useStyles = makeStyles(theme => ({
  campaignsTable: {
    [theme.breakpoints.down('md')]: {
      '& th:nth-of-type(1)': {
        width: '50%',
      },
      '& th:nth-of-type(2)': {
        width: theme.remSpacing(12),
      },
      '& th:nth-of-type(3)': {
        width: theme.remSpacing(4),
      },
    },
  },
  chatMessageTemplateEditor: {
    marginBottom: theme.remSpacing(3),
  },
  chatMessageTemplatesListColumnHeader: {
    width: '30%',
  },
  create: {
    [theme.breakpoints.up('lg')]: {
      // eslint-disable-next-line max-len
      // '& .simple-form > .MuiCardContent-root > *:not(.ra-input-campaignCustomerUserIds)': {
      //   maxWidth: '50%',
      // },
      maxWidth: 'unset', // overwrite theme's '50%' value
    },
  },
  createChatMessageTemplateLink: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  customerUserFilterMarketingConsentFilter: {
    marginBottom: 0,
  },
  customerUserListRoot: {
    [theme.breakpoints.up('lg')]: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      border: '1px solid',
      borderRadius: theme.remSpacing(0.75),
      width: '100%',
    },
    '& .MuiTableCell-paddingCheckbox': {
      padding: theme.remSpacing(1),
    },
    '& .MuiTableCell-root:first-child': {
      [theme.breakpoints.up('lg')]: {
        paddingLeft: `${theme.remSpacing(4)} !important`,
      },
    },
    '& .column-aggregated_merchant_customer_user_tags': {
      '& .MuiChip-root': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        marginRight: theme.remSpacing(0.5),
      },
      overflow: 'visible !important',
    },
    padding: 0,
  },
  emptyCampaignList: {
    padding: 0,
  },
  emptyCustomerUserList: {
    '& .MuiTypography-root': {
      margin: 'auto',
    },
    height: '100%',
    padding: theme.remSpacing(3),
    width: theme.remSpacing(110),
  },
  nameListColumnHeader: {
    width: '20%',
  },
  pagination: {
    overflow: 'inherit',
  },
  performance: {
    '& $performanceRate': {
      color: theme.palette.info.main,
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0px 1px 3px ${theme.palette.background.secondary}`,
    marginTop: theme.remSpacing(1),
    padding: theme.remSpacing(2),
  },
  performanceRate: {},
}))

interface MerchantCustomerUserDatagridProps {
  onChange?: (ids: Identifier[]) => void
}

export {
  CampaignCreate,
  CampaignShow,
  CampaignsList,
}
