import {Box, Button, Tooltip, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, ReactNode} from 'react'
import {useResourceContext, useTranslate} from 'react-admin'
import {useHistory, useLocation} from 'react-router-dom'

import {ArrowLeftIcon, InfoIcon} from '../../components/icons'

const ResourceTitle: FC<ResourceTitleProps> = ({description, title}) => {
  const styles = useStyles()
  const resource = useResourceContext()
  const translate = useTranslate()
  const hasBackButton = useLocation().pathname !== `/${resource}`
  const {goBack} = useHistory()
  return (
    <Box alignItems="center" display="flex" justifyContent="start">
      {hasBackButton && (
        <Button className={styles.backButton} onClick={goBack}><ArrowLeftIcon /></Button>
      )}
      <Typography color="primary" variant="h2">
        <Box alignItems="top" display="flex" gridGap="0.5rem">
          <span>{title ?? translate(`resources.${resource}.name`)}</span>
          {description && (
            <Tooltip arrow interactive title={description}>
              <span><InfoIcon /></span>
            </Tooltip>
          )}
        </Box>
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  backButton: {
    '& .MuiButton-label': {
      width: 'fit-content',
    },
    borderRadius: 0,
    borderRight: `1px solid ${theme.palette.disabled.main}`,
    color: theme.palette.primary.main,
    height: theme.remSpacing(6),
    margin: 0,
    marginRight: theme.remSpacing(3),
    minWidth: 'unset',
    padding: 0,
    width: theme.remSpacing(6),
  },
}))

interface ResourceTitleProps {
  description?: ReactNode,
  title?: ReactNode,
}

export default ResourceTitle
