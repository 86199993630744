const REPLY_EXPIRATION_INTERVAL = 24 * 60 * 60

const EMAIL_ADDRESS_REGEX = (/^[a-z0-9._+%-]+@[a-z0-9.-]+[.][a-z]+$/)

const PHONE_NUMBER_REGEX = /^\+?[0-9]{7,}/

const CHAT_MESSAGE_TEMPLATE_TEXT_MAX_LENGTH = 1024

const THREE_SIXTY_CLOUD_API_MAX_FILE_SIZE_BYTES = 5 * 1_048_576 // 5Mb

export {
  CHAT_MESSAGE_TEMPLATE_TEXT_MAX_LENGTH,
  EMAIL_ADDRESS_REGEX,
  PHONE_NUMBER_REGEX,
  REPLY_EXPIRATION_INTERVAL,
  THREE_SIXTY_CLOUD_API_MAX_FILE_SIZE_BYTES,
}
