import {Location} from 'history'
import {createContext, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

const HistoryStackContext = createContext<Location[]>([])

/* Keeps track of and makes available the complete history stack */
const HistoryStackProvider = ({children}) => {
  const history = useHistory()
  const [historyStack, setHistoryStack] = useState([useLocation()])
  useEffect(() => history.listen((location, action) => {
    setHistoryStack(s => ({
      'POP': s.slice(0, -1),
      'PUSH': [...s, location],
      'REPLACE': [...s.slice(0, -1), location],
    }[action]))
  }), [history, setHistoryStack])
  return (
    <HistoryStackContext.Provider value={historyStack}>
      {children}
    </HistoryStackContext.Provider>
  )
}

export default HistoryStackContext

export {HistoryStackProvider}
