import {Box, Link, Tooltip, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {isNil} from 'lodash'
import {FC} from 'react'
import {useTranslate} from 'react-admin'

import {WhatsappMessages, WhatsappMessageStatusesEnum} from '../../../types/graphqlSchema'
import {timeStringFromTimestamp} from '../../../utils/dates'
import {
  DeliveredStatusIcon,
  FailedStatusIcon,
  InfoIcon,
  ReadStatusIcon,
  SendingStatusIcon,
  SentStatusIcon,
} from '../../icons'

const ChatMessageStatusLine: FC<ChatMessageStatusLineProps> = ({
  authorUserDisplayName,
  chatMessageReceivingErrorCode,
  failedWhatsappMessage,
  insertionTimestamp,
  whatsappMessageReceivingErrorCode,
  whatsappMessageStatus,
}) => (
  <div>
    {chatMessageReceivingErrorCode && (
      <ChatMessageReceivingError errorCode={chatMessageReceivingErrorCode} />
    )}
    <WhatsappMessageStatusMessage
      failedWhatsappMessage={failedWhatsappMessage}
      whatsappMessageReceivingErrorCode={whatsappMessageReceivingErrorCode}
      whatsappMessageStatus={whatsappMessageStatus}
    />
    <div className={useStyles().root}>
      {authorUserDisplayName && <span>{authorUserDisplayName}</span>}
      {insertionTimestamp && (
        <span>{timeStringFromTimestamp(insertionTimestamp)}</span>
      )}
      {whatsappMessageStatus && <WhatsappMessageStatus value={whatsappMessageStatus}/>}
    </div>
  </div>
)

const ChatMessageReceivingError: FC<{errorCode: string}> = ({errorCode}) => {
  const translate = useTranslate()
  const canShowDetails = errorCode !== 'UNEXPECTED'
  return (
    <div>
      <Tooltip
        arrow
        interactive
        title={canShowDetails ? (
          <Typography variant="caption">
            {translate(
              `chat_messages.receivingErrorCode.` +
              `${errorCode}`
            )}
          </Typography>
        ) : ''}
      >
        <Typography color="error" variant="caption">
          {canShowDetails && <span><InfoIcon size="small"/></span>}
          {" "}{translate('chat.chatMessageBubble.receivingErrorNotice.generalMessage')}
        </Typography>
      </Tooltip>
    </div>
  )
}

const WhatsappMessageStatusMessage = ({
  failedWhatsappMessage,
  whatsappMessageReceivingErrorCode,
  whatsappMessageStatus,
}) => {
  const translate = useTranslate()
  return (
    <div>
      {!isNil(whatsappMessageReceivingErrorCode) && (
        <WhatsappMessageReceivedError errorCode={whatsappMessageReceivingErrorCode} />
      )}
      {whatsappMessageStatus === 'FAILED' && (
        <WhatsappMessageLastSendAttemptError
          errorCode={failedWhatsappMessage?.lastSendAttemptErrorCode}
          errorDetails={
            failedWhatsappMessage?.lastSendAttemptErrorDetails ??
            translate(
              'whatsapp_messages.lastSendAttemptErrorCodeToCustomDetails.' +
              `${failedWhatsappMessage?.lastSendAttemptErrorCode}`,
              {_: ''}
            )
          }
        />
      )}
    </div>
  )
}

const WhatsappMessageStatus: FC<WhatsappMessageStatusProps> = ({value}) => {
  const Icon = WHATSAPP_MESSAGE_STATUS_TO_ICON[value] ?? null
  return Icon && <Icon color={value === 'READ' ? 'info' : 'inherit'} size="inherit" />
}

const WhatsappMessageLastSendAttemptError: FC<
  WhatsappMessageLastSendAttemptErrorProps
> = ({errorCode, errorDetails}) => {
  const translate = useTranslate()
  return (
    <WhatsappMessageAlert
      details={errorDetails}
      message={translate(
        `whatsapp_messages.lastSendAttemptErrorCode.${errorCode}`,
        {
          _: translate(
            'chat.chatMessageBubble.whatsappMessageStatus.error.failedToSend',
            {errorCode: errorCode ?? 'null'},
          ),
        },
      )}
      severity="error"
    />
  )
}

const WhatsappMessageReceivedError: FC<WhatsappMessageReceivedErrorProps> = ({
  errorCode,
}) => {
  const translate = useTranslate()
  return (
    <WhatsappMessageAlert
      message={translate(
        `whatsapp_messages.receivingErrorCode.${errorCode}`,
        {
          _: translate(
            'chat.chatMessageBubble.whatsappMessageStatus.error.couldNotReceiveMessage',
            {errorCode},
          ),
        },
      )}
      severity="error"
    />
  )
}

const WhatsappMessageAlert: FC<WhatsappMessageAlertProps> = ({
  details, message, severity,
}) => {
  const translate = useTranslate()
  return (
    <div className={useStyles().whatsappMessageAlert}>
      <Tooltip
        arrow
        interactive
        title={details ? (
          <Box display="flex" flexDirection="column" gridGap="0.5rem">
            <span>{details}</span>
            {
              <Link
                href="https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes"
                target="_blank"
              >
                {translate('actions.moreInfo')}
              </Link>
            }
          </Box>
        ) : ''}
      >
        <Typography
          color={SEVERITY_TO_TYPOGRAPHY_COLOR[severity] as any}
          variant="caption"
        >
          {details && <span><InfoIcon size="small" /></span>}{" "}{message}
        </Typography>
      </Tooltip>
    </div>
  )
}

const SEVERITY_TO_TYPOGRAPHY_COLOR = {
  'error': 'error',
  'info': 'secondary',
}

const WHATSAPP_MESSAGE_STATUS_TO_ICON = {
  'DELIVERED': DeliveredStatusIcon,
  'FAILED': FailedStatusIcon,
  'READ': ReadStatusIcon,
  'SENDING': SendingStatusIcon,
  'SENT': SentStatusIcon,
}

const useStyles = makeStyles(theme => ({
  cancelChatMessage: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: `${theme.palette.error.dark} !important`,
    cursor: 'pointer',
  },
  root: {
    '& span': {
      color: theme.palette.text.secondary,
      display: 'inline-block',
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.caption.fontWeight,
      marginTop: theme.typography.pxToRem(2),
    },
    '& span:not(:last-child)': {
      marginRight: theme.remSpacing(0.5),
    },
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexBasis: '100%',
    flexGrow: 0,
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    margin: 0,
    marginTop: theme.typography.pxToRem(3),
    maxWidth: '100%',
    padding: 0,
    width: 'calc(100% + 8px)',
  },
  sendChatMessage: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: `${theme.palette.info.main} !important`,
    cursor: 'pointer',
  },
  whatsappMessageAlert: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: theme.remSpacing(0.5),
    marginTop: theme.remSpacing(0.5),
    minWidth: 0,
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  },
}))

interface ChatMessageStatusLineProps {
  authorUserDisplayName: string | undefined
  chatMessageReceivingErrorCode: string | undefined
  failedWhatsappMessage: WhatsappMessages | undefined
  insertionTimestamp: string | undefined,
  whatsappMessageReceivingErrorCode: number | undefined
  whatsappMessageStatus: WhatsappMessageStatusesEnum | undefined
}

interface WhatsappMessageStatusProps {
  value: WhatsappMessageStatusesEnum
}

interface WhatsappMessageAlertProps {
  details?: string
  message: string
  severity: 'info' | 'error'
}

interface WhatsappMessageReceivedErrorProps {
  errorCode: string | number | undefined
}

interface WhatsappMessageLastSendAttemptErrorProps {
  errorCode: string | number | undefined
  errorDetails?: string
}

export default ChatMessageStatusLine
