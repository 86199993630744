import {
  Button, ButtonProps, CircularProgress, Grid, IconButton, InputLabel,
  MenuItem, OutlinedInput, OutlinedInputProps, Select, SelectProps, Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Visibility, VisibilityOff} from '@material-ui/icons'
import {FC, ReactNode, ReactNodeArray, useState} from 'react'
import {useTranslate} from 'react-admin'
import {
  Field as FinalField,
  Form as FinalForm,
  FormProps as FinalFormProps, UseFieldConfig,
  useFormState,
} from 'react-final-form'

import {CheckIcon, CloseIcon} from '../components/icons'
import Dot from './Dot'

const Form: FC<ExtendedFinalFormProps> = ({
  children, className, submit, validate,
}) => {
  const styles = useStyles()
  return (
    <FinalForm
      onSubmit={submit}
      render={({handleSubmit}) => (
        <form className={`${styles.form} ${className ?? ''}`} onSubmit={handleSubmit}>
          <Grid
            alignItems="stretch"
            container
            direction="column"
            justifyContent="center"
            spacing={3}
            wrap="nowrap"
          >
            {children}
          </Grid>
        </form>
      )}
      validate={validate}
    />
  )
}

const PasswordField: FC<OutlinedInputProps> = props => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  return (
    <TextField
      endAdornment={(
        <IconButton onClick={() => setIsPasswordVisible(s => !s)}>
          {isPasswordVisible ? <Visibility/> : <VisibilityOff/>}
        </IconButton>
      )}
      name="password"
      type={isPasswordVisible ? 'text' : 'password'}
      {...props}
    />
  )
}

const PasswordFieldError = () => {
  const styles = useStyles()
  const translate = useTranslate()
  const {modified = {}, touched = {}, values: {password = ''} = {}} = useFormState({
    subscription: {modified: true, touched: true, values: true},
  })
  const validations = {
    digit: {
      label: translate('validations.password.digit'),
      valid: (/\d/).test(password),
    },
    length: {
      label: translate('validations.password.length'),
      valid: password.length >= 8,
    },
    lowercase: {
      label: translate('validations.password.lowercase'),
      valid: (/\p{Ll}/u).test(password),
    },
    uppercase: {
      label: translate('validations.password.uppercase'),
      valid: (/\p{Lu}/u).test(password),
    },
  }
  return (
    <Grid className={styles.passwordFieldErrors} container>
      {Object.values(validations).map((error, i) => (
        <Grid container item key={i} xs={6}>
          {(modified.password || touched.password) ?
            (
              error.valid ? (
                <CheckIcon
                  className={styles.passwordErrorIcon} color="success" size="small"
                />
              ) : (
                <CloseIcon
                  className={styles.passwordErrorIcon} color="error" size="small"
                />
              )
            ) : <Dot className={styles.passwordErrorDot} />
          }
          <Typography color="textSecondary" variant="caption">
            {error.label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}
/* eslint-disable @typescript-eslint/comma-dangle */
const TextField = <FieldValue, >({
  endAdornment, label, name, parse, placeholder, type,
}: OutlinedInputProps & UseFieldConfig<FieldValue>) => {
  const styles = useStyles()
  return (
    <FinalField<FieldValue>
      name={name ?? ''}
      parse={parse}
      render={({input, meta}) => (
        <div>
          <InputLabel htmlFor={name}>
            <Typography color="textPrimary">{label}</Typography>
          </InputLabel>
          <OutlinedInput
            endAdornment={endAdornment}
            error={meta.touched && meta.error}
            fullWidth
            placeholder={placeholder}
            {...input}
          />
          {meta.touched && meta.error && (
            <>
              <Dot className={styles.dot}/>
              <Typography color="error" variant="caption">
                {meta.error}
              </Typography>
            </>
          )}
        </div>
      )}
      type={type}
    />
  )
}

const SelectField: FC<ExtendedSelectProps> = ({label, name, options, placeholder}) => {
  const styles = useStyles()
  return (
    <FinalField
      name={name ?? ''}
      render={({input, meta}) => (
        <div>
          <InputLabel htmlFor={name}>
            <Typography color="textPrimary">{label}</Typography>
          </InputLabel>
          <Select disableUnderline displayEmpty {...input}>
            <MenuItem disabled value="">{placeholder}</MenuItem>
            {options.map(({label, value}) => (
              <MenuItem key={label} value={value}>{label}</MenuItem>
            ))}
          </Select>
          {meta.touched && meta.error && (
            <>
              <Dot className={styles.dot}/>
              <Typography color="error" variant="caption">
                {meta.error}
              </Typography>
            </>
          )}
        </div>
      )}
    />
  )
}

const SubmitButton: FC<ButtonProps> = props => {
  const {submitting} = useFormState()
  const styles = useStyles()
  return (
    <Button
      className={styles.submitButton}
      color="primary"
      disabled={submitting}
      fullWidth
      type="submit"
      variant="contained"
      {...props}
    >
      {submitting ? <CircularProgress size={20}/> : props.children}
    </Button>
  )
}

const SubmitErrorMessage = () => {
  const {submitError, submitting} = useFormState()
  return !submitting && submitError ? (
    <Typography align="center" color="error">{submitError}</Typography>
  ) : null
}

const useStyles = makeStyles(theme => ({
  dot: {
    backgroundColor: theme.palette.error.main,
    height: '4px',
    margin: theme.remSpacing(1),
    verticalAlign: 'middle',
    width: '4px',
  },
  form: {
    '& .MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiInputBase-root': {
      borderRadius: theme.remSpacing(1),
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.remSpacing(1),
      paddingLeft: theme.remSpacing(1),
    },
    '& .MuiSelect-root': {
      borderRadius: theme.remSpacing(1),
      paddingLeft: theme.remSpacing(2),
    },
    '& .MuiTypography-root': {
      verticalAlign: 'baseline',
    },
    '& > .MuiGrid-root': {
      height: '100%',
      margin: 'auto',
      width: theme.remSpacing(56),
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    height: '100%',
    marginLeft: theme.remSpacing(13),
    marginRight: theme.remSpacing(13),
    [theme.breakpoints.down('md')]: {
      '& .MuiGrid-spacing-xs-3': {
        margin: 0,
        width: '100%',
      },
      marginLeft: theme.remSpacing(5),
      marginRight: theme.remSpacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.remSpacing(3),
      marginRight: theme.remSpacing(3),
    },
  },
  passwordErrorDot: {
    backgroundColor: theme.palette.secondary.main,
    height: '4px',
    margin: theme.remSpacing(0.75),
    verticalAlign: 'middle',
    width: '4px',
  },
  passwordErrorIcon: {
    fontSize: theme.typography.caption.fontSize,
    marginRight: '4px',
    marginTop: '1px',
  },
  passwordFieldErrors: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.remSpacing(1.5),
    rowGap: theme.remSpacing(0.5),
    width: '100%',
  },
  submitButton: {
    border: 'none',
    margin: 0,
  },
}))

interface ExtendedFinalFormProps {
  children: ReactNode | ReactNodeArray,
  className?: string,
  submit: FinalFormProps['onSubmit'],
  validate: FinalFormProps['validate'],
}

interface ExtendedSelectProps extends SelectProps {
  options: {label: string, value: string}[],
}

export {
  Form, PasswordField, PasswordFieldError, SelectField,
  SubmitButton, SubmitErrorMessage, TextField,
}
