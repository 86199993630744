export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Void: any;
  date: any;
  jsonb: any;
  numeric: any;
  time: any;
  timestamp: any;
  timestamptz: string;
  uuid: string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Chat = {
  channelId?: InputMaybe<Scalars['uuid']>;
  chatId?: InputMaybe<Scalars['uuid']>;
  customerUserWhatsappPhoneNumber?: InputMaybe<Scalars['String']>;
  merchantWhatsappPhoneNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owningUserId?: InputMaybe<Scalars['uuid']>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
};

export type ChatMessageFileAttachment = {
  contentHex: Scalars['String'];
  filename: Scalars['String'];
  mimeType: Scalars['String'];
};

export type ChatMessageTemplateValue = {
  chatMessageTemplatePlaceholderId: Scalars['uuid'];
  value: Scalars['String'];
};

export type ChatResult = {
  __typename?: 'ChatResult';
  id: Scalars['uuid'];
};

export type ChatStart = {
  __typename?: 'ChatStart';
  chat?: Maybe<Chats>;
  chatId: Scalars['uuid'];
};

export type ChatSummary = {
  __typename?: 'ChatSummary';
  chatSummary?: Maybe<ChatSummaries>;
  chatSummaryId: Scalars['uuid'];
};

export type ExportCampaignMessagesMetadataResult = {
  __typename?: 'ExportCampaignMessagesMetadataResult';
  filename: Scalars['String'];
  key: Scalars['String'];
};

export type InsertChatMessageResult = {
  __typename?: 'InsertChatMessageResult';
  chatMessage?: Maybe<ChatMessages>;
  chatMessageId: Scalars['uuid'];
};

export type InsertChatMessageTemplate = {
  __typename?: 'InsertChatMessageTemplate';
  chatMessageTemplate?: Maybe<ChatMessageTemplates>;
  chatMessageTemplateId: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type MerchantCustomerUsersImport = {
  __typename?: 'MerchantCustomerUsersImport';
  importedMerchantCustomerUsersCount: Scalars['Int'];
  invalidRowsCount: Scalars['Int'];
  rowFailureReasons: Array<Maybe<Scalars['String']>>;
};

export type MerchantUser = {
  __typename?: 'MerchantUser';
  id: Scalars['uuid'];
  merchantUser?: Maybe<MerchantUsers>;
};

export type OauthTokens = {
  __typename?: 'OauthTokens';
  accessToken: Scalars['String'];
  expiration: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RestoreMerchantUserResult = {
  __typename?: 'RestoreMerchantUserResult';
  isRestored: Scalars['Boolean'];
};

export type SalesforceAuthentication = {
  __typename?: 'SalesforceAuthentication';
  merchant?: Maybe<Merchants>;
  merchantId: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringArrayComparisonExp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type ThreeSixtyWhatsappAccount = {
  __typename?: 'ThreeSixtyWhatsappAccount';
  whatsappAccount?: Maybe<WhatsappAccounts>;
  whatsappAccountId: Scalars['uuid'];
};

export type TranslatedText = {
  __typename?: 'TranslatedText';
  text: Scalars['String'];
};

export type UploadWhatsappBusinessProfilePhotoResult = {
  __typename?: 'UploadWhatsappBusinessProfilePhotoResult';
  merchant?: Maybe<Merchants>;
  merchantId?: Maybe<Scalars['uuid']>;
  whatsappAccount?: Maybe<WhatsappAccounts>;
  whatsappAccountId?: Maybe<Scalars['uuid']>;
};

export type VonageWhatsappAccount = {
  __typename?: 'VonageWhatsappAccount';
  whatsappAccount?: Maybe<WhatsappAccounts>;
  whatsappAccountId: Scalars['uuid'];
};

export type WhatsappAccount = {
  __typename?: 'WhatsappAccount';
  id: Scalars['uuid'];
  whatsappAccount?: Maybe<WhatsappAccounts>;
};

export type WhatsappAccountConnection = {
  __typename?: 'WhatsappAccountConnection';
  status: WhatsappAccountConnectionStatus;
  webhookHostname: Scalars['String'];
};

export enum WhatsappAccountConnectionStatus {
  Connected = 'CONNECTED',
  ConnectedToDifferentEnvironment = 'CONNECTED_TO_DIFFERENT_ENVIRONMENT',
  Disconnected = 'DISCONNECTED',
  NotReadyYet = 'NOT_READY_YET'
}

/** columns and relationships of "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTags = {
  __typename?: 'aggregated_merchant_customer_user_tags';
  /** An object relationship */
  merchantCustomerUser?: Maybe<MerchantCustomerUsers>;
  merchantCustomerUserId?: Maybe<Scalars['uuid']>;
  tagName?: Maybe<Scalars['String']>;
  tagType?: Maybe<Scalars['String']>;
};

/** aggregated selection of "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTagsAggregate = {
  __typename?: 'aggregated_merchant_customer_user_tags_aggregate';
  aggregate?: Maybe<AggregatedMerchantCustomerUserTagsAggregateFields>;
  nodes: Array<AggregatedMerchantCustomerUserTags>;
};

export type AggregatedMerchantCustomerUserTagsAggregateBoolExp = {
  count?: InputMaybe<AggregatedMerchantCustomerUserTagsAggregateBoolExpCount>;
};

export type AggregatedMerchantCustomerUserTagsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTagsAggregateFields = {
  __typename?: 'aggregated_merchant_customer_user_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AggregatedMerchantCustomerUserTagsMaxFields>;
  min?: Maybe<AggregatedMerchantCustomerUserTagsMinFields>;
};


/** aggregate fields of "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTagsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTagsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AggregatedMerchantCustomerUserTagsMaxOrderBy>;
  min?: InputMaybe<AggregatedMerchantCustomerUserTagsMinOrderBy>;
};

/** input type for inserting array relation for remote table "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTagsArrRelInsertInput = {
  data: Array<AggregatedMerchantCustomerUserTagsInsertInput>;
};

/** Boolean expression to filter rows from the table "aggregated_merchant_customer_user_tags". All fields are combined with a logical 'AND'. */
export type AggregatedMerchantCustomerUserTagsBoolExp = {
  _and?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsBoolExp>>;
  _not?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
  _or?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsBoolExp>>;
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersBoolExp>;
  merchantCustomerUserId?: InputMaybe<UuidComparisonExp>;
  tagName?: InputMaybe<StringComparisonExp>;
  tagType?: InputMaybe<StringComparisonExp>;
};

/** input type for inserting data into table "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTagsInsertInput = {
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersObjRelInsertInput>;
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
  tagName?: InputMaybe<Scalars['String']>;
  tagType?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AggregatedMerchantCustomerUserTagsMaxFields = {
  __typename?: 'aggregated_merchant_customer_user_tags_max_fields';
  merchantCustomerUserId?: Maybe<Scalars['uuid']>;
  tagName?: Maybe<Scalars['String']>;
  tagType?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTagsMaxOrderBy = {
  merchantCustomerUserId?: InputMaybe<OrderBy>;
  tagName?: InputMaybe<OrderBy>;
  tagType?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AggregatedMerchantCustomerUserTagsMinFields = {
  __typename?: 'aggregated_merchant_customer_user_tags_min_fields';
  merchantCustomerUserId?: Maybe<Scalars['uuid']>;
  tagName?: Maybe<Scalars['String']>;
  tagType?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTagsMinOrderBy = {
  merchantCustomerUserId?: InputMaybe<OrderBy>;
  tagName?: InputMaybe<OrderBy>;
  tagType?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "aggregated_merchant_customer_user_tags". */
export type AggregatedMerchantCustomerUserTagsOrderBy = {
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersOrderBy>;
  merchantCustomerUserId?: InputMaybe<OrderBy>;
  tagName?: InputMaybe<OrderBy>;
  tagType?: InputMaybe<OrderBy>;
};

/** select columns of table "aggregated_merchant_customer_user_tags" */
export enum AggregatedMerchantCustomerUserTagsSelectColumn {
  /** column name */
  MerchantCustomerUserId = 'merchantCustomerUserId',
  /** column name */
  TagName = 'tagName',
  /** column name */
  TagType = 'tagType'
}

/** Streaming cursor of the table "aggregated_merchant_customer_user_tags" */
export type AggregatedMerchantCustomerUserTagsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AggregatedMerchantCustomerUserTagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AggregatedMerchantCustomerUserTagsStreamCursorValueInput = {
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
  tagName?: InputMaybe<Scalars['String']>;
  tagType?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "campaign_audience_types" */
export type CampaignAudienceTypes = {
  __typename?: 'campaign_audience_types';
  type: Scalars['String'];
};

/** aggregated selection of "campaign_audience_types" */
export type CampaignAudienceTypesAggregate = {
  __typename?: 'campaign_audience_types_aggregate';
  aggregate?: Maybe<CampaignAudienceTypesAggregateFields>;
  nodes: Array<CampaignAudienceTypes>;
};

/** aggregate fields of "campaign_audience_types" */
export type CampaignAudienceTypesAggregateFields = {
  __typename?: 'campaign_audience_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CampaignAudienceTypesMaxFields>;
  min?: Maybe<CampaignAudienceTypesMinFields>;
};


/** aggregate fields of "campaign_audience_types" */
export type CampaignAudienceTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CampaignAudienceTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "campaign_audience_types". All fields are combined with a logical 'AND'. */
export type CampaignAudienceTypesBoolExp = {
  _and?: InputMaybe<Array<CampaignAudienceTypesBoolExp>>;
  _not?: InputMaybe<CampaignAudienceTypesBoolExp>;
  _or?: InputMaybe<Array<CampaignAudienceTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "campaign_audience_types" */
export enum CampaignAudienceTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  CampaignAudienceTypesPkey = 'campaign_audience_types_pkey'
}

export enum CampaignAudienceTypesEnum {
  All = 'ALL',
  AllWithMarketingOptIn = 'ALL_WITH_MARKETING_OPT_IN',
  SelectedCustomerUsers = 'SELECTED_CUSTOMER_USERS'
}

/** Boolean expression to compare columns of type "campaign_audience_types_enum". All fields are combined with logical 'AND'. */
export type CampaignAudienceTypesEnumComparisonExp = {
  _eq?: InputMaybe<CampaignAudienceTypesEnum>;
  _in?: InputMaybe<Array<CampaignAudienceTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CampaignAudienceTypesEnum>;
  _nin?: InputMaybe<Array<CampaignAudienceTypesEnum>>;
};

/** input type for inserting data into table "campaign_audience_types" */
export type CampaignAudienceTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CampaignAudienceTypesMaxFields = {
  __typename?: 'campaign_audience_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CampaignAudienceTypesMinFields = {
  __typename?: 'campaign_audience_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "campaign_audience_types" */
export type CampaignAudienceTypesMutationResponse = {
  __typename?: 'campaign_audience_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CampaignAudienceTypes>;
};

/** input type for inserting object relation for remote table "campaign_audience_types" */
export type CampaignAudienceTypesObjRelInsertInput = {
  data: CampaignAudienceTypesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CampaignAudienceTypesOnConflict>;
};

/** on_conflict condition type for table "campaign_audience_types" */
export type CampaignAudienceTypesOnConflict = {
  constraint: CampaignAudienceTypesConstraint;
  update_columns?: Array<CampaignAudienceTypesUpdateColumn>;
  where?: InputMaybe<CampaignAudienceTypesBoolExp>;
};

/** Ordering options when selecting data from "campaign_audience_types". */
export type CampaignAudienceTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: campaign_audience_types */
export type CampaignAudienceTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "campaign_audience_types" */
export enum CampaignAudienceTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "campaign_audience_types" */
export type CampaignAudienceTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "campaign_audience_types" */
export type CampaignAudienceTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CampaignAudienceTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CampaignAudienceTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "campaign_audience_types" */
export enum CampaignAudienceTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type CampaignAudienceTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CampaignAudienceTypesSetInput>;
  /** filter the rows which have to be updated */
  where: CampaignAudienceTypesBoolExp;
};

/** columns and relationships of "campaign_customer_users" */
export type CampaignCustomerUsers = {
  __typename?: 'campaign_customer_users';
  /** An object relationship */
  campaign: Campaigns;
  campaignId: Scalars['uuid'];
  /** An object relationship */
  customerUser: CustomerUsers;
  customerUserId: Scalars['uuid'];
};

/** aggregated selection of "campaign_customer_users" */
export type CampaignCustomerUsersAggregate = {
  __typename?: 'campaign_customer_users_aggregate';
  aggregate?: Maybe<CampaignCustomerUsersAggregateFields>;
  nodes: Array<CampaignCustomerUsers>;
};

export type CampaignCustomerUsersAggregateBoolExp = {
  count?: InputMaybe<CampaignCustomerUsersAggregateBoolExpCount>;
};

export type CampaignCustomerUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<CampaignCustomerUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "campaign_customer_users" */
export type CampaignCustomerUsersAggregateFields = {
  __typename?: 'campaign_customer_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CampaignCustomerUsersMaxFields>;
  min?: Maybe<CampaignCustomerUsersMinFields>;
};


/** aggregate fields of "campaign_customer_users" */
export type CampaignCustomerUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "campaign_customer_users" */
export type CampaignCustomerUsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CampaignCustomerUsersMaxOrderBy>;
  min?: InputMaybe<CampaignCustomerUsersMinOrderBy>;
};

/** input type for inserting array relation for remote table "campaign_customer_users" */
export type CampaignCustomerUsersArrRelInsertInput = {
  data: Array<CampaignCustomerUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CampaignCustomerUsersOnConflict>;
};

/** Boolean expression to filter rows from the table "campaign_customer_users". All fields are combined with a logical 'AND'. */
export type CampaignCustomerUsersBoolExp = {
  _and?: InputMaybe<Array<CampaignCustomerUsersBoolExp>>;
  _not?: InputMaybe<CampaignCustomerUsersBoolExp>;
  _or?: InputMaybe<Array<CampaignCustomerUsersBoolExp>>;
  campaign?: InputMaybe<CampaignsBoolExp>;
  campaignId?: InputMaybe<UuidComparisonExp>;
  customerUser?: InputMaybe<CustomerUsersBoolExp>;
  customerUserId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "campaign_customer_users" */
export enum CampaignCustomerUsersConstraint {
  /** unique or primary key constraint on columns "customer_user_id", "campaign_id" */
  CampaignCustomerUsersPkey = 'campaign_customer_users_pkey'
}

/** input type for inserting data into table "campaign_customer_users" */
export type CampaignCustomerUsersInsertInput = {
  campaign?: InputMaybe<CampaignsObjRelInsertInput>;
  campaignId?: InputMaybe<Scalars['uuid']>;
  customerUser?: InputMaybe<CustomerUsersObjRelInsertInput>;
  customerUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CampaignCustomerUsersMaxFields = {
  __typename?: 'campaign_customer_users_max_fields';
  campaignId?: Maybe<Scalars['uuid']>;
  customerUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "campaign_customer_users" */
export type CampaignCustomerUsersMaxOrderBy = {
  campaignId?: InputMaybe<OrderBy>;
  customerUserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CampaignCustomerUsersMinFields = {
  __typename?: 'campaign_customer_users_min_fields';
  campaignId?: Maybe<Scalars['uuid']>;
  customerUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "campaign_customer_users" */
export type CampaignCustomerUsersMinOrderBy = {
  campaignId?: InputMaybe<OrderBy>;
  customerUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "campaign_customer_users" */
export type CampaignCustomerUsersMutationResponse = {
  __typename?: 'campaign_customer_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CampaignCustomerUsers>;
};

/** on_conflict condition type for table "campaign_customer_users" */
export type CampaignCustomerUsersOnConflict = {
  constraint: CampaignCustomerUsersConstraint;
  update_columns?: Array<CampaignCustomerUsersUpdateColumn>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};

/** Ordering options when selecting data from "campaign_customer_users". */
export type CampaignCustomerUsersOrderBy = {
  campaign?: InputMaybe<CampaignsOrderBy>;
  campaignId?: InputMaybe<OrderBy>;
  customerUser?: InputMaybe<CustomerUsersOrderBy>;
  customerUserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: campaign_customer_users */
export type CampaignCustomerUsersPkColumnsInput = {
  campaignId: Scalars['uuid'];
  customerUserId: Scalars['uuid'];
};

/** select columns of table "campaign_customer_users" */
export enum CampaignCustomerUsersSelectColumn {
  /** column name */
  CampaignId = 'campaignId',
  /** column name */
  CustomerUserId = 'customerUserId'
}

/** input type for updating data in table "campaign_customer_users" */
export type CampaignCustomerUsersSetInput = {
  campaignId?: InputMaybe<Scalars['uuid']>;
  customerUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "campaign_customer_users" */
export type CampaignCustomerUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CampaignCustomerUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CampaignCustomerUsersStreamCursorValueInput = {
  campaignId?: InputMaybe<Scalars['uuid']>;
  customerUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "campaign_customer_users" */
export enum CampaignCustomerUsersUpdateColumn {
  /** column name */
  CampaignId = 'campaignId',
  /** column name */
  CustomerUserId = 'customerUserId'
}

export type CampaignCustomerUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CampaignCustomerUsersSetInput>;
  /** filter the rows which have to be updated */
  where: CampaignCustomerUsersBoolExp;
};

/** columns and relationships of "campaigns" */
export type Campaigns = {
  __typename?: 'campaigns';
  audienceType: CampaignAudienceTypesEnum;
  /** An object relationship */
  campaignAudienceType: CampaignAudienceTypes;
  /** An array relationship */
  campaignCustomerUsers: Array<CampaignCustomerUsers>;
  /** An aggregate relationship */
  campaignCustomerUsers_aggregate: CampaignCustomerUsersAggregate;
  /** An object relationship */
  channel: Channels;
  channelId: Scalars['uuid'];
  /** An object relationship */
  chatMessageTemplate: ChatMessageTemplates;
  chatMessageTemplateId: Scalars['uuid'];
  /** An array relationship */
  chatMessages: Array<ChatMessages>;
  /** An aggregate relationship */
  chatMessages_aggregate: ChatMessagesAggregate;
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  insertionTimestamp: Scalars['timestamptz'];
  /** An object relationship */
  insertionUser: Users;
  insertionUserId: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  isListed: Scalars['Boolean'];
  name: Scalars['String'];
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  scheduledTimestamp?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "campaigns_status" */
  status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "campaigns" */
export type CampaignsCampaignCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignCustomerUsersOrderBy>>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};


/** columns and relationships of "campaigns" */
export type CampaignsCampaignCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignCustomerUsersOrderBy>>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};


/** columns and relationships of "campaigns" */
export type CampaignsChatMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


/** columns and relationships of "campaigns" */
export type CampaignsChatMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};

/** aggregated selection of "campaigns" */
export type CampaignsAggregate = {
  __typename?: 'campaigns_aggregate';
  aggregate?: Maybe<CampaignsAggregateFields>;
  nodes: Array<Campaigns>;
};

/** aggregate fields of "campaigns" */
export type CampaignsAggregateFields = {
  __typename?: 'campaigns_aggregate_fields';
  avg?: Maybe<CampaignsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CampaignsMaxFields>;
  min?: Maybe<CampaignsMinFields>;
  stddev?: Maybe<CampaignsStddevFields>;
  stddev_pop?: Maybe<CampaignsStddevPopFields>;
  stddev_samp?: Maybe<CampaignsStddevSampFields>;
  sum?: Maybe<CampaignsSumFields>;
  var_pop?: Maybe<CampaignsVarPopFields>;
  var_samp?: Maybe<CampaignsVarSampFields>;
  variance?: Maybe<CampaignsVarianceFields>;
};


/** aggregate fields of "campaigns" */
export type CampaignsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CampaignsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CampaignsAvgFields = {
  __typename?: 'campaigns_avg_fields';
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "campaigns". All fields are combined with a logical 'AND'. */
export type CampaignsBoolExp = {
  _and?: InputMaybe<Array<CampaignsBoolExp>>;
  _not?: InputMaybe<CampaignsBoolExp>;
  _or?: InputMaybe<Array<CampaignsBoolExp>>;
  audienceType?: InputMaybe<CampaignAudienceTypesEnumComparisonExp>;
  campaignAudienceType?: InputMaybe<CampaignAudienceTypesBoolExp>;
  campaignCustomerUsers?: InputMaybe<CampaignCustomerUsersBoolExp>;
  campaignCustomerUsers_aggregate?: InputMaybe<CampaignCustomerUsersAggregateBoolExp>;
  channel?: InputMaybe<ChannelsBoolExp>;
  channelId?: InputMaybe<UuidComparisonExp>;
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesBoolExp>;
  chatMessageTemplateId?: InputMaybe<UuidComparisonExp>;
  chatMessages?: InputMaybe<ChatMessagesBoolExp>;
  chatMessages_aggregate?: InputMaybe<ChatMessagesAggregateBoolExp>;
  deliveredMessageCount?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  insertionUser?: InputMaybe<UsersBoolExp>;
  insertionUserId?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  isListed?: InputMaybe<BooleanComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  readMessageCount?: InputMaybe<IntComparisonExp>;
  scheduledTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  totalMessageCount?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "campaigns" */
export enum CampaignsConstraint {
  /** unique or primary key constraint on columns "id" */
  CampaignsPkey = 'campaigns_pkey'
}

/** input type for inserting data into table "campaigns" */
export type CampaignsInsertInput = {
  audienceType?: InputMaybe<CampaignAudienceTypesEnum>;
  campaignAudienceType?: InputMaybe<CampaignAudienceTypesObjRelInsertInput>;
  campaignCustomerUsers?: InputMaybe<CampaignCustomerUsersArrRelInsertInput>;
  channel?: InputMaybe<ChannelsObjRelInsertInput>;
  channelId?: InputMaybe<Scalars['uuid']>;
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesObjRelInsertInput>;
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  chatMessages?: InputMaybe<ChatMessagesArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  insertionUser?: InputMaybe<UsersObjRelInsertInput>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isListed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  scheduledTimestamp?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CampaignsMaxFields = {
  __typename?: 'campaigns_max_fields';
  channelId?: Maybe<Scalars['uuid']>;
  chatMessageTemplateId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  scheduledTimestamp?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "campaigns_status" */
  status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CampaignsMinFields = {
  __typename?: 'campaigns_min_fields';
  channelId?: Maybe<Scalars['uuid']>;
  chatMessageTemplateId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  scheduledTimestamp?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "campaigns_status" */
  status?: Maybe<Scalars['String']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "campaigns" */
export type CampaignsMutationResponse = {
  __typename?: 'campaigns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Campaigns>;
};

/** input type for inserting object relation for remote table "campaigns" */
export type CampaignsObjRelInsertInput = {
  data: CampaignsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CampaignsOnConflict>;
};

/** on_conflict condition type for table "campaigns" */
export type CampaignsOnConflict = {
  constraint: CampaignsConstraint;
  update_columns?: Array<CampaignsUpdateColumn>;
  where?: InputMaybe<CampaignsBoolExp>;
};

/** Ordering options when selecting data from "campaigns". */
export type CampaignsOrderBy = {
  audienceType?: InputMaybe<OrderBy>;
  campaignAudienceType?: InputMaybe<CampaignAudienceTypesOrderBy>;
  campaignCustomerUsers_aggregate?: InputMaybe<CampaignCustomerUsersAggregateOrderBy>;
  channel?: InputMaybe<ChannelsOrderBy>;
  channelId?: InputMaybe<OrderBy>;
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesOrderBy>;
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  chatMessages_aggregate?: InputMaybe<ChatMessagesAggregateOrderBy>;
  deliveredMessageCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  insertionUser?: InputMaybe<UsersOrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  isListed?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  readMessageCount?: InputMaybe<OrderBy>;
  scheduledTimestamp?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  totalMessageCount?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: campaigns */
export type CampaignsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "campaigns" */
export enum CampaignsSelectColumn {
  /** column name */
  AudienceType = 'audienceType',
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  ChatMessageTemplateId = 'chatMessageTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsListed = 'isListed',
  /** column name */
  Name = 'name',
  /** column name */
  ScheduledTimestamp = 'scheduledTimestamp'
}

/** input type for updating data in table "campaigns" */
export type CampaignsSetInput = {
  audienceType?: InputMaybe<CampaignAudienceTypesEnum>;
  channelId?: InputMaybe<Scalars['uuid']>;
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isListed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  scheduledTimestamp?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CampaignsStddevFields = {
  __typename?: 'campaigns_stddev_fields';
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_pop on columns */
export type CampaignsStddevPopFields = {
  __typename?: 'campaigns_stddev_pop_fields';
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_samp on columns */
export type CampaignsStddevSampFields = {
  __typename?: 'campaigns_stddev_samp_fields';
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** Streaming cursor of the table "campaigns" */
export type CampaignsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CampaignsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CampaignsStreamCursorValueInput = {
  audienceType?: InputMaybe<CampaignAudienceTypesEnum>;
  channelId?: InputMaybe<Scalars['uuid']>;
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isListed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  scheduledTimestamp?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type CampaignsSumFields = {
  __typename?: 'campaigns_sum_fields';
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "campaigns" */
export enum CampaignsUpdateColumn {
  /** column name */
  AudienceType = 'audienceType',
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  ChatMessageTemplateId = 'chatMessageTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsListed = 'isListed',
  /** column name */
  Name = 'name',
  /** column name */
  ScheduledTimestamp = 'scheduledTimestamp'
}

export type CampaignsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CampaignsSetInput>;
  /** filter the rows which have to be updated */
  where: CampaignsBoolExp;
};

/** aggregate var_pop on columns */
export type CampaignsVarPopFields = {
  __typename?: 'campaigns_var_pop_fields';
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** aggregate var_samp on columns */
export type CampaignsVarSampFields = {
  __typename?: 'campaigns_var_samp_fields';
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type CampaignsVarianceFields = {
  __typename?: 'campaigns_variance_fields';
  /** A computed field, executes function "campaigns_delivered_message_count" */
  deliveredMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_read_message_count" */
  readMessageCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "campaigns_total_message_count" */
  totalMessageCount?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "channel_chat_bot_users" */
export type ChannelChatBotUsers = {
  __typename?: 'channel_chat_bot_users';
  /** An object relationship */
  channel: Channels;
  channelId: Scalars['uuid'];
  id: Scalars['uuid'];
  optInTrigger?: Maybe<Scalars['String']>;
  optInTriggerAutoresponse?: Maybe<Scalars['String']>;
  optOutTrigger?: Maybe<Scalars['String']>;
  optOutTriggerAutoresponse?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "channel_chat_bot_users" */
export type ChannelChatBotUsersAggregate = {
  __typename?: 'channel_chat_bot_users_aggregate';
  aggregate?: Maybe<ChannelChatBotUsersAggregateFields>;
  nodes: Array<ChannelChatBotUsers>;
};

/** aggregate fields of "channel_chat_bot_users" */
export type ChannelChatBotUsersAggregateFields = {
  __typename?: 'channel_chat_bot_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChannelChatBotUsersMaxFields>;
  min?: Maybe<ChannelChatBotUsersMinFields>;
};


/** aggregate fields of "channel_chat_bot_users" */
export type ChannelChatBotUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChannelChatBotUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "channel_chat_bot_users". All fields are combined with a logical 'AND'. */
export type ChannelChatBotUsersBoolExp = {
  _and?: InputMaybe<Array<ChannelChatBotUsersBoolExp>>;
  _not?: InputMaybe<ChannelChatBotUsersBoolExp>;
  _or?: InputMaybe<Array<ChannelChatBotUsersBoolExp>>;
  channel?: InputMaybe<ChannelsBoolExp>;
  channelId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  optInTrigger?: InputMaybe<StringComparisonExp>;
  optInTriggerAutoresponse?: InputMaybe<StringComparisonExp>;
  optOutTrigger?: InputMaybe<StringComparisonExp>;
  optOutTriggerAutoresponse?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "channel_chat_bot_users" */
export enum ChannelChatBotUsersConstraint {
  /** unique or primary key constraint on columns "channel_id" */
  ChannelChatBotUsersChannelIdKey = 'channel_chat_bot_users_channel_id_key',
  /** unique or primary key constraint on columns "id" */
  ChannelChatBotUsersPkey = 'channel_chat_bot_users_pkey'
}

/** input type for inserting data into table "channel_chat_bot_users" */
export type ChannelChatBotUsersInsertInput = {
  channel?: InputMaybe<ChannelsObjRelInsertInput>;
  channelId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  optInTrigger?: InputMaybe<Scalars['String']>;
  optInTriggerAutoresponse?: InputMaybe<Scalars['String']>;
  optOutTrigger?: InputMaybe<Scalars['String']>;
  optOutTriggerAutoresponse?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type ChannelChatBotUsersMaxFields = {
  __typename?: 'channel_chat_bot_users_max_fields';
  channelId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  optInTrigger?: Maybe<Scalars['String']>;
  optInTriggerAutoresponse?: Maybe<Scalars['String']>;
  optOutTrigger?: Maybe<Scalars['String']>;
  optOutTriggerAutoresponse?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ChannelChatBotUsersMinFields = {
  __typename?: 'channel_chat_bot_users_min_fields';
  channelId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  optInTrigger?: Maybe<Scalars['String']>;
  optInTriggerAutoresponse?: Maybe<Scalars['String']>;
  optOutTrigger?: Maybe<Scalars['String']>;
  optOutTriggerAutoresponse?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "channel_chat_bot_users" */
export type ChannelChatBotUsersMutationResponse = {
  __typename?: 'channel_chat_bot_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChannelChatBotUsers>;
};

/** input type for inserting object relation for remote table "channel_chat_bot_users" */
export type ChannelChatBotUsersObjRelInsertInput = {
  data: ChannelChatBotUsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChannelChatBotUsersOnConflict>;
};

/** on_conflict condition type for table "channel_chat_bot_users" */
export type ChannelChatBotUsersOnConflict = {
  constraint: ChannelChatBotUsersConstraint;
  update_columns?: Array<ChannelChatBotUsersUpdateColumn>;
  where?: InputMaybe<ChannelChatBotUsersBoolExp>;
};

/** Ordering options when selecting data from "channel_chat_bot_users". */
export type ChannelChatBotUsersOrderBy = {
  channel?: InputMaybe<ChannelsOrderBy>;
  channelId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  optInTrigger?: InputMaybe<OrderBy>;
  optInTriggerAutoresponse?: InputMaybe<OrderBy>;
  optOutTrigger?: InputMaybe<OrderBy>;
  optOutTriggerAutoresponse?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
};

/** primary key columns input for table: channel_chat_bot_users */
export type ChannelChatBotUsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "channel_chat_bot_users" */
export enum ChannelChatBotUsersSelectColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Id = 'id',
  /** column name */
  OptInTrigger = 'optInTrigger',
  /** column name */
  OptInTriggerAutoresponse = 'optInTriggerAutoresponse',
  /** column name */
  OptOutTrigger = 'optOutTrigger',
  /** column name */
  OptOutTriggerAutoresponse = 'optOutTriggerAutoresponse'
}

/** input type for updating data in table "channel_chat_bot_users" */
export type ChannelChatBotUsersSetInput = {
  channelId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  optInTrigger?: InputMaybe<Scalars['String']>;
  optInTriggerAutoresponse?: InputMaybe<Scalars['String']>;
  optOutTrigger?: InputMaybe<Scalars['String']>;
  optOutTriggerAutoresponse?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "channel_chat_bot_users" */
export type ChannelChatBotUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChannelChatBotUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChannelChatBotUsersStreamCursorValueInput = {
  channelId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  optInTrigger?: InputMaybe<Scalars['String']>;
  optInTriggerAutoresponse?: InputMaybe<Scalars['String']>;
  optOutTrigger?: InputMaybe<Scalars['String']>;
  optOutTriggerAutoresponse?: InputMaybe<Scalars['String']>;
};

/** update columns of table "channel_chat_bot_users" */
export enum ChannelChatBotUsersUpdateColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Id = 'id',
  /** column name */
  OptInTrigger = 'optInTrigger',
  /** column name */
  OptInTriggerAutoresponse = 'optInTriggerAutoresponse',
  /** column name */
  OptOutTrigger = 'optOutTrigger',
  /** column name */
  OptOutTriggerAutoresponse = 'optOutTriggerAutoresponse'
}

export type ChannelChatBotUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChannelChatBotUsersSetInput>;
  /** filter the rows which have to be updated */
  where: ChannelChatBotUsersBoolExp;
};

/** columns and relationships of "channel_merchant_users" */
export type ChannelMerchantUsers = {
  __typename?: 'channel_merchant_users';
  _upsert: Scalars['Boolean'];
  /** An object relationship */
  channel: Channels;
  channelId: Scalars['uuid'];
  isAdmin: Scalars['Boolean'];
  /** An object relationship */
  merchantUser: MerchantUsers;
  merchantUserId: Scalars['uuid'];
};

/** aggregated selection of "channel_merchant_users" */
export type ChannelMerchantUsersAggregate = {
  __typename?: 'channel_merchant_users_aggregate';
  aggregate?: Maybe<ChannelMerchantUsersAggregateFields>;
  nodes: Array<ChannelMerchantUsers>;
};

export type ChannelMerchantUsersAggregateBoolExp = {
  bool_and?: InputMaybe<ChannelMerchantUsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ChannelMerchantUsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<ChannelMerchantUsersAggregateBoolExpCount>;
};

export type ChannelMerchantUsersAggregateBoolExpBoolAnd = {
  arguments: ChannelMerchantUsersSelectColumnChannelMerchantUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChannelMerchantUsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChannelMerchantUsersAggregateBoolExpBoolOr = {
  arguments: ChannelMerchantUsersSelectColumnChannelMerchantUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChannelMerchantUsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChannelMerchantUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChannelMerchantUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "channel_merchant_users" */
export type ChannelMerchantUsersAggregateFields = {
  __typename?: 'channel_merchant_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChannelMerchantUsersMaxFields>;
  min?: Maybe<ChannelMerchantUsersMinFields>;
};


/** aggregate fields of "channel_merchant_users" */
export type ChannelMerchantUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "channel_merchant_users" */
export type ChannelMerchantUsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChannelMerchantUsersMaxOrderBy>;
  min?: InputMaybe<ChannelMerchantUsersMinOrderBy>;
};

/** input type for inserting array relation for remote table "channel_merchant_users" */
export type ChannelMerchantUsersArrRelInsertInput = {
  data: Array<ChannelMerchantUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChannelMerchantUsersOnConflict>;
};

/** Boolean expression to filter rows from the table "channel_merchant_users". All fields are combined with a logical 'AND'. */
export type ChannelMerchantUsersBoolExp = {
  _and?: InputMaybe<Array<ChannelMerchantUsersBoolExp>>;
  _not?: InputMaybe<ChannelMerchantUsersBoolExp>;
  _or?: InputMaybe<Array<ChannelMerchantUsersBoolExp>>;
  _upsert?: InputMaybe<BooleanComparisonExp>;
  channel?: InputMaybe<ChannelsBoolExp>;
  channelId?: InputMaybe<UuidComparisonExp>;
  isAdmin?: InputMaybe<BooleanComparisonExp>;
  merchantUser?: InputMaybe<MerchantUsersBoolExp>;
  merchantUserId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "channel_merchant_users" */
export enum ChannelMerchantUsersConstraint {
  /** unique or primary key constraint on columns "merchant_user_id", "channel_id" */
  ChannelMerchantUsersPkey = 'channel_merchant_users_pkey'
}

/** input type for inserting data into table "channel_merchant_users" */
export type ChannelMerchantUsersInsertInput = {
  _upsert?: InputMaybe<Scalars['Boolean']>;
  channel?: InputMaybe<ChannelsObjRelInsertInput>;
  channelId?: InputMaybe<Scalars['uuid']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  merchantUser?: InputMaybe<MerchantUsersObjRelInsertInput>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ChannelMerchantUsersMaxFields = {
  __typename?: 'channel_merchant_users_max_fields';
  channelId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "channel_merchant_users" */
export type ChannelMerchantUsersMaxOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChannelMerchantUsersMinFields = {
  __typename?: 'channel_merchant_users_min_fields';
  channelId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "channel_merchant_users" */
export type ChannelMerchantUsersMinOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "channel_merchant_users" */
export type ChannelMerchantUsersMutationResponse = {
  __typename?: 'channel_merchant_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChannelMerchantUsers>;
};

/** on_conflict condition type for table "channel_merchant_users" */
export type ChannelMerchantUsersOnConflict = {
  constraint: ChannelMerchantUsersConstraint;
  update_columns?: Array<ChannelMerchantUsersUpdateColumn>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};

/** Ordering options when selecting data from "channel_merchant_users". */
export type ChannelMerchantUsersOrderBy = {
  _upsert?: InputMaybe<OrderBy>;
  channel?: InputMaybe<ChannelsOrderBy>;
  channelId?: InputMaybe<OrderBy>;
  isAdmin?: InputMaybe<OrderBy>;
  merchantUser?: InputMaybe<MerchantUsersOrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: channel_merchant_users */
export type ChannelMerchantUsersPkColumnsInput = {
  channelId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};

/** select columns of table "channel_merchant_users" */
export enum ChannelMerchantUsersSelectColumn {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

/** select "channel_merchant_users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "channel_merchant_users" */
export enum ChannelMerchantUsersSelectColumnChannelMerchantUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  IsAdmin = 'isAdmin'
}

/** select "channel_merchant_users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "channel_merchant_users" */
export enum ChannelMerchantUsersSelectColumnChannelMerchantUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  IsAdmin = 'isAdmin'
}

/** input type for updating data in table "channel_merchant_users" */
export type ChannelMerchantUsersSetInput = {
  _upsert?: InputMaybe<Scalars['Boolean']>;
  channelId?: InputMaybe<Scalars['uuid']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "channel_merchant_users" */
export type ChannelMerchantUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChannelMerchantUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChannelMerchantUsersStreamCursorValueInput = {
  _upsert?: InputMaybe<Scalars['Boolean']>;
  channelId?: InputMaybe<Scalars['uuid']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "channel_merchant_users" */
export enum ChannelMerchantUsersUpdateColumn {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

export type ChannelMerchantUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChannelMerchantUsersSetInput>;
  /** filter the rows which have to be updated */
  where: ChannelMerchantUsersBoolExp;
};

/** columns and relationships of "channel_working_hours" */
export type ChannelWorkingHours = {
  __typename?: 'channel_working_hours';
  /** An object relationship */
  channel: Channels;
  channelId: Scalars['uuid'];
  closingTime?: Maybe<Scalars['time']>;
  day: Scalars['Int'];
  id: Scalars['uuid'];
  isOpen: Scalars['Boolean'];
  openingTime?: Maybe<Scalars['time']>;
};

/** aggregated selection of "channel_working_hours" */
export type ChannelWorkingHoursAggregate = {
  __typename?: 'channel_working_hours_aggregate';
  aggregate?: Maybe<ChannelWorkingHoursAggregateFields>;
  nodes: Array<ChannelWorkingHours>;
};

export type ChannelWorkingHoursAggregateBoolExp = {
  bool_and?: InputMaybe<ChannelWorkingHoursAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ChannelWorkingHoursAggregateBoolExpBoolOr>;
  count?: InputMaybe<ChannelWorkingHoursAggregateBoolExpCount>;
};

export type ChannelWorkingHoursAggregateBoolExpBoolAnd = {
  arguments: ChannelWorkingHoursSelectColumnChannelWorkingHoursAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChannelWorkingHoursBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChannelWorkingHoursAggregateBoolExpBoolOr = {
  arguments: ChannelWorkingHoursSelectColumnChannelWorkingHoursAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChannelWorkingHoursBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChannelWorkingHoursAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChannelWorkingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChannelWorkingHoursBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "channel_working_hours" */
export type ChannelWorkingHoursAggregateFields = {
  __typename?: 'channel_working_hours_aggregate_fields';
  avg?: Maybe<ChannelWorkingHoursAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ChannelWorkingHoursMaxFields>;
  min?: Maybe<ChannelWorkingHoursMinFields>;
  stddev?: Maybe<ChannelWorkingHoursStddevFields>;
  stddev_pop?: Maybe<ChannelWorkingHoursStddevPopFields>;
  stddev_samp?: Maybe<ChannelWorkingHoursStddevSampFields>;
  sum?: Maybe<ChannelWorkingHoursSumFields>;
  var_pop?: Maybe<ChannelWorkingHoursVarPopFields>;
  var_samp?: Maybe<ChannelWorkingHoursVarSampFields>;
  variance?: Maybe<ChannelWorkingHoursVarianceFields>;
};


/** aggregate fields of "channel_working_hours" */
export type ChannelWorkingHoursAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChannelWorkingHoursSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "channel_working_hours" */
export type ChannelWorkingHoursAggregateOrderBy = {
  avg?: InputMaybe<ChannelWorkingHoursAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChannelWorkingHoursMaxOrderBy>;
  min?: InputMaybe<ChannelWorkingHoursMinOrderBy>;
  stddev?: InputMaybe<ChannelWorkingHoursStddevOrderBy>;
  stddev_pop?: InputMaybe<ChannelWorkingHoursStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ChannelWorkingHoursStddevSampOrderBy>;
  sum?: InputMaybe<ChannelWorkingHoursSumOrderBy>;
  var_pop?: InputMaybe<ChannelWorkingHoursVarPopOrderBy>;
  var_samp?: InputMaybe<ChannelWorkingHoursVarSampOrderBy>;
  variance?: InputMaybe<ChannelWorkingHoursVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "channel_working_hours" */
export type ChannelWorkingHoursArrRelInsertInput = {
  data: Array<ChannelWorkingHoursInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChannelWorkingHoursOnConflict>;
};

/** aggregate avg on columns */
export type ChannelWorkingHoursAvgFields = {
  __typename?: 'channel_working_hours_avg_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursAvgOrderBy = {
  day?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "channel_working_hours". All fields are combined with a logical 'AND'. */
export type ChannelWorkingHoursBoolExp = {
  _and?: InputMaybe<Array<ChannelWorkingHoursBoolExp>>;
  _not?: InputMaybe<ChannelWorkingHoursBoolExp>;
  _or?: InputMaybe<Array<ChannelWorkingHoursBoolExp>>;
  channel?: InputMaybe<ChannelsBoolExp>;
  channelId?: InputMaybe<UuidComparisonExp>;
  closingTime?: InputMaybe<TimeComparisonExp>;
  day?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isOpen?: InputMaybe<BooleanComparisonExp>;
  openingTime?: InputMaybe<TimeComparisonExp>;
};

/** unique or primary key constraints on table "channel_working_hours" */
export enum ChannelWorkingHoursConstraint {
  /** unique or primary key constraint on columns "channel_id", "day" */
  ChannelWorkingHoursDayChannelIdKey = 'channel_working_hours_day_channel_id_key',
  /** unique or primary key constraint on columns "id" */
  ChannelWorkingHoursPkey = 'channel_working_hours_pkey'
}

/** input type for incrementing numeric columns in table "channel_working_hours" */
export type ChannelWorkingHoursIncInput = {
  day?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "channel_working_hours" */
export type ChannelWorkingHoursInsertInput = {
  channel?: InputMaybe<ChannelsObjRelInsertInput>;
  channelId?: InputMaybe<Scalars['uuid']>;
  closingTime?: InputMaybe<Scalars['time']>;
  day?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  openingTime?: InputMaybe<Scalars['time']>;
};

/** aggregate max on columns */
export type ChannelWorkingHoursMaxFields = {
  __typename?: 'channel_working_hours_max_fields';
  channelId?: Maybe<Scalars['uuid']>;
  day?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursMaxOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChannelWorkingHoursMinFields = {
  __typename?: 'channel_working_hours_min_fields';
  channelId?: Maybe<Scalars['uuid']>;
  day?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursMinOrderBy = {
  channelId?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "channel_working_hours" */
export type ChannelWorkingHoursMutationResponse = {
  __typename?: 'channel_working_hours_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChannelWorkingHours>;
};

/** on_conflict condition type for table "channel_working_hours" */
export type ChannelWorkingHoursOnConflict = {
  constraint: ChannelWorkingHoursConstraint;
  update_columns?: Array<ChannelWorkingHoursUpdateColumn>;
  where?: InputMaybe<ChannelWorkingHoursBoolExp>;
};

/** Ordering options when selecting data from "channel_working_hours". */
export type ChannelWorkingHoursOrderBy = {
  channel?: InputMaybe<ChannelsOrderBy>;
  channelId?: InputMaybe<OrderBy>;
  closingTime?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isOpen?: InputMaybe<OrderBy>;
  openingTime?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: channel_working_hours */
export type ChannelWorkingHoursPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "channel_working_hours" */
export enum ChannelWorkingHoursSelectColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  ClosingTime = 'closingTime',
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  IsOpen = 'isOpen',
  /** column name */
  OpeningTime = 'openingTime'
}

/** select "channel_working_hours_aggregate_bool_exp_bool_and_arguments_columns" columns of table "channel_working_hours" */
export enum ChannelWorkingHoursSelectColumnChannelWorkingHoursAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsOpen = 'isOpen'
}

/** select "channel_working_hours_aggregate_bool_exp_bool_or_arguments_columns" columns of table "channel_working_hours" */
export enum ChannelWorkingHoursSelectColumnChannelWorkingHoursAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsOpen = 'isOpen'
}

/** input type for updating data in table "channel_working_hours" */
export type ChannelWorkingHoursSetInput = {
  channelId?: InputMaybe<Scalars['uuid']>;
  closingTime?: InputMaybe<Scalars['time']>;
  day?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  openingTime?: InputMaybe<Scalars['time']>;
};

/** aggregate stddev on columns */
export type ChannelWorkingHoursStddevFields = {
  __typename?: 'channel_working_hours_stddev_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursStddevOrderBy = {
  day?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ChannelWorkingHoursStddevPopFields = {
  __typename?: 'channel_working_hours_stddev_pop_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursStddevPopOrderBy = {
  day?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ChannelWorkingHoursStddevSampFields = {
  __typename?: 'channel_working_hours_stddev_samp_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursStddevSampOrderBy = {
  day?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "channel_working_hours" */
export type ChannelWorkingHoursStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChannelWorkingHoursStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChannelWorkingHoursStreamCursorValueInput = {
  channelId?: InputMaybe<Scalars['uuid']>;
  closingTime?: InputMaybe<Scalars['time']>;
  day?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  openingTime?: InputMaybe<Scalars['time']>;
};

/** aggregate sum on columns */
export type ChannelWorkingHoursSumFields = {
  __typename?: 'channel_working_hours_sum_fields';
  day?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursSumOrderBy = {
  day?: InputMaybe<OrderBy>;
};

/** update columns of table "channel_working_hours" */
export enum ChannelWorkingHoursUpdateColumn {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  ClosingTime = 'closingTime',
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  IsOpen = 'isOpen',
  /** column name */
  OpeningTime = 'openingTime'
}

export type ChannelWorkingHoursUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChannelWorkingHoursIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChannelWorkingHoursSetInput>;
  /** filter the rows which have to be updated */
  where: ChannelWorkingHoursBoolExp;
};

/** aggregate var_pop on columns */
export type ChannelWorkingHoursVarPopFields = {
  __typename?: 'channel_working_hours_var_pop_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursVarPopOrderBy = {
  day?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ChannelWorkingHoursVarSampFields = {
  __typename?: 'channel_working_hours_var_samp_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursVarSampOrderBy = {
  day?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ChannelWorkingHoursVarianceFields = {
  __typename?: 'channel_working_hours_variance_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "channel_working_hours" */
export type ChannelWorkingHoursVarianceOrderBy = {
  day?: InputMaybe<OrderBy>;
};

/** columns and relationships of "channels" */
export type Channels = {
  __typename?: 'channels';
  absenceAutoreplyText?: Maybe<Scalars['String']>;
  absenceEndTimestamp?: Maybe<Scalars['timestamptz']>;
  absenceStartTimestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  birthdayMessageTemplate?: Maybe<ChatMessageTemplates>;
  birthdayMessageTemplateId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  channelChatBotUser?: Maybe<ChannelChatBotUsers>;
  /** An array relationship */
  channelMerchantUsers: Array<ChannelMerchantUsers>;
  /** An aggregate relationship */
  channelMerchantUsers_aggregate: ChannelMerchantUsersAggregate;
  /** An array relationship */
  channelWorkingHours: Array<ChannelWorkingHours>;
  /** An aggregate relationship */
  channelWorkingHours_aggregate: ChannelWorkingHoursAggregate;
  /** An array relationship */
  chats: Array<Chats>;
  /** An aggregate relationship */
  chats_aggregate: ChatsAggregate;
  id: Scalars['uuid'];
  isAbsenceAutoreplyEnabled: Scalars['Boolean'];
  isBirthdayMessageEnabled: Scalars['Boolean'];
  isGptBotEnabled?: Maybe<Scalars['Boolean']>;
  isMarketingOptInAutoreplyEnabled: Scalars['Boolean'];
  isOutsideWorkingHoursAutoreplyEnabled: Scalars['Boolean'];
  isWelcomeAutoreplyEnabled: Scalars['Boolean'];
  /** An object relationship */
  merchant: Merchants;
  merchantId: Scalars['uuid'];
  name: Scalars['String'];
  outsideWorkingHoursAutoreplyText?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  welcomeAutoreplyText?: Maybe<Scalars['String']>;
  /** An object relationship */
  whatsappAccount?: Maybe<WhatsappAccounts>;
  whatsappAccountId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "channels" */
export type ChannelsChannelMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelMerchantUsersOrderBy>>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannelMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelMerchantUsersOrderBy>>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannelWorkingHoursArgs = {
  distinct_on?: InputMaybe<Array<ChannelWorkingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelWorkingHoursOrderBy>>;
  where?: InputMaybe<ChannelWorkingHoursBoolExp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannelWorkingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelWorkingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelWorkingHoursOrderBy>>;
  where?: InputMaybe<ChannelWorkingHoursBoolExp>;
};


/** columns and relationships of "channels" */
export type ChannelsChatsArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


/** columns and relationships of "channels" */
export type ChannelsChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};

/** aggregated selection of "channels" */
export type ChannelsAggregate = {
  __typename?: 'channels_aggregate';
  aggregate?: Maybe<ChannelsAggregateFields>;
  nodes: Array<Channels>;
};

export type ChannelsAggregateBoolExp = {
  bool_and?: InputMaybe<ChannelsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ChannelsAggregateBoolExpBoolOr>;
  count?: InputMaybe<ChannelsAggregateBoolExpCount>;
};

export type ChannelsAggregateBoolExpBoolAnd = {
  arguments: ChannelsSelectColumnChannelsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChannelsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChannelsAggregateBoolExpBoolOr = {
  arguments: ChannelsSelectColumnChannelsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChannelsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChannelsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChannelsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChannelsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "channels" */
export type ChannelsAggregateFields = {
  __typename?: 'channels_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChannelsMaxFields>;
  min?: Maybe<ChannelsMinFields>;
};


/** aggregate fields of "channels" */
export type ChannelsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChannelsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "channels" */
export type ChannelsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChannelsMaxOrderBy>;
  min?: InputMaybe<ChannelsMinOrderBy>;
};

/** input type for inserting array relation for remote table "channels" */
export type ChannelsArrRelInsertInput = {
  data: Array<ChannelsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChannelsOnConflict>;
};

/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
export type ChannelsBoolExp = {
  _and?: InputMaybe<Array<ChannelsBoolExp>>;
  _not?: InputMaybe<ChannelsBoolExp>;
  _or?: InputMaybe<Array<ChannelsBoolExp>>;
  absenceAutoreplyText?: InputMaybe<StringComparisonExp>;
  absenceEndTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  absenceStartTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  birthdayMessageTemplate?: InputMaybe<ChatMessageTemplatesBoolExp>;
  birthdayMessageTemplateId?: InputMaybe<UuidComparisonExp>;
  channelChatBotUser?: InputMaybe<ChannelChatBotUsersBoolExp>;
  channelMerchantUsers?: InputMaybe<ChannelMerchantUsersBoolExp>;
  channelMerchantUsers_aggregate?: InputMaybe<ChannelMerchantUsersAggregateBoolExp>;
  channelWorkingHours?: InputMaybe<ChannelWorkingHoursBoolExp>;
  channelWorkingHours_aggregate?: InputMaybe<ChannelWorkingHoursAggregateBoolExp>;
  chats?: InputMaybe<ChatsBoolExp>;
  chats_aggregate?: InputMaybe<ChatsAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isAbsenceAutoreplyEnabled?: InputMaybe<BooleanComparisonExp>;
  isBirthdayMessageEnabled?: InputMaybe<BooleanComparisonExp>;
  isGptBotEnabled?: InputMaybe<BooleanComparisonExp>;
  isMarketingOptInAutoreplyEnabled?: InputMaybe<BooleanComparisonExp>;
  isOutsideWorkingHoursAutoreplyEnabled?: InputMaybe<BooleanComparisonExp>;
  isWelcomeAutoreplyEnabled?: InputMaybe<BooleanComparisonExp>;
  merchant?: InputMaybe<MerchantsBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  outsideWorkingHoursAutoreplyText?: InputMaybe<StringComparisonExp>;
  tagName?: InputMaybe<StringComparisonExp>;
  welcomeAutoreplyText?: InputMaybe<StringComparisonExp>;
  whatsappAccount?: InputMaybe<WhatsappAccountsBoolExp>;
  whatsappAccountId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "channels" */
export enum ChannelsConstraint {
  /** unique or primary key constraint on columns "id" */
  ChannelsPkey = 'channels_pkey',
  /** unique or primary key constraint on columns "whatsapp_account_id" */
  ChannelsWhatsappAccountIdKey = 'channels_whatsapp_account_id_key'
}

/** input type for inserting data into table "channels" */
export type ChannelsInsertInput = {
  absenceAutoreplyText?: InputMaybe<Scalars['String']>;
  absenceEndTimestamp?: InputMaybe<Scalars['timestamptz']>;
  absenceStartTimestamp?: InputMaybe<Scalars['timestamptz']>;
  birthdayMessageTemplate?: InputMaybe<ChatMessageTemplatesObjRelInsertInput>;
  birthdayMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  channelChatBotUser?: InputMaybe<ChannelChatBotUsersObjRelInsertInput>;
  channelMerchantUsers?: InputMaybe<ChannelMerchantUsersArrRelInsertInput>;
  channelWorkingHours?: InputMaybe<ChannelWorkingHoursArrRelInsertInput>;
  chats?: InputMaybe<ChatsArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  isAbsenceAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  isBirthdayMessageEnabled?: InputMaybe<Scalars['Boolean']>;
  isGptBotEnabled?: InputMaybe<Scalars['Boolean']>;
  isMarketingOptInAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  isOutsideWorkingHoursAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  isWelcomeAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  merchant?: InputMaybe<MerchantsObjRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  outsideWorkingHoursAutoreplyText?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  welcomeAutoreplyText?: InputMaybe<Scalars['String']>;
  whatsappAccount?: InputMaybe<WhatsappAccountsObjRelInsertInput>;
  whatsappAccountId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ChannelsMaxFields = {
  __typename?: 'channels_max_fields';
  absenceAutoreplyText?: Maybe<Scalars['String']>;
  absenceEndTimestamp?: Maybe<Scalars['timestamptz']>;
  absenceStartTimestamp?: Maybe<Scalars['timestamptz']>;
  birthdayMessageTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  outsideWorkingHoursAutoreplyText?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  welcomeAutoreplyText?: Maybe<Scalars['String']>;
  whatsappAccountId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "channels" */
export type ChannelsMaxOrderBy = {
  absenceAutoreplyText?: InputMaybe<OrderBy>;
  absenceEndTimestamp?: InputMaybe<OrderBy>;
  absenceStartTimestamp?: InputMaybe<OrderBy>;
  birthdayMessageTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  outsideWorkingHoursAutoreplyText?: InputMaybe<OrderBy>;
  tagName?: InputMaybe<OrderBy>;
  welcomeAutoreplyText?: InputMaybe<OrderBy>;
  whatsappAccountId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChannelsMinFields = {
  __typename?: 'channels_min_fields';
  absenceAutoreplyText?: Maybe<Scalars['String']>;
  absenceEndTimestamp?: Maybe<Scalars['timestamptz']>;
  absenceStartTimestamp?: Maybe<Scalars['timestamptz']>;
  birthdayMessageTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  outsideWorkingHoursAutoreplyText?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  welcomeAutoreplyText?: Maybe<Scalars['String']>;
  whatsappAccountId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "channels" */
export type ChannelsMinOrderBy = {
  absenceAutoreplyText?: InputMaybe<OrderBy>;
  absenceEndTimestamp?: InputMaybe<OrderBy>;
  absenceStartTimestamp?: InputMaybe<OrderBy>;
  birthdayMessageTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  outsideWorkingHoursAutoreplyText?: InputMaybe<OrderBy>;
  tagName?: InputMaybe<OrderBy>;
  welcomeAutoreplyText?: InputMaybe<OrderBy>;
  whatsappAccountId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "channels" */
export type ChannelsMutationResponse = {
  __typename?: 'channels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Channels>;
};

/** input type for inserting object relation for remote table "channels" */
export type ChannelsObjRelInsertInput = {
  data: ChannelsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChannelsOnConflict>;
};

/** on_conflict condition type for table "channels" */
export type ChannelsOnConflict = {
  constraint: ChannelsConstraint;
  update_columns?: Array<ChannelsUpdateColumn>;
  where?: InputMaybe<ChannelsBoolExp>;
};

/** Ordering options when selecting data from "channels". */
export type ChannelsOrderBy = {
  absenceAutoreplyText?: InputMaybe<OrderBy>;
  absenceEndTimestamp?: InputMaybe<OrderBy>;
  absenceStartTimestamp?: InputMaybe<OrderBy>;
  birthdayMessageTemplate?: InputMaybe<ChatMessageTemplatesOrderBy>;
  birthdayMessageTemplateId?: InputMaybe<OrderBy>;
  channelChatBotUser?: InputMaybe<ChannelChatBotUsersOrderBy>;
  channelMerchantUsers_aggregate?: InputMaybe<ChannelMerchantUsersAggregateOrderBy>;
  channelWorkingHours_aggregate?: InputMaybe<ChannelWorkingHoursAggregateOrderBy>;
  chats_aggregate?: InputMaybe<ChatsAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  isAbsenceAutoreplyEnabled?: InputMaybe<OrderBy>;
  isBirthdayMessageEnabled?: InputMaybe<OrderBy>;
  isGptBotEnabled?: InputMaybe<OrderBy>;
  isMarketingOptInAutoreplyEnabled?: InputMaybe<OrderBy>;
  isOutsideWorkingHoursAutoreplyEnabled?: InputMaybe<OrderBy>;
  isWelcomeAutoreplyEnabled?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantsOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  outsideWorkingHoursAutoreplyText?: InputMaybe<OrderBy>;
  tagName?: InputMaybe<OrderBy>;
  welcomeAutoreplyText?: InputMaybe<OrderBy>;
  whatsappAccount?: InputMaybe<WhatsappAccountsOrderBy>;
  whatsappAccountId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: channels */
export type ChannelsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "channels" */
export enum ChannelsSelectColumn {
  /** column name */
  AbsenceAutoreplyText = 'absenceAutoreplyText',
  /** column name */
  AbsenceEndTimestamp = 'absenceEndTimestamp',
  /** column name */
  AbsenceStartTimestamp = 'absenceStartTimestamp',
  /** column name */
  BirthdayMessageTemplateId = 'birthdayMessageTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  IsAbsenceAutoreplyEnabled = 'isAbsenceAutoreplyEnabled',
  /** column name */
  IsBirthdayMessageEnabled = 'isBirthdayMessageEnabled',
  /** column name */
  IsGptBotEnabled = 'isGptBotEnabled',
  /** column name */
  IsMarketingOptInAutoreplyEnabled = 'isMarketingOptInAutoreplyEnabled',
  /** column name */
  IsOutsideWorkingHoursAutoreplyEnabled = 'isOutsideWorkingHoursAutoreplyEnabled',
  /** column name */
  IsWelcomeAutoreplyEnabled = 'isWelcomeAutoreplyEnabled',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name',
  /** column name */
  OutsideWorkingHoursAutoreplyText = 'outsideWorkingHoursAutoreplyText',
  /** column name */
  TagName = 'tagName',
  /** column name */
  WelcomeAutoreplyText = 'welcomeAutoreplyText',
  /** column name */
  WhatsappAccountId = 'whatsappAccountId'
}

/** select "channels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "channels" */
export enum ChannelsSelectColumnChannelsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsAbsenceAutoreplyEnabled = 'isAbsenceAutoreplyEnabled',
  /** column name */
  IsBirthdayMessageEnabled = 'isBirthdayMessageEnabled',
  /** column name */
  IsGptBotEnabled = 'isGptBotEnabled',
  /** column name */
  IsMarketingOptInAutoreplyEnabled = 'isMarketingOptInAutoreplyEnabled',
  /** column name */
  IsOutsideWorkingHoursAutoreplyEnabled = 'isOutsideWorkingHoursAutoreplyEnabled',
  /** column name */
  IsWelcomeAutoreplyEnabled = 'isWelcomeAutoreplyEnabled'
}

/** select "channels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "channels" */
export enum ChannelsSelectColumnChannelsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsAbsenceAutoreplyEnabled = 'isAbsenceAutoreplyEnabled',
  /** column name */
  IsBirthdayMessageEnabled = 'isBirthdayMessageEnabled',
  /** column name */
  IsGptBotEnabled = 'isGptBotEnabled',
  /** column name */
  IsMarketingOptInAutoreplyEnabled = 'isMarketingOptInAutoreplyEnabled',
  /** column name */
  IsOutsideWorkingHoursAutoreplyEnabled = 'isOutsideWorkingHoursAutoreplyEnabled',
  /** column name */
  IsWelcomeAutoreplyEnabled = 'isWelcomeAutoreplyEnabled'
}

/** input type for updating data in table "channels" */
export type ChannelsSetInput = {
  absenceAutoreplyText?: InputMaybe<Scalars['String']>;
  absenceEndTimestamp?: InputMaybe<Scalars['timestamptz']>;
  absenceStartTimestamp?: InputMaybe<Scalars['timestamptz']>;
  birthdayMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAbsenceAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  isBirthdayMessageEnabled?: InputMaybe<Scalars['Boolean']>;
  isGptBotEnabled?: InputMaybe<Scalars['Boolean']>;
  isMarketingOptInAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  isOutsideWorkingHoursAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  isWelcomeAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  outsideWorkingHoursAutoreplyText?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  welcomeAutoreplyText?: InputMaybe<Scalars['String']>;
  whatsappAccountId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "channels" */
export type ChannelsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChannelsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChannelsStreamCursorValueInput = {
  absenceAutoreplyText?: InputMaybe<Scalars['String']>;
  absenceEndTimestamp?: InputMaybe<Scalars['timestamptz']>;
  absenceStartTimestamp?: InputMaybe<Scalars['timestamptz']>;
  birthdayMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  isAbsenceAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  isBirthdayMessageEnabled?: InputMaybe<Scalars['Boolean']>;
  isGptBotEnabled?: InputMaybe<Scalars['Boolean']>;
  isMarketingOptInAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  isOutsideWorkingHoursAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  isWelcomeAutoreplyEnabled?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  outsideWorkingHoursAutoreplyText?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  welcomeAutoreplyText?: InputMaybe<Scalars['String']>;
  whatsappAccountId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "channels" */
export enum ChannelsUpdateColumn {
  /** column name */
  AbsenceAutoreplyText = 'absenceAutoreplyText',
  /** column name */
  AbsenceEndTimestamp = 'absenceEndTimestamp',
  /** column name */
  AbsenceStartTimestamp = 'absenceStartTimestamp',
  /** column name */
  BirthdayMessageTemplateId = 'birthdayMessageTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  IsAbsenceAutoreplyEnabled = 'isAbsenceAutoreplyEnabled',
  /** column name */
  IsBirthdayMessageEnabled = 'isBirthdayMessageEnabled',
  /** column name */
  IsGptBotEnabled = 'isGptBotEnabled',
  /** column name */
  IsMarketingOptInAutoreplyEnabled = 'isMarketingOptInAutoreplyEnabled',
  /** column name */
  IsOutsideWorkingHoursAutoreplyEnabled = 'isOutsideWorkingHoursAutoreplyEnabled',
  /** column name */
  IsWelcomeAutoreplyEnabled = 'isWelcomeAutoreplyEnabled',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name',
  /** column name */
  OutsideWorkingHoursAutoreplyText = 'outsideWorkingHoursAutoreplyText',
  /** column name */
  TagName = 'tagName',
  /** column name */
  WelcomeAutoreplyText = 'welcomeAutoreplyText',
  /** column name */
  WhatsappAccountId = 'whatsappAccountId'
}

export type ChannelsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChannelsSetInput>;
  /** filter the rows which have to be updated */
  where: ChannelsBoolExp;
};

/** columns and relationships of "chat_message_chat_bot_autoreply_types" */
export type ChatMessageChatBotAutoreplyTypes = {
  __typename?: 'chat_message_chat_bot_autoreply_types';
  type: Scalars['String'];
};

/** aggregated selection of "chat_message_chat_bot_autoreply_types" */
export type ChatMessageChatBotAutoreplyTypesAggregate = {
  __typename?: 'chat_message_chat_bot_autoreply_types_aggregate';
  aggregate?: Maybe<ChatMessageChatBotAutoreplyTypesAggregateFields>;
  nodes: Array<ChatMessageChatBotAutoreplyTypes>;
};

/** aggregate fields of "chat_message_chat_bot_autoreply_types" */
export type ChatMessageChatBotAutoreplyTypesAggregateFields = {
  __typename?: 'chat_message_chat_bot_autoreply_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessageChatBotAutoreplyTypesMaxFields>;
  min?: Maybe<ChatMessageChatBotAutoreplyTypesMinFields>;
};


/** aggregate fields of "chat_message_chat_bot_autoreply_types" */
export type ChatMessageChatBotAutoreplyTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_message_chat_bot_autoreply_types". All fields are combined with a logical 'AND'. */
export type ChatMessageChatBotAutoreplyTypesBoolExp = {
  _and?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesBoolExp>>;
  _not?: InputMaybe<ChatMessageChatBotAutoreplyTypesBoolExp>;
  _or?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_chat_bot_autoreply_types" */
export enum ChatMessageChatBotAutoreplyTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  ChatMessageChatBotAutoreplyTypesPkey = 'chat_message_chat_bot_autoreply_types_pkey'
}

/** input type for inserting data into table "chat_message_chat_bot_autoreply_types" */
export type ChatMessageChatBotAutoreplyTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChatMessageChatBotAutoreplyTypesMaxFields = {
  __typename?: 'chat_message_chat_bot_autoreply_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ChatMessageChatBotAutoreplyTypesMinFields = {
  __typename?: 'chat_message_chat_bot_autoreply_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chat_message_chat_bot_autoreply_types" */
export type ChatMessageChatBotAutoreplyTypesMutationResponse = {
  __typename?: 'chat_message_chat_bot_autoreply_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageChatBotAutoreplyTypes>;
};

/** on_conflict condition type for table "chat_message_chat_bot_autoreply_types" */
export type ChatMessageChatBotAutoreplyTypesOnConflict = {
  constraint: ChatMessageChatBotAutoreplyTypesConstraint;
  update_columns?: Array<ChatMessageChatBotAutoreplyTypesUpdateColumn>;
  where?: InputMaybe<ChatMessageChatBotAutoreplyTypesBoolExp>;
};

/** Ordering options when selecting data from "chat_message_chat_bot_autoreply_types". */
export type ChatMessageChatBotAutoreplyTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_chat_bot_autoreply_types */
export type ChatMessageChatBotAutoreplyTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "chat_message_chat_bot_autoreply_types" */
export enum ChatMessageChatBotAutoreplyTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chat_message_chat_bot_autoreply_types" */
export type ChatMessageChatBotAutoreplyTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message_chat_bot_autoreply_types" */
export type ChatMessageChatBotAutoreplyTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageChatBotAutoreplyTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageChatBotAutoreplyTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_message_chat_bot_autoreply_types" */
export enum ChatMessageChatBotAutoreplyTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type ChatMessageChatBotAutoreplyTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageChatBotAutoreplyTypesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageChatBotAutoreplyTypesBoolExp;
};

/** columns and relationships of "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslations = {
  __typename?: 'chat_message_file_attachment_translations';
  /** An object relationship */
  chatMessageFileAttachment: ChatMessageFileAttachments;
  chatMessageFileAttachmentId: Scalars['uuid'];
  language: Scalars['String'];
  text: Scalars['String'];
};

/** aggregated selection of "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsAggregate = {
  __typename?: 'chat_message_file_attachment_translations_aggregate';
  aggregate?: Maybe<ChatMessageFileAttachmentTranslationsAggregateFields>;
  nodes: Array<ChatMessageFileAttachmentTranslations>;
};

export type ChatMessageFileAttachmentTranslationsAggregateBoolExp = {
  count?: InputMaybe<ChatMessageFileAttachmentTranslationsAggregateBoolExpCount>;
};

export type ChatMessageFileAttachmentTranslationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsAggregateFields = {
  __typename?: 'chat_message_file_attachment_translations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessageFileAttachmentTranslationsMaxFields>;
  min?: Maybe<ChatMessageFileAttachmentTranslationsMinFields>;
};


/** aggregate fields of "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatMessageFileAttachmentTranslationsMaxOrderBy>;
  min?: InputMaybe<ChatMessageFileAttachmentTranslationsMinOrderBy>;
};

/** input type for inserting array relation for remote table "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsArrRelInsertInput = {
  data: Array<ChatMessageFileAttachmentTranslationsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageFileAttachmentTranslationsOnConflict>;
};

/** Boolean expression to filter rows from the table "chat_message_file_attachment_translations". All fields are combined with a logical 'AND'. */
export type ChatMessageFileAttachmentTranslationsBoolExp = {
  _and?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsBoolExp>>;
  _not?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
  _or?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsBoolExp>>;
  chatMessageFileAttachment?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
  chatMessageFileAttachmentId?: InputMaybe<UuidComparisonExp>;
  language?: InputMaybe<StringComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_file_attachment_translations" */
export enum ChatMessageFileAttachmentTranslationsConstraint {
  /** unique or primary key constraint on columns "language", "chat_message_file_attachment_id" */
  ChatMessageFileAttachmentTranslationsPkey = 'chat_message_file_attachment_translations_pkey'
}

/** input type for inserting data into table "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsInsertInput = {
  chatMessageFileAttachment?: InputMaybe<ChatMessageFileAttachmentsObjRelInsertInput>;
  chatMessageFileAttachmentId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChatMessageFileAttachmentTranslationsMaxFields = {
  __typename?: 'chat_message_file_attachment_translations_max_fields';
  chatMessageFileAttachmentId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsMaxOrderBy = {
  chatMessageFileAttachmentId?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatMessageFileAttachmentTranslationsMinFields = {
  __typename?: 'chat_message_file_attachment_translations_min_fields';
  chatMessageFileAttachmentId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsMinOrderBy = {
  chatMessageFileAttachmentId?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsMutationResponse = {
  __typename?: 'chat_message_file_attachment_translations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageFileAttachmentTranslations>;
};

/** on_conflict condition type for table "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsOnConflict = {
  constraint: ChatMessageFileAttachmentTranslationsConstraint;
  update_columns?: Array<ChatMessageFileAttachmentTranslationsUpdateColumn>;
  where?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
};

/** Ordering options when selecting data from "chat_message_file_attachment_translations". */
export type ChatMessageFileAttachmentTranslationsOrderBy = {
  chatMessageFileAttachment?: InputMaybe<ChatMessageFileAttachmentsOrderBy>;
  chatMessageFileAttachmentId?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_file_attachment_translations */
export type ChatMessageFileAttachmentTranslationsPkColumnsInput = {
  chatMessageFileAttachmentId: Scalars['uuid'];
  language: Scalars['String'];
};

/** select columns of table "chat_message_file_attachment_translations" */
export enum ChatMessageFileAttachmentTranslationsSelectColumn {
  /** column name */
  ChatMessageFileAttachmentId = 'chatMessageFileAttachmentId',
  /** column name */
  Language = 'language',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsSetInput = {
  chatMessageFileAttachmentId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message_file_attachment_translations" */
export type ChatMessageFileAttachmentTranslationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageFileAttachmentTranslationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageFileAttachmentTranslationsStreamCursorValueInput = {
  chatMessageFileAttachmentId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_message_file_attachment_translations" */
export enum ChatMessageFileAttachmentTranslationsUpdateColumn {
  /** column name */
  ChatMessageFileAttachmentId = 'chatMessageFileAttachmentId',
  /** column name */
  Language = 'language',
  /** column name */
  Text = 'text'
}

export type ChatMessageFileAttachmentTranslationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageFileAttachmentTranslationsSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageFileAttachmentTranslationsBoolExp;
};

/** columns and relationships of "chat_message_file_attachments" */
export type ChatMessageFileAttachments = {
  __typename?: 'chat_message_file_attachments';
  /** An object relationship */
  chatMessage: ChatMessages;
  /** An array relationship */
  chatMessageFileAttachmentTranslations: Array<ChatMessageFileAttachmentTranslations>;
  /** An aggregate relationship */
  chatMessageFileAttachmentTranslations_aggregate: ChatMessageFileAttachmentTranslationsAggregate;
  chatMessageId: Scalars['uuid'];
  contentLanguage?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  key: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  mimeType: Scalars['String'];
  thumbnailMediaKey?: Maybe<Scalars['String']>;
  transcribedText?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsChatMessageFileAttachmentTranslationsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
};


/** columns and relationships of "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsChatMessageFileAttachmentTranslationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
};


/** columns and relationships of "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsAggregate = {
  __typename?: 'chat_message_file_attachments_aggregate';
  aggregate?: Maybe<ChatMessageFileAttachmentsAggregateFields>;
  nodes: Array<ChatMessageFileAttachments>;
};

export type ChatMessageFileAttachmentsAggregateBoolExp = {
  count?: InputMaybe<ChatMessageFileAttachmentsAggregateBoolExpCount>;
};

export type ChatMessageFileAttachmentsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatMessageFileAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsAggregateFields = {
  __typename?: 'chat_message_file_attachments_aggregate_fields';
  avg?: Maybe<ChatMessageFileAttachmentsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ChatMessageFileAttachmentsMaxFields>;
  min?: Maybe<ChatMessageFileAttachmentsMinFields>;
  stddev?: Maybe<ChatMessageFileAttachmentsStddevFields>;
  stddev_pop?: Maybe<ChatMessageFileAttachmentsStddevPopFields>;
  stddev_samp?: Maybe<ChatMessageFileAttachmentsStddevSampFields>;
  sum?: Maybe<ChatMessageFileAttachmentsSumFields>;
  var_pop?: Maybe<ChatMessageFileAttachmentsVarPopFields>;
  var_samp?: Maybe<ChatMessageFileAttachmentsVarSampFields>;
  variance?: Maybe<ChatMessageFileAttachmentsVarianceFields>;
};


/** aggregate fields of "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageFileAttachmentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsAggregateOrderBy = {
  avg?: InputMaybe<ChatMessageFileAttachmentsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatMessageFileAttachmentsMaxOrderBy>;
  min?: InputMaybe<ChatMessageFileAttachmentsMinOrderBy>;
  stddev?: InputMaybe<ChatMessageFileAttachmentsStddevOrderBy>;
  stddev_pop?: InputMaybe<ChatMessageFileAttachmentsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ChatMessageFileAttachmentsStddevSampOrderBy>;
  sum?: InputMaybe<ChatMessageFileAttachmentsSumOrderBy>;
  var_pop?: InputMaybe<ChatMessageFileAttachmentsVarPopOrderBy>;
  var_samp?: InputMaybe<ChatMessageFileAttachmentsVarSampOrderBy>;
  variance?: InputMaybe<ChatMessageFileAttachmentsVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ChatMessageFileAttachmentsAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsArrRelInsertInput = {
  data: Array<ChatMessageFileAttachmentsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageFileAttachmentsOnConflict>;
};

/** aggregate avg on columns */
export type ChatMessageFileAttachmentsAvgFields = {
  __typename?: 'chat_message_file_attachments_avg_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsAvgOrderBy = {
  height?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "chat_message_file_attachments". All fields are combined with a logical 'AND'. */
export type ChatMessageFileAttachmentsBoolExp = {
  _and?: InputMaybe<Array<ChatMessageFileAttachmentsBoolExp>>;
  _not?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
  _or?: InputMaybe<Array<ChatMessageFileAttachmentsBoolExp>>;
  chatMessage?: InputMaybe<ChatMessagesBoolExp>;
  chatMessageFileAttachmentTranslations?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
  chatMessageFileAttachmentTranslations_aggregate?: InputMaybe<ChatMessageFileAttachmentTranslationsAggregateBoolExp>;
  chatMessageId?: InputMaybe<UuidComparisonExp>;
  contentLanguage?: InputMaybe<StringComparisonExp>;
  filename?: InputMaybe<StringComparisonExp>;
  height?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  key?: InputMaybe<StringComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  mimeType?: InputMaybe<StringComparisonExp>;
  thumbnailMediaKey?: InputMaybe<StringComparisonExp>;
  transcribedText?: InputMaybe<StringComparisonExp>;
  width?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_file_attachments" */
export enum ChatMessageFileAttachmentsConstraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessageFileAttachmentsPkey = 'chat_message_file_attachments_pkey',
  /** unique or primary key constraint on columns "key" */
  ChatMessageFileAttachmentsS3KeyKey = 'chat_message_file_attachments_s3_key_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ChatMessageFileAttachmentsDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ChatMessageFileAttachmentsDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ChatMessageFileAttachmentsDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsIncInput = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsInsertInput = {
  chatMessage?: InputMaybe<ChatMessagesObjRelInsertInput>;
  chatMessageFileAttachmentTranslations?: InputMaybe<ChatMessageFileAttachmentTranslationsArrRelInsertInput>;
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  contentLanguage?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mimeType?: InputMaybe<Scalars['String']>;
  thumbnailMediaKey?: InputMaybe<Scalars['String']>;
  transcribedText?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ChatMessageFileAttachmentsMaxFields = {
  __typename?: 'chat_message_file_attachments_max_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  contentLanguage?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  thumbnailMediaKey?: Maybe<Scalars['String']>;
  transcribedText?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsMaxOrderBy = {
  chatMessageId?: InputMaybe<OrderBy>;
  contentLanguage?: InputMaybe<OrderBy>;
  filename?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  key?: InputMaybe<OrderBy>;
  mimeType?: InputMaybe<OrderBy>;
  thumbnailMediaKey?: InputMaybe<OrderBy>;
  transcribedText?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatMessageFileAttachmentsMinFields = {
  __typename?: 'chat_message_file_attachments_min_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  contentLanguage?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  thumbnailMediaKey?: Maybe<Scalars['String']>;
  transcribedText?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsMinOrderBy = {
  chatMessageId?: InputMaybe<OrderBy>;
  contentLanguage?: InputMaybe<OrderBy>;
  filename?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  key?: InputMaybe<OrderBy>;
  mimeType?: InputMaybe<OrderBy>;
  thumbnailMediaKey?: InputMaybe<OrderBy>;
  transcribedText?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsMutationResponse = {
  __typename?: 'chat_message_file_attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageFileAttachments>;
};

/** input type for inserting object relation for remote table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsObjRelInsertInput = {
  data: ChatMessageFileAttachmentsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageFileAttachmentsOnConflict>;
};

/** on_conflict condition type for table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsOnConflict = {
  constraint: ChatMessageFileAttachmentsConstraint;
  update_columns?: Array<ChatMessageFileAttachmentsUpdateColumn>;
  where?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
};

/** Ordering options when selecting data from "chat_message_file_attachments". */
export type ChatMessageFileAttachmentsOrderBy = {
  chatMessage?: InputMaybe<ChatMessagesOrderBy>;
  chatMessageFileAttachmentTranslations_aggregate?: InputMaybe<ChatMessageFileAttachmentTranslationsAggregateOrderBy>;
  chatMessageId?: InputMaybe<OrderBy>;
  contentLanguage?: InputMaybe<OrderBy>;
  filename?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  key?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  mimeType?: InputMaybe<OrderBy>;
  thumbnailMediaKey?: InputMaybe<OrderBy>;
  transcribedText?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_file_attachments */
export type ChatMessageFileAttachmentsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ChatMessageFileAttachmentsPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "chat_message_file_attachments" */
export enum ChatMessageFileAttachmentsSelectColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  ContentLanguage = 'contentLanguage',
  /** column name */
  Filename = 'filename',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  ThumbnailMediaKey = 'thumbnailMediaKey',
  /** column name */
  TranscribedText = 'transcribedText',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsSetInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  contentLanguage?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mimeType?: InputMaybe<Scalars['String']>;
  thumbnailMediaKey?: InputMaybe<Scalars['String']>;
  transcribedText?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ChatMessageFileAttachmentsStddevFields = {
  __typename?: 'chat_message_file_attachments_stddev_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsStddevOrderBy = {
  height?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ChatMessageFileAttachmentsStddevPopFields = {
  __typename?: 'chat_message_file_attachments_stddev_pop_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsStddevPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ChatMessageFileAttachmentsStddevSampFields = {
  __typename?: 'chat_message_file_attachments_stddev_samp_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsStddevSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageFileAttachmentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageFileAttachmentsStreamCursorValueInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  contentLanguage?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mimeType?: InputMaybe<Scalars['String']>;
  thumbnailMediaKey?: InputMaybe<Scalars['String']>;
  transcribedText?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ChatMessageFileAttachmentsSumFields = {
  __typename?: 'chat_message_file_attachments_sum_fields';
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsSumOrderBy = {
  height?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** update columns of table "chat_message_file_attachments" */
export enum ChatMessageFileAttachmentsUpdateColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  ContentLanguage = 'contentLanguage',
  /** column name */
  Filename = 'filename',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  ThumbnailMediaKey = 'thumbnailMediaKey',
  /** column name */
  TranscribedText = 'transcribedText',
  /** column name */
  Width = 'width'
}

export type ChatMessageFileAttachmentsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ChatMessageFileAttachmentsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ChatMessageFileAttachmentsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ChatMessageFileAttachmentsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ChatMessageFileAttachmentsDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChatMessageFileAttachmentsIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ChatMessageFileAttachmentsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageFileAttachmentsSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageFileAttachmentsBoolExp;
};

/** aggregate var_pop on columns */
export type ChatMessageFileAttachmentsVarPopFields = {
  __typename?: 'chat_message_file_attachments_var_pop_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsVarPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ChatMessageFileAttachmentsVarSampFields = {
  __typename?: 'chat_message_file_attachments_var_samp_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsVarSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ChatMessageFileAttachmentsVarianceFields = {
  __typename?: 'chat_message_file_attachments_variance_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "chat_message_file_attachments" */
export type ChatMessageFileAttachmentsVarianceOrderBy = {
  height?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** columns and relationships of "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypes = {
  __typename?: 'chat_message_template_placeholder_types';
  type: Scalars['String'];
};

/** aggregated selection of "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypesAggregate = {
  __typename?: 'chat_message_template_placeholder_types_aggregate';
  aggregate?: Maybe<ChatMessageTemplatePlaceholderTypesAggregateFields>;
  nodes: Array<ChatMessageTemplatePlaceholderTypes>;
};

/** aggregate fields of "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypesAggregateFields = {
  __typename?: 'chat_message_template_placeholder_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessageTemplatePlaceholderTypesMaxFields>;
  min?: Maybe<ChatMessageTemplatePlaceholderTypesMinFields>;
};


/** aggregate fields of "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_message_template_placeholder_types". All fields are combined with a logical 'AND'. */
export type ChatMessageTemplatePlaceholderTypesBoolExp = {
  _and?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesBoolExp>>;
  _not?: InputMaybe<ChatMessageTemplatePlaceholderTypesBoolExp>;
  _or?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_template_placeholder_types" */
export enum ChatMessageTemplatePlaceholderTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  ChatMessageTemplatePlaceholderTypesPkey = 'chat_message_template_placeholder_types_pkey'
}

export enum ChatMessageTemplatePlaceholderTypesEnum {
  FreeText = 'FREE_TEXT',
  MerchantCustomerUserCompanyName = 'MERCHANT_CUSTOMER_USER_COMPANY_NAME',
  MerchantCustomerUserFirstName = 'MERCHANT_CUSTOMER_USER_FIRST_NAME',
  MerchantCustomerUserLastName = 'MERCHANT_CUSTOMER_USER_LAST_NAME',
  MerchantCustomerUserResponsibleMerchantUserName = 'MERCHANT_CUSTOMER_USER_RESPONSIBLE_MERCHANT_USER_NAME'
}

/** Boolean expression to compare columns of type "chat_message_template_placeholder_types_enum". All fields are combined with logical 'AND'. */
export type ChatMessageTemplatePlaceholderTypesEnumComparisonExp = {
  _eq?: InputMaybe<ChatMessageTemplatePlaceholderTypesEnum>;
  _in?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ChatMessageTemplatePlaceholderTypesEnum>;
  _nin?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesEnum>>;
};

/** input type for inserting data into table "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChatMessageTemplatePlaceholderTypesMaxFields = {
  __typename?: 'chat_message_template_placeholder_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ChatMessageTemplatePlaceholderTypesMinFields = {
  __typename?: 'chat_message_template_placeholder_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypesMutationResponse = {
  __typename?: 'chat_message_template_placeholder_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageTemplatePlaceholderTypes>;
};

/** input type for inserting object relation for remote table "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypesObjRelInsertInput = {
  data: ChatMessageTemplatePlaceholderTypesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageTemplatePlaceholderTypesOnConflict>;
};

/** on_conflict condition type for table "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypesOnConflict = {
  constraint: ChatMessageTemplatePlaceholderTypesConstraint;
  update_columns?: Array<ChatMessageTemplatePlaceholderTypesUpdateColumn>;
  where?: InputMaybe<ChatMessageTemplatePlaceholderTypesBoolExp>;
};

/** Ordering options when selecting data from "chat_message_template_placeholder_types". */
export type ChatMessageTemplatePlaceholderTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_template_placeholder_types */
export type ChatMessageTemplatePlaceholderTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "chat_message_template_placeholder_types" */
export enum ChatMessageTemplatePlaceholderTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message_template_placeholder_types" */
export type ChatMessageTemplatePlaceholderTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageTemplatePlaceholderTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageTemplatePlaceholderTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_message_template_placeholder_types" */
export enum ChatMessageTemplatePlaceholderTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type ChatMessageTemplatePlaceholderTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageTemplatePlaceholderTypesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageTemplatePlaceholderTypesBoolExp;
};

/** columns and relationships of "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholders = {
  __typename?: 'chat_message_template_placeholders';
  /** An object relationship */
  chatMessageTemplate: ChatMessageTemplates;
  chatMessageTemplateId: Scalars['uuid'];
  /** An object relationship */
  chatMessageTemplatePlaceholderType: ChatMessageTemplatePlaceholderTypes;
  exampleValue?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  index: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  maxLength: Scalars['Int'];
  type: ChatMessageTemplatePlaceholderTypesEnum;
};

/** aggregated selection of "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersAggregate = {
  __typename?: 'chat_message_template_placeholders_aggregate';
  aggregate?: Maybe<ChatMessageTemplatePlaceholdersAggregateFields>;
  nodes: Array<ChatMessageTemplatePlaceholders>;
};

export type ChatMessageTemplatePlaceholdersAggregateBoolExp = {
  count?: InputMaybe<ChatMessageTemplatePlaceholdersAggregateBoolExpCount>;
};

export type ChatMessageTemplatePlaceholdersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatMessageTemplatePlaceholdersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersAggregateFields = {
  __typename?: 'chat_message_template_placeholders_aggregate_fields';
  avg?: Maybe<ChatMessageTemplatePlaceholdersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ChatMessageTemplatePlaceholdersMaxFields>;
  min?: Maybe<ChatMessageTemplatePlaceholdersMinFields>;
  stddev?: Maybe<ChatMessageTemplatePlaceholdersStddevFields>;
  stddev_pop?: Maybe<ChatMessageTemplatePlaceholdersStddevPopFields>;
  stddev_samp?: Maybe<ChatMessageTemplatePlaceholdersStddevSampFields>;
  sum?: Maybe<ChatMessageTemplatePlaceholdersSumFields>;
  var_pop?: Maybe<ChatMessageTemplatePlaceholdersVarPopFields>;
  var_samp?: Maybe<ChatMessageTemplatePlaceholdersVarSampFields>;
  variance?: Maybe<ChatMessageTemplatePlaceholdersVarianceFields>;
};


/** aggregate fields of "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageTemplatePlaceholdersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersAggregateOrderBy = {
  avg?: InputMaybe<ChatMessageTemplatePlaceholdersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatMessageTemplatePlaceholdersMaxOrderBy>;
  min?: InputMaybe<ChatMessageTemplatePlaceholdersMinOrderBy>;
  stddev?: InputMaybe<ChatMessageTemplatePlaceholdersStddevOrderBy>;
  stddev_pop?: InputMaybe<ChatMessageTemplatePlaceholdersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ChatMessageTemplatePlaceholdersStddevSampOrderBy>;
  sum?: InputMaybe<ChatMessageTemplatePlaceholdersSumOrderBy>;
  var_pop?: InputMaybe<ChatMessageTemplatePlaceholdersVarPopOrderBy>;
  var_samp?: InputMaybe<ChatMessageTemplatePlaceholdersVarSampOrderBy>;
  variance?: InputMaybe<ChatMessageTemplatePlaceholdersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersArrRelInsertInput = {
  data: Array<ChatMessageTemplatePlaceholdersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageTemplatePlaceholdersOnConflict>;
};

/** aggregate avg on columns */
export type ChatMessageTemplatePlaceholdersAvgFields = {
  __typename?: 'chat_message_template_placeholders_avg_fields';
  index?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "chat_message_template_placeholders". All fields are combined with a logical 'AND'. */
export type ChatMessageTemplatePlaceholdersBoolExp = {
  _and?: InputMaybe<Array<ChatMessageTemplatePlaceholdersBoolExp>>;
  _not?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
  _or?: InputMaybe<Array<ChatMessageTemplatePlaceholdersBoolExp>>;
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesBoolExp>;
  chatMessageTemplateId?: InputMaybe<UuidComparisonExp>;
  chatMessageTemplatePlaceholderType?: InputMaybe<ChatMessageTemplatePlaceholderTypesBoolExp>;
  exampleValue?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  maxLength?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<ChatMessageTemplatePlaceholderTypesEnumComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_template_placeholders" */
export enum ChatMessageTemplatePlaceholdersConstraint {
  /** unique or primary key constraint on columns "chat_message_template_id", "index" */
  ChatMessageTemplatePlacehoIndexChatMessageTemplateIKey = 'chat_message_template_placeho_index_chat_message_template_i_key',
  /** unique or primary key constraint on columns "id" */
  ChatMessageTemplatePlaceholdersPkey = 'chat_message_template_placeholders_pkey'
}

/** input type for incrementing numeric columns in table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersIncInput = {
  index?: InputMaybe<Scalars['Int']>;
  maxLength?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersInsertInput = {
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesObjRelInsertInput>;
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  chatMessageTemplatePlaceholderType?: InputMaybe<ChatMessageTemplatePlaceholderTypesObjRelInsertInput>;
  exampleValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ChatMessageTemplatePlaceholderTypesEnum>;
};

/** aggregate max on columns */
export type ChatMessageTemplatePlaceholdersMaxFields = {
  __typename?: 'chat_message_template_placeholders_max_fields';
  chatMessageTemplateId?: Maybe<Scalars['uuid']>;
  exampleValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersMaxOrderBy = {
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  exampleValue?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatMessageTemplatePlaceholdersMinFields = {
  __typename?: 'chat_message_template_placeholders_min_fields';
  chatMessageTemplateId?: Maybe<Scalars['uuid']>;
  exampleValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersMinOrderBy = {
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  exampleValue?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersMutationResponse = {
  __typename?: 'chat_message_template_placeholders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageTemplatePlaceholders>;
};

/** input type for inserting object relation for remote table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersObjRelInsertInput = {
  data: ChatMessageTemplatePlaceholdersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageTemplatePlaceholdersOnConflict>;
};

/** on_conflict condition type for table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersOnConflict = {
  constraint: ChatMessageTemplatePlaceholdersConstraint;
  update_columns?: Array<ChatMessageTemplatePlaceholdersUpdateColumn>;
  where?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
};

/** Ordering options when selecting data from "chat_message_template_placeholders". */
export type ChatMessageTemplatePlaceholdersOrderBy = {
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesOrderBy>;
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  chatMessageTemplatePlaceholderType?: InputMaybe<ChatMessageTemplatePlaceholderTypesOrderBy>;
  exampleValue?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_template_placeholders */
export type ChatMessageTemplatePlaceholdersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_message_template_placeholders" */
export enum ChatMessageTemplatePlaceholdersSelectColumn {
  /** column name */
  ChatMessageTemplateId = 'chatMessageTemplateId',
  /** column name */
  ExampleValue = 'exampleValue',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Label = 'label',
  /** column name */
  MaxLength = 'maxLength',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersSetInput = {
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  exampleValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ChatMessageTemplatePlaceholderTypesEnum>;
};

/** aggregate stddev on columns */
export type ChatMessageTemplatePlaceholdersStddevFields = {
  __typename?: 'chat_message_template_placeholders_stddev_fields';
  index?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ChatMessageTemplatePlaceholdersStddevPopFields = {
  __typename?: 'chat_message_template_placeholders_stddev_pop_fields';
  index?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ChatMessageTemplatePlaceholdersStddevSampFields = {
  __typename?: 'chat_message_template_placeholders_stddev_samp_fields';
  index?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageTemplatePlaceholdersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageTemplatePlaceholdersStreamCursorValueInput = {
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  exampleValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  maxLength?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ChatMessageTemplatePlaceholderTypesEnum>;
};

/** aggregate sum on columns */
export type ChatMessageTemplatePlaceholdersSumFields = {
  __typename?: 'chat_message_template_placeholders_sum_fields';
  index?: Maybe<Scalars['Int']>;
  maxLength?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersSumOrderBy = {
  index?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** update columns of table "chat_message_template_placeholders" */
export enum ChatMessageTemplatePlaceholdersUpdateColumn {
  /** column name */
  ChatMessageTemplateId = 'chatMessageTemplateId',
  /** column name */
  ExampleValue = 'exampleValue',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Label = 'label',
  /** column name */
  MaxLength = 'maxLength',
  /** column name */
  Type = 'type'
}

export type ChatMessageTemplatePlaceholdersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChatMessageTemplatePlaceholdersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageTemplatePlaceholdersSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageTemplatePlaceholdersBoolExp;
};

/** aggregate var_pop on columns */
export type ChatMessageTemplatePlaceholdersVarPopFields = {
  __typename?: 'chat_message_template_placeholders_var_pop_fields';
  index?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ChatMessageTemplatePlaceholdersVarSampFields = {
  __typename?: 'chat_message_template_placeholders_var_samp_fields';
  index?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ChatMessageTemplatePlaceholdersVarianceFields = {
  __typename?: 'chat_message_template_placeholders_variance_fields';
  index?: Maybe<Scalars['Float']>;
  maxLength?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "chat_message_template_placeholders" */
export type ChatMessageTemplatePlaceholdersVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
  maxLength?: InputMaybe<OrderBy>;
};

/** columns and relationships of "chat_message_template_types" */
export type ChatMessageTemplateTypes = {
  __typename?: 'chat_message_template_types';
  type: Scalars['String'];
};

/** aggregated selection of "chat_message_template_types" */
export type ChatMessageTemplateTypesAggregate = {
  __typename?: 'chat_message_template_types_aggregate';
  aggregate?: Maybe<ChatMessageTemplateTypesAggregateFields>;
  nodes: Array<ChatMessageTemplateTypes>;
};

/** aggregate fields of "chat_message_template_types" */
export type ChatMessageTemplateTypesAggregateFields = {
  __typename?: 'chat_message_template_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessageTemplateTypesMaxFields>;
  min?: Maybe<ChatMessageTemplateTypesMinFields>;
};


/** aggregate fields of "chat_message_template_types" */
export type ChatMessageTemplateTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageTemplateTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_message_template_types". All fields are combined with a logical 'AND'. */
export type ChatMessageTemplateTypesBoolExp = {
  _and?: InputMaybe<Array<ChatMessageTemplateTypesBoolExp>>;
  _not?: InputMaybe<ChatMessageTemplateTypesBoolExp>;
  _or?: InputMaybe<Array<ChatMessageTemplateTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_template_types" */
export enum ChatMessageTemplateTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  WhatsappTemplateCategoriesPkey = 'whatsapp_template_categories_pkey'
}

/** input type for inserting data into table "chat_message_template_types" */
export type ChatMessageTemplateTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChatMessageTemplateTypesMaxFields = {
  __typename?: 'chat_message_template_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ChatMessageTemplateTypesMinFields = {
  __typename?: 'chat_message_template_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chat_message_template_types" */
export type ChatMessageTemplateTypesMutationResponse = {
  __typename?: 'chat_message_template_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageTemplateTypes>;
};

/** on_conflict condition type for table "chat_message_template_types" */
export type ChatMessageTemplateTypesOnConflict = {
  constraint: ChatMessageTemplateTypesConstraint;
  update_columns?: Array<ChatMessageTemplateTypesUpdateColumn>;
  where?: InputMaybe<ChatMessageTemplateTypesBoolExp>;
};

/** Ordering options when selecting data from "chat_message_template_types". */
export type ChatMessageTemplateTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_template_types */
export type ChatMessageTemplateTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "chat_message_template_types" */
export enum ChatMessageTemplateTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chat_message_template_types" */
export type ChatMessageTemplateTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message_template_types" */
export type ChatMessageTemplateTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageTemplateTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageTemplateTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_message_template_types" */
export enum ChatMessageTemplateTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type ChatMessageTemplateTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageTemplateTypesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageTemplateTypesBoolExp;
};

/** columns and relationships of "chat_message_template_values" */
export type ChatMessageTemplateValues = {
  __typename?: 'chat_message_template_values';
  /** An object relationship */
  chatMessage: ChatMessages;
  chatMessageId: Scalars['uuid'];
  /** An object relationship */
  chatMessageTemplatePlaceholder: ChatMessageTemplatePlaceholders;
  chatMessageTemplatePlaceholderId: Scalars['uuid'];
  id: Scalars['uuid'];
  value: Scalars['String'];
};

/** aggregated selection of "chat_message_template_values" */
export type ChatMessageTemplateValuesAggregate = {
  __typename?: 'chat_message_template_values_aggregate';
  aggregate?: Maybe<ChatMessageTemplateValuesAggregateFields>;
  nodes: Array<ChatMessageTemplateValues>;
};

export type ChatMessageTemplateValuesAggregateBoolExp = {
  count?: InputMaybe<ChatMessageTemplateValuesAggregateBoolExpCount>;
};

export type ChatMessageTemplateValuesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatMessageTemplateValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_message_template_values" */
export type ChatMessageTemplateValuesAggregateFields = {
  __typename?: 'chat_message_template_values_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessageTemplateValuesMaxFields>;
  min?: Maybe<ChatMessageTemplateValuesMinFields>;
};


/** aggregate fields of "chat_message_template_values" */
export type ChatMessageTemplateValuesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageTemplateValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_template_values" */
export type ChatMessageTemplateValuesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatMessageTemplateValuesMaxOrderBy>;
  min?: InputMaybe<ChatMessageTemplateValuesMinOrderBy>;
};

/** input type for inserting array relation for remote table "chat_message_template_values" */
export type ChatMessageTemplateValuesArrRelInsertInput = {
  data: Array<ChatMessageTemplateValuesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageTemplateValuesOnConflict>;
};

/** Boolean expression to filter rows from the table "chat_message_template_values". All fields are combined with a logical 'AND'. */
export type ChatMessageTemplateValuesBoolExp = {
  _and?: InputMaybe<Array<ChatMessageTemplateValuesBoolExp>>;
  _not?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
  _or?: InputMaybe<Array<ChatMessageTemplateValuesBoolExp>>;
  chatMessage?: InputMaybe<ChatMessagesBoolExp>;
  chatMessageId?: InputMaybe<UuidComparisonExp>;
  chatMessageTemplatePlaceholder?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
  chatMessageTemplatePlaceholderId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_template_values" */
export enum ChatMessageTemplateValuesConstraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessageTemplateValuesPkey = 'chat_message_template_values_pkey'
}

/** input type for inserting data into table "chat_message_template_values" */
export type ChatMessageTemplateValuesInsertInput = {
  chatMessage?: InputMaybe<ChatMessagesObjRelInsertInput>;
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  chatMessageTemplatePlaceholder?: InputMaybe<ChatMessageTemplatePlaceholdersObjRelInsertInput>;
  chatMessageTemplatePlaceholderId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChatMessageTemplateValuesMaxFields = {
  __typename?: 'chat_message_template_values_max_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  chatMessageTemplatePlaceholderId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "chat_message_template_values" */
export type ChatMessageTemplateValuesMaxOrderBy = {
  chatMessageId?: InputMaybe<OrderBy>;
  chatMessageTemplatePlaceholderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatMessageTemplateValuesMinFields = {
  __typename?: 'chat_message_template_values_min_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  chatMessageTemplatePlaceholderId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "chat_message_template_values" */
export type ChatMessageTemplateValuesMinOrderBy = {
  chatMessageId?: InputMaybe<OrderBy>;
  chatMessageTemplatePlaceholderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_message_template_values" */
export type ChatMessageTemplateValuesMutationResponse = {
  __typename?: 'chat_message_template_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageTemplateValues>;
};

/** on_conflict condition type for table "chat_message_template_values" */
export type ChatMessageTemplateValuesOnConflict = {
  constraint: ChatMessageTemplateValuesConstraint;
  update_columns?: Array<ChatMessageTemplateValuesUpdateColumn>;
  where?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
};

/** Ordering options when selecting data from "chat_message_template_values". */
export type ChatMessageTemplateValuesOrderBy = {
  chatMessage?: InputMaybe<ChatMessagesOrderBy>;
  chatMessageId?: InputMaybe<OrderBy>;
  chatMessageTemplatePlaceholder?: InputMaybe<ChatMessageTemplatePlaceholdersOrderBy>;
  chatMessageTemplatePlaceholderId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_template_values */
export type ChatMessageTemplateValuesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_message_template_values" */
export enum ChatMessageTemplateValuesSelectColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  ChatMessageTemplatePlaceholderId = 'chatMessageTemplatePlaceholderId',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "chat_message_template_values" */
export type ChatMessageTemplateValuesSetInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  chatMessageTemplatePlaceholderId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message_template_values" */
export type ChatMessageTemplateValuesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageTemplateValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageTemplateValuesStreamCursorValueInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  chatMessageTemplatePlaceholderId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_message_template_values" */
export enum ChatMessageTemplateValuesUpdateColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  ChatMessageTemplatePlaceholderId = 'chatMessageTemplatePlaceholderId',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

export type ChatMessageTemplateValuesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageTemplateValuesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageTemplateValuesBoolExp;
};

/** columns and relationships of "chat_message_templates" */
export type ChatMessageTemplates = {
  __typename?: 'chat_message_templates';
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  chatMessageTemplatePlaceholders: Array<ChatMessageTemplatePlaceholders>;
  /** An aggregate relationship */
  chatMessageTemplatePlaceholders_aggregate: ChatMessageTemplatePlaceholdersAggregate;
  /** A computed field, executes function "chat_message_templates_has_free_text_placeholders" */
  hasFreeTextPlaceholders?: Maybe<Scalars['Boolean']>;
  headerMediaKey?: Maybe<Scalars['String']>;
  headerMediaMimeType?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  insertionTimestamp: Scalars['timestamptz'];
  insertionUserId: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  isListed: Scalars['Boolean'];
  isStandardFileAttachmentTemplate: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  /** An object relationship */
  merchant: Merchants;
  merchantId: Scalars['uuid'];
  name: Scalars['String'];
  standardFileAttachmentType?: Maybe<Scalars['String']>;
  /** A computed field, executes function "chat_message_templates_status" */
  status?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: Scalars['String'];
  /** An array relationship */
  whatsappMessageTemplates: Array<WhatsappMessageTemplates>;
  /** An aggregate relationship */
  whatsappMessageTemplates_aggregate: WhatsappMessageTemplatesAggregate;
};


/** columns and relationships of "chat_message_templates" */
export type ChatMessageTemplatesChatMessageTemplatePlaceholdersArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholdersOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
};


/** columns and relationships of "chat_message_templates" */
export type ChatMessageTemplatesChatMessageTemplatePlaceholdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholdersOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
};


/** columns and relationships of "chat_message_templates" */
export type ChatMessageTemplatesWhatsappMessageTemplatesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplatesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};


/** columns and relationships of "chat_message_templates" */
export type ChatMessageTemplatesWhatsappMessageTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplatesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};

/** aggregated selection of "chat_message_templates" */
export type ChatMessageTemplatesAggregate = {
  __typename?: 'chat_message_templates_aggregate';
  aggregate?: Maybe<ChatMessageTemplatesAggregateFields>;
  nodes: Array<ChatMessageTemplates>;
};

export type ChatMessageTemplatesAggregateBoolExp = {
  bool_and?: InputMaybe<ChatMessageTemplatesAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ChatMessageTemplatesAggregateBoolExpBoolOr>;
  count?: InputMaybe<ChatMessageTemplatesAggregateBoolExpCount>;
};

export type ChatMessageTemplatesAggregateBoolExpBoolAnd = {
  arguments: ChatMessageTemplatesSelectColumnChatMessageTemplatesAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessageTemplatesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChatMessageTemplatesAggregateBoolExpBoolOr = {
  arguments: ChatMessageTemplatesSelectColumnChatMessageTemplatesAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessageTemplatesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChatMessageTemplatesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatMessageTemplatesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessageTemplatesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_message_templates" */
export type ChatMessageTemplatesAggregateFields = {
  __typename?: 'chat_message_templates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessageTemplatesMaxFields>;
  min?: Maybe<ChatMessageTemplatesMinFields>;
};


/** aggregate fields of "chat_message_templates" */
export type ChatMessageTemplatesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageTemplatesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_templates" */
export type ChatMessageTemplatesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatMessageTemplatesMaxOrderBy>;
  min?: InputMaybe<ChatMessageTemplatesMinOrderBy>;
};

/** input type for inserting array relation for remote table "chat_message_templates" */
export type ChatMessageTemplatesArrRelInsertInput = {
  data: Array<ChatMessageTemplatesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageTemplatesOnConflict>;
};

/** Boolean expression to filter rows from the table "chat_message_templates". All fields are combined with a logical 'AND'. */
export type ChatMessageTemplatesBoolExp = {
  _and?: InputMaybe<Array<ChatMessageTemplatesBoolExp>>;
  _not?: InputMaybe<ChatMessageTemplatesBoolExp>;
  _or?: InputMaybe<Array<ChatMessageTemplatesBoolExp>>;
  buttonText?: InputMaybe<StringComparisonExp>;
  buttonUrl?: InputMaybe<StringComparisonExp>;
  chatMessageTemplatePlaceholders?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
  chatMessageTemplatePlaceholders_aggregate?: InputMaybe<ChatMessageTemplatePlaceholdersAggregateBoolExp>;
  hasFreeTextPlaceholders?: InputMaybe<BooleanComparisonExp>;
  headerMediaKey?: InputMaybe<StringComparisonExp>;
  headerMediaMimeType?: InputMaybe<StringComparisonExp>;
  headline?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  insertionUserId?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  isListed?: InputMaybe<BooleanComparisonExp>;
  isStandardFileAttachmentTemplate?: InputMaybe<BooleanComparisonExp>;
  language?: InputMaybe<StringComparisonExp>;
  merchant?: InputMaybe<MerchantsBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  standardFileAttachmentType?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  whatsappMessageTemplates?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
  whatsappMessageTemplates_aggregate?: InputMaybe<WhatsappMessageTemplatesAggregateBoolExp>;
};

/** unique or primary key constraints on table "chat_message_templates" */
export enum ChatMessageTemplatesConstraint {
  /** unique or primary key constraint on columns "merchant_id", "name" */
  ChatMessageTemplatesMerchantIdNameIdx = 'chat_message_templates_merchant_id_name_idx',
  /** unique or primary key constraint on columns "merchant_id", "name" */
  ChatMessageTemplatesMerchantIdNameKey = 'chat_message_templates_merchant_id_name_key',
  /** unique or primary key constraint on columns "id" */
  ChatMessageTemplatesPkey = 'chat_message_templates_pkey'
}

/** input type for inserting data into table "chat_message_templates" */
export type ChatMessageTemplatesInsertInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  chatMessageTemplatePlaceholders?: InputMaybe<ChatMessageTemplatePlaceholdersArrRelInsertInput>;
  headerMediaKey?: InputMaybe<Scalars['String']>;
  headerMediaMimeType?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isListed?: InputMaybe<Scalars['Boolean']>;
  isStandardFileAttachmentTemplate?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  merchant?: InputMaybe<MerchantsObjRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  standardFileAttachmentType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  whatsappMessageTemplates?: InputMaybe<WhatsappMessageTemplatesArrRelInsertInput>;
};

/** aggregate max on columns */
export type ChatMessageTemplatesMaxFields = {
  __typename?: 'chat_message_templates_max_fields';
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  headerMediaKey?: Maybe<Scalars['String']>;
  headerMediaMimeType?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  standardFileAttachmentType?: Maybe<Scalars['String']>;
  /** A computed field, executes function "chat_message_templates_status" */
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "chat_message_templates" */
export type ChatMessageTemplatesMaxOrderBy = {
  buttonText?: InputMaybe<OrderBy>;
  buttonUrl?: InputMaybe<OrderBy>;
  headerMediaKey?: InputMaybe<OrderBy>;
  headerMediaMimeType?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  standardFileAttachmentType?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatMessageTemplatesMinFields = {
  __typename?: 'chat_message_templates_min_fields';
  buttonText?: Maybe<Scalars['String']>;
  buttonUrl?: Maybe<Scalars['String']>;
  headerMediaKey?: Maybe<Scalars['String']>;
  headerMediaMimeType?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  standardFileAttachmentType?: Maybe<Scalars['String']>;
  /** A computed field, executes function "chat_message_templates_status" */
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "chat_message_templates" */
export type ChatMessageTemplatesMinOrderBy = {
  buttonText?: InputMaybe<OrderBy>;
  buttonUrl?: InputMaybe<OrderBy>;
  headerMediaKey?: InputMaybe<OrderBy>;
  headerMediaMimeType?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  standardFileAttachmentType?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_message_templates" */
export type ChatMessageTemplatesMutationResponse = {
  __typename?: 'chat_message_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageTemplates>;
};

/** input type for inserting object relation for remote table "chat_message_templates" */
export type ChatMessageTemplatesObjRelInsertInput = {
  data: ChatMessageTemplatesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageTemplatesOnConflict>;
};

/** on_conflict condition type for table "chat_message_templates" */
export type ChatMessageTemplatesOnConflict = {
  constraint: ChatMessageTemplatesConstraint;
  update_columns?: Array<ChatMessageTemplatesUpdateColumn>;
  where?: InputMaybe<ChatMessageTemplatesBoolExp>;
};

/** Ordering options when selecting data from "chat_message_templates". */
export type ChatMessageTemplatesOrderBy = {
  buttonText?: InputMaybe<OrderBy>;
  buttonUrl?: InputMaybe<OrderBy>;
  chatMessageTemplatePlaceholders_aggregate?: InputMaybe<ChatMessageTemplatePlaceholdersAggregateOrderBy>;
  hasFreeTextPlaceholders?: InputMaybe<OrderBy>;
  headerMediaKey?: InputMaybe<OrderBy>;
  headerMediaMimeType?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  isListed?: InputMaybe<OrderBy>;
  isStandardFileAttachmentTemplate?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantsOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  standardFileAttachmentType?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  whatsappMessageTemplates_aggregate?: InputMaybe<WhatsappMessageTemplatesAggregateOrderBy>;
};

/** primary key columns input for table: chat_message_templates */
export type ChatMessageTemplatesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_message_templates" */
export enum ChatMessageTemplatesSelectColumn {
  /** column name */
  ButtonText = 'buttonText',
  /** column name */
  ButtonUrl = 'buttonUrl',
  /** column name */
  HeaderMediaKey = 'headerMediaKey',
  /** column name */
  HeaderMediaMimeType = 'headerMediaMimeType',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsListed = 'isListed',
  /** column name */
  IsStandardFileAttachmentTemplate = 'isStandardFileAttachmentTemplate',
  /** column name */
  Language = 'language',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name',
  /** column name */
  StandardFileAttachmentType = 'standardFileAttachmentType',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type'
}

/** select "chat_message_templates_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_message_templates" */
export enum ChatMessageTemplatesSelectColumnChatMessageTemplatesAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsListed = 'isListed',
  /** column name */
  IsStandardFileAttachmentTemplate = 'isStandardFileAttachmentTemplate'
}

/** select "chat_message_templates_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_message_templates" */
export enum ChatMessageTemplatesSelectColumnChatMessageTemplatesAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsListed = 'isListed',
  /** column name */
  IsStandardFileAttachmentTemplate = 'isStandardFileAttachmentTemplate'
}

/** input type for updating data in table "chat_message_templates" */
export type ChatMessageTemplatesSetInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  headerMediaKey?: InputMaybe<Scalars['String']>;
  headerMediaMimeType?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isListed?: InputMaybe<Scalars['Boolean']>;
  isStandardFileAttachmentTemplate?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  standardFileAttachmentType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message_templates" */
export type ChatMessageTemplatesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageTemplatesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageTemplatesStreamCursorValueInput = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  headerMediaKey?: InputMaybe<Scalars['String']>;
  headerMediaMimeType?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isListed?: InputMaybe<Scalars['Boolean']>;
  isStandardFileAttachmentTemplate?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  standardFileAttachmentType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_message_templates" */
export enum ChatMessageTemplatesUpdateColumn {
  /** column name */
  ButtonText = 'buttonText',
  /** column name */
  ButtonUrl = 'buttonUrl',
  /** column name */
  HeaderMediaKey = 'headerMediaKey',
  /** column name */
  HeaderMediaMimeType = 'headerMediaMimeType',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsListed = 'isListed',
  /** column name */
  IsStandardFileAttachmentTemplate = 'isStandardFileAttachmentTemplate',
  /** column name */
  Language = 'language',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name',
  /** column name */
  StandardFileAttachmentType = 'standardFileAttachmentType',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type'
}

export type ChatMessageTemplatesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageTemplatesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageTemplatesBoolExp;
};

/** columns and relationships of "chat_message_translations" */
export type ChatMessageTranslations = {
  __typename?: 'chat_message_translations';
  /** An object relationship */
  chatMessage: ChatMessages;
  chatMessageId: Scalars['uuid'];
  language: Scalars['String'];
  text: Scalars['String'];
};

/** aggregated selection of "chat_message_translations" */
export type ChatMessageTranslationsAggregate = {
  __typename?: 'chat_message_translations_aggregate';
  aggregate?: Maybe<ChatMessageTranslationsAggregateFields>;
  nodes: Array<ChatMessageTranslations>;
};

export type ChatMessageTranslationsAggregateBoolExp = {
  count?: InputMaybe<ChatMessageTranslationsAggregateBoolExpCount>;
};

export type ChatMessageTranslationsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatMessageTranslationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessageTranslationsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_message_translations" */
export type ChatMessageTranslationsAggregateFields = {
  __typename?: 'chat_message_translations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessageTranslationsMaxFields>;
  min?: Maybe<ChatMessageTranslationsMinFields>;
};


/** aggregate fields of "chat_message_translations" */
export type ChatMessageTranslationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageTranslationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_translations" */
export type ChatMessageTranslationsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatMessageTranslationsMaxOrderBy>;
  min?: InputMaybe<ChatMessageTranslationsMinOrderBy>;
};

/** input type for inserting array relation for remote table "chat_message_translations" */
export type ChatMessageTranslationsArrRelInsertInput = {
  data: Array<ChatMessageTranslationsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageTranslationsOnConflict>;
};

/** Boolean expression to filter rows from the table "chat_message_translations". All fields are combined with a logical 'AND'. */
export type ChatMessageTranslationsBoolExp = {
  _and?: InputMaybe<Array<ChatMessageTranslationsBoolExp>>;
  _not?: InputMaybe<ChatMessageTranslationsBoolExp>;
  _or?: InputMaybe<Array<ChatMessageTranslationsBoolExp>>;
  chatMessage?: InputMaybe<ChatMessagesBoolExp>;
  chatMessageId?: InputMaybe<UuidComparisonExp>;
  language?: InputMaybe<StringComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_translations" */
export enum ChatMessageTranslationsConstraint {
  /** unique or primary key constraint on columns "language", "chat_message_id" */
  ChatMessageTranslationsPkey = 'chat_message_translations_pkey'
}

/** input type for inserting data into table "chat_message_translations" */
export type ChatMessageTranslationsInsertInput = {
  chatMessage?: InputMaybe<ChatMessagesObjRelInsertInput>;
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChatMessageTranslationsMaxFields = {
  __typename?: 'chat_message_translations_max_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "chat_message_translations" */
export type ChatMessageTranslationsMaxOrderBy = {
  chatMessageId?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatMessageTranslationsMinFields = {
  __typename?: 'chat_message_translations_min_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "chat_message_translations" */
export type ChatMessageTranslationsMinOrderBy = {
  chatMessageId?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_message_translations" */
export type ChatMessageTranslationsMutationResponse = {
  __typename?: 'chat_message_translations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageTranslations>;
};

/** on_conflict condition type for table "chat_message_translations" */
export type ChatMessageTranslationsOnConflict = {
  constraint: ChatMessageTranslationsConstraint;
  update_columns?: Array<ChatMessageTranslationsUpdateColumn>;
  where?: InputMaybe<ChatMessageTranslationsBoolExp>;
};

/** Ordering options when selecting data from "chat_message_translations". */
export type ChatMessageTranslationsOrderBy = {
  chatMessage?: InputMaybe<ChatMessagesOrderBy>;
  chatMessageId?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_translations */
export type ChatMessageTranslationsPkColumnsInput = {
  chatMessageId: Scalars['uuid'];
  language: Scalars['String'];
};

/** select columns of table "chat_message_translations" */
export enum ChatMessageTranslationsSelectColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  Language = 'language',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "chat_message_translations" */
export type ChatMessageTranslationsSetInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message_translations" */
export type ChatMessageTranslationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageTranslationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageTranslationsStreamCursorValueInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_message_translations" */
export enum ChatMessageTranslationsUpdateColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  Language = 'language',
  /** column name */
  Text = 'text'
}

export type ChatMessageTranslationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageTranslationsSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageTranslationsBoolExp;
};

/** columns and relationships of "chat_message_types" */
export type ChatMessageTypes = {
  __typename?: 'chat_message_types';
  type: Scalars['String'];
};

/** aggregated selection of "chat_message_types" */
export type ChatMessageTypesAggregate = {
  __typename?: 'chat_message_types_aggregate';
  aggregate?: Maybe<ChatMessageTypesAggregateFields>;
  nodes: Array<ChatMessageTypes>;
};

/** aggregate fields of "chat_message_types" */
export type ChatMessageTypesAggregateFields = {
  __typename?: 'chat_message_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessageTypesMaxFields>;
  min?: Maybe<ChatMessageTypesMinFields>;
};


/** aggregate fields of "chat_message_types" */
export type ChatMessageTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_message_types". All fields are combined with a logical 'AND'. */
export type ChatMessageTypesBoolExp = {
  _and?: InputMaybe<Array<ChatMessageTypesBoolExp>>;
  _not?: InputMaybe<ChatMessageTypesBoolExp>;
  _or?: InputMaybe<Array<ChatMessageTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_types" */
export enum ChatMessageTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  ChatMessageTypesPkey = 'chat_message_types_pkey'
}

/** input type for inserting data into table "chat_message_types" */
export type ChatMessageTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChatMessageTypesMaxFields = {
  __typename?: 'chat_message_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ChatMessageTypesMinFields = {
  __typename?: 'chat_message_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chat_message_types" */
export type ChatMessageTypesMutationResponse = {
  __typename?: 'chat_message_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageTypes>;
};

/** on_conflict condition type for table "chat_message_types" */
export type ChatMessageTypesOnConflict = {
  constraint: ChatMessageTypesConstraint;
  update_columns?: Array<ChatMessageTypesUpdateColumn>;
  where?: InputMaybe<ChatMessageTypesBoolExp>;
};

/** Ordering options when selecting data from "chat_message_types". */
export type ChatMessageTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_types */
export type ChatMessageTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "chat_message_types" */
export enum ChatMessageTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chat_message_types" */
export type ChatMessageTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_message_types" */
export type ChatMessageTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_message_types" */
export enum ChatMessageTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type ChatMessageTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageTypesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageTypesBoolExp;
};

/** columns and relationships of "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessages = {
  __typename?: 'chat_message_whatsapp_messages';
  /** An object relationship */
  chatMessage: ChatMessages;
  chatMessageId: Scalars['uuid'];
  /** An object relationship */
  whatsappMessage: WhatsappMessages;
  whatsappMessageId: Scalars['uuid'];
};

/** aggregated selection of "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesAggregate = {
  __typename?: 'chat_message_whatsapp_messages_aggregate';
  aggregate?: Maybe<ChatMessageWhatsappMessagesAggregateFields>;
  nodes: Array<ChatMessageWhatsappMessages>;
};

export type ChatMessageWhatsappMessagesAggregateBoolExp = {
  count?: InputMaybe<ChatMessageWhatsappMessagesAggregateBoolExpCount>;
};

export type ChatMessageWhatsappMessagesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesAggregateFields = {
  __typename?: 'chat_message_whatsapp_messages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessageWhatsappMessagesMaxFields>;
  min?: Maybe<ChatMessageWhatsappMessagesMinFields>;
};


/** aggregate fields of "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatMessageWhatsappMessagesMaxOrderBy>;
  min?: InputMaybe<ChatMessageWhatsappMessagesMinOrderBy>;
};

/** input type for inserting array relation for remote table "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesArrRelInsertInput = {
  data: Array<ChatMessageWhatsappMessagesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessageWhatsappMessagesOnConflict>;
};

/** Boolean expression to filter rows from the table "chat_message_whatsapp_messages". All fields are combined with a logical 'AND'. */
export type ChatMessageWhatsappMessagesBoolExp = {
  _and?: InputMaybe<Array<ChatMessageWhatsappMessagesBoolExp>>;
  _not?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
  _or?: InputMaybe<Array<ChatMessageWhatsappMessagesBoolExp>>;
  chatMessage?: InputMaybe<ChatMessagesBoolExp>;
  chatMessageId?: InputMaybe<UuidComparisonExp>;
  whatsappMessage?: InputMaybe<WhatsappMessagesBoolExp>;
  whatsappMessageId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "chat_message_whatsapp_messages" */
export enum ChatMessageWhatsappMessagesConstraint {
  /** unique or primary key constraint on columns "whatsapp_message_id", "chat_message_id" */
  ChatMessageWhatsappMessagesPkey = 'chat_message_whatsapp_messages_pkey'
}

/** input type for inserting data into table "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesInsertInput = {
  chatMessage?: InputMaybe<ChatMessagesObjRelInsertInput>;
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  whatsappMessage?: InputMaybe<WhatsappMessagesObjRelInsertInput>;
  whatsappMessageId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ChatMessageWhatsappMessagesMaxFields = {
  __typename?: 'chat_message_whatsapp_messages_max_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  whatsappMessageId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesMaxOrderBy = {
  chatMessageId?: InputMaybe<OrderBy>;
  whatsappMessageId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatMessageWhatsappMessagesMinFields = {
  __typename?: 'chat_message_whatsapp_messages_min_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  whatsappMessageId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesMinOrderBy = {
  chatMessageId?: InputMaybe<OrderBy>;
  whatsappMessageId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesMutationResponse = {
  __typename?: 'chat_message_whatsapp_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessageWhatsappMessages>;
};

/** on_conflict condition type for table "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesOnConflict = {
  constraint: ChatMessageWhatsappMessagesConstraint;
  update_columns?: Array<ChatMessageWhatsappMessagesUpdateColumn>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};

/** Ordering options when selecting data from "chat_message_whatsapp_messages". */
export type ChatMessageWhatsappMessagesOrderBy = {
  chatMessage?: InputMaybe<ChatMessagesOrderBy>;
  chatMessageId?: InputMaybe<OrderBy>;
  whatsappMessage?: InputMaybe<WhatsappMessagesOrderBy>;
  whatsappMessageId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_message_whatsapp_messages */
export type ChatMessageWhatsappMessagesPkColumnsInput = {
  chatMessageId: Scalars['uuid'];
  whatsappMessageId: Scalars['uuid'];
};

/** select columns of table "chat_message_whatsapp_messages" */
export enum ChatMessageWhatsappMessagesSelectColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  WhatsappMessageId = 'whatsappMessageId'
}

/** input type for updating data in table "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesSetInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  whatsappMessageId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "chat_message_whatsapp_messages" */
export type ChatMessageWhatsappMessagesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessageWhatsappMessagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessageWhatsappMessagesStreamCursorValueInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  whatsappMessageId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "chat_message_whatsapp_messages" */
export enum ChatMessageWhatsappMessagesUpdateColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  WhatsappMessageId = 'whatsappMessageId'
}

export type ChatMessageWhatsappMessagesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessageWhatsappMessagesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessageWhatsappMessagesBoolExp;
};

/** columns and relationships of "chat_messages" */
export type ChatMessages = {
  __typename?: 'chat_messages';
  /** An object relationship */
  authorUser: Users;
  /** A computed field, executes function "chat_messages_author_user_display_name" */
  authorUserDisplayName?: Maybe<Scalars['String']>;
  authorUserId: Scalars['uuid'];
  campaignId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  chat: Chats;
  chatId: Scalars['uuid'];
  chatMessageChatbotAutoreplyType?: Maybe<Scalars['String']>;
  /** An array relationship */
  chatMessageFileAttachments: Array<ChatMessageFileAttachments>;
  /** An aggregate relationship */
  chatMessageFileAttachments_aggregate: ChatMessageFileAttachmentsAggregate;
  /** An object relationship */
  chatMessageTemplate?: Maybe<ChatMessageTemplates>;
  chatMessageTemplateId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  chatMessageTemplateValues: Array<ChatMessageTemplateValues>;
  /** An aggregate relationship */
  chatMessageTemplateValues_aggregate: ChatMessageTemplateValuesAggregate;
  /** An array relationship */
  chatMessageTranslations: Array<ChatMessageTranslations>;
  /** An aggregate relationship */
  chatMessageTranslations_aggregate: ChatMessageTranslationsAggregate;
  /** An array relationship */
  chatMessageWhatsappMessages: Array<ChatMessageWhatsappMessages>;
  /** An aggregate relationship */
  chatMessageWhatsappMessages_aggregate: ChatMessageWhatsappMessagesAggregate;
  detectedLanguage?: Maybe<Scalars['String']>;
  /** An object relationship */
  forwardedChatMessage?: Maybe<ChatMessages>;
  forwardedChatMessageId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  forwardingChatMessages: Array<ChatMessages>;
  /** An aggregate relationship */
  forwardingChatMessages_aggregate: ChatMessagesAggregate;
  fullText?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  lastReactionChatMessage?: Maybe<ChatMessages>;
  lastReactionChatMessageId?: Maybe<Scalars['uuid']>;
  reactedChatMessageId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  reactionChatMessages: Array<ChatMessages>;
  /** An aggregate relationship */
  reactionChatMessages_aggregate: ChatMessagesAggregate;
  reactionEmoji?: Maybe<Scalars['String']>;
  receivingErrorCode?: Maybe<Scalars['String']>;
  /** An object relationship */
  repliedChatMessage?: Maybe<ChatMessages>;
  repliedChatMessageId?: Maybe<Scalars['uuid']>;
  statistics: Scalars['jsonb'];
  templateHeaderMediaKey?: Maybe<Scalars['String']>;
  templateHeaderMediaMimeType?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** A computed field, executes function "chat_messages_timestamp" */
  timestamp?: Maybe<Scalars['timestamptz']>;
  type: Scalars['String'];
  /** An object relationship */
  whatsappMessage?: Maybe<WhatsappMessages>;
  /** A computed field, executes function "chat_messages_whatsapp_messages_status" */
  whatsappMessagesStatus?: Maybe<Scalars['String']>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesChatMessageFileAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesChatMessageFileAttachmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesChatMessageTemplateValuesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesChatMessageTemplateValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesChatMessageTranslationsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageTranslationsBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesChatMessageTranslationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageTranslationsBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesChatMessageWhatsappMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageWhatsappMessagesOrderBy>>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesChatMessageWhatsappMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageWhatsappMessagesOrderBy>>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesForwardingChatMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesForwardingChatMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesReactionChatMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesReactionChatMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


/** columns and relationships of "chat_messages" */
export type ChatMessagesStatisticsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "chat_messages" */
export type ChatMessagesAggregate = {
  __typename?: 'chat_messages_aggregate';
  aggregate?: Maybe<ChatMessagesAggregateFields>;
  nodes: Array<ChatMessages>;
};

export type ChatMessagesAggregateBoolExp = {
  count?: InputMaybe<ChatMessagesAggregateBoolExpCount>;
};

export type ChatMessagesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatMessagesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_messages" */
export type ChatMessagesAggregateFields = {
  __typename?: 'chat_messages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessagesMaxFields>;
  min?: Maybe<ChatMessagesMinFields>;
};


/** aggregate fields of "chat_messages" */
export type ChatMessagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_messages" */
export type ChatMessagesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatMessagesMaxOrderBy>;
  min?: InputMaybe<ChatMessagesMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ChatMessagesAppendInput = {
  statistics?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "chat_messages" */
export type ChatMessagesArrRelInsertInput = {
  data: Array<ChatMessagesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessagesOnConflict>;
};

/** Boolean expression to filter rows from the table "chat_messages". All fields are combined with a logical 'AND'. */
export type ChatMessagesBoolExp = {
  _and?: InputMaybe<Array<ChatMessagesBoolExp>>;
  _not?: InputMaybe<ChatMessagesBoolExp>;
  _or?: InputMaybe<Array<ChatMessagesBoolExp>>;
  authorUser?: InputMaybe<UsersBoolExp>;
  authorUserDisplayName?: InputMaybe<StringComparisonExp>;
  authorUserId?: InputMaybe<UuidComparisonExp>;
  campaignId?: InputMaybe<UuidComparisonExp>;
  chat?: InputMaybe<ChatsBoolExp>;
  chatId?: InputMaybe<UuidComparisonExp>;
  chatMessageChatbotAutoreplyType?: InputMaybe<StringComparisonExp>;
  chatMessageFileAttachments?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
  chatMessageFileAttachments_aggregate?: InputMaybe<ChatMessageFileAttachmentsAggregateBoolExp>;
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesBoolExp>;
  chatMessageTemplateId?: InputMaybe<UuidComparisonExp>;
  chatMessageTemplateValues?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
  chatMessageTemplateValues_aggregate?: InputMaybe<ChatMessageTemplateValuesAggregateBoolExp>;
  chatMessageTranslations?: InputMaybe<ChatMessageTranslationsBoolExp>;
  chatMessageTranslations_aggregate?: InputMaybe<ChatMessageTranslationsAggregateBoolExp>;
  chatMessageWhatsappMessages?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
  chatMessageWhatsappMessages_aggregate?: InputMaybe<ChatMessageWhatsappMessagesAggregateBoolExp>;
  detectedLanguage?: InputMaybe<StringComparisonExp>;
  forwardedChatMessage?: InputMaybe<ChatMessagesBoolExp>;
  forwardedChatMessageId?: InputMaybe<UuidComparisonExp>;
  forwardingChatMessages?: InputMaybe<ChatMessagesBoolExp>;
  forwardingChatMessages_aggregate?: InputMaybe<ChatMessagesAggregateBoolExp>;
  fullText?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  lastReactionChatMessage?: InputMaybe<ChatMessagesBoolExp>;
  lastReactionChatMessageId?: InputMaybe<UuidComparisonExp>;
  reactedChatMessageId?: InputMaybe<UuidComparisonExp>;
  reactionChatMessages?: InputMaybe<ChatMessagesBoolExp>;
  reactionChatMessages_aggregate?: InputMaybe<ChatMessagesAggregateBoolExp>;
  reactionEmoji?: InputMaybe<StringComparisonExp>;
  receivingErrorCode?: InputMaybe<StringComparisonExp>;
  repliedChatMessage?: InputMaybe<ChatMessagesBoolExp>;
  repliedChatMessageId?: InputMaybe<UuidComparisonExp>;
  statistics?: InputMaybe<JsonbComparisonExp>;
  templateHeaderMediaKey?: InputMaybe<StringComparisonExp>;
  templateHeaderMediaMimeType?: InputMaybe<StringComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  timestamp?: InputMaybe<TimestamptzComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  whatsappMessage?: InputMaybe<WhatsappMessagesBoolExp>;
  whatsappMessagesStatus?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_messages" */
export enum ChatMessagesConstraint {
  /** unique or primary key constraint on columns "campaign_id", "chat_id" */
  ChatMessagesChatIdCampaignIdKey = 'chat_messages_chat_id_campaign_id_key',
  /** unique or primary key constraint on columns "id" */
  ChatMessagesPkey = 'chat_messages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ChatMessagesDeleteAtPathInput = {
  statistics?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ChatMessagesDeleteElemInput = {
  statistics?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ChatMessagesDeleteKeyInput = {
  statistics?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "chat_messages_event_logs" */
export type ChatMessagesEventLogs = {
  __typename?: 'chat_messages_event_logs';
  /** An object relationship */
  chatMessage: ChatMessages;
  chatMessageId: Scalars['uuid'];
  id: Scalars['uuid'];
  insertionTimestamp: Scalars['timestamptz'];
};

/** aggregated selection of "chat_messages_event_logs" */
export type ChatMessagesEventLogsAggregate = {
  __typename?: 'chat_messages_event_logs_aggregate';
  aggregate?: Maybe<ChatMessagesEventLogsAggregateFields>;
  nodes: Array<ChatMessagesEventLogs>;
};

/** aggregate fields of "chat_messages_event_logs" */
export type ChatMessagesEventLogsAggregateFields = {
  __typename?: 'chat_messages_event_logs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatMessagesEventLogsMaxFields>;
  min?: Maybe<ChatMessagesEventLogsMinFields>;
};


/** aggregate fields of "chat_messages_event_logs" */
export type ChatMessagesEventLogsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatMessagesEventLogsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_messages_event_logs". All fields are combined with a logical 'AND'. */
export type ChatMessagesEventLogsBoolExp = {
  _and?: InputMaybe<Array<ChatMessagesEventLogsBoolExp>>;
  _not?: InputMaybe<ChatMessagesEventLogsBoolExp>;
  _or?: InputMaybe<Array<ChatMessagesEventLogsBoolExp>>;
  chatMessage?: InputMaybe<ChatMessagesBoolExp>;
  chatMessageId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionTimestamp?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "chat_messages_event_logs" */
export enum ChatMessagesEventLogsConstraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessagesEventLogsPkey = 'chat_messages_event_logs_pkey'
}

/** input type for inserting data into table "chat_messages_event_logs" */
export type ChatMessagesEventLogsInsertInput = {
  chatMessage?: InputMaybe<ChatMessagesObjRelInsertInput>;
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ChatMessagesEventLogsMaxFields = {
  __typename?: 'chat_messages_event_logs_max_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ChatMessagesEventLogsMinFields = {
  __typename?: 'chat_messages_event_logs_min_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "chat_messages_event_logs" */
export type ChatMessagesEventLogsMutationResponse = {
  __typename?: 'chat_messages_event_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessagesEventLogs>;
};

/** on_conflict condition type for table "chat_messages_event_logs" */
export type ChatMessagesEventLogsOnConflict = {
  constraint: ChatMessagesEventLogsConstraint;
  update_columns?: Array<ChatMessagesEventLogsUpdateColumn>;
  where?: InputMaybe<ChatMessagesEventLogsBoolExp>;
};

/** Ordering options when selecting data from "chat_messages_event_logs". */
export type ChatMessagesEventLogsOrderBy = {
  chatMessage?: InputMaybe<ChatMessagesOrderBy>;
  chatMessageId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_messages_event_logs */
export type ChatMessagesEventLogsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_messages_event_logs" */
export enum ChatMessagesEventLogsSelectColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp'
}

/** input type for updating data in table "chat_messages_event_logs" */
export type ChatMessagesEventLogsSetInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_messages_event_logs" */
export type ChatMessagesEventLogsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessagesEventLogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessagesEventLogsStreamCursorValueInput = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_messages_event_logs" */
export enum ChatMessagesEventLogsUpdateColumn {
  /** column name */
  ChatMessageId = 'chatMessageId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp'
}

export type ChatMessagesEventLogsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessagesEventLogsSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessagesEventLogsBoolExp;
};

/** input type for inserting data into table "chat_messages" */
export type ChatMessagesInsertInput = {
  authorUser?: InputMaybe<UsersObjRelInsertInput>;
  authorUserId?: InputMaybe<Scalars['uuid']>;
  campaignId?: InputMaybe<Scalars['uuid']>;
  chat?: InputMaybe<ChatsObjRelInsertInput>;
  chatId?: InputMaybe<Scalars['uuid']>;
  chatMessageChatbotAutoreplyType?: InputMaybe<Scalars['String']>;
  chatMessageFileAttachments?: InputMaybe<ChatMessageFileAttachmentsArrRelInsertInput>;
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesObjRelInsertInput>;
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  chatMessageTemplateValues?: InputMaybe<ChatMessageTemplateValuesArrRelInsertInput>;
  chatMessageTranslations?: InputMaybe<ChatMessageTranslationsArrRelInsertInput>;
  chatMessageWhatsappMessages?: InputMaybe<ChatMessageWhatsappMessagesArrRelInsertInput>;
  detectedLanguage?: InputMaybe<Scalars['String']>;
  forwardedChatMessage?: InputMaybe<ChatMessagesObjRelInsertInput>;
  forwardedChatMessageId?: InputMaybe<Scalars['uuid']>;
  forwardingChatMessages?: InputMaybe<ChatMessagesArrRelInsertInput>;
  fullText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  lastReactionChatMessage?: InputMaybe<ChatMessagesObjRelInsertInput>;
  lastReactionChatMessageId?: InputMaybe<Scalars['uuid']>;
  reactedChatMessageId?: InputMaybe<Scalars['uuid']>;
  reactionChatMessages?: InputMaybe<ChatMessagesArrRelInsertInput>;
  reactionEmoji?: InputMaybe<Scalars['String']>;
  receivingErrorCode?: InputMaybe<Scalars['String']>;
  repliedChatMessage?: InputMaybe<ChatMessagesObjRelInsertInput>;
  repliedChatMessageId?: InputMaybe<Scalars['uuid']>;
  statistics?: InputMaybe<Scalars['jsonb']>;
  templateHeaderMediaKey?: InputMaybe<Scalars['String']>;
  templateHeaderMediaMimeType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  whatsappMessage?: InputMaybe<WhatsappMessagesObjRelInsertInput>;
};

/** aggregate max on columns */
export type ChatMessagesMaxFields = {
  __typename?: 'chat_messages_max_fields';
  /** A computed field, executes function "chat_messages_author_user_display_name" */
  authorUserDisplayName?: Maybe<Scalars['String']>;
  authorUserId?: Maybe<Scalars['uuid']>;
  campaignId?: Maybe<Scalars['uuid']>;
  chatId?: Maybe<Scalars['uuid']>;
  chatMessageChatbotAutoreplyType?: Maybe<Scalars['String']>;
  chatMessageTemplateId?: Maybe<Scalars['uuid']>;
  detectedLanguage?: Maybe<Scalars['String']>;
  forwardedChatMessageId?: Maybe<Scalars['uuid']>;
  fullText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  lastReactionChatMessageId?: Maybe<Scalars['uuid']>;
  reactedChatMessageId?: Maybe<Scalars['uuid']>;
  reactionEmoji?: Maybe<Scalars['String']>;
  receivingErrorCode?: Maybe<Scalars['String']>;
  repliedChatMessageId?: Maybe<Scalars['uuid']>;
  templateHeaderMediaKey?: Maybe<Scalars['String']>;
  templateHeaderMediaMimeType?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** A computed field, executes function "chat_messages_timestamp" */
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  /** A computed field, executes function "chat_messages_whatsapp_messages_status" */
  whatsappMessagesStatus?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "chat_messages" */
export type ChatMessagesMaxOrderBy = {
  authorUserId?: InputMaybe<OrderBy>;
  campaignId?: InputMaybe<OrderBy>;
  chatId?: InputMaybe<OrderBy>;
  chatMessageChatbotAutoreplyType?: InputMaybe<OrderBy>;
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  detectedLanguage?: InputMaybe<OrderBy>;
  forwardedChatMessageId?: InputMaybe<OrderBy>;
  fullText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  lastReactionChatMessageId?: InputMaybe<OrderBy>;
  reactedChatMessageId?: InputMaybe<OrderBy>;
  reactionEmoji?: InputMaybe<OrderBy>;
  receivingErrorCode?: InputMaybe<OrderBy>;
  repliedChatMessageId?: InputMaybe<OrderBy>;
  templateHeaderMediaKey?: InputMaybe<OrderBy>;
  templateHeaderMediaMimeType?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatMessagesMinFields = {
  __typename?: 'chat_messages_min_fields';
  /** A computed field, executes function "chat_messages_author_user_display_name" */
  authorUserDisplayName?: Maybe<Scalars['String']>;
  authorUserId?: Maybe<Scalars['uuid']>;
  campaignId?: Maybe<Scalars['uuid']>;
  chatId?: Maybe<Scalars['uuid']>;
  chatMessageChatbotAutoreplyType?: Maybe<Scalars['String']>;
  chatMessageTemplateId?: Maybe<Scalars['uuid']>;
  detectedLanguage?: Maybe<Scalars['String']>;
  forwardedChatMessageId?: Maybe<Scalars['uuid']>;
  fullText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  lastReactionChatMessageId?: Maybe<Scalars['uuid']>;
  reactedChatMessageId?: Maybe<Scalars['uuid']>;
  reactionEmoji?: Maybe<Scalars['String']>;
  receivingErrorCode?: Maybe<Scalars['String']>;
  repliedChatMessageId?: Maybe<Scalars['uuid']>;
  templateHeaderMediaKey?: Maybe<Scalars['String']>;
  templateHeaderMediaMimeType?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** A computed field, executes function "chat_messages_timestamp" */
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  /** A computed field, executes function "chat_messages_whatsapp_messages_status" */
  whatsappMessagesStatus?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "chat_messages" */
export type ChatMessagesMinOrderBy = {
  authorUserId?: InputMaybe<OrderBy>;
  campaignId?: InputMaybe<OrderBy>;
  chatId?: InputMaybe<OrderBy>;
  chatMessageChatbotAutoreplyType?: InputMaybe<OrderBy>;
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  detectedLanguage?: InputMaybe<OrderBy>;
  forwardedChatMessageId?: InputMaybe<OrderBy>;
  fullText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  lastReactionChatMessageId?: InputMaybe<OrderBy>;
  reactedChatMessageId?: InputMaybe<OrderBy>;
  reactionEmoji?: InputMaybe<OrderBy>;
  receivingErrorCode?: InputMaybe<OrderBy>;
  repliedChatMessageId?: InputMaybe<OrderBy>;
  templateHeaderMediaKey?: InputMaybe<OrderBy>;
  templateHeaderMediaMimeType?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_messages" */
export type ChatMessagesMutationResponse = {
  __typename?: 'chat_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatMessages>;
};

/** input type for inserting object relation for remote table "chat_messages" */
export type ChatMessagesObjRelInsertInput = {
  data: ChatMessagesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatMessagesOnConflict>;
};

/** on_conflict condition type for table "chat_messages" */
export type ChatMessagesOnConflict = {
  constraint: ChatMessagesConstraint;
  update_columns?: Array<ChatMessagesUpdateColumn>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};

/** Ordering options when selecting data from "chat_messages". */
export type ChatMessagesOrderBy = {
  authorUser?: InputMaybe<UsersOrderBy>;
  authorUserDisplayName?: InputMaybe<OrderBy>;
  authorUserId?: InputMaybe<OrderBy>;
  campaignId?: InputMaybe<OrderBy>;
  chat?: InputMaybe<ChatsOrderBy>;
  chatId?: InputMaybe<OrderBy>;
  chatMessageChatbotAutoreplyType?: InputMaybe<OrderBy>;
  chatMessageFileAttachments_aggregate?: InputMaybe<ChatMessageFileAttachmentsAggregateOrderBy>;
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesOrderBy>;
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  chatMessageTemplateValues_aggregate?: InputMaybe<ChatMessageTemplateValuesAggregateOrderBy>;
  chatMessageTranslations_aggregate?: InputMaybe<ChatMessageTranslationsAggregateOrderBy>;
  chatMessageWhatsappMessages_aggregate?: InputMaybe<ChatMessageWhatsappMessagesAggregateOrderBy>;
  detectedLanguage?: InputMaybe<OrderBy>;
  forwardedChatMessage?: InputMaybe<ChatMessagesOrderBy>;
  forwardedChatMessageId?: InputMaybe<OrderBy>;
  forwardingChatMessages_aggregate?: InputMaybe<ChatMessagesAggregateOrderBy>;
  fullText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  lastReactionChatMessage?: InputMaybe<ChatMessagesOrderBy>;
  lastReactionChatMessageId?: InputMaybe<OrderBy>;
  reactedChatMessageId?: InputMaybe<OrderBy>;
  reactionChatMessages_aggregate?: InputMaybe<ChatMessagesAggregateOrderBy>;
  reactionEmoji?: InputMaybe<OrderBy>;
  receivingErrorCode?: InputMaybe<OrderBy>;
  repliedChatMessage?: InputMaybe<ChatMessagesOrderBy>;
  repliedChatMessageId?: InputMaybe<OrderBy>;
  statistics?: InputMaybe<OrderBy>;
  templateHeaderMediaKey?: InputMaybe<OrderBy>;
  templateHeaderMediaMimeType?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  timestamp?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  whatsappMessage?: InputMaybe<WhatsappMessagesOrderBy>;
  whatsappMessagesStatus?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_messages */
export type ChatMessagesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ChatMessagesPrependInput = {
  statistics?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "chat_messages" */
export enum ChatMessagesSelectColumn {
  /** column name */
  AuthorUserId = 'authorUserId',
  /** column name */
  CampaignId = 'campaignId',
  /** column name */
  ChatId = 'chatId',
  /** column name */
  ChatMessageChatbotAutoreplyType = 'chatMessageChatbotAutoreplyType',
  /** column name */
  ChatMessageTemplateId = 'chatMessageTemplateId',
  /** column name */
  DetectedLanguage = 'detectedLanguage',
  /** column name */
  ForwardedChatMessageId = 'forwardedChatMessageId',
  /** column name */
  FullText = 'fullText',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  LastReactionChatMessageId = 'lastReactionChatMessageId',
  /** column name */
  ReactedChatMessageId = 'reactedChatMessageId',
  /** column name */
  ReactionEmoji = 'reactionEmoji',
  /** column name */
  ReceivingErrorCode = 'receivingErrorCode',
  /** column name */
  RepliedChatMessageId = 'repliedChatMessageId',
  /** column name */
  Statistics = 'statistics',
  /** column name */
  TemplateHeaderMediaKey = 'templateHeaderMediaKey',
  /** column name */
  TemplateHeaderMediaMimeType = 'templateHeaderMediaMimeType',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chat_messages" */
export type ChatMessagesSetInput = {
  authorUserId?: InputMaybe<Scalars['uuid']>;
  campaignId?: InputMaybe<Scalars['uuid']>;
  chatId?: InputMaybe<Scalars['uuid']>;
  chatMessageChatbotAutoreplyType?: InputMaybe<Scalars['String']>;
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  detectedLanguage?: InputMaybe<Scalars['String']>;
  forwardedChatMessageId?: InputMaybe<Scalars['uuid']>;
  fullText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  lastReactionChatMessageId?: InputMaybe<Scalars['uuid']>;
  reactedChatMessageId?: InputMaybe<Scalars['uuid']>;
  reactionEmoji?: InputMaybe<Scalars['String']>;
  receivingErrorCode?: InputMaybe<Scalars['String']>;
  repliedChatMessageId?: InputMaybe<Scalars['uuid']>;
  statistics?: InputMaybe<Scalars['jsonb']>;
  templateHeaderMediaKey?: InputMaybe<Scalars['String']>;
  templateHeaderMediaMimeType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_messages" */
export type ChatMessagesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatMessagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatMessagesStreamCursorValueInput = {
  authorUserId?: InputMaybe<Scalars['uuid']>;
  campaignId?: InputMaybe<Scalars['uuid']>;
  chatId?: InputMaybe<Scalars['uuid']>;
  chatMessageChatbotAutoreplyType?: InputMaybe<Scalars['String']>;
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  detectedLanguage?: InputMaybe<Scalars['String']>;
  forwardedChatMessageId?: InputMaybe<Scalars['uuid']>;
  fullText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  lastReactionChatMessageId?: InputMaybe<Scalars['uuid']>;
  reactedChatMessageId?: InputMaybe<Scalars['uuid']>;
  reactionEmoji?: InputMaybe<Scalars['String']>;
  receivingErrorCode?: InputMaybe<Scalars['String']>;
  repliedChatMessageId?: InputMaybe<Scalars['uuid']>;
  statistics?: InputMaybe<Scalars['jsonb']>;
  templateHeaderMediaKey?: InputMaybe<Scalars['String']>;
  templateHeaderMediaMimeType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_messages" */
export enum ChatMessagesUpdateColumn {
  /** column name */
  AuthorUserId = 'authorUserId',
  /** column name */
  CampaignId = 'campaignId',
  /** column name */
  ChatId = 'chatId',
  /** column name */
  ChatMessageChatbotAutoreplyType = 'chatMessageChatbotAutoreplyType',
  /** column name */
  ChatMessageTemplateId = 'chatMessageTemplateId',
  /** column name */
  DetectedLanguage = 'detectedLanguage',
  /** column name */
  ForwardedChatMessageId = 'forwardedChatMessageId',
  /** column name */
  FullText = 'fullText',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  LastReactionChatMessageId = 'lastReactionChatMessageId',
  /** column name */
  ReactedChatMessageId = 'reactedChatMessageId',
  /** column name */
  ReactionEmoji = 'reactionEmoji',
  /** column name */
  ReceivingErrorCode = 'receivingErrorCode',
  /** column name */
  RepliedChatMessageId = 'repliedChatMessageId',
  /** column name */
  Statistics = 'statistics',
  /** column name */
  TemplateHeaderMediaKey = 'templateHeaderMediaKey',
  /** column name */
  TemplateHeaderMediaMimeType = 'templateHeaderMediaMimeType',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type'
}

export type ChatMessagesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ChatMessagesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ChatMessagesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ChatMessagesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ChatMessagesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ChatMessagesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatMessagesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatMessagesBoolExp;
};

/** columns and relationships of "chat_summaries" */
export type ChatSummaries = {
  __typename?: 'chat_summaries';
  actionItem: Scalars['String'];
  /** An object relationship */
  chat: Chats;
  chatId: Scalars['uuid'];
  customerSatisfaction?: Maybe<Scalars['Int']>;
  details: Array<Scalars['String']>;
  fromDate: Scalars['date'];
  id: Scalars['uuid'];
  messagesCount: Scalars['Int'];
  title: Scalars['String'];
  toDate: Scalars['date'];
  upsellPossibility?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "chat_summaries" */
export type ChatSummariesAggregate = {
  __typename?: 'chat_summaries_aggregate';
  aggregate?: Maybe<ChatSummariesAggregateFields>;
  nodes: Array<ChatSummaries>;
};

/** aggregate fields of "chat_summaries" */
export type ChatSummariesAggregateFields = {
  __typename?: 'chat_summaries_aggregate_fields';
  avg?: Maybe<ChatSummariesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ChatSummariesMaxFields>;
  min?: Maybe<ChatSummariesMinFields>;
  stddev?: Maybe<ChatSummariesStddevFields>;
  stddev_pop?: Maybe<ChatSummariesStddevPopFields>;
  stddev_samp?: Maybe<ChatSummariesStddevSampFields>;
  sum?: Maybe<ChatSummariesSumFields>;
  var_pop?: Maybe<ChatSummariesVarPopFields>;
  var_samp?: Maybe<ChatSummariesVarSampFields>;
  variance?: Maybe<ChatSummariesVarianceFields>;
};


/** aggregate fields of "chat_summaries" */
export type ChatSummariesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatSummariesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ChatSummariesAvgFields = {
  __typename?: 'chat_summaries_avg_fields';
  customerSatisfaction?: Maybe<Scalars['Float']>;
  messagesCount?: Maybe<Scalars['Float']>;
  upsellPossibility?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "chat_summaries". All fields are combined with a logical 'AND'. */
export type ChatSummariesBoolExp = {
  _and?: InputMaybe<Array<ChatSummariesBoolExp>>;
  _not?: InputMaybe<ChatSummariesBoolExp>;
  _or?: InputMaybe<Array<ChatSummariesBoolExp>>;
  actionItem?: InputMaybe<StringComparisonExp>;
  chat?: InputMaybe<ChatsBoolExp>;
  chatId?: InputMaybe<UuidComparisonExp>;
  customerSatisfaction?: InputMaybe<IntComparisonExp>;
  details?: InputMaybe<StringArrayComparisonExp>;
  fromDate?: InputMaybe<DateComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  messagesCount?: InputMaybe<IntComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  toDate?: InputMaybe<DateComparisonExp>;
  upsellPossibility?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "chat_summaries" */
export enum ChatSummariesConstraint {
  /** unique or primary key constraint on columns "to_date", "from_date", "chat_id" */
  ChatSummariesChatIdFromDateToDateKey = 'chat_summaries_chat_id_from_date_to_date_key',
  /** unique or primary key constraint on columns "id" */
  ChatSummariesPkey = 'chat_summaries_pkey'
}

/** input type for incrementing numeric columns in table "chat_summaries" */
export type ChatSummariesIncInput = {
  customerSatisfaction?: InputMaybe<Scalars['Int']>;
  messagesCount?: InputMaybe<Scalars['Int']>;
  upsellPossibility?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "chat_summaries" */
export type ChatSummariesInsertInput = {
  actionItem?: InputMaybe<Scalars['String']>;
  chat?: InputMaybe<ChatsObjRelInsertInput>;
  chatId?: InputMaybe<Scalars['uuid']>;
  customerSatisfaction?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Array<Scalars['String']>>;
  fromDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  messagesCount?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['date']>;
  upsellPossibility?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ChatSummariesMaxFields = {
  __typename?: 'chat_summaries_max_fields';
  actionItem?: Maybe<Scalars['String']>;
  chatId?: Maybe<Scalars['uuid']>;
  customerSatisfaction?: Maybe<Scalars['Int']>;
  details?: Maybe<Array<Scalars['String']>>;
  fromDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  messagesCount?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['date']>;
  upsellPossibility?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ChatSummariesMinFields = {
  __typename?: 'chat_summaries_min_fields';
  actionItem?: Maybe<Scalars['String']>;
  chatId?: Maybe<Scalars['uuid']>;
  customerSatisfaction?: Maybe<Scalars['Int']>;
  details?: Maybe<Array<Scalars['String']>>;
  fromDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  messagesCount?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['date']>;
  upsellPossibility?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "chat_summaries" */
export type ChatSummariesMutationResponse = {
  __typename?: 'chat_summaries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatSummaries>;
};

/** on_conflict condition type for table "chat_summaries" */
export type ChatSummariesOnConflict = {
  constraint: ChatSummariesConstraint;
  update_columns?: Array<ChatSummariesUpdateColumn>;
  where?: InputMaybe<ChatSummariesBoolExp>;
};

/** Ordering options when selecting data from "chat_summaries". */
export type ChatSummariesOrderBy = {
  actionItem?: InputMaybe<OrderBy>;
  chat?: InputMaybe<ChatsOrderBy>;
  chatId?: InputMaybe<OrderBy>;
  customerSatisfaction?: InputMaybe<OrderBy>;
  details?: InputMaybe<OrderBy>;
  fromDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messagesCount?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  toDate?: InputMaybe<OrderBy>;
  upsellPossibility?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_summaries */
export type ChatSummariesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_summaries" */
export enum ChatSummariesSelectColumn {
  /** column name */
  ActionItem = 'actionItem',
  /** column name */
  ChatId = 'chatId',
  /** column name */
  CustomerSatisfaction = 'customerSatisfaction',
  /** column name */
  Details = 'details',
  /** column name */
  FromDate = 'fromDate',
  /** column name */
  Id = 'id',
  /** column name */
  MessagesCount = 'messagesCount',
  /** column name */
  Title = 'title',
  /** column name */
  ToDate = 'toDate',
  /** column name */
  UpsellPossibility = 'upsellPossibility'
}

/** input type for updating data in table "chat_summaries" */
export type ChatSummariesSetInput = {
  actionItem?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['uuid']>;
  customerSatisfaction?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Array<Scalars['String']>>;
  fromDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  messagesCount?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['date']>;
  upsellPossibility?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ChatSummariesStddevFields = {
  __typename?: 'chat_summaries_stddev_fields';
  customerSatisfaction?: Maybe<Scalars['Float']>;
  messagesCount?: Maybe<Scalars['Float']>;
  upsellPossibility?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ChatSummariesStddevPopFields = {
  __typename?: 'chat_summaries_stddev_pop_fields';
  customerSatisfaction?: Maybe<Scalars['Float']>;
  messagesCount?: Maybe<Scalars['Float']>;
  upsellPossibility?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ChatSummariesStddevSampFields = {
  __typename?: 'chat_summaries_stddev_samp_fields';
  customerSatisfaction?: Maybe<Scalars['Float']>;
  messagesCount?: Maybe<Scalars['Float']>;
  upsellPossibility?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "chat_summaries" */
export type ChatSummariesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatSummariesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatSummariesStreamCursorValueInput = {
  actionItem?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['uuid']>;
  customerSatisfaction?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Array<Scalars['String']>>;
  fromDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  messagesCount?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['date']>;
  upsellPossibility?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ChatSummariesSumFields = {
  __typename?: 'chat_summaries_sum_fields';
  customerSatisfaction?: Maybe<Scalars['Int']>;
  messagesCount?: Maybe<Scalars['Int']>;
  upsellPossibility?: Maybe<Scalars['Int']>;
};

/** update columns of table "chat_summaries" */
export enum ChatSummariesUpdateColumn {
  /** column name */
  ActionItem = 'actionItem',
  /** column name */
  ChatId = 'chatId',
  /** column name */
  CustomerSatisfaction = 'customerSatisfaction',
  /** column name */
  Details = 'details',
  /** column name */
  FromDate = 'fromDate',
  /** column name */
  Id = 'id',
  /** column name */
  MessagesCount = 'messagesCount',
  /** column name */
  Title = 'title',
  /** column name */
  ToDate = 'toDate',
  /** column name */
  UpsellPossibility = 'upsellPossibility'
}

export type ChatSummariesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChatSummariesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatSummariesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatSummariesBoolExp;
};

/** aggregate var_pop on columns */
export type ChatSummariesVarPopFields = {
  __typename?: 'chat_summaries_var_pop_fields';
  customerSatisfaction?: Maybe<Scalars['Float']>;
  messagesCount?: Maybe<Scalars['Float']>;
  upsellPossibility?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ChatSummariesVarSampFields = {
  __typename?: 'chat_summaries_var_samp_fields';
  customerSatisfaction?: Maybe<Scalars['Float']>;
  messagesCount?: Maybe<Scalars['Float']>;
  upsellPossibility?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ChatSummariesVarianceFields = {
  __typename?: 'chat_summaries_variance_fields';
  customerSatisfaction?: Maybe<Scalars['Float']>;
  messagesCount?: Maybe<Scalars['Float']>;
  upsellPossibility?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "chat_types" */
export type ChatTypes = {
  __typename?: 'chat_types';
  type: Scalars['String'];
};

/** aggregated selection of "chat_types" */
export type ChatTypesAggregate = {
  __typename?: 'chat_types_aggregate';
  aggregate?: Maybe<ChatTypesAggregateFields>;
  nodes: Array<ChatTypes>;
};

/** aggregate fields of "chat_types" */
export type ChatTypesAggregateFields = {
  __typename?: 'chat_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatTypesMaxFields>;
  min?: Maybe<ChatTypesMinFields>;
};


/** aggregate fields of "chat_types" */
export type ChatTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_types". All fields are combined with a logical 'AND'. */
export type ChatTypesBoolExp = {
  _and?: InputMaybe<Array<ChatTypesBoolExp>>;
  _not?: InputMaybe<ChatTypesBoolExp>;
  _or?: InputMaybe<Array<ChatTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "chat_types" */
export enum ChatTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  ChatTypesPkey = 'chat_types_pkey'
}

export enum ChatTypesEnum {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

/** Boolean expression to compare columns of type "chat_types_enum". All fields are combined with logical 'AND'. */
export type ChatTypesEnumComparisonExp = {
  _eq?: InputMaybe<ChatTypesEnum>;
  _in?: InputMaybe<Array<ChatTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ChatTypesEnum>;
  _nin?: InputMaybe<Array<ChatTypesEnum>>;
};

/** input type for inserting data into table "chat_types" */
export type ChatTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ChatTypesMaxFields = {
  __typename?: 'chat_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ChatTypesMinFields = {
  __typename?: 'chat_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chat_types" */
export type ChatTypesMutationResponse = {
  __typename?: 'chat_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatTypes>;
};

/** on_conflict condition type for table "chat_types" */
export type ChatTypesOnConflict = {
  constraint: ChatTypesConstraint;
  update_columns?: Array<ChatTypesUpdateColumn>;
  where?: InputMaybe<ChatTypesBoolExp>;
};

/** Ordering options when selecting data from "chat_types". */
export type ChatTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_types */
export type ChatTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "chat_types" */
export enum ChatTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "chat_types" */
export type ChatTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_types" */
export type ChatTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_types" */
export enum ChatTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type ChatTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatTypesSetInput>;
  /** filter the rows which have to be updated */
  where: ChatTypesBoolExp;
};

/** columns and relationships of "chat_users" */
export type ChatUsers = {
  __typename?: 'chat_users';
  /** An object relationship */
  chat: Chats;
  chatId: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid'];
};

/** aggregated selection of "chat_users" */
export type ChatUsersAggregate = {
  __typename?: 'chat_users_aggregate';
  aggregate?: Maybe<ChatUsersAggregateFields>;
  nodes: Array<ChatUsers>;
};

export type ChatUsersAggregateBoolExp = {
  count?: InputMaybe<ChatUsersAggregateBoolExpCount>;
};

export type ChatUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chat_users" */
export type ChatUsersAggregateFields = {
  __typename?: 'chat_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatUsersMaxFields>;
  min?: Maybe<ChatUsersMinFields>;
};


/** aggregate fields of "chat_users" */
export type ChatUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_users" */
export type ChatUsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatUsersMaxOrderBy>;
  min?: InputMaybe<ChatUsersMinOrderBy>;
};

/** input type for inserting array relation for remote table "chat_users" */
export type ChatUsersArrRelInsertInput = {
  data: Array<ChatUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatUsersOnConflict>;
};

/** Boolean expression to filter rows from the table "chat_users". All fields are combined with a logical 'AND'. */
export type ChatUsersBoolExp = {
  _and?: InputMaybe<Array<ChatUsersBoolExp>>;
  _not?: InputMaybe<ChatUsersBoolExp>;
  _or?: InputMaybe<Array<ChatUsersBoolExp>>;
  chat?: InputMaybe<ChatsBoolExp>;
  chatId?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "chat_users" */
export enum ChatUsersConstraint {
  /** unique or primary key constraint on columns "user_id", "chat_id" */
  ChatUsersPkey = 'chat_users_pkey'
}

/** input type for inserting data into table "chat_users" */
export type ChatUsersInsertInput = {
  chat?: InputMaybe<ChatsObjRelInsertInput>;
  chatId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ChatUsersMaxFields = {
  __typename?: 'chat_users_max_fields';
  chatId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "chat_users" */
export type ChatUsersMaxOrderBy = {
  chatId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatUsersMinFields = {
  __typename?: 'chat_users_min_fields';
  chatId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "chat_users" */
export type ChatUsersMinOrderBy = {
  chatId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chat_users" */
export type ChatUsersMutationResponse = {
  __typename?: 'chat_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatUsers>;
};

/** on_conflict condition type for table "chat_users" */
export type ChatUsersOnConflict = {
  constraint: ChatUsersConstraint;
  update_columns?: Array<ChatUsersUpdateColumn>;
  where?: InputMaybe<ChatUsersBoolExp>;
};

/** Ordering options when selecting data from "chat_users". */
export type ChatUsersOrderBy = {
  chat?: InputMaybe<ChatsOrderBy>;
  chatId?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chat_users */
export type ChatUsersPkColumnsInput = {
  chatId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "chat_users" */
export enum ChatUsersSelectColumn {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "chat_users" */
export type ChatUsersSetInput = {
  chatId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "chat_users" */
export type ChatUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatUsersStreamCursorValueInput = {
  chatId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "chat_users" */
export enum ChatUsersUpdateColumn {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  UserId = 'userId'
}

export type ChatUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatUsersSetInput>;
  /** filter the rows which have to be updated */
  where: ChatUsersBoolExp;
};

/** columns and relationships of "chats" */
export type Chats = {
  __typename?: 'chats';
  _upsert: Scalars['Boolean'];
  /** An object relationship */
  assignedMerchantUser?: Maybe<MerchantUsers>;
  assignedMerchantUserId?: Maybe<Scalars['uuid']>;
  autoTranslationLanguage?: Maybe<Scalars['String']>;
  botContext?: Maybe<Scalars['String']>;
  /** An object relationship */
  channel?: Maybe<Channels>;
  channelId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  chatMessages: Array<ChatMessages>;
  /** An aggregate relationship */
  chatMessages_aggregate: ChatMessagesAggregate;
  /** An array relationship */
  chatUsers: Array<ChatUsers>;
  /** An aggregate relationship */
  chatUsers_aggregate: ChatUsersAggregate;
  /** A computed field, executes function "has_unread_failed_chat_message" */
  hasUnreadFailedChatMessage?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  insertionTimestamp: Scalars['timestamptz'];
  insertionUserId: Scalars['uuid'];
  isArchived: Scalars['Boolean'];
  isProductNews: Scalars['Boolean'];
  isSpam: Scalars['Boolean'];
  lastActivityTimestamp: Scalars['timestamptz'];
  merchantId: Scalars['uuid'];
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  owningUser: Users;
  owningUserId: Scalars['uuid'];
  type: ChatTypesEnum;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
  /** An array relationship */
  userChats: Array<UserChats>;
  /** An aggregate relationship */
  userChats_aggregate: UserChatsAggregate;
};


/** columns and relationships of "chats" */
export type ChatsChatMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


/** columns and relationships of "chats" */
export type ChatsChatMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


/** columns and relationships of "chats" */
export type ChatsChatUsersArgs = {
  distinct_on?: InputMaybe<Array<ChatUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatUsersOrderBy>>;
  where?: InputMaybe<ChatUsersBoolExp>;
};


/** columns and relationships of "chats" */
export type ChatsChatUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatUsersOrderBy>>;
  where?: InputMaybe<ChatUsersBoolExp>;
};


/** columns and relationships of "chats" */
export type ChatsUserChatsArgs = {
  distinct_on?: InputMaybe<Array<UserChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserChatsOrderBy>>;
  where?: InputMaybe<UserChatsBoolExp>;
};


/** columns and relationships of "chats" */
export type ChatsUserChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserChatsOrderBy>>;
  where?: InputMaybe<UserChatsBoolExp>;
};

/** aggregated selection of "chats" */
export type ChatsAggregate = {
  __typename?: 'chats_aggregate';
  aggregate?: Maybe<ChatsAggregateFields>;
  nodes: Array<Chats>;
};

export type ChatsAggregateBoolExp = {
  bool_and?: InputMaybe<ChatsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ChatsAggregateBoolExpBoolOr>;
  count?: InputMaybe<ChatsAggregateBoolExpCount>;
};

export type ChatsAggregateBoolExpBoolAnd = {
  arguments: ChatsSelectColumnChatsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChatsAggregateBoolExpBoolOr = {
  arguments: ChatsSelectColumnChatsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ChatsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChatsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ChatsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "chats" */
export type ChatsAggregateFields = {
  __typename?: 'chats_aggregate_fields';
  avg?: Maybe<ChatsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ChatsMaxFields>;
  min?: Maybe<ChatsMinFields>;
  stddev?: Maybe<ChatsStddevFields>;
  stddev_pop?: Maybe<ChatsStddevPopFields>;
  stddev_samp?: Maybe<ChatsStddevSampFields>;
  sum?: Maybe<ChatsSumFields>;
  var_pop?: Maybe<ChatsVarPopFields>;
  var_samp?: Maybe<ChatsVarSampFields>;
  variance?: Maybe<ChatsVarianceFields>;
};


/** aggregate fields of "chats" */
export type ChatsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chats" */
export type ChatsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChatsMaxOrderBy>;
  min?: InputMaybe<ChatsMinOrderBy>;
};

/** input type for inserting array relation for remote table "chats" */
export type ChatsArrRelInsertInput = {
  data: Array<ChatsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatsOnConflict>;
};

/** aggregate avg on columns */
export type ChatsAvgFields = {
  __typename?: 'chats_avg_fields';
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "chats". All fields are combined with a logical 'AND'. */
export type ChatsBoolExp = {
  _and?: InputMaybe<Array<ChatsBoolExp>>;
  _not?: InputMaybe<ChatsBoolExp>;
  _or?: InputMaybe<Array<ChatsBoolExp>>;
  _upsert?: InputMaybe<BooleanComparisonExp>;
  assignedMerchantUser?: InputMaybe<MerchantUsersBoolExp>;
  assignedMerchantUserId?: InputMaybe<UuidComparisonExp>;
  autoTranslationLanguage?: InputMaybe<StringComparisonExp>;
  botContext?: InputMaybe<StringComparisonExp>;
  channel?: InputMaybe<ChannelsBoolExp>;
  channelId?: InputMaybe<UuidComparisonExp>;
  chatMessages?: InputMaybe<ChatMessagesBoolExp>;
  chatMessages_aggregate?: InputMaybe<ChatMessagesAggregateBoolExp>;
  chatUsers?: InputMaybe<ChatUsersBoolExp>;
  chatUsers_aggregate?: InputMaybe<ChatUsersAggregateBoolExp>;
  hasUnreadFailedChatMessage?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  insertionUserId?: InputMaybe<UuidComparisonExp>;
  isArchived?: InputMaybe<BooleanComparisonExp>;
  isProductNews?: InputMaybe<BooleanComparisonExp>;
  isSpam?: InputMaybe<BooleanComparisonExp>;
  lastActivityTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  mostRecentInboundChatMessageElapsedInterval?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  owningUser?: InputMaybe<UsersBoolExp>;
  owningUserId?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<ChatTypesEnumComparisonExp>;
  unreadMessageCount?: InputMaybe<IntComparisonExp>;
  userChats?: InputMaybe<UserChatsBoolExp>;
  userChats_aggregate?: InputMaybe<UserChatsAggregateBoolExp>;
};

/** unique or primary key constraints on table "chats" */
export enum ChatsConstraint {
  /** unique or primary key constraint on columns "channel_id", "owning_user_id" */
  ChatsChannelIdOwningUserIdKey = 'chats_channel_id_owning_user_id_key',
  /** unique or primary key constraint on columns "is_product_news", "merchant_id" */
  ChatsMerchantIdIsProductNewsIdx = 'chats_merchant_id_is_product_news_idx',
  /** unique or primary key constraint on columns "id" */
  ChatsPkey = 'chats_pkey'
}

/** columns and relationships of "chats_event_logs" */
export type ChatsEventLogs = {
  __typename?: 'chats_event_logs';
  /** An object relationship */
  chat: Chats;
  chatId: Scalars['uuid'];
  id: Scalars['uuid'];
  insertionTimestamp: Scalars['timestamptz'];
};

/** aggregated selection of "chats_event_logs" */
export type ChatsEventLogsAggregate = {
  __typename?: 'chats_event_logs_aggregate';
  aggregate?: Maybe<ChatsEventLogsAggregateFields>;
  nodes: Array<ChatsEventLogs>;
};

/** aggregate fields of "chats_event_logs" */
export type ChatsEventLogsAggregateFields = {
  __typename?: 'chats_event_logs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChatsEventLogsMaxFields>;
  min?: Maybe<ChatsEventLogsMinFields>;
};


/** aggregate fields of "chats_event_logs" */
export type ChatsEventLogsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChatsEventLogsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chats_event_logs". All fields are combined with a logical 'AND'. */
export type ChatsEventLogsBoolExp = {
  _and?: InputMaybe<Array<ChatsEventLogsBoolExp>>;
  _not?: InputMaybe<ChatsEventLogsBoolExp>;
  _or?: InputMaybe<Array<ChatsEventLogsBoolExp>>;
  chat?: InputMaybe<ChatsBoolExp>;
  chatId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionTimestamp?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "chats_event_logs" */
export enum ChatsEventLogsConstraint {
  /** unique or primary key constraint on columns "id" */
  ChatsEventLogsPkey = 'chats_event_logs_pkey'
}

/** input type for inserting data into table "chats_event_logs" */
export type ChatsEventLogsInsertInput = {
  chat?: InputMaybe<ChatsObjRelInsertInput>;
  chatId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ChatsEventLogsMaxFields = {
  __typename?: 'chats_event_logs_max_fields';
  chatId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ChatsEventLogsMinFields = {
  __typename?: 'chats_event_logs_min_fields';
  chatId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "chats_event_logs" */
export type ChatsEventLogsMutationResponse = {
  __typename?: 'chats_event_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ChatsEventLogs>;
};

/** on_conflict condition type for table "chats_event_logs" */
export type ChatsEventLogsOnConflict = {
  constraint: ChatsEventLogsConstraint;
  update_columns?: Array<ChatsEventLogsUpdateColumn>;
  where?: InputMaybe<ChatsEventLogsBoolExp>;
};

/** Ordering options when selecting data from "chats_event_logs". */
export type ChatsEventLogsOrderBy = {
  chat?: InputMaybe<ChatsOrderBy>;
  chatId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: chats_event_logs */
export type ChatsEventLogsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "chats_event_logs" */
export enum ChatsEventLogsSelectColumn {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp'
}

/** input type for updating data in table "chats_event_logs" */
export type ChatsEventLogsSetInput = {
  chatId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chats_event_logs" */
export type ChatsEventLogsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatsEventLogsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatsEventLogsStreamCursorValueInput = {
  chatId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chats_event_logs" */
export enum ChatsEventLogsUpdateColumn {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp'
}

export type ChatsEventLogsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatsEventLogsSetInput>;
  /** filter the rows which have to be updated */
  where: ChatsEventLogsBoolExp;
};

/** input type for inserting data into table "chats" */
export type ChatsInsertInput = {
  _upsert?: InputMaybe<Scalars['Boolean']>;
  assignedMerchantUser?: InputMaybe<MerchantUsersObjRelInsertInput>;
  assignedMerchantUserId?: InputMaybe<Scalars['uuid']>;
  autoTranslationLanguage?: InputMaybe<Scalars['String']>;
  botContext?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelsObjRelInsertInput>;
  channelId?: InputMaybe<Scalars['uuid']>;
  chatMessages?: InputMaybe<ChatMessagesArrRelInsertInput>;
  chatUsers?: InputMaybe<ChatUsersArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isProductNews?: InputMaybe<Scalars['Boolean']>;
  isSpam?: InputMaybe<Scalars['Boolean']>;
  lastActivityTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owningUser?: InputMaybe<UsersObjRelInsertInput>;
  owningUserId?: InputMaybe<Scalars['uuid']>;
  userChats?: InputMaybe<UserChatsArrRelInsertInput>;
};

/** aggregate max on columns */
export type ChatsMaxFields = {
  __typename?: 'chats_max_fields';
  assignedMerchantUserId?: Maybe<Scalars['uuid']>;
  autoTranslationLanguage?: Maybe<Scalars['String']>;
  botContext?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  lastActivityTimestamp?: Maybe<Scalars['timestamptz']>;
  merchantId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owningUserId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "chats" */
export type ChatsMaxOrderBy = {
  assignedMerchantUserId?: InputMaybe<OrderBy>;
  autoTranslationLanguage?: InputMaybe<OrderBy>;
  botContext?: InputMaybe<OrderBy>;
  channelId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  lastActivityTimestamp?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  owningUserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChatsMinFields = {
  __typename?: 'chats_min_fields';
  assignedMerchantUserId?: Maybe<Scalars['uuid']>;
  autoTranslationLanguage?: Maybe<Scalars['String']>;
  botContext?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  lastActivityTimestamp?: Maybe<Scalars['timestamptz']>;
  merchantId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  owningUserId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "chats" */
export type ChatsMinOrderBy = {
  assignedMerchantUserId?: InputMaybe<OrderBy>;
  autoTranslationLanguage?: InputMaybe<OrderBy>;
  botContext?: InputMaybe<OrderBy>;
  channelId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  lastActivityTimestamp?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  owningUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "chats" */
export type ChatsMutationResponse = {
  __typename?: 'chats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chats>;
};

/** input type for inserting object relation for remote table "chats" */
export type ChatsObjRelInsertInput = {
  data: ChatsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChatsOnConflict>;
};

/** on_conflict condition type for table "chats" */
export type ChatsOnConflict = {
  constraint: ChatsConstraint;
  update_columns?: Array<ChatsUpdateColumn>;
  where?: InputMaybe<ChatsBoolExp>;
};

/** Ordering options when selecting data from "chats". */
export type ChatsOrderBy = {
  _upsert?: InputMaybe<OrderBy>;
  assignedMerchantUser?: InputMaybe<MerchantUsersOrderBy>;
  assignedMerchantUserId?: InputMaybe<OrderBy>;
  autoTranslationLanguage?: InputMaybe<OrderBy>;
  botContext?: InputMaybe<OrderBy>;
  channel?: InputMaybe<ChannelsOrderBy>;
  channelId?: InputMaybe<OrderBy>;
  chatMessages_aggregate?: InputMaybe<ChatMessagesAggregateOrderBy>;
  chatUsers_aggregate?: InputMaybe<ChatUsersAggregateOrderBy>;
  hasUnreadFailedChatMessage?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  isArchived?: InputMaybe<OrderBy>;
  isProductNews?: InputMaybe<OrderBy>;
  isSpam?: InputMaybe<OrderBy>;
  lastActivityTimestamp?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  mostRecentInboundChatMessageElapsedInterval?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  owningUser?: InputMaybe<UsersOrderBy>;
  owningUserId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  unreadMessageCount?: InputMaybe<OrderBy>;
  userChats_aggregate?: InputMaybe<UserChatsAggregateOrderBy>;
};

/** primary key columns input for table: chats */
export type ChatsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "chats" */
export enum ChatsSelectColumn {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  AssignedMerchantUserId = 'assignedMerchantUserId',
  /** column name */
  AutoTranslationLanguage = 'autoTranslationLanguage',
  /** column name */
  BotContext = 'botContext',
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsProductNews = 'isProductNews',
  /** column name */
  IsSpam = 'isSpam',
  /** column name */
  LastActivityTimestamp = 'lastActivityTimestamp',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name',
  /** column name */
  OwningUserId = 'owningUserId',
  /** column name */
  Type = 'type'
}

/** select "chats_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chats" */
export enum ChatsSelectColumnChatsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsProductNews = 'isProductNews',
  /** column name */
  IsSpam = 'isSpam'
}

/** select "chats_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chats" */
export enum ChatsSelectColumnChatsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsProductNews = 'isProductNews',
  /** column name */
  IsSpam = 'isSpam'
}

/** input type for updating data in table "chats" */
export type ChatsSetInput = {
  _upsert?: InputMaybe<Scalars['Boolean']>;
  assignedMerchantUserId?: InputMaybe<Scalars['uuid']>;
  autoTranslationLanguage?: InputMaybe<Scalars['String']>;
  botContext?: InputMaybe<Scalars['String']>;
  channelId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isProductNews?: InputMaybe<Scalars['Boolean']>;
  isSpam?: InputMaybe<Scalars['Boolean']>;
  lastActivityTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owningUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ChatsStddevFields = {
  __typename?: 'chats_stddev_fields';
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_pop on columns */
export type ChatsStddevPopFields = {
  __typename?: 'chats_stddev_pop_fields';
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_samp on columns */
export type ChatsStddevSampFields = {
  __typename?: 'chats_stddev_samp_fields';
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** Streaming cursor of the table "chats" */
export type ChatsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChatsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChatsStreamCursorValueInput = {
  _upsert?: InputMaybe<Scalars['Boolean']>;
  assignedMerchantUserId?: InputMaybe<Scalars['uuid']>;
  autoTranslationLanguage?: InputMaybe<Scalars['String']>;
  botContext?: InputMaybe<Scalars['String']>;
  channelId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isProductNews?: InputMaybe<Scalars['Boolean']>;
  isSpam?: InputMaybe<Scalars['Boolean']>;
  lastActivityTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owningUserId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<ChatTypesEnum>;
};

/** aggregate sum on columns */
export type ChatsSumFields = {
  __typename?: 'chats_sum_fields';
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "chats" */
export enum ChatsUpdateColumn {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  AssignedMerchantUserId = 'assignedMerchantUserId',
  /** column name */
  AutoTranslationLanguage = 'autoTranslationLanguage',
  /** column name */
  BotContext = 'botContext',
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  IsArchived = 'isArchived',
  /** column name */
  IsProductNews = 'isProductNews',
  /** column name */
  IsSpam = 'isSpam',
  /** column name */
  LastActivityTimestamp = 'lastActivityTimestamp',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name',
  /** column name */
  OwningUserId = 'owningUserId'
}

export type ChatsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChatsSetInput>;
  /** filter the rows which have to be updated */
  where: ChatsBoolExp;
};

/** aggregate var_pop on columns */
export type ChatsVarPopFields = {
  __typename?: 'chats_var_pop_fields';
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** aggregate var_samp on columns */
export type ChatsVarSampFields = {
  __typename?: 'chats_var_samp_fields';
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type ChatsVarianceFields = {
  __typename?: 'chats_variance_fields';
  /** A computed field, executes function "most_recent_inbound_chat_message_elapsed_interval" */
  mostRecentInboundChatMessageElapsedInterval?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "unread_message_count" */
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "contact_groups" */
export type ContactGroups = {
  __typename?: 'contact_groups';
  id: Scalars['uuid'];
  /** An object relationship */
  merchant: Merchants;
  /** An array relationship */
  merchantCustomerUserContactGroups: Array<MerchantCustomerUserContactGroups>;
  /** An aggregate relationship */
  merchantCustomerUserContactGroups_aggregate: MerchantCustomerUserContactGroupsAggregate;
  merchantId: Scalars['uuid'];
  /** An array relationship */
  merchantUserContactGroups: Array<MerchantUserContactGroups>;
  /** An aggregate relationship */
  merchantUserContactGroups_aggregate: MerchantUserContactGroupsAggregate;
  name: Scalars['String'];
};


/** columns and relationships of "contact_groups" */
export type ContactGroupsMerchantCustomerUserContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};


/** columns and relationships of "contact_groups" */
export type ContactGroupsMerchantCustomerUserContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};


/** columns and relationships of "contact_groups" */
export type ContactGroupsMerchantUserContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};


/** columns and relationships of "contact_groups" */
export type ContactGroupsMerchantUserContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};

/** aggregated selection of "contact_groups" */
export type ContactGroupsAggregate = {
  __typename?: 'contact_groups_aggregate';
  aggregate?: Maybe<ContactGroupsAggregateFields>;
  nodes: Array<ContactGroups>;
};

/** aggregate fields of "contact_groups" */
export type ContactGroupsAggregateFields = {
  __typename?: 'contact_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContactGroupsMaxFields>;
  min?: Maybe<ContactGroupsMinFields>;
};


/** aggregate fields of "contact_groups" */
export type ContactGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContactGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "contact_groups". All fields are combined with a logical 'AND'. */
export type ContactGroupsBoolExp = {
  _and?: InputMaybe<Array<ContactGroupsBoolExp>>;
  _not?: InputMaybe<ContactGroupsBoolExp>;
  _or?: InputMaybe<Array<ContactGroupsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  merchant?: InputMaybe<MerchantsBoolExp>;
  merchantCustomerUserContactGroups?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
  merchantCustomerUserContactGroups_aggregate?: InputMaybe<MerchantCustomerUserContactGroupsAggregateBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  merchantUserContactGroups?: InputMaybe<MerchantUserContactGroupsBoolExp>;
  merchantUserContactGroups_aggregate?: InputMaybe<MerchantUserContactGroupsAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "contact_groups" */
export enum ContactGroupsConstraint {
  /** unique or primary key constraint on columns "merchant_id", "name" */
  ContactGroupsMerchantIdNameKey = 'contact_groups_merchant_id_name_key',
  /** unique or primary key constraint on columns "id" */
  ContactGroupsPkey = 'contact_groups_pkey'
}

/** input type for inserting data into table "contact_groups" */
export type ContactGroupsInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchant?: InputMaybe<MerchantsObjRelInsertInput>;
  merchantCustomerUserContactGroups?: InputMaybe<MerchantCustomerUserContactGroupsArrRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  merchantUserContactGroups?: InputMaybe<MerchantUserContactGroupsArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ContactGroupsMaxFields = {
  __typename?: 'contact_groups_max_fields';
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ContactGroupsMinFields = {
  __typename?: 'contact_groups_min_fields';
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "contact_groups" */
export type ContactGroupsMutationResponse = {
  __typename?: 'contact_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ContactGroups>;
};

/** input type for inserting object relation for remote table "contact_groups" */
export type ContactGroupsObjRelInsertInput = {
  data: ContactGroupsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ContactGroupsOnConflict>;
};

/** on_conflict condition type for table "contact_groups" */
export type ContactGroupsOnConflict = {
  constraint: ContactGroupsConstraint;
  update_columns?: Array<ContactGroupsUpdateColumn>;
  where?: InputMaybe<ContactGroupsBoolExp>;
};

/** Ordering options when selecting data from "contact_groups". */
export type ContactGroupsOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantsOrderBy>;
  merchantCustomerUserContactGroups_aggregate?: InputMaybe<MerchantCustomerUserContactGroupsAggregateOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  merchantUserContactGroups_aggregate?: InputMaybe<MerchantUserContactGroupsAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: contact_groups */
export type ContactGroupsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "contact_groups" */
export enum ContactGroupsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "contact_groups" */
export type ContactGroupsSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "contact_groups" */
export type ContactGroupsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ContactGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactGroupsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "contact_groups" */
export enum ContactGroupsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name'
}

export type ContactGroupsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContactGroupsSetInput>;
  /** filter the rows which have to be updated */
  where: ContactGroupsBoolExp;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customer_users" */
export type CustomerUsers = {
  __typename?: 'customer_users';
  _upsert: Scalars['Boolean'];
  /** An array relationship */
  campaignCustomerUsers: Array<CampaignCustomerUsers>;
  /** An aggregate relationship */
  campaignCustomerUsers_aggregate: CampaignCustomerUsersAggregate;
  id: Scalars['uuid'];
  insertionTimestamp: Scalars['timestamptz'];
  /** An array relationship */
  merchantCustomerUsers: Array<MerchantCustomerUsers>;
  /** An aggregate relationship */
  merchantCustomerUsers_aggregate: MerchantCustomerUsersAggregate;
  /** An object relationship */
  user?: Maybe<Users>;
  whatsappDisplayName?: Maybe<Scalars['String']>;
  whatsappPhoneNumber: Scalars['String'];
};


/** columns and relationships of "customer_users" */
export type CustomerUsersCampaignCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignCustomerUsersOrderBy>>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};


/** columns and relationships of "customer_users" */
export type CustomerUsersCampaignCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignCustomerUsersOrderBy>>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};


/** columns and relationships of "customer_users" */
export type CustomerUsersMerchantCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


/** columns and relationships of "customer_users" */
export type CustomerUsersMerchantCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};

/** aggregated selection of "customer_users" */
export type CustomerUsersAggregate = {
  __typename?: 'customer_users_aggregate';
  aggregate?: Maybe<CustomerUsersAggregateFields>;
  nodes: Array<CustomerUsers>;
};

/** aggregate fields of "customer_users" */
export type CustomerUsersAggregateFields = {
  __typename?: 'customer_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CustomerUsersMaxFields>;
  min?: Maybe<CustomerUsersMinFields>;
};


/** aggregate fields of "customer_users" */
export type CustomerUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CustomerUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer_users". All fields are combined with a logical 'AND'. */
export type CustomerUsersBoolExp = {
  _and?: InputMaybe<Array<CustomerUsersBoolExp>>;
  _not?: InputMaybe<CustomerUsersBoolExp>;
  _or?: InputMaybe<Array<CustomerUsersBoolExp>>;
  _upsert?: InputMaybe<BooleanComparisonExp>;
  campaignCustomerUsers?: InputMaybe<CampaignCustomerUsersBoolExp>;
  campaignCustomerUsers_aggregate?: InputMaybe<CampaignCustomerUsersAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  merchantCustomerUsers?: InputMaybe<MerchantCustomerUsersBoolExp>;
  merchantCustomerUsers_aggregate?: InputMaybe<MerchantCustomerUsersAggregateBoolExp>;
  user?: InputMaybe<UsersBoolExp>;
  whatsappDisplayName?: InputMaybe<StringComparisonExp>;
  whatsappPhoneNumber?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "customer_users" */
export enum CustomerUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  CustomerUsersPkey = 'customer_users_pkey',
  /** unique or primary key constraint on columns "whatsapp_phone_number" */
  CustomerUsersWhatsappPhoneNumberKey = 'customer_users_whatsapp_phone_number_key'
}

/** input type for inserting data into table "customer_users" */
export type CustomerUsersInsertInput = {
  _upsert?: InputMaybe<Scalars['Boolean']>;
  campaignCustomerUsers?: InputMaybe<CampaignCustomerUsersArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantCustomerUsers?: InputMaybe<MerchantCustomerUsersArrRelInsertInput>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  whatsappDisplayName?: InputMaybe<Scalars['String']>;
  whatsappPhoneNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CustomerUsersMaxFields = {
  __typename?: 'customer_users_max_fields';
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  whatsappDisplayName?: Maybe<Scalars['String']>;
  whatsappPhoneNumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CustomerUsersMinFields = {
  __typename?: 'customer_users_min_fields';
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  whatsappDisplayName?: Maybe<Scalars['String']>;
  whatsappPhoneNumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "customer_users" */
export type CustomerUsersMutationResponse = {
  __typename?: 'customer_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomerUsers>;
};

/** input type for inserting object relation for remote table "customer_users" */
export type CustomerUsersObjRelInsertInput = {
  data: CustomerUsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomerUsersOnConflict>;
};

/** on_conflict condition type for table "customer_users" */
export type CustomerUsersOnConflict = {
  constraint: CustomerUsersConstraint;
  update_columns?: Array<CustomerUsersUpdateColumn>;
  where?: InputMaybe<CustomerUsersBoolExp>;
};

/** Ordering options when selecting data from "customer_users". */
export type CustomerUsersOrderBy = {
  _upsert?: InputMaybe<OrderBy>;
  campaignCustomerUsers_aggregate?: InputMaybe<CampaignCustomerUsersAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  merchantCustomerUsers_aggregate?: InputMaybe<MerchantCustomerUsersAggregateOrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  whatsappDisplayName?: InputMaybe<OrderBy>;
  whatsappPhoneNumber?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: customer_users */
export type CustomerUsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "customer_users" */
export enum CustomerUsersSelectColumn {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  WhatsappDisplayName = 'whatsappDisplayName',
  /** column name */
  WhatsappPhoneNumber = 'whatsappPhoneNumber'
}

/** input type for updating data in table "customer_users" */
export type CustomerUsersSetInput = {
  _upsert?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  whatsappDisplayName?: InputMaybe<Scalars['String']>;
  whatsappPhoneNumber?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "customer_users" */
export type CustomerUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CustomerUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomerUsersStreamCursorValueInput = {
  _upsert?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  whatsappDisplayName?: InputMaybe<Scalars['String']>;
  whatsappPhoneNumber?: InputMaybe<Scalars['String']>;
};

/** update columns of table "customer_users" */
export enum CustomerUsersUpdateColumn {
  /** column name */
  Upsert = '_upsert',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  WhatsappDisplayName = 'whatsappDisplayName',
  /** column name */
  WhatsappPhoneNumber = 'whatsappPhoneNumber'
}

export type CustomerUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomerUsersSetInput>;
  /** filter the rows which have to be updated */
  where: CustomerUsersBoolExp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "email_notification_preferences" */
export type EmailNotificationPreferences = {
  __typename?: 'email_notification_preferences';
  preference: Scalars['String'];
};

/** aggregated selection of "email_notification_preferences" */
export type EmailNotificationPreferencesAggregate = {
  __typename?: 'email_notification_preferences_aggregate';
  aggregate?: Maybe<EmailNotificationPreferencesAggregateFields>;
  nodes: Array<EmailNotificationPreferences>;
};

/** aggregate fields of "email_notification_preferences" */
export type EmailNotificationPreferencesAggregateFields = {
  __typename?: 'email_notification_preferences_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<EmailNotificationPreferencesMaxFields>;
  min?: Maybe<EmailNotificationPreferencesMinFields>;
};


/** aggregate fields of "email_notification_preferences" */
export type EmailNotificationPreferencesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EmailNotificationPreferencesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "email_notification_preferences". All fields are combined with a logical 'AND'. */
export type EmailNotificationPreferencesBoolExp = {
  _and?: InputMaybe<Array<EmailNotificationPreferencesBoolExp>>;
  _not?: InputMaybe<EmailNotificationPreferencesBoolExp>;
  _or?: InputMaybe<Array<EmailNotificationPreferencesBoolExp>>;
  preference?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "email_notification_preferences" */
export enum EmailNotificationPreferencesConstraint {
  /** unique or primary key constraint on columns "preference" */
  EmailNotificationPreferencesPkey = 'email_notification_preferences_pkey'
}

export enum EmailNotificationPreferencesEnum {
  Instant = 'INSTANT',
  None = 'NONE',
  Reminders = 'REMINDERS'
}

/** Boolean expression to compare columns of type "email_notification_preferences_enum". All fields are combined with logical 'AND'. */
export type EmailNotificationPreferencesEnumComparisonExp = {
  _eq?: InputMaybe<EmailNotificationPreferencesEnum>;
  _in?: InputMaybe<Array<EmailNotificationPreferencesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailNotificationPreferencesEnum>;
  _nin?: InputMaybe<Array<EmailNotificationPreferencesEnum>>;
};

/** input type for inserting data into table "email_notification_preferences" */
export type EmailNotificationPreferencesInsertInput = {
  preference?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type EmailNotificationPreferencesMaxFields = {
  __typename?: 'email_notification_preferences_max_fields';
  preference?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EmailNotificationPreferencesMinFields = {
  __typename?: 'email_notification_preferences_min_fields';
  preference?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "email_notification_preferences" */
export type EmailNotificationPreferencesMutationResponse = {
  __typename?: 'email_notification_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EmailNotificationPreferences>;
};

/** on_conflict condition type for table "email_notification_preferences" */
export type EmailNotificationPreferencesOnConflict = {
  constraint: EmailNotificationPreferencesConstraint;
  update_columns?: Array<EmailNotificationPreferencesUpdateColumn>;
  where?: InputMaybe<EmailNotificationPreferencesBoolExp>;
};

/** Ordering options when selecting data from "email_notification_preferences". */
export type EmailNotificationPreferencesOrderBy = {
  preference?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: email_notification_preferences */
export type EmailNotificationPreferencesPkColumnsInput = {
  preference: Scalars['String'];
};

/** select columns of table "email_notification_preferences" */
export enum EmailNotificationPreferencesSelectColumn {
  /** column name */
  Preference = 'preference'
}

/** input type for updating data in table "email_notification_preferences" */
export type EmailNotificationPreferencesSetInput = {
  preference?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "email_notification_preferences" */
export type EmailNotificationPreferencesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EmailNotificationPreferencesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EmailNotificationPreferencesStreamCursorValueInput = {
  preference?: InputMaybe<Scalars['String']>;
};

/** update columns of table "email_notification_preferences" */
export enum EmailNotificationPreferencesUpdateColumn {
  /** column name */
  Preference = 'preference'
}

export type EmailNotificationPreferencesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EmailNotificationPreferencesSetInput>;
  /** filter the rows which have to be updated */
  where: EmailNotificationPreferencesBoolExp;
};

/** columns and relationships of "file_mime_types" */
export type FileMimeTypes = {
  __typename?: 'file_mime_types';
  /** react-admin requires an id column for every resource (=table) */
  id?: Maybe<Scalars['String']>;
  mimeType: Scalars['String'];
  whatsappMediaType: WhatsappMediaTypesEnum;
};

/** aggregated selection of "file_mime_types" */
export type FileMimeTypesAggregate = {
  __typename?: 'file_mime_types_aggregate';
  aggregate?: Maybe<FileMimeTypesAggregateFields>;
  nodes: Array<FileMimeTypes>;
};

/** aggregate fields of "file_mime_types" */
export type FileMimeTypesAggregateFields = {
  __typename?: 'file_mime_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FileMimeTypesMaxFields>;
  min?: Maybe<FileMimeTypesMinFields>;
};


/** aggregate fields of "file_mime_types" */
export type FileMimeTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FileMimeTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "file_mime_types". All fields are combined with a logical 'AND'. */
export type FileMimeTypesBoolExp = {
  _and?: InputMaybe<Array<FileMimeTypesBoolExp>>;
  _not?: InputMaybe<FileMimeTypesBoolExp>;
  _or?: InputMaybe<Array<FileMimeTypesBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
  mimeType?: InputMaybe<StringComparisonExp>;
  whatsappMediaType?: InputMaybe<WhatsappMediaTypesEnumComparisonExp>;
};

/** unique or primary key constraints on table "file_mime_types" */
export enum FileMimeTypesConstraint {
  /** unique or primary key constraint on columns "mime_type" */
  FileMimeTypesPkey = 'file_mime_types_pkey'
}

/** input type for inserting data into table "file_mime_types" */
export type FileMimeTypesInsertInput = {
  mimeType?: InputMaybe<Scalars['String']>;
  whatsappMediaType?: InputMaybe<WhatsappMediaTypesEnum>;
};

/** aggregate max on columns */
export type FileMimeTypesMaxFields = {
  __typename?: 'file_mime_types_max_fields';
  /** react-admin requires an id column for every resource (=table) */
  id?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type FileMimeTypesMinFields = {
  __typename?: 'file_mime_types_min_fields';
  /** react-admin requires an id column for every resource (=table) */
  id?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "file_mime_types" */
export type FileMimeTypesMutationResponse = {
  __typename?: 'file_mime_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<FileMimeTypes>;
};

/** on_conflict condition type for table "file_mime_types" */
export type FileMimeTypesOnConflict = {
  constraint: FileMimeTypesConstraint;
  update_columns?: Array<FileMimeTypesUpdateColumn>;
  where?: InputMaybe<FileMimeTypesBoolExp>;
};

/** Ordering options when selecting data from "file_mime_types". */
export type FileMimeTypesOrderBy = {
  id?: InputMaybe<OrderBy>;
  mimeType?: InputMaybe<OrderBy>;
  whatsappMediaType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: file_mime_types */
export type FileMimeTypesPkColumnsInput = {
  mimeType: Scalars['String'];
};

/** select columns of table "file_mime_types" */
export enum FileMimeTypesSelectColumn {
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  WhatsappMediaType = 'whatsappMediaType'
}

/** input type for updating data in table "file_mime_types" */
export type FileMimeTypesSetInput = {
  mimeType?: InputMaybe<Scalars['String']>;
  whatsappMediaType?: InputMaybe<WhatsappMediaTypesEnum>;
};

/** Streaming cursor of the table "file_mime_types" */
export type FileMimeTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FileMimeTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FileMimeTypesStreamCursorValueInput = {
  mimeType?: InputMaybe<Scalars['String']>;
  whatsappMediaType?: InputMaybe<WhatsappMediaTypesEnum>;
};

/** update columns of table "file_mime_types" */
export enum FileMimeTypesUpdateColumn {
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  WhatsappMediaType = 'whatsappMediaType'
}

export type FileMimeTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FileMimeTypesSetInput>;
  /** filter the rows which have to be updated */
  where: FileMimeTypesBoolExp;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  contentLanguage?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  insertionUser: Users;
  insertionUserId: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  mimeType: Scalars['String'];
  s3Key: Scalars['String'];
  thumbnailS3Key?: Maybe<Scalars['String']>;
  transcribedText?: Maybe<Scalars['String']>;
};


/** columns and relationships of "files" */
export type FilesMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "files" */
export type FilesAggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<FilesAggregateFields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type FilesAggregateFields = {
  __typename?: 'files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FilesMaxFields>;
  min?: Maybe<FilesMinFields>;
};


/** aggregate fields of "files" */
export type FilesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FilesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type FilesAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type FilesBoolExp = {
  _and?: InputMaybe<Array<FilesBoolExp>>;
  _not?: InputMaybe<FilesBoolExp>;
  _or?: InputMaybe<Array<FilesBoolExp>>;
  contentLanguage?: InputMaybe<StringComparisonExp>;
  filename?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionUser?: InputMaybe<UsersBoolExp>;
  insertionUserId?: InputMaybe<UuidComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  mimeType?: InputMaybe<StringComparisonExp>;
  s3Key?: InputMaybe<StringComparisonExp>;
  thumbnailS3Key?: InputMaybe<StringComparisonExp>;
  transcribedText?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "files" */
export enum FilesConstraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey',
  /** unique or primary key constraint on columns "s3_key" */
  FilesS3KeyKey = 'files_s3_key_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type FilesDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type FilesDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type FilesDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "files" */
export type FilesInsertInput = {
  contentLanguage?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionUser?: InputMaybe<UsersObjRelInsertInput>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mimeType?: InputMaybe<Scalars['String']>;
  s3Key?: InputMaybe<Scalars['String']>;
  thumbnailS3Key?: InputMaybe<Scalars['String']>;
  transcribedText?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type FilesMaxFields = {
  __typename?: 'files_max_fields';
  contentLanguage?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  mimeType?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  thumbnailS3Key?: Maybe<Scalars['String']>;
  transcribedText?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type FilesMinFields = {
  __typename?: 'files_min_fields';
  contentLanguage?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  mimeType?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  thumbnailS3Key?: Maybe<Scalars['String']>;
  transcribedText?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "files" */
export type FilesMutationResponse = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type FilesObjRelInsertInput = {
  data: FilesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FilesOnConflict>;
};

/** on_conflict condition type for table "files" */
export type FilesOnConflict = {
  constraint: FilesConstraint;
  update_columns?: Array<FilesUpdateColumn>;
  where?: InputMaybe<FilesBoolExp>;
};

/** Ordering options when selecting data from "files". */
export type FilesOrderBy = {
  contentLanguage?: InputMaybe<OrderBy>;
  filename?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionUser?: InputMaybe<UsersOrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  mimeType?: InputMaybe<OrderBy>;
  s3Key?: InputMaybe<OrderBy>;
  thumbnailS3Key?: InputMaybe<OrderBy>;
  transcribedText?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: files */
export type FilesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type FilesPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "files" */
export enum FilesSelectColumn {
  /** column name */
  ContentLanguage = 'contentLanguage',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  S3Key = 's3Key',
  /** column name */
  ThumbnailS3Key = 'thumbnailS3Key',
  /** column name */
  TranscribedText = 'transcribedText'
}

/** input type for updating data in table "files" */
export type FilesSetInput = {
  contentLanguage?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mimeType?: InputMaybe<Scalars['String']>;
  s3Key?: InputMaybe<Scalars['String']>;
  thumbnailS3Key?: InputMaybe<Scalars['String']>;
  transcribedText?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "files" */
export type FilesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FilesStreamCursorValueInput = {
  contentLanguage?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mimeType?: InputMaybe<Scalars['String']>;
  s3Key?: InputMaybe<Scalars['String']>;
  thumbnailS3Key?: InputMaybe<Scalars['String']>;
  transcribedText?: InputMaybe<Scalars['String']>;
};

/** update columns of table "files" */
export enum FilesUpdateColumn {
  /** column name */
  ContentLanguage = 'contentLanguage',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  S3Key = 's3Key',
  /** column name */
  ThumbnailS3Key = 'thumbnailS3Key',
  /** column name */
  TranscribedText = 'transcribedText'
}

export type FilesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<FilesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<FilesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<FilesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<FilesDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<FilesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FilesSetInput>;
  /** filter the rows which have to be updated */
  where: FilesBoolExp;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "merchant_api_users" */
export type MerchantApiUsers = {
  __typename?: 'merchant_api_users';
  apiKey: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  merchant?: Maybe<Merchants>;
  merchantId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "merchant_api_users" */
export type MerchantApiUsersAggregate = {
  __typename?: 'merchant_api_users_aggregate';
  aggregate?: Maybe<MerchantApiUsersAggregateFields>;
  nodes: Array<MerchantApiUsers>;
};

export type MerchantApiUsersAggregateBoolExp = {
  count?: InputMaybe<MerchantApiUsersAggregateBoolExpCount>;
};

export type MerchantApiUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantApiUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantApiUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchant_api_users" */
export type MerchantApiUsersAggregateFields = {
  __typename?: 'merchant_api_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MerchantApiUsersMaxFields>;
  min?: Maybe<MerchantApiUsersMinFields>;
};


/** aggregate fields of "merchant_api_users" */
export type MerchantApiUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantApiUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_api_users" */
export type MerchantApiUsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantApiUsersMaxOrderBy>;
  min?: InputMaybe<MerchantApiUsersMinOrderBy>;
};

/** input type for inserting array relation for remote table "merchant_api_users" */
export type MerchantApiUsersArrRelInsertInput = {
  data: Array<MerchantApiUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantApiUsersOnConflict>;
};

/** Boolean expression to filter rows from the table "merchant_api_users". All fields are combined with a logical 'AND'. */
export type MerchantApiUsersBoolExp = {
  _and?: InputMaybe<Array<MerchantApiUsersBoolExp>>;
  _not?: InputMaybe<MerchantApiUsersBoolExp>;
  _or?: InputMaybe<Array<MerchantApiUsersBoolExp>>;
  apiKey?: InputMaybe<StringComparisonExp>;
  emailAddress?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  merchant?: InputMaybe<MerchantsBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
};

/** unique or primary key constraints on table "merchant_api_users" */
export enum MerchantApiUsersConstraint {
  /** unique or primary key constraint on columns "api_key" */
  MerchantApiUsersApiKeyKey = 'merchant_api_users_api_key_key',
  /** unique or primary key constraint on columns "id" */
  MerchantApiUsersPkey = 'merchant_api_users_pkey'
}

/** input type for inserting data into table "merchant_api_users" */
export type MerchantApiUsersInsertInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  merchant?: InputMaybe<MerchantsObjRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
};

/** aggregate max on columns */
export type MerchantApiUsersMaxFields = {
  __typename?: 'merchant_api_users_max_fields';
  apiKey?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "merchant_api_users" */
export type MerchantApiUsersMaxOrderBy = {
  apiKey?: InputMaybe<OrderBy>;
  emailAddress?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantApiUsersMinFields = {
  __typename?: 'merchant_api_users_min_fields';
  apiKey?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "merchant_api_users" */
export type MerchantApiUsersMinOrderBy = {
  apiKey?: InputMaybe<OrderBy>;
  emailAddress?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_api_users" */
export type MerchantApiUsersMutationResponse = {
  __typename?: 'merchant_api_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantApiUsers>;
};

/** input type for inserting object relation for remote table "merchant_api_users" */
export type MerchantApiUsersObjRelInsertInput = {
  data: MerchantApiUsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantApiUsersOnConflict>;
};

/** on_conflict condition type for table "merchant_api_users" */
export type MerchantApiUsersOnConflict = {
  constraint: MerchantApiUsersConstraint;
  update_columns?: Array<MerchantApiUsersUpdateColumn>;
  where?: InputMaybe<MerchantApiUsersBoolExp>;
};

/** Ordering options when selecting data from "merchant_api_users". */
export type MerchantApiUsersOrderBy = {
  apiKey?: InputMaybe<OrderBy>;
  emailAddress?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantsOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
};

/** primary key columns input for table: merchant_api_users */
export type MerchantApiUsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "merchant_api_users" */
export enum MerchantApiUsersSelectColumn {
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "merchant_api_users" */
export type MerchantApiUsersSetInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "merchant_api_users" */
export type MerchantApiUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantApiUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantApiUsersStreamCursorValueInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "merchant_api_users" */
export enum MerchantApiUsersUpdateColumn {
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name'
}

export type MerchantApiUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantApiUsersSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantApiUsersBoolExp;
};

/** columns and relationships of "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroups = {
  __typename?: 'merchant_customer_user_contact_groups';
  /** An object relationship */
  contactGroup: ContactGroups;
  contactGroupId: Scalars['uuid'];
  /** An object relationship */
  merchantCustomerUser: MerchantCustomerUsers;
  merchantCustomerUserId: Scalars['uuid'];
};

/** aggregated selection of "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsAggregate = {
  __typename?: 'merchant_customer_user_contact_groups_aggregate';
  aggregate?: Maybe<MerchantCustomerUserContactGroupsAggregateFields>;
  nodes: Array<MerchantCustomerUserContactGroups>;
};

export type MerchantCustomerUserContactGroupsAggregateBoolExp = {
  count?: InputMaybe<MerchantCustomerUserContactGroupsAggregateBoolExpCount>;
};

export type MerchantCustomerUserContactGroupsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsAggregateFields = {
  __typename?: 'merchant_customer_user_contact_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MerchantCustomerUserContactGroupsMaxFields>;
  min?: Maybe<MerchantCustomerUserContactGroupsMinFields>;
};


/** aggregate fields of "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantCustomerUserContactGroupsMaxOrderBy>;
  min?: InputMaybe<MerchantCustomerUserContactGroupsMinOrderBy>;
};

/** input type for inserting array relation for remote table "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsArrRelInsertInput = {
  data: Array<MerchantCustomerUserContactGroupsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantCustomerUserContactGroupsOnConflict>;
};

/** Boolean expression to filter rows from the table "merchant_customer_user_contact_groups". All fields are combined with a logical 'AND'. */
export type MerchantCustomerUserContactGroupsBoolExp = {
  _and?: InputMaybe<Array<MerchantCustomerUserContactGroupsBoolExp>>;
  _not?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
  _or?: InputMaybe<Array<MerchantCustomerUserContactGroupsBoolExp>>;
  contactGroup?: InputMaybe<ContactGroupsBoolExp>;
  contactGroupId?: InputMaybe<UuidComparisonExp>;
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersBoolExp>;
  merchantCustomerUserId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "merchant_customer_user_contact_groups" */
export enum MerchantCustomerUserContactGroupsConstraint {
  /** unique or primary key constraint on columns "merchant_customer_user_id", "contact_group_id" */
  MerchantCustomerUserContactGroupsPkey = 'merchant_customer_user_contact_groups_pkey'
}

/** input type for inserting data into table "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsInsertInput = {
  contactGroup?: InputMaybe<ContactGroupsObjRelInsertInput>;
  contactGroupId?: InputMaybe<Scalars['uuid']>;
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersObjRelInsertInput>;
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MerchantCustomerUserContactGroupsMaxFields = {
  __typename?: 'merchant_customer_user_contact_groups_max_fields';
  contactGroupId?: Maybe<Scalars['uuid']>;
  merchantCustomerUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsMaxOrderBy = {
  contactGroupId?: InputMaybe<OrderBy>;
  merchantCustomerUserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantCustomerUserContactGroupsMinFields = {
  __typename?: 'merchant_customer_user_contact_groups_min_fields';
  contactGroupId?: Maybe<Scalars['uuid']>;
  merchantCustomerUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsMinOrderBy = {
  contactGroupId?: InputMaybe<OrderBy>;
  merchantCustomerUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsMutationResponse = {
  __typename?: 'merchant_customer_user_contact_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantCustomerUserContactGroups>;
};

/** on_conflict condition type for table "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsOnConflict = {
  constraint: MerchantCustomerUserContactGroupsConstraint;
  update_columns?: Array<MerchantCustomerUserContactGroupsUpdateColumn>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};

/** Ordering options when selecting data from "merchant_customer_user_contact_groups". */
export type MerchantCustomerUserContactGroupsOrderBy = {
  contactGroup?: InputMaybe<ContactGroupsOrderBy>;
  contactGroupId?: InputMaybe<OrderBy>;
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersOrderBy>;
  merchantCustomerUserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_customer_user_contact_groups */
export type MerchantCustomerUserContactGroupsPkColumnsInput = {
  contactGroupId: Scalars['uuid'];
  merchantCustomerUserId: Scalars['uuid'];
};

/** select columns of table "merchant_customer_user_contact_groups" */
export enum MerchantCustomerUserContactGroupsSelectColumn {
  /** column name */
  ContactGroupId = 'contactGroupId',
  /** column name */
  MerchantCustomerUserId = 'merchantCustomerUserId'
}

/** input type for updating data in table "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsSetInput = {
  contactGroupId?: InputMaybe<Scalars['uuid']>;
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "merchant_customer_user_contact_groups" */
export type MerchantCustomerUserContactGroupsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantCustomerUserContactGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantCustomerUserContactGroupsStreamCursorValueInput = {
  contactGroupId?: InputMaybe<Scalars['uuid']>;
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "merchant_customer_user_contact_groups" */
export enum MerchantCustomerUserContactGroupsUpdateColumn {
  /** column name */
  ContactGroupId = 'contactGroupId',
  /** column name */
  MerchantCustomerUserId = 'merchantCustomerUserId'
}

export type MerchantCustomerUserContactGroupsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantCustomerUserContactGroupsSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantCustomerUserContactGroupsBoolExp;
};

/** columns and relationships of "merchant_customer_user_tags" */
export type MerchantCustomerUserTags = {
  __typename?: 'merchant_customer_user_tags';
  /** An object relationship */
  merchantCustomerUser: MerchantCustomerUsers;
  merchantCustomerUserId: Scalars['uuid'];
  /** An object relationship */
  tag: Tags;
  tagId: Scalars['uuid'];
};

/** aggregated selection of "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsAggregate = {
  __typename?: 'merchant_customer_user_tags_aggregate';
  aggregate?: Maybe<MerchantCustomerUserTagsAggregateFields>;
  nodes: Array<MerchantCustomerUserTags>;
};

export type MerchantCustomerUserTagsAggregateBoolExp = {
  count?: InputMaybe<MerchantCustomerUserTagsAggregateBoolExpCount>;
};

export type MerchantCustomerUserTagsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantCustomerUserTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsAggregateFields = {
  __typename?: 'merchant_customer_user_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MerchantCustomerUserTagsMaxFields>;
  min?: Maybe<MerchantCustomerUserTagsMinFields>;
};


/** aggregate fields of "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantCustomerUserTagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantCustomerUserTagsMaxOrderBy>;
  min?: InputMaybe<MerchantCustomerUserTagsMinOrderBy>;
};

/** input type for inserting array relation for remote table "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsArrRelInsertInput = {
  data: Array<MerchantCustomerUserTagsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantCustomerUserTagsOnConflict>;
};

/** Boolean expression to filter rows from the table "merchant_customer_user_tags". All fields are combined with a logical 'AND'. */
export type MerchantCustomerUserTagsBoolExp = {
  _and?: InputMaybe<Array<MerchantCustomerUserTagsBoolExp>>;
  _not?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
  _or?: InputMaybe<Array<MerchantCustomerUserTagsBoolExp>>;
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersBoolExp>;
  merchantCustomerUserId?: InputMaybe<UuidComparisonExp>;
  tag?: InputMaybe<TagsBoolExp>;
  tagId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "merchant_customer_user_tags" */
export enum MerchantCustomerUserTagsConstraint {
  /** unique or primary key constraint on columns "merchant_customer_user_id", "tag_id" */
  MerchantCustomerUserTagsPkey = 'merchant_customer_user_tags_pkey'
}

/** input type for inserting data into table "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsInsertInput = {
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersObjRelInsertInput>;
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<TagsObjRelInsertInput>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MerchantCustomerUserTagsMaxFields = {
  __typename?: 'merchant_customer_user_tags_max_fields';
  merchantCustomerUserId?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsMaxOrderBy = {
  merchantCustomerUserId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantCustomerUserTagsMinFields = {
  __typename?: 'merchant_customer_user_tags_min_fields';
  merchantCustomerUserId?: Maybe<Scalars['uuid']>;
  tagId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsMinOrderBy = {
  merchantCustomerUserId?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsMutationResponse = {
  __typename?: 'merchant_customer_user_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantCustomerUserTags>;
};

/** on_conflict condition type for table "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsOnConflict = {
  constraint: MerchantCustomerUserTagsConstraint;
  update_columns?: Array<MerchantCustomerUserTagsUpdateColumn>;
  where?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
};

/** Ordering options when selecting data from "merchant_customer_user_tags". */
export type MerchantCustomerUserTagsOrderBy = {
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersOrderBy>;
  merchantCustomerUserId?: InputMaybe<OrderBy>;
  tag?: InputMaybe<TagsOrderBy>;
  tagId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_customer_user_tags */
export type MerchantCustomerUserTagsPkColumnsInput = {
  merchantCustomerUserId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};

/** select columns of table "merchant_customer_user_tags" */
export enum MerchantCustomerUserTagsSelectColumn {
  /** column name */
  MerchantCustomerUserId = 'merchantCustomerUserId',
  /** column name */
  TagId = 'tagId'
}

/** input type for updating data in table "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsSetInput = {
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "merchant_customer_user_tags" */
export type MerchantCustomerUserTagsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantCustomerUserTagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantCustomerUserTagsStreamCursorValueInput = {
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
  tagId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "merchant_customer_user_tags" */
export enum MerchantCustomerUserTagsUpdateColumn {
  /** column name */
  MerchantCustomerUserId = 'merchantCustomerUserId',
  /** column name */
  TagId = 'tagId'
}

export type MerchantCustomerUserTagsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantCustomerUserTagsSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantCustomerUserTagsBoolExp;
};

/** columns and relationships of "merchant_customer_users" */
export type MerchantCustomerUsers = {
  __typename?: 'merchant_customer_users';
  /** An array relationship */
  aggregatedMerchantCustomerUserTags: Array<AggregatedMerchantCustomerUserTags>;
  /** An aggregate relationship */
  aggregatedMerchantCustomerUserTags_aggregate: AggregatedMerchantCustomerUserTagsAggregate;
  birthdayDate?: Maybe<Scalars['date']>;
  birthdayDayOfMonth?: Maybe<Scalars['numeric']>;
  birthdayMonth?: Maybe<Scalars['numeric']>;
  companyCity?: Maybe<Scalars['String']>;
  companyHouseNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyStreet?: Maybe<Scalars['String']>;
  companyZipCode?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  /** An object relationship */
  customerUser: CustomerUsers;
  customerUserId: Scalars['uuid'];
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  insertionUserId: Scalars['uuid'];
  isMarketingConsentGranted?: Maybe<Scalars['Boolean']>;
  lastMarketingConsentUpdateTimestamp?: Maybe<Scalars['timestamp']>;
  lastName?: Maybe<Scalars['String']>;
  /** An object relationship */
  merchant: Merchants;
  /** An array relationship */
  merchantCustomerUserContactGroups: Array<MerchantCustomerUserContactGroups>;
  /** An aggregate relationship */
  merchantCustomerUserContactGroups_aggregate: MerchantCustomerUserContactGroupsAggregate;
  /** An array relationship */
  merchantCustomerUserTags: Array<MerchantCustomerUserTags>;
  /** An aggregate relationship */
  merchantCustomerUserTags_aggregate: MerchantCustomerUserTagsAggregate;
  merchantId: Scalars['uuid'];
  phoneNumber?: Maybe<Scalars['String']>;
  /** An object relationship */
  primaryResponsibleMerchantUser?: Maybe<MerchantUsers>;
  primaryResponsibleMerchantUserId?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  salesforceRecordAttribute?: Maybe<SalesforceRecordAttributesEnum>;
  salesforceRecordIdentifier?: Maybe<Scalars['String']>;
  /** An array relationship */
  secondaryResponsibleMerchantUsers: Array<SecondaryResponsibleMerchantUsers>;
  /** An aggregate relationship */
  secondaryResponsibleMerchantUsers_aggregate: SecondaryResponsibleMerchantUsersAggregate;
  trade?: Maybe<Scalars['String']>;
  whatsappPhoneNumber: Scalars['String'];
};


/** columns and relationships of "merchant_customer_users" */
export type MerchantCustomerUsersAggregatedMerchantCustomerUserTagsArgs = {
  distinct_on?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
};


/** columns and relationships of "merchant_customer_users" */
export type MerchantCustomerUsersAggregatedMerchantCustomerUserTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
};


/** columns and relationships of "merchant_customer_users" */
export type MerchantCustomerUsersMerchantCustomerUserContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};


/** columns and relationships of "merchant_customer_users" */
export type MerchantCustomerUsersMerchantCustomerUserContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};


/** columns and relationships of "merchant_customer_users" */
export type MerchantCustomerUsersMerchantCustomerUserTagsArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
};


/** columns and relationships of "merchant_customer_users" */
export type MerchantCustomerUsersMerchantCustomerUserTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
};


/** columns and relationships of "merchant_customer_users" */
export type MerchantCustomerUsersSecondaryResponsibleMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<SecondaryResponsibleMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecondaryResponsibleMerchantUsersOrderBy>>;
  where?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
};


/** columns and relationships of "merchant_customer_users" */
export type MerchantCustomerUsersSecondaryResponsibleMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<SecondaryResponsibleMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecondaryResponsibleMerchantUsersOrderBy>>;
  where?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
};

/** aggregated selection of "merchant_customer_users" */
export type MerchantCustomerUsersAggregate = {
  __typename?: 'merchant_customer_users_aggregate';
  aggregate?: Maybe<MerchantCustomerUsersAggregateFields>;
  nodes: Array<MerchantCustomerUsers>;
};

export type MerchantCustomerUsersAggregateBoolExp = {
  bool_and?: InputMaybe<MerchantCustomerUsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<MerchantCustomerUsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<MerchantCustomerUsersAggregateBoolExpCount>;
};

export type MerchantCustomerUsersAggregateBoolExpBoolAnd = {
  arguments: MerchantCustomerUsersSelectColumnMerchantCustomerUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantCustomerUsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantCustomerUsersAggregateBoolExpBoolOr = {
  arguments: MerchantCustomerUsersSelectColumnMerchantCustomerUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantCustomerUsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantCustomerUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantCustomerUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchant_customer_users" */
export type MerchantCustomerUsersAggregateFields = {
  __typename?: 'merchant_customer_users_aggregate_fields';
  avg?: Maybe<MerchantCustomerUsersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MerchantCustomerUsersMaxFields>;
  min?: Maybe<MerchantCustomerUsersMinFields>;
  stddev?: Maybe<MerchantCustomerUsersStddevFields>;
  stddev_pop?: Maybe<MerchantCustomerUsersStddevPopFields>;
  stddev_samp?: Maybe<MerchantCustomerUsersStddevSampFields>;
  sum?: Maybe<MerchantCustomerUsersSumFields>;
  var_pop?: Maybe<MerchantCustomerUsersVarPopFields>;
  var_samp?: Maybe<MerchantCustomerUsersVarSampFields>;
  variance?: Maybe<MerchantCustomerUsersVarianceFields>;
};


/** aggregate fields of "merchant_customer_users" */
export type MerchantCustomerUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_customer_users" */
export type MerchantCustomerUsersAggregateOrderBy = {
  avg?: InputMaybe<MerchantCustomerUsersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantCustomerUsersMaxOrderBy>;
  min?: InputMaybe<MerchantCustomerUsersMinOrderBy>;
  stddev?: InputMaybe<MerchantCustomerUsersStddevOrderBy>;
  stddev_pop?: InputMaybe<MerchantCustomerUsersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MerchantCustomerUsersStddevSampOrderBy>;
  sum?: InputMaybe<MerchantCustomerUsersSumOrderBy>;
  var_pop?: InputMaybe<MerchantCustomerUsersVarPopOrderBy>;
  var_samp?: InputMaybe<MerchantCustomerUsersVarSampOrderBy>;
  variance?: InputMaybe<MerchantCustomerUsersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "merchant_customer_users" */
export type MerchantCustomerUsersArrRelInsertInput = {
  data: Array<MerchantCustomerUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantCustomerUsersOnConflict>;
};

/** aggregate avg on columns */
export type MerchantCustomerUsersAvgFields = {
  __typename?: 'merchant_customer_users_avg_fields';
  birthdayDayOfMonth?: Maybe<Scalars['Float']>;
  birthdayMonth?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersAvgOrderBy = {
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "merchant_customer_users". All fields are combined with a logical 'AND'. */
export type MerchantCustomerUsersBoolExp = {
  _and?: InputMaybe<Array<MerchantCustomerUsersBoolExp>>;
  _not?: InputMaybe<MerchantCustomerUsersBoolExp>;
  _or?: InputMaybe<Array<MerchantCustomerUsersBoolExp>>;
  aggregatedMerchantCustomerUserTags?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
  aggregatedMerchantCustomerUserTags_aggregate?: InputMaybe<AggregatedMerchantCustomerUserTagsAggregateBoolExp>;
  birthdayDate?: InputMaybe<DateComparisonExp>;
  birthdayDayOfMonth?: InputMaybe<NumericComparisonExp>;
  birthdayMonth?: InputMaybe<NumericComparisonExp>;
  companyCity?: InputMaybe<StringComparisonExp>;
  companyHouseNumber?: InputMaybe<StringComparisonExp>;
  companyName?: InputMaybe<StringComparisonExp>;
  companyStreet?: InputMaybe<StringComparisonExp>;
  companyZipCode?: InputMaybe<StringComparisonExp>;
  customerCode?: InputMaybe<StringComparisonExp>;
  customerUser?: InputMaybe<CustomerUsersBoolExp>;
  customerUserId?: InputMaybe<UuidComparisonExp>;
  emailAddress?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  fullText?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionUserId?: InputMaybe<UuidComparisonExp>;
  isMarketingConsentGranted?: InputMaybe<BooleanComparisonExp>;
  lastMarketingConsentUpdateTimestamp?: InputMaybe<TimestampComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  merchant?: InputMaybe<MerchantsBoolExp>;
  merchantCustomerUserContactGroups?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
  merchantCustomerUserContactGroups_aggregate?: InputMaybe<MerchantCustomerUserContactGroupsAggregateBoolExp>;
  merchantCustomerUserTags?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
  merchantCustomerUserTags_aggregate?: InputMaybe<MerchantCustomerUserTagsAggregateBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  primaryResponsibleMerchantUser?: InputMaybe<MerchantUsersBoolExp>;
  primaryResponsibleMerchantUserId?: InputMaybe<UuidComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  salesforceRecordAttribute?: InputMaybe<SalesforceRecordAttributesEnumComparisonExp>;
  salesforceRecordIdentifier?: InputMaybe<StringComparisonExp>;
  secondaryResponsibleMerchantUsers?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
  secondaryResponsibleMerchantUsers_aggregate?: InputMaybe<SecondaryResponsibleMerchantUsersAggregateBoolExp>;
  trade?: InputMaybe<StringComparisonExp>;
  whatsappPhoneNumber?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "merchant_customer_users" */
export enum MerchantCustomerUsersConstraint {
  /** unique or primary key constraint on columns "customer_user_id", "merchant_id" */
  MerchantCustomerUsersCustomerUserIdMerchantIdKey = 'merchant_customer_users_customer_user_id_merchant_id_key',
  /** unique or primary key constraint on columns "salesforce_record_identifier", "merchant_id" */
  MerchantCustomerUsersMerchantIdSalesforceRecordIdentKey = 'merchant_customer_users_merchant_id_salesforce_record_ident_key',
  /** unique or primary key constraint on columns "whatsapp_phone_number", "merchant_id" */
  MerchantCustomerUsersMerchantIdWhatsappPhoneNumberKey = 'merchant_customer_users_merchant_id_whatsapp_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  MerchantCustomerUsersPkey = 'merchant_customer_users_pkey'
}

/** input type for inserting data into table "merchant_customer_users" */
export type MerchantCustomerUsersInsertInput = {
  aggregatedMerchantCustomerUserTags?: InputMaybe<AggregatedMerchantCustomerUserTagsArrRelInsertInput>;
  birthdayDate?: InputMaybe<Scalars['date']>;
  companyCity?: InputMaybe<Scalars['String']>;
  companyHouseNumber?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyStreet?: InputMaybe<Scalars['String']>;
  companyZipCode?: InputMaybe<Scalars['String']>;
  customerCode?: InputMaybe<Scalars['String']>;
  customerUser?: InputMaybe<CustomerUsersObjRelInsertInput>;
  customerUserId?: InputMaybe<Scalars['uuid']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isMarketingConsentGranted?: InputMaybe<Scalars['Boolean']>;
  lastMarketingConsentUpdateTimestamp?: InputMaybe<Scalars['timestamp']>;
  lastName?: InputMaybe<Scalars['String']>;
  merchant?: InputMaybe<MerchantsObjRelInsertInput>;
  merchantCustomerUserContactGroups?: InputMaybe<MerchantCustomerUserContactGroupsArrRelInsertInput>;
  merchantCustomerUserTags?: InputMaybe<MerchantCustomerUserTagsArrRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primaryResponsibleMerchantUser?: InputMaybe<MerchantUsersObjRelInsertInput>;
  primaryResponsibleMerchantUserId?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  salesforceRecordAttribute?: InputMaybe<SalesforceRecordAttributesEnum>;
  salesforceRecordIdentifier?: InputMaybe<Scalars['String']>;
  secondaryResponsibleMerchantUsers?: InputMaybe<SecondaryResponsibleMerchantUsersArrRelInsertInput>;
  trade?: InputMaybe<Scalars['String']>;
  whatsappPhoneNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MerchantCustomerUsersMaxFields = {
  __typename?: 'merchant_customer_users_max_fields';
  birthdayDate?: Maybe<Scalars['date']>;
  birthdayDayOfMonth?: Maybe<Scalars['numeric']>;
  birthdayMonth?: Maybe<Scalars['numeric']>;
  companyCity?: Maybe<Scalars['String']>;
  companyHouseNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyStreet?: Maybe<Scalars['String']>;
  companyZipCode?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  customerUserId?: Maybe<Scalars['uuid']>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  lastMarketingConsentUpdateTimestamp?: Maybe<Scalars['timestamp']>;
  lastName?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['uuid']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryResponsibleMerchantUserId?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  salesforceRecordIdentifier?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  whatsappPhoneNumber?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersMaxOrderBy = {
  birthdayDate?: InputMaybe<OrderBy>;
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
  companyCity?: InputMaybe<OrderBy>;
  companyHouseNumber?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  companyStreet?: InputMaybe<OrderBy>;
  companyZipCode?: InputMaybe<OrderBy>;
  customerCode?: InputMaybe<OrderBy>;
  customerUserId?: InputMaybe<OrderBy>;
  emailAddress?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  fullText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  lastMarketingConsentUpdateTimestamp?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  primaryResponsibleMerchantUserId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  salesforceRecordIdentifier?: InputMaybe<OrderBy>;
  trade?: InputMaybe<OrderBy>;
  whatsappPhoneNumber?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantCustomerUsersMinFields = {
  __typename?: 'merchant_customer_users_min_fields';
  birthdayDate?: Maybe<Scalars['date']>;
  birthdayDayOfMonth?: Maybe<Scalars['numeric']>;
  birthdayMonth?: Maybe<Scalars['numeric']>;
  companyCity?: Maybe<Scalars['String']>;
  companyHouseNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyStreet?: Maybe<Scalars['String']>;
  companyZipCode?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  customerUserId?: Maybe<Scalars['uuid']>;
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionUserId?: Maybe<Scalars['uuid']>;
  lastMarketingConsentUpdateTimestamp?: Maybe<Scalars['timestamp']>;
  lastName?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['uuid']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryResponsibleMerchantUserId?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  salesforceRecordIdentifier?: Maybe<Scalars['String']>;
  trade?: Maybe<Scalars['String']>;
  whatsappPhoneNumber?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersMinOrderBy = {
  birthdayDate?: InputMaybe<OrderBy>;
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
  companyCity?: InputMaybe<OrderBy>;
  companyHouseNumber?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  companyStreet?: InputMaybe<OrderBy>;
  companyZipCode?: InputMaybe<OrderBy>;
  customerCode?: InputMaybe<OrderBy>;
  customerUserId?: InputMaybe<OrderBy>;
  emailAddress?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  fullText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  lastMarketingConsentUpdateTimestamp?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  primaryResponsibleMerchantUserId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  salesforceRecordIdentifier?: InputMaybe<OrderBy>;
  trade?: InputMaybe<OrderBy>;
  whatsappPhoneNumber?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_customer_users" */
export type MerchantCustomerUsersMutationResponse = {
  __typename?: 'merchant_customer_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantCustomerUsers>;
};

/** input type for inserting object relation for remote table "merchant_customer_users" */
export type MerchantCustomerUsersObjRelInsertInput = {
  data: MerchantCustomerUsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantCustomerUsersOnConflict>;
};

/** on_conflict condition type for table "merchant_customer_users" */
export type MerchantCustomerUsersOnConflict = {
  constraint: MerchantCustomerUsersConstraint;
  update_columns?: Array<MerchantCustomerUsersUpdateColumn>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};

/** Ordering options when selecting data from "merchant_customer_users". */
export type MerchantCustomerUsersOrderBy = {
  aggregatedMerchantCustomerUserTags_aggregate?: InputMaybe<AggregatedMerchantCustomerUserTagsAggregateOrderBy>;
  birthdayDate?: InputMaybe<OrderBy>;
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
  companyCity?: InputMaybe<OrderBy>;
  companyHouseNumber?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  companyStreet?: InputMaybe<OrderBy>;
  companyZipCode?: InputMaybe<OrderBy>;
  customerCode?: InputMaybe<OrderBy>;
  customerUser?: InputMaybe<CustomerUsersOrderBy>;
  customerUserId?: InputMaybe<OrderBy>;
  emailAddress?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  fullText?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionUserId?: InputMaybe<OrderBy>;
  isMarketingConsentGranted?: InputMaybe<OrderBy>;
  lastMarketingConsentUpdateTimestamp?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantsOrderBy>;
  merchantCustomerUserContactGroups_aggregate?: InputMaybe<MerchantCustomerUserContactGroupsAggregateOrderBy>;
  merchantCustomerUserTags_aggregate?: InputMaybe<MerchantCustomerUserTagsAggregateOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  primaryResponsibleMerchantUser?: InputMaybe<MerchantUsersOrderBy>;
  primaryResponsibleMerchantUserId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  salesforceRecordAttribute?: InputMaybe<OrderBy>;
  salesforceRecordIdentifier?: InputMaybe<OrderBy>;
  secondaryResponsibleMerchantUsers_aggregate?: InputMaybe<SecondaryResponsibleMerchantUsersAggregateOrderBy>;
  trade?: InputMaybe<OrderBy>;
  whatsappPhoneNumber?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_customer_users */
export type MerchantCustomerUsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "merchant_customer_users" */
export enum MerchantCustomerUsersSelectColumn {
  /** column name */
  BirthdayDate = 'birthdayDate',
  /** column name */
  BirthdayDayOfMonth = 'birthdayDayOfMonth',
  /** column name */
  BirthdayMonth = 'birthdayMonth',
  /** column name */
  CompanyCity = 'companyCity',
  /** column name */
  CompanyHouseNumber = 'companyHouseNumber',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CompanyStreet = 'companyStreet',
  /** column name */
  CompanyZipCode = 'companyZipCode',
  /** column name */
  CustomerCode = 'customerCode',
  /** column name */
  CustomerUserId = 'customerUserId',
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullText = 'fullText',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  IsMarketingConsentGranted = 'isMarketingConsentGranted',
  /** column name */
  LastMarketingConsentUpdateTimestamp = 'lastMarketingConsentUpdateTimestamp',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PrimaryResponsibleMerchantUserId = 'primaryResponsibleMerchantUserId',
  /** column name */
  Role = 'role',
  /** column name */
  SalesforceRecordAttribute = 'salesforceRecordAttribute',
  /** column name */
  SalesforceRecordIdentifier = 'salesforceRecordIdentifier',
  /** column name */
  Trade = 'trade',
  /** column name */
  WhatsappPhoneNumber = 'whatsappPhoneNumber'
}

/** select "merchant_customer_users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "merchant_customer_users" */
export enum MerchantCustomerUsersSelectColumnMerchantCustomerUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsMarketingConsentGranted = 'isMarketingConsentGranted'
}

/** select "merchant_customer_users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "merchant_customer_users" */
export enum MerchantCustomerUsersSelectColumnMerchantCustomerUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsMarketingConsentGranted = 'isMarketingConsentGranted'
}

/** input type for updating data in table "merchant_customer_users" */
export type MerchantCustomerUsersSetInput = {
  birthdayDate?: InputMaybe<Scalars['date']>;
  companyCity?: InputMaybe<Scalars['String']>;
  companyHouseNumber?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyStreet?: InputMaybe<Scalars['String']>;
  companyZipCode?: InputMaybe<Scalars['String']>;
  customerCode?: InputMaybe<Scalars['String']>;
  customerUserId?: InputMaybe<Scalars['uuid']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isMarketingConsentGranted?: InputMaybe<Scalars['Boolean']>;
  lastMarketingConsentUpdateTimestamp?: InputMaybe<Scalars['timestamp']>;
  lastName?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primaryResponsibleMerchantUserId?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  salesforceRecordAttribute?: InputMaybe<SalesforceRecordAttributesEnum>;
  salesforceRecordIdentifier?: InputMaybe<Scalars['String']>;
  trade?: InputMaybe<Scalars['String']>;
  whatsappPhoneNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type MerchantCustomerUsersStddevFields = {
  __typename?: 'merchant_customer_users_stddev_fields';
  birthdayDayOfMonth?: Maybe<Scalars['Float']>;
  birthdayMonth?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersStddevOrderBy = {
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MerchantCustomerUsersStddevPopFields = {
  __typename?: 'merchant_customer_users_stddev_pop_fields';
  birthdayDayOfMonth?: Maybe<Scalars['Float']>;
  birthdayMonth?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersStddevPopOrderBy = {
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MerchantCustomerUsersStddevSampFields = {
  __typename?: 'merchant_customer_users_stddev_samp_fields';
  birthdayDayOfMonth?: Maybe<Scalars['Float']>;
  birthdayMonth?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersStddevSampOrderBy = {
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "merchant_customer_users" */
export type MerchantCustomerUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantCustomerUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantCustomerUsersStreamCursorValueInput = {
  birthdayDate?: InputMaybe<Scalars['date']>;
  birthdayDayOfMonth?: InputMaybe<Scalars['numeric']>;
  birthdayMonth?: InputMaybe<Scalars['numeric']>;
  companyCity?: InputMaybe<Scalars['String']>;
  companyHouseNumber?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  companyStreet?: InputMaybe<Scalars['String']>;
  companyZipCode?: InputMaybe<Scalars['String']>;
  customerCode?: InputMaybe<Scalars['String']>;
  customerUserId?: InputMaybe<Scalars['uuid']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionUserId?: InputMaybe<Scalars['uuid']>;
  isMarketingConsentGranted?: InputMaybe<Scalars['Boolean']>;
  lastMarketingConsentUpdateTimestamp?: InputMaybe<Scalars['timestamp']>;
  lastName?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primaryResponsibleMerchantUserId?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  salesforceRecordAttribute?: InputMaybe<SalesforceRecordAttributesEnum>;
  salesforceRecordIdentifier?: InputMaybe<Scalars['String']>;
  trade?: InputMaybe<Scalars['String']>;
  whatsappPhoneNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type MerchantCustomerUsersSumFields = {
  __typename?: 'merchant_customer_users_sum_fields';
  birthdayDayOfMonth?: Maybe<Scalars['numeric']>;
  birthdayMonth?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersSumOrderBy = {
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
};

/** update columns of table "merchant_customer_users" */
export enum MerchantCustomerUsersUpdateColumn {
  /** column name */
  BirthdayDate = 'birthdayDate',
  /** column name */
  CompanyCity = 'companyCity',
  /** column name */
  CompanyHouseNumber = 'companyHouseNumber',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  CompanyStreet = 'companyStreet',
  /** column name */
  CompanyZipCode = 'companyZipCode',
  /** column name */
  CustomerCode = 'customerCode',
  /** column name */
  CustomerUserId = 'customerUserId',
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionUserId = 'insertionUserId',
  /** column name */
  IsMarketingConsentGranted = 'isMarketingConsentGranted',
  /** column name */
  LastMarketingConsentUpdateTimestamp = 'lastMarketingConsentUpdateTimestamp',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PrimaryResponsibleMerchantUserId = 'primaryResponsibleMerchantUserId',
  /** column name */
  Role = 'role',
  /** column name */
  SalesforceRecordAttribute = 'salesforceRecordAttribute',
  /** column name */
  SalesforceRecordIdentifier = 'salesforceRecordIdentifier',
  /** column name */
  Trade = 'trade',
  /** column name */
  WhatsappPhoneNumber = 'whatsappPhoneNumber'
}

export type MerchantCustomerUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantCustomerUsersSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantCustomerUsersBoolExp;
};

/** aggregate var_pop on columns */
export type MerchantCustomerUsersVarPopFields = {
  __typename?: 'merchant_customer_users_var_pop_fields';
  birthdayDayOfMonth?: Maybe<Scalars['Float']>;
  birthdayMonth?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersVarPopOrderBy = {
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MerchantCustomerUsersVarSampFields = {
  __typename?: 'merchant_customer_users_var_samp_fields';
  birthdayDayOfMonth?: Maybe<Scalars['Float']>;
  birthdayMonth?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersVarSampOrderBy = {
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MerchantCustomerUsersVarianceFields = {
  __typename?: 'merchant_customer_users_variance_fields';
  birthdayDayOfMonth?: Maybe<Scalars['Float']>;
  birthdayMonth?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "merchant_customer_users" */
export type MerchantCustomerUsersVarianceOrderBy = {
  birthdayDayOfMonth?: InputMaybe<OrderBy>;
  birthdayMonth?: InputMaybe<OrderBy>;
};

/** columns and relationships of "merchant_mobile_app_installations" */
export type MerchantMobileAppInstallations = {
  __typename?: 'merchant_mobile_app_installations';
  deviceIdentifier: Scalars['String'];
  id: Scalars['uuid'];
  lastPushNotificationSendAttemptErrorCode?: Maybe<Scalars['String']>;
  lastPushNotificationSendAttemptTimestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  merchantUser: MerchantUsers;
  merchantUserId: Scalars['uuid'];
  platform: MobileAppPlatformsEnum;
};

/** aggregated selection of "merchant_mobile_app_installations" */
export type MerchantMobileAppInstallationsAggregate = {
  __typename?: 'merchant_mobile_app_installations_aggregate';
  aggregate?: Maybe<MerchantMobileAppInstallationsAggregateFields>;
  nodes: Array<MerchantMobileAppInstallations>;
};

/** aggregate fields of "merchant_mobile_app_installations" */
export type MerchantMobileAppInstallationsAggregateFields = {
  __typename?: 'merchant_mobile_app_installations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MerchantMobileAppInstallationsMaxFields>;
  min?: Maybe<MerchantMobileAppInstallationsMinFields>;
};


/** aggregate fields of "merchant_mobile_app_installations" */
export type MerchantMobileAppInstallationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantMobileAppInstallationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "merchant_mobile_app_installations". All fields are combined with a logical 'AND'. */
export type MerchantMobileAppInstallationsBoolExp = {
  _and?: InputMaybe<Array<MerchantMobileAppInstallationsBoolExp>>;
  _not?: InputMaybe<MerchantMobileAppInstallationsBoolExp>;
  _or?: InputMaybe<Array<MerchantMobileAppInstallationsBoolExp>>;
  deviceIdentifier?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lastPushNotificationSendAttemptErrorCode?: InputMaybe<StringComparisonExp>;
  lastPushNotificationSendAttemptTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  merchantUser?: InputMaybe<MerchantUsersBoolExp>;
  merchantUserId?: InputMaybe<UuidComparisonExp>;
  platform?: InputMaybe<MobileAppPlatformsEnumComparisonExp>;
};

/** unique or primary key constraints on table "merchant_mobile_app_installations" */
export enum MerchantMobileAppInstallationsConstraint {
  /** unique or primary key constraint on columns "device_identifier" */
  MerchantMobileAppInstallationsDeviceIdentifierKey = 'merchant_mobile_app_installations_device_identifier_key',
  /** unique or primary key constraint on columns "id" */
  MerchantMobileAppInstallationsPkey = 'merchant_mobile_app_installations_pkey'
}

/** input type for inserting data into table "merchant_mobile_app_installations" */
export type MerchantMobileAppInstallationsInsertInput = {
  deviceIdentifier?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastPushNotificationSendAttemptErrorCode?: InputMaybe<Scalars['String']>;
  lastPushNotificationSendAttemptTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantUser?: InputMaybe<MerchantUsersObjRelInsertInput>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
  platform?: InputMaybe<MobileAppPlatformsEnum>;
};

/** aggregate max on columns */
export type MerchantMobileAppInstallationsMaxFields = {
  __typename?: 'merchant_mobile_app_installations_max_fields';
  deviceIdentifier?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastPushNotificationSendAttemptErrorCode?: Maybe<Scalars['String']>;
  lastPushNotificationSendAttemptTimestamp?: Maybe<Scalars['timestamptz']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MerchantMobileAppInstallationsMinFields = {
  __typename?: 'merchant_mobile_app_installations_min_fields';
  deviceIdentifier?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastPushNotificationSendAttemptErrorCode?: Maybe<Scalars['String']>;
  lastPushNotificationSendAttemptTimestamp?: Maybe<Scalars['timestamptz']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "merchant_mobile_app_installations" */
export type MerchantMobileAppInstallationsMutationResponse = {
  __typename?: 'merchant_mobile_app_installations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantMobileAppInstallations>;
};

/** on_conflict condition type for table "merchant_mobile_app_installations" */
export type MerchantMobileAppInstallationsOnConflict = {
  constraint: MerchantMobileAppInstallationsConstraint;
  update_columns?: Array<MerchantMobileAppInstallationsUpdateColumn>;
  where?: InputMaybe<MerchantMobileAppInstallationsBoolExp>;
};

/** Ordering options when selecting data from "merchant_mobile_app_installations". */
export type MerchantMobileAppInstallationsOrderBy = {
  deviceIdentifier?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastPushNotificationSendAttemptErrorCode?: InputMaybe<OrderBy>;
  lastPushNotificationSendAttemptTimestamp?: InputMaybe<OrderBy>;
  merchantUser?: InputMaybe<MerchantUsersOrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
  platform?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_mobile_app_installations */
export type MerchantMobileAppInstallationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "merchant_mobile_app_installations" */
export enum MerchantMobileAppInstallationsSelectColumn {
  /** column name */
  DeviceIdentifier = 'deviceIdentifier',
  /** column name */
  Id = 'id',
  /** column name */
  LastPushNotificationSendAttemptErrorCode = 'lastPushNotificationSendAttemptErrorCode',
  /** column name */
  LastPushNotificationSendAttemptTimestamp = 'lastPushNotificationSendAttemptTimestamp',
  /** column name */
  MerchantUserId = 'merchantUserId',
  /** column name */
  Platform = 'platform'
}

/** input type for updating data in table "merchant_mobile_app_installations" */
export type MerchantMobileAppInstallationsSetInput = {
  deviceIdentifier?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastPushNotificationSendAttemptErrorCode?: InputMaybe<Scalars['String']>;
  lastPushNotificationSendAttemptTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
  platform?: InputMaybe<MobileAppPlatformsEnum>;
};

/** Streaming cursor of the table "merchant_mobile_app_installations" */
export type MerchantMobileAppInstallationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantMobileAppInstallationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantMobileAppInstallationsStreamCursorValueInput = {
  deviceIdentifier?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastPushNotificationSendAttemptErrorCode?: InputMaybe<Scalars['String']>;
  lastPushNotificationSendAttemptTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
  platform?: InputMaybe<MobileAppPlatformsEnum>;
};

/** update columns of table "merchant_mobile_app_installations" */
export enum MerchantMobileAppInstallationsUpdateColumn {
  /** column name */
  DeviceIdentifier = 'deviceIdentifier',
  /** column name */
  Id = 'id',
  /** column name */
  LastPushNotificationSendAttemptErrorCode = 'lastPushNotificationSendAttemptErrorCode',
  /** column name */
  LastPushNotificationSendAttemptTimestamp = 'lastPushNotificationSendAttemptTimestamp',
  /** column name */
  MerchantUserId = 'merchantUserId',
  /** column name */
  Platform = 'platform'
}

export type MerchantMobileAppInstallationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantMobileAppInstallationsSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantMobileAppInstallationsBoolExp;
};

/** columns and relationships of "merchant_salesforce_sync_configurations" */
export type MerchantSalesforceSyncConfigurations = {
  __typename?: 'merchant_salesforce_sync_configurations';
  isActive: Scalars['Boolean'];
  merchantId: Scalars['uuid'];
  salesforceOrganizationHost: Scalars['String'];
};

/** aggregated selection of "merchant_salesforce_sync_configurations" */
export type MerchantSalesforceSyncConfigurationsAggregate = {
  __typename?: 'merchant_salesforce_sync_configurations_aggregate';
  aggregate?: Maybe<MerchantSalesforceSyncConfigurationsAggregateFields>;
  nodes: Array<MerchantSalesforceSyncConfigurations>;
};

/** aggregate fields of "merchant_salesforce_sync_configurations" */
export type MerchantSalesforceSyncConfigurationsAggregateFields = {
  __typename?: 'merchant_salesforce_sync_configurations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MerchantSalesforceSyncConfigurationsMaxFields>;
  min?: Maybe<MerchantSalesforceSyncConfigurationsMinFields>;
};


/** aggregate fields of "merchant_salesforce_sync_configurations" */
export type MerchantSalesforceSyncConfigurationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "merchant_salesforce_sync_configurations". All fields are combined with a logical 'AND'. */
export type MerchantSalesforceSyncConfigurationsBoolExp = {
  _and?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsBoolExp>>;
  _not?: InputMaybe<MerchantSalesforceSyncConfigurationsBoolExp>;
  _or?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsBoolExp>>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  salesforceOrganizationHost?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "merchant_salesforce_sync_configurations" */
export enum MerchantSalesforceSyncConfigurationsConstraint {
  /** unique or primary key constraint on columns "merchant_id" */
  MerchantSalesforceSyncConfigurationsPkey = 'merchant_salesforce_sync_configurations_pkey'
}

/** input type for inserting data into table "merchant_salesforce_sync_configurations" */
export type MerchantSalesforceSyncConfigurationsInsertInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  salesforceOrganizationHost?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MerchantSalesforceSyncConfigurationsMaxFields = {
  __typename?: 'merchant_salesforce_sync_configurations_max_fields';
  merchantId?: Maybe<Scalars['uuid']>;
  salesforceOrganizationHost?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MerchantSalesforceSyncConfigurationsMinFields = {
  __typename?: 'merchant_salesforce_sync_configurations_min_fields';
  merchantId?: Maybe<Scalars['uuid']>;
  salesforceOrganizationHost?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "merchant_salesforce_sync_configurations" */
export type MerchantSalesforceSyncConfigurationsMutationResponse = {
  __typename?: 'merchant_salesforce_sync_configurations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantSalesforceSyncConfigurations>;
};

/** on_conflict condition type for table "merchant_salesforce_sync_configurations" */
export type MerchantSalesforceSyncConfigurationsOnConflict = {
  constraint: MerchantSalesforceSyncConfigurationsConstraint;
  update_columns?: Array<MerchantSalesforceSyncConfigurationsUpdateColumn>;
  where?: InputMaybe<MerchantSalesforceSyncConfigurationsBoolExp>;
};

/** Ordering options when selecting data from "merchant_salesforce_sync_configurations". */
export type MerchantSalesforceSyncConfigurationsOrderBy = {
  isActive?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  salesforceOrganizationHost?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_salesforce_sync_configurations */
export type MerchantSalesforceSyncConfigurationsPkColumnsInput = {
  merchantId: Scalars['uuid'];
};

/** select columns of table "merchant_salesforce_sync_configurations" */
export enum MerchantSalesforceSyncConfigurationsSelectColumn {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  SalesforceOrganizationHost = 'salesforceOrganizationHost'
}

/** input type for updating data in table "merchant_salesforce_sync_configurations" */
export type MerchantSalesforceSyncConfigurationsSetInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  salesforceOrganizationHost?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "merchant_salesforce_sync_configurations" */
export type MerchantSalesforceSyncConfigurationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantSalesforceSyncConfigurationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantSalesforceSyncConfigurationsStreamCursorValueInput = {
  isActive?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  salesforceOrganizationHost?: InputMaybe<Scalars['String']>;
};

/** update columns of table "merchant_salesforce_sync_configurations" */
export enum MerchantSalesforceSyncConfigurationsUpdateColumn {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  SalesforceOrganizationHost = 'salesforceOrganizationHost'
}

export type MerchantSalesforceSyncConfigurationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantSalesforceSyncConfigurationsSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantSalesforceSyncConfigurationsBoolExp;
};

/** columns and relationships of "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsers = {
  __typename?: 'merchant_store_merchant_users';
  /** An object relationship */
  merchantStore: MerchantStores;
  merchantStoreId: Scalars['uuid'];
  /** An object relationship */
  merchantUser: MerchantUsers;
  merchantUserId: Scalars['uuid'];
};

/** aggregated selection of "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersAggregate = {
  __typename?: 'merchant_store_merchant_users_aggregate';
  aggregate?: Maybe<MerchantStoreMerchantUsersAggregateFields>;
  nodes: Array<MerchantStoreMerchantUsers>;
};

export type MerchantStoreMerchantUsersAggregateBoolExp = {
  count?: InputMaybe<MerchantStoreMerchantUsersAggregateBoolExpCount>;
};

export type MerchantStoreMerchantUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersAggregateFields = {
  __typename?: 'merchant_store_merchant_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MerchantStoreMerchantUsersMaxFields>;
  min?: Maybe<MerchantStoreMerchantUsersMinFields>;
};


/** aggregate fields of "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantStoreMerchantUsersMaxOrderBy>;
  min?: InputMaybe<MerchantStoreMerchantUsersMinOrderBy>;
};

/** input type for inserting array relation for remote table "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersArrRelInsertInput = {
  data: Array<MerchantStoreMerchantUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantStoreMerchantUsersOnConflict>;
};

/** Boolean expression to filter rows from the table "merchant_store_merchant_users". All fields are combined with a logical 'AND'. */
export type MerchantStoreMerchantUsersBoolExp = {
  _and?: InputMaybe<Array<MerchantStoreMerchantUsersBoolExp>>;
  _not?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
  _or?: InputMaybe<Array<MerchantStoreMerchantUsersBoolExp>>;
  merchantStore?: InputMaybe<MerchantStoresBoolExp>;
  merchantStoreId?: InputMaybe<UuidComparisonExp>;
  merchantUser?: InputMaybe<MerchantUsersBoolExp>;
  merchantUserId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "merchant_store_merchant_users" */
export enum MerchantStoreMerchantUsersConstraint {
  /** unique or primary key constraint on columns "merchant_user_id", "merchant_store_id" */
  MerchantStoreMerchantUsersPkey = 'merchant_store_merchant_users_pkey'
}

/** input type for inserting data into table "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersInsertInput = {
  merchantStore?: InputMaybe<MerchantStoresObjRelInsertInput>;
  merchantStoreId?: InputMaybe<Scalars['uuid']>;
  merchantUser?: InputMaybe<MerchantUsersObjRelInsertInput>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MerchantStoreMerchantUsersMaxFields = {
  __typename?: 'merchant_store_merchant_users_max_fields';
  merchantStoreId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersMaxOrderBy = {
  merchantStoreId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantStoreMerchantUsersMinFields = {
  __typename?: 'merchant_store_merchant_users_min_fields';
  merchantStoreId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersMinOrderBy = {
  merchantStoreId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersMutationResponse = {
  __typename?: 'merchant_store_merchant_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantStoreMerchantUsers>;
};

/** on_conflict condition type for table "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersOnConflict = {
  constraint: MerchantStoreMerchantUsersConstraint;
  update_columns?: Array<MerchantStoreMerchantUsersUpdateColumn>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};

/** Ordering options when selecting data from "merchant_store_merchant_users". */
export type MerchantStoreMerchantUsersOrderBy = {
  merchantStore?: InputMaybe<MerchantStoresOrderBy>;
  merchantStoreId?: InputMaybe<OrderBy>;
  merchantUser?: InputMaybe<MerchantUsersOrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_store_merchant_users */
export type MerchantStoreMerchantUsersPkColumnsInput = {
  merchantStoreId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};

/** select columns of table "merchant_store_merchant_users" */
export enum MerchantStoreMerchantUsersSelectColumn {
  /** column name */
  MerchantStoreId = 'merchantStoreId',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

/** input type for updating data in table "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersSetInput = {
  merchantStoreId?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "merchant_store_merchant_users" */
export type MerchantStoreMerchantUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantStoreMerchantUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantStoreMerchantUsersStreamCursorValueInput = {
  merchantStoreId?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "merchant_store_merchant_users" */
export enum MerchantStoreMerchantUsersUpdateColumn {
  /** column name */
  MerchantStoreId = 'merchantStoreId',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

export type MerchantStoreMerchantUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantStoreMerchantUsersSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantStoreMerchantUsersBoolExp;
};

/** columns and relationships of "merchant_stores" */
export type MerchantStores = {
  __typename?: 'merchant_stores';
  id: Scalars['uuid'];
  /** An object relationship */
  merchant: Merchants;
  merchantId: Scalars['uuid'];
  /** An array relationship */
  merchantStoreMerchantUsers: Array<MerchantStoreMerchantUsers>;
  /** An aggregate relationship */
  merchantStoreMerchantUsers_aggregate: MerchantStoreMerchantUsersAggregate;
  name: Scalars['String'];
};


/** columns and relationships of "merchant_stores" */
export type MerchantStoresMerchantStoreMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoreMerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};


/** columns and relationships of "merchant_stores" */
export type MerchantStoresMerchantStoreMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoreMerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};

/** aggregated selection of "merchant_stores" */
export type MerchantStoresAggregate = {
  __typename?: 'merchant_stores_aggregate';
  aggregate?: Maybe<MerchantStoresAggregateFields>;
  nodes: Array<MerchantStores>;
};

export type MerchantStoresAggregateBoolExp = {
  count?: InputMaybe<MerchantStoresAggregateBoolExpCount>;
};

export type MerchantStoresAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantStoresSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantStoresBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchant_stores" */
export type MerchantStoresAggregateFields = {
  __typename?: 'merchant_stores_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MerchantStoresMaxFields>;
  min?: Maybe<MerchantStoresMinFields>;
};


/** aggregate fields of "merchant_stores" */
export type MerchantStoresAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantStoresSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_stores" */
export type MerchantStoresAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantStoresMaxOrderBy>;
  min?: InputMaybe<MerchantStoresMinOrderBy>;
};

/** input type for inserting array relation for remote table "merchant_stores" */
export type MerchantStoresArrRelInsertInput = {
  data: Array<MerchantStoresInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantStoresOnConflict>;
};

/** Boolean expression to filter rows from the table "merchant_stores". All fields are combined with a logical 'AND'. */
export type MerchantStoresBoolExp = {
  _and?: InputMaybe<Array<MerchantStoresBoolExp>>;
  _not?: InputMaybe<MerchantStoresBoolExp>;
  _or?: InputMaybe<Array<MerchantStoresBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  merchant?: InputMaybe<MerchantsBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  merchantStoreMerchantUsers?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
  merchantStoreMerchantUsers_aggregate?: InputMaybe<MerchantStoreMerchantUsersAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "merchant_stores" */
export enum MerchantStoresConstraint {
  /** unique or primary key constraint on columns "id" */
  MerchantStoresPkey = 'merchant_stores_pkey'
}

/** input type for inserting data into table "merchant_stores" */
export type MerchantStoresInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchant?: InputMaybe<MerchantsObjRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  merchantStoreMerchantUsers?: InputMaybe<MerchantStoreMerchantUsersArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MerchantStoresMaxFields = {
  __typename?: 'merchant_stores_max_fields';
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "merchant_stores" */
export type MerchantStoresMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantStoresMinFields = {
  __typename?: 'merchant_stores_min_fields';
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "merchant_stores" */
export type MerchantStoresMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_stores" */
export type MerchantStoresMutationResponse = {
  __typename?: 'merchant_stores_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantStores>;
};

/** input type for inserting object relation for remote table "merchant_stores" */
export type MerchantStoresObjRelInsertInput = {
  data: MerchantStoresInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantStoresOnConflict>;
};

/** on_conflict condition type for table "merchant_stores" */
export type MerchantStoresOnConflict = {
  constraint: MerchantStoresConstraint;
  update_columns?: Array<MerchantStoresUpdateColumn>;
  where?: InputMaybe<MerchantStoresBoolExp>;
};

/** Ordering options when selecting data from "merchant_stores". */
export type MerchantStoresOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantsOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  merchantStoreMerchantUsers_aggregate?: InputMaybe<MerchantStoreMerchantUsersAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_stores */
export type MerchantStoresPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "merchant_stores" */
export enum MerchantStoresSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "merchant_stores" */
export type MerchantStoresSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "merchant_stores" */
export type MerchantStoresStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantStoresStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantStoresStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "merchant_stores" */
export enum MerchantStoresUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name'
}

export type MerchantStoresUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantStoresSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantStoresBoolExp;
};

/** columns and relationships of "merchant_user_contact_groups" */
export type MerchantUserContactGroups = {
  __typename?: 'merchant_user_contact_groups';
  /** An object relationship */
  contactGroup: ContactGroups;
  contactGroupId: Scalars['uuid'];
  /** An object relationship */
  merchantUser: MerchantUsers;
  merchantUserId: Scalars['uuid'];
};

/** aggregated selection of "merchant_user_contact_groups" */
export type MerchantUserContactGroupsAggregate = {
  __typename?: 'merchant_user_contact_groups_aggregate';
  aggregate?: Maybe<MerchantUserContactGroupsAggregateFields>;
  nodes: Array<MerchantUserContactGroups>;
};

export type MerchantUserContactGroupsAggregateBoolExp = {
  count?: InputMaybe<MerchantUserContactGroupsAggregateBoolExpCount>;
};

export type MerchantUserContactGroupsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantUserContactGroupsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchant_user_contact_groups" */
export type MerchantUserContactGroupsAggregateFields = {
  __typename?: 'merchant_user_contact_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MerchantUserContactGroupsMaxFields>;
  min?: Maybe<MerchantUserContactGroupsMinFields>;
};


/** aggregate fields of "merchant_user_contact_groups" */
export type MerchantUserContactGroupsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_user_contact_groups" */
export type MerchantUserContactGroupsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantUserContactGroupsMaxOrderBy>;
  min?: InputMaybe<MerchantUserContactGroupsMinOrderBy>;
};

/** input type for inserting array relation for remote table "merchant_user_contact_groups" */
export type MerchantUserContactGroupsArrRelInsertInput = {
  data: Array<MerchantUserContactGroupsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantUserContactGroupsOnConflict>;
};

/** Boolean expression to filter rows from the table "merchant_user_contact_groups". All fields are combined with a logical 'AND'. */
export type MerchantUserContactGroupsBoolExp = {
  _and?: InputMaybe<Array<MerchantUserContactGroupsBoolExp>>;
  _not?: InputMaybe<MerchantUserContactGroupsBoolExp>;
  _or?: InputMaybe<Array<MerchantUserContactGroupsBoolExp>>;
  contactGroup?: InputMaybe<ContactGroupsBoolExp>;
  contactGroupId?: InputMaybe<UuidComparisonExp>;
  merchantUser?: InputMaybe<MerchantUsersBoolExp>;
  merchantUserId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "merchant_user_contact_groups" */
export enum MerchantUserContactGroupsConstraint {
  /** unique or primary key constraint on columns "merchant_user_id", "contact_group_id" */
  MerchantUserContactGroupsPkey = 'merchant_user_contact_groups_pkey'
}

/** input type for inserting data into table "merchant_user_contact_groups" */
export type MerchantUserContactGroupsInsertInput = {
  contactGroup?: InputMaybe<ContactGroupsObjRelInsertInput>;
  contactGroupId?: InputMaybe<Scalars['uuid']>;
  merchantUser?: InputMaybe<MerchantUsersObjRelInsertInput>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MerchantUserContactGroupsMaxFields = {
  __typename?: 'merchant_user_contact_groups_max_fields';
  contactGroupId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "merchant_user_contact_groups" */
export type MerchantUserContactGroupsMaxOrderBy = {
  contactGroupId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantUserContactGroupsMinFields = {
  __typename?: 'merchant_user_contact_groups_min_fields';
  contactGroupId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "merchant_user_contact_groups" */
export type MerchantUserContactGroupsMinOrderBy = {
  contactGroupId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_user_contact_groups" */
export type MerchantUserContactGroupsMutationResponse = {
  __typename?: 'merchant_user_contact_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantUserContactGroups>;
};

/** on_conflict condition type for table "merchant_user_contact_groups" */
export type MerchantUserContactGroupsOnConflict = {
  constraint: MerchantUserContactGroupsConstraint;
  update_columns?: Array<MerchantUserContactGroupsUpdateColumn>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};

/** Ordering options when selecting data from "merchant_user_contact_groups". */
export type MerchantUserContactGroupsOrderBy = {
  contactGroup?: InputMaybe<ContactGroupsOrderBy>;
  contactGroupId?: InputMaybe<OrderBy>;
  merchantUser?: InputMaybe<MerchantUsersOrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_user_contact_groups */
export type MerchantUserContactGroupsPkColumnsInput = {
  contactGroupId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};

/** select columns of table "merchant_user_contact_groups" */
export enum MerchantUserContactGroupsSelectColumn {
  /** column name */
  ContactGroupId = 'contactGroupId',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

/** input type for updating data in table "merchant_user_contact_groups" */
export type MerchantUserContactGroupsSetInput = {
  contactGroupId?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "merchant_user_contact_groups" */
export type MerchantUserContactGroupsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantUserContactGroupsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantUserContactGroupsStreamCursorValueInput = {
  contactGroupId?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "merchant_user_contact_groups" */
export enum MerchantUserContactGroupsUpdateColumn {
  /** column name */
  ContactGroupId = 'contactGroupId',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

export type MerchantUserContactGroupsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantUserContactGroupsSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantUserContactGroupsBoolExp;
};

/** columns and relationships of "merchant_user_password_resets" */
export type MerchantUserPasswordResets = {
  __typename?: 'merchant_user_password_resets';
  expirationTimestamp: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  merchantUser: MerchantUsers;
  merchantUserId: Scalars['uuid'];
};

/** aggregated selection of "merchant_user_password_resets" */
export type MerchantUserPasswordResetsAggregate = {
  __typename?: 'merchant_user_password_resets_aggregate';
  aggregate?: Maybe<MerchantUserPasswordResetsAggregateFields>;
  nodes: Array<MerchantUserPasswordResets>;
};

/** aggregate fields of "merchant_user_password_resets" */
export type MerchantUserPasswordResetsAggregateFields = {
  __typename?: 'merchant_user_password_resets_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MerchantUserPasswordResetsMaxFields>;
  min?: Maybe<MerchantUserPasswordResetsMinFields>;
};


/** aggregate fields of "merchant_user_password_resets" */
export type MerchantUserPasswordResetsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantUserPasswordResetsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "merchant_user_password_resets". All fields are combined with a logical 'AND'. */
export type MerchantUserPasswordResetsBoolExp = {
  _and?: InputMaybe<Array<MerchantUserPasswordResetsBoolExp>>;
  _not?: InputMaybe<MerchantUserPasswordResetsBoolExp>;
  _or?: InputMaybe<Array<MerchantUserPasswordResetsBoolExp>>;
  expirationTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  merchantUser?: InputMaybe<MerchantUsersBoolExp>;
  merchantUserId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "merchant_user_password_resets" */
export enum MerchantUserPasswordResetsConstraint {
  /** unique or primary key constraint on columns "id" */
  MerchantUserPasswordResetsPkey = 'merchant_user_password_resets_pkey'
}

/** input type for inserting data into table "merchant_user_password_resets" */
export type MerchantUserPasswordResetsInsertInput = {
  expirationTimestamp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  merchantUser?: InputMaybe<MerchantUsersObjRelInsertInput>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type MerchantUserPasswordResetsMaxFields = {
  __typename?: 'merchant_user_password_resets_max_fields';
  expirationTimestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type MerchantUserPasswordResetsMinFields = {
  __typename?: 'merchant_user_password_resets_min_fields';
  expirationTimestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "merchant_user_password_resets" */
export type MerchantUserPasswordResetsMutationResponse = {
  __typename?: 'merchant_user_password_resets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantUserPasswordResets>;
};

/** on_conflict condition type for table "merchant_user_password_resets" */
export type MerchantUserPasswordResetsOnConflict = {
  constraint: MerchantUserPasswordResetsConstraint;
  update_columns?: Array<MerchantUserPasswordResetsUpdateColumn>;
  where?: InputMaybe<MerchantUserPasswordResetsBoolExp>;
};

/** Ordering options when selecting data from "merchant_user_password_resets". */
export type MerchantUserPasswordResetsOrderBy = {
  expirationTimestamp?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantUser?: InputMaybe<MerchantUsersOrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_user_password_resets */
export type MerchantUserPasswordResetsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "merchant_user_password_resets" */
export enum MerchantUserPasswordResetsSelectColumn {
  /** column name */
  ExpirationTimestamp = 'expirationTimestamp',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

/** input type for updating data in table "merchant_user_password_resets" */
export type MerchantUserPasswordResetsSetInput = {
  expirationTimestamp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "merchant_user_password_resets" */
export type MerchantUserPasswordResetsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantUserPasswordResetsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantUserPasswordResetsStreamCursorValueInput = {
  expirationTimestamp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "merchant_user_password_resets" */
export enum MerchantUserPasswordResetsUpdateColumn {
  /** column name */
  ExpirationTimestamp = 'expirationTimestamp',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

export type MerchantUserPasswordResetsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantUserPasswordResetsSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantUserPasswordResetsBoolExp;
};

/** columns and relationships of "merchant_users" */
export type MerchantUsers = {
  __typename?: 'merchant_users';
  /** An array relationship */
  channelMerchantUsers: Array<ChannelMerchantUsers>;
  /** An aggregate relationship */
  channelMerchantUsers_aggregate: ChannelMerchantUsersAggregate;
  emailAddress: Scalars['String'];
  emailNotificationPreference: EmailNotificationPreferencesEnum;
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  insertionTimestamp: Scalars['timestamptz'];
  isAdmin: Scalars['Boolean'];
  isAutoResponseEnabled: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  isSuperAdmin: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastSalesforceSyncTimestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  merchant: Merchants;
  /** An array relationship */
  merchantCustomerUsers: Array<MerchantCustomerUsers>;
  /** An aggregate relationship */
  merchantCustomerUsers_aggregate: MerchantCustomerUsersAggregate;
  merchantId: Scalars['uuid'];
  /** An array relationship */
  merchantStoreMerchantUsers: Array<MerchantStoreMerchantUsers>;
  /** An aggregate relationship */
  merchantStoreMerchantUsers_aggregate: MerchantStoreMerchantUsersAggregate;
  /** An array relationship */
  merchantUserContactGroups: Array<MerchantUserContactGroups>;
  /** An aggregate relationship */
  merchantUserContactGroups_aggregate: MerchantUserContactGroupsAggregate;
  salesforceInstanceUrl?: Maybe<Scalars['String']>;
  salesforceRefreshToken?: Maybe<Scalars['String']>;
  salesforceRequiredCustomFieldToDefaultValue: Scalars['jsonb'];
  salesforceUserIdentifier?: Maybe<Scalars['String']>;
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** An object relationship */
  user?: Maybe<Users>;
  userColor: Scalars['String'];
};


/** columns and relationships of "merchant_users" */
export type MerchantUsersChannelMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelMerchantUsersOrderBy>>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};


/** columns and relationships of "merchant_users" */
export type MerchantUsersChannelMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelMerchantUsersOrderBy>>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};


/** columns and relationships of "merchant_users" */
export type MerchantUsersMerchantCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


/** columns and relationships of "merchant_users" */
export type MerchantUsersMerchantCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


/** columns and relationships of "merchant_users" */
export type MerchantUsersMerchantStoreMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoreMerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};


/** columns and relationships of "merchant_users" */
export type MerchantUsersMerchantStoreMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoreMerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};


/** columns and relationships of "merchant_users" */
export type MerchantUsersMerchantUserContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};


/** columns and relationships of "merchant_users" */
export type MerchantUsersMerchantUserContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};


/** columns and relationships of "merchant_users" */
export type MerchantUsersSalesforceRequiredCustomFieldToDefaultValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "merchant_users" */
export type MerchantUsersAggregate = {
  __typename?: 'merchant_users_aggregate';
  aggregate?: Maybe<MerchantUsersAggregateFields>;
  nodes: Array<MerchantUsers>;
};

export type MerchantUsersAggregateBoolExp = {
  bool_and?: InputMaybe<MerchantUsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<MerchantUsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<MerchantUsersAggregateBoolExpCount>;
};

export type MerchantUsersAggregateBoolExpBoolAnd = {
  arguments: MerchantUsersSelectColumnMerchantUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantUsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantUsersAggregateBoolExpBoolOr = {
  arguments: MerchantUsersSelectColumnMerchantUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantUsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchant_users" */
export type MerchantUsersAggregateFields = {
  __typename?: 'merchant_users_aggregate_fields';
  avg?: Maybe<MerchantUsersAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MerchantUsersMaxFields>;
  min?: Maybe<MerchantUsersMinFields>;
  stddev?: Maybe<MerchantUsersStddevFields>;
  stddev_pop?: Maybe<MerchantUsersStddevPopFields>;
  stddev_samp?: Maybe<MerchantUsersStddevSampFields>;
  sum?: Maybe<MerchantUsersSumFields>;
  var_pop?: Maybe<MerchantUsersVarPopFields>;
  var_samp?: Maybe<MerchantUsersVarSampFields>;
  variance?: Maybe<MerchantUsersVarianceFields>;
};


/** aggregate fields of "merchant_users" */
export type MerchantUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchant_users" */
export type MerchantUsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantUsersMaxOrderBy>;
  min?: InputMaybe<MerchantUsersMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type MerchantUsersAppendInput = {
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "merchant_users" */
export type MerchantUsersArrRelInsertInput = {
  data: Array<MerchantUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantUsersOnConflict>;
};

/** aggregate avg on columns */
export type MerchantUsersAvgFields = {
  __typename?: 'merchant_users_avg_fields';
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "merchant_users". All fields are combined with a logical 'AND'. */
export type MerchantUsersBoolExp = {
  _and?: InputMaybe<Array<MerchantUsersBoolExp>>;
  _not?: InputMaybe<MerchantUsersBoolExp>;
  _or?: InputMaybe<Array<MerchantUsersBoolExp>>;
  channelMerchantUsers?: InputMaybe<ChannelMerchantUsersBoolExp>;
  channelMerchantUsers_aggregate?: InputMaybe<ChannelMerchantUsersAggregateBoolExp>;
  emailAddress?: InputMaybe<StringComparisonExp>;
  emailNotificationPreference?: InputMaybe<EmailNotificationPreferencesEnumComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertionTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  isAdmin?: InputMaybe<BooleanComparisonExp>;
  isAutoResponseEnabled?: InputMaybe<BooleanComparisonExp>;
  isOwner?: InputMaybe<BooleanComparisonExp>;
  isSuperAdmin?: InputMaybe<BooleanComparisonExp>;
  language?: InputMaybe<StringComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  lastSalesforceSyncTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  merchant?: InputMaybe<MerchantsBoolExp>;
  merchantCustomerUsers?: InputMaybe<MerchantCustomerUsersBoolExp>;
  merchantCustomerUsers_aggregate?: InputMaybe<MerchantCustomerUsersAggregateBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  merchantStoreMerchantUsers?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
  merchantStoreMerchantUsers_aggregate?: InputMaybe<MerchantStoreMerchantUsersAggregateBoolExp>;
  merchantUserContactGroups?: InputMaybe<MerchantUserContactGroupsBoolExp>;
  merchantUserContactGroups_aggregate?: InputMaybe<MerchantUserContactGroupsAggregateBoolExp>;
  salesforceInstanceUrl?: InputMaybe<StringComparisonExp>;
  salesforceRefreshToken?: InputMaybe<StringComparisonExp>;
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<JsonbComparisonExp>;
  salesforceUserIdentifier?: InputMaybe<StringComparisonExp>;
  unreadExternalChatMessagesCount?: InputMaybe<IntComparisonExp>;
  unreadInternalChatMessagesCount?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userColor?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "merchant_users" */
export enum MerchantUsersConstraint {
  /** unique or primary key constraint on columns "email_address" */
  MerchantUsersEmailAddressKey = 'merchant_users_email_address_key',
  /** unique or primary key constraint on columns "id" */
  MerchantUsersPkey = 'merchant_users_pkey',
  /** unique or primary key constraint on columns "salesforce_refresh_token" */
  MerchantUsersSalesforceRefreshTokenKey = 'merchant_users_salesforce_refresh_token_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type MerchantUsersDeleteAtPathInput = {
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type MerchantUsersDeleteElemInput = {
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type MerchantUsersDeleteKeyInput = {
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "merchant_users" */
export type MerchantUsersInsertInput = {
  channelMerchantUsers?: InputMaybe<ChannelMerchantUsersArrRelInsertInput>;
  emailAddress?: InputMaybe<Scalars['String']>;
  emailNotificationPreference?: InputMaybe<EmailNotificationPreferencesEnum>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isAutoResponseEnabled?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  isSuperAdmin?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastSalesforceSyncTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchant?: InputMaybe<MerchantsObjRelInsertInput>;
  merchantCustomerUsers?: InputMaybe<MerchantCustomerUsersArrRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  merchantStoreMerchantUsers?: InputMaybe<MerchantStoreMerchantUsersArrRelInsertInput>;
  merchantUserContactGroups?: InputMaybe<MerchantUserContactGroupsArrRelInsertInput>;
  salesforceInstanceUrl?: InputMaybe<Scalars['String']>;
  salesforceRefreshToken?: InputMaybe<Scalars['String']>;
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<Scalars['jsonb']>;
  salesforceUserIdentifier?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userColor?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MerchantUsersMaxFields = {
  __typename?: 'merchant_users_max_fields';
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastSalesforceSyncTimestamp?: Maybe<Scalars['timestamptz']>;
  merchantId?: Maybe<Scalars['uuid']>;
  salesforceInstanceUrl?: Maybe<Scalars['String']>;
  salesforceRefreshToken?: Maybe<Scalars['String']>;
  salesforceUserIdentifier?: Maybe<Scalars['String']>;
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
  userColor?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "merchant_users" */
export type MerchantUsersMaxOrderBy = {
  emailAddress?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastSalesforceSyncTimestamp?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  salesforceInstanceUrl?: InputMaybe<OrderBy>;
  salesforceRefreshToken?: InputMaybe<OrderBy>;
  salesforceUserIdentifier?: InputMaybe<OrderBy>;
  userColor?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantUsersMinFields = {
  __typename?: 'merchant_users_min_fields';
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertionTimestamp?: Maybe<Scalars['timestamptz']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastSalesforceSyncTimestamp?: Maybe<Scalars['timestamptz']>;
  merchantId?: Maybe<Scalars['uuid']>;
  salesforceInstanceUrl?: Maybe<Scalars['String']>;
  salesforceRefreshToken?: Maybe<Scalars['String']>;
  salesforceUserIdentifier?: Maybe<Scalars['String']>;
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
  userColor?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "merchant_users" */
export type MerchantUsersMinOrderBy = {
  emailAddress?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastSalesforceSyncTimestamp?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  salesforceInstanceUrl?: InputMaybe<OrderBy>;
  salesforceRefreshToken?: InputMaybe<OrderBy>;
  salesforceUserIdentifier?: InputMaybe<OrderBy>;
  userColor?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchant_users" */
export type MerchantUsersMutationResponse = {
  __typename?: 'merchant_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MerchantUsers>;
};

/** input type for inserting object relation for remote table "merchant_users" */
export type MerchantUsersObjRelInsertInput = {
  data: MerchantUsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantUsersOnConflict>;
};

/** on_conflict condition type for table "merchant_users" */
export type MerchantUsersOnConflict = {
  constraint: MerchantUsersConstraint;
  update_columns?: Array<MerchantUsersUpdateColumn>;
  where?: InputMaybe<MerchantUsersBoolExp>;
};

/** Ordering options when selecting data from "merchant_users". */
export type MerchantUsersOrderBy = {
  channelMerchantUsers_aggregate?: InputMaybe<ChannelMerchantUsersAggregateOrderBy>;
  emailAddress?: InputMaybe<OrderBy>;
  emailNotificationPreference?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertionTimestamp?: InputMaybe<OrderBy>;
  isAdmin?: InputMaybe<OrderBy>;
  isAutoResponseEnabled?: InputMaybe<OrderBy>;
  isOwner?: InputMaybe<OrderBy>;
  isSuperAdmin?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lastSalesforceSyncTimestamp?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantsOrderBy>;
  merchantCustomerUsers_aggregate?: InputMaybe<MerchantCustomerUsersAggregateOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  merchantStoreMerchantUsers_aggregate?: InputMaybe<MerchantStoreMerchantUsersAggregateOrderBy>;
  merchantUserContactGroups_aggregate?: InputMaybe<MerchantUserContactGroupsAggregateOrderBy>;
  salesforceInstanceUrl?: InputMaybe<OrderBy>;
  salesforceRefreshToken?: InputMaybe<OrderBy>;
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<OrderBy>;
  salesforceUserIdentifier?: InputMaybe<OrderBy>;
  unreadExternalChatMessagesCount?: InputMaybe<OrderBy>;
  unreadInternalChatMessagesCount?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userColor?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchant_users */
export type MerchantUsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type MerchantUsersPrependInput = {
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "merchant_users" */
export enum MerchantUsersSelectColumn {
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  EmailNotificationPreference = 'emailNotificationPreference',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsAutoResponseEnabled = 'isAutoResponseEnabled',
  /** column name */
  IsOwner = 'isOwner',
  /** column name */
  IsSuperAdmin = 'isSuperAdmin',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastSalesforceSyncTimestamp = 'lastSalesforceSyncTimestamp',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  SalesforceInstanceUrl = 'salesforceInstanceUrl',
  /** column name */
  SalesforceRefreshToken = 'salesforceRefreshToken',
  /** column name */
  SalesforceRequiredCustomFieldToDefaultValue = 'salesforceRequiredCustomFieldToDefaultValue',
  /** column name */
  SalesforceUserIdentifier = 'salesforceUserIdentifier',
  /** column name */
  UserColor = 'userColor'
}

/** select "merchant_users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "merchant_users" */
export enum MerchantUsersSelectColumnMerchantUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsAutoResponseEnabled = 'isAutoResponseEnabled',
  /** column name */
  IsOwner = 'isOwner',
  /** column name */
  IsSuperAdmin = 'isSuperAdmin'
}

/** select "merchant_users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "merchant_users" */
export enum MerchantUsersSelectColumnMerchantUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsAutoResponseEnabled = 'isAutoResponseEnabled',
  /** column name */
  IsOwner = 'isOwner',
  /** column name */
  IsSuperAdmin = 'isSuperAdmin'
}

/** input type for updating data in table "merchant_users" */
export type MerchantUsersSetInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  emailNotificationPreference?: InputMaybe<EmailNotificationPreferencesEnum>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isAutoResponseEnabled?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  isSuperAdmin?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastSalesforceSyncTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  salesforceInstanceUrl?: InputMaybe<Scalars['String']>;
  salesforceRefreshToken?: InputMaybe<Scalars['String']>;
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<Scalars['jsonb']>;
  salesforceUserIdentifier?: InputMaybe<Scalars['String']>;
  userColor?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type MerchantUsersStddevFields = {
  __typename?: 'merchant_users_stddev_fields';
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_pop on columns */
export type MerchantUsersStddevPopFields = {
  __typename?: 'merchant_users_stddev_pop_fields';
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev_samp on columns */
export type MerchantUsersStddevSampFields = {
  __typename?: 'merchant_users_stddev_samp_fields';
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
};

/** Streaming cursor of the table "merchant_users" */
export type MerchantUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantUsersStreamCursorValueInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  emailNotificationPreference?: InputMaybe<EmailNotificationPreferencesEnum>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertionTimestamp?: InputMaybe<Scalars['timestamptz']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  isAutoResponseEnabled?: InputMaybe<Scalars['Boolean']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  isSuperAdmin?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastSalesforceSyncTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  salesforceInstanceUrl?: InputMaybe<Scalars['String']>;
  salesforceRefreshToken?: InputMaybe<Scalars['String']>;
  salesforceRequiredCustomFieldToDefaultValue?: InputMaybe<Scalars['jsonb']>;
  salesforceUserIdentifier?: InputMaybe<Scalars['String']>;
  userColor?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type MerchantUsersSumFields = {
  __typename?: 'merchant_users_sum_fields';
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
};

/** update columns of table "merchant_users" */
export enum MerchantUsersUpdateColumn {
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  EmailNotificationPreference = 'emailNotificationPreference',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertionTimestamp',
  /** column name */
  IsAdmin = 'isAdmin',
  /** column name */
  IsAutoResponseEnabled = 'isAutoResponseEnabled',
  /** column name */
  IsOwner = 'isOwner',
  /** column name */
  IsSuperAdmin = 'isSuperAdmin',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastSalesforceSyncTimestamp = 'lastSalesforceSyncTimestamp',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  SalesforceInstanceUrl = 'salesforceInstanceUrl',
  /** column name */
  SalesforceRefreshToken = 'salesforceRefreshToken',
  /** column name */
  SalesforceRequiredCustomFieldToDefaultValue = 'salesforceRequiredCustomFieldToDefaultValue',
  /** column name */
  SalesforceUserIdentifier = 'salesforceUserIdentifier',
  /** column name */
  UserColor = 'userColor'
}

export type MerchantUsersUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<MerchantUsersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<MerchantUsersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<MerchantUsersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<MerchantUsersDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<MerchantUsersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantUsersSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantUsersBoolExp;
};

/** aggregate var_pop on columns */
export type MerchantUsersVarPopFields = {
  __typename?: 'merchant_users_var_pop_fields';
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
};

/** aggregate var_samp on columns */
export type MerchantUsersVarSampFields = {
  __typename?: 'merchant_users_var_samp_fields';
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type MerchantUsersVarianceFields = {
  __typename?: 'merchant_users_variance_fields';
  /** A computed field, executes function "merchant_user_unread_external_chat_messages_count" */
  unreadExternalChatMessagesCount?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "merchant_user_unread_internal_chat_messages_count" */
  unreadInternalChatMessagesCount?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "merchants" */
export type Merchants = {
  __typename?: 'merchants';
  /** An array relationship */
  channels: Array<Channels>;
  /** An aggregate relationship */
  channels_aggregate: ChannelsAggregate;
  /** An array relationship */
  chatMessageTemplates: Array<ChatMessageTemplates>;
  /** An aggregate relationship */
  chatMessageTemplates_aggregate: ChatMessageTemplatesAggregate;
  code?: Maybe<Scalars['String']>;
  hubspotPortalId?: Maybe<Scalars['Int']>;
  hubspotRefreshToken?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isDeleted: Scalars['Boolean'];
  isSandbox: Scalars['Boolean'];
  /** A computed field, executes function "merchants_last_salesforce_sync_timestamp" */
  lastSalesforceSyncTimestamp?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  merchantApiUsers: Array<MerchantApiUsers>;
  /** An aggregate relationship */
  merchantApiUsers_aggregate: MerchantApiUsersAggregate;
  /** An array relationship */
  merchantCustomerUsers: Array<MerchantCustomerUsers>;
  /** An aggregate relationship */
  merchantCustomerUsers_aggregate: MerchantCustomerUsersAggregate;
  /** An array relationship */
  merchantStores: Array<MerchantStores>;
  /** An aggregate relationship */
  merchantStores_aggregate: MerchantStoresAggregate;
  /** An array relationship */
  merchantUsers: Array<MerchantUsers>;
  /** An aggregate relationship */
  merchantUsers_aggregate: MerchantUsersAggregate;
  name: Scalars['String'];
  /** An object relationship */
  organization: Organizations;
  organizationId: Scalars['uuid'];
  /** A computed field, executes function "merchants_salesforce_connection_status" */
  salesforceConnectionStatus?: Maybe<Scalars['String']>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: TagsAggregate;
  whatsappBusinessAbout?: Maybe<Scalars['String']>;
  /** An array relationship */
  whatsappBusinessAccounts: Array<WhatsappBusinessAccounts>;
  /** An aggregate relationship */
  whatsappBusinessAccounts_aggregate: WhatsappBusinessAccountsAggregate;
  whatsappBusinessAddress?: Maybe<Scalars['String']>;
  whatsappBusinessDescription?: Maybe<Scalars['String']>;
  whatsappBusinessEmail?: Maybe<Scalars['String']>;
  /** An object relationship */
  whatsappBusinessProfileImageFile?: Maybe<Files>;
  whatsappBusinessProfileImageFileId?: Maybe<Scalars['uuid']>;
  whatsappBusinessVertical?: Maybe<WhatsappBusinessVerticalsEnum>;
  whatsappBusinessWebsite?: Maybe<Scalars['String']>;
};


/** columns and relationships of "merchants" */
export type MerchantsChannelsArgs = {
  distinct_on?: InputMaybe<Array<ChannelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelsOrderBy>>;
  where?: InputMaybe<ChannelsBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelsOrderBy>>;
  where?: InputMaybe<ChannelsBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsChatMessageTemplatesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatesBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsChatMessageTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatesBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsMerchantApiUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantApiUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantApiUsersOrderBy>>;
  where?: InputMaybe<MerchantApiUsersBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsMerchantApiUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantApiUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantApiUsersOrderBy>>;
  where?: InputMaybe<MerchantApiUsersBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsMerchantCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsMerchantCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsMerchantStoresArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoresOrderBy>>;
  where?: InputMaybe<MerchantStoresBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsMerchantStoresAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoresOrderBy>>;
  where?: InputMaybe<MerchantStoresBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantUsersBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantUsersBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsTagsArgs = {
  distinct_on?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsWhatsappBusinessAccountsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessAccountsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
};


/** columns and relationships of "merchants" */
export type MerchantsWhatsappBusinessAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessAccountsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
};

/** aggregated selection of "merchants" */
export type MerchantsAggregate = {
  __typename?: 'merchants_aggregate';
  aggregate?: Maybe<MerchantsAggregateFields>;
  nodes: Array<Merchants>;
};

export type MerchantsAggregateBoolExp = {
  bool_and?: InputMaybe<MerchantsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<MerchantsAggregateBoolExpBoolOr>;
  count?: InputMaybe<MerchantsAggregateBoolExpCount>;
};

export type MerchantsAggregateBoolExpBoolAnd = {
  arguments: MerchantsSelectColumnMerchantsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantsAggregateBoolExpBoolOr = {
  arguments: MerchantsSelectColumnMerchantsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MerchantsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MerchantsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<MerchantsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "merchants" */
export type MerchantsAggregateFields = {
  __typename?: 'merchants_aggregate_fields';
  avg?: Maybe<MerchantsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MerchantsMaxFields>;
  min?: Maybe<MerchantsMinFields>;
  stddev?: Maybe<MerchantsStddevFields>;
  stddev_pop?: Maybe<MerchantsStddevPopFields>;
  stddev_samp?: Maybe<MerchantsStddevSampFields>;
  sum?: Maybe<MerchantsSumFields>;
  var_pop?: Maybe<MerchantsVarPopFields>;
  var_samp?: Maybe<MerchantsVarSampFields>;
  variance?: Maybe<MerchantsVarianceFields>;
};


/** aggregate fields of "merchants" */
export type MerchantsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MerchantsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "merchants" */
export type MerchantsAggregateOrderBy = {
  avg?: InputMaybe<MerchantsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MerchantsMaxOrderBy>;
  min?: InputMaybe<MerchantsMinOrderBy>;
  stddev?: InputMaybe<MerchantsStddevOrderBy>;
  stddev_pop?: InputMaybe<MerchantsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MerchantsStddevSampOrderBy>;
  sum?: InputMaybe<MerchantsSumOrderBy>;
  var_pop?: InputMaybe<MerchantsVarPopOrderBy>;
  var_samp?: InputMaybe<MerchantsVarSampOrderBy>;
  variance?: InputMaybe<MerchantsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "merchants" */
export type MerchantsArrRelInsertInput = {
  data: Array<MerchantsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantsOnConflict>;
};

/** aggregate avg on columns */
export type MerchantsAvgFields = {
  __typename?: 'merchants_avg_fields';
  hubspotPortalId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "merchants" */
export type MerchantsAvgOrderBy = {
  hubspotPortalId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "merchants". All fields are combined with a logical 'AND'. */
export type MerchantsBoolExp = {
  _and?: InputMaybe<Array<MerchantsBoolExp>>;
  _not?: InputMaybe<MerchantsBoolExp>;
  _or?: InputMaybe<Array<MerchantsBoolExp>>;
  channels?: InputMaybe<ChannelsBoolExp>;
  channels_aggregate?: InputMaybe<ChannelsAggregateBoolExp>;
  chatMessageTemplates?: InputMaybe<ChatMessageTemplatesBoolExp>;
  chatMessageTemplates_aggregate?: InputMaybe<ChatMessageTemplatesAggregateBoolExp>;
  code?: InputMaybe<StringComparisonExp>;
  hubspotPortalId?: InputMaybe<IntComparisonExp>;
  hubspotRefreshToken?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  isSandbox?: InputMaybe<BooleanComparisonExp>;
  lastSalesforceSyncTimestamp?: InputMaybe<TimestampComparisonExp>;
  merchantApiUsers?: InputMaybe<MerchantApiUsersBoolExp>;
  merchantApiUsers_aggregate?: InputMaybe<MerchantApiUsersAggregateBoolExp>;
  merchantCustomerUsers?: InputMaybe<MerchantCustomerUsersBoolExp>;
  merchantCustomerUsers_aggregate?: InputMaybe<MerchantCustomerUsersAggregateBoolExp>;
  merchantStores?: InputMaybe<MerchantStoresBoolExp>;
  merchantStores_aggregate?: InputMaybe<MerchantStoresAggregateBoolExp>;
  merchantUsers?: InputMaybe<MerchantUsersBoolExp>;
  merchantUsers_aggregate?: InputMaybe<MerchantUsersAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
  salesforceConnectionStatus?: InputMaybe<StringComparisonExp>;
  tags?: InputMaybe<TagsBoolExp>;
  tags_aggregate?: InputMaybe<TagsAggregateBoolExp>;
  whatsappBusinessAbout?: InputMaybe<StringComparisonExp>;
  whatsappBusinessAccounts?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
  whatsappBusinessAccounts_aggregate?: InputMaybe<WhatsappBusinessAccountsAggregateBoolExp>;
  whatsappBusinessAddress?: InputMaybe<StringComparisonExp>;
  whatsappBusinessDescription?: InputMaybe<StringComparisonExp>;
  whatsappBusinessEmail?: InputMaybe<StringComparisonExp>;
  whatsappBusinessProfileImageFile?: InputMaybe<FilesBoolExp>;
  whatsappBusinessProfileImageFileId?: InputMaybe<UuidComparisonExp>;
  whatsappBusinessVertical?: InputMaybe<WhatsappBusinessVerticalsEnumComparisonExp>;
  whatsappBusinessWebsite?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "merchants" */
export enum MerchantsConstraint {
  /** unique or primary key constraint on columns "code", "organization_id" */
  MerchantsCodeOrganizationIdKey = 'merchants_code_organization_id_key',
  /** unique or primary key constraint on columns "is_sandbox" */
  MerchantsIsSandboxTrueIdx = 'merchants_is_sandbox_true_idx',
  /** unique or primary key constraint on columns "id" */
  MerchantsPkey = 'merchants_pkey'
}

/** input type for incrementing numeric columns in table "merchants" */
export type MerchantsIncInput = {
  hubspotPortalId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "merchants" */
export type MerchantsInsertInput = {
  channels?: InputMaybe<ChannelsArrRelInsertInput>;
  chatMessageTemplates?: InputMaybe<ChatMessageTemplatesArrRelInsertInput>;
  code?: InputMaybe<Scalars['String']>;
  hubspotPortalId?: InputMaybe<Scalars['Int']>;
  hubspotRefreshToken?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isSandbox?: InputMaybe<Scalars['Boolean']>;
  merchantApiUsers?: InputMaybe<MerchantApiUsersArrRelInsertInput>;
  merchantCustomerUsers?: InputMaybe<MerchantCustomerUsersArrRelInsertInput>;
  merchantStores?: InputMaybe<MerchantStoresArrRelInsertInput>;
  merchantUsers?: InputMaybe<MerchantUsersArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationsObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<TagsArrRelInsertInput>;
  whatsappBusinessAbout?: InputMaybe<Scalars['String']>;
  whatsappBusinessAccounts?: InputMaybe<WhatsappBusinessAccountsArrRelInsertInput>;
  whatsappBusinessAddress?: InputMaybe<Scalars['String']>;
  whatsappBusinessDescription?: InputMaybe<Scalars['String']>;
  whatsappBusinessEmail?: InputMaybe<Scalars['String']>;
  whatsappBusinessProfileImageFile?: InputMaybe<FilesObjRelInsertInput>;
  whatsappBusinessProfileImageFileId?: InputMaybe<Scalars['uuid']>;
  whatsappBusinessVertical?: InputMaybe<WhatsappBusinessVerticalsEnum>;
  whatsappBusinessWebsite?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MerchantsMaxFields = {
  __typename?: 'merchants_max_fields';
  code?: Maybe<Scalars['String']>;
  hubspotPortalId?: Maybe<Scalars['Int']>;
  hubspotRefreshToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "merchants_last_salesforce_sync_timestamp" */
  lastSalesforceSyncTimestamp?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "merchants_salesforce_connection_status" */
  salesforceConnectionStatus?: Maybe<Scalars['String']>;
  whatsappBusinessAbout?: Maybe<Scalars['String']>;
  whatsappBusinessAddress?: Maybe<Scalars['String']>;
  whatsappBusinessDescription?: Maybe<Scalars['String']>;
  whatsappBusinessEmail?: Maybe<Scalars['String']>;
  whatsappBusinessProfileImageFileId?: Maybe<Scalars['uuid']>;
  whatsappBusinessWebsite?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "merchants" */
export type MerchantsMaxOrderBy = {
  code?: InputMaybe<OrderBy>;
  hubspotPortalId?: InputMaybe<OrderBy>;
  hubspotRefreshToken?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  whatsappBusinessAbout?: InputMaybe<OrderBy>;
  whatsappBusinessAddress?: InputMaybe<OrderBy>;
  whatsappBusinessDescription?: InputMaybe<OrderBy>;
  whatsappBusinessEmail?: InputMaybe<OrderBy>;
  whatsappBusinessProfileImageFileId?: InputMaybe<OrderBy>;
  whatsappBusinessWebsite?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MerchantsMinFields = {
  __typename?: 'merchants_min_fields';
  code?: Maybe<Scalars['String']>;
  hubspotPortalId?: Maybe<Scalars['Int']>;
  hubspotRefreshToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "merchants_last_salesforce_sync_timestamp" */
  lastSalesforceSyncTimestamp?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "merchants_salesforce_connection_status" */
  salesforceConnectionStatus?: Maybe<Scalars['String']>;
  whatsappBusinessAbout?: Maybe<Scalars['String']>;
  whatsappBusinessAddress?: Maybe<Scalars['String']>;
  whatsappBusinessDescription?: Maybe<Scalars['String']>;
  whatsappBusinessEmail?: Maybe<Scalars['String']>;
  whatsappBusinessProfileImageFileId?: Maybe<Scalars['uuid']>;
  whatsappBusinessWebsite?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "merchants" */
export type MerchantsMinOrderBy = {
  code?: InputMaybe<OrderBy>;
  hubspotPortalId?: InputMaybe<OrderBy>;
  hubspotRefreshToken?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  whatsappBusinessAbout?: InputMaybe<OrderBy>;
  whatsappBusinessAddress?: InputMaybe<OrderBy>;
  whatsappBusinessDescription?: InputMaybe<OrderBy>;
  whatsappBusinessEmail?: InputMaybe<OrderBy>;
  whatsappBusinessProfileImageFileId?: InputMaybe<OrderBy>;
  whatsappBusinessWebsite?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "merchants" */
export type MerchantsMutationResponse = {
  __typename?: 'merchants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Merchants>;
};

/** input type for inserting object relation for remote table "merchants" */
export type MerchantsObjRelInsertInput = {
  data: MerchantsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MerchantsOnConflict>;
};

/** on_conflict condition type for table "merchants" */
export type MerchantsOnConflict = {
  constraint: MerchantsConstraint;
  update_columns?: Array<MerchantsUpdateColumn>;
  where?: InputMaybe<MerchantsBoolExp>;
};

/** Ordering options when selecting data from "merchants". */
export type MerchantsOrderBy = {
  channels_aggregate?: InputMaybe<ChannelsAggregateOrderBy>;
  chatMessageTemplates_aggregate?: InputMaybe<ChatMessageTemplatesAggregateOrderBy>;
  code?: InputMaybe<OrderBy>;
  hubspotPortalId?: InputMaybe<OrderBy>;
  hubspotRefreshToken?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  isSandbox?: InputMaybe<OrderBy>;
  lastSalesforceSyncTimestamp?: InputMaybe<OrderBy>;
  merchantApiUsers_aggregate?: InputMaybe<MerchantApiUsersAggregateOrderBy>;
  merchantCustomerUsers_aggregate?: InputMaybe<MerchantCustomerUsersAggregateOrderBy>;
  merchantStores_aggregate?: InputMaybe<MerchantStoresAggregateOrderBy>;
  merchantUsers_aggregate?: InputMaybe<MerchantUsersAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  salesforceConnectionStatus?: InputMaybe<OrderBy>;
  tags_aggregate?: InputMaybe<TagsAggregateOrderBy>;
  whatsappBusinessAbout?: InputMaybe<OrderBy>;
  whatsappBusinessAccounts_aggregate?: InputMaybe<WhatsappBusinessAccountsAggregateOrderBy>;
  whatsappBusinessAddress?: InputMaybe<OrderBy>;
  whatsappBusinessDescription?: InputMaybe<OrderBy>;
  whatsappBusinessEmail?: InputMaybe<OrderBy>;
  whatsappBusinessProfileImageFile?: InputMaybe<FilesOrderBy>;
  whatsappBusinessProfileImageFileId?: InputMaybe<OrderBy>;
  whatsappBusinessVertical?: InputMaybe<OrderBy>;
  whatsappBusinessWebsite?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: merchants */
export type MerchantsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "merchants" */
export enum MerchantsSelectColumn {
  /** column name */
  Code = 'code',
  /** column name */
  HubspotPortalId = 'hubspotPortalId',
  /** column name */
  HubspotRefreshToken = 'hubspotRefreshToken',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsSandbox = 'isSandbox',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  WhatsappBusinessAbout = 'whatsappBusinessAbout',
  /** column name */
  WhatsappBusinessAddress = 'whatsappBusinessAddress',
  /** column name */
  WhatsappBusinessDescription = 'whatsappBusinessDescription',
  /** column name */
  WhatsappBusinessEmail = 'whatsappBusinessEmail',
  /** column name */
  WhatsappBusinessProfileImageFileId = 'whatsappBusinessProfileImageFileId',
  /** column name */
  WhatsappBusinessVertical = 'whatsappBusinessVertical',
  /** column name */
  WhatsappBusinessWebsite = 'whatsappBusinessWebsite'
}

/** select "merchants_aggregate_bool_exp_bool_and_arguments_columns" columns of table "merchants" */
export enum MerchantsSelectColumnMerchantsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsSandbox = 'isSandbox'
}

/** select "merchants_aggregate_bool_exp_bool_or_arguments_columns" columns of table "merchants" */
export enum MerchantsSelectColumnMerchantsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsSandbox = 'isSandbox'
}

/** input type for updating data in table "merchants" */
export type MerchantsSetInput = {
  code?: InputMaybe<Scalars['String']>;
  hubspotPortalId?: InputMaybe<Scalars['Int']>;
  hubspotRefreshToken?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isSandbox?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  whatsappBusinessAbout?: InputMaybe<Scalars['String']>;
  whatsappBusinessAddress?: InputMaybe<Scalars['String']>;
  whatsappBusinessDescription?: InputMaybe<Scalars['String']>;
  whatsappBusinessEmail?: InputMaybe<Scalars['String']>;
  whatsappBusinessProfileImageFileId?: InputMaybe<Scalars['uuid']>;
  whatsappBusinessVertical?: InputMaybe<WhatsappBusinessVerticalsEnum>;
  whatsappBusinessWebsite?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type MerchantsStddevFields = {
  __typename?: 'merchants_stddev_fields';
  hubspotPortalId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "merchants" */
export type MerchantsStddevOrderBy = {
  hubspotPortalId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MerchantsStddevPopFields = {
  __typename?: 'merchants_stddev_pop_fields';
  hubspotPortalId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "merchants" */
export type MerchantsStddevPopOrderBy = {
  hubspotPortalId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MerchantsStddevSampFields = {
  __typename?: 'merchants_stddev_samp_fields';
  hubspotPortalId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "merchants" */
export type MerchantsStddevSampOrderBy = {
  hubspotPortalId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "merchants" */
export type MerchantsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MerchantsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MerchantsStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']>;
  hubspotPortalId?: InputMaybe<Scalars['Int']>;
  hubspotRefreshToken?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isSandbox?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
  whatsappBusinessAbout?: InputMaybe<Scalars['String']>;
  whatsappBusinessAddress?: InputMaybe<Scalars['String']>;
  whatsappBusinessDescription?: InputMaybe<Scalars['String']>;
  whatsappBusinessEmail?: InputMaybe<Scalars['String']>;
  whatsappBusinessProfileImageFileId?: InputMaybe<Scalars['uuid']>;
  whatsappBusinessVertical?: InputMaybe<WhatsappBusinessVerticalsEnum>;
  whatsappBusinessWebsite?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type MerchantsSumFields = {
  __typename?: 'merchants_sum_fields';
  hubspotPortalId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "merchants" */
export type MerchantsSumOrderBy = {
  hubspotPortalId?: InputMaybe<OrderBy>;
};

/** update columns of table "merchants" */
export enum MerchantsUpdateColumn {
  /** column name */
  Code = 'code',
  /** column name */
  HubspotPortalId = 'hubspotPortalId',
  /** column name */
  HubspotRefreshToken = 'hubspotRefreshToken',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsSandbox = 'isSandbox',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  WhatsappBusinessAbout = 'whatsappBusinessAbout',
  /** column name */
  WhatsappBusinessAddress = 'whatsappBusinessAddress',
  /** column name */
  WhatsappBusinessDescription = 'whatsappBusinessDescription',
  /** column name */
  WhatsappBusinessEmail = 'whatsappBusinessEmail',
  /** column name */
  WhatsappBusinessProfileImageFileId = 'whatsappBusinessProfileImageFileId',
  /** column name */
  WhatsappBusinessVertical = 'whatsappBusinessVertical',
  /** column name */
  WhatsappBusinessWebsite = 'whatsappBusinessWebsite'
}

export type MerchantsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MerchantsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MerchantsSetInput>;
  /** filter the rows which have to be updated */
  where: MerchantsBoolExp;
};

/** aggregate var_pop on columns */
export type MerchantsVarPopFields = {
  __typename?: 'merchants_var_pop_fields';
  hubspotPortalId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "merchants" */
export type MerchantsVarPopOrderBy = {
  hubspotPortalId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MerchantsVarSampFields = {
  __typename?: 'merchants_var_samp_fields';
  hubspotPortalId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "merchants" */
export type MerchantsVarSampOrderBy = {
  hubspotPortalId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MerchantsVarianceFields = {
  __typename?: 'merchants_variance_fields';
  hubspotPortalId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "merchants" */
export type MerchantsVarianceOrderBy = {
  hubspotPortalId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "mobile_app_platforms" */
export type MobileAppPlatforms = {
  __typename?: 'mobile_app_platforms';
  platform: Scalars['String'];
};

/** aggregated selection of "mobile_app_platforms" */
export type MobileAppPlatformsAggregate = {
  __typename?: 'mobile_app_platforms_aggregate';
  aggregate?: Maybe<MobileAppPlatformsAggregateFields>;
  nodes: Array<MobileAppPlatforms>;
};

/** aggregate fields of "mobile_app_platforms" */
export type MobileAppPlatformsAggregateFields = {
  __typename?: 'mobile_app_platforms_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MobileAppPlatformsMaxFields>;
  min?: Maybe<MobileAppPlatformsMinFields>;
};


/** aggregate fields of "mobile_app_platforms" */
export type MobileAppPlatformsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MobileAppPlatformsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mobile_app_platforms". All fields are combined with a logical 'AND'. */
export type MobileAppPlatformsBoolExp = {
  _and?: InputMaybe<Array<MobileAppPlatformsBoolExp>>;
  _not?: InputMaybe<MobileAppPlatformsBoolExp>;
  _or?: InputMaybe<Array<MobileAppPlatformsBoolExp>>;
  platform?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "mobile_app_platforms" */
export enum MobileAppPlatformsConstraint {
  /** unique or primary key constraint on columns "platform" */
  MobileAppPlatformsPkey = 'mobile_app_platforms_pkey'
}

export enum MobileAppPlatformsEnum {
  Android = 'ANDROID',
  Ios = 'IOS'
}

/** Boolean expression to compare columns of type "mobile_app_platforms_enum". All fields are combined with logical 'AND'. */
export type MobileAppPlatformsEnumComparisonExp = {
  _eq?: InputMaybe<MobileAppPlatformsEnum>;
  _in?: InputMaybe<Array<MobileAppPlatformsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MobileAppPlatformsEnum>;
  _nin?: InputMaybe<Array<MobileAppPlatformsEnum>>;
};

/** input type for inserting data into table "mobile_app_platforms" */
export type MobileAppPlatformsInsertInput = {
  platform?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MobileAppPlatformsMaxFields = {
  __typename?: 'mobile_app_platforms_max_fields';
  platform?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MobileAppPlatformsMinFields = {
  __typename?: 'mobile_app_platforms_min_fields';
  platform?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mobile_app_platforms" */
export type MobileAppPlatformsMutationResponse = {
  __typename?: 'mobile_app_platforms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MobileAppPlatforms>;
};

/** on_conflict condition type for table "mobile_app_platforms" */
export type MobileAppPlatformsOnConflict = {
  constraint: MobileAppPlatformsConstraint;
  update_columns?: Array<MobileAppPlatformsUpdateColumn>;
  where?: InputMaybe<MobileAppPlatformsBoolExp>;
};

/** Ordering options when selecting data from "mobile_app_platforms". */
export type MobileAppPlatformsOrderBy = {
  platform?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: mobile_app_platforms */
export type MobileAppPlatformsPkColumnsInput = {
  platform: Scalars['String'];
};

/** select columns of table "mobile_app_platforms" */
export enum MobileAppPlatformsSelectColumn {
  /** column name */
  Platform = 'platform'
}

/** input type for updating data in table "mobile_app_platforms" */
export type MobileAppPlatformsSetInput = {
  platform?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mobile_app_platforms" */
export type MobileAppPlatformsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MobileAppPlatformsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MobileAppPlatformsStreamCursorValueInput = {
  platform?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mobile_app_platforms" */
export enum MobileAppPlatformsUpdateColumn {
  /** column name */
  Platform = 'platform'
}

export type MobileAppPlatformsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MobileAppPlatformsSetInput>;
  /** filter the rows which have to be updated */
  where: MobileAppPlatformsBoolExp;
};

/** columns and relationships of "mobile_app_release_statuses" */
export type MobileAppReleaseStatuses = {
  __typename?: 'mobile_app_release_statuses';
  status: Scalars['String'];
};

/** aggregated selection of "mobile_app_release_statuses" */
export type MobileAppReleaseStatusesAggregate = {
  __typename?: 'mobile_app_release_statuses_aggregate';
  aggregate?: Maybe<MobileAppReleaseStatusesAggregateFields>;
  nodes: Array<MobileAppReleaseStatuses>;
};

/** aggregate fields of "mobile_app_release_statuses" */
export type MobileAppReleaseStatusesAggregateFields = {
  __typename?: 'mobile_app_release_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MobileAppReleaseStatusesMaxFields>;
  min?: Maybe<MobileAppReleaseStatusesMinFields>;
};


/** aggregate fields of "mobile_app_release_statuses" */
export type MobileAppReleaseStatusesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MobileAppReleaseStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mobile_app_release_statuses". All fields are combined with a logical 'AND'. */
export type MobileAppReleaseStatusesBoolExp = {
  _and?: InputMaybe<Array<MobileAppReleaseStatusesBoolExp>>;
  _not?: InputMaybe<MobileAppReleaseStatusesBoolExp>;
  _or?: InputMaybe<Array<MobileAppReleaseStatusesBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "mobile_app_release_statuses" */
export enum MobileAppReleaseStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  MobileAppReleaseStatusesPkey = 'mobile_app_release_statuses_pkey'
}

export enum MobileAppReleaseStatusesEnum {
  Approved = 'APPROVED',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
  Reviewing = 'REVIEWING',
  Submitted = 'SUBMITTED'
}

/** Boolean expression to compare columns of type "mobile_app_release_statuses_enum". All fields are combined with logical 'AND'. */
export type MobileAppReleaseStatusesEnumComparisonExp = {
  _eq?: InputMaybe<MobileAppReleaseStatusesEnum>;
  _in?: InputMaybe<Array<MobileAppReleaseStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MobileAppReleaseStatusesEnum>;
  _nin?: InputMaybe<Array<MobileAppReleaseStatusesEnum>>;
};

/** input type for inserting data into table "mobile_app_release_statuses" */
export type MobileAppReleaseStatusesInsertInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MobileAppReleaseStatusesMaxFields = {
  __typename?: 'mobile_app_release_statuses_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MobileAppReleaseStatusesMinFields = {
  __typename?: 'mobile_app_release_statuses_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mobile_app_release_statuses" */
export type MobileAppReleaseStatusesMutationResponse = {
  __typename?: 'mobile_app_release_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MobileAppReleaseStatuses>;
};

/** on_conflict condition type for table "mobile_app_release_statuses" */
export type MobileAppReleaseStatusesOnConflict = {
  constraint: MobileAppReleaseStatusesConstraint;
  update_columns?: Array<MobileAppReleaseStatusesUpdateColumn>;
  where?: InputMaybe<MobileAppReleaseStatusesBoolExp>;
};

/** Ordering options when selecting data from "mobile_app_release_statuses". */
export type MobileAppReleaseStatusesOrderBy = {
  status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: mobile_app_release_statuses */
export type MobileAppReleaseStatusesPkColumnsInput = {
  status: Scalars['String'];
};

/** select columns of table "mobile_app_release_statuses" */
export enum MobileAppReleaseStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "mobile_app_release_statuses" */
export type MobileAppReleaseStatusesSetInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mobile_app_release_statuses" */
export type MobileAppReleaseStatusesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MobileAppReleaseStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MobileAppReleaseStatusesStreamCursorValueInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mobile_app_release_statuses" */
export enum MobileAppReleaseStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export type MobileAppReleaseStatusesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MobileAppReleaseStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: MobileAppReleaseStatusesBoolExp;
};

/** columns and relationships of "mobile_app_releases" */
export type MobileAppReleases = {
  __typename?: 'mobile_app_releases';
  id: Scalars['uuid'];
  reviewBaseHostname: Scalars['String'];
  status: MobileAppReleaseStatusesEnum;
  version: Scalars['String'];
};

/** aggregated selection of "mobile_app_releases" */
export type MobileAppReleasesAggregate = {
  __typename?: 'mobile_app_releases_aggregate';
  aggregate?: Maybe<MobileAppReleasesAggregateFields>;
  nodes: Array<MobileAppReleases>;
};

/** aggregate fields of "mobile_app_releases" */
export type MobileAppReleasesAggregateFields = {
  __typename?: 'mobile_app_releases_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MobileAppReleasesMaxFields>;
  min?: Maybe<MobileAppReleasesMinFields>;
};


/** aggregate fields of "mobile_app_releases" */
export type MobileAppReleasesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MobileAppReleasesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "mobile_app_releases". All fields are combined with a logical 'AND'. */
export type MobileAppReleasesBoolExp = {
  _and?: InputMaybe<Array<MobileAppReleasesBoolExp>>;
  _not?: InputMaybe<MobileAppReleasesBoolExp>;
  _or?: InputMaybe<Array<MobileAppReleasesBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  reviewBaseHostname?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<MobileAppReleaseStatusesEnumComparisonExp>;
  version?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "mobile_app_releases" */
export enum MobileAppReleasesConstraint {
  /** unique or primary key constraint on columns "id" */
  MobileAppReleasesPkey = 'mobile_app_releases_pkey',
  /** unique or primary key constraint on columns "version" */
  MobileAppReleasesVersionKey = 'mobile_app_releases_version_key'
}

/** input type for inserting data into table "mobile_app_releases" */
export type MobileAppReleasesInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  reviewBaseHostname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MobileAppReleaseStatusesEnum>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MobileAppReleasesMaxFields = {
  __typename?: 'mobile_app_releases_max_fields';
  id?: Maybe<Scalars['uuid']>;
  reviewBaseHostname?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MobileAppReleasesMinFields = {
  __typename?: 'mobile_app_releases_min_fields';
  id?: Maybe<Scalars['uuid']>;
  reviewBaseHostname?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "mobile_app_releases" */
export type MobileAppReleasesMutationResponse = {
  __typename?: 'mobile_app_releases_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MobileAppReleases>;
};

/** on_conflict condition type for table "mobile_app_releases" */
export type MobileAppReleasesOnConflict = {
  constraint: MobileAppReleasesConstraint;
  update_columns?: Array<MobileAppReleasesUpdateColumn>;
  where?: InputMaybe<MobileAppReleasesBoolExp>;
};

/** Ordering options when selecting data from "mobile_app_releases". */
export type MobileAppReleasesOrderBy = {
  id?: InputMaybe<OrderBy>;
  reviewBaseHostname?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: mobile_app_releases */
export type MobileAppReleasesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "mobile_app_releases" */
export enum MobileAppReleasesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ReviewBaseHostname = 'reviewBaseHostname',
  /** column name */
  Status = 'status',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "mobile_app_releases" */
export type MobileAppReleasesSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  reviewBaseHostname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MobileAppReleaseStatusesEnum>;
  version?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "mobile_app_releases" */
export type MobileAppReleasesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MobileAppReleasesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MobileAppReleasesStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  reviewBaseHostname?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MobileAppReleaseStatusesEnum>;
  version?: InputMaybe<Scalars['String']>;
};

/** update columns of table "mobile_app_releases" */
export enum MobileAppReleasesUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ReviewBaseHostname = 'reviewBaseHostname',
  /** column name */
  Status = 'status',
  /** column name */
  Version = 'version'
}

export type MobileAppReleasesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MobileAppReleasesSetInput>;
  /** filter the rows which have to be updated */
  where: MobileAppReleasesBoolExp;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  authenticateSalesforce?: Maybe<SalesforceAuthentication>;
  connectThreeSixtyChannels: Array<ThreeSixtyWhatsappAccount>;
  connectVonageChannels: Array<VonageWhatsappAccount>;
  /** delete data from the table: "campaign_audience_types" */
  delete_campaign_audience_types?: Maybe<CampaignAudienceTypesMutationResponse>;
  /** delete single row from the table: "campaign_audience_types" */
  delete_campaign_audience_types_by_pk?: Maybe<CampaignAudienceTypes>;
  /** delete data from the table: "campaign_customer_users" */
  delete_campaign_customer_users?: Maybe<CampaignCustomerUsersMutationResponse>;
  /** delete single row from the table: "campaign_customer_users" */
  delete_campaign_customer_users_by_pk?: Maybe<CampaignCustomerUsers>;
  /** delete data from the table: "campaigns" */
  delete_campaigns?: Maybe<CampaignsMutationResponse>;
  /** delete single row from the table: "campaigns" */
  delete_campaigns_by_pk?: Maybe<Campaigns>;
  /** delete data from the table: "channel_chat_bot_users" */
  delete_channel_chat_bot_users?: Maybe<ChannelChatBotUsersMutationResponse>;
  /** delete single row from the table: "channel_chat_bot_users" */
  delete_channel_chat_bot_users_by_pk?: Maybe<ChannelChatBotUsers>;
  /** delete data from the table: "channel_merchant_users" */
  delete_channel_merchant_users?: Maybe<ChannelMerchantUsersMutationResponse>;
  /** delete single row from the table: "channel_merchant_users" */
  delete_channel_merchant_users_by_pk?: Maybe<ChannelMerchantUsers>;
  /** delete data from the table: "channel_working_hours" */
  delete_channel_working_hours?: Maybe<ChannelWorkingHoursMutationResponse>;
  /** delete single row from the table: "channel_working_hours" */
  delete_channel_working_hours_by_pk?: Maybe<ChannelWorkingHours>;
  /** delete data from the table: "channels" */
  delete_channels?: Maybe<ChannelsMutationResponse>;
  /** delete single row from the table: "channels" */
  delete_channels_by_pk?: Maybe<Channels>;
  /** delete data from the table: "chat_message_chat_bot_autoreply_types" */
  delete_chat_message_chat_bot_autoreply_types?: Maybe<ChatMessageChatBotAutoreplyTypesMutationResponse>;
  /** delete single row from the table: "chat_message_chat_bot_autoreply_types" */
  delete_chat_message_chat_bot_autoreply_types_by_pk?: Maybe<ChatMessageChatBotAutoreplyTypes>;
  /** delete data from the table: "chat_message_file_attachment_translations" */
  delete_chat_message_file_attachment_translations?: Maybe<ChatMessageFileAttachmentTranslationsMutationResponse>;
  /** delete single row from the table: "chat_message_file_attachment_translations" */
  delete_chat_message_file_attachment_translations_by_pk?: Maybe<ChatMessageFileAttachmentTranslations>;
  /** delete data from the table: "chat_message_file_attachments" */
  delete_chat_message_file_attachments?: Maybe<ChatMessageFileAttachmentsMutationResponse>;
  /** delete single row from the table: "chat_message_file_attachments" */
  delete_chat_message_file_attachments_by_pk?: Maybe<ChatMessageFileAttachments>;
  /** delete data from the table: "chat_message_template_placeholder_types" */
  delete_chat_message_template_placeholder_types?: Maybe<ChatMessageTemplatePlaceholderTypesMutationResponse>;
  /** delete single row from the table: "chat_message_template_placeholder_types" */
  delete_chat_message_template_placeholder_types_by_pk?: Maybe<ChatMessageTemplatePlaceholderTypes>;
  /** delete data from the table: "chat_message_template_placeholders" */
  delete_chat_message_template_placeholders?: Maybe<ChatMessageTemplatePlaceholdersMutationResponse>;
  /** delete single row from the table: "chat_message_template_placeholders" */
  delete_chat_message_template_placeholders_by_pk?: Maybe<ChatMessageTemplatePlaceholders>;
  /** delete data from the table: "chat_message_template_types" */
  delete_chat_message_template_types?: Maybe<ChatMessageTemplateTypesMutationResponse>;
  /** delete single row from the table: "chat_message_template_types" */
  delete_chat_message_template_types_by_pk?: Maybe<ChatMessageTemplateTypes>;
  /** delete data from the table: "chat_message_template_values" */
  delete_chat_message_template_values?: Maybe<ChatMessageTemplateValuesMutationResponse>;
  /** delete single row from the table: "chat_message_template_values" */
  delete_chat_message_template_values_by_pk?: Maybe<ChatMessageTemplateValues>;
  /** delete data from the table: "chat_message_templates" */
  delete_chat_message_templates?: Maybe<ChatMessageTemplatesMutationResponse>;
  /** delete single row from the table: "chat_message_templates" */
  delete_chat_message_templates_by_pk?: Maybe<ChatMessageTemplates>;
  /** delete data from the table: "chat_message_translations" */
  delete_chat_message_translations?: Maybe<ChatMessageTranslationsMutationResponse>;
  /** delete single row from the table: "chat_message_translations" */
  delete_chat_message_translations_by_pk?: Maybe<ChatMessageTranslations>;
  /** delete data from the table: "chat_message_types" */
  delete_chat_message_types?: Maybe<ChatMessageTypesMutationResponse>;
  /** delete single row from the table: "chat_message_types" */
  delete_chat_message_types_by_pk?: Maybe<ChatMessageTypes>;
  /** delete data from the table: "chat_message_whatsapp_messages" */
  delete_chat_message_whatsapp_messages?: Maybe<ChatMessageWhatsappMessagesMutationResponse>;
  /** delete single row from the table: "chat_message_whatsapp_messages" */
  delete_chat_message_whatsapp_messages_by_pk?: Maybe<ChatMessageWhatsappMessages>;
  /** delete data from the table: "chat_messages" */
  delete_chat_messages?: Maybe<ChatMessagesMutationResponse>;
  /** delete single row from the table: "chat_messages" */
  delete_chat_messages_by_pk?: Maybe<ChatMessages>;
  /** delete data from the table: "chat_messages_event_logs" */
  delete_chat_messages_event_logs?: Maybe<ChatMessagesEventLogsMutationResponse>;
  /** delete single row from the table: "chat_messages_event_logs" */
  delete_chat_messages_event_logs_by_pk?: Maybe<ChatMessagesEventLogs>;
  /** delete data from the table: "chat_summaries" */
  delete_chat_summaries?: Maybe<ChatSummariesMutationResponse>;
  /** delete single row from the table: "chat_summaries" */
  delete_chat_summaries_by_pk?: Maybe<ChatSummaries>;
  /** delete data from the table: "chat_types" */
  delete_chat_types?: Maybe<ChatTypesMutationResponse>;
  /** delete single row from the table: "chat_types" */
  delete_chat_types_by_pk?: Maybe<ChatTypes>;
  /** delete data from the table: "chat_users" */
  delete_chat_users?: Maybe<ChatUsersMutationResponse>;
  /** delete single row from the table: "chat_users" */
  delete_chat_users_by_pk?: Maybe<ChatUsers>;
  /** delete data from the table: "chats" */
  delete_chats?: Maybe<ChatsMutationResponse>;
  /** delete single row from the table: "chats" */
  delete_chats_by_pk?: Maybe<Chats>;
  /** delete data from the table: "chats_event_logs" */
  delete_chats_event_logs?: Maybe<ChatsEventLogsMutationResponse>;
  /** delete single row from the table: "chats_event_logs" */
  delete_chats_event_logs_by_pk?: Maybe<ChatsEventLogs>;
  /** delete data from the table: "contact_groups" */
  delete_contact_groups?: Maybe<ContactGroupsMutationResponse>;
  /** delete single row from the table: "contact_groups" */
  delete_contact_groups_by_pk?: Maybe<ContactGroups>;
  /** delete data from the table: "customer_users" */
  delete_customer_users?: Maybe<CustomerUsersMutationResponse>;
  /** delete single row from the table: "customer_users" */
  delete_customer_users_by_pk?: Maybe<CustomerUsers>;
  /** delete data from the table: "email_notification_preferences" */
  delete_email_notification_preferences?: Maybe<EmailNotificationPreferencesMutationResponse>;
  /** delete single row from the table: "email_notification_preferences" */
  delete_email_notification_preferences_by_pk?: Maybe<EmailNotificationPreferences>;
  /** delete data from the table: "file_mime_types" */
  delete_file_mime_types?: Maybe<FileMimeTypesMutationResponse>;
  /** delete single row from the table: "file_mime_types" */
  delete_file_mime_types_by_pk?: Maybe<FileMimeTypes>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<FilesMutationResponse>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "merchant_api_users" */
  delete_merchant_api_users?: Maybe<MerchantApiUsersMutationResponse>;
  /** delete single row from the table: "merchant_api_users" */
  delete_merchant_api_users_by_pk?: Maybe<MerchantApiUsers>;
  /** delete data from the table: "merchant_customer_user_contact_groups" */
  delete_merchant_customer_user_contact_groups?: Maybe<MerchantCustomerUserContactGroupsMutationResponse>;
  /** delete single row from the table: "merchant_customer_user_contact_groups" */
  delete_merchant_customer_user_contact_groups_by_pk?: Maybe<MerchantCustomerUserContactGroups>;
  /** delete data from the table: "merchant_customer_user_tags" */
  delete_merchant_customer_user_tags?: Maybe<MerchantCustomerUserTagsMutationResponse>;
  /** delete single row from the table: "merchant_customer_user_tags" */
  delete_merchant_customer_user_tags_by_pk?: Maybe<MerchantCustomerUserTags>;
  /** delete data from the table: "merchant_customer_users" */
  delete_merchant_customer_users?: Maybe<MerchantCustomerUsersMutationResponse>;
  /** delete single row from the table: "merchant_customer_users" */
  delete_merchant_customer_users_by_pk?: Maybe<MerchantCustomerUsers>;
  /** delete data from the table: "merchant_mobile_app_installations" */
  delete_merchant_mobile_app_installations?: Maybe<MerchantMobileAppInstallationsMutationResponse>;
  /** delete single row from the table: "merchant_mobile_app_installations" */
  delete_merchant_mobile_app_installations_by_pk?: Maybe<MerchantMobileAppInstallations>;
  /** delete data from the table: "merchant_salesforce_sync_configurations" */
  delete_merchant_salesforce_sync_configurations?: Maybe<MerchantSalesforceSyncConfigurationsMutationResponse>;
  /** delete single row from the table: "merchant_salesforce_sync_configurations" */
  delete_merchant_salesforce_sync_configurations_by_pk?: Maybe<MerchantSalesforceSyncConfigurations>;
  /** delete data from the table: "merchant_store_merchant_users" */
  delete_merchant_store_merchant_users?: Maybe<MerchantStoreMerchantUsersMutationResponse>;
  /** delete single row from the table: "merchant_store_merchant_users" */
  delete_merchant_store_merchant_users_by_pk?: Maybe<MerchantStoreMerchantUsers>;
  /** delete data from the table: "merchant_stores" */
  delete_merchant_stores?: Maybe<MerchantStoresMutationResponse>;
  /** delete single row from the table: "merchant_stores" */
  delete_merchant_stores_by_pk?: Maybe<MerchantStores>;
  /** delete data from the table: "merchant_user_contact_groups" */
  delete_merchant_user_contact_groups?: Maybe<MerchantUserContactGroupsMutationResponse>;
  /** delete single row from the table: "merchant_user_contact_groups" */
  delete_merchant_user_contact_groups_by_pk?: Maybe<MerchantUserContactGroups>;
  /** delete data from the table: "merchant_user_password_resets" */
  delete_merchant_user_password_resets?: Maybe<MerchantUserPasswordResetsMutationResponse>;
  /** delete single row from the table: "merchant_user_password_resets" */
  delete_merchant_user_password_resets_by_pk?: Maybe<MerchantUserPasswordResets>;
  /** delete data from the table: "merchant_users" */
  delete_merchant_users?: Maybe<MerchantUsersMutationResponse>;
  /** delete single row from the table: "merchant_users" */
  delete_merchant_users_by_pk?: Maybe<MerchantUsers>;
  /** delete data from the table: "merchants" */
  delete_merchants?: Maybe<MerchantsMutationResponse>;
  /** delete single row from the table: "merchants" */
  delete_merchants_by_pk?: Maybe<Merchants>;
  /** delete data from the table: "mobile_app_platforms" */
  delete_mobile_app_platforms?: Maybe<MobileAppPlatformsMutationResponse>;
  /** delete single row from the table: "mobile_app_platforms" */
  delete_mobile_app_platforms_by_pk?: Maybe<MobileAppPlatforms>;
  /** delete data from the table: "mobile_app_release_statuses" */
  delete_mobile_app_release_statuses?: Maybe<MobileAppReleaseStatusesMutationResponse>;
  /** delete single row from the table: "mobile_app_release_statuses" */
  delete_mobile_app_release_statuses_by_pk?: Maybe<MobileAppReleaseStatuses>;
  /** delete data from the table: "mobile_app_releases" */
  delete_mobile_app_releases?: Maybe<MobileAppReleasesMutationResponse>;
  /** delete single row from the table: "mobile_app_releases" */
  delete_mobile_app_releases_by_pk?: Maybe<MobileAppReleases>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<OrganizationsMutationResponse>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "salesforce_record_attributes" */
  delete_salesforce_record_attributes?: Maybe<SalesforceRecordAttributesMutationResponse>;
  /** delete single row from the table: "salesforce_record_attributes" */
  delete_salesforce_record_attributes_by_pk?: Maybe<SalesforceRecordAttributes>;
  /** delete data from the table: "secondary_responsible_merchant_users" */
  delete_secondary_responsible_merchant_users?: Maybe<SecondaryResponsibleMerchantUsersMutationResponse>;
  /** delete single row from the table: "secondary_responsible_merchant_users" */
  delete_secondary_responsible_merchant_users_by_pk?: Maybe<SecondaryResponsibleMerchantUsers>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<TagsMutationResponse>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "toast_messages" */
  delete_toast_messages?: Maybe<ToastMessagesMutationResponse>;
  /** delete single row from the table: "toast_messages" */
  delete_toast_messages_by_pk?: Maybe<ToastMessages>;
  /** delete data from the table: "user_chats" */
  delete_user_chats?: Maybe<UserChatsMutationResponse>;
  /** delete single row from the table: "user_chats" */
  delete_user_chats_by_pk?: Maybe<UserChats>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "whatsapp_account_hosting_types" */
  delete_whatsapp_account_hosting_types?: Maybe<WhatsappAccountHostingTypesMutationResponse>;
  /** delete single row from the table: "whatsapp_account_hosting_types" */
  delete_whatsapp_account_hosting_types_by_pk?: Maybe<WhatsappAccountHostingTypes>;
  /** delete data from the table: "whatsapp_account_providers" */
  delete_whatsapp_account_providers?: Maybe<WhatsappAccountProvidersMutationResponse>;
  /** delete single row from the table: "whatsapp_account_providers" */
  delete_whatsapp_account_providers_by_pk?: Maybe<WhatsappAccountProviders>;
  /** delete data from the table: "whatsapp_accounts" */
  delete_whatsapp_accounts?: Maybe<WhatsappAccountsMutationResponse>;
  /** delete single row from the table: "whatsapp_accounts" */
  delete_whatsapp_accounts_by_pk?: Maybe<WhatsappAccounts>;
  /** delete data from the table: "whatsapp_business_accounts" */
  delete_whatsapp_business_accounts?: Maybe<WhatsappBusinessAccountsMutationResponse>;
  /** delete single row from the table: "whatsapp_business_accounts" */
  delete_whatsapp_business_accounts_by_pk?: Maybe<WhatsappBusinessAccounts>;
  /** delete data from the table: "whatsapp_business_verticals" */
  delete_whatsapp_business_verticals?: Maybe<WhatsappBusinessVerticalsMutationResponse>;
  /** delete single row from the table: "whatsapp_business_verticals" */
  delete_whatsapp_business_verticals_by_pk?: Maybe<WhatsappBusinessVerticals>;
  /** delete data from the table: "whatsapp_conversation_types" */
  delete_whatsapp_conversation_types?: Maybe<WhatsappConversationTypesMutationResponse>;
  /** delete single row from the table: "whatsapp_conversation_types" */
  delete_whatsapp_conversation_types_by_pk?: Maybe<WhatsappConversationTypes>;
  /** delete data from the table: "whatsapp_conversations" */
  delete_whatsapp_conversations?: Maybe<WhatsappConversationsMutationResponse>;
  /** delete single row from the table: "whatsapp_conversations" */
  delete_whatsapp_conversations_by_pk?: Maybe<WhatsappConversations>;
  /** delete data from the table: "whatsapp_media_types" */
  delete_whatsapp_media_types?: Maybe<WhatsappMediaTypesMutationResponse>;
  /** delete single row from the table: "whatsapp_media_types" */
  delete_whatsapp_media_types_by_pk?: Maybe<WhatsappMediaTypes>;
  /** delete data from the table: "whatsapp_message_directions" */
  delete_whatsapp_message_directions?: Maybe<WhatsappMessageDirectionsMutationResponse>;
  /** delete single row from the table: "whatsapp_message_directions" */
  delete_whatsapp_message_directions_by_pk?: Maybe<WhatsappMessageDirections>;
  /** delete data from the table: "whatsapp_message_statuses" */
  delete_whatsapp_message_statuses?: Maybe<WhatsappMessageStatusesMutationResponse>;
  /** delete single row from the table: "whatsapp_message_statuses" */
  delete_whatsapp_message_statuses_by_pk?: Maybe<WhatsappMessageStatuses>;
  /** delete data from the table: "whatsapp_message_template_statuses" */
  delete_whatsapp_message_template_statuses?: Maybe<WhatsappMessageTemplateStatusesMutationResponse>;
  /** delete single row from the table: "whatsapp_message_template_statuses" */
  delete_whatsapp_message_template_statuses_by_pk?: Maybe<WhatsappMessageTemplateStatuses>;
  /** delete data from the table: "whatsapp_message_template_values" */
  delete_whatsapp_message_template_values?: Maybe<WhatsappMessageTemplateValuesMutationResponse>;
  /** delete single row from the table: "whatsapp_message_template_values" */
  delete_whatsapp_message_template_values_by_pk?: Maybe<WhatsappMessageTemplateValues>;
  /** delete data from the table: "whatsapp_message_templates" */
  delete_whatsapp_message_templates?: Maybe<WhatsappMessageTemplatesMutationResponse>;
  /** delete single row from the table: "whatsapp_message_templates" */
  delete_whatsapp_message_templates_by_pk?: Maybe<WhatsappMessageTemplates>;
  /** delete data from the table: "whatsapp_message_types" */
  delete_whatsapp_message_types?: Maybe<WhatsappMessageTypesMutationResponse>;
  /** delete single row from the table: "whatsapp_message_types" */
  delete_whatsapp_message_types_by_pk?: Maybe<WhatsappMessageTypes>;
  /** delete data from the table: "whatsapp_messages" */
  delete_whatsapp_messages?: Maybe<WhatsappMessagesMutationResponse>;
  /** delete single row from the table: "whatsapp_messages" */
  delete_whatsapp_messages_by_pk?: Maybe<WhatsappMessages>;
  exportCampaignMessagesMetadata?: Maybe<ExportCampaignMessagesMetadataResult>;
  importMerchantCustomerUsers?: Maybe<MerchantCustomerUsersImport>;
  importMerchantUsers: Array<MerchantUser>;
  importWhatsappChat?: Maybe<ChatResult>;
  insertChatMessage?: Maybe<InsertChatMessageResult>;
  insertChatMessageTemplate?: Maybe<InsertChatMessageTemplate>;
  /** insert data into the table: "campaign_audience_types" */
  insert_campaign_audience_types?: Maybe<CampaignAudienceTypesMutationResponse>;
  /** insert a single row into the table: "campaign_audience_types" */
  insert_campaign_audience_types_one?: Maybe<CampaignAudienceTypes>;
  /** insert data into the table: "campaign_customer_users" */
  insert_campaign_customer_users?: Maybe<CampaignCustomerUsersMutationResponse>;
  /** insert a single row into the table: "campaign_customer_users" */
  insert_campaign_customer_users_one?: Maybe<CampaignCustomerUsers>;
  /** insert data into the table: "campaigns" */
  insert_campaigns?: Maybe<CampaignsMutationResponse>;
  /** insert a single row into the table: "campaigns" */
  insert_campaigns_one?: Maybe<Campaigns>;
  /** insert data into the table: "channel_chat_bot_users" */
  insert_channel_chat_bot_users?: Maybe<ChannelChatBotUsersMutationResponse>;
  /** insert a single row into the table: "channel_chat_bot_users" */
  insert_channel_chat_bot_users_one?: Maybe<ChannelChatBotUsers>;
  /** insert data into the table: "channel_merchant_users" */
  insert_channel_merchant_users?: Maybe<ChannelMerchantUsersMutationResponse>;
  /** insert a single row into the table: "channel_merchant_users" */
  insert_channel_merchant_users_one?: Maybe<ChannelMerchantUsers>;
  /** insert data into the table: "channel_working_hours" */
  insert_channel_working_hours?: Maybe<ChannelWorkingHoursMutationResponse>;
  /** insert a single row into the table: "channel_working_hours" */
  insert_channel_working_hours_one?: Maybe<ChannelWorkingHours>;
  /** insert data into the table: "channels" */
  insert_channels?: Maybe<ChannelsMutationResponse>;
  /** insert a single row into the table: "channels" */
  insert_channels_one?: Maybe<Channels>;
  /** insert data into the table: "chat_message_chat_bot_autoreply_types" */
  insert_chat_message_chat_bot_autoreply_types?: Maybe<ChatMessageChatBotAutoreplyTypesMutationResponse>;
  /** insert a single row into the table: "chat_message_chat_bot_autoreply_types" */
  insert_chat_message_chat_bot_autoreply_types_one?: Maybe<ChatMessageChatBotAutoreplyTypes>;
  /** insert data into the table: "chat_message_file_attachment_translations" */
  insert_chat_message_file_attachment_translations?: Maybe<ChatMessageFileAttachmentTranslationsMutationResponse>;
  /** insert a single row into the table: "chat_message_file_attachment_translations" */
  insert_chat_message_file_attachment_translations_one?: Maybe<ChatMessageFileAttachmentTranslations>;
  /** insert data into the table: "chat_message_file_attachments" */
  insert_chat_message_file_attachments?: Maybe<ChatMessageFileAttachmentsMutationResponse>;
  /** insert a single row into the table: "chat_message_file_attachments" */
  insert_chat_message_file_attachments_one?: Maybe<ChatMessageFileAttachments>;
  /** insert data into the table: "chat_message_template_placeholder_types" */
  insert_chat_message_template_placeholder_types?: Maybe<ChatMessageTemplatePlaceholderTypesMutationResponse>;
  /** insert a single row into the table: "chat_message_template_placeholder_types" */
  insert_chat_message_template_placeholder_types_one?: Maybe<ChatMessageTemplatePlaceholderTypes>;
  /** insert data into the table: "chat_message_template_placeholders" */
  insert_chat_message_template_placeholders?: Maybe<ChatMessageTemplatePlaceholdersMutationResponse>;
  /** insert a single row into the table: "chat_message_template_placeholders" */
  insert_chat_message_template_placeholders_one?: Maybe<ChatMessageTemplatePlaceholders>;
  /** insert data into the table: "chat_message_template_types" */
  insert_chat_message_template_types?: Maybe<ChatMessageTemplateTypesMutationResponse>;
  /** insert a single row into the table: "chat_message_template_types" */
  insert_chat_message_template_types_one?: Maybe<ChatMessageTemplateTypes>;
  /** insert data into the table: "chat_message_template_values" */
  insert_chat_message_template_values?: Maybe<ChatMessageTemplateValuesMutationResponse>;
  /** insert a single row into the table: "chat_message_template_values" */
  insert_chat_message_template_values_one?: Maybe<ChatMessageTemplateValues>;
  /** insert data into the table: "chat_message_templates" */
  insert_chat_message_templates?: Maybe<ChatMessageTemplatesMutationResponse>;
  /** insert a single row into the table: "chat_message_templates" */
  insert_chat_message_templates_one?: Maybe<ChatMessageTemplates>;
  /** insert data into the table: "chat_message_translations" */
  insert_chat_message_translations?: Maybe<ChatMessageTranslationsMutationResponse>;
  /** insert a single row into the table: "chat_message_translations" */
  insert_chat_message_translations_one?: Maybe<ChatMessageTranslations>;
  /** insert data into the table: "chat_message_types" */
  insert_chat_message_types?: Maybe<ChatMessageTypesMutationResponse>;
  /** insert a single row into the table: "chat_message_types" */
  insert_chat_message_types_one?: Maybe<ChatMessageTypes>;
  /** insert data into the table: "chat_message_whatsapp_messages" */
  insert_chat_message_whatsapp_messages?: Maybe<ChatMessageWhatsappMessagesMutationResponse>;
  /** insert a single row into the table: "chat_message_whatsapp_messages" */
  insert_chat_message_whatsapp_messages_one?: Maybe<ChatMessageWhatsappMessages>;
  /** insert data into the table: "chat_messages" */
  insert_chat_messages?: Maybe<ChatMessagesMutationResponse>;
  /** insert data into the table: "chat_messages_event_logs" */
  insert_chat_messages_event_logs?: Maybe<ChatMessagesEventLogsMutationResponse>;
  /** insert a single row into the table: "chat_messages_event_logs" */
  insert_chat_messages_event_logs_one?: Maybe<ChatMessagesEventLogs>;
  /** insert a single row into the table: "chat_messages" */
  insert_chat_messages_one?: Maybe<ChatMessages>;
  /** insert data into the table: "chat_summaries" */
  insert_chat_summaries?: Maybe<ChatSummariesMutationResponse>;
  /** insert a single row into the table: "chat_summaries" */
  insert_chat_summaries_one?: Maybe<ChatSummaries>;
  /** insert data into the table: "chat_types" */
  insert_chat_types?: Maybe<ChatTypesMutationResponse>;
  /** insert a single row into the table: "chat_types" */
  insert_chat_types_one?: Maybe<ChatTypes>;
  /** insert data into the table: "chat_users" */
  insert_chat_users?: Maybe<ChatUsersMutationResponse>;
  /** insert a single row into the table: "chat_users" */
  insert_chat_users_one?: Maybe<ChatUsers>;
  /** insert data into the table: "chats" */
  insert_chats?: Maybe<ChatsMutationResponse>;
  /** insert data into the table: "chats_event_logs" */
  insert_chats_event_logs?: Maybe<ChatsEventLogsMutationResponse>;
  /** insert a single row into the table: "chats_event_logs" */
  insert_chats_event_logs_one?: Maybe<ChatsEventLogs>;
  /** insert a single row into the table: "chats" */
  insert_chats_one?: Maybe<Chats>;
  /** insert data into the table: "contact_groups" */
  insert_contact_groups?: Maybe<ContactGroupsMutationResponse>;
  /** insert a single row into the table: "contact_groups" */
  insert_contact_groups_one?: Maybe<ContactGroups>;
  /** insert data into the table: "customer_users" */
  insert_customer_users?: Maybe<CustomerUsersMutationResponse>;
  /** insert a single row into the table: "customer_users" */
  insert_customer_users_one?: Maybe<CustomerUsers>;
  /** insert data into the table: "email_notification_preferences" */
  insert_email_notification_preferences?: Maybe<EmailNotificationPreferencesMutationResponse>;
  /** insert a single row into the table: "email_notification_preferences" */
  insert_email_notification_preferences_one?: Maybe<EmailNotificationPreferences>;
  /** insert data into the table: "file_mime_types" */
  insert_file_mime_types?: Maybe<FileMimeTypesMutationResponse>;
  /** insert a single row into the table: "file_mime_types" */
  insert_file_mime_types_one?: Maybe<FileMimeTypes>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<FilesMutationResponse>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "merchant_api_users" */
  insert_merchant_api_users?: Maybe<MerchantApiUsersMutationResponse>;
  /** insert a single row into the table: "merchant_api_users" */
  insert_merchant_api_users_one?: Maybe<MerchantApiUsers>;
  /** insert data into the table: "merchant_customer_user_contact_groups" */
  insert_merchant_customer_user_contact_groups?: Maybe<MerchantCustomerUserContactGroupsMutationResponse>;
  /** insert a single row into the table: "merchant_customer_user_contact_groups" */
  insert_merchant_customer_user_contact_groups_one?: Maybe<MerchantCustomerUserContactGroups>;
  /** insert data into the table: "merchant_customer_user_tags" */
  insert_merchant_customer_user_tags?: Maybe<MerchantCustomerUserTagsMutationResponse>;
  /** insert a single row into the table: "merchant_customer_user_tags" */
  insert_merchant_customer_user_tags_one?: Maybe<MerchantCustomerUserTags>;
  /** insert data into the table: "merchant_customer_users" */
  insert_merchant_customer_users?: Maybe<MerchantCustomerUsersMutationResponse>;
  /** insert a single row into the table: "merchant_customer_users" */
  insert_merchant_customer_users_one?: Maybe<MerchantCustomerUsers>;
  /** insert data into the table: "merchant_mobile_app_installations" */
  insert_merchant_mobile_app_installations?: Maybe<MerchantMobileAppInstallationsMutationResponse>;
  /** insert a single row into the table: "merchant_mobile_app_installations" */
  insert_merchant_mobile_app_installations_one?: Maybe<MerchantMobileAppInstallations>;
  /** insert data into the table: "merchant_salesforce_sync_configurations" */
  insert_merchant_salesforce_sync_configurations?: Maybe<MerchantSalesforceSyncConfigurationsMutationResponse>;
  /** insert a single row into the table: "merchant_salesforce_sync_configurations" */
  insert_merchant_salesforce_sync_configurations_one?: Maybe<MerchantSalesforceSyncConfigurations>;
  /** insert data into the table: "merchant_store_merchant_users" */
  insert_merchant_store_merchant_users?: Maybe<MerchantStoreMerchantUsersMutationResponse>;
  /** insert a single row into the table: "merchant_store_merchant_users" */
  insert_merchant_store_merchant_users_one?: Maybe<MerchantStoreMerchantUsers>;
  /** insert data into the table: "merchant_stores" */
  insert_merchant_stores?: Maybe<MerchantStoresMutationResponse>;
  /** insert a single row into the table: "merchant_stores" */
  insert_merchant_stores_one?: Maybe<MerchantStores>;
  /** insert data into the table: "merchant_user_contact_groups" */
  insert_merchant_user_contact_groups?: Maybe<MerchantUserContactGroupsMutationResponse>;
  /** insert a single row into the table: "merchant_user_contact_groups" */
  insert_merchant_user_contact_groups_one?: Maybe<MerchantUserContactGroups>;
  /** insert data into the table: "merchant_user_password_resets" */
  insert_merchant_user_password_resets?: Maybe<MerchantUserPasswordResetsMutationResponse>;
  /** insert a single row into the table: "merchant_user_password_resets" */
  insert_merchant_user_password_resets_one?: Maybe<MerchantUserPasswordResets>;
  /** insert data into the table: "merchant_users" */
  insert_merchant_users?: Maybe<MerchantUsersMutationResponse>;
  /** insert a single row into the table: "merchant_users" */
  insert_merchant_users_one?: Maybe<MerchantUsers>;
  /** insert data into the table: "merchants" */
  insert_merchants?: Maybe<MerchantsMutationResponse>;
  /** insert a single row into the table: "merchants" */
  insert_merchants_one?: Maybe<Merchants>;
  /** insert data into the table: "mobile_app_platforms" */
  insert_mobile_app_platforms?: Maybe<MobileAppPlatformsMutationResponse>;
  /** insert a single row into the table: "mobile_app_platforms" */
  insert_mobile_app_platforms_one?: Maybe<MobileAppPlatforms>;
  /** insert data into the table: "mobile_app_release_statuses" */
  insert_mobile_app_release_statuses?: Maybe<MobileAppReleaseStatusesMutationResponse>;
  /** insert a single row into the table: "mobile_app_release_statuses" */
  insert_mobile_app_release_statuses_one?: Maybe<MobileAppReleaseStatuses>;
  /** insert data into the table: "mobile_app_releases" */
  insert_mobile_app_releases?: Maybe<MobileAppReleasesMutationResponse>;
  /** insert a single row into the table: "mobile_app_releases" */
  insert_mobile_app_releases_one?: Maybe<MobileAppReleases>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<OrganizationsMutationResponse>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "salesforce_record_attributes" */
  insert_salesforce_record_attributes?: Maybe<SalesforceRecordAttributesMutationResponse>;
  /** insert a single row into the table: "salesforce_record_attributes" */
  insert_salesforce_record_attributes_one?: Maybe<SalesforceRecordAttributes>;
  /** insert data into the table: "secondary_responsible_merchant_users" */
  insert_secondary_responsible_merchant_users?: Maybe<SecondaryResponsibleMerchantUsersMutationResponse>;
  /** insert a single row into the table: "secondary_responsible_merchant_users" */
  insert_secondary_responsible_merchant_users_one?: Maybe<SecondaryResponsibleMerchantUsers>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<TagsMutationResponse>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "toast_messages" */
  insert_toast_messages?: Maybe<ToastMessagesMutationResponse>;
  /** insert a single row into the table: "toast_messages" */
  insert_toast_messages_one?: Maybe<ToastMessages>;
  /** insert data into the table: "user_chats" */
  insert_user_chats?: Maybe<UserChatsMutationResponse>;
  /** insert a single row into the table: "user_chats" */
  insert_user_chats_one?: Maybe<UserChats>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "whatsapp_account_hosting_types" */
  insert_whatsapp_account_hosting_types?: Maybe<WhatsappAccountHostingTypesMutationResponse>;
  /** insert a single row into the table: "whatsapp_account_hosting_types" */
  insert_whatsapp_account_hosting_types_one?: Maybe<WhatsappAccountHostingTypes>;
  /** insert data into the table: "whatsapp_account_providers" */
  insert_whatsapp_account_providers?: Maybe<WhatsappAccountProvidersMutationResponse>;
  /** insert a single row into the table: "whatsapp_account_providers" */
  insert_whatsapp_account_providers_one?: Maybe<WhatsappAccountProviders>;
  /** insert data into the table: "whatsapp_accounts" */
  insert_whatsapp_accounts?: Maybe<WhatsappAccountsMutationResponse>;
  /** insert a single row into the table: "whatsapp_accounts" */
  insert_whatsapp_accounts_one?: Maybe<WhatsappAccounts>;
  /** insert data into the table: "whatsapp_business_accounts" */
  insert_whatsapp_business_accounts?: Maybe<WhatsappBusinessAccountsMutationResponse>;
  /** insert a single row into the table: "whatsapp_business_accounts" */
  insert_whatsapp_business_accounts_one?: Maybe<WhatsappBusinessAccounts>;
  /** insert data into the table: "whatsapp_business_verticals" */
  insert_whatsapp_business_verticals?: Maybe<WhatsappBusinessVerticalsMutationResponse>;
  /** insert a single row into the table: "whatsapp_business_verticals" */
  insert_whatsapp_business_verticals_one?: Maybe<WhatsappBusinessVerticals>;
  /** insert data into the table: "whatsapp_conversation_types" */
  insert_whatsapp_conversation_types?: Maybe<WhatsappConversationTypesMutationResponse>;
  /** insert a single row into the table: "whatsapp_conversation_types" */
  insert_whatsapp_conversation_types_one?: Maybe<WhatsappConversationTypes>;
  /** insert data into the table: "whatsapp_conversations" */
  insert_whatsapp_conversations?: Maybe<WhatsappConversationsMutationResponse>;
  /** insert a single row into the table: "whatsapp_conversations" */
  insert_whatsapp_conversations_one?: Maybe<WhatsappConversations>;
  /** insert data into the table: "whatsapp_media_types" */
  insert_whatsapp_media_types?: Maybe<WhatsappMediaTypesMutationResponse>;
  /** insert a single row into the table: "whatsapp_media_types" */
  insert_whatsapp_media_types_one?: Maybe<WhatsappMediaTypes>;
  /** insert data into the table: "whatsapp_message_directions" */
  insert_whatsapp_message_directions?: Maybe<WhatsappMessageDirectionsMutationResponse>;
  /** insert a single row into the table: "whatsapp_message_directions" */
  insert_whatsapp_message_directions_one?: Maybe<WhatsappMessageDirections>;
  /** insert data into the table: "whatsapp_message_statuses" */
  insert_whatsapp_message_statuses?: Maybe<WhatsappMessageStatusesMutationResponse>;
  /** insert a single row into the table: "whatsapp_message_statuses" */
  insert_whatsapp_message_statuses_one?: Maybe<WhatsappMessageStatuses>;
  /** insert data into the table: "whatsapp_message_template_statuses" */
  insert_whatsapp_message_template_statuses?: Maybe<WhatsappMessageTemplateStatusesMutationResponse>;
  /** insert a single row into the table: "whatsapp_message_template_statuses" */
  insert_whatsapp_message_template_statuses_one?: Maybe<WhatsappMessageTemplateStatuses>;
  /** insert data into the table: "whatsapp_message_template_values" */
  insert_whatsapp_message_template_values?: Maybe<WhatsappMessageTemplateValuesMutationResponse>;
  /** insert a single row into the table: "whatsapp_message_template_values" */
  insert_whatsapp_message_template_values_one?: Maybe<WhatsappMessageTemplateValues>;
  /** insert data into the table: "whatsapp_message_templates" */
  insert_whatsapp_message_templates?: Maybe<WhatsappMessageTemplatesMutationResponse>;
  /** insert a single row into the table: "whatsapp_message_templates" */
  insert_whatsapp_message_templates_one?: Maybe<WhatsappMessageTemplates>;
  /** insert data into the table: "whatsapp_message_types" */
  insert_whatsapp_message_types?: Maybe<WhatsappMessageTypesMutationResponse>;
  /** insert a single row into the table: "whatsapp_message_types" */
  insert_whatsapp_message_types_one?: Maybe<WhatsappMessageTypes>;
  /** insert data into the table: "whatsapp_messages" */
  insert_whatsapp_messages?: Maybe<WhatsappMessagesMutationResponse>;
  /** insert a single row into the table: "whatsapp_messages" */
  insert_whatsapp_messages_one?: Maybe<WhatsappMessages>;
  issueOauthTokens?: Maybe<OauthTokens>;
  refreshAccessToken?: Maybe<OauthTokens>;
  requestPasswordReset: Scalars['Void'];
  requestSandboxConversion: Scalars['Void'];
  resetPassword: Scalars['Void'];
  resetWhatsappAccountWebhookUrl: Scalars['Void'];
  /** restoreMerchantUser */
  restoreMerchantUser: RestoreMerchantUserResult;
  saveCompanyDetails: Scalars['Void'];
  signup?: Maybe<OauthTokens>;
  startChat?: Maybe<ChatStart>;
  summarizeChat: ChatSummary;
  updateAllWhatsappAccountBusinessProfiles: Array<WhatsappAccount>;
  updateMerchantUserEmailAddress: Scalars['Void'];
  updateWhatsappAccountBusinessProfile: WhatsappAccount;
  /** update data of the table: "campaign_audience_types" */
  update_campaign_audience_types?: Maybe<CampaignAudienceTypesMutationResponse>;
  /** update single row of the table: "campaign_audience_types" */
  update_campaign_audience_types_by_pk?: Maybe<CampaignAudienceTypes>;
  /** update multiples rows of table: "campaign_audience_types" */
  update_campaign_audience_types_many?: Maybe<Array<Maybe<CampaignAudienceTypesMutationResponse>>>;
  /** update data of the table: "campaign_customer_users" */
  update_campaign_customer_users?: Maybe<CampaignCustomerUsersMutationResponse>;
  /** update single row of the table: "campaign_customer_users" */
  update_campaign_customer_users_by_pk?: Maybe<CampaignCustomerUsers>;
  /** update multiples rows of table: "campaign_customer_users" */
  update_campaign_customer_users_many?: Maybe<Array<Maybe<CampaignCustomerUsersMutationResponse>>>;
  /** update data of the table: "campaigns" */
  update_campaigns?: Maybe<CampaignsMutationResponse>;
  /** update single row of the table: "campaigns" */
  update_campaigns_by_pk?: Maybe<Campaigns>;
  /** update multiples rows of table: "campaigns" */
  update_campaigns_many?: Maybe<Array<Maybe<CampaignsMutationResponse>>>;
  /** update data of the table: "channel_chat_bot_users" */
  update_channel_chat_bot_users?: Maybe<ChannelChatBotUsersMutationResponse>;
  /** update single row of the table: "channel_chat_bot_users" */
  update_channel_chat_bot_users_by_pk?: Maybe<ChannelChatBotUsers>;
  /** update multiples rows of table: "channel_chat_bot_users" */
  update_channel_chat_bot_users_many?: Maybe<Array<Maybe<ChannelChatBotUsersMutationResponse>>>;
  /** update data of the table: "channel_merchant_users" */
  update_channel_merchant_users?: Maybe<ChannelMerchantUsersMutationResponse>;
  /** update single row of the table: "channel_merchant_users" */
  update_channel_merchant_users_by_pk?: Maybe<ChannelMerchantUsers>;
  /** update multiples rows of table: "channel_merchant_users" */
  update_channel_merchant_users_many?: Maybe<Array<Maybe<ChannelMerchantUsersMutationResponse>>>;
  /** update data of the table: "channel_working_hours" */
  update_channel_working_hours?: Maybe<ChannelWorkingHoursMutationResponse>;
  /** update single row of the table: "channel_working_hours" */
  update_channel_working_hours_by_pk?: Maybe<ChannelWorkingHours>;
  /** update multiples rows of table: "channel_working_hours" */
  update_channel_working_hours_many?: Maybe<Array<Maybe<ChannelWorkingHoursMutationResponse>>>;
  /** update data of the table: "channels" */
  update_channels?: Maybe<ChannelsMutationResponse>;
  /** update single row of the table: "channels" */
  update_channels_by_pk?: Maybe<Channels>;
  /** update multiples rows of table: "channels" */
  update_channels_many?: Maybe<Array<Maybe<ChannelsMutationResponse>>>;
  /** update data of the table: "chat_message_chat_bot_autoreply_types" */
  update_chat_message_chat_bot_autoreply_types?: Maybe<ChatMessageChatBotAutoreplyTypesMutationResponse>;
  /** update single row of the table: "chat_message_chat_bot_autoreply_types" */
  update_chat_message_chat_bot_autoreply_types_by_pk?: Maybe<ChatMessageChatBotAutoreplyTypes>;
  /** update multiples rows of table: "chat_message_chat_bot_autoreply_types" */
  update_chat_message_chat_bot_autoreply_types_many?: Maybe<Array<Maybe<ChatMessageChatBotAutoreplyTypesMutationResponse>>>;
  /** update data of the table: "chat_message_file_attachment_translations" */
  update_chat_message_file_attachment_translations?: Maybe<ChatMessageFileAttachmentTranslationsMutationResponse>;
  /** update single row of the table: "chat_message_file_attachment_translations" */
  update_chat_message_file_attachment_translations_by_pk?: Maybe<ChatMessageFileAttachmentTranslations>;
  /** update multiples rows of table: "chat_message_file_attachment_translations" */
  update_chat_message_file_attachment_translations_many?: Maybe<Array<Maybe<ChatMessageFileAttachmentTranslationsMutationResponse>>>;
  /** update data of the table: "chat_message_file_attachments" */
  update_chat_message_file_attachments?: Maybe<ChatMessageFileAttachmentsMutationResponse>;
  /** update single row of the table: "chat_message_file_attachments" */
  update_chat_message_file_attachments_by_pk?: Maybe<ChatMessageFileAttachments>;
  /** update multiples rows of table: "chat_message_file_attachments" */
  update_chat_message_file_attachments_many?: Maybe<Array<Maybe<ChatMessageFileAttachmentsMutationResponse>>>;
  /** update data of the table: "chat_message_template_placeholder_types" */
  update_chat_message_template_placeholder_types?: Maybe<ChatMessageTemplatePlaceholderTypesMutationResponse>;
  /** update single row of the table: "chat_message_template_placeholder_types" */
  update_chat_message_template_placeholder_types_by_pk?: Maybe<ChatMessageTemplatePlaceholderTypes>;
  /** update multiples rows of table: "chat_message_template_placeholder_types" */
  update_chat_message_template_placeholder_types_many?: Maybe<Array<Maybe<ChatMessageTemplatePlaceholderTypesMutationResponse>>>;
  /** update data of the table: "chat_message_template_placeholders" */
  update_chat_message_template_placeholders?: Maybe<ChatMessageTemplatePlaceholdersMutationResponse>;
  /** update single row of the table: "chat_message_template_placeholders" */
  update_chat_message_template_placeholders_by_pk?: Maybe<ChatMessageTemplatePlaceholders>;
  /** update multiples rows of table: "chat_message_template_placeholders" */
  update_chat_message_template_placeholders_many?: Maybe<Array<Maybe<ChatMessageTemplatePlaceholdersMutationResponse>>>;
  /** update data of the table: "chat_message_template_types" */
  update_chat_message_template_types?: Maybe<ChatMessageTemplateTypesMutationResponse>;
  /** update single row of the table: "chat_message_template_types" */
  update_chat_message_template_types_by_pk?: Maybe<ChatMessageTemplateTypes>;
  /** update multiples rows of table: "chat_message_template_types" */
  update_chat_message_template_types_many?: Maybe<Array<Maybe<ChatMessageTemplateTypesMutationResponse>>>;
  /** update data of the table: "chat_message_template_values" */
  update_chat_message_template_values?: Maybe<ChatMessageTemplateValuesMutationResponse>;
  /** update single row of the table: "chat_message_template_values" */
  update_chat_message_template_values_by_pk?: Maybe<ChatMessageTemplateValues>;
  /** update multiples rows of table: "chat_message_template_values" */
  update_chat_message_template_values_many?: Maybe<Array<Maybe<ChatMessageTemplateValuesMutationResponse>>>;
  /** update data of the table: "chat_message_templates" */
  update_chat_message_templates?: Maybe<ChatMessageTemplatesMutationResponse>;
  /** update single row of the table: "chat_message_templates" */
  update_chat_message_templates_by_pk?: Maybe<ChatMessageTemplates>;
  /** update multiples rows of table: "chat_message_templates" */
  update_chat_message_templates_many?: Maybe<Array<Maybe<ChatMessageTemplatesMutationResponse>>>;
  /** update data of the table: "chat_message_translations" */
  update_chat_message_translations?: Maybe<ChatMessageTranslationsMutationResponse>;
  /** update single row of the table: "chat_message_translations" */
  update_chat_message_translations_by_pk?: Maybe<ChatMessageTranslations>;
  /** update multiples rows of table: "chat_message_translations" */
  update_chat_message_translations_many?: Maybe<Array<Maybe<ChatMessageTranslationsMutationResponse>>>;
  /** update data of the table: "chat_message_types" */
  update_chat_message_types?: Maybe<ChatMessageTypesMutationResponse>;
  /** update single row of the table: "chat_message_types" */
  update_chat_message_types_by_pk?: Maybe<ChatMessageTypes>;
  /** update multiples rows of table: "chat_message_types" */
  update_chat_message_types_many?: Maybe<Array<Maybe<ChatMessageTypesMutationResponse>>>;
  /** update data of the table: "chat_message_whatsapp_messages" */
  update_chat_message_whatsapp_messages?: Maybe<ChatMessageWhatsappMessagesMutationResponse>;
  /** update single row of the table: "chat_message_whatsapp_messages" */
  update_chat_message_whatsapp_messages_by_pk?: Maybe<ChatMessageWhatsappMessages>;
  /** update multiples rows of table: "chat_message_whatsapp_messages" */
  update_chat_message_whatsapp_messages_many?: Maybe<Array<Maybe<ChatMessageWhatsappMessagesMutationResponse>>>;
  /** update data of the table: "chat_messages" */
  update_chat_messages?: Maybe<ChatMessagesMutationResponse>;
  /** update single row of the table: "chat_messages" */
  update_chat_messages_by_pk?: Maybe<ChatMessages>;
  /** update data of the table: "chat_messages_event_logs" */
  update_chat_messages_event_logs?: Maybe<ChatMessagesEventLogsMutationResponse>;
  /** update single row of the table: "chat_messages_event_logs" */
  update_chat_messages_event_logs_by_pk?: Maybe<ChatMessagesEventLogs>;
  /** update multiples rows of table: "chat_messages_event_logs" */
  update_chat_messages_event_logs_many?: Maybe<Array<Maybe<ChatMessagesEventLogsMutationResponse>>>;
  /** update multiples rows of table: "chat_messages" */
  update_chat_messages_many?: Maybe<Array<Maybe<ChatMessagesMutationResponse>>>;
  /** update data of the table: "chat_summaries" */
  update_chat_summaries?: Maybe<ChatSummariesMutationResponse>;
  /** update single row of the table: "chat_summaries" */
  update_chat_summaries_by_pk?: Maybe<ChatSummaries>;
  /** update multiples rows of table: "chat_summaries" */
  update_chat_summaries_many?: Maybe<Array<Maybe<ChatSummariesMutationResponse>>>;
  /** update data of the table: "chat_types" */
  update_chat_types?: Maybe<ChatTypesMutationResponse>;
  /** update single row of the table: "chat_types" */
  update_chat_types_by_pk?: Maybe<ChatTypes>;
  /** update multiples rows of table: "chat_types" */
  update_chat_types_many?: Maybe<Array<Maybe<ChatTypesMutationResponse>>>;
  /** update data of the table: "chat_users" */
  update_chat_users?: Maybe<ChatUsersMutationResponse>;
  /** update single row of the table: "chat_users" */
  update_chat_users_by_pk?: Maybe<ChatUsers>;
  /** update multiples rows of table: "chat_users" */
  update_chat_users_many?: Maybe<Array<Maybe<ChatUsersMutationResponse>>>;
  /** update data of the table: "chats" */
  update_chats?: Maybe<ChatsMutationResponse>;
  /** update single row of the table: "chats" */
  update_chats_by_pk?: Maybe<Chats>;
  /** update data of the table: "chats_event_logs" */
  update_chats_event_logs?: Maybe<ChatsEventLogsMutationResponse>;
  /** update single row of the table: "chats_event_logs" */
  update_chats_event_logs_by_pk?: Maybe<ChatsEventLogs>;
  /** update multiples rows of table: "chats_event_logs" */
  update_chats_event_logs_many?: Maybe<Array<Maybe<ChatsEventLogsMutationResponse>>>;
  /** update multiples rows of table: "chats" */
  update_chats_many?: Maybe<Array<Maybe<ChatsMutationResponse>>>;
  /** update data of the table: "contact_groups" */
  update_contact_groups?: Maybe<ContactGroupsMutationResponse>;
  /** update single row of the table: "contact_groups" */
  update_contact_groups_by_pk?: Maybe<ContactGroups>;
  /** update multiples rows of table: "contact_groups" */
  update_contact_groups_many?: Maybe<Array<Maybe<ContactGroupsMutationResponse>>>;
  /** update data of the table: "customer_users" */
  update_customer_users?: Maybe<CustomerUsersMutationResponse>;
  /** update single row of the table: "customer_users" */
  update_customer_users_by_pk?: Maybe<CustomerUsers>;
  /** update multiples rows of table: "customer_users" */
  update_customer_users_many?: Maybe<Array<Maybe<CustomerUsersMutationResponse>>>;
  /** update data of the table: "email_notification_preferences" */
  update_email_notification_preferences?: Maybe<EmailNotificationPreferencesMutationResponse>;
  /** update single row of the table: "email_notification_preferences" */
  update_email_notification_preferences_by_pk?: Maybe<EmailNotificationPreferences>;
  /** update multiples rows of table: "email_notification_preferences" */
  update_email_notification_preferences_many?: Maybe<Array<Maybe<EmailNotificationPreferencesMutationResponse>>>;
  /** update data of the table: "file_mime_types" */
  update_file_mime_types?: Maybe<FileMimeTypesMutationResponse>;
  /** update single row of the table: "file_mime_types" */
  update_file_mime_types_by_pk?: Maybe<FileMimeTypes>;
  /** update multiples rows of table: "file_mime_types" */
  update_file_mime_types_many?: Maybe<Array<Maybe<FileMimeTypesMutationResponse>>>;
  /** update data of the table: "files" */
  update_files?: Maybe<FilesMutationResponse>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update multiples rows of table: "files" */
  update_files_many?: Maybe<Array<Maybe<FilesMutationResponse>>>;
  /** update data of the table: "merchant_api_users" */
  update_merchant_api_users?: Maybe<MerchantApiUsersMutationResponse>;
  /** update single row of the table: "merchant_api_users" */
  update_merchant_api_users_by_pk?: Maybe<MerchantApiUsers>;
  /** update multiples rows of table: "merchant_api_users" */
  update_merchant_api_users_many?: Maybe<Array<Maybe<MerchantApiUsersMutationResponse>>>;
  /** update data of the table: "merchant_customer_user_contact_groups" */
  update_merchant_customer_user_contact_groups?: Maybe<MerchantCustomerUserContactGroupsMutationResponse>;
  /** update single row of the table: "merchant_customer_user_contact_groups" */
  update_merchant_customer_user_contact_groups_by_pk?: Maybe<MerchantCustomerUserContactGroups>;
  /** update multiples rows of table: "merchant_customer_user_contact_groups" */
  update_merchant_customer_user_contact_groups_many?: Maybe<Array<Maybe<MerchantCustomerUserContactGroupsMutationResponse>>>;
  /** update data of the table: "merchant_customer_user_tags" */
  update_merchant_customer_user_tags?: Maybe<MerchantCustomerUserTagsMutationResponse>;
  /** update single row of the table: "merchant_customer_user_tags" */
  update_merchant_customer_user_tags_by_pk?: Maybe<MerchantCustomerUserTags>;
  /** update multiples rows of table: "merchant_customer_user_tags" */
  update_merchant_customer_user_tags_many?: Maybe<Array<Maybe<MerchantCustomerUserTagsMutationResponse>>>;
  /** update data of the table: "merchant_customer_users" */
  update_merchant_customer_users?: Maybe<MerchantCustomerUsersMutationResponse>;
  /** update single row of the table: "merchant_customer_users" */
  update_merchant_customer_users_by_pk?: Maybe<MerchantCustomerUsers>;
  /** update multiples rows of table: "merchant_customer_users" */
  update_merchant_customer_users_many?: Maybe<Array<Maybe<MerchantCustomerUsersMutationResponse>>>;
  /** update data of the table: "merchant_mobile_app_installations" */
  update_merchant_mobile_app_installations?: Maybe<MerchantMobileAppInstallationsMutationResponse>;
  /** update single row of the table: "merchant_mobile_app_installations" */
  update_merchant_mobile_app_installations_by_pk?: Maybe<MerchantMobileAppInstallations>;
  /** update multiples rows of table: "merchant_mobile_app_installations" */
  update_merchant_mobile_app_installations_many?: Maybe<Array<Maybe<MerchantMobileAppInstallationsMutationResponse>>>;
  /** update data of the table: "merchant_salesforce_sync_configurations" */
  update_merchant_salesforce_sync_configurations?: Maybe<MerchantSalesforceSyncConfigurationsMutationResponse>;
  /** update single row of the table: "merchant_salesforce_sync_configurations" */
  update_merchant_salesforce_sync_configurations_by_pk?: Maybe<MerchantSalesforceSyncConfigurations>;
  /** update multiples rows of table: "merchant_salesforce_sync_configurations" */
  update_merchant_salesforce_sync_configurations_many?: Maybe<Array<Maybe<MerchantSalesforceSyncConfigurationsMutationResponse>>>;
  /** update data of the table: "merchant_store_merchant_users" */
  update_merchant_store_merchant_users?: Maybe<MerchantStoreMerchantUsersMutationResponse>;
  /** update single row of the table: "merchant_store_merchant_users" */
  update_merchant_store_merchant_users_by_pk?: Maybe<MerchantStoreMerchantUsers>;
  /** update multiples rows of table: "merchant_store_merchant_users" */
  update_merchant_store_merchant_users_many?: Maybe<Array<Maybe<MerchantStoreMerchantUsersMutationResponse>>>;
  /** update data of the table: "merchant_stores" */
  update_merchant_stores?: Maybe<MerchantStoresMutationResponse>;
  /** update single row of the table: "merchant_stores" */
  update_merchant_stores_by_pk?: Maybe<MerchantStores>;
  /** update multiples rows of table: "merchant_stores" */
  update_merchant_stores_many?: Maybe<Array<Maybe<MerchantStoresMutationResponse>>>;
  /** update data of the table: "merchant_user_contact_groups" */
  update_merchant_user_contact_groups?: Maybe<MerchantUserContactGroupsMutationResponse>;
  /** update single row of the table: "merchant_user_contact_groups" */
  update_merchant_user_contact_groups_by_pk?: Maybe<MerchantUserContactGroups>;
  /** update multiples rows of table: "merchant_user_contact_groups" */
  update_merchant_user_contact_groups_many?: Maybe<Array<Maybe<MerchantUserContactGroupsMutationResponse>>>;
  /** update data of the table: "merchant_user_password_resets" */
  update_merchant_user_password_resets?: Maybe<MerchantUserPasswordResetsMutationResponse>;
  /** update single row of the table: "merchant_user_password_resets" */
  update_merchant_user_password_resets_by_pk?: Maybe<MerchantUserPasswordResets>;
  /** update multiples rows of table: "merchant_user_password_resets" */
  update_merchant_user_password_resets_many?: Maybe<Array<Maybe<MerchantUserPasswordResetsMutationResponse>>>;
  /** update data of the table: "merchant_users" */
  update_merchant_users?: Maybe<MerchantUsersMutationResponse>;
  /** update single row of the table: "merchant_users" */
  update_merchant_users_by_pk?: Maybe<MerchantUsers>;
  /** update multiples rows of table: "merchant_users" */
  update_merchant_users_many?: Maybe<Array<Maybe<MerchantUsersMutationResponse>>>;
  /** update data of the table: "merchants" */
  update_merchants?: Maybe<MerchantsMutationResponse>;
  /** update single row of the table: "merchants" */
  update_merchants_by_pk?: Maybe<Merchants>;
  /** update multiples rows of table: "merchants" */
  update_merchants_many?: Maybe<Array<Maybe<MerchantsMutationResponse>>>;
  /** update data of the table: "mobile_app_platforms" */
  update_mobile_app_platforms?: Maybe<MobileAppPlatformsMutationResponse>;
  /** update single row of the table: "mobile_app_platforms" */
  update_mobile_app_platforms_by_pk?: Maybe<MobileAppPlatforms>;
  /** update multiples rows of table: "mobile_app_platforms" */
  update_mobile_app_platforms_many?: Maybe<Array<Maybe<MobileAppPlatformsMutationResponse>>>;
  /** update data of the table: "mobile_app_release_statuses" */
  update_mobile_app_release_statuses?: Maybe<MobileAppReleaseStatusesMutationResponse>;
  /** update single row of the table: "mobile_app_release_statuses" */
  update_mobile_app_release_statuses_by_pk?: Maybe<MobileAppReleaseStatuses>;
  /** update multiples rows of table: "mobile_app_release_statuses" */
  update_mobile_app_release_statuses_many?: Maybe<Array<Maybe<MobileAppReleaseStatusesMutationResponse>>>;
  /** update data of the table: "mobile_app_releases" */
  update_mobile_app_releases?: Maybe<MobileAppReleasesMutationResponse>;
  /** update single row of the table: "mobile_app_releases" */
  update_mobile_app_releases_by_pk?: Maybe<MobileAppReleases>;
  /** update multiples rows of table: "mobile_app_releases" */
  update_mobile_app_releases_many?: Maybe<Array<Maybe<MobileAppReleasesMutationResponse>>>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<OrganizationsMutationResponse>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update multiples rows of table: "organizations" */
  update_organizations_many?: Maybe<Array<Maybe<OrganizationsMutationResponse>>>;
  /** update data of the table: "salesforce_record_attributes" */
  update_salesforce_record_attributes?: Maybe<SalesforceRecordAttributesMutationResponse>;
  /** update single row of the table: "salesforce_record_attributes" */
  update_salesforce_record_attributes_by_pk?: Maybe<SalesforceRecordAttributes>;
  /** update multiples rows of table: "salesforce_record_attributes" */
  update_salesforce_record_attributes_many?: Maybe<Array<Maybe<SalesforceRecordAttributesMutationResponse>>>;
  /** update data of the table: "secondary_responsible_merchant_users" */
  update_secondary_responsible_merchant_users?: Maybe<SecondaryResponsibleMerchantUsersMutationResponse>;
  /** update single row of the table: "secondary_responsible_merchant_users" */
  update_secondary_responsible_merchant_users_by_pk?: Maybe<SecondaryResponsibleMerchantUsers>;
  /** update multiples rows of table: "secondary_responsible_merchant_users" */
  update_secondary_responsible_merchant_users_many?: Maybe<Array<Maybe<SecondaryResponsibleMerchantUsersMutationResponse>>>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<TagsMutationResponse>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  update_tags_many?: Maybe<Array<Maybe<TagsMutationResponse>>>;
  /** update data of the table: "toast_messages" */
  update_toast_messages?: Maybe<ToastMessagesMutationResponse>;
  /** update single row of the table: "toast_messages" */
  update_toast_messages_by_pk?: Maybe<ToastMessages>;
  /** update multiples rows of table: "toast_messages" */
  update_toast_messages_many?: Maybe<Array<Maybe<ToastMessagesMutationResponse>>>;
  /** update data of the table: "user_chats" */
  update_user_chats?: Maybe<UserChatsMutationResponse>;
  /** update single row of the table: "user_chats" */
  update_user_chats_by_pk?: Maybe<UserChats>;
  /** update multiples rows of table: "user_chats" */
  update_user_chats_many?: Maybe<Array<Maybe<UserChatsMutationResponse>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<UsersMutationResponse>>>;
  /** update data of the table: "whatsapp_account_hosting_types" */
  update_whatsapp_account_hosting_types?: Maybe<WhatsappAccountHostingTypesMutationResponse>;
  /** update single row of the table: "whatsapp_account_hosting_types" */
  update_whatsapp_account_hosting_types_by_pk?: Maybe<WhatsappAccountHostingTypes>;
  /** update multiples rows of table: "whatsapp_account_hosting_types" */
  update_whatsapp_account_hosting_types_many?: Maybe<Array<Maybe<WhatsappAccountHostingTypesMutationResponse>>>;
  /** update data of the table: "whatsapp_account_providers" */
  update_whatsapp_account_providers?: Maybe<WhatsappAccountProvidersMutationResponse>;
  /** update single row of the table: "whatsapp_account_providers" */
  update_whatsapp_account_providers_by_pk?: Maybe<WhatsappAccountProviders>;
  /** update multiples rows of table: "whatsapp_account_providers" */
  update_whatsapp_account_providers_many?: Maybe<Array<Maybe<WhatsappAccountProvidersMutationResponse>>>;
  /** update data of the table: "whatsapp_accounts" */
  update_whatsapp_accounts?: Maybe<WhatsappAccountsMutationResponse>;
  /** update single row of the table: "whatsapp_accounts" */
  update_whatsapp_accounts_by_pk?: Maybe<WhatsappAccounts>;
  /** update multiples rows of table: "whatsapp_accounts" */
  update_whatsapp_accounts_many?: Maybe<Array<Maybe<WhatsappAccountsMutationResponse>>>;
  /** update data of the table: "whatsapp_business_accounts" */
  update_whatsapp_business_accounts?: Maybe<WhatsappBusinessAccountsMutationResponse>;
  /** update single row of the table: "whatsapp_business_accounts" */
  update_whatsapp_business_accounts_by_pk?: Maybe<WhatsappBusinessAccounts>;
  /** update multiples rows of table: "whatsapp_business_accounts" */
  update_whatsapp_business_accounts_many?: Maybe<Array<Maybe<WhatsappBusinessAccountsMutationResponse>>>;
  /** update data of the table: "whatsapp_business_verticals" */
  update_whatsapp_business_verticals?: Maybe<WhatsappBusinessVerticalsMutationResponse>;
  /** update single row of the table: "whatsapp_business_verticals" */
  update_whatsapp_business_verticals_by_pk?: Maybe<WhatsappBusinessVerticals>;
  /** update multiples rows of table: "whatsapp_business_verticals" */
  update_whatsapp_business_verticals_many?: Maybe<Array<Maybe<WhatsappBusinessVerticalsMutationResponse>>>;
  /** update data of the table: "whatsapp_conversation_types" */
  update_whatsapp_conversation_types?: Maybe<WhatsappConversationTypesMutationResponse>;
  /** update single row of the table: "whatsapp_conversation_types" */
  update_whatsapp_conversation_types_by_pk?: Maybe<WhatsappConversationTypes>;
  /** update multiples rows of table: "whatsapp_conversation_types" */
  update_whatsapp_conversation_types_many?: Maybe<Array<Maybe<WhatsappConversationTypesMutationResponse>>>;
  /** update data of the table: "whatsapp_conversations" */
  update_whatsapp_conversations?: Maybe<WhatsappConversationsMutationResponse>;
  /** update single row of the table: "whatsapp_conversations" */
  update_whatsapp_conversations_by_pk?: Maybe<WhatsappConversations>;
  /** update multiples rows of table: "whatsapp_conversations" */
  update_whatsapp_conversations_many?: Maybe<Array<Maybe<WhatsappConversationsMutationResponse>>>;
  /** update data of the table: "whatsapp_media_types" */
  update_whatsapp_media_types?: Maybe<WhatsappMediaTypesMutationResponse>;
  /** update single row of the table: "whatsapp_media_types" */
  update_whatsapp_media_types_by_pk?: Maybe<WhatsappMediaTypes>;
  /** update multiples rows of table: "whatsapp_media_types" */
  update_whatsapp_media_types_many?: Maybe<Array<Maybe<WhatsappMediaTypesMutationResponse>>>;
  /** update data of the table: "whatsapp_message_directions" */
  update_whatsapp_message_directions?: Maybe<WhatsappMessageDirectionsMutationResponse>;
  /** update single row of the table: "whatsapp_message_directions" */
  update_whatsapp_message_directions_by_pk?: Maybe<WhatsappMessageDirections>;
  /** update multiples rows of table: "whatsapp_message_directions" */
  update_whatsapp_message_directions_many?: Maybe<Array<Maybe<WhatsappMessageDirectionsMutationResponse>>>;
  /** update data of the table: "whatsapp_message_statuses" */
  update_whatsapp_message_statuses?: Maybe<WhatsappMessageStatusesMutationResponse>;
  /** update single row of the table: "whatsapp_message_statuses" */
  update_whatsapp_message_statuses_by_pk?: Maybe<WhatsappMessageStatuses>;
  /** update multiples rows of table: "whatsapp_message_statuses" */
  update_whatsapp_message_statuses_many?: Maybe<Array<Maybe<WhatsappMessageStatusesMutationResponse>>>;
  /** update data of the table: "whatsapp_message_template_statuses" */
  update_whatsapp_message_template_statuses?: Maybe<WhatsappMessageTemplateStatusesMutationResponse>;
  /** update single row of the table: "whatsapp_message_template_statuses" */
  update_whatsapp_message_template_statuses_by_pk?: Maybe<WhatsappMessageTemplateStatuses>;
  /** update multiples rows of table: "whatsapp_message_template_statuses" */
  update_whatsapp_message_template_statuses_many?: Maybe<Array<Maybe<WhatsappMessageTemplateStatusesMutationResponse>>>;
  /** update data of the table: "whatsapp_message_template_values" */
  update_whatsapp_message_template_values?: Maybe<WhatsappMessageTemplateValuesMutationResponse>;
  /** update single row of the table: "whatsapp_message_template_values" */
  update_whatsapp_message_template_values_by_pk?: Maybe<WhatsappMessageTemplateValues>;
  /** update multiples rows of table: "whatsapp_message_template_values" */
  update_whatsapp_message_template_values_many?: Maybe<Array<Maybe<WhatsappMessageTemplateValuesMutationResponse>>>;
  /** update data of the table: "whatsapp_message_templates" */
  update_whatsapp_message_templates?: Maybe<WhatsappMessageTemplatesMutationResponse>;
  /** update single row of the table: "whatsapp_message_templates" */
  update_whatsapp_message_templates_by_pk?: Maybe<WhatsappMessageTemplates>;
  /** update multiples rows of table: "whatsapp_message_templates" */
  update_whatsapp_message_templates_many?: Maybe<Array<Maybe<WhatsappMessageTemplatesMutationResponse>>>;
  /** update data of the table: "whatsapp_message_types" */
  update_whatsapp_message_types?: Maybe<WhatsappMessageTypesMutationResponse>;
  /** update single row of the table: "whatsapp_message_types" */
  update_whatsapp_message_types_by_pk?: Maybe<WhatsappMessageTypes>;
  /** update multiples rows of table: "whatsapp_message_types" */
  update_whatsapp_message_types_many?: Maybe<Array<Maybe<WhatsappMessageTypesMutationResponse>>>;
  /** update data of the table: "whatsapp_messages" */
  update_whatsapp_messages?: Maybe<WhatsappMessagesMutationResponse>;
  /** update single row of the table: "whatsapp_messages" */
  update_whatsapp_messages_by_pk?: Maybe<WhatsappMessages>;
  /** update multiples rows of table: "whatsapp_messages" */
  update_whatsapp_messages_many?: Maybe<Array<Maybe<WhatsappMessagesMutationResponse>>>;
  uploadWhatsappBusinessProfilePhoto?: Maybe<UploadWhatsappBusinessProfilePhotoResult>;
  upsertChannelsAndChannelMerchantUsers: Array<WhatsappAccount>;
  upsertMerchantUsers?: Maybe<Array<Maybe<MerchantUser>>>;
};


/** mutation root */
export type MutationRootAuthenticateSalesforceArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type MutationRootConnectThreeSixtyChannelsArgs = {
  channelIdentifiers: Array<Scalars['String']>;
};


/** mutation root */
export type MutationRootConnectVonageChannelsArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteCampaignAudienceTypesArgs = {
  where: CampaignAudienceTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteCampaignAudienceTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteCampaignCustomerUsersArgs = {
  where: CampaignCustomerUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteCampaignCustomerUsersByPkArgs = {
  campaignId: Scalars['uuid'];
  customerUserId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteCampaignsArgs = {
  where: CampaignsBoolExp;
};


/** mutation root */
export type MutationRootDeleteCampaignsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChannelChatBotUsersArgs = {
  where: ChannelChatBotUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteChannelChatBotUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChannelMerchantUsersArgs = {
  where: ChannelMerchantUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteChannelMerchantUsersByPkArgs = {
  channelId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChannelWorkingHoursArgs = {
  where: ChannelWorkingHoursBoolExp;
};


/** mutation root */
export type MutationRootDeleteChannelWorkingHoursByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChannelsArgs = {
  where: ChannelsBoolExp;
};


/** mutation root */
export type MutationRootDeleteChannelsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatMessageChatBotAutoreplyTypesArgs = {
  where: ChatMessageChatBotAutoreplyTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageChatBotAutoreplyTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteChatMessageFileAttachmentTranslationsArgs = {
  where: ChatMessageFileAttachmentTranslationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageFileAttachmentTranslationsByPkArgs = {
  chatMessageFileAttachmentId: Scalars['uuid'];
  language: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteChatMessageFileAttachmentsArgs = {
  where: ChatMessageFileAttachmentsBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageFileAttachmentsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplatePlaceholderTypesArgs = {
  where: ChatMessageTemplatePlaceholderTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplatePlaceholderTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplatePlaceholdersArgs = {
  where: ChatMessageTemplatePlaceholdersBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplatePlaceholdersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplateTypesArgs = {
  where: ChatMessageTemplateTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplateTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplateValuesArgs = {
  where: ChatMessageTemplateValuesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplateValuesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplatesArgs = {
  where: ChatMessageTemplatesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageTemplatesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatMessageTranslationsArgs = {
  where: ChatMessageTranslationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageTranslationsByPkArgs = {
  chatMessageId: Scalars['uuid'];
  language: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteChatMessageTypesArgs = {
  where: ChatMessageTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteChatMessageWhatsappMessagesArgs = {
  where: ChatMessageWhatsappMessagesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessageWhatsappMessagesByPkArgs = {
  chatMessageId: Scalars['uuid'];
  whatsappMessageId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatMessagesArgs = {
  where: ChatMessagesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessagesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatMessagesEventLogsArgs = {
  where: ChatMessagesEventLogsBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatMessagesEventLogsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatSummariesArgs = {
  where: ChatSummariesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatSummariesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatTypesArgs = {
  where: ChatTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteChatUsersArgs = {
  where: ChatUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatUsersByPkArgs = {
  chatId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatsArgs = {
  where: ChatsBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteChatsEventLogsArgs = {
  where: ChatsEventLogsBoolExp;
};


/** mutation root */
export type MutationRootDeleteChatsEventLogsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteContactGroupsArgs = {
  where: ContactGroupsBoolExp;
};


/** mutation root */
export type MutationRootDeleteContactGroupsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteCustomerUsersArgs = {
  where: CustomerUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteCustomerUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteEmailNotificationPreferencesArgs = {
  where: EmailNotificationPreferencesBoolExp;
};


/** mutation root */
export type MutationRootDeleteEmailNotificationPreferencesByPkArgs = {
  preference: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteFileMimeTypesArgs = {
  where: FileMimeTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteFileMimeTypesByPkArgs = {
  mimeType: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteFilesArgs = {
  where: FilesBoolExp;
};


/** mutation root */
export type MutationRootDeleteFilesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantApiUsersArgs = {
  where: MerchantApiUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantApiUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantCustomerUserContactGroupsArgs = {
  where: MerchantCustomerUserContactGroupsBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantCustomerUserContactGroupsByPkArgs = {
  contactGroupId: Scalars['uuid'];
  merchantCustomerUserId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantCustomerUserTagsArgs = {
  where: MerchantCustomerUserTagsBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantCustomerUserTagsByPkArgs = {
  merchantCustomerUserId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantCustomerUsersArgs = {
  where: MerchantCustomerUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantCustomerUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantMobileAppInstallationsArgs = {
  where: MerchantMobileAppInstallationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantMobileAppInstallationsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantSalesforceSyncConfigurationsArgs = {
  where: MerchantSalesforceSyncConfigurationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantSalesforceSyncConfigurationsByPkArgs = {
  merchantId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantStoreMerchantUsersArgs = {
  where: MerchantStoreMerchantUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantStoreMerchantUsersByPkArgs = {
  merchantStoreId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantStoresArgs = {
  where: MerchantStoresBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantStoresByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantUserContactGroupsArgs = {
  where: MerchantUserContactGroupsBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantUserContactGroupsByPkArgs = {
  contactGroupId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantUserPasswordResetsArgs = {
  where: MerchantUserPasswordResetsBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantUserPasswordResetsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantUsersArgs = {
  where: MerchantUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMerchantsArgs = {
  where: MerchantsBoolExp;
};


/** mutation root */
export type MutationRootDeleteMerchantsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteMobileAppPlatformsArgs = {
  where: MobileAppPlatformsBoolExp;
};


/** mutation root */
export type MutationRootDeleteMobileAppPlatformsByPkArgs = {
  platform: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteMobileAppReleaseStatusesArgs = {
  where: MobileAppReleaseStatusesBoolExp;
};


/** mutation root */
export type MutationRootDeleteMobileAppReleaseStatusesByPkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteMobileAppReleasesArgs = {
  where: MobileAppReleasesBoolExp;
};


/** mutation root */
export type MutationRootDeleteMobileAppReleasesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteOrganizationsArgs = {
  where: OrganizationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrganizationsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteSalesforceRecordAttributesArgs = {
  where: SalesforceRecordAttributesBoolExp;
};


/** mutation root */
export type MutationRootDeleteSalesforceRecordAttributesByPkArgs = {
  attribute: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteSecondaryResponsibleMerchantUsersArgs = {
  where: SecondaryResponsibleMerchantUsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteSecondaryResponsibleMerchantUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteTagsArgs = {
  where: TagsBoolExp;
};


/** mutation root */
export type MutationRootDeleteTagsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteToastMessagesArgs = {
  where: ToastMessagesBoolExp;
};


/** mutation root */
export type MutationRootDeleteToastMessagesByPkArgs = {
  message: Scalars['String'];
  pathname: Scalars['String'];
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteUserChatsArgs = {
  where: UserChatsBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserChatsByPkArgs = {
  chatId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteUsersArgs = {
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootDeleteUsersByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteWhatsappAccountHostingTypesArgs = {
  where: WhatsappAccountHostingTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappAccountHostingTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWhatsappAccountProvidersArgs = {
  where: WhatsappAccountProvidersBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappAccountProvidersByPkArgs = {
  provider: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWhatsappAccountsArgs = {
  where: WhatsappAccountsBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappAccountsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteWhatsappBusinessAccountsArgs = {
  where: WhatsappBusinessAccountsBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappBusinessAccountsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteWhatsappBusinessVerticalsArgs = {
  where: WhatsappBusinessVerticalsBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappBusinessVerticalsByPkArgs = {
  vertical: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWhatsappConversationTypesArgs = {
  where: WhatsappConversationTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappConversationTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWhatsappConversationsArgs = {
  where: WhatsappConversationsBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappConversationsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteWhatsappMediaTypesArgs = {
  where: WhatsappMediaTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappMediaTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageDirectionsArgs = {
  where: WhatsappMessageDirectionsBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageDirectionsByPkArgs = {
  direction: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageStatusesArgs = {
  where: WhatsappMessageStatusesBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageStatusesByPkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageTemplateStatusesArgs = {
  where: WhatsappMessageTemplateStatusesBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageTemplateStatusesByPkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageTemplateValuesArgs = {
  where: WhatsappMessageTemplateValuesBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageTemplateValuesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageTemplatesArgs = {
  where: WhatsappMessageTemplatesBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageTemplatesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageTypesArgs = {
  where: WhatsappMessageTypesBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappMessageTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type MutationRootDeleteWhatsappMessagesArgs = {
  where: WhatsappMessagesBoolExp;
};


/** mutation root */
export type MutationRootDeleteWhatsappMessagesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type MutationRootExportCampaignMessagesMetadataArgs = {
  campaignId?: InputMaybe<Scalars['uuid']>;
};


/** mutation root */
export type MutationRootImportMerchantCustomerUsersArgs = {
  csvBase64Content: Scalars['String'];
  merchantId: Scalars['uuid'];
  mimeType: Scalars['String'];
};


/** mutation root */
export type MutationRootImportMerchantUsersArgs = {
  csvBase64Content: Scalars['String'];
};


/** mutation root */
export type MutationRootImportWhatsappChatArgs = {
  channelId: Scalars['uuid'];
  customerPhoneNumber: Scalars['String'];
  textFileContentHex: Scalars['String'];
  textFileMerchantUserName: Scalars['String'];
};


/** mutation root */
export type MutationRootInsertChatMessageArgs = {
  chat: Chat;
  chatMessageFileAttachments?: InputMaybe<Array<ChatMessageFileAttachment>>;
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  chatMessageTemplateValues?: InputMaybe<Array<InputMaybe<ChatMessageTemplateValue>>>;
  forwardedChatMessageId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  platform?: InputMaybe<Scalars['String']>;
  templateHeaderMediaBase64Content?: InputMaybe<Scalars['String']>;
  templateHeaderMediaMimeType?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type_?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplateArgs = {
  buttonText?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  headerMediaBase64?: InputMaybe<Scalars['String']>;
  headerMediaMimeType?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name: Scalars['String'];
  text: Scalars['String'];
  type_: Scalars['String'];
};


/** mutation root */
export type MutationRootInsertCampaignAudienceTypesArgs = {
  objects: Array<CampaignAudienceTypesInsertInput>;
  on_conflict?: InputMaybe<CampaignAudienceTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertCampaignAudienceTypesOneArgs = {
  object: CampaignAudienceTypesInsertInput;
  on_conflict?: InputMaybe<CampaignAudienceTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertCampaignCustomerUsersArgs = {
  objects: Array<CampaignCustomerUsersInsertInput>;
  on_conflict?: InputMaybe<CampaignCustomerUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertCampaignCustomerUsersOneArgs = {
  object: CampaignCustomerUsersInsertInput;
  on_conflict?: InputMaybe<CampaignCustomerUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertCampaignsArgs = {
  objects: Array<CampaignsInsertInput>;
  on_conflict?: InputMaybe<CampaignsOnConflict>;
};


/** mutation root */
export type MutationRootInsertCampaignsOneArgs = {
  object: CampaignsInsertInput;
  on_conflict?: InputMaybe<CampaignsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChannelChatBotUsersArgs = {
  objects: Array<ChannelChatBotUsersInsertInput>;
  on_conflict?: InputMaybe<ChannelChatBotUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertChannelChatBotUsersOneArgs = {
  object: ChannelChatBotUsersInsertInput;
  on_conflict?: InputMaybe<ChannelChatBotUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertChannelMerchantUsersArgs = {
  objects: Array<ChannelMerchantUsersInsertInput>;
  on_conflict?: InputMaybe<ChannelMerchantUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertChannelMerchantUsersOneArgs = {
  object: ChannelMerchantUsersInsertInput;
  on_conflict?: InputMaybe<ChannelMerchantUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertChannelWorkingHoursArgs = {
  objects: Array<ChannelWorkingHoursInsertInput>;
  on_conflict?: InputMaybe<ChannelWorkingHoursOnConflict>;
};


/** mutation root */
export type MutationRootInsertChannelWorkingHoursOneArgs = {
  object: ChannelWorkingHoursInsertInput;
  on_conflict?: InputMaybe<ChannelWorkingHoursOnConflict>;
};


/** mutation root */
export type MutationRootInsertChannelsArgs = {
  objects: Array<ChannelsInsertInput>;
  on_conflict?: InputMaybe<ChannelsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChannelsOneArgs = {
  object: ChannelsInsertInput;
  on_conflict?: InputMaybe<ChannelsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageChatBotAutoreplyTypesArgs = {
  objects: Array<ChatMessageChatBotAutoreplyTypesInsertInput>;
  on_conflict?: InputMaybe<ChatMessageChatBotAutoreplyTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageChatBotAutoreplyTypesOneArgs = {
  object: ChatMessageChatBotAutoreplyTypesInsertInput;
  on_conflict?: InputMaybe<ChatMessageChatBotAutoreplyTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageFileAttachmentTranslationsArgs = {
  objects: Array<ChatMessageFileAttachmentTranslationsInsertInput>;
  on_conflict?: InputMaybe<ChatMessageFileAttachmentTranslationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageFileAttachmentTranslationsOneArgs = {
  object: ChatMessageFileAttachmentTranslationsInsertInput;
  on_conflict?: InputMaybe<ChatMessageFileAttachmentTranslationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageFileAttachmentsArgs = {
  objects: Array<ChatMessageFileAttachmentsInsertInput>;
  on_conflict?: InputMaybe<ChatMessageFileAttachmentsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageFileAttachmentsOneArgs = {
  object: ChatMessageFileAttachmentsInsertInput;
  on_conflict?: InputMaybe<ChatMessageFileAttachmentsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplatePlaceholderTypesArgs = {
  objects: Array<ChatMessageTemplatePlaceholderTypesInsertInput>;
  on_conflict?: InputMaybe<ChatMessageTemplatePlaceholderTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplatePlaceholderTypesOneArgs = {
  object: ChatMessageTemplatePlaceholderTypesInsertInput;
  on_conflict?: InputMaybe<ChatMessageTemplatePlaceholderTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplatePlaceholdersArgs = {
  objects: Array<ChatMessageTemplatePlaceholdersInsertInput>;
  on_conflict?: InputMaybe<ChatMessageTemplatePlaceholdersOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplatePlaceholdersOneArgs = {
  object: ChatMessageTemplatePlaceholdersInsertInput;
  on_conflict?: InputMaybe<ChatMessageTemplatePlaceholdersOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplateTypesArgs = {
  objects: Array<ChatMessageTemplateTypesInsertInput>;
  on_conflict?: InputMaybe<ChatMessageTemplateTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplateTypesOneArgs = {
  object: ChatMessageTemplateTypesInsertInput;
  on_conflict?: InputMaybe<ChatMessageTemplateTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplateValuesArgs = {
  objects: Array<ChatMessageTemplateValuesInsertInput>;
  on_conflict?: InputMaybe<ChatMessageTemplateValuesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplateValuesOneArgs = {
  object: ChatMessageTemplateValuesInsertInput;
  on_conflict?: InputMaybe<ChatMessageTemplateValuesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplatesArgs = {
  objects: Array<ChatMessageTemplatesInsertInput>;
  on_conflict?: InputMaybe<ChatMessageTemplatesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTemplatesOneArgs = {
  object: ChatMessageTemplatesInsertInput;
  on_conflict?: InputMaybe<ChatMessageTemplatesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTranslationsArgs = {
  objects: Array<ChatMessageTranslationsInsertInput>;
  on_conflict?: InputMaybe<ChatMessageTranslationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTranslationsOneArgs = {
  object: ChatMessageTranslationsInsertInput;
  on_conflict?: InputMaybe<ChatMessageTranslationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTypesArgs = {
  objects: Array<ChatMessageTypesInsertInput>;
  on_conflict?: InputMaybe<ChatMessageTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageTypesOneArgs = {
  object: ChatMessageTypesInsertInput;
  on_conflict?: InputMaybe<ChatMessageTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageWhatsappMessagesArgs = {
  objects: Array<ChatMessageWhatsappMessagesInsertInput>;
  on_conflict?: InputMaybe<ChatMessageWhatsappMessagesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessageWhatsappMessagesOneArgs = {
  object: ChatMessageWhatsappMessagesInsertInput;
  on_conflict?: InputMaybe<ChatMessageWhatsappMessagesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessagesArgs = {
  objects: Array<ChatMessagesInsertInput>;
  on_conflict?: InputMaybe<ChatMessagesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessagesEventLogsArgs = {
  objects: Array<ChatMessagesEventLogsInsertInput>;
  on_conflict?: InputMaybe<ChatMessagesEventLogsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessagesEventLogsOneArgs = {
  object: ChatMessagesEventLogsInsertInput;
  on_conflict?: InputMaybe<ChatMessagesEventLogsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatMessagesOneArgs = {
  object: ChatMessagesInsertInput;
  on_conflict?: InputMaybe<ChatMessagesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatSummariesArgs = {
  objects: Array<ChatSummariesInsertInput>;
  on_conflict?: InputMaybe<ChatSummariesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatSummariesOneArgs = {
  object: ChatSummariesInsertInput;
  on_conflict?: InputMaybe<ChatSummariesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatTypesArgs = {
  objects: Array<ChatTypesInsertInput>;
  on_conflict?: InputMaybe<ChatTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatTypesOneArgs = {
  object: ChatTypesInsertInput;
  on_conflict?: InputMaybe<ChatTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatUsersArgs = {
  objects: Array<ChatUsersInsertInput>;
  on_conflict?: InputMaybe<ChatUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatUsersOneArgs = {
  object: ChatUsersInsertInput;
  on_conflict?: InputMaybe<ChatUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatsArgs = {
  objects: Array<ChatsInsertInput>;
  on_conflict?: InputMaybe<ChatsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatsEventLogsArgs = {
  objects: Array<ChatsEventLogsInsertInput>;
  on_conflict?: InputMaybe<ChatsEventLogsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatsEventLogsOneArgs = {
  object: ChatsEventLogsInsertInput;
  on_conflict?: InputMaybe<ChatsEventLogsOnConflict>;
};


/** mutation root */
export type MutationRootInsertChatsOneArgs = {
  object: ChatsInsertInput;
  on_conflict?: InputMaybe<ChatsOnConflict>;
};


/** mutation root */
export type MutationRootInsertContactGroupsArgs = {
  objects: Array<ContactGroupsInsertInput>;
  on_conflict?: InputMaybe<ContactGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertContactGroupsOneArgs = {
  object: ContactGroupsInsertInput;
  on_conflict?: InputMaybe<ContactGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomerUsersArgs = {
  objects: Array<CustomerUsersInsertInput>;
  on_conflict?: InputMaybe<CustomerUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomerUsersOneArgs = {
  object: CustomerUsersInsertInput;
  on_conflict?: InputMaybe<CustomerUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertEmailNotificationPreferencesArgs = {
  objects: Array<EmailNotificationPreferencesInsertInput>;
  on_conflict?: InputMaybe<EmailNotificationPreferencesOnConflict>;
};


/** mutation root */
export type MutationRootInsertEmailNotificationPreferencesOneArgs = {
  object: EmailNotificationPreferencesInsertInput;
  on_conflict?: InputMaybe<EmailNotificationPreferencesOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileMimeTypesArgs = {
  objects: Array<FileMimeTypesInsertInput>;
  on_conflict?: InputMaybe<FileMimeTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileMimeTypesOneArgs = {
  object: FileMimeTypesInsertInput;
  on_conflict?: InputMaybe<FileMimeTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertFilesArgs = {
  objects: Array<FilesInsertInput>;
  on_conflict?: InputMaybe<FilesOnConflict>;
};


/** mutation root */
export type MutationRootInsertFilesOneArgs = {
  object: FilesInsertInput;
  on_conflict?: InputMaybe<FilesOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantApiUsersArgs = {
  objects: Array<MerchantApiUsersInsertInput>;
  on_conflict?: InputMaybe<MerchantApiUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantApiUsersOneArgs = {
  object: MerchantApiUsersInsertInput;
  on_conflict?: InputMaybe<MerchantApiUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantCustomerUserContactGroupsArgs = {
  objects: Array<MerchantCustomerUserContactGroupsInsertInput>;
  on_conflict?: InputMaybe<MerchantCustomerUserContactGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantCustomerUserContactGroupsOneArgs = {
  object: MerchantCustomerUserContactGroupsInsertInput;
  on_conflict?: InputMaybe<MerchantCustomerUserContactGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantCustomerUserTagsArgs = {
  objects: Array<MerchantCustomerUserTagsInsertInput>;
  on_conflict?: InputMaybe<MerchantCustomerUserTagsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantCustomerUserTagsOneArgs = {
  object: MerchantCustomerUserTagsInsertInput;
  on_conflict?: InputMaybe<MerchantCustomerUserTagsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantCustomerUsersArgs = {
  objects: Array<MerchantCustomerUsersInsertInput>;
  on_conflict?: InputMaybe<MerchantCustomerUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantCustomerUsersOneArgs = {
  object: MerchantCustomerUsersInsertInput;
  on_conflict?: InputMaybe<MerchantCustomerUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantMobileAppInstallationsArgs = {
  objects: Array<MerchantMobileAppInstallationsInsertInput>;
  on_conflict?: InputMaybe<MerchantMobileAppInstallationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantMobileAppInstallationsOneArgs = {
  object: MerchantMobileAppInstallationsInsertInput;
  on_conflict?: InputMaybe<MerchantMobileAppInstallationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantSalesforceSyncConfigurationsArgs = {
  objects: Array<MerchantSalesforceSyncConfigurationsInsertInput>;
  on_conflict?: InputMaybe<MerchantSalesforceSyncConfigurationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantSalesforceSyncConfigurationsOneArgs = {
  object: MerchantSalesforceSyncConfigurationsInsertInput;
  on_conflict?: InputMaybe<MerchantSalesforceSyncConfigurationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantStoreMerchantUsersArgs = {
  objects: Array<MerchantStoreMerchantUsersInsertInput>;
  on_conflict?: InputMaybe<MerchantStoreMerchantUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantStoreMerchantUsersOneArgs = {
  object: MerchantStoreMerchantUsersInsertInput;
  on_conflict?: InputMaybe<MerchantStoreMerchantUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantStoresArgs = {
  objects: Array<MerchantStoresInsertInput>;
  on_conflict?: InputMaybe<MerchantStoresOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantStoresOneArgs = {
  object: MerchantStoresInsertInput;
  on_conflict?: InputMaybe<MerchantStoresOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantUserContactGroupsArgs = {
  objects: Array<MerchantUserContactGroupsInsertInput>;
  on_conflict?: InputMaybe<MerchantUserContactGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantUserContactGroupsOneArgs = {
  object: MerchantUserContactGroupsInsertInput;
  on_conflict?: InputMaybe<MerchantUserContactGroupsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantUserPasswordResetsArgs = {
  objects: Array<MerchantUserPasswordResetsInsertInput>;
  on_conflict?: InputMaybe<MerchantUserPasswordResetsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantUserPasswordResetsOneArgs = {
  object: MerchantUserPasswordResetsInsertInput;
  on_conflict?: InputMaybe<MerchantUserPasswordResetsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantUsersArgs = {
  objects: Array<MerchantUsersInsertInput>;
  on_conflict?: InputMaybe<MerchantUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantUsersOneArgs = {
  object: MerchantUsersInsertInput;
  on_conflict?: InputMaybe<MerchantUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantsArgs = {
  objects: Array<MerchantsInsertInput>;
  on_conflict?: InputMaybe<MerchantsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMerchantsOneArgs = {
  object: MerchantsInsertInput;
  on_conflict?: InputMaybe<MerchantsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMobileAppPlatformsArgs = {
  objects: Array<MobileAppPlatformsInsertInput>;
  on_conflict?: InputMaybe<MobileAppPlatformsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMobileAppPlatformsOneArgs = {
  object: MobileAppPlatformsInsertInput;
  on_conflict?: InputMaybe<MobileAppPlatformsOnConflict>;
};


/** mutation root */
export type MutationRootInsertMobileAppReleaseStatusesArgs = {
  objects: Array<MobileAppReleaseStatusesInsertInput>;
  on_conflict?: InputMaybe<MobileAppReleaseStatusesOnConflict>;
};


/** mutation root */
export type MutationRootInsertMobileAppReleaseStatusesOneArgs = {
  object: MobileAppReleaseStatusesInsertInput;
  on_conflict?: InputMaybe<MobileAppReleaseStatusesOnConflict>;
};


/** mutation root */
export type MutationRootInsertMobileAppReleasesArgs = {
  objects: Array<MobileAppReleasesInsertInput>;
  on_conflict?: InputMaybe<MobileAppReleasesOnConflict>;
};


/** mutation root */
export type MutationRootInsertMobileAppReleasesOneArgs = {
  object: MobileAppReleasesInsertInput;
  on_conflict?: InputMaybe<MobileAppReleasesOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationsArgs = {
  objects: Array<OrganizationsInsertInput>;
  on_conflict?: InputMaybe<OrganizationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationsOneArgs = {
  object: OrganizationsInsertInput;
  on_conflict?: InputMaybe<OrganizationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertSalesforceRecordAttributesArgs = {
  objects: Array<SalesforceRecordAttributesInsertInput>;
  on_conflict?: InputMaybe<SalesforceRecordAttributesOnConflict>;
};


/** mutation root */
export type MutationRootInsertSalesforceRecordAttributesOneArgs = {
  object: SalesforceRecordAttributesInsertInput;
  on_conflict?: InputMaybe<SalesforceRecordAttributesOnConflict>;
};


/** mutation root */
export type MutationRootInsertSecondaryResponsibleMerchantUsersArgs = {
  objects: Array<SecondaryResponsibleMerchantUsersInsertInput>;
  on_conflict?: InputMaybe<SecondaryResponsibleMerchantUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertSecondaryResponsibleMerchantUsersOneArgs = {
  object: SecondaryResponsibleMerchantUsersInsertInput;
  on_conflict?: InputMaybe<SecondaryResponsibleMerchantUsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagsArgs = {
  objects: Array<TagsInsertInput>;
  on_conflict?: InputMaybe<TagsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagsOneArgs = {
  object: TagsInsertInput;
  on_conflict?: InputMaybe<TagsOnConflict>;
};


/** mutation root */
export type MutationRootInsertToastMessagesArgs = {
  objects: Array<ToastMessagesInsertInput>;
  on_conflict?: InputMaybe<ToastMessagesOnConflict>;
};


/** mutation root */
export type MutationRootInsertToastMessagesOneArgs = {
  object: ToastMessagesInsertInput;
  on_conflict?: InputMaybe<ToastMessagesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserChatsArgs = {
  objects: Array<UserChatsInsertInput>;
  on_conflict?: InputMaybe<UserChatsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserChatsOneArgs = {
  object: UserChatsInsertInput;
  on_conflict?: InputMaybe<UserChatsOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  on_conflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertUsersOneArgs = {
  object: UsersInsertInput;
  on_conflict?: InputMaybe<UsersOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappAccountHostingTypesArgs = {
  objects: Array<WhatsappAccountHostingTypesInsertInput>;
  on_conflict?: InputMaybe<WhatsappAccountHostingTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappAccountHostingTypesOneArgs = {
  object: WhatsappAccountHostingTypesInsertInput;
  on_conflict?: InputMaybe<WhatsappAccountHostingTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappAccountProvidersArgs = {
  objects: Array<WhatsappAccountProvidersInsertInput>;
  on_conflict?: InputMaybe<WhatsappAccountProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappAccountProvidersOneArgs = {
  object: WhatsappAccountProvidersInsertInput;
  on_conflict?: InputMaybe<WhatsappAccountProvidersOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappAccountsArgs = {
  objects: Array<WhatsappAccountsInsertInput>;
  on_conflict?: InputMaybe<WhatsappAccountsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappAccountsOneArgs = {
  object: WhatsappAccountsInsertInput;
  on_conflict?: InputMaybe<WhatsappAccountsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappBusinessAccountsArgs = {
  objects: Array<WhatsappBusinessAccountsInsertInput>;
  on_conflict?: InputMaybe<WhatsappBusinessAccountsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappBusinessAccountsOneArgs = {
  object: WhatsappBusinessAccountsInsertInput;
  on_conflict?: InputMaybe<WhatsappBusinessAccountsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappBusinessVerticalsArgs = {
  objects: Array<WhatsappBusinessVerticalsInsertInput>;
  on_conflict?: InputMaybe<WhatsappBusinessVerticalsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappBusinessVerticalsOneArgs = {
  object: WhatsappBusinessVerticalsInsertInput;
  on_conflict?: InputMaybe<WhatsappBusinessVerticalsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappConversationTypesArgs = {
  objects: Array<WhatsappConversationTypesInsertInput>;
  on_conflict?: InputMaybe<WhatsappConversationTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappConversationTypesOneArgs = {
  object: WhatsappConversationTypesInsertInput;
  on_conflict?: InputMaybe<WhatsappConversationTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappConversationsArgs = {
  objects: Array<WhatsappConversationsInsertInput>;
  on_conflict?: InputMaybe<WhatsappConversationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappConversationsOneArgs = {
  object: WhatsappConversationsInsertInput;
  on_conflict?: InputMaybe<WhatsappConversationsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMediaTypesArgs = {
  objects: Array<WhatsappMediaTypesInsertInput>;
  on_conflict?: InputMaybe<WhatsappMediaTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMediaTypesOneArgs = {
  object: WhatsappMediaTypesInsertInput;
  on_conflict?: InputMaybe<WhatsappMediaTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageDirectionsArgs = {
  objects: Array<WhatsappMessageDirectionsInsertInput>;
  on_conflict?: InputMaybe<WhatsappMessageDirectionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageDirectionsOneArgs = {
  object: WhatsappMessageDirectionsInsertInput;
  on_conflict?: InputMaybe<WhatsappMessageDirectionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageStatusesArgs = {
  objects: Array<WhatsappMessageStatusesInsertInput>;
  on_conflict?: InputMaybe<WhatsappMessageStatusesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageStatusesOneArgs = {
  object: WhatsappMessageStatusesInsertInput;
  on_conflict?: InputMaybe<WhatsappMessageStatusesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageTemplateStatusesArgs = {
  objects: Array<WhatsappMessageTemplateStatusesInsertInput>;
  on_conflict?: InputMaybe<WhatsappMessageTemplateStatusesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageTemplateStatusesOneArgs = {
  object: WhatsappMessageTemplateStatusesInsertInput;
  on_conflict?: InputMaybe<WhatsappMessageTemplateStatusesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageTemplateValuesArgs = {
  objects: Array<WhatsappMessageTemplateValuesInsertInput>;
  on_conflict?: InputMaybe<WhatsappMessageTemplateValuesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageTemplateValuesOneArgs = {
  object: WhatsappMessageTemplateValuesInsertInput;
  on_conflict?: InputMaybe<WhatsappMessageTemplateValuesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageTemplatesArgs = {
  objects: Array<WhatsappMessageTemplatesInsertInput>;
  on_conflict?: InputMaybe<WhatsappMessageTemplatesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageTemplatesOneArgs = {
  object: WhatsappMessageTemplatesInsertInput;
  on_conflict?: InputMaybe<WhatsappMessageTemplatesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageTypesArgs = {
  objects: Array<WhatsappMessageTypesInsertInput>;
  on_conflict?: InputMaybe<WhatsappMessageTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessageTypesOneArgs = {
  object: WhatsappMessageTypesInsertInput;
  on_conflict?: InputMaybe<WhatsappMessageTypesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessagesArgs = {
  objects: Array<WhatsappMessagesInsertInput>;
  on_conflict?: InputMaybe<WhatsappMessagesOnConflict>;
};


/** mutation root */
export type MutationRootInsertWhatsappMessagesOneArgs = {
  object: WhatsappMessagesInsertInput;
  on_conflict?: InputMaybe<WhatsappMessagesOnConflict>;
};


/** mutation root */
export type MutationRootIssueOauthTokensArgs = {
  apiKey?: InputMaybe<Scalars['String']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type MutationRootRefreshAccessTokenArgs = {
  refreshToken: Scalars['String'];
};


/** mutation root */
export type MutationRootRequestPasswordResetArgs = {
  emailAddress: Scalars['String'];
};


/** mutation root */
export type MutationRootResetPasswordArgs = {
  newPassword: Scalars['String'];
  passwordResetCode: Scalars['String'];
};


/** mutation root */
export type MutationRootResetWhatsappAccountWebhookUrlArgs = {
  whatsappAccountId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootRestoreMerchantUserArgs = {
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


/** mutation root */
export type MutationRootSaveCompanyDetailsArgs = {
  companyName: Scalars['String'];
  companySize: Scalars['String'];
  industry: Scalars['String'];
};


/** mutation root */
export type MutationRootSignupArgs = {
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type MutationRootStartChatArgs = {
  chat: Chat;
};


/** mutation root */
export type MutationRootSummarizeChatArgs = {
  chatId: Scalars['uuid'];
  fromDate: Scalars['date'];
  toDate: Scalars['date'];
};


/** mutation root */
export type MutationRootUpdateMerchantUserEmailAddressArgs = {
  newEmailAddress: Scalars['String'];
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountBusinessProfileArgs = {
  whatsappAccountId: Scalars['uuid'];
};


/** mutation root */
export type MutationRootUpdateCampaignAudienceTypesArgs = {
  _set?: InputMaybe<CampaignAudienceTypesSetInput>;
  where: CampaignAudienceTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateCampaignAudienceTypesByPkArgs = {
  _set?: InputMaybe<CampaignAudienceTypesSetInput>;
  pk_columns: CampaignAudienceTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCampaignAudienceTypesManyArgs = {
  updates: Array<CampaignAudienceTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateCampaignCustomerUsersArgs = {
  _set?: InputMaybe<CampaignCustomerUsersSetInput>;
  where: CampaignCustomerUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateCampaignCustomerUsersByPkArgs = {
  _set?: InputMaybe<CampaignCustomerUsersSetInput>;
  pk_columns: CampaignCustomerUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCampaignCustomerUsersManyArgs = {
  updates: Array<CampaignCustomerUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateCampaignsArgs = {
  _set?: InputMaybe<CampaignsSetInput>;
  where: CampaignsBoolExp;
};


/** mutation root */
export type MutationRootUpdateCampaignsByPkArgs = {
  _set?: InputMaybe<CampaignsSetInput>;
  pk_columns: CampaignsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCampaignsManyArgs = {
  updates: Array<CampaignsUpdates>;
};


/** mutation root */
export type MutationRootUpdateChannelChatBotUsersArgs = {
  _set?: InputMaybe<ChannelChatBotUsersSetInput>;
  where: ChannelChatBotUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateChannelChatBotUsersByPkArgs = {
  _set?: InputMaybe<ChannelChatBotUsersSetInput>;
  pk_columns: ChannelChatBotUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChannelChatBotUsersManyArgs = {
  updates: Array<ChannelChatBotUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateChannelMerchantUsersArgs = {
  _set?: InputMaybe<ChannelMerchantUsersSetInput>;
  where: ChannelMerchantUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateChannelMerchantUsersByPkArgs = {
  _set?: InputMaybe<ChannelMerchantUsersSetInput>;
  pk_columns: ChannelMerchantUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChannelMerchantUsersManyArgs = {
  updates: Array<ChannelMerchantUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateChannelWorkingHoursArgs = {
  _inc?: InputMaybe<ChannelWorkingHoursIncInput>;
  _set?: InputMaybe<ChannelWorkingHoursSetInput>;
  where: ChannelWorkingHoursBoolExp;
};


/** mutation root */
export type MutationRootUpdateChannelWorkingHoursByPkArgs = {
  _inc?: InputMaybe<ChannelWorkingHoursIncInput>;
  _set?: InputMaybe<ChannelWorkingHoursSetInput>;
  pk_columns: ChannelWorkingHoursPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChannelWorkingHoursManyArgs = {
  updates: Array<ChannelWorkingHoursUpdates>;
};


/** mutation root */
export type MutationRootUpdateChannelsArgs = {
  _set?: InputMaybe<ChannelsSetInput>;
  where: ChannelsBoolExp;
};


/** mutation root */
export type MutationRootUpdateChannelsByPkArgs = {
  _set?: InputMaybe<ChannelsSetInput>;
  pk_columns: ChannelsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChannelsManyArgs = {
  updates: Array<ChannelsUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageChatBotAutoreplyTypesArgs = {
  _set?: InputMaybe<ChatMessageChatBotAutoreplyTypesSetInput>;
  where: ChatMessageChatBotAutoreplyTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageChatBotAutoreplyTypesByPkArgs = {
  _set?: InputMaybe<ChatMessageChatBotAutoreplyTypesSetInput>;
  pk_columns: ChatMessageChatBotAutoreplyTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageChatBotAutoreplyTypesManyArgs = {
  updates: Array<ChatMessageChatBotAutoreplyTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageFileAttachmentTranslationsArgs = {
  _set?: InputMaybe<ChatMessageFileAttachmentTranslationsSetInput>;
  where: ChatMessageFileAttachmentTranslationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageFileAttachmentTranslationsByPkArgs = {
  _set?: InputMaybe<ChatMessageFileAttachmentTranslationsSetInput>;
  pk_columns: ChatMessageFileAttachmentTranslationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageFileAttachmentTranslationsManyArgs = {
  updates: Array<ChatMessageFileAttachmentTranslationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageFileAttachmentsArgs = {
  _append?: InputMaybe<ChatMessageFileAttachmentsAppendInput>;
  _delete_at_path?: InputMaybe<ChatMessageFileAttachmentsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ChatMessageFileAttachmentsDeleteElemInput>;
  _delete_key?: InputMaybe<ChatMessageFileAttachmentsDeleteKeyInput>;
  _inc?: InputMaybe<ChatMessageFileAttachmentsIncInput>;
  _prepend?: InputMaybe<ChatMessageFileAttachmentsPrependInput>;
  _set?: InputMaybe<ChatMessageFileAttachmentsSetInput>;
  where: ChatMessageFileAttachmentsBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageFileAttachmentsByPkArgs = {
  _append?: InputMaybe<ChatMessageFileAttachmentsAppendInput>;
  _delete_at_path?: InputMaybe<ChatMessageFileAttachmentsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ChatMessageFileAttachmentsDeleteElemInput>;
  _delete_key?: InputMaybe<ChatMessageFileAttachmentsDeleteKeyInput>;
  _inc?: InputMaybe<ChatMessageFileAttachmentsIncInput>;
  _prepend?: InputMaybe<ChatMessageFileAttachmentsPrependInput>;
  _set?: InputMaybe<ChatMessageFileAttachmentsSetInput>;
  pk_columns: ChatMessageFileAttachmentsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageFileAttachmentsManyArgs = {
  updates: Array<ChatMessageFileAttachmentsUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplatePlaceholderTypesArgs = {
  _set?: InputMaybe<ChatMessageTemplatePlaceholderTypesSetInput>;
  where: ChatMessageTemplatePlaceholderTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplatePlaceholderTypesByPkArgs = {
  _set?: InputMaybe<ChatMessageTemplatePlaceholderTypesSetInput>;
  pk_columns: ChatMessageTemplatePlaceholderTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplatePlaceholderTypesManyArgs = {
  updates: Array<ChatMessageTemplatePlaceholderTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplatePlaceholdersArgs = {
  _inc?: InputMaybe<ChatMessageTemplatePlaceholdersIncInput>;
  _set?: InputMaybe<ChatMessageTemplatePlaceholdersSetInput>;
  where: ChatMessageTemplatePlaceholdersBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplatePlaceholdersByPkArgs = {
  _inc?: InputMaybe<ChatMessageTemplatePlaceholdersIncInput>;
  _set?: InputMaybe<ChatMessageTemplatePlaceholdersSetInput>;
  pk_columns: ChatMessageTemplatePlaceholdersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplatePlaceholdersManyArgs = {
  updates: Array<ChatMessageTemplatePlaceholdersUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplateTypesArgs = {
  _set?: InputMaybe<ChatMessageTemplateTypesSetInput>;
  where: ChatMessageTemplateTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplateTypesByPkArgs = {
  _set?: InputMaybe<ChatMessageTemplateTypesSetInput>;
  pk_columns: ChatMessageTemplateTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplateTypesManyArgs = {
  updates: Array<ChatMessageTemplateTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplateValuesArgs = {
  _set?: InputMaybe<ChatMessageTemplateValuesSetInput>;
  where: ChatMessageTemplateValuesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplateValuesByPkArgs = {
  _set?: InputMaybe<ChatMessageTemplateValuesSetInput>;
  pk_columns: ChatMessageTemplateValuesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplateValuesManyArgs = {
  updates: Array<ChatMessageTemplateValuesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplatesArgs = {
  _set?: InputMaybe<ChatMessageTemplatesSetInput>;
  where: ChatMessageTemplatesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplatesByPkArgs = {
  _set?: InputMaybe<ChatMessageTemplatesSetInput>;
  pk_columns: ChatMessageTemplatesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageTemplatesManyArgs = {
  updates: Array<ChatMessageTemplatesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageTranslationsArgs = {
  _set?: InputMaybe<ChatMessageTranslationsSetInput>;
  where: ChatMessageTranslationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageTranslationsByPkArgs = {
  _set?: InputMaybe<ChatMessageTranslationsSetInput>;
  pk_columns: ChatMessageTranslationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageTranslationsManyArgs = {
  updates: Array<ChatMessageTranslationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageTypesArgs = {
  _set?: InputMaybe<ChatMessageTypesSetInput>;
  where: ChatMessageTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageTypesByPkArgs = {
  _set?: InputMaybe<ChatMessageTypesSetInput>;
  pk_columns: ChatMessageTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageTypesManyArgs = {
  updates: Array<ChatMessageTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessageWhatsappMessagesArgs = {
  _set?: InputMaybe<ChatMessageWhatsappMessagesSetInput>;
  where: ChatMessageWhatsappMessagesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessageWhatsappMessagesByPkArgs = {
  _set?: InputMaybe<ChatMessageWhatsappMessagesSetInput>;
  pk_columns: ChatMessageWhatsappMessagesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessageWhatsappMessagesManyArgs = {
  updates: Array<ChatMessageWhatsappMessagesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessagesArgs = {
  _append?: InputMaybe<ChatMessagesAppendInput>;
  _delete_at_path?: InputMaybe<ChatMessagesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ChatMessagesDeleteElemInput>;
  _delete_key?: InputMaybe<ChatMessagesDeleteKeyInput>;
  _prepend?: InputMaybe<ChatMessagesPrependInput>;
  _set?: InputMaybe<ChatMessagesSetInput>;
  where: ChatMessagesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessagesByPkArgs = {
  _append?: InputMaybe<ChatMessagesAppendInput>;
  _delete_at_path?: InputMaybe<ChatMessagesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ChatMessagesDeleteElemInput>;
  _delete_key?: InputMaybe<ChatMessagesDeleteKeyInput>;
  _prepend?: InputMaybe<ChatMessagesPrependInput>;
  _set?: InputMaybe<ChatMessagesSetInput>;
  pk_columns: ChatMessagesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessagesEventLogsArgs = {
  _set?: InputMaybe<ChatMessagesEventLogsSetInput>;
  where: ChatMessagesEventLogsBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatMessagesEventLogsByPkArgs = {
  _set?: InputMaybe<ChatMessagesEventLogsSetInput>;
  pk_columns: ChatMessagesEventLogsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatMessagesEventLogsManyArgs = {
  updates: Array<ChatMessagesEventLogsUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatMessagesManyArgs = {
  updates: Array<ChatMessagesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatSummariesArgs = {
  _inc?: InputMaybe<ChatSummariesIncInput>;
  _set?: InputMaybe<ChatSummariesSetInput>;
  where: ChatSummariesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatSummariesByPkArgs = {
  _inc?: InputMaybe<ChatSummariesIncInput>;
  _set?: InputMaybe<ChatSummariesSetInput>;
  pk_columns: ChatSummariesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatSummariesManyArgs = {
  updates: Array<ChatSummariesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatTypesArgs = {
  _set?: InputMaybe<ChatTypesSetInput>;
  where: ChatTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatTypesByPkArgs = {
  _set?: InputMaybe<ChatTypesSetInput>;
  pk_columns: ChatTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatTypesManyArgs = {
  updates: Array<ChatTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatUsersArgs = {
  _set?: InputMaybe<ChatUsersSetInput>;
  where: ChatUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatUsersByPkArgs = {
  _set?: InputMaybe<ChatUsersSetInput>;
  pk_columns: ChatUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatUsersManyArgs = {
  updates: Array<ChatUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatsArgs = {
  _set?: InputMaybe<ChatsSetInput>;
  where: ChatsBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatsByPkArgs = {
  _set?: InputMaybe<ChatsSetInput>;
  pk_columns: ChatsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatsEventLogsArgs = {
  _set?: InputMaybe<ChatsEventLogsSetInput>;
  where: ChatsEventLogsBoolExp;
};


/** mutation root */
export type MutationRootUpdateChatsEventLogsByPkArgs = {
  _set?: InputMaybe<ChatsEventLogsSetInput>;
  pk_columns: ChatsEventLogsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChatsEventLogsManyArgs = {
  updates: Array<ChatsEventLogsUpdates>;
};


/** mutation root */
export type MutationRootUpdateChatsManyArgs = {
  updates: Array<ChatsUpdates>;
};


/** mutation root */
export type MutationRootUpdateContactGroupsArgs = {
  _set?: InputMaybe<ContactGroupsSetInput>;
  where: ContactGroupsBoolExp;
};


/** mutation root */
export type MutationRootUpdateContactGroupsByPkArgs = {
  _set?: InputMaybe<ContactGroupsSetInput>;
  pk_columns: ContactGroupsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContactGroupsManyArgs = {
  updates: Array<ContactGroupsUpdates>;
};


/** mutation root */
export type MutationRootUpdateCustomerUsersArgs = {
  _set?: InputMaybe<CustomerUsersSetInput>;
  where: CustomerUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateCustomerUsersByPkArgs = {
  _set?: InputMaybe<CustomerUsersSetInput>;
  pk_columns: CustomerUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCustomerUsersManyArgs = {
  updates: Array<CustomerUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateEmailNotificationPreferencesArgs = {
  _set?: InputMaybe<EmailNotificationPreferencesSetInput>;
  where: EmailNotificationPreferencesBoolExp;
};


/** mutation root */
export type MutationRootUpdateEmailNotificationPreferencesByPkArgs = {
  _set?: InputMaybe<EmailNotificationPreferencesSetInput>;
  pk_columns: EmailNotificationPreferencesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEmailNotificationPreferencesManyArgs = {
  updates: Array<EmailNotificationPreferencesUpdates>;
};


/** mutation root */
export type MutationRootUpdateFileMimeTypesArgs = {
  _set?: InputMaybe<FileMimeTypesSetInput>;
  where: FileMimeTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateFileMimeTypesByPkArgs = {
  _set?: InputMaybe<FileMimeTypesSetInput>;
  pk_columns: FileMimeTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFileMimeTypesManyArgs = {
  updates: Array<FileMimeTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateFilesArgs = {
  _append?: InputMaybe<FilesAppendInput>;
  _delete_at_path?: InputMaybe<FilesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<FilesDeleteElemInput>;
  _delete_key?: InputMaybe<FilesDeleteKeyInput>;
  _prepend?: InputMaybe<FilesPrependInput>;
  _set?: InputMaybe<FilesSetInput>;
  where: FilesBoolExp;
};


/** mutation root */
export type MutationRootUpdateFilesByPkArgs = {
  _append?: InputMaybe<FilesAppendInput>;
  _delete_at_path?: InputMaybe<FilesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<FilesDeleteElemInput>;
  _delete_key?: InputMaybe<FilesDeleteKeyInput>;
  _prepend?: InputMaybe<FilesPrependInput>;
  _set?: InputMaybe<FilesSetInput>;
  pk_columns: FilesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFilesManyArgs = {
  updates: Array<FilesUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantApiUsersArgs = {
  _set?: InputMaybe<MerchantApiUsersSetInput>;
  where: MerchantApiUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantApiUsersByPkArgs = {
  _set?: InputMaybe<MerchantApiUsersSetInput>;
  pk_columns: MerchantApiUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantApiUsersManyArgs = {
  updates: Array<MerchantApiUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantCustomerUserContactGroupsArgs = {
  _set?: InputMaybe<MerchantCustomerUserContactGroupsSetInput>;
  where: MerchantCustomerUserContactGroupsBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantCustomerUserContactGroupsByPkArgs = {
  _set?: InputMaybe<MerchantCustomerUserContactGroupsSetInput>;
  pk_columns: MerchantCustomerUserContactGroupsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantCustomerUserContactGroupsManyArgs = {
  updates: Array<MerchantCustomerUserContactGroupsUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantCustomerUserTagsArgs = {
  _set?: InputMaybe<MerchantCustomerUserTagsSetInput>;
  where: MerchantCustomerUserTagsBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantCustomerUserTagsByPkArgs = {
  _set?: InputMaybe<MerchantCustomerUserTagsSetInput>;
  pk_columns: MerchantCustomerUserTagsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantCustomerUserTagsManyArgs = {
  updates: Array<MerchantCustomerUserTagsUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantCustomerUsersArgs = {
  _set?: InputMaybe<MerchantCustomerUsersSetInput>;
  where: MerchantCustomerUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantCustomerUsersByPkArgs = {
  _set?: InputMaybe<MerchantCustomerUsersSetInput>;
  pk_columns: MerchantCustomerUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantCustomerUsersManyArgs = {
  updates: Array<MerchantCustomerUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantMobileAppInstallationsArgs = {
  _set?: InputMaybe<MerchantMobileAppInstallationsSetInput>;
  where: MerchantMobileAppInstallationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantMobileAppInstallationsByPkArgs = {
  _set?: InputMaybe<MerchantMobileAppInstallationsSetInput>;
  pk_columns: MerchantMobileAppInstallationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantMobileAppInstallationsManyArgs = {
  updates: Array<MerchantMobileAppInstallationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantSalesforceSyncConfigurationsArgs = {
  _set?: InputMaybe<MerchantSalesforceSyncConfigurationsSetInput>;
  where: MerchantSalesforceSyncConfigurationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantSalesforceSyncConfigurationsByPkArgs = {
  _set?: InputMaybe<MerchantSalesforceSyncConfigurationsSetInput>;
  pk_columns: MerchantSalesforceSyncConfigurationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantSalesforceSyncConfigurationsManyArgs = {
  updates: Array<MerchantSalesforceSyncConfigurationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantStoreMerchantUsersArgs = {
  _set?: InputMaybe<MerchantStoreMerchantUsersSetInput>;
  where: MerchantStoreMerchantUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantStoreMerchantUsersByPkArgs = {
  _set?: InputMaybe<MerchantStoreMerchantUsersSetInput>;
  pk_columns: MerchantStoreMerchantUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantStoreMerchantUsersManyArgs = {
  updates: Array<MerchantStoreMerchantUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantStoresArgs = {
  _set?: InputMaybe<MerchantStoresSetInput>;
  where: MerchantStoresBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantStoresByPkArgs = {
  _set?: InputMaybe<MerchantStoresSetInput>;
  pk_columns: MerchantStoresPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantStoresManyArgs = {
  updates: Array<MerchantStoresUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantUserContactGroupsArgs = {
  _set?: InputMaybe<MerchantUserContactGroupsSetInput>;
  where: MerchantUserContactGroupsBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantUserContactGroupsByPkArgs = {
  _set?: InputMaybe<MerchantUserContactGroupsSetInput>;
  pk_columns: MerchantUserContactGroupsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantUserContactGroupsManyArgs = {
  updates: Array<MerchantUserContactGroupsUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantUserPasswordResetsArgs = {
  _set?: InputMaybe<MerchantUserPasswordResetsSetInput>;
  where: MerchantUserPasswordResetsBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantUserPasswordResetsByPkArgs = {
  _set?: InputMaybe<MerchantUserPasswordResetsSetInput>;
  pk_columns: MerchantUserPasswordResetsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantUserPasswordResetsManyArgs = {
  updates: Array<MerchantUserPasswordResetsUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantUsersArgs = {
  _append?: InputMaybe<MerchantUsersAppendInput>;
  _delete_at_path?: InputMaybe<MerchantUsersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MerchantUsersDeleteElemInput>;
  _delete_key?: InputMaybe<MerchantUsersDeleteKeyInput>;
  _prepend?: InputMaybe<MerchantUsersPrependInput>;
  _set?: InputMaybe<MerchantUsersSetInput>;
  where: MerchantUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantUsersByPkArgs = {
  _append?: InputMaybe<MerchantUsersAppendInput>;
  _delete_at_path?: InputMaybe<MerchantUsersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MerchantUsersDeleteElemInput>;
  _delete_key?: InputMaybe<MerchantUsersDeleteKeyInput>;
  _prepend?: InputMaybe<MerchantUsersPrependInput>;
  _set?: InputMaybe<MerchantUsersSetInput>;
  pk_columns: MerchantUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantUsersManyArgs = {
  updates: Array<MerchantUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateMerchantsArgs = {
  _inc?: InputMaybe<MerchantsIncInput>;
  _set?: InputMaybe<MerchantsSetInput>;
  where: MerchantsBoolExp;
};


/** mutation root */
export type MutationRootUpdateMerchantsByPkArgs = {
  _inc?: InputMaybe<MerchantsIncInput>;
  _set?: InputMaybe<MerchantsSetInput>;
  pk_columns: MerchantsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMerchantsManyArgs = {
  updates: Array<MerchantsUpdates>;
};


/** mutation root */
export type MutationRootUpdateMobileAppPlatformsArgs = {
  _set?: InputMaybe<MobileAppPlatformsSetInput>;
  where: MobileAppPlatformsBoolExp;
};


/** mutation root */
export type MutationRootUpdateMobileAppPlatformsByPkArgs = {
  _set?: InputMaybe<MobileAppPlatformsSetInput>;
  pk_columns: MobileAppPlatformsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMobileAppPlatformsManyArgs = {
  updates: Array<MobileAppPlatformsUpdates>;
};


/** mutation root */
export type MutationRootUpdateMobileAppReleaseStatusesArgs = {
  _set?: InputMaybe<MobileAppReleaseStatusesSetInput>;
  where: MobileAppReleaseStatusesBoolExp;
};


/** mutation root */
export type MutationRootUpdateMobileAppReleaseStatusesByPkArgs = {
  _set?: InputMaybe<MobileAppReleaseStatusesSetInput>;
  pk_columns: MobileAppReleaseStatusesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMobileAppReleaseStatusesManyArgs = {
  updates: Array<MobileAppReleaseStatusesUpdates>;
};


/** mutation root */
export type MutationRootUpdateMobileAppReleasesArgs = {
  _set?: InputMaybe<MobileAppReleasesSetInput>;
  where: MobileAppReleasesBoolExp;
};


/** mutation root */
export type MutationRootUpdateMobileAppReleasesByPkArgs = {
  _set?: InputMaybe<MobileAppReleasesSetInput>;
  pk_columns: MobileAppReleasesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMobileAppReleasesManyArgs = {
  updates: Array<MobileAppReleasesUpdates>;
};


/** mutation root */
export type MutationRootUpdateOrganizationsArgs = {
  _set?: InputMaybe<OrganizationsSetInput>;
  where: OrganizationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrganizationsByPkArgs = {
  _set?: InputMaybe<OrganizationsSetInput>;
  pk_columns: OrganizationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrganizationsManyArgs = {
  updates: Array<OrganizationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateSalesforceRecordAttributesArgs = {
  _set?: InputMaybe<SalesforceRecordAttributesSetInput>;
  where: SalesforceRecordAttributesBoolExp;
};


/** mutation root */
export type MutationRootUpdateSalesforceRecordAttributesByPkArgs = {
  _set?: InputMaybe<SalesforceRecordAttributesSetInput>;
  pk_columns: SalesforceRecordAttributesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSalesforceRecordAttributesManyArgs = {
  updates: Array<SalesforceRecordAttributesUpdates>;
};


/** mutation root */
export type MutationRootUpdateSecondaryResponsibleMerchantUsersArgs = {
  _set?: InputMaybe<SecondaryResponsibleMerchantUsersSetInput>;
  where: SecondaryResponsibleMerchantUsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateSecondaryResponsibleMerchantUsersByPkArgs = {
  _set?: InputMaybe<SecondaryResponsibleMerchantUsersSetInput>;
  pk_columns: SecondaryResponsibleMerchantUsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSecondaryResponsibleMerchantUsersManyArgs = {
  updates: Array<SecondaryResponsibleMerchantUsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateTagsArgs = {
  _set?: InputMaybe<TagsSetInput>;
  where: TagsBoolExp;
};


/** mutation root */
export type MutationRootUpdateTagsByPkArgs = {
  _set?: InputMaybe<TagsSetInput>;
  pk_columns: TagsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTagsManyArgs = {
  updates: Array<TagsUpdates>;
};


/** mutation root */
export type MutationRootUpdateToastMessagesArgs = {
  _set?: InputMaybe<ToastMessagesSetInput>;
  where: ToastMessagesBoolExp;
};


/** mutation root */
export type MutationRootUpdateToastMessagesByPkArgs = {
  _set?: InputMaybe<ToastMessagesSetInput>;
  pk_columns: ToastMessagesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateToastMessagesManyArgs = {
  updates: Array<ToastMessagesUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserChatsArgs = {
  _set?: InputMaybe<UserChatsSetInput>;
  where: UserChatsBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserChatsByPkArgs = {
  _set?: InputMaybe<UserChatsSetInput>;
  pk_columns: UserChatsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserChatsManyArgs = {
  updates: Array<UserChatsUpdates>;
};


/** mutation root */
export type MutationRootUpdateUsersArgs = {
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};


/** mutation root */
export type MutationRootUpdateUsersByPkArgs = {
  _set?: InputMaybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountHostingTypesArgs = {
  _set?: InputMaybe<WhatsappAccountHostingTypesSetInput>;
  where: WhatsappAccountHostingTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountHostingTypesByPkArgs = {
  _set?: InputMaybe<WhatsappAccountHostingTypesSetInput>;
  pk_columns: WhatsappAccountHostingTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountHostingTypesManyArgs = {
  updates: Array<WhatsappAccountHostingTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountProvidersArgs = {
  _set?: InputMaybe<WhatsappAccountProvidersSetInput>;
  where: WhatsappAccountProvidersBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountProvidersByPkArgs = {
  _set?: InputMaybe<WhatsappAccountProvidersSetInput>;
  pk_columns: WhatsappAccountProvidersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountProvidersManyArgs = {
  updates: Array<WhatsappAccountProvidersUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountsArgs = {
  _set?: InputMaybe<WhatsappAccountsSetInput>;
  where: WhatsappAccountsBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountsByPkArgs = {
  _set?: InputMaybe<WhatsappAccountsSetInput>;
  pk_columns: WhatsappAccountsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappAccountsManyArgs = {
  updates: Array<WhatsappAccountsUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappBusinessAccountsArgs = {
  _set?: InputMaybe<WhatsappBusinessAccountsSetInput>;
  where: WhatsappBusinessAccountsBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappBusinessAccountsByPkArgs = {
  _set?: InputMaybe<WhatsappBusinessAccountsSetInput>;
  pk_columns: WhatsappBusinessAccountsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappBusinessAccountsManyArgs = {
  updates: Array<WhatsappBusinessAccountsUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappBusinessVerticalsArgs = {
  _set?: InputMaybe<WhatsappBusinessVerticalsSetInput>;
  where: WhatsappBusinessVerticalsBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappBusinessVerticalsByPkArgs = {
  _set?: InputMaybe<WhatsappBusinessVerticalsSetInput>;
  pk_columns: WhatsappBusinessVerticalsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappBusinessVerticalsManyArgs = {
  updates: Array<WhatsappBusinessVerticalsUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappConversationTypesArgs = {
  _set?: InputMaybe<WhatsappConversationTypesSetInput>;
  where: WhatsappConversationTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappConversationTypesByPkArgs = {
  _set?: InputMaybe<WhatsappConversationTypesSetInput>;
  pk_columns: WhatsappConversationTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappConversationTypesManyArgs = {
  updates: Array<WhatsappConversationTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappConversationsArgs = {
  _inc?: InputMaybe<WhatsappConversationsIncInput>;
  _set?: InputMaybe<WhatsappConversationsSetInput>;
  where: WhatsappConversationsBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappConversationsByPkArgs = {
  _inc?: InputMaybe<WhatsappConversationsIncInput>;
  _set?: InputMaybe<WhatsappConversationsSetInput>;
  pk_columns: WhatsappConversationsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappConversationsManyArgs = {
  updates: Array<WhatsappConversationsUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappMediaTypesArgs = {
  _set?: InputMaybe<WhatsappMediaTypesSetInput>;
  where: WhatsappMediaTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappMediaTypesByPkArgs = {
  _set?: InputMaybe<WhatsappMediaTypesSetInput>;
  pk_columns: WhatsappMediaTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappMediaTypesManyArgs = {
  updates: Array<WhatsappMediaTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageDirectionsArgs = {
  _set?: InputMaybe<WhatsappMessageDirectionsSetInput>;
  where: WhatsappMessageDirectionsBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageDirectionsByPkArgs = {
  _set?: InputMaybe<WhatsappMessageDirectionsSetInput>;
  pk_columns: WhatsappMessageDirectionsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageDirectionsManyArgs = {
  updates: Array<WhatsappMessageDirectionsUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageStatusesArgs = {
  _set?: InputMaybe<WhatsappMessageStatusesSetInput>;
  where: WhatsappMessageStatusesBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageStatusesByPkArgs = {
  _set?: InputMaybe<WhatsappMessageStatusesSetInput>;
  pk_columns: WhatsappMessageStatusesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageStatusesManyArgs = {
  updates: Array<WhatsappMessageStatusesUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTemplateStatusesArgs = {
  _set?: InputMaybe<WhatsappMessageTemplateStatusesSetInput>;
  where: WhatsappMessageTemplateStatusesBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTemplateStatusesByPkArgs = {
  _set?: InputMaybe<WhatsappMessageTemplateStatusesSetInput>;
  pk_columns: WhatsappMessageTemplateStatusesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTemplateStatusesManyArgs = {
  updates: Array<WhatsappMessageTemplateStatusesUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTemplateValuesArgs = {
  _inc?: InputMaybe<WhatsappMessageTemplateValuesIncInput>;
  _set?: InputMaybe<WhatsappMessageTemplateValuesSetInput>;
  where: WhatsappMessageTemplateValuesBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTemplateValuesByPkArgs = {
  _inc?: InputMaybe<WhatsappMessageTemplateValuesIncInput>;
  _set?: InputMaybe<WhatsappMessageTemplateValuesSetInput>;
  pk_columns: WhatsappMessageTemplateValuesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTemplateValuesManyArgs = {
  updates: Array<WhatsappMessageTemplateValuesUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTemplatesArgs = {
  _set?: InputMaybe<WhatsappMessageTemplatesSetInput>;
  where: WhatsappMessageTemplatesBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTemplatesByPkArgs = {
  _set?: InputMaybe<WhatsappMessageTemplatesSetInput>;
  pk_columns: WhatsappMessageTemplatesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTemplatesManyArgs = {
  updates: Array<WhatsappMessageTemplatesUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTypesArgs = {
  _set?: InputMaybe<WhatsappMessageTypesSetInput>;
  where: WhatsappMessageTypesBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTypesByPkArgs = {
  _set?: InputMaybe<WhatsappMessageTypesSetInput>;
  pk_columns: WhatsappMessageTypesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessageTypesManyArgs = {
  updates: Array<WhatsappMessageTypesUpdates>;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessagesArgs = {
  _append?: InputMaybe<WhatsappMessagesAppendInput>;
  _delete_at_path?: InputMaybe<WhatsappMessagesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<WhatsappMessagesDeleteElemInput>;
  _delete_key?: InputMaybe<WhatsappMessagesDeleteKeyInput>;
  _inc?: InputMaybe<WhatsappMessagesIncInput>;
  _prepend?: InputMaybe<WhatsappMessagesPrependInput>;
  _set?: InputMaybe<WhatsappMessagesSetInput>;
  where: WhatsappMessagesBoolExp;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessagesByPkArgs = {
  _append?: InputMaybe<WhatsappMessagesAppendInput>;
  _delete_at_path?: InputMaybe<WhatsappMessagesDeleteAtPathInput>;
  _delete_elem?: InputMaybe<WhatsappMessagesDeleteElemInput>;
  _delete_key?: InputMaybe<WhatsappMessagesDeleteKeyInput>;
  _inc?: InputMaybe<WhatsappMessagesIncInput>;
  _prepend?: InputMaybe<WhatsappMessagesPrependInput>;
  _set?: InputMaybe<WhatsappMessagesSetInput>;
  pk_columns: WhatsappMessagesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateWhatsappMessagesManyArgs = {
  updates: Array<WhatsappMessagesUpdates>;
};


/** mutation root */
export type MutationRootUploadWhatsappBusinessProfilePhotoArgs = {
  contentBase64: Scalars['String'];
  filename: Scalars['String'];
  merchantId?: InputMaybe<Scalars['uuid']>;
  mimeType: Scalars['String'];
  whatsappAccountId?: InputMaybe<Scalars['uuid']>;
};


/** mutation root */
export type MutationRootUpsertChannelsAndChannelMerchantUsersArgs = {
  csvBase64Content: Scalars['String'];
};


/** mutation root */
export type MutationRootUpsertMerchantUsersArgs = {
  csvBase64Content: Scalars['String'];
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organization_merchant_users" */
export type OrganizationMerchantUsers = {
  __typename?: 'organization_merchant_users';
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isOwner?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  /** An object relationship */
  merchant?: Maybe<Merchants>;
  merchantId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "organization_merchant_users" */
export type OrganizationMerchantUsersAggregate = {
  __typename?: 'organization_merchant_users_aggregate';
  aggregate?: Maybe<OrganizationMerchantUsersAggregateFields>;
  nodes: Array<OrganizationMerchantUsers>;
};

/** aggregate fields of "organization_merchant_users" */
export type OrganizationMerchantUsersAggregateFields = {
  __typename?: 'organization_merchant_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationMerchantUsersMaxFields>;
  min?: Maybe<OrganizationMerchantUsersMinFields>;
};


/** aggregate fields of "organization_merchant_users" */
export type OrganizationMerchantUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationMerchantUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organization_merchant_users". All fields are combined with a logical 'AND'. */
export type OrganizationMerchantUsersBoolExp = {
  _and?: InputMaybe<Array<OrganizationMerchantUsersBoolExp>>;
  _not?: InputMaybe<OrganizationMerchantUsersBoolExp>;
  _or?: InputMaybe<Array<OrganizationMerchantUsersBoolExp>>;
  emailAddress?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  isOwner?: InputMaybe<BooleanComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  merchant?: InputMaybe<MerchantsBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  organization?: InputMaybe<OrganizationsBoolExp>;
  organizationId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type OrganizationMerchantUsersMaxFields = {
  __typename?: 'organization_merchant_users_max_fields';
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type OrganizationMerchantUsersMinFields = {
  __typename?: 'organization_merchant_users_min_fields';
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['uuid']>;
  organizationId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "organization_merchant_users". */
export type OrganizationMerchantUsersOrderBy = {
  emailAddress?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isOwner?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantsOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationsOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
};

/** select columns of table "organization_merchant_users" */
export enum OrganizationMerchantUsersSelectColumn {
  /** column name */
  EmailAddress = 'emailAddress',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IsOwner = 'isOwner',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  OrganizationId = 'organizationId'
}

/** Streaming cursor of the table "organization_merchant_users" */
export type OrganizationMerchantUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OrganizationMerchantUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationMerchantUsersStreamCursorValueInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isOwner?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  organizationId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "organizations" */
export type Organizations = {
  __typename?: 'organizations';
  id: Scalars['uuid'];
  /** An array relationship */
  merchants: Array<Merchants>;
  /** An aggregate relationship */
  merchants_aggregate: MerchantsAggregate;
  name: Scalars['String'];
};


/** columns and relationships of "organizations" */
export type OrganizationsMerchantsArgs = {
  distinct_on?: InputMaybe<Array<MerchantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantsOrderBy>>;
  where?: InputMaybe<MerchantsBoolExp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsMerchantsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantsOrderBy>>;
  where?: InputMaybe<MerchantsBoolExp>;
};

/** aggregated selection of "organizations" */
export type OrganizationsAggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<OrganizationsAggregateFields>;
  nodes: Array<Organizations>;
};

/** aggregate fields of "organizations" */
export type OrganizationsAggregateFields = {
  __typename?: 'organizations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationsMaxFields>;
  min?: Maybe<OrganizationsMinFields>;
};


/** aggregate fields of "organizations" */
export type OrganizationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type OrganizationsBoolExp = {
  _and?: InputMaybe<Array<OrganizationsBoolExp>>;
  _not?: InputMaybe<OrganizationsBoolExp>;
  _or?: InputMaybe<Array<OrganizationsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  merchants?: InputMaybe<MerchantsBoolExp>;
  merchants_aggregate?: InputMaybe<MerchantsAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "organizations" */
export enum OrganizationsConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationsPkey = 'organizations_pkey'
}

/** input type for inserting data into table "organizations" */
export type OrganizationsInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchants?: InputMaybe<MerchantsArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrganizationsMaxFields = {
  __typename?: 'organizations_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrganizationsMinFields = {
  __typename?: 'organizations_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "organizations" */
export type OrganizationsMutationResponse = {
  __typename?: 'organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type OrganizationsObjRelInsertInput = {
  data: OrganizationsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizationsOnConflict>;
};

/** on_conflict condition type for table "organizations" */
export type OrganizationsOnConflict = {
  constraint: OrganizationsConstraint;
  update_columns?: Array<OrganizationsUpdateColumn>;
  where?: InputMaybe<OrganizationsBoolExp>;
};

/** Ordering options when selecting data from "organizations". */
export type OrganizationsOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchants_aggregate?: InputMaybe<MerchantsAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organizations */
export type OrganizationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "organizations" */
export enum OrganizationsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "organizations" */
export type OrganizationsSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "organizations" */
export type OrganizationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OrganizationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "organizations" */
export enum OrganizationsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type OrganizationsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationsSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationsBoolExp;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "aggregated_merchant_customer_user_tags" */
  aggregated_merchant_customer_user_tags: Array<AggregatedMerchantCustomerUserTags>;
  /** fetch aggregated fields from the table: "aggregated_merchant_customer_user_tags" */
  aggregated_merchant_customer_user_tags_aggregate: AggregatedMerchantCustomerUserTagsAggregate;
  /** fetch data from the table: "campaign_audience_types" */
  campaign_audience_types: Array<CampaignAudienceTypes>;
  /** fetch aggregated fields from the table: "campaign_audience_types" */
  campaign_audience_types_aggregate: CampaignAudienceTypesAggregate;
  /** fetch data from the table: "campaign_audience_types" using primary key columns */
  campaign_audience_types_by_pk?: Maybe<CampaignAudienceTypes>;
  /** fetch data from the table: "campaign_customer_users" */
  campaign_customer_users: Array<CampaignCustomerUsers>;
  /** fetch aggregated fields from the table: "campaign_customer_users" */
  campaign_customer_users_aggregate: CampaignCustomerUsersAggregate;
  /** fetch data from the table: "campaign_customer_users" using primary key columns */
  campaign_customer_users_by_pk?: Maybe<CampaignCustomerUsers>;
  /** fetch data from the table: "campaigns" */
  campaigns: Array<Campaigns>;
  /** fetch aggregated fields from the table: "campaigns" */
  campaigns_aggregate: CampaignsAggregate;
  /** fetch data from the table: "campaigns" using primary key columns */
  campaigns_by_pk?: Maybe<Campaigns>;
  /** fetch data from the table: "channel_chat_bot_users" */
  channel_chat_bot_users: Array<ChannelChatBotUsers>;
  /** fetch aggregated fields from the table: "channel_chat_bot_users" */
  channel_chat_bot_users_aggregate: ChannelChatBotUsersAggregate;
  /** fetch data from the table: "channel_chat_bot_users" using primary key columns */
  channel_chat_bot_users_by_pk?: Maybe<ChannelChatBotUsers>;
  /** fetch data from the table: "channel_merchant_users" */
  channel_merchant_users: Array<ChannelMerchantUsers>;
  /** fetch aggregated fields from the table: "channel_merchant_users" */
  channel_merchant_users_aggregate: ChannelMerchantUsersAggregate;
  /** fetch data from the table: "channel_merchant_users" using primary key columns */
  channel_merchant_users_by_pk?: Maybe<ChannelMerchantUsers>;
  /** fetch data from the table: "channel_working_hours" */
  channel_working_hours: Array<ChannelWorkingHours>;
  /** fetch aggregated fields from the table: "channel_working_hours" */
  channel_working_hours_aggregate: ChannelWorkingHoursAggregate;
  /** fetch data from the table: "channel_working_hours" using primary key columns */
  channel_working_hours_by_pk?: Maybe<ChannelWorkingHours>;
  /** An array relationship */
  channels: Array<Channels>;
  /** An aggregate relationship */
  channels_aggregate: ChannelsAggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table: "chat_message_chat_bot_autoreply_types" */
  chat_message_chat_bot_autoreply_types: Array<ChatMessageChatBotAutoreplyTypes>;
  /** fetch aggregated fields from the table: "chat_message_chat_bot_autoreply_types" */
  chat_message_chat_bot_autoreply_types_aggregate: ChatMessageChatBotAutoreplyTypesAggregate;
  /** fetch data from the table: "chat_message_chat_bot_autoreply_types" using primary key columns */
  chat_message_chat_bot_autoreply_types_by_pk?: Maybe<ChatMessageChatBotAutoreplyTypes>;
  /** fetch data from the table: "chat_message_file_attachment_translations" */
  chat_message_file_attachment_translations: Array<ChatMessageFileAttachmentTranslations>;
  /** fetch aggregated fields from the table: "chat_message_file_attachment_translations" */
  chat_message_file_attachment_translations_aggregate: ChatMessageFileAttachmentTranslationsAggregate;
  /** fetch data from the table: "chat_message_file_attachment_translations" using primary key columns */
  chat_message_file_attachment_translations_by_pk?: Maybe<ChatMessageFileAttachmentTranslations>;
  /** fetch data from the table: "chat_message_file_attachments" */
  chat_message_file_attachments: Array<ChatMessageFileAttachments>;
  /** fetch aggregated fields from the table: "chat_message_file_attachments" */
  chat_message_file_attachments_aggregate: ChatMessageFileAttachmentsAggregate;
  /** fetch data from the table: "chat_message_file_attachments" using primary key columns */
  chat_message_file_attachments_by_pk?: Maybe<ChatMessageFileAttachments>;
  /** fetch data from the table: "chat_message_template_placeholder_types" */
  chat_message_template_placeholder_types: Array<ChatMessageTemplatePlaceholderTypes>;
  /** fetch aggregated fields from the table: "chat_message_template_placeholder_types" */
  chat_message_template_placeholder_types_aggregate: ChatMessageTemplatePlaceholderTypesAggregate;
  /** fetch data from the table: "chat_message_template_placeholder_types" using primary key columns */
  chat_message_template_placeholder_types_by_pk?: Maybe<ChatMessageTemplatePlaceholderTypes>;
  /** fetch data from the table: "chat_message_template_placeholders" */
  chat_message_template_placeholders: Array<ChatMessageTemplatePlaceholders>;
  /** fetch aggregated fields from the table: "chat_message_template_placeholders" */
  chat_message_template_placeholders_aggregate: ChatMessageTemplatePlaceholdersAggregate;
  /** fetch data from the table: "chat_message_template_placeholders" using primary key columns */
  chat_message_template_placeholders_by_pk?: Maybe<ChatMessageTemplatePlaceholders>;
  /** fetch data from the table: "chat_message_template_types" */
  chat_message_template_types: Array<ChatMessageTemplateTypes>;
  /** fetch aggregated fields from the table: "chat_message_template_types" */
  chat_message_template_types_aggregate: ChatMessageTemplateTypesAggregate;
  /** fetch data from the table: "chat_message_template_types" using primary key columns */
  chat_message_template_types_by_pk?: Maybe<ChatMessageTemplateTypes>;
  /** fetch data from the table: "chat_message_template_values" */
  chat_message_template_values: Array<ChatMessageTemplateValues>;
  /** fetch aggregated fields from the table: "chat_message_template_values" */
  chat_message_template_values_aggregate: ChatMessageTemplateValuesAggregate;
  /** fetch data from the table: "chat_message_template_values" using primary key columns */
  chat_message_template_values_by_pk?: Maybe<ChatMessageTemplateValues>;
  /** fetch data from the table: "chat_message_templates" */
  chat_message_templates: Array<ChatMessageTemplates>;
  /** fetch aggregated fields from the table: "chat_message_templates" */
  chat_message_templates_aggregate: ChatMessageTemplatesAggregate;
  /** fetch data from the table: "chat_message_templates" using primary key columns */
  chat_message_templates_by_pk?: Maybe<ChatMessageTemplates>;
  /** fetch data from the table: "chat_message_translations" */
  chat_message_translations: Array<ChatMessageTranslations>;
  /** fetch aggregated fields from the table: "chat_message_translations" */
  chat_message_translations_aggregate: ChatMessageTranslationsAggregate;
  /** fetch data from the table: "chat_message_translations" using primary key columns */
  chat_message_translations_by_pk?: Maybe<ChatMessageTranslations>;
  /** fetch data from the table: "chat_message_types" */
  chat_message_types: Array<ChatMessageTypes>;
  /** fetch aggregated fields from the table: "chat_message_types" */
  chat_message_types_aggregate: ChatMessageTypesAggregate;
  /** fetch data from the table: "chat_message_types" using primary key columns */
  chat_message_types_by_pk?: Maybe<ChatMessageTypes>;
  /** fetch data from the table: "chat_message_whatsapp_messages" */
  chat_message_whatsapp_messages: Array<ChatMessageWhatsappMessages>;
  /** fetch aggregated fields from the table: "chat_message_whatsapp_messages" */
  chat_message_whatsapp_messages_aggregate: ChatMessageWhatsappMessagesAggregate;
  /** fetch data from the table: "chat_message_whatsapp_messages" using primary key columns */
  chat_message_whatsapp_messages_by_pk?: Maybe<ChatMessageWhatsappMessages>;
  /** fetch data from the table: "chat_messages" */
  chat_messages: Array<ChatMessages>;
  /** fetch aggregated fields from the table: "chat_messages" */
  chat_messages_aggregate: ChatMessagesAggregate;
  /** fetch data from the table: "chat_messages" using primary key columns */
  chat_messages_by_pk?: Maybe<ChatMessages>;
  /** fetch data from the table: "chat_messages_event_logs" */
  chat_messages_event_logs: Array<ChatMessagesEventLogs>;
  /** fetch aggregated fields from the table: "chat_messages_event_logs" */
  chat_messages_event_logs_aggregate: ChatMessagesEventLogsAggregate;
  /** fetch data from the table: "chat_messages_event_logs" using primary key columns */
  chat_messages_event_logs_by_pk?: Maybe<ChatMessagesEventLogs>;
  /** fetch data from the table: "chat_summaries" */
  chat_summaries: Array<ChatSummaries>;
  /** fetch aggregated fields from the table: "chat_summaries" */
  chat_summaries_aggregate: ChatSummariesAggregate;
  /** fetch data from the table: "chat_summaries" using primary key columns */
  chat_summaries_by_pk?: Maybe<ChatSummaries>;
  /** fetch data from the table: "chat_types" */
  chat_types: Array<ChatTypes>;
  /** fetch aggregated fields from the table: "chat_types" */
  chat_types_aggregate: ChatTypesAggregate;
  /** fetch data from the table: "chat_types" using primary key columns */
  chat_types_by_pk?: Maybe<ChatTypes>;
  /** fetch data from the table: "chat_users" */
  chat_users: Array<ChatUsers>;
  /** fetch aggregated fields from the table: "chat_users" */
  chat_users_aggregate: ChatUsersAggregate;
  /** fetch data from the table: "chat_users" using primary key columns */
  chat_users_by_pk?: Maybe<ChatUsers>;
  /** An array relationship */
  chats: Array<Chats>;
  /** An aggregate relationship */
  chats_aggregate: ChatsAggregate;
  /** fetch data from the table: "chats" using primary key columns */
  chats_by_pk?: Maybe<Chats>;
  /** fetch data from the table: "chats_event_logs" */
  chats_event_logs: Array<ChatsEventLogs>;
  /** fetch aggregated fields from the table: "chats_event_logs" */
  chats_event_logs_aggregate: ChatsEventLogsAggregate;
  /** fetch data from the table: "chats_event_logs" using primary key columns */
  chats_event_logs_by_pk?: Maybe<ChatsEventLogs>;
  /** fetch data from the table: "contact_groups" */
  contact_groups: Array<ContactGroups>;
  /** fetch aggregated fields from the table: "contact_groups" */
  contact_groups_aggregate: ContactGroupsAggregate;
  /** fetch data from the table: "contact_groups" using primary key columns */
  contact_groups_by_pk?: Maybe<ContactGroups>;
  /** fetch data from the table: "customer_users" */
  customer_users: Array<CustomerUsers>;
  /** fetch aggregated fields from the table: "customer_users" */
  customer_users_aggregate: CustomerUsersAggregate;
  /** fetch data from the table: "customer_users" using primary key columns */
  customer_users_by_pk?: Maybe<CustomerUsers>;
  /** fetch data from the table: "email_notification_preferences" */
  email_notification_preferences: Array<EmailNotificationPreferences>;
  /** fetch aggregated fields from the table: "email_notification_preferences" */
  email_notification_preferences_aggregate: EmailNotificationPreferencesAggregate;
  /** fetch data from the table: "email_notification_preferences" using primary key columns */
  email_notification_preferences_by_pk?: Maybe<EmailNotificationPreferences>;
  /** fetch data from the table: "file_mime_types" */
  file_mime_types: Array<FileMimeTypes>;
  /** fetch aggregated fields from the table: "file_mime_types" */
  file_mime_types_aggregate: FileMimeTypesAggregate;
  /** fetch data from the table: "file_mime_types" using primary key columns */
  file_mime_types_by_pk?: Maybe<FileMimeTypes>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: FilesAggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  getWhatsappAccountConnection: WhatsappAccountConnection;
  /** fetch data from the table: "merchant_api_users" */
  merchant_api_users: Array<MerchantApiUsers>;
  /** fetch aggregated fields from the table: "merchant_api_users" */
  merchant_api_users_aggregate: MerchantApiUsersAggregate;
  /** fetch data from the table: "merchant_api_users" using primary key columns */
  merchant_api_users_by_pk?: Maybe<MerchantApiUsers>;
  /** fetch data from the table: "merchant_customer_user_contact_groups" */
  merchant_customer_user_contact_groups: Array<MerchantCustomerUserContactGroups>;
  /** fetch aggregated fields from the table: "merchant_customer_user_contact_groups" */
  merchant_customer_user_contact_groups_aggregate: MerchantCustomerUserContactGroupsAggregate;
  /** fetch data from the table: "merchant_customer_user_contact_groups" using primary key columns */
  merchant_customer_user_contact_groups_by_pk?: Maybe<MerchantCustomerUserContactGroups>;
  /** fetch data from the table: "merchant_customer_user_tags" */
  merchant_customer_user_tags: Array<MerchantCustomerUserTags>;
  /** fetch aggregated fields from the table: "merchant_customer_user_tags" */
  merchant_customer_user_tags_aggregate: MerchantCustomerUserTagsAggregate;
  /** fetch data from the table: "merchant_customer_user_tags" using primary key columns */
  merchant_customer_user_tags_by_pk?: Maybe<MerchantCustomerUserTags>;
  /** fetch data from the table: "merchant_customer_users" */
  merchant_customer_users: Array<MerchantCustomerUsers>;
  /** fetch aggregated fields from the table: "merchant_customer_users" */
  merchant_customer_users_aggregate: MerchantCustomerUsersAggregate;
  /** fetch data from the table: "merchant_customer_users" using primary key columns */
  merchant_customer_users_by_pk?: Maybe<MerchantCustomerUsers>;
  /** fetch data from the table: "merchant_mobile_app_installations" */
  merchant_mobile_app_installations: Array<MerchantMobileAppInstallations>;
  /** fetch aggregated fields from the table: "merchant_mobile_app_installations" */
  merchant_mobile_app_installations_aggregate: MerchantMobileAppInstallationsAggregate;
  /** fetch data from the table: "merchant_mobile_app_installations" using primary key columns */
  merchant_mobile_app_installations_by_pk?: Maybe<MerchantMobileAppInstallations>;
  /** fetch data from the table: "merchant_salesforce_sync_configurations" */
  merchant_salesforce_sync_configurations: Array<MerchantSalesforceSyncConfigurations>;
  /** fetch aggregated fields from the table: "merchant_salesforce_sync_configurations" */
  merchant_salesforce_sync_configurations_aggregate: MerchantSalesforceSyncConfigurationsAggregate;
  /** fetch data from the table: "merchant_salesforce_sync_configurations" using primary key columns */
  merchant_salesforce_sync_configurations_by_pk?: Maybe<MerchantSalesforceSyncConfigurations>;
  /** fetch data from the table: "merchant_store_merchant_users" */
  merchant_store_merchant_users: Array<MerchantStoreMerchantUsers>;
  /** fetch aggregated fields from the table: "merchant_store_merchant_users" */
  merchant_store_merchant_users_aggregate: MerchantStoreMerchantUsersAggregate;
  /** fetch data from the table: "merchant_store_merchant_users" using primary key columns */
  merchant_store_merchant_users_by_pk?: Maybe<MerchantStoreMerchantUsers>;
  /** fetch data from the table: "merchant_stores" */
  merchant_stores: Array<MerchantStores>;
  /** fetch aggregated fields from the table: "merchant_stores" */
  merchant_stores_aggregate: MerchantStoresAggregate;
  /** fetch data from the table: "merchant_stores" using primary key columns */
  merchant_stores_by_pk?: Maybe<MerchantStores>;
  /** fetch data from the table: "merchant_user_contact_groups" */
  merchant_user_contact_groups: Array<MerchantUserContactGroups>;
  /** fetch aggregated fields from the table: "merchant_user_contact_groups" */
  merchant_user_contact_groups_aggregate: MerchantUserContactGroupsAggregate;
  /** fetch data from the table: "merchant_user_contact_groups" using primary key columns */
  merchant_user_contact_groups_by_pk?: Maybe<MerchantUserContactGroups>;
  /** fetch data from the table: "merchant_user_password_resets" */
  merchant_user_password_resets: Array<MerchantUserPasswordResets>;
  /** fetch aggregated fields from the table: "merchant_user_password_resets" */
  merchant_user_password_resets_aggregate: MerchantUserPasswordResetsAggregate;
  /** fetch data from the table: "merchant_user_password_resets" using primary key columns */
  merchant_user_password_resets_by_pk?: Maybe<MerchantUserPasswordResets>;
  /** fetch data from the table: "merchant_users" */
  merchant_users: Array<MerchantUsers>;
  /** fetch aggregated fields from the table: "merchant_users" */
  merchant_users_aggregate: MerchantUsersAggregate;
  /** fetch data from the table: "merchant_users" using primary key columns */
  merchant_users_by_pk?: Maybe<MerchantUsers>;
  /** An array relationship */
  merchants: Array<Merchants>;
  /** An aggregate relationship */
  merchants_aggregate: MerchantsAggregate;
  /** fetch data from the table: "merchants" using primary key columns */
  merchants_by_pk?: Maybe<Merchants>;
  /** fetch data from the table: "mobile_app_platforms" */
  mobile_app_platforms: Array<MobileAppPlatforms>;
  /** fetch aggregated fields from the table: "mobile_app_platforms" */
  mobile_app_platforms_aggregate: MobileAppPlatformsAggregate;
  /** fetch data from the table: "mobile_app_platforms" using primary key columns */
  mobile_app_platforms_by_pk?: Maybe<MobileAppPlatforms>;
  /** fetch data from the table: "mobile_app_release_statuses" */
  mobile_app_release_statuses: Array<MobileAppReleaseStatuses>;
  /** fetch aggregated fields from the table: "mobile_app_release_statuses" */
  mobile_app_release_statuses_aggregate: MobileAppReleaseStatusesAggregate;
  /** fetch data from the table: "mobile_app_release_statuses" using primary key columns */
  mobile_app_release_statuses_by_pk?: Maybe<MobileAppReleaseStatuses>;
  /** fetch data from the table: "mobile_app_releases" */
  mobile_app_releases: Array<MobileAppReleases>;
  /** fetch aggregated fields from the table: "mobile_app_releases" */
  mobile_app_releases_aggregate: MobileAppReleasesAggregate;
  /** fetch data from the table: "mobile_app_releases" using primary key columns */
  mobile_app_releases_by_pk?: Maybe<MobileAppReleases>;
  /** fetch data from the table: "organization_merchant_users" */
  organization_merchant_users: Array<OrganizationMerchantUsers>;
  /** fetch aggregated fields from the table: "organization_merchant_users" */
  organization_merchant_users_aggregate: OrganizationMerchantUsersAggregate;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: OrganizationsAggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "salesforce_record_attributes" */
  salesforce_record_attributes: Array<SalesforceRecordAttributes>;
  /** fetch aggregated fields from the table: "salesforce_record_attributes" */
  salesforce_record_attributes_aggregate: SalesforceRecordAttributesAggregate;
  /** fetch data from the table: "salesforce_record_attributes" using primary key columns */
  salesforce_record_attributes_by_pk?: Maybe<SalesforceRecordAttributes>;
  /** fetch data from the table: "secondary_responsible_merchant_users" */
  secondary_responsible_merchant_users: Array<SecondaryResponsibleMerchantUsers>;
  /** fetch aggregated fields from the table: "secondary_responsible_merchant_users" */
  secondary_responsible_merchant_users_aggregate: SecondaryResponsibleMerchantUsersAggregate;
  /** fetch data from the table: "secondary_responsible_merchant_users" using primary key columns */
  secondary_responsible_merchant_users_by_pk?: Maybe<SecondaryResponsibleMerchantUsers>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: TagsAggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "toast_messages" */
  toast_messages: Array<ToastMessages>;
  /** fetch aggregated fields from the table: "toast_messages" */
  toast_messages_aggregate: ToastMessagesAggregate;
  /** fetch data from the table: "toast_messages" using primary key columns */
  toast_messages_by_pk?: Maybe<ToastMessages>;
  translateText: TranslatedText;
  /** fetch data from the table: "user_chats" */
  user_chats: Array<UserChats>;
  /** fetch aggregated fields from the table: "user_chats" */
  user_chats_aggregate: UserChatsAggregate;
  /** fetch data from the table: "user_chats" using primary key columns */
  user_chats_by_pk?: Maybe<UserChats>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "whatsapp_account_hosting_types" */
  whatsapp_account_hosting_types: Array<WhatsappAccountHostingTypes>;
  /** fetch aggregated fields from the table: "whatsapp_account_hosting_types" */
  whatsapp_account_hosting_types_aggregate: WhatsappAccountHostingTypesAggregate;
  /** fetch data from the table: "whatsapp_account_hosting_types" using primary key columns */
  whatsapp_account_hosting_types_by_pk?: Maybe<WhatsappAccountHostingTypes>;
  /** fetch data from the table: "whatsapp_account_providers" */
  whatsapp_account_providers: Array<WhatsappAccountProviders>;
  /** fetch aggregated fields from the table: "whatsapp_account_providers" */
  whatsapp_account_providers_aggregate: WhatsappAccountProvidersAggregate;
  /** fetch data from the table: "whatsapp_account_providers" using primary key columns */
  whatsapp_account_providers_by_pk?: Maybe<WhatsappAccountProviders>;
  /** fetch data from the table: "whatsapp_accounts" */
  whatsapp_accounts: Array<WhatsappAccounts>;
  /** fetch aggregated fields from the table: "whatsapp_accounts" */
  whatsapp_accounts_aggregate: WhatsappAccountsAggregate;
  /** fetch data from the table: "whatsapp_accounts" using primary key columns */
  whatsapp_accounts_by_pk?: Maybe<WhatsappAccounts>;
  /** fetch data from the table: "whatsapp_business_accounts" */
  whatsapp_business_accounts: Array<WhatsappBusinessAccounts>;
  /** fetch aggregated fields from the table: "whatsapp_business_accounts" */
  whatsapp_business_accounts_aggregate: WhatsappBusinessAccountsAggregate;
  /** fetch data from the table: "whatsapp_business_accounts" using primary key columns */
  whatsapp_business_accounts_by_pk?: Maybe<WhatsappBusinessAccounts>;
  /** fetch data from the table: "whatsapp_business_verticals" */
  whatsapp_business_verticals: Array<WhatsappBusinessVerticals>;
  /** fetch aggregated fields from the table: "whatsapp_business_verticals" */
  whatsapp_business_verticals_aggregate: WhatsappBusinessVerticalsAggregate;
  /** fetch data from the table: "whatsapp_business_verticals" using primary key columns */
  whatsapp_business_verticals_by_pk?: Maybe<WhatsappBusinessVerticals>;
  /** fetch data from the table: "whatsapp_conversation_types" */
  whatsapp_conversation_types: Array<WhatsappConversationTypes>;
  /** fetch aggregated fields from the table: "whatsapp_conversation_types" */
  whatsapp_conversation_types_aggregate: WhatsappConversationTypesAggregate;
  /** fetch data from the table: "whatsapp_conversation_types" using primary key columns */
  whatsapp_conversation_types_by_pk?: Maybe<WhatsappConversationTypes>;
  /** fetch data from the table: "whatsapp_conversations" */
  whatsapp_conversations: Array<WhatsappConversations>;
  /** fetch aggregated fields from the table: "whatsapp_conversations" */
  whatsapp_conversations_aggregate: WhatsappConversationsAggregate;
  /** fetch data from the table: "whatsapp_conversations" using primary key columns */
  whatsapp_conversations_by_pk?: Maybe<WhatsappConversations>;
  /** fetch data from the table: "whatsapp_media_types" */
  whatsapp_media_types: Array<WhatsappMediaTypes>;
  /** fetch aggregated fields from the table: "whatsapp_media_types" */
  whatsapp_media_types_aggregate: WhatsappMediaTypesAggregate;
  /** fetch data from the table: "whatsapp_media_types" using primary key columns */
  whatsapp_media_types_by_pk?: Maybe<WhatsappMediaTypes>;
  /** fetch data from the table: "whatsapp_message_directions" */
  whatsapp_message_directions: Array<WhatsappMessageDirections>;
  /** fetch aggregated fields from the table: "whatsapp_message_directions" */
  whatsapp_message_directions_aggregate: WhatsappMessageDirectionsAggregate;
  /** fetch data from the table: "whatsapp_message_directions" using primary key columns */
  whatsapp_message_directions_by_pk?: Maybe<WhatsappMessageDirections>;
  /** fetch data from the table: "whatsapp_message_statuses" */
  whatsapp_message_statuses: Array<WhatsappMessageStatuses>;
  /** fetch aggregated fields from the table: "whatsapp_message_statuses" */
  whatsapp_message_statuses_aggregate: WhatsappMessageStatusesAggregate;
  /** fetch data from the table: "whatsapp_message_statuses" using primary key columns */
  whatsapp_message_statuses_by_pk?: Maybe<WhatsappMessageStatuses>;
  /** fetch data from the table: "whatsapp_message_template_statuses" */
  whatsapp_message_template_statuses: Array<WhatsappMessageTemplateStatuses>;
  /** fetch aggregated fields from the table: "whatsapp_message_template_statuses" */
  whatsapp_message_template_statuses_aggregate: WhatsappMessageTemplateStatusesAggregate;
  /** fetch data from the table: "whatsapp_message_template_statuses" using primary key columns */
  whatsapp_message_template_statuses_by_pk?: Maybe<WhatsappMessageTemplateStatuses>;
  /** fetch data from the table: "whatsapp_message_template_values" */
  whatsapp_message_template_values: Array<WhatsappMessageTemplateValues>;
  /** fetch aggregated fields from the table: "whatsapp_message_template_values" */
  whatsapp_message_template_values_aggregate: WhatsappMessageTemplateValuesAggregate;
  /** fetch data from the table: "whatsapp_message_template_values" using primary key columns */
  whatsapp_message_template_values_by_pk?: Maybe<WhatsappMessageTemplateValues>;
  /** fetch data from the table: "whatsapp_message_templates" */
  whatsapp_message_templates: Array<WhatsappMessageTemplates>;
  /** fetch aggregated fields from the table: "whatsapp_message_templates" */
  whatsapp_message_templates_aggregate: WhatsappMessageTemplatesAggregate;
  /** fetch data from the table: "whatsapp_message_templates" using primary key columns */
  whatsapp_message_templates_by_pk?: Maybe<WhatsappMessageTemplates>;
  /** fetch data from the table: "whatsapp_message_types" */
  whatsapp_message_types: Array<WhatsappMessageTypes>;
  /** fetch aggregated fields from the table: "whatsapp_message_types" */
  whatsapp_message_types_aggregate: WhatsappMessageTypesAggregate;
  /** fetch data from the table: "whatsapp_message_types" using primary key columns */
  whatsapp_message_types_by_pk?: Maybe<WhatsappMessageTypes>;
  /** fetch data from the table: "whatsapp_messages" */
  whatsapp_messages: Array<WhatsappMessages>;
  /** fetch aggregated fields from the table: "whatsapp_messages" */
  whatsapp_messages_aggregate: WhatsappMessagesAggregate;
  /** fetch data from the table: "whatsapp_messages" using primary key columns */
  whatsapp_messages_by_pk?: Maybe<WhatsappMessages>;
};


export type QueryRootAggregatedMerchantCustomerUserTagsArgs = {
  distinct_on?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
};


export type QueryRootAggregatedMerchantCustomerUserTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
};


export type QueryRootCampaignAudienceTypesArgs = {
  distinct_on?: InputMaybe<Array<CampaignAudienceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignAudienceTypesOrderBy>>;
  where?: InputMaybe<CampaignAudienceTypesBoolExp>;
};


export type QueryRootCampaignAudienceTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CampaignAudienceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignAudienceTypesOrderBy>>;
  where?: InputMaybe<CampaignAudienceTypesBoolExp>;
};


export type QueryRootCampaignAudienceTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootCampaignCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignCustomerUsersOrderBy>>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};


export type QueryRootCampaignCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignCustomerUsersOrderBy>>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};


export type QueryRootCampaignCustomerUsersByPkArgs = {
  campaignId: Scalars['uuid'];
  customerUserId: Scalars['uuid'];
};


export type QueryRootCampaignsArgs = {
  distinct_on?: InputMaybe<Array<CampaignsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignsOrderBy>>;
  where?: InputMaybe<CampaignsBoolExp>;
};


export type QueryRootCampaignsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CampaignsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignsOrderBy>>;
  where?: InputMaybe<CampaignsBoolExp>;
};


export type QueryRootCampaignsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChannelChatBotUsersArgs = {
  distinct_on?: InputMaybe<Array<ChannelChatBotUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelChatBotUsersOrderBy>>;
  where?: InputMaybe<ChannelChatBotUsersBoolExp>;
};


export type QueryRootChannelChatBotUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelChatBotUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelChatBotUsersOrderBy>>;
  where?: InputMaybe<ChannelChatBotUsersBoolExp>;
};


export type QueryRootChannelChatBotUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChannelMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelMerchantUsersOrderBy>>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};


export type QueryRootChannelMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelMerchantUsersOrderBy>>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};


export type QueryRootChannelMerchantUsersByPkArgs = {
  channelId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};


export type QueryRootChannelWorkingHoursArgs = {
  distinct_on?: InputMaybe<Array<ChannelWorkingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelWorkingHoursOrderBy>>;
  where?: InputMaybe<ChannelWorkingHoursBoolExp>;
};


export type QueryRootChannelWorkingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelWorkingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelWorkingHoursOrderBy>>;
  where?: InputMaybe<ChannelWorkingHoursBoolExp>;
};


export type QueryRootChannelWorkingHoursByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChannelsArgs = {
  distinct_on?: InputMaybe<Array<ChannelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelsOrderBy>>;
  where?: InputMaybe<ChannelsBoolExp>;
};


export type QueryRootChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelsOrderBy>>;
  where?: InputMaybe<ChannelsBoolExp>;
};


export type QueryRootChannelsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChatMessageChatBotAutoreplyTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesOrderBy>>;
  where?: InputMaybe<ChatMessageChatBotAutoreplyTypesBoolExp>;
};


export type QueryRootChatMessageChatBotAutoreplyTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesOrderBy>>;
  where?: InputMaybe<ChatMessageChatBotAutoreplyTypesBoolExp>;
};


export type QueryRootChatMessageChatBotAutoreplyTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootChatMessageFileAttachmentTranslationsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
};


export type QueryRootChatMessageFileAttachmentTranslationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
};


export type QueryRootChatMessageFileAttachmentTranslationsByPkArgs = {
  chatMessageFileAttachmentId: Scalars['uuid'];
  language: Scalars['String'];
};


export type QueryRootChatMessageFileAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
};


export type QueryRootChatMessageFileAttachmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
};


export type QueryRootChatMessageFileAttachmentsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChatMessageTemplatePlaceholderTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholderTypesBoolExp>;
};


export type QueryRootChatMessageTemplatePlaceholderTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholderTypesBoolExp>;
};


export type QueryRootChatMessageTemplatePlaceholderTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootChatMessageTemplatePlaceholdersArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholdersOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
};


export type QueryRootChatMessageTemplatePlaceholdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholdersOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
};


export type QueryRootChatMessageTemplatePlaceholdersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChatMessageTemplateTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateTypesBoolExp>;
};


export type QueryRootChatMessageTemplateTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateTypesBoolExp>;
};


export type QueryRootChatMessageTemplateTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootChatMessageTemplateValuesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
};


export type QueryRootChatMessageTemplateValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
};


export type QueryRootChatMessageTemplateValuesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChatMessageTemplatesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatesBoolExp>;
};


export type QueryRootChatMessageTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatesBoolExp>;
};


export type QueryRootChatMessageTemplatesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChatMessageTranslationsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageTranslationsBoolExp>;
};


export type QueryRootChatMessageTranslationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageTranslationsBoolExp>;
};


export type QueryRootChatMessageTranslationsByPkArgs = {
  chatMessageId: Scalars['uuid'];
  language: Scalars['String'];
};


export type QueryRootChatMessageTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTypesBoolExp>;
};


export type QueryRootChatMessageTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTypesBoolExp>;
};


export type QueryRootChatMessageTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootChatMessageWhatsappMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageWhatsappMessagesOrderBy>>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};


export type QueryRootChatMessageWhatsappMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageWhatsappMessagesOrderBy>>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};


export type QueryRootChatMessageWhatsappMessagesByPkArgs = {
  chatMessageId: Scalars['uuid'];
  whatsappMessageId: Scalars['uuid'];
};


export type QueryRootChatMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


export type QueryRootChatMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


export type QueryRootChatMessagesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChatMessagesEventLogsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesEventLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesEventLogsOrderBy>>;
  where?: InputMaybe<ChatMessagesEventLogsBoolExp>;
};


export type QueryRootChatMessagesEventLogsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesEventLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesEventLogsOrderBy>>;
  where?: InputMaybe<ChatMessagesEventLogsBoolExp>;
};


export type QueryRootChatMessagesEventLogsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChatSummariesArgs = {
  distinct_on?: InputMaybe<Array<ChatSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatSummariesOrderBy>>;
  where?: InputMaybe<ChatSummariesBoolExp>;
};


export type QueryRootChatSummariesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatSummariesOrderBy>>;
  where?: InputMaybe<ChatSummariesBoolExp>;
};


export type QueryRootChatSummariesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChatTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatTypesOrderBy>>;
  where?: InputMaybe<ChatTypesBoolExp>;
};


export type QueryRootChatTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatTypesOrderBy>>;
  where?: InputMaybe<ChatTypesBoolExp>;
};


export type QueryRootChatTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootChatUsersArgs = {
  distinct_on?: InputMaybe<Array<ChatUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatUsersOrderBy>>;
  where?: InputMaybe<ChatUsersBoolExp>;
};


export type QueryRootChatUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatUsersOrderBy>>;
  where?: InputMaybe<ChatUsersBoolExp>;
};


export type QueryRootChatUsersByPkArgs = {
  chatId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type QueryRootChatsArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type QueryRootChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type QueryRootChatsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootChatsEventLogsArgs = {
  distinct_on?: InputMaybe<Array<ChatsEventLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsEventLogsOrderBy>>;
  where?: InputMaybe<ChatsEventLogsBoolExp>;
};


export type QueryRootChatsEventLogsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsEventLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsEventLogsOrderBy>>;
  where?: InputMaybe<ChatsEventLogsBoolExp>;
};


export type QueryRootChatsEventLogsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactGroupsOrderBy>>;
  where?: InputMaybe<ContactGroupsBoolExp>;
};


export type QueryRootContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactGroupsOrderBy>>;
  where?: InputMaybe<ContactGroupsBoolExp>;
};


export type QueryRootContactGroupsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<CustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerUsersOrderBy>>;
  where?: InputMaybe<CustomerUsersBoolExp>;
};


export type QueryRootCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerUsersOrderBy>>;
  where?: InputMaybe<CustomerUsersBoolExp>;
};


export type QueryRootCustomerUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootEmailNotificationPreferencesArgs = {
  distinct_on?: InputMaybe<Array<EmailNotificationPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailNotificationPreferencesOrderBy>>;
  where?: InputMaybe<EmailNotificationPreferencesBoolExp>;
};


export type QueryRootEmailNotificationPreferencesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailNotificationPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailNotificationPreferencesOrderBy>>;
  where?: InputMaybe<EmailNotificationPreferencesBoolExp>;
};


export type QueryRootEmailNotificationPreferencesByPkArgs = {
  preference: Scalars['String'];
};


export type QueryRootFileMimeTypesArgs = {
  distinct_on?: InputMaybe<Array<FileMimeTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileMimeTypesOrderBy>>;
  where?: InputMaybe<FileMimeTypesBoolExp>;
};


export type QueryRootFileMimeTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileMimeTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileMimeTypesOrderBy>>;
  where?: InputMaybe<FileMimeTypesBoolExp>;
};


export type QueryRootFileMimeTypesByPkArgs = {
  mimeType: Scalars['String'];
};


export type QueryRootFilesArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type QueryRootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type QueryRootFilesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootGetWhatsappAccountConnectionArgs = {
  whatsappAccountId: Scalars['uuid'];
};


export type QueryRootMerchantApiUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantApiUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantApiUsersOrderBy>>;
  where?: InputMaybe<MerchantApiUsersBoolExp>;
};


export type QueryRootMerchantApiUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantApiUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantApiUsersOrderBy>>;
  where?: InputMaybe<MerchantApiUsersBoolExp>;
};


export type QueryRootMerchantApiUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMerchantCustomerUserContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};


export type QueryRootMerchantCustomerUserContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};


export type QueryRootMerchantCustomerUserContactGroupsByPkArgs = {
  contactGroupId: Scalars['uuid'];
  merchantCustomerUserId: Scalars['uuid'];
};


export type QueryRootMerchantCustomerUserTagsArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
};


export type QueryRootMerchantCustomerUserTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
};


export type QueryRootMerchantCustomerUserTagsByPkArgs = {
  merchantCustomerUserId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};


export type QueryRootMerchantCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


export type QueryRootMerchantCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


export type QueryRootMerchantCustomerUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMerchantMobileAppInstallationsArgs = {
  distinct_on?: InputMaybe<Array<MerchantMobileAppInstallationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantMobileAppInstallationsOrderBy>>;
  where?: InputMaybe<MerchantMobileAppInstallationsBoolExp>;
};


export type QueryRootMerchantMobileAppInstallationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantMobileAppInstallationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantMobileAppInstallationsOrderBy>>;
  where?: InputMaybe<MerchantMobileAppInstallationsBoolExp>;
};


export type QueryRootMerchantMobileAppInstallationsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMerchantSalesforceSyncConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsOrderBy>>;
  where?: InputMaybe<MerchantSalesforceSyncConfigurationsBoolExp>;
};


export type QueryRootMerchantSalesforceSyncConfigurationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsOrderBy>>;
  where?: InputMaybe<MerchantSalesforceSyncConfigurationsBoolExp>;
};


export type QueryRootMerchantSalesforceSyncConfigurationsByPkArgs = {
  merchantId: Scalars['uuid'];
};


export type QueryRootMerchantStoreMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoreMerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};


export type QueryRootMerchantStoreMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoreMerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};


export type QueryRootMerchantStoreMerchantUsersByPkArgs = {
  merchantStoreId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};


export type QueryRootMerchantStoresArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoresOrderBy>>;
  where?: InputMaybe<MerchantStoresBoolExp>;
};


export type QueryRootMerchantStoresAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoresOrderBy>>;
  where?: InputMaybe<MerchantStoresBoolExp>;
};


export type QueryRootMerchantStoresByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMerchantUserContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};


export type QueryRootMerchantUserContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};


export type QueryRootMerchantUserContactGroupsByPkArgs = {
  contactGroupId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};


export type QueryRootMerchantUserPasswordResetsArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserPasswordResetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserPasswordResetsOrderBy>>;
  where?: InputMaybe<MerchantUserPasswordResetsBoolExp>;
};


export type QueryRootMerchantUserPasswordResetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserPasswordResetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserPasswordResetsOrderBy>>;
  where?: InputMaybe<MerchantUserPasswordResetsBoolExp>;
};


export type QueryRootMerchantUserPasswordResetsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantUsersBoolExp>;
};


export type QueryRootMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantUsersBoolExp>;
};


export type QueryRootMerchantUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMerchantsArgs = {
  distinct_on?: InputMaybe<Array<MerchantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantsOrderBy>>;
  where?: InputMaybe<MerchantsBoolExp>;
};


export type QueryRootMerchantsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantsOrderBy>>;
  where?: InputMaybe<MerchantsBoolExp>;
};


export type QueryRootMerchantsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootMobileAppPlatformsArgs = {
  distinct_on?: InputMaybe<Array<MobileAppPlatformsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppPlatformsOrderBy>>;
  where?: InputMaybe<MobileAppPlatformsBoolExp>;
};


export type QueryRootMobileAppPlatformsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MobileAppPlatformsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppPlatformsOrderBy>>;
  where?: InputMaybe<MobileAppPlatformsBoolExp>;
};


export type QueryRootMobileAppPlatformsByPkArgs = {
  platform: Scalars['String'];
};


export type QueryRootMobileAppReleaseStatusesArgs = {
  distinct_on?: InputMaybe<Array<MobileAppReleaseStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppReleaseStatusesOrderBy>>;
  where?: InputMaybe<MobileAppReleaseStatusesBoolExp>;
};


export type QueryRootMobileAppReleaseStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MobileAppReleaseStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppReleaseStatusesOrderBy>>;
  where?: InputMaybe<MobileAppReleaseStatusesBoolExp>;
};


export type QueryRootMobileAppReleaseStatusesByPkArgs = {
  status: Scalars['String'];
};


export type QueryRootMobileAppReleasesArgs = {
  distinct_on?: InputMaybe<Array<MobileAppReleasesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppReleasesOrderBy>>;
  where?: InputMaybe<MobileAppReleasesBoolExp>;
};


export type QueryRootMobileAppReleasesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MobileAppReleasesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppReleasesOrderBy>>;
  where?: InputMaybe<MobileAppReleasesBoolExp>;
};


export type QueryRootMobileAppReleasesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootOrganizationMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationMerchantUsersOrderBy>>;
  where?: InputMaybe<OrganizationMerchantUsersBoolExp>;
};


export type QueryRootOrganizationMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationMerchantUsersOrderBy>>;
  where?: InputMaybe<OrganizationMerchantUsersBoolExp>;
};


export type QueryRootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type QueryRootOrganizationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type QueryRootOrganizationsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootSalesforceRecordAttributesArgs = {
  distinct_on?: InputMaybe<Array<SalesforceRecordAttributesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceRecordAttributesOrderBy>>;
  where?: InputMaybe<SalesforceRecordAttributesBoolExp>;
};


export type QueryRootSalesforceRecordAttributesAggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceRecordAttributesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceRecordAttributesOrderBy>>;
  where?: InputMaybe<SalesforceRecordAttributesBoolExp>;
};


export type QueryRootSalesforceRecordAttributesByPkArgs = {
  attribute: Scalars['String'];
};


export type QueryRootSecondaryResponsibleMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<SecondaryResponsibleMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecondaryResponsibleMerchantUsersOrderBy>>;
  where?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
};


export type QueryRootSecondaryResponsibleMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<SecondaryResponsibleMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecondaryResponsibleMerchantUsersOrderBy>>;
  where?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
};


export type QueryRootSecondaryResponsibleMerchantUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootTagsArgs = {
  distinct_on?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};


export type QueryRootTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};


export type QueryRootTagsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootToastMessagesArgs = {
  distinct_on?: InputMaybe<Array<ToastMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ToastMessagesOrderBy>>;
  where?: InputMaybe<ToastMessagesBoolExp>;
};


export type QueryRootToastMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ToastMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ToastMessagesOrderBy>>;
  where?: InputMaybe<ToastMessagesBoolExp>;
};


export type QueryRootToastMessagesByPkArgs = {
  message: Scalars['String'];
  pathname: Scalars['String'];
  type: Scalars['String'];
};


export type QueryRootTranslateTextArgs = {
  targetLanguage: Scalars['String'];
  text: Scalars['String'];
};


export type QueryRootUserChatsArgs = {
  distinct_on?: InputMaybe<Array<UserChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserChatsOrderBy>>;
  where?: InputMaybe<UserChatsBoolExp>;
};


export type QueryRootUserChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserChatsOrderBy>>;
  where?: InputMaybe<UserChatsBoolExp>;
};


export type QueryRootUserChatsByPkArgs = {
  chatId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type QueryRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type QueryRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type QueryRootUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootWhatsappAccountHostingTypesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountHostingTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountHostingTypesOrderBy>>;
  where?: InputMaybe<WhatsappAccountHostingTypesBoolExp>;
};


export type QueryRootWhatsappAccountHostingTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountHostingTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountHostingTypesOrderBy>>;
  where?: InputMaybe<WhatsappAccountHostingTypesBoolExp>;
};


export type QueryRootWhatsappAccountHostingTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootWhatsappAccountProvidersArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountProvidersOrderBy>>;
  where?: InputMaybe<WhatsappAccountProvidersBoolExp>;
};


export type QueryRootWhatsappAccountProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountProvidersOrderBy>>;
  where?: InputMaybe<WhatsappAccountProvidersBoolExp>;
};


export type QueryRootWhatsappAccountProvidersByPkArgs = {
  provider: Scalars['String'];
};


export type QueryRootWhatsappAccountsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountsOrderBy>>;
  where?: InputMaybe<WhatsappAccountsBoolExp>;
};


export type QueryRootWhatsappAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountsOrderBy>>;
  where?: InputMaybe<WhatsappAccountsBoolExp>;
};


export type QueryRootWhatsappAccountsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootWhatsappBusinessAccountsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessAccountsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
};


export type QueryRootWhatsappBusinessAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessAccountsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
};


export type QueryRootWhatsappBusinessAccountsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootWhatsappBusinessVerticalsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessVerticalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessVerticalsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessVerticalsBoolExp>;
};


export type QueryRootWhatsappBusinessVerticalsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessVerticalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessVerticalsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessVerticalsBoolExp>;
};


export type QueryRootWhatsappBusinessVerticalsByPkArgs = {
  vertical: Scalars['String'];
};


export type QueryRootWhatsappConversationTypesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappConversationTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappConversationTypesOrderBy>>;
  where?: InputMaybe<WhatsappConversationTypesBoolExp>;
};


export type QueryRootWhatsappConversationTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappConversationTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappConversationTypesOrderBy>>;
  where?: InputMaybe<WhatsappConversationTypesBoolExp>;
};


export type QueryRootWhatsappConversationTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootWhatsappConversationsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappConversationsOrderBy>>;
  where?: InputMaybe<WhatsappConversationsBoolExp>;
};


export type QueryRootWhatsappConversationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappConversationsOrderBy>>;
  where?: InputMaybe<WhatsappConversationsBoolExp>;
};


export type QueryRootWhatsappConversationsByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootWhatsappMediaTypesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMediaTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMediaTypesOrderBy>>;
  where?: InputMaybe<WhatsappMediaTypesBoolExp>;
};


export type QueryRootWhatsappMediaTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMediaTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMediaTypesOrderBy>>;
  where?: InputMaybe<WhatsappMediaTypesBoolExp>;
};


export type QueryRootWhatsappMediaTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootWhatsappMessageDirectionsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageDirectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageDirectionsOrderBy>>;
  where?: InputMaybe<WhatsappMessageDirectionsBoolExp>;
};


export type QueryRootWhatsappMessageDirectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageDirectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageDirectionsOrderBy>>;
  where?: InputMaybe<WhatsappMessageDirectionsBoolExp>;
};


export type QueryRootWhatsappMessageDirectionsByPkArgs = {
  direction: Scalars['String'];
};


export type QueryRootWhatsappMessageStatusesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageStatusesOrderBy>>;
  where?: InputMaybe<WhatsappMessageStatusesBoolExp>;
};


export type QueryRootWhatsappMessageStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageStatusesOrderBy>>;
  where?: InputMaybe<WhatsappMessageStatusesBoolExp>;
};


export type QueryRootWhatsappMessageStatusesByPkArgs = {
  status: Scalars['String'];
};


export type QueryRootWhatsappMessageTemplateStatusesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateStatusesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateStatusesBoolExp>;
};


export type QueryRootWhatsappMessageTemplateStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateStatusesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateStatusesBoolExp>;
};


export type QueryRootWhatsappMessageTemplateStatusesByPkArgs = {
  status: Scalars['String'];
};


export type QueryRootWhatsappMessageTemplateValuesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
};


export type QueryRootWhatsappMessageTemplateValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
};


export type QueryRootWhatsappMessageTemplateValuesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootWhatsappMessageTemplatesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplatesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};


export type QueryRootWhatsappMessageTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplatesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};


export type QueryRootWhatsappMessageTemplatesByPkArgs = {
  id: Scalars['uuid'];
};


export type QueryRootWhatsappMessageTypesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTypesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTypesBoolExp>;
};


export type QueryRootWhatsappMessageTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTypesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTypesBoolExp>;
};


export type QueryRootWhatsappMessageTypesByPkArgs = {
  type: Scalars['String'];
};


export type QueryRootWhatsappMessagesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessagesOrderBy>>;
  where?: InputMaybe<WhatsappMessagesBoolExp>;
};


export type QueryRootWhatsappMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessagesOrderBy>>;
  where?: InputMaybe<WhatsappMessagesBoolExp>;
};


export type QueryRootWhatsappMessagesByPkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "salesforce_record_attributes" */
export type SalesforceRecordAttributes = {
  __typename?: 'salesforce_record_attributes';
  attribute: Scalars['String'];
};

/** aggregated selection of "salesforce_record_attributes" */
export type SalesforceRecordAttributesAggregate = {
  __typename?: 'salesforce_record_attributes_aggregate';
  aggregate?: Maybe<SalesforceRecordAttributesAggregateFields>;
  nodes: Array<SalesforceRecordAttributes>;
};

/** aggregate fields of "salesforce_record_attributes" */
export type SalesforceRecordAttributesAggregateFields = {
  __typename?: 'salesforce_record_attributes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SalesforceRecordAttributesMaxFields>;
  min?: Maybe<SalesforceRecordAttributesMinFields>;
};


/** aggregate fields of "salesforce_record_attributes" */
export type SalesforceRecordAttributesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SalesforceRecordAttributesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "salesforce_record_attributes". All fields are combined with a logical 'AND'. */
export type SalesforceRecordAttributesBoolExp = {
  _and?: InputMaybe<Array<SalesforceRecordAttributesBoolExp>>;
  _not?: InputMaybe<SalesforceRecordAttributesBoolExp>;
  _or?: InputMaybe<Array<SalesforceRecordAttributesBoolExp>>;
  attribute?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "salesforce_record_attributes" */
export enum SalesforceRecordAttributesConstraint {
  /** unique or primary key constraint on columns "attribute" */
  SalesforceRecordAttributesPkey = 'salesforce_record_attributes_pkey'
}

export enum SalesforceRecordAttributesEnum {
  Contact = 'Contact',
  Lead = 'Lead'
}

/** Boolean expression to compare columns of type "salesforce_record_attributes_enum". All fields are combined with logical 'AND'. */
export type SalesforceRecordAttributesEnumComparisonExp = {
  _eq?: InputMaybe<SalesforceRecordAttributesEnum>;
  _in?: InputMaybe<Array<SalesforceRecordAttributesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SalesforceRecordAttributesEnum>;
  _nin?: InputMaybe<Array<SalesforceRecordAttributesEnum>>;
};

/** input type for inserting data into table "salesforce_record_attributes" */
export type SalesforceRecordAttributesInsertInput = {
  attribute?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SalesforceRecordAttributesMaxFields = {
  __typename?: 'salesforce_record_attributes_max_fields';
  attribute?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SalesforceRecordAttributesMinFields = {
  __typename?: 'salesforce_record_attributes_min_fields';
  attribute?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "salesforce_record_attributes" */
export type SalesforceRecordAttributesMutationResponse = {
  __typename?: 'salesforce_record_attributes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SalesforceRecordAttributes>;
};

/** on_conflict condition type for table "salesforce_record_attributes" */
export type SalesforceRecordAttributesOnConflict = {
  constraint: SalesforceRecordAttributesConstraint;
  update_columns?: Array<SalesforceRecordAttributesUpdateColumn>;
  where?: InputMaybe<SalesforceRecordAttributesBoolExp>;
};

/** Ordering options when selecting data from "salesforce_record_attributes". */
export type SalesforceRecordAttributesOrderBy = {
  attribute?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: salesforce_record_attributes */
export type SalesforceRecordAttributesPkColumnsInput = {
  attribute: Scalars['String'];
};

/** select columns of table "salesforce_record_attributes" */
export enum SalesforceRecordAttributesSelectColumn {
  /** column name */
  Attribute = 'attribute'
}

/** input type for updating data in table "salesforce_record_attributes" */
export type SalesforceRecordAttributesSetInput = {
  attribute?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "salesforce_record_attributes" */
export type SalesforceRecordAttributesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SalesforceRecordAttributesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SalesforceRecordAttributesStreamCursorValueInput = {
  attribute?: InputMaybe<Scalars['String']>;
};

/** update columns of table "salesforce_record_attributes" */
export enum SalesforceRecordAttributesUpdateColumn {
  /** column name */
  Attribute = 'attribute'
}

export type SalesforceRecordAttributesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SalesforceRecordAttributesSetInput>;
  /** filter the rows which have to be updated */
  where: SalesforceRecordAttributesBoolExp;
};

/** columns and relationships of "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsers = {
  __typename?: 'secondary_responsible_merchant_users';
  id: Scalars['uuid'];
  /** An object relationship */
  merchantCustomerUser: MerchantCustomerUsers;
  merchantCustomerUserId: Scalars['uuid'];
  /** An object relationship */
  merchantUser: MerchantUsers;
  merchantUserId: Scalars['uuid'];
};

/** aggregated selection of "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersAggregate = {
  __typename?: 'secondary_responsible_merchant_users_aggregate';
  aggregate?: Maybe<SecondaryResponsibleMerchantUsersAggregateFields>;
  nodes: Array<SecondaryResponsibleMerchantUsers>;
};

export type SecondaryResponsibleMerchantUsersAggregateBoolExp = {
  count?: InputMaybe<SecondaryResponsibleMerchantUsersAggregateBoolExpCount>;
};

export type SecondaryResponsibleMerchantUsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SecondaryResponsibleMerchantUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersAggregateFields = {
  __typename?: 'secondary_responsible_merchant_users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SecondaryResponsibleMerchantUsersMaxFields>;
  min?: Maybe<SecondaryResponsibleMerchantUsersMinFields>;
};


/** aggregate fields of "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SecondaryResponsibleMerchantUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SecondaryResponsibleMerchantUsersMaxOrderBy>;
  min?: InputMaybe<SecondaryResponsibleMerchantUsersMinOrderBy>;
};

/** input type for inserting array relation for remote table "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersArrRelInsertInput = {
  data: Array<SecondaryResponsibleMerchantUsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SecondaryResponsibleMerchantUsersOnConflict>;
};

/** Boolean expression to filter rows from the table "secondary_responsible_merchant_users". All fields are combined with a logical 'AND'. */
export type SecondaryResponsibleMerchantUsersBoolExp = {
  _and?: InputMaybe<Array<SecondaryResponsibleMerchantUsersBoolExp>>;
  _not?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
  _or?: InputMaybe<Array<SecondaryResponsibleMerchantUsersBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersBoolExp>;
  merchantCustomerUserId?: InputMaybe<UuidComparisonExp>;
  merchantUser?: InputMaybe<MerchantUsersBoolExp>;
  merchantUserId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "secondary_responsible_merchant_users" */
export enum SecondaryResponsibleMerchantUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  SecondaryResponsibleMerchantUsersPkey = 'secondary_responsible_merchant_users_pkey'
}

/** input type for inserting data into table "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersObjRelInsertInput>;
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
  merchantUser?: InputMaybe<MerchantUsersObjRelInsertInput>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SecondaryResponsibleMerchantUsersMaxFields = {
  __typename?: 'secondary_responsible_merchant_users_max_fields';
  id?: Maybe<Scalars['uuid']>;
  merchantCustomerUserId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchantCustomerUserId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SecondaryResponsibleMerchantUsersMinFields = {
  __typename?: 'secondary_responsible_merchant_users_min_fields';
  id?: Maybe<Scalars['uuid']>;
  merchantCustomerUserId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchantCustomerUserId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersMutationResponse = {
  __typename?: 'secondary_responsible_merchant_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SecondaryResponsibleMerchantUsers>;
};

/** on_conflict condition type for table "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersOnConflict = {
  constraint: SecondaryResponsibleMerchantUsersConstraint;
  update_columns?: Array<SecondaryResponsibleMerchantUsersUpdateColumn>;
  where?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
};

/** Ordering options when selecting data from "secondary_responsible_merchant_users". */
export type SecondaryResponsibleMerchantUsersOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchantCustomerUser?: InputMaybe<MerchantCustomerUsersOrderBy>;
  merchantCustomerUserId?: InputMaybe<OrderBy>;
  merchantUser?: InputMaybe<MerchantUsersOrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: secondary_responsible_merchant_users */
export type SecondaryResponsibleMerchantUsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "secondary_responsible_merchant_users" */
export enum SecondaryResponsibleMerchantUsersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MerchantCustomerUserId = 'merchantCustomerUserId',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

/** input type for updating data in table "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "secondary_responsible_merchant_users" */
export type SecondaryResponsibleMerchantUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SecondaryResponsibleMerchantUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SecondaryResponsibleMerchantUsersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantCustomerUserId?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "secondary_responsible_merchant_users" */
export enum SecondaryResponsibleMerchantUsersUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MerchantCustomerUserId = 'merchantCustomerUserId',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

export type SecondaryResponsibleMerchantUsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SecondaryResponsibleMerchantUsersSetInput>;
  /** filter the rows which have to be updated */
  where: SecondaryResponsibleMerchantUsersBoolExp;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "aggregated_merchant_customer_user_tags" */
  aggregated_merchant_customer_user_tags: Array<AggregatedMerchantCustomerUserTags>;
  /** fetch aggregated fields from the table: "aggregated_merchant_customer_user_tags" */
  aggregated_merchant_customer_user_tags_aggregate: AggregatedMerchantCustomerUserTagsAggregate;
  /** fetch data from the table in a streaming manner: "aggregated_merchant_customer_user_tags" */
  aggregated_merchant_customer_user_tags_stream: Array<AggregatedMerchantCustomerUserTags>;
  /** fetch data from the table: "campaign_audience_types" */
  campaign_audience_types: Array<CampaignAudienceTypes>;
  /** fetch aggregated fields from the table: "campaign_audience_types" */
  campaign_audience_types_aggregate: CampaignAudienceTypesAggregate;
  /** fetch data from the table: "campaign_audience_types" using primary key columns */
  campaign_audience_types_by_pk?: Maybe<CampaignAudienceTypes>;
  /** fetch data from the table in a streaming manner: "campaign_audience_types" */
  campaign_audience_types_stream: Array<CampaignAudienceTypes>;
  /** fetch data from the table: "campaign_customer_users" */
  campaign_customer_users: Array<CampaignCustomerUsers>;
  /** fetch aggregated fields from the table: "campaign_customer_users" */
  campaign_customer_users_aggregate: CampaignCustomerUsersAggregate;
  /** fetch data from the table: "campaign_customer_users" using primary key columns */
  campaign_customer_users_by_pk?: Maybe<CampaignCustomerUsers>;
  /** fetch data from the table in a streaming manner: "campaign_customer_users" */
  campaign_customer_users_stream: Array<CampaignCustomerUsers>;
  /** fetch data from the table: "campaigns" */
  campaigns: Array<Campaigns>;
  /** fetch aggregated fields from the table: "campaigns" */
  campaigns_aggregate: CampaignsAggregate;
  /** fetch data from the table: "campaigns" using primary key columns */
  campaigns_by_pk?: Maybe<Campaigns>;
  /** fetch data from the table in a streaming manner: "campaigns" */
  campaigns_stream: Array<Campaigns>;
  /** fetch data from the table: "channel_chat_bot_users" */
  channel_chat_bot_users: Array<ChannelChatBotUsers>;
  /** fetch aggregated fields from the table: "channel_chat_bot_users" */
  channel_chat_bot_users_aggregate: ChannelChatBotUsersAggregate;
  /** fetch data from the table: "channel_chat_bot_users" using primary key columns */
  channel_chat_bot_users_by_pk?: Maybe<ChannelChatBotUsers>;
  /** fetch data from the table in a streaming manner: "channel_chat_bot_users" */
  channel_chat_bot_users_stream: Array<ChannelChatBotUsers>;
  /** fetch data from the table: "channel_merchant_users" */
  channel_merchant_users: Array<ChannelMerchantUsers>;
  /** fetch aggregated fields from the table: "channel_merchant_users" */
  channel_merchant_users_aggregate: ChannelMerchantUsersAggregate;
  /** fetch data from the table: "channel_merchant_users" using primary key columns */
  channel_merchant_users_by_pk?: Maybe<ChannelMerchantUsers>;
  /** fetch data from the table in a streaming manner: "channel_merchant_users" */
  channel_merchant_users_stream: Array<ChannelMerchantUsers>;
  /** fetch data from the table: "channel_working_hours" */
  channel_working_hours: Array<ChannelWorkingHours>;
  /** fetch aggregated fields from the table: "channel_working_hours" */
  channel_working_hours_aggregate: ChannelWorkingHoursAggregate;
  /** fetch data from the table: "channel_working_hours" using primary key columns */
  channel_working_hours_by_pk?: Maybe<ChannelWorkingHours>;
  /** fetch data from the table in a streaming manner: "channel_working_hours" */
  channel_working_hours_stream: Array<ChannelWorkingHours>;
  /** An array relationship */
  channels: Array<Channels>;
  /** An aggregate relationship */
  channels_aggregate: ChannelsAggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table in a streaming manner: "channels" */
  channels_stream: Array<Channels>;
  /** fetch data from the table: "chat_message_chat_bot_autoreply_types" */
  chat_message_chat_bot_autoreply_types: Array<ChatMessageChatBotAutoreplyTypes>;
  /** fetch aggregated fields from the table: "chat_message_chat_bot_autoreply_types" */
  chat_message_chat_bot_autoreply_types_aggregate: ChatMessageChatBotAutoreplyTypesAggregate;
  /** fetch data from the table: "chat_message_chat_bot_autoreply_types" using primary key columns */
  chat_message_chat_bot_autoreply_types_by_pk?: Maybe<ChatMessageChatBotAutoreplyTypes>;
  /** fetch data from the table in a streaming manner: "chat_message_chat_bot_autoreply_types" */
  chat_message_chat_bot_autoreply_types_stream: Array<ChatMessageChatBotAutoreplyTypes>;
  /** fetch data from the table: "chat_message_file_attachment_translations" */
  chat_message_file_attachment_translations: Array<ChatMessageFileAttachmentTranslations>;
  /** fetch aggregated fields from the table: "chat_message_file_attachment_translations" */
  chat_message_file_attachment_translations_aggregate: ChatMessageFileAttachmentTranslationsAggregate;
  /** fetch data from the table: "chat_message_file_attachment_translations" using primary key columns */
  chat_message_file_attachment_translations_by_pk?: Maybe<ChatMessageFileAttachmentTranslations>;
  /** fetch data from the table in a streaming manner: "chat_message_file_attachment_translations" */
  chat_message_file_attachment_translations_stream: Array<ChatMessageFileAttachmentTranslations>;
  /** fetch data from the table: "chat_message_file_attachments" */
  chat_message_file_attachments: Array<ChatMessageFileAttachments>;
  /** fetch aggregated fields from the table: "chat_message_file_attachments" */
  chat_message_file_attachments_aggregate: ChatMessageFileAttachmentsAggregate;
  /** fetch data from the table: "chat_message_file_attachments" using primary key columns */
  chat_message_file_attachments_by_pk?: Maybe<ChatMessageFileAttachments>;
  /** fetch data from the table in a streaming manner: "chat_message_file_attachments" */
  chat_message_file_attachments_stream: Array<ChatMessageFileAttachments>;
  /** fetch data from the table: "chat_message_template_placeholder_types" */
  chat_message_template_placeholder_types: Array<ChatMessageTemplatePlaceholderTypes>;
  /** fetch aggregated fields from the table: "chat_message_template_placeholder_types" */
  chat_message_template_placeholder_types_aggregate: ChatMessageTemplatePlaceholderTypesAggregate;
  /** fetch data from the table: "chat_message_template_placeholder_types" using primary key columns */
  chat_message_template_placeholder_types_by_pk?: Maybe<ChatMessageTemplatePlaceholderTypes>;
  /** fetch data from the table in a streaming manner: "chat_message_template_placeholder_types" */
  chat_message_template_placeholder_types_stream: Array<ChatMessageTemplatePlaceholderTypes>;
  /** fetch data from the table: "chat_message_template_placeholders" */
  chat_message_template_placeholders: Array<ChatMessageTemplatePlaceholders>;
  /** fetch aggregated fields from the table: "chat_message_template_placeholders" */
  chat_message_template_placeholders_aggregate: ChatMessageTemplatePlaceholdersAggregate;
  /** fetch data from the table: "chat_message_template_placeholders" using primary key columns */
  chat_message_template_placeholders_by_pk?: Maybe<ChatMessageTemplatePlaceholders>;
  /** fetch data from the table in a streaming manner: "chat_message_template_placeholders" */
  chat_message_template_placeholders_stream: Array<ChatMessageTemplatePlaceholders>;
  /** fetch data from the table: "chat_message_template_types" */
  chat_message_template_types: Array<ChatMessageTemplateTypes>;
  /** fetch aggregated fields from the table: "chat_message_template_types" */
  chat_message_template_types_aggregate: ChatMessageTemplateTypesAggregate;
  /** fetch data from the table: "chat_message_template_types" using primary key columns */
  chat_message_template_types_by_pk?: Maybe<ChatMessageTemplateTypes>;
  /** fetch data from the table in a streaming manner: "chat_message_template_types" */
  chat_message_template_types_stream: Array<ChatMessageTemplateTypes>;
  /** fetch data from the table: "chat_message_template_values" */
  chat_message_template_values: Array<ChatMessageTemplateValues>;
  /** fetch aggregated fields from the table: "chat_message_template_values" */
  chat_message_template_values_aggregate: ChatMessageTemplateValuesAggregate;
  /** fetch data from the table: "chat_message_template_values" using primary key columns */
  chat_message_template_values_by_pk?: Maybe<ChatMessageTemplateValues>;
  /** fetch data from the table in a streaming manner: "chat_message_template_values" */
  chat_message_template_values_stream: Array<ChatMessageTemplateValues>;
  /** fetch data from the table: "chat_message_templates" */
  chat_message_templates: Array<ChatMessageTemplates>;
  /** fetch aggregated fields from the table: "chat_message_templates" */
  chat_message_templates_aggregate: ChatMessageTemplatesAggregate;
  /** fetch data from the table: "chat_message_templates" using primary key columns */
  chat_message_templates_by_pk?: Maybe<ChatMessageTemplates>;
  /** fetch data from the table in a streaming manner: "chat_message_templates" */
  chat_message_templates_stream: Array<ChatMessageTemplates>;
  /** fetch data from the table: "chat_message_translations" */
  chat_message_translations: Array<ChatMessageTranslations>;
  /** fetch aggregated fields from the table: "chat_message_translations" */
  chat_message_translations_aggregate: ChatMessageTranslationsAggregate;
  /** fetch data from the table: "chat_message_translations" using primary key columns */
  chat_message_translations_by_pk?: Maybe<ChatMessageTranslations>;
  /** fetch data from the table in a streaming manner: "chat_message_translations" */
  chat_message_translations_stream: Array<ChatMessageTranslations>;
  /** fetch data from the table: "chat_message_types" */
  chat_message_types: Array<ChatMessageTypes>;
  /** fetch aggregated fields from the table: "chat_message_types" */
  chat_message_types_aggregate: ChatMessageTypesAggregate;
  /** fetch data from the table: "chat_message_types" using primary key columns */
  chat_message_types_by_pk?: Maybe<ChatMessageTypes>;
  /** fetch data from the table in a streaming manner: "chat_message_types" */
  chat_message_types_stream: Array<ChatMessageTypes>;
  /** fetch data from the table: "chat_message_whatsapp_messages" */
  chat_message_whatsapp_messages: Array<ChatMessageWhatsappMessages>;
  /** fetch aggregated fields from the table: "chat_message_whatsapp_messages" */
  chat_message_whatsapp_messages_aggregate: ChatMessageWhatsappMessagesAggregate;
  /** fetch data from the table: "chat_message_whatsapp_messages" using primary key columns */
  chat_message_whatsapp_messages_by_pk?: Maybe<ChatMessageWhatsappMessages>;
  /** fetch data from the table in a streaming manner: "chat_message_whatsapp_messages" */
  chat_message_whatsapp_messages_stream: Array<ChatMessageWhatsappMessages>;
  /** fetch data from the table: "chat_messages" */
  chat_messages: Array<ChatMessages>;
  /** fetch aggregated fields from the table: "chat_messages" */
  chat_messages_aggregate: ChatMessagesAggregate;
  /** fetch data from the table: "chat_messages" using primary key columns */
  chat_messages_by_pk?: Maybe<ChatMessages>;
  /** fetch data from the table: "chat_messages_event_logs" */
  chat_messages_event_logs: Array<ChatMessagesEventLogs>;
  /** fetch aggregated fields from the table: "chat_messages_event_logs" */
  chat_messages_event_logs_aggregate: ChatMessagesEventLogsAggregate;
  /** fetch data from the table: "chat_messages_event_logs" using primary key columns */
  chat_messages_event_logs_by_pk?: Maybe<ChatMessagesEventLogs>;
  /** fetch data from the table in a streaming manner: "chat_messages_event_logs" */
  chat_messages_event_logs_stream: Array<ChatMessagesEventLogs>;
  /** fetch data from the table in a streaming manner: "chat_messages" */
  chat_messages_stream: Array<ChatMessages>;
  /** fetch data from the table: "chat_summaries" */
  chat_summaries: Array<ChatSummaries>;
  /** fetch aggregated fields from the table: "chat_summaries" */
  chat_summaries_aggregate: ChatSummariesAggregate;
  /** fetch data from the table: "chat_summaries" using primary key columns */
  chat_summaries_by_pk?: Maybe<ChatSummaries>;
  /** fetch data from the table in a streaming manner: "chat_summaries" */
  chat_summaries_stream: Array<ChatSummaries>;
  /** fetch data from the table: "chat_types" */
  chat_types: Array<ChatTypes>;
  /** fetch aggregated fields from the table: "chat_types" */
  chat_types_aggregate: ChatTypesAggregate;
  /** fetch data from the table: "chat_types" using primary key columns */
  chat_types_by_pk?: Maybe<ChatTypes>;
  /** fetch data from the table in a streaming manner: "chat_types" */
  chat_types_stream: Array<ChatTypes>;
  /** fetch data from the table: "chat_users" */
  chat_users: Array<ChatUsers>;
  /** fetch aggregated fields from the table: "chat_users" */
  chat_users_aggregate: ChatUsersAggregate;
  /** fetch data from the table: "chat_users" using primary key columns */
  chat_users_by_pk?: Maybe<ChatUsers>;
  /** fetch data from the table in a streaming manner: "chat_users" */
  chat_users_stream: Array<ChatUsers>;
  /** An array relationship */
  chats: Array<Chats>;
  /** An aggregate relationship */
  chats_aggregate: ChatsAggregate;
  /** fetch data from the table: "chats" using primary key columns */
  chats_by_pk?: Maybe<Chats>;
  /** fetch data from the table: "chats_event_logs" */
  chats_event_logs: Array<ChatsEventLogs>;
  /** fetch aggregated fields from the table: "chats_event_logs" */
  chats_event_logs_aggregate: ChatsEventLogsAggregate;
  /** fetch data from the table: "chats_event_logs" using primary key columns */
  chats_event_logs_by_pk?: Maybe<ChatsEventLogs>;
  /** fetch data from the table in a streaming manner: "chats_event_logs" */
  chats_event_logs_stream: Array<ChatsEventLogs>;
  /** fetch data from the table in a streaming manner: "chats" */
  chats_stream: Array<Chats>;
  /** fetch data from the table: "contact_groups" */
  contact_groups: Array<ContactGroups>;
  /** fetch aggregated fields from the table: "contact_groups" */
  contact_groups_aggregate: ContactGroupsAggregate;
  /** fetch data from the table: "contact_groups" using primary key columns */
  contact_groups_by_pk?: Maybe<ContactGroups>;
  /** fetch data from the table in a streaming manner: "contact_groups" */
  contact_groups_stream: Array<ContactGroups>;
  /** fetch data from the table: "customer_users" */
  customer_users: Array<CustomerUsers>;
  /** fetch aggregated fields from the table: "customer_users" */
  customer_users_aggregate: CustomerUsersAggregate;
  /** fetch data from the table: "customer_users" using primary key columns */
  customer_users_by_pk?: Maybe<CustomerUsers>;
  /** fetch data from the table in a streaming manner: "customer_users" */
  customer_users_stream: Array<CustomerUsers>;
  /** fetch data from the table: "email_notification_preferences" */
  email_notification_preferences: Array<EmailNotificationPreferences>;
  /** fetch aggregated fields from the table: "email_notification_preferences" */
  email_notification_preferences_aggregate: EmailNotificationPreferencesAggregate;
  /** fetch data from the table: "email_notification_preferences" using primary key columns */
  email_notification_preferences_by_pk?: Maybe<EmailNotificationPreferences>;
  /** fetch data from the table in a streaming manner: "email_notification_preferences" */
  email_notification_preferences_stream: Array<EmailNotificationPreferences>;
  /** fetch data from the table: "file_mime_types" */
  file_mime_types: Array<FileMimeTypes>;
  /** fetch aggregated fields from the table: "file_mime_types" */
  file_mime_types_aggregate: FileMimeTypesAggregate;
  /** fetch data from the table: "file_mime_types" using primary key columns */
  file_mime_types_by_pk?: Maybe<FileMimeTypes>;
  /** fetch data from the table in a streaming manner: "file_mime_types" */
  file_mime_types_stream: Array<FileMimeTypes>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: FilesAggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table in a streaming manner: "files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "merchant_api_users" */
  merchant_api_users: Array<MerchantApiUsers>;
  /** fetch aggregated fields from the table: "merchant_api_users" */
  merchant_api_users_aggregate: MerchantApiUsersAggregate;
  /** fetch data from the table: "merchant_api_users" using primary key columns */
  merchant_api_users_by_pk?: Maybe<MerchantApiUsers>;
  /** fetch data from the table in a streaming manner: "merchant_api_users" */
  merchant_api_users_stream: Array<MerchantApiUsers>;
  /** fetch data from the table: "merchant_customer_user_contact_groups" */
  merchant_customer_user_contact_groups: Array<MerchantCustomerUserContactGroups>;
  /** fetch aggregated fields from the table: "merchant_customer_user_contact_groups" */
  merchant_customer_user_contact_groups_aggregate: MerchantCustomerUserContactGroupsAggregate;
  /** fetch data from the table: "merchant_customer_user_contact_groups" using primary key columns */
  merchant_customer_user_contact_groups_by_pk?: Maybe<MerchantCustomerUserContactGroups>;
  /** fetch data from the table in a streaming manner: "merchant_customer_user_contact_groups" */
  merchant_customer_user_contact_groups_stream: Array<MerchantCustomerUserContactGroups>;
  /** fetch data from the table: "merchant_customer_user_tags" */
  merchant_customer_user_tags: Array<MerchantCustomerUserTags>;
  /** fetch aggregated fields from the table: "merchant_customer_user_tags" */
  merchant_customer_user_tags_aggregate: MerchantCustomerUserTagsAggregate;
  /** fetch data from the table: "merchant_customer_user_tags" using primary key columns */
  merchant_customer_user_tags_by_pk?: Maybe<MerchantCustomerUserTags>;
  /** fetch data from the table in a streaming manner: "merchant_customer_user_tags" */
  merchant_customer_user_tags_stream: Array<MerchantCustomerUserTags>;
  /** fetch data from the table: "merchant_customer_users" */
  merchant_customer_users: Array<MerchantCustomerUsers>;
  /** fetch aggregated fields from the table: "merchant_customer_users" */
  merchant_customer_users_aggregate: MerchantCustomerUsersAggregate;
  /** fetch data from the table: "merchant_customer_users" using primary key columns */
  merchant_customer_users_by_pk?: Maybe<MerchantCustomerUsers>;
  /** fetch data from the table in a streaming manner: "merchant_customer_users" */
  merchant_customer_users_stream: Array<MerchantCustomerUsers>;
  /** fetch data from the table: "merchant_mobile_app_installations" */
  merchant_mobile_app_installations: Array<MerchantMobileAppInstallations>;
  /** fetch aggregated fields from the table: "merchant_mobile_app_installations" */
  merchant_mobile_app_installations_aggregate: MerchantMobileAppInstallationsAggregate;
  /** fetch data from the table: "merchant_mobile_app_installations" using primary key columns */
  merchant_mobile_app_installations_by_pk?: Maybe<MerchantMobileAppInstallations>;
  /** fetch data from the table in a streaming manner: "merchant_mobile_app_installations" */
  merchant_mobile_app_installations_stream: Array<MerchantMobileAppInstallations>;
  /** fetch data from the table: "merchant_salesforce_sync_configurations" */
  merchant_salesforce_sync_configurations: Array<MerchantSalesforceSyncConfigurations>;
  /** fetch aggregated fields from the table: "merchant_salesforce_sync_configurations" */
  merchant_salesforce_sync_configurations_aggregate: MerchantSalesforceSyncConfigurationsAggregate;
  /** fetch data from the table: "merchant_salesforce_sync_configurations" using primary key columns */
  merchant_salesforce_sync_configurations_by_pk?: Maybe<MerchantSalesforceSyncConfigurations>;
  /** fetch data from the table in a streaming manner: "merchant_salesforce_sync_configurations" */
  merchant_salesforce_sync_configurations_stream: Array<MerchantSalesforceSyncConfigurations>;
  /** fetch data from the table: "merchant_store_merchant_users" */
  merchant_store_merchant_users: Array<MerchantStoreMerchantUsers>;
  /** fetch aggregated fields from the table: "merchant_store_merchant_users" */
  merchant_store_merchant_users_aggregate: MerchantStoreMerchantUsersAggregate;
  /** fetch data from the table: "merchant_store_merchant_users" using primary key columns */
  merchant_store_merchant_users_by_pk?: Maybe<MerchantStoreMerchantUsers>;
  /** fetch data from the table in a streaming manner: "merchant_store_merchant_users" */
  merchant_store_merchant_users_stream: Array<MerchantStoreMerchantUsers>;
  /** fetch data from the table: "merchant_stores" */
  merchant_stores: Array<MerchantStores>;
  /** fetch aggregated fields from the table: "merchant_stores" */
  merchant_stores_aggregate: MerchantStoresAggregate;
  /** fetch data from the table: "merchant_stores" using primary key columns */
  merchant_stores_by_pk?: Maybe<MerchantStores>;
  /** fetch data from the table in a streaming manner: "merchant_stores" */
  merchant_stores_stream: Array<MerchantStores>;
  /** fetch data from the table: "merchant_user_contact_groups" */
  merchant_user_contact_groups: Array<MerchantUserContactGroups>;
  /** fetch aggregated fields from the table: "merchant_user_contact_groups" */
  merchant_user_contact_groups_aggregate: MerchantUserContactGroupsAggregate;
  /** fetch data from the table: "merchant_user_contact_groups" using primary key columns */
  merchant_user_contact_groups_by_pk?: Maybe<MerchantUserContactGroups>;
  /** fetch data from the table in a streaming manner: "merchant_user_contact_groups" */
  merchant_user_contact_groups_stream: Array<MerchantUserContactGroups>;
  /** fetch data from the table: "merchant_user_password_resets" */
  merchant_user_password_resets: Array<MerchantUserPasswordResets>;
  /** fetch aggregated fields from the table: "merchant_user_password_resets" */
  merchant_user_password_resets_aggregate: MerchantUserPasswordResetsAggregate;
  /** fetch data from the table: "merchant_user_password_resets" using primary key columns */
  merchant_user_password_resets_by_pk?: Maybe<MerchantUserPasswordResets>;
  /** fetch data from the table in a streaming manner: "merchant_user_password_resets" */
  merchant_user_password_resets_stream: Array<MerchantUserPasswordResets>;
  /** fetch data from the table: "merchant_users" */
  merchant_users: Array<MerchantUsers>;
  /** fetch aggregated fields from the table: "merchant_users" */
  merchant_users_aggregate: MerchantUsersAggregate;
  /** fetch data from the table: "merchant_users" using primary key columns */
  merchant_users_by_pk?: Maybe<MerchantUsers>;
  /** fetch data from the table in a streaming manner: "merchant_users" */
  merchant_users_stream: Array<MerchantUsers>;
  /** An array relationship */
  merchants: Array<Merchants>;
  /** An aggregate relationship */
  merchants_aggregate: MerchantsAggregate;
  /** fetch data from the table: "merchants" using primary key columns */
  merchants_by_pk?: Maybe<Merchants>;
  /** fetch data from the table in a streaming manner: "merchants" */
  merchants_stream: Array<Merchants>;
  /** fetch data from the table: "mobile_app_platforms" */
  mobile_app_platforms: Array<MobileAppPlatforms>;
  /** fetch aggregated fields from the table: "mobile_app_platforms" */
  mobile_app_platforms_aggregate: MobileAppPlatformsAggregate;
  /** fetch data from the table: "mobile_app_platforms" using primary key columns */
  mobile_app_platforms_by_pk?: Maybe<MobileAppPlatforms>;
  /** fetch data from the table in a streaming manner: "mobile_app_platforms" */
  mobile_app_platforms_stream: Array<MobileAppPlatforms>;
  /** fetch data from the table: "mobile_app_release_statuses" */
  mobile_app_release_statuses: Array<MobileAppReleaseStatuses>;
  /** fetch aggregated fields from the table: "mobile_app_release_statuses" */
  mobile_app_release_statuses_aggregate: MobileAppReleaseStatusesAggregate;
  /** fetch data from the table: "mobile_app_release_statuses" using primary key columns */
  mobile_app_release_statuses_by_pk?: Maybe<MobileAppReleaseStatuses>;
  /** fetch data from the table in a streaming manner: "mobile_app_release_statuses" */
  mobile_app_release_statuses_stream: Array<MobileAppReleaseStatuses>;
  /** fetch data from the table: "mobile_app_releases" */
  mobile_app_releases: Array<MobileAppReleases>;
  /** fetch aggregated fields from the table: "mobile_app_releases" */
  mobile_app_releases_aggregate: MobileAppReleasesAggregate;
  /** fetch data from the table: "mobile_app_releases" using primary key columns */
  mobile_app_releases_by_pk?: Maybe<MobileAppReleases>;
  /** fetch data from the table in a streaming manner: "mobile_app_releases" */
  mobile_app_releases_stream: Array<MobileAppReleases>;
  /** fetch data from the table: "organization_merchant_users" */
  organization_merchant_users: Array<OrganizationMerchantUsers>;
  /** fetch aggregated fields from the table: "organization_merchant_users" */
  organization_merchant_users_aggregate: OrganizationMerchantUsersAggregate;
  /** fetch data from the table in a streaming manner: "organization_merchant_users" */
  organization_merchant_users_stream: Array<OrganizationMerchantUsers>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: OrganizationsAggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table in a streaming manner: "organizations" */
  organizations_stream: Array<Organizations>;
  /** fetch data from the table: "salesforce_record_attributes" */
  salesforce_record_attributes: Array<SalesforceRecordAttributes>;
  /** fetch aggregated fields from the table: "salesforce_record_attributes" */
  salesforce_record_attributes_aggregate: SalesforceRecordAttributesAggregate;
  /** fetch data from the table: "salesforce_record_attributes" using primary key columns */
  salesforce_record_attributes_by_pk?: Maybe<SalesforceRecordAttributes>;
  /** fetch data from the table in a streaming manner: "salesforce_record_attributes" */
  salesforce_record_attributes_stream: Array<SalesforceRecordAttributes>;
  /** fetch data from the table: "secondary_responsible_merchant_users" */
  secondary_responsible_merchant_users: Array<SecondaryResponsibleMerchantUsers>;
  /** fetch aggregated fields from the table: "secondary_responsible_merchant_users" */
  secondary_responsible_merchant_users_aggregate: SecondaryResponsibleMerchantUsersAggregate;
  /** fetch data from the table: "secondary_responsible_merchant_users" using primary key columns */
  secondary_responsible_merchant_users_by_pk?: Maybe<SecondaryResponsibleMerchantUsers>;
  /** fetch data from the table in a streaming manner: "secondary_responsible_merchant_users" */
  secondary_responsible_merchant_users_stream: Array<SecondaryResponsibleMerchantUsers>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: TagsAggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  tags_stream: Array<Tags>;
  /** fetch data from the table: "toast_messages" */
  toast_messages: Array<ToastMessages>;
  /** fetch aggregated fields from the table: "toast_messages" */
  toast_messages_aggregate: ToastMessagesAggregate;
  /** fetch data from the table: "toast_messages" using primary key columns */
  toast_messages_by_pk?: Maybe<ToastMessages>;
  /** fetch data from the table in a streaming manner: "toast_messages" */
  toast_messages_stream: Array<ToastMessages>;
  /** fetch data from the table: "user_chats" */
  user_chats: Array<UserChats>;
  /** fetch aggregated fields from the table: "user_chats" */
  user_chats_aggregate: UserChatsAggregate;
  /** fetch data from the table: "user_chats" using primary key columns */
  user_chats_by_pk?: Maybe<UserChats>;
  /** fetch data from the table in a streaming manner: "user_chats" */
  user_chats_stream: Array<UserChats>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "whatsapp_account_hosting_types" */
  whatsapp_account_hosting_types: Array<WhatsappAccountHostingTypes>;
  /** fetch aggregated fields from the table: "whatsapp_account_hosting_types" */
  whatsapp_account_hosting_types_aggregate: WhatsappAccountHostingTypesAggregate;
  /** fetch data from the table: "whatsapp_account_hosting_types" using primary key columns */
  whatsapp_account_hosting_types_by_pk?: Maybe<WhatsappAccountHostingTypes>;
  /** fetch data from the table in a streaming manner: "whatsapp_account_hosting_types" */
  whatsapp_account_hosting_types_stream: Array<WhatsappAccountHostingTypes>;
  /** fetch data from the table: "whatsapp_account_providers" */
  whatsapp_account_providers: Array<WhatsappAccountProviders>;
  /** fetch aggregated fields from the table: "whatsapp_account_providers" */
  whatsapp_account_providers_aggregate: WhatsappAccountProvidersAggregate;
  /** fetch data from the table: "whatsapp_account_providers" using primary key columns */
  whatsapp_account_providers_by_pk?: Maybe<WhatsappAccountProviders>;
  /** fetch data from the table in a streaming manner: "whatsapp_account_providers" */
  whatsapp_account_providers_stream: Array<WhatsappAccountProviders>;
  /** fetch data from the table: "whatsapp_accounts" */
  whatsapp_accounts: Array<WhatsappAccounts>;
  /** fetch aggregated fields from the table: "whatsapp_accounts" */
  whatsapp_accounts_aggregate: WhatsappAccountsAggregate;
  /** fetch data from the table: "whatsapp_accounts" using primary key columns */
  whatsapp_accounts_by_pk?: Maybe<WhatsappAccounts>;
  /** fetch data from the table in a streaming manner: "whatsapp_accounts" */
  whatsapp_accounts_stream: Array<WhatsappAccounts>;
  /** fetch data from the table: "whatsapp_business_accounts" */
  whatsapp_business_accounts: Array<WhatsappBusinessAccounts>;
  /** fetch aggregated fields from the table: "whatsapp_business_accounts" */
  whatsapp_business_accounts_aggregate: WhatsappBusinessAccountsAggregate;
  /** fetch data from the table: "whatsapp_business_accounts" using primary key columns */
  whatsapp_business_accounts_by_pk?: Maybe<WhatsappBusinessAccounts>;
  /** fetch data from the table in a streaming manner: "whatsapp_business_accounts" */
  whatsapp_business_accounts_stream: Array<WhatsappBusinessAccounts>;
  /** fetch data from the table: "whatsapp_business_verticals" */
  whatsapp_business_verticals: Array<WhatsappBusinessVerticals>;
  /** fetch aggregated fields from the table: "whatsapp_business_verticals" */
  whatsapp_business_verticals_aggregate: WhatsappBusinessVerticalsAggregate;
  /** fetch data from the table: "whatsapp_business_verticals" using primary key columns */
  whatsapp_business_verticals_by_pk?: Maybe<WhatsappBusinessVerticals>;
  /** fetch data from the table in a streaming manner: "whatsapp_business_verticals" */
  whatsapp_business_verticals_stream: Array<WhatsappBusinessVerticals>;
  /** fetch data from the table: "whatsapp_conversation_types" */
  whatsapp_conversation_types: Array<WhatsappConversationTypes>;
  /** fetch aggregated fields from the table: "whatsapp_conversation_types" */
  whatsapp_conversation_types_aggregate: WhatsappConversationTypesAggregate;
  /** fetch data from the table: "whatsapp_conversation_types" using primary key columns */
  whatsapp_conversation_types_by_pk?: Maybe<WhatsappConversationTypes>;
  /** fetch data from the table in a streaming manner: "whatsapp_conversation_types" */
  whatsapp_conversation_types_stream: Array<WhatsappConversationTypes>;
  /** fetch data from the table: "whatsapp_conversations" */
  whatsapp_conversations: Array<WhatsappConversations>;
  /** fetch aggregated fields from the table: "whatsapp_conversations" */
  whatsapp_conversations_aggregate: WhatsappConversationsAggregate;
  /** fetch data from the table: "whatsapp_conversations" using primary key columns */
  whatsapp_conversations_by_pk?: Maybe<WhatsappConversations>;
  /** fetch data from the table in a streaming manner: "whatsapp_conversations" */
  whatsapp_conversations_stream: Array<WhatsappConversations>;
  /** fetch data from the table: "whatsapp_media_types" */
  whatsapp_media_types: Array<WhatsappMediaTypes>;
  /** fetch aggregated fields from the table: "whatsapp_media_types" */
  whatsapp_media_types_aggregate: WhatsappMediaTypesAggregate;
  /** fetch data from the table: "whatsapp_media_types" using primary key columns */
  whatsapp_media_types_by_pk?: Maybe<WhatsappMediaTypes>;
  /** fetch data from the table in a streaming manner: "whatsapp_media_types" */
  whatsapp_media_types_stream: Array<WhatsappMediaTypes>;
  /** fetch data from the table: "whatsapp_message_directions" */
  whatsapp_message_directions: Array<WhatsappMessageDirections>;
  /** fetch aggregated fields from the table: "whatsapp_message_directions" */
  whatsapp_message_directions_aggregate: WhatsappMessageDirectionsAggregate;
  /** fetch data from the table: "whatsapp_message_directions" using primary key columns */
  whatsapp_message_directions_by_pk?: Maybe<WhatsappMessageDirections>;
  /** fetch data from the table in a streaming manner: "whatsapp_message_directions" */
  whatsapp_message_directions_stream: Array<WhatsappMessageDirections>;
  /** fetch data from the table: "whatsapp_message_statuses" */
  whatsapp_message_statuses: Array<WhatsappMessageStatuses>;
  /** fetch aggregated fields from the table: "whatsapp_message_statuses" */
  whatsapp_message_statuses_aggregate: WhatsappMessageStatusesAggregate;
  /** fetch data from the table: "whatsapp_message_statuses" using primary key columns */
  whatsapp_message_statuses_by_pk?: Maybe<WhatsappMessageStatuses>;
  /** fetch data from the table in a streaming manner: "whatsapp_message_statuses" */
  whatsapp_message_statuses_stream: Array<WhatsappMessageStatuses>;
  /** fetch data from the table: "whatsapp_message_template_statuses" */
  whatsapp_message_template_statuses: Array<WhatsappMessageTemplateStatuses>;
  /** fetch aggregated fields from the table: "whatsapp_message_template_statuses" */
  whatsapp_message_template_statuses_aggregate: WhatsappMessageTemplateStatusesAggregate;
  /** fetch data from the table: "whatsapp_message_template_statuses" using primary key columns */
  whatsapp_message_template_statuses_by_pk?: Maybe<WhatsappMessageTemplateStatuses>;
  /** fetch data from the table in a streaming manner: "whatsapp_message_template_statuses" */
  whatsapp_message_template_statuses_stream: Array<WhatsappMessageTemplateStatuses>;
  /** fetch data from the table: "whatsapp_message_template_values" */
  whatsapp_message_template_values: Array<WhatsappMessageTemplateValues>;
  /** fetch aggregated fields from the table: "whatsapp_message_template_values" */
  whatsapp_message_template_values_aggregate: WhatsappMessageTemplateValuesAggregate;
  /** fetch data from the table: "whatsapp_message_template_values" using primary key columns */
  whatsapp_message_template_values_by_pk?: Maybe<WhatsappMessageTemplateValues>;
  /** fetch data from the table in a streaming manner: "whatsapp_message_template_values" */
  whatsapp_message_template_values_stream: Array<WhatsappMessageTemplateValues>;
  /** fetch data from the table: "whatsapp_message_templates" */
  whatsapp_message_templates: Array<WhatsappMessageTemplates>;
  /** fetch aggregated fields from the table: "whatsapp_message_templates" */
  whatsapp_message_templates_aggregate: WhatsappMessageTemplatesAggregate;
  /** fetch data from the table: "whatsapp_message_templates" using primary key columns */
  whatsapp_message_templates_by_pk?: Maybe<WhatsappMessageTemplates>;
  /** fetch data from the table in a streaming manner: "whatsapp_message_templates" */
  whatsapp_message_templates_stream: Array<WhatsappMessageTemplates>;
  /** fetch data from the table: "whatsapp_message_types" */
  whatsapp_message_types: Array<WhatsappMessageTypes>;
  /** fetch aggregated fields from the table: "whatsapp_message_types" */
  whatsapp_message_types_aggregate: WhatsappMessageTypesAggregate;
  /** fetch data from the table: "whatsapp_message_types" using primary key columns */
  whatsapp_message_types_by_pk?: Maybe<WhatsappMessageTypes>;
  /** fetch data from the table in a streaming manner: "whatsapp_message_types" */
  whatsapp_message_types_stream: Array<WhatsappMessageTypes>;
  /** fetch data from the table: "whatsapp_messages" */
  whatsapp_messages: Array<WhatsappMessages>;
  /** fetch aggregated fields from the table: "whatsapp_messages" */
  whatsapp_messages_aggregate: WhatsappMessagesAggregate;
  /** fetch data from the table: "whatsapp_messages" using primary key columns */
  whatsapp_messages_by_pk?: Maybe<WhatsappMessages>;
  /** fetch data from the table in a streaming manner: "whatsapp_messages" */
  whatsapp_messages_stream: Array<WhatsappMessages>;
};


export type SubscriptionRootAggregatedMerchantCustomerUserTagsArgs = {
  distinct_on?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
};


export type SubscriptionRootAggregatedMerchantCustomerUserTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AggregatedMerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
};


export type SubscriptionRootAggregatedMerchantCustomerUserTagsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<AggregatedMerchantCustomerUserTagsStreamCursorInput>>;
  where?: InputMaybe<AggregatedMerchantCustomerUserTagsBoolExp>;
};


export type SubscriptionRootCampaignAudienceTypesArgs = {
  distinct_on?: InputMaybe<Array<CampaignAudienceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignAudienceTypesOrderBy>>;
  where?: InputMaybe<CampaignAudienceTypesBoolExp>;
};


export type SubscriptionRootCampaignAudienceTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CampaignAudienceTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignAudienceTypesOrderBy>>;
  where?: InputMaybe<CampaignAudienceTypesBoolExp>;
};


export type SubscriptionRootCampaignAudienceTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootCampaignAudienceTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CampaignAudienceTypesStreamCursorInput>>;
  where?: InputMaybe<CampaignAudienceTypesBoolExp>;
};


export type SubscriptionRootCampaignCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignCustomerUsersOrderBy>>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};


export type SubscriptionRootCampaignCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CampaignCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignCustomerUsersOrderBy>>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};


export type SubscriptionRootCampaignCustomerUsersByPkArgs = {
  campaignId: Scalars['uuid'];
  customerUserId: Scalars['uuid'];
};


export type SubscriptionRootCampaignCustomerUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CampaignCustomerUsersStreamCursorInput>>;
  where?: InputMaybe<CampaignCustomerUsersBoolExp>;
};


export type SubscriptionRootCampaignsArgs = {
  distinct_on?: InputMaybe<Array<CampaignsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignsOrderBy>>;
  where?: InputMaybe<CampaignsBoolExp>;
};


export type SubscriptionRootCampaignsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CampaignsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CampaignsOrderBy>>;
  where?: InputMaybe<CampaignsBoolExp>;
};


export type SubscriptionRootCampaignsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCampaignsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CampaignsStreamCursorInput>>;
  where?: InputMaybe<CampaignsBoolExp>;
};


export type SubscriptionRootChannelChatBotUsersArgs = {
  distinct_on?: InputMaybe<Array<ChannelChatBotUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelChatBotUsersOrderBy>>;
  where?: InputMaybe<ChannelChatBotUsersBoolExp>;
};


export type SubscriptionRootChannelChatBotUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelChatBotUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelChatBotUsersOrderBy>>;
  where?: InputMaybe<ChannelChatBotUsersBoolExp>;
};


export type SubscriptionRootChannelChatBotUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChannelChatBotUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChannelChatBotUsersStreamCursorInput>>;
  where?: InputMaybe<ChannelChatBotUsersBoolExp>;
};


export type SubscriptionRootChannelMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelMerchantUsersOrderBy>>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};


export type SubscriptionRootChannelMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelMerchantUsersOrderBy>>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};


export type SubscriptionRootChannelMerchantUsersByPkArgs = {
  channelId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};


export type SubscriptionRootChannelMerchantUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChannelMerchantUsersStreamCursorInput>>;
  where?: InputMaybe<ChannelMerchantUsersBoolExp>;
};


export type SubscriptionRootChannelWorkingHoursArgs = {
  distinct_on?: InputMaybe<Array<ChannelWorkingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelWorkingHoursOrderBy>>;
  where?: InputMaybe<ChannelWorkingHoursBoolExp>;
};


export type SubscriptionRootChannelWorkingHoursAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelWorkingHoursSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelWorkingHoursOrderBy>>;
  where?: InputMaybe<ChannelWorkingHoursBoolExp>;
};


export type SubscriptionRootChannelWorkingHoursByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChannelWorkingHoursStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChannelWorkingHoursStreamCursorInput>>;
  where?: InputMaybe<ChannelWorkingHoursBoolExp>;
};


export type SubscriptionRootChannelsArgs = {
  distinct_on?: InputMaybe<Array<ChannelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelsOrderBy>>;
  where?: InputMaybe<ChannelsBoolExp>;
};


export type SubscriptionRootChannelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChannelsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChannelsOrderBy>>;
  where?: InputMaybe<ChannelsBoolExp>;
};


export type SubscriptionRootChannelsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChannelsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChannelsStreamCursorInput>>;
  where?: InputMaybe<ChannelsBoolExp>;
};


export type SubscriptionRootChatMessageChatBotAutoreplyTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesOrderBy>>;
  where?: InputMaybe<ChatMessageChatBotAutoreplyTypesBoolExp>;
};


export type SubscriptionRootChatMessageChatBotAutoreplyTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageChatBotAutoreplyTypesOrderBy>>;
  where?: InputMaybe<ChatMessageChatBotAutoreplyTypesBoolExp>;
};


export type SubscriptionRootChatMessageChatBotAutoreplyTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootChatMessageChatBotAutoreplyTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageChatBotAutoreplyTypesStreamCursorInput>>;
  where?: InputMaybe<ChatMessageChatBotAutoreplyTypesBoolExp>;
};


export type SubscriptionRootChatMessageFileAttachmentTranslationsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
};


export type SubscriptionRootChatMessageFileAttachmentTranslationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
};


export type SubscriptionRootChatMessageFileAttachmentTranslationsByPkArgs = {
  chatMessageFileAttachmentId: Scalars['uuid'];
  language: Scalars['String'];
};


export type SubscriptionRootChatMessageFileAttachmentTranslationsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageFileAttachmentTranslationsStreamCursorInput>>;
  where?: InputMaybe<ChatMessageFileAttachmentTranslationsBoolExp>;
};


export type SubscriptionRootChatMessageFileAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
};


export type SubscriptionRootChatMessageFileAttachmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageFileAttachmentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageFileAttachmentsOrderBy>>;
  where?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
};


export type SubscriptionRootChatMessageFileAttachmentsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChatMessageFileAttachmentsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageFileAttachmentsStreamCursorInput>>;
  where?: InputMaybe<ChatMessageFileAttachmentsBoolExp>;
};


export type SubscriptionRootChatMessageTemplatePlaceholderTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholderTypesBoolExp>;
};


export type SubscriptionRootChatMessageTemplatePlaceholderTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholderTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholderTypesBoolExp>;
};


export type SubscriptionRootChatMessageTemplatePlaceholderTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootChatMessageTemplatePlaceholderTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageTemplatePlaceholderTypesStreamCursorInput>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholderTypesBoolExp>;
};


export type SubscriptionRootChatMessageTemplatePlaceholdersArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholdersOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
};


export type SubscriptionRootChatMessageTemplatePlaceholdersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatePlaceholdersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatePlaceholdersOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
};


export type SubscriptionRootChatMessageTemplatePlaceholdersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChatMessageTemplatePlaceholdersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageTemplatePlaceholdersStreamCursorInput>>;
  where?: InputMaybe<ChatMessageTemplatePlaceholdersBoolExp>;
};


export type SubscriptionRootChatMessageTemplateTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateTypesBoolExp>;
};


export type SubscriptionRootChatMessageTemplateTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateTypesBoolExp>;
};


export type SubscriptionRootChatMessageTemplateTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootChatMessageTemplateTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageTemplateTypesStreamCursorInput>>;
  where?: InputMaybe<ChatMessageTemplateTypesBoolExp>;
};


export type SubscriptionRootChatMessageTemplateValuesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
};


export type SubscriptionRootChatMessageTemplateValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
};


export type SubscriptionRootChatMessageTemplateValuesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChatMessageTemplateValuesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageTemplateValuesStreamCursorInput>>;
  where?: InputMaybe<ChatMessageTemplateValuesBoolExp>;
};


export type SubscriptionRootChatMessageTemplatesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatesBoolExp>;
};


export type SubscriptionRootChatMessageTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTemplatesOrderBy>>;
  where?: InputMaybe<ChatMessageTemplatesBoolExp>;
};


export type SubscriptionRootChatMessageTemplatesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChatMessageTemplatesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageTemplatesStreamCursorInput>>;
  where?: InputMaybe<ChatMessageTemplatesBoolExp>;
};


export type SubscriptionRootChatMessageTranslationsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageTranslationsBoolExp>;
};


export type SubscriptionRootChatMessageTranslationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTranslationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTranslationsOrderBy>>;
  where?: InputMaybe<ChatMessageTranslationsBoolExp>;
};


export type SubscriptionRootChatMessageTranslationsByPkArgs = {
  chatMessageId: Scalars['uuid'];
  language: Scalars['String'];
};


export type SubscriptionRootChatMessageTranslationsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageTranslationsStreamCursorInput>>;
  where?: InputMaybe<ChatMessageTranslationsBoolExp>;
};


export type SubscriptionRootChatMessageTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTypesBoolExp>;
};


export type SubscriptionRootChatMessageTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageTypesOrderBy>>;
  where?: InputMaybe<ChatMessageTypesBoolExp>;
};


export type SubscriptionRootChatMessageTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootChatMessageTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageTypesStreamCursorInput>>;
  where?: InputMaybe<ChatMessageTypesBoolExp>;
};


export type SubscriptionRootChatMessageWhatsappMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageWhatsappMessagesOrderBy>>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};


export type SubscriptionRootChatMessageWhatsappMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageWhatsappMessagesOrderBy>>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};


export type SubscriptionRootChatMessageWhatsappMessagesByPkArgs = {
  chatMessageId: Scalars['uuid'];
  whatsappMessageId: Scalars['uuid'];
};


export type SubscriptionRootChatMessageWhatsappMessagesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessageWhatsappMessagesStreamCursorInput>>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};


export type SubscriptionRootChatMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


export type SubscriptionRootChatMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


export type SubscriptionRootChatMessagesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChatMessagesEventLogsArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesEventLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesEventLogsOrderBy>>;
  where?: InputMaybe<ChatMessagesEventLogsBoolExp>;
};


export type SubscriptionRootChatMessagesEventLogsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesEventLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesEventLogsOrderBy>>;
  where?: InputMaybe<ChatMessagesEventLogsBoolExp>;
};


export type SubscriptionRootChatMessagesEventLogsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChatMessagesEventLogsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessagesEventLogsStreamCursorInput>>;
  where?: InputMaybe<ChatMessagesEventLogsBoolExp>;
};


export type SubscriptionRootChatMessagesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatMessagesStreamCursorInput>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


export type SubscriptionRootChatSummariesArgs = {
  distinct_on?: InputMaybe<Array<ChatSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatSummariesOrderBy>>;
  where?: InputMaybe<ChatSummariesBoolExp>;
};


export type SubscriptionRootChatSummariesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatSummariesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatSummariesOrderBy>>;
  where?: InputMaybe<ChatSummariesBoolExp>;
};


export type SubscriptionRootChatSummariesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChatSummariesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatSummariesStreamCursorInput>>;
  where?: InputMaybe<ChatSummariesBoolExp>;
};


export type SubscriptionRootChatTypesArgs = {
  distinct_on?: InputMaybe<Array<ChatTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatTypesOrderBy>>;
  where?: InputMaybe<ChatTypesBoolExp>;
};


export type SubscriptionRootChatTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatTypesOrderBy>>;
  where?: InputMaybe<ChatTypesBoolExp>;
};


export type SubscriptionRootChatTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootChatTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatTypesStreamCursorInput>>;
  where?: InputMaybe<ChatTypesBoolExp>;
};


export type SubscriptionRootChatUsersArgs = {
  distinct_on?: InputMaybe<Array<ChatUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatUsersOrderBy>>;
  where?: InputMaybe<ChatUsersBoolExp>;
};


export type SubscriptionRootChatUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatUsersOrderBy>>;
  where?: InputMaybe<ChatUsersBoolExp>;
};


export type SubscriptionRootChatUsersByPkArgs = {
  chatId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type SubscriptionRootChatUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatUsersStreamCursorInput>>;
  where?: InputMaybe<ChatUsersBoolExp>;
};


export type SubscriptionRootChatsArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type SubscriptionRootChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type SubscriptionRootChatsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChatsEventLogsArgs = {
  distinct_on?: InputMaybe<Array<ChatsEventLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsEventLogsOrderBy>>;
  where?: InputMaybe<ChatsEventLogsBoolExp>;
};


export type SubscriptionRootChatsEventLogsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsEventLogsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsEventLogsOrderBy>>;
  where?: InputMaybe<ChatsEventLogsBoolExp>;
};


export type SubscriptionRootChatsEventLogsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootChatsEventLogsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatsEventLogsStreamCursorInput>>;
  where?: InputMaybe<ChatsEventLogsBoolExp>;
};


export type SubscriptionRootChatsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ChatsStreamCursorInput>>;
  where?: InputMaybe<ChatsBoolExp>;
};


export type SubscriptionRootContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactGroupsOrderBy>>;
  where?: InputMaybe<ContactGroupsBoolExp>;
};


export type SubscriptionRootContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ContactGroupsOrderBy>>;
  where?: InputMaybe<ContactGroupsBoolExp>;
};


export type SubscriptionRootContactGroupsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootContactGroupsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ContactGroupsStreamCursorInput>>;
  where?: InputMaybe<ContactGroupsBoolExp>;
};


export type SubscriptionRootCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<CustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerUsersOrderBy>>;
  where?: InputMaybe<CustomerUsersBoolExp>;
};


export type SubscriptionRootCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CustomerUsersOrderBy>>;
  where?: InputMaybe<CustomerUsersBoolExp>;
};


export type SubscriptionRootCustomerUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootCustomerUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<CustomerUsersStreamCursorInput>>;
  where?: InputMaybe<CustomerUsersBoolExp>;
};


export type SubscriptionRootEmailNotificationPreferencesArgs = {
  distinct_on?: InputMaybe<Array<EmailNotificationPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailNotificationPreferencesOrderBy>>;
  where?: InputMaybe<EmailNotificationPreferencesBoolExp>;
};


export type SubscriptionRootEmailNotificationPreferencesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EmailNotificationPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EmailNotificationPreferencesOrderBy>>;
  where?: InputMaybe<EmailNotificationPreferencesBoolExp>;
};


export type SubscriptionRootEmailNotificationPreferencesByPkArgs = {
  preference: Scalars['String'];
};


export type SubscriptionRootEmailNotificationPreferencesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EmailNotificationPreferencesStreamCursorInput>>;
  where?: InputMaybe<EmailNotificationPreferencesBoolExp>;
};


export type SubscriptionRootFileMimeTypesArgs = {
  distinct_on?: InputMaybe<Array<FileMimeTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileMimeTypesOrderBy>>;
  where?: InputMaybe<FileMimeTypesBoolExp>;
};


export type SubscriptionRootFileMimeTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileMimeTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FileMimeTypesOrderBy>>;
  where?: InputMaybe<FileMimeTypesBoolExp>;
};


export type SubscriptionRootFileMimeTypesByPkArgs = {
  mimeType: Scalars['String'];
};


export type SubscriptionRootFileMimeTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FileMimeTypesStreamCursorInput>>;
  where?: InputMaybe<FileMimeTypesBoolExp>;
};


export type SubscriptionRootFilesArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type SubscriptionRootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<FilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<FilesOrderBy>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type SubscriptionRootFilesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootFilesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<FilesStreamCursorInput>>;
  where?: InputMaybe<FilesBoolExp>;
};


export type SubscriptionRootMerchantApiUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantApiUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantApiUsersOrderBy>>;
  where?: InputMaybe<MerchantApiUsersBoolExp>;
};


export type SubscriptionRootMerchantApiUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantApiUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantApiUsersOrderBy>>;
  where?: InputMaybe<MerchantApiUsersBoolExp>;
};


export type SubscriptionRootMerchantApiUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMerchantApiUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantApiUsersStreamCursorInput>>;
  where?: InputMaybe<MerchantApiUsersBoolExp>;
};


export type SubscriptionRootMerchantCustomerUserContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};


export type SubscriptionRootMerchantCustomerUserContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};


export type SubscriptionRootMerchantCustomerUserContactGroupsByPkArgs = {
  contactGroupId: Scalars['uuid'];
  merchantCustomerUserId: Scalars['uuid'];
};


export type SubscriptionRootMerchantCustomerUserContactGroupsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantCustomerUserContactGroupsStreamCursorInput>>;
  where?: InputMaybe<MerchantCustomerUserContactGroupsBoolExp>;
};


export type SubscriptionRootMerchantCustomerUserTagsArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
};


export type SubscriptionRootMerchantCustomerUserTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUserTagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUserTagsOrderBy>>;
  where?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
};


export type SubscriptionRootMerchantCustomerUserTagsByPkArgs = {
  merchantCustomerUserId: Scalars['uuid'];
  tagId: Scalars['uuid'];
};


export type SubscriptionRootMerchantCustomerUserTagsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantCustomerUserTagsStreamCursorInput>>;
  where?: InputMaybe<MerchantCustomerUserTagsBoolExp>;
};


export type SubscriptionRootMerchantCustomerUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


export type SubscriptionRootMerchantCustomerUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantCustomerUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantCustomerUsersOrderBy>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


export type SubscriptionRootMerchantCustomerUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMerchantCustomerUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantCustomerUsersStreamCursorInput>>;
  where?: InputMaybe<MerchantCustomerUsersBoolExp>;
};


export type SubscriptionRootMerchantMobileAppInstallationsArgs = {
  distinct_on?: InputMaybe<Array<MerchantMobileAppInstallationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantMobileAppInstallationsOrderBy>>;
  where?: InputMaybe<MerchantMobileAppInstallationsBoolExp>;
};


export type SubscriptionRootMerchantMobileAppInstallationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantMobileAppInstallationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantMobileAppInstallationsOrderBy>>;
  where?: InputMaybe<MerchantMobileAppInstallationsBoolExp>;
};


export type SubscriptionRootMerchantMobileAppInstallationsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMerchantMobileAppInstallationsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantMobileAppInstallationsStreamCursorInput>>;
  where?: InputMaybe<MerchantMobileAppInstallationsBoolExp>;
};


export type SubscriptionRootMerchantSalesforceSyncConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsOrderBy>>;
  where?: InputMaybe<MerchantSalesforceSyncConfigurationsBoolExp>;
};


export type SubscriptionRootMerchantSalesforceSyncConfigurationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantSalesforceSyncConfigurationsOrderBy>>;
  where?: InputMaybe<MerchantSalesforceSyncConfigurationsBoolExp>;
};


export type SubscriptionRootMerchantSalesforceSyncConfigurationsByPkArgs = {
  merchantId: Scalars['uuid'];
};


export type SubscriptionRootMerchantSalesforceSyncConfigurationsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantSalesforceSyncConfigurationsStreamCursorInput>>;
  where?: InputMaybe<MerchantSalesforceSyncConfigurationsBoolExp>;
};


export type SubscriptionRootMerchantStoreMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoreMerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};


export type SubscriptionRootMerchantStoreMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoreMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoreMerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};


export type SubscriptionRootMerchantStoreMerchantUsersByPkArgs = {
  merchantStoreId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};


export type SubscriptionRootMerchantStoreMerchantUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantStoreMerchantUsersStreamCursorInput>>;
  where?: InputMaybe<MerchantStoreMerchantUsersBoolExp>;
};


export type SubscriptionRootMerchantStoresArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoresOrderBy>>;
  where?: InputMaybe<MerchantStoresBoolExp>;
};


export type SubscriptionRootMerchantStoresAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantStoresOrderBy>>;
  where?: InputMaybe<MerchantStoresBoolExp>;
};


export type SubscriptionRootMerchantStoresByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMerchantStoresStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantStoresStreamCursorInput>>;
  where?: InputMaybe<MerchantStoresBoolExp>;
};


export type SubscriptionRootMerchantUserContactGroupsArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};


export type SubscriptionRootMerchantUserContactGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserContactGroupsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserContactGroupsOrderBy>>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};


export type SubscriptionRootMerchantUserContactGroupsByPkArgs = {
  contactGroupId: Scalars['uuid'];
  merchantUserId: Scalars['uuid'];
};


export type SubscriptionRootMerchantUserContactGroupsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantUserContactGroupsStreamCursorInput>>;
  where?: InputMaybe<MerchantUserContactGroupsBoolExp>;
};


export type SubscriptionRootMerchantUserPasswordResetsArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserPasswordResetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserPasswordResetsOrderBy>>;
  where?: InputMaybe<MerchantUserPasswordResetsBoolExp>;
};


export type SubscriptionRootMerchantUserPasswordResetsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantUserPasswordResetsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUserPasswordResetsOrderBy>>;
  where?: InputMaybe<MerchantUserPasswordResetsBoolExp>;
};


export type SubscriptionRootMerchantUserPasswordResetsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMerchantUserPasswordResetsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantUserPasswordResetsStreamCursorInput>>;
  where?: InputMaybe<MerchantUserPasswordResetsBoolExp>;
};


export type SubscriptionRootMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<MerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantUsersBoolExp>;
};


export type SubscriptionRootMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantUsersOrderBy>>;
  where?: InputMaybe<MerchantUsersBoolExp>;
};


export type SubscriptionRootMerchantUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMerchantUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantUsersStreamCursorInput>>;
  where?: InputMaybe<MerchantUsersBoolExp>;
};


export type SubscriptionRootMerchantsArgs = {
  distinct_on?: InputMaybe<Array<MerchantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantsOrderBy>>;
  where?: InputMaybe<MerchantsBoolExp>;
};


export type SubscriptionRootMerchantsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MerchantsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MerchantsOrderBy>>;
  where?: InputMaybe<MerchantsBoolExp>;
};


export type SubscriptionRootMerchantsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMerchantsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MerchantsStreamCursorInput>>;
  where?: InputMaybe<MerchantsBoolExp>;
};


export type SubscriptionRootMobileAppPlatformsArgs = {
  distinct_on?: InputMaybe<Array<MobileAppPlatformsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppPlatformsOrderBy>>;
  where?: InputMaybe<MobileAppPlatformsBoolExp>;
};


export type SubscriptionRootMobileAppPlatformsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MobileAppPlatformsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppPlatformsOrderBy>>;
  where?: InputMaybe<MobileAppPlatformsBoolExp>;
};


export type SubscriptionRootMobileAppPlatformsByPkArgs = {
  platform: Scalars['String'];
};


export type SubscriptionRootMobileAppPlatformsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MobileAppPlatformsStreamCursorInput>>;
  where?: InputMaybe<MobileAppPlatformsBoolExp>;
};


export type SubscriptionRootMobileAppReleaseStatusesArgs = {
  distinct_on?: InputMaybe<Array<MobileAppReleaseStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppReleaseStatusesOrderBy>>;
  where?: InputMaybe<MobileAppReleaseStatusesBoolExp>;
};


export type SubscriptionRootMobileAppReleaseStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MobileAppReleaseStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppReleaseStatusesOrderBy>>;
  where?: InputMaybe<MobileAppReleaseStatusesBoolExp>;
};


export type SubscriptionRootMobileAppReleaseStatusesByPkArgs = {
  status: Scalars['String'];
};


export type SubscriptionRootMobileAppReleaseStatusesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MobileAppReleaseStatusesStreamCursorInput>>;
  where?: InputMaybe<MobileAppReleaseStatusesBoolExp>;
};


export type SubscriptionRootMobileAppReleasesArgs = {
  distinct_on?: InputMaybe<Array<MobileAppReleasesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppReleasesOrderBy>>;
  where?: InputMaybe<MobileAppReleasesBoolExp>;
};


export type SubscriptionRootMobileAppReleasesAggregateArgs = {
  distinct_on?: InputMaybe<Array<MobileAppReleasesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<MobileAppReleasesOrderBy>>;
  where?: InputMaybe<MobileAppReleasesBoolExp>;
};


export type SubscriptionRootMobileAppReleasesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootMobileAppReleasesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<MobileAppReleasesStreamCursorInput>>;
  where?: InputMaybe<MobileAppReleasesBoolExp>;
};


export type SubscriptionRootOrganizationMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationMerchantUsersOrderBy>>;
  where?: InputMaybe<OrganizationMerchantUsersBoolExp>;
};


export type SubscriptionRootOrganizationMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationMerchantUsersOrderBy>>;
  where?: InputMaybe<OrganizationMerchantUsersBoolExp>;
};


export type SubscriptionRootOrganizationMerchantUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationMerchantUsersStreamCursorInput>>;
  where?: InputMaybe<OrganizationMerchantUsersBoolExp>;
};


export type SubscriptionRootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type SubscriptionRootOrganizationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OrganizationsOrderBy>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type SubscriptionRootOrganizationsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootOrganizationsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OrganizationsStreamCursorInput>>;
  where?: InputMaybe<OrganizationsBoolExp>;
};


export type SubscriptionRootSalesforceRecordAttributesArgs = {
  distinct_on?: InputMaybe<Array<SalesforceRecordAttributesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceRecordAttributesOrderBy>>;
  where?: InputMaybe<SalesforceRecordAttributesBoolExp>;
};


export type SubscriptionRootSalesforceRecordAttributesAggregateArgs = {
  distinct_on?: InputMaybe<Array<SalesforceRecordAttributesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SalesforceRecordAttributesOrderBy>>;
  where?: InputMaybe<SalesforceRecordAttributesBoolExp>;
};


export type SubscriptionRootSalesforceRecordAttributesByPkArgs = {
  attribute: Scalars['String'];
};


export type SubscriptionRootSalesforceRecordAttributesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SalesforceRecordAttributesStreamCursorInput>>;
  where?: InputMaybe<SalesforceRecordAttributesBoolExp>;
};


export type SubscriptionRootSecondaryResponsibleMerchantUsersArgs = {
  distinct_on?: InputMaybe<Array<SecondaryResponsibleMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecondaryResponsibleMerchantUsersOrderBy>>;
  where?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
};


export type SubscriptionRootSecondaryResponsibleMerchantUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<SecondaryResponsibleMerchantUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<SecondaryResponsibleMerchantUsersOrderBy>>;
  where?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
};


export type SubscriptionRootSecondaryResponsibleMerchantUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootSecondaryResponsibleMerchantUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<SecondaryResponsibleMerchantUsersStreamCursorInput>>;
  where?: InputMaybe<SecondaryResponsibleMerchantUsersBoolExp>;
};


export type SubscriptionRootTagsArgs = {
  distinct_on?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};


export type SubscriptionRootTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<TagsOrderBy>>;
  where?: InputMaybe<TagsBoolExp>;
};


export type SubscriptionRootTagsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootTagsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<TagsStreamCursorInput>>;
  where?: InputMaybe<TagsBoolExp>;
};


export type SubscriptionRootToastMessagesArgs = {
  distinct_on?: InputMaybe<Array<ToastMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ToastMessagesOrderBy>>;
  where?: InputMaybe<ToastMessagesBoolExp>;
};


export type SubscriptionRootToastMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ToastMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ToastMessagesOrderBy>>;
  where?: InputMaybe<ToastMessagesBoolExp>;
};


export type SubscriptionRootToastMessagesByPkArgs = {
  message: Scalars['String'];
  pathname: Scalars['String'];
  type: Scalars['String'];
};


export type SubscriptionRootToastMessagesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<ToastMessagesStreamCursorInput>>;
  where?: InputMaybe<ToastMessagesBoolExp>;
};


export type SubscriptionRootUserChatsArgs = {
  distinct_on?: InputMaybe<Array<UserChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserChatsOrderBy>>;
  where?: InputMaybe<UserChatsBoolExp>;
};


export type SubscriptionRootUserChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserChatsOrderBy>>;
  where?: InputMaybe<UserChatsBoolExp>;
};


export type SubscriptionRootUserChatsByPkArgs = {
  chatId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type SubscriptionRootUserChatsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UserChatsStreamCursorInput>>;
  where?: InputMaybe<UserChatsBoolExp>;
};


export type SubscriptionRootUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootUsersByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootUsersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};


export type SubscriptionRootWhatsappAccountHostingTypesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountHostingTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountHostingTypesOrderBy>>;
  where?: InputMaybe<WhatsappAccountHostingTypesBoolExp>;
};


export type SubscriptionRootWhatsappAccountHostingTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountHostingTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountHostingTypesOrderBy>>;
  where?: InputMaybe<WhatsappAccountHostingTypesBoolExp>;
};


export type SubscriptionRootWhatsappAccountHostingTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootWhatsappAccountHostingTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappAccountHostingTypesStreamCursorInput>>;
  where?: InputMaybe<WhatsappAccountHostingTypesBoolExp>;
};


export type SubscriptionRootWhatsappAccountProvidersArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountProvidersOrderBy>>;
  where?: InputMaybe<WhatsappAccountProvidersBoolExp>;
};


export type SubscriptionRootWhatsappAccountProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountProvidersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountProvidersOrderBy>>;
  where?: InputMaybe<WhatsappAccountProvidersBoolExp>;
};


export type SubscriptionRootWhatsappAccountProvidersByPkArgs = {
  provider: Scalars['String'];
};


export type SubscriptionRootWhatsappAccountProvidersStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappAccountProvidersStreamCursorInput>>;
  where?: InputMaybe<WhatsappAccountProvidersBoolExp>;
};


export type SubscriptionRootWhatsappAccountsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountsOrderBy>>;
  where?: InputMaybe<WhatsappAccountsBoolExp>;
};


export type SubscriptionRootWhatsappAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountsOrderBy>>;
  where?: InputMaybe<WhatsappAccountsBoolExp>;
};


export type SubscriptionRootWhatsappAccountsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootWhatsappAccountsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappAccountsStreamCursorInput>>;
  where?: InputMaybe<WhatsappAccountsBoolExp>;
};


export type SubscriptionRootWhatsappBusinessAccountsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessAccountsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
};


export type SubscriptionRootWhatsappBusinessAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessAccountsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
};


export type SubscriptionRootWhatsappBusinessAccountsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootWhatsappBusinessAccountsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappBusinessAccountsStreamCursorInput>>;
  where?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
};


export type SubscriptionRootWhatsappBusinessVerticalsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessVerticalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessVerticalsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessVerticalsBoolExp>;
};


export type SubscriptionRootWhatsappBusinessVerticalsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappBusinessVerticalsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappBusinessVerticalsOrderBy>>;
  where?: InputMaybe<WhatsappBusinessVerticalsBoolExp>;
};


export type SubscriptionRootWhatsappBusinessVerticalsByPkArgs = {
  vertical: Scalars['String'];
};


export type SubscriptionRootWhatsappBusinessVerticalsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappBusinessVerticalsStreamCursorInput>>;
  where?: InputMaybe<WhatsappBusinessVerticalsBoolExp>;
};


export type SubscriptionRootWhatsappConversationTypesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappConversationTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappConversationTypesOrderBy>>;
  where?: InputMaybe<WhatsappConversationTypesBoolExp>;
};


export type SubscriptionRootWhatsappConversationTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappConversationTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappConversationTypesOrderBy>>;
  where?: InputMaybe<WhatsappConversationTypesBoolExp>;
};


export type SubscriptionRootWhatsappConversationTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootWhatsappConversationTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappConversationTypesStreamCursorInput>>;
  where?: InputMaybe<WhatsappConversationTypesBoolExp>;
};


export type SubscriptionRootWhatsappConversationsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappConversationsOrderBy>>;
  where?: InputMaybe<WhatsappConversationsBoolExp>;
};


export type SubscriptionRootWhatsappConversationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappConversationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappConversationsOrderBy>>;
  where?: InputMaybe<WhatsappConversationsBoolExp>;
};


export type SubscriptionRootWhatsappConversationsByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootWhatsappConversationsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappConversationsStreamCursorInput>>;
  where?: InputMaybe<WhatsappConversationsBoolExp>;
};


export type SubscriptionRootWhatsappMediaTypesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMediaTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMediaTypesOrderBy>>;
  where?: InputMaybe<WhatsappMediaTypesBoolExp>;
};


export type SubscriptionRootWhatsappMediaTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMediaTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMediaTypesOrderBy>>;
  where?: InputMaybe<WhatsappMediaTypesBoolExp>;
};


export type SubscriptionRootWhatsappMediaTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootWhatsappMediaTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappMediaTypesStreamCursorInput>>;
  where?: InputMaybe<WhatsappMediaTypesBoolExp>;
};


export type SubscriptionRootWhatsappMessageDirectionsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageDirectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageDirectionsOrderBy>>;
  where?: InputMaybe<WhatsappMessageDirectionsBoolExp>;
};


export type SubscriptionRootWhatsappMessageDirectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageDirectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageDirectionsOrderBy>>;
  where?: InputMaybe<WhatsappMessageDirectionsBoolExp>;
};


export type SubscriptionRootWhatsappMessageDirectionsByPkArgs = {
  direction: Scalars['String'];
};


export type SubscriptionRootWhatsappMessageDirectionsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappMessageDirectionsStreamCursorInput>>;
  where?: InputMaybe<WhatsappMessageDirectionsBoolExp>;
};


export type SubscriptionRootWhatsappMessageStatusesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageStatusesOrderBy>>;
  where?: InputMaybe<WhatsappMessageStatusesBoolExp>;
};


export type SubscriptionRootWhatsappMessageStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageStatusesOrderBy>>;
  where?: InputMaybe<WhatsappMessageStatusesBoolExp>;
};


export type SubscriptionRootWhatsappMessageStatusesByPkArgs = {
  status: Scalars['String'];
};


export type SubscriptionRootWhatsappMessageStatusesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappMessageStatusesStreamCursorInput>>;
  where?: InputMaybe<WhatsappMessageStatusesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTemplateStatusesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateStatusesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateStatusesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTemplateStatusesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateStatusesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateStatusesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTemplateStatusesByPkArgs = {
  status: Scalars['String'];
};


export type SubscriptionRootWhatsappMessageTemplateStatusesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappMessageTemplateStatusesStreamCursorInput>>;
  where?: InputMaybe<WhatsappMessageTemplateStatusesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTemplateValuesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTemplateValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTemplateValuesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootWhatsappMessageTemplateValuesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappMessageTemplateValuesStreamCursorInput>>;
  where?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTemplatesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplatesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplatesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTemplatesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootWhatsappMessageTemplatesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappMessageTemplatesStreamCursorInput>>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTypesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTypesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTypesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTypesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTypesBoolExp>;
};


export type SubscriptionRootWhatsappMessageTypesByPkArgs = {
  type: Scalars['String'];
};


export type SubscriptionRootWhatsappMessageTypesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappMessageTypesStreamCursorInput>>;
  where?: InputMaybe<WhatsappMessageTypesBoolExp>;
};


export type SubscriptionRootWhatsappMessagesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessagesOrderBy>>;
  where?: InputMaybe<WhatsappMessagesBoolExp>;
};


export type SubscriptionRootWhatsappMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessagesOrderBy>>;
  where?: InputMaybe<WhatsappMessagesBoolExp>;
};


export type SubscriptionRootWhatsappMessagesByPkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionRootWhatsappMessagesStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<WhatsappMessagesStreamCursorInput>>;
  where?: InputMaybe<WhatsappMessagesBoolExp>;
};

/** columns and relationships of "tags" */
export type Tags = {
  __typename?: 'tags';
  id: Scalars['uuid'];
  merchantId: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "tags" */
export type TagsAggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<TagsAggregateFields>;
  nodes: Array<Tags>;
};

export type TagsAggregateBoolExp = {
  count?: InputMaybe<TagsAggregateBoolExpCount>;
};

export type TagsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<TagsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "tags" */
export type TagsAggregateFields = {
  __typename?: 'tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<TagsMaxFields>;
  min?: Maybe<TagsMinFields>;
};


/** aggregate fields of "tags" */
export type TagsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tags" */
export type TagsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TagsMaxOrderBy>;
  min?: InputMaybe<TagsMinOrderBy>;
};

/** input type for inserting array relation for remote table "tags" */
export type TagsArrRelInsertInput = {
  data: Array<TagsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TagsOnConflict>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type TagsBoolExp = {
  _and?: InputMaybe<Array<TagsBoolExp>>;
  _not?: InputMaybe<TagsBoolExp>;
  _or?: InputMaybe<Array<TagsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "tags" */
export enum TagsConstraint {
  /** unique or primary key constraint on columns "merchant_id", "name" */
  TagsMerchantIdNameKey = 'tags_merchant_id_name_key',
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'tags_pkey'
}

/** input type for inserting data into table "tags" */
export type TagsInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TagsMaxFields = {
  __typename?: 'tags_max_fields';
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tags" */
export type TagsMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TagsMinFields = {
  __typename?: 'tags_min_fields';
  id?: Maybe<Scalars['uuid']>;
  merchantId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tags" */
export type TagsMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "tags" */
export type TagsMutationResponse = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type TagsObjRelInsertInput = {
  data: TagsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TagsOnConflict>;
};

/** on_conflict condition type for table "tags" */
export type TagsOnConflict = {
  constraint: TagsConstraint;
  update_columns?: Array<TagsUpdateColumn>;
  where?: InputMaybe<TagsBoolExp>;
};

/** Ordering options when selecting data from "tags". */
export type TagsOrderBy = {
  id?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tags */
export type TagsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "tags" */
export enum TagsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "tags" */
export type TagsSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "tags" */
export type TagsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagsStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "tags" */
export enum TagsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Name = 'name'
}

export type TagsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagsSetInput>;
  /** filter the rows which have to be updated */
  where: TagsBoolExp;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "toast_messages" */
export type ToastMessages = {
  __typename?: 'toast_messages';
  message: Scalars['String'];
  pathname: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "toast_messages" */
export type ToastMessagesAggregate = {
  __typename?: 'toast_messages_aggregate';
  aggregate?: Maybe<ToastMessagesAggregateFields>;
  nodes: Array<ToastMessages>;
};

/** aggregate fields of "toast_messages" */
export type ToastMessagesAggregateFields = {
  __typename?: 'toast_messages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ToastMessagesMaxFields>;
  min?: Maybe<ToastMessagesMinFields>;
};


/** aggregate fields of "toast_messages" */
export type ToastMessagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ToastMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "toast_messages". All fields are combined with a logical 'AND'. */
export type ToastMessagesBoolExp = {
  _and?: InputMaybe<Array<ToastMessagesBoolExp>>;
  _not?: InputMaybe<ToastMessagesBoolExp>;
  _or?: InputMaybe<Array<ToastMessagesBoolExp>>;
  message?: InputMaybe<StringComparisonExp>;
  pathname?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "toast_messages" */
export enum ToastMessagesConstraint {
  /** unique or primary key constraint on columns "message", "pathname", "type" */
  ToastMessagesPkey = 'toast_messages_pkey'
}

/** input type for inserting data into table "toast_messages" */
export type ToastMessagesInsertInput = {
  message?: InputMaybe<Scalars['String']>;
  pathname?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ToastMessagesMaxFields = {
  __typename?: 'toast_messages_max_fields';
  message?: Maybe<Scalars['String']>;
  pathname?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ToastMessagesMinFields = {
  __typename?: 'toast_messages_min_fields';
  message?: Maybe<Scalars['String']>;
  pathname?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "toast_messages" */
export type ToastMessagesMutationResponse = {
  __typename?: 'toast_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ToastMessages>;
};

/** on_conflict condition type for table "toast_messages" */
export type ToastMessagesOnConflict = {
  constraint: ToastMessagesConstraint;
  update_columns?: Array<ToastMessagesUpdateColumn>;
  where?: InputMaybe<ToastMessagesBoolExp>;
};

/** Ordering options when selecting data from "toast_messages". */
export type ToastMessagesOrderBy = {
  message?: InputMaybe<OrderBy>;
  pathname?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: toast_messages */
export type ToastMessagesPkColumnsInput = {
  message: Scalars['String'];
  pathname: Scalars['String'];
  type: Scalars['String'];
};

/** select columns of table "toast_messages" */
export enum ToastMessagesSelectColumn {
  /** column name */
  Message = 'message',
  /** column name */
  Pathname = 'pathname',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "toast_messages" */
export type ToastMessagesSetInput = {
  message?: InputMaybe<Scalars['String']>;
  pathname?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "toast_messages" */
export type ToastMessagesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ToastMessagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ToastMessagesStreamCursorValueInput = {
  message?: InputMaybe<Scalars['String']>;
  pathname?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "toast_messages" */
export enum ToastMessagesUpdateColumn {
  /** column name */
  Message = 'message',
  /** column name */
  Pathname = 'pathname',
  /** column name */
  Type = 'type'
}

export type ToastMessagesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ToastMessagesSetInput>;
  /** filter the rows which have to be updated */
  where: ToastMessagesBoolExp;
};

/** columns and relationships of "user_chats" */
export type UserChats = {
  __typename?: 'user_chats';
  /** An object relationship */
  chat: Chats;
  chatId: Scalars['uuid'];
  isFlagged?: Maybe<Scalars['Boolean']>;
  isShowingTranslations: Scalars['Boolean'];
  /** An object relationship */
  mostRecentReadChatMessage?: Maybe<ChatMessages>;
  mostRecentReadChatMessageId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid'];
};

/** aggregated selection of "user_chats" */
export type UserChatsAggregate = {
  __typename?: 'user_chats_aggregate';
  aggregate?: Maybe<UserChatsAggregateFields>;
  nodes: Array<UserChats>;
};

export type UserChatsAggregateBoolExp = {
  bool_and?: InputMaybe<UserChatsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UserChatsAggregateBoolExpBoolOr>;
  count?: InputMaybe<UserChatsAggregateBoolExpCount>;
};

export type UserChatsAggregateBoolExpBoolAnd = {
  arguments: UserChatsSelectColumnUserChatsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserChatsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserChatsAggregateBoolExpBoolOr = {
  arguments: UserChatsSelectColumnUserChatsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserChatsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UserChatsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserChatsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UserChatsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_chats" */
export type UserChatsAggregateFields = {
  __typename?: 'user_chats_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserChatsMaxFields>;
  min?: Maybe<UserChatsMinFields>;
};


/** aggregate fields of "user_chats" */
export type UserChatsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserChatsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_chats" */
export type UserChatsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserChatsMaxOrderBy>;
  min?: InputMaybe<UserChatsMinOrderBy>;
};

/** input type for inserting array relation for remote table "user_chats" */
export type UserChatsArrRelInsertInput = {
  data: Array<UserChatsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserChatsOnConflict>;
};

/** Boolean expression to filter rows from the table "user_chats". All fields are combined with a logical 'AND'. */
export type UserChatsBoolExp = {
  _and?: InputMaybe<Array<UserChatsBoolExp>>;
  _not?: InputMaybe<UserChatsBoolExp>;
  _or?: InputMaybe<Array<UserChatsBoolExp>>;
  chat?: InputMaybe<ChatsBoolExp>;
  chatId?: InputMaybe<UuidComparisonExp>;
  isFlagged?: InputMaybe<BooleanComparisonExp>;
  isShowingTranslations?: InputMaybe<BooleanComparisonExp>;
  mostRecentReadChatMessage?: InputMaybe<ChatMessagesBoolExp>;
  mostRecentReadChatMessageId?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "user_chats" */
export enum UserChatsConstraint {
  /** unique or primary key constraint on columns "user_id", "chat_id" */
  UserChatsPkey = 'user_chats_pkey'
}

/** input type for inserting data into table "user_chats" */
export type UserChatsInsertInput = {
  chat?: InputMaybe<ChatsObjRelInsertInput>;
  chatId?: InputMaybe<Scalars['uuid']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isShowingTranslations?: InputMaybe<Scalars['Boolean']>;
  mostRecentReadChatMessage?: InputMaybe<ChatMessagesObjRelInsertInput>;
  mostRecentReadChatMessageId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserChatsMaxFields = {
  __typename?: 'user_chats_max_fields';
  chatId?: Maybe<Scalars['uuid']>;
  mostRecentReadChatMessageId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_chats" */
export type UserChatsMaxOrderBy = {
  chatId?: InputMaybe<OrderBy>;
  mostRecentReadChatMessageId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserChatsMinFields = {
  __typename?: 'user_chats_min_fields';
  chatId?: Maybe<Scalars['uuid']>;
  mostRecentReadChatMessageId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_chats" */
export type UserChatsMinOrderBy = {
  chatId?: InputMaybe<OrderBy>;
  mostRecentReadChatMessageId?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_chats" */
export type UserChatsMutationResponse = {
  __typename?: 'user_chats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserChats>;
};

/** on_conflict condition type for table "user_chats" */
export type UserChatsOnConflict = {
  constraint: UserChatsConstraint;
  update_columns?: Array<UserChatsUpdateColumn>;
  where?: InputMaybe<UserChatsBoolExp>;
};

/** Ordering options when selecting data from "user_chats". */
export type UserChatsOrderBy = {
  chat?: InputMaybe<ChatsOrderBy>;
  chatId?: InputMaybe<OrderBy>;
  isFlagged?: InputMaybe<OrderBy>;
  isShowingTranslations?: InputMaybe<OrderBy>;
  mostRecentReadChatMessage?: InputMaybe<ChatMessagesOrderBy>;
  mostRecentReadChatMessageId?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_chats */
export type UserChatsPkColumnsInput = {
  chatId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "user_chats" */
export enum UserChatsSelectColumn {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  IsFlagged = 'isFlagged',
  /** column name */
  IsShowingTranslations = 'isShowingTranslations',
  /** column name */
  MostRecentReadChatMessageId = 'mostRecentReadChatMessageId',
  /** column name */
  UserId = 'userId'
}

/** select "user_chats_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_chats" */
export enum UserChatsSelectColumnUserChatsAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsFlagged = 'isFlagged',
  /** column name */
  IsShowingTranslations = 'isShowingTranslations'
}

/** select "user_chats_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_chats" */
export enum UserChatsSelectColumnUserChatsAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsFlagged = 'isFlagged',
  /** column name */
  IsShowingTranslations = 'isShowingTranslations'
}

/** input type for updating data in table "user_chats" */
export type UserChatsSetInput = {
  chatId?: InputMaybe<Scalars['uuid']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isShowingTranslations?: InputMaybe<Scalars['Boolean']>;
  mostRecentReadChatMessageId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_chats" */
export type UserChatsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserChatsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserChatsStreamCursorValueInput = {
  chatId?: InputMaybe<Scalars['uuid']>;
  isFlagged?: InputMaybe<Scalars['Boolean']>;
  isShowingTranslations?: InputMaybe<Scalars['Boolean']>;
  mostRecentReadChatMessageId?: InputMaybe<Scalars['uuid']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_chats" */
export enum UserChatsUpdateColumn {
  /** column name */
  ChatId = 'chatId',
  /** column name */
  IsFlagged = 'isFlagged',
  /** column name */
  IsShowingTranslations = 'isShowingTranslations',
  /** column name */
  MostRecentReadChatMessageId = 'mostRecentReadChatMessageId',
  /** column name */
  UserId = 'userId'
}

export type UserChatsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserChatsSetInput>;
  /** filter the rows which have to be updated */
  where: UserChatsBoolExp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  channelChatBotUser?: Maybe<ChannelChatBotUsers>;
  channelChatBotUserId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  chatMessages: Array<ChatMessages>;
  /** An aggregate relationship */
  chatMessages_aggregate: ChatMessagesAggregate;
  /** An array relationship */
  chatUsers: Array<ChatUsers>;
  /** An aggregate relationship */
  chatUsers_aggregate: ChatUsersAggregate;
  /** An object relationship */
  customerUser?: Maybe<CustomerUsers>;
  customerUserId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  impersonatedUser?: Maybe<Users>;
  impersonatedUserId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  impersonatingUsers: Array<Users>;
  /** An aggregate relationship */
  impersonatingUsers_aggregate: UsersAggregate;
  isDeleted: Scalars['Boolean'];
  lastLoginTimestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  merchantApiUser?: Maybe<MerchantApiUsers>;
  merchantApiUserId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  merchantUser?: Maybe<MerchantUsers>;
  merchantUserId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  ownedChats: Array<Chats>;
  /** An aggregate relationship */
  ownedChats_aggregate: ChatsAggregate;
  /** An array relationship */
  userChats: Array<UserChats>;
  /** An aggregate relationship */
  userChats_aggregate: UserChatsAggregate;
};


/** columns and relationships of "users" */
export type UsersChatMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersChatMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessagesOrderBy>>;
  where?: InputMaybe<ChatMessagesBoolExp>;
};


/** columns and relationships of "users" */
export type UsersChatUsersArgs = {
  distinct_on?: InputMaybe<Array<ChatUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatUsersOrderBy>>;
  where?: InputMaybe<ChatUsersBoolExp>;
};


/** columns and relationships of "users" */
export type UsersChatUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatUsersOrderBy>>;
  where?: InputMaybe<ChatUsersBoolExp>;
};


/** columns and relationships of "users" */
export type UsersImpersonatingUsersArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


/** columns and relationships of "users" */
export type UsersImpersonatingUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};


/** columns and relationships of "users" */
export type UsersOwnedChatsArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersOwnedChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatsOrderBy>>;
  where?: InputMaybe<ChatsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserChatsArgs = {
  distinct_on?: InputMaybe<Array<UserChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserChatsOrderBy>>;
  where?: InputMaybe<UserChatsBoolExp>;
};


/** columns and relationships of "users" */
export type UsersUserChatsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserChatsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserChatsOrderBy>>;
  where?: InputMaybe<UserChatsBoolExp>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

export type UsersAggregateBoolExp = {
  bool_and?: InputMaybe<UsersAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<UsersAggregateBoolExpBoolOr>;
  count?: InputMaybe<UsersAggregateBoolExpCount>;
};

export type UsersAggregateBoolExpBoolAnd = {
  arguments: UsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UsersAggregateBoolExpBoolOr = {
  arguments: UsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type UsersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<UsersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
};


/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type UsersAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UsersMaxOrderBy>;
  min?: InputMaybe<UsersMinOrderBy>;
};

/** input type for inserting array relation for remote table "users" */
export type UsersArrRelInsertInput = {
  data: Array<UsersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UsersOnConflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  channelChatBotUser?: InputMaybe<ChannelChatBotUsersBoolExp>;
  channelChatBotUserId?: InputMaybe<UuidComparisonExp>;
  chatMessages?: InputMaybe<ChatMessagesBoolExp>;
  chatMessages_aggregate?: InputMaybe<ChatMessagesAggregateBoolExp>;
  chatUsers?: InputMaybe<ChatUsersBoolExp>;
  chatUsers_aggregate?: InputMaybe<ChatUsersAggregateBoolExp>;
  customerUser?: InputMaybe<CustomerUsersBoolExp>;
  customerUserId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  impersonatedUser?: InputMaybe<UsersBoolExp>;
  impersonatedUserId?: InputMaybe<UuidComparisonExp>;
  impersonatingUsers?: InputMaybe<UsersBoolExp>;
  impersonatingUsers_aggregate?: InputMaybe<UsersAggregateBoolExp>;
  isDeleted?: InputMaybe<BooleanComparisonExp>;
  lastLoginTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  merchantApiUser?: InputMaybe<MerchantApiUsersBoolExp>;
  merchantApiUserId?: InputMaybe<UuidComparisonExp>;
  merchantUser?: InputMaybe<MerchantUsersBoolExp>;
  merchantUserId?: InputMaybe<UuidComparisonExp>;
  ownedChats?: InputMaybe<ChatsBoolExp>;
  ownedChats_aggregate?: InputMaybe<ChatsAggregateBoolExp>;
  userChats?: InputMaybe<UserChatsBoolExp>;
  userChats_aggregate?: InputMaybe<UserChatsAggregateBoolExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "channel_chat_bot_user_id" */
  UsersChannelChatBotUserIdKey = 'users_channel_chat_bot_user_id_key',
  /** unique or primary key constraint on columns "customer_user_id" */
  UsersCustomerUserIdKey = 'users_customer_user_id_key',
  /** unique or primary key constraint on columns "merchant_api_user_id" */
  UsersMerchantApiUserIdKey = 'users_merchant_api_user_id_key',
  /** unique or primary key constraint on columns "merchant_user_id" */
  UsersMerchantUserIdKey = 'users_merchant_user_id_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  channelChatBotUser?: InputMaybe<ChannelChatBotUsersObjRelInsertInput>;
  channelChatBotUserId?: InputMaybe<Scalars['uuid']>;
  chatMessages?: InputMaybe<ChatMessagesArrRelInsertInput>;
  chatUsers?: InputMaybe<ChatUsersArrRelInsertInput>;
  customerUser?: InputMaybe<CustomerUsersObjRelInsertInput>;
  customerUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impersonatedUser?: InputMaybe<UsersObjRelInsertInput>;
  impersonatedUserId?: InputMaybe<Scalars['uuid']>;
  impersonatingUsers?: InputMaybe<UsersArrRelInsertInput>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastLoginTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantApiUser?: InputMaybe<MerchantApiUsersObjRelInsertInput>;
  merchantApiUserId?: InputMaybe<Scalars['uuid']>;
  merchantUser?: InputMaybe<MerchantUsersObjRelInsertInput>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
  ownedChats?: InputMaybe<ChatsArrRelInsertInput>;
  userChats?: InputMaybe<UserChatsArrRelInsertInput>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'users_max_fields';
  channelChatBotUserId?: Maybe<Scalars['uuid']>;
  customerUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  impersonatedUserId?: Maybe<Scalars['uuid']>;
  lastLoginTimestamp?: Maybe<Scalars['timestamptz']>;
  merchantApiUserId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "users" */
export type UsersMaxOrderBy = {
  channelChatBotUserId?: InputMaybe<OrderBy>;
  customerUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impersonatedUserId?: InputMaybe<OrderBy>;
  lastLoginTimestamp?: InputMaybe<OrderBy>;
  merchantApiUserId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'users_min_fields';
  channelChatBotUserId?: Maybe<Scalars['uuid']>;
  customerUserId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  impersonatedUserId?: Maybe<Scalars['uuid']>;
  lastLoginTimestamp?: Maybe<Scalars['timestamptz']>;
  merchantApiUserId?: Maybe<Scalars['uuid']>;
  merchantUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "users" */
export type UsersMinOrderBy = {
  channelChatBotUserId?: InputMaybe<OrderBy>;
  customerUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impersonatedUserId?: InputMaybe<OrderBy>;
  lastLoginTimestamp?: InputMaybe<OrderBy>;
  merchantApiUserId?: InputMaybe<OrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UsersOnConflict>;
};

/** on_conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "users". */
export type UsersOrderBy = {
  channelChatBotUser?: InputMaybe<ChannelChatBotUsersOrderBy>;
  channelChatBotUserId?: InputMaybe<OrderBy>;
  chatMessages_aggregate?: InputMaybe<ChatMessagesAggregateOrderBy>;
  chatUsers_aggregate?: InputMaybe<ChatUsersAggregateOrderBy>;
  customerUser?: InputMaybe<CustomerUsersOrderBy>;
  customerUserId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  impersonatedUser?: InputMaybe<UsersOrderBy>;
  impersonatedUserId?: InputMaybe<OrderBy>;
  impersonatingUsers_aggregate?: InputMaybe<UsersAggregateOrderBy>;
  isDeleted?: InputMaybe<OrderBy>;
  lastLoginTimestamp?: InputMaybe<OrderBy>;
  merchantApiUser?: InputMaybe<MerchantApiUsersOrderBy>;
  merchantApiUserId?: InputMaybe<OrderBy>;
  merchantUser?: InputMaybe<MerchantUsersOrderBy>;
  merchantUserId?: InputMaybe<OrderBy>;
  ownedChats_aggregate?: InputMaybe<ChatsAggregateOrderBy>;
  userChats_aggregate?: InputMaybe<UserChatsAggregateOrderBy>;
};

/** primary key columns input for table: users */
export type UsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  ChannelChatBotUserId = 'channelChatBotUserId',
  /** column name */
  CustomerUserId = 'customerUserId',
  /** column name */
  Id = 'id',
  /** column name */
  ImpersonatedUserId = 'impersonatedUserId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LastLoginTimestamp = 'lastLoginTimestamp',
  /** column name */
  MerchantApiUserId = 'merchantApiUserId',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum UsersSelectColumnUsersAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum UsersSelectColumnUsersAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsDeleted = 'isDeleted'
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  channelChatBotUserId?: InputMaybe<Scalars['uuid']>;
  customerUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impersonatedUserId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastLoginTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantApiUserId?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  channelChatBotUserId?: InputMaybe<Scalars['uuid']>;
  customerUserId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  impersonatedUserId?: InputMaybe<Scalars['uuid']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lastLoginTimestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantApiUserId?: InputMaybe<Scalars['uuid']>;
  merchantUserId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  ChannelChatBotUserId = 'channelChatBotUserId',
  /** column name */
  CustomerUserId = 'customerUserId',
  /** column name */
  Id = 'id',
  /** column name */
  ImpersonatedUserId = 'impersonatedUserId',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  LastLoginTimestamp = 'lastLoginTimestamp',
  /** column name */
  MerchantApiUserId = 'merchantApiUserId',
  /** column name */
  MerchantUserId = 'merchantUserId'
}

export type UsersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "whatsapp_account_hosting_types" */
export type WhatsappAccountHostingTypes = {
  __typename?: 'whatsapp_account_hosting_types';
  type: Scalars['String'];
};

/** aggregated selection of "whatsapp_account_hosting_types" */
export type WhatsappAccountHostingTypesAggregate = {
  __typename?: 'whatsapp_account_hosting_types_aggregate';
  aggregate?: Maybe<WhatsappAccountHostingTypesAggregateFields>;
  nodes: Array<WhatsappAccountHostingTypes>;
};

/** aggregate fields of "whatsapp_account_hosting_types" */
export type WhatsappAccountHostingTypesAggregateFields = {
  __typename?: 'whatsapp_account_hosting_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappAccountHostingTypesMaxFields>;
  min?: Maybe<WhatsappAccountHostingTypesMinFields>;
};


/** aggregate fields of "whatsapp_account_hosting_types" */
export type WhatsappAccountHostingTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappAccountHostingTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "whatsapp_account_hosting_types". All fields are combined with a logical 'AND'. */
export type WhatsappAccountHostingTypesBoolExp = {
  _and?: InputMaybe<Array<WhatsappAccountHostingTypesBoolExp>>;
  _not?: InputMaybe<WhatsappAccountHostingTypesBoolExp>;
  _or?: InputMaybe<Array<WhatsappAccountHostingTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_account_hosting_types" */
export enum WhatsappAccountHostingTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  WhatsappAccountHostingTypesPkey = 'whatsapp_account_hosting_types_pkey'
}

export enum WhatsappAccountHostingTypesEnum {
  CloudApi = 'CLOUD_API',
  OnPremise = 'ON_PREMISE'
}

/** Boolean expression to compare columns of type "whatsapp_account_hosting_types_enum". All fields are combined with logical 'AND'. */
export type WhatsappAccountHostingTypesEnumComparisonExp = {
  _eq?: InputMaybe<WhatsappAccountHostingTypesEnum>;
  _in?: InputMaybe<Array<WhatsappAccountHostingTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WhatsappAccountHostingTypesEnum>;
  _nin?: InputMaybe<Array<WhatsappAccountHostingTypesEnum>>;
};

/** input type for inserting data into table "whatsapp_account_hosting_types" */
export type WhatsappAccountHostingTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhatsappAccountHostingTypesMaxFields = {
  __typename?: 'whatsapp_account_hosting_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhatsappAccountHostingTypesMinFields = {
  __typename?: 'whatsapp_account_hosting_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "whatsapp_account_hosting_types" */
export type WhatsappAccountHostingTypesMutationResponse = {
  __typename?: 'whatsapp_account_hosting_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappAccountHostingTypes>;
};

/** on_conflict condition type for table "whatsapp_account_hosting_types" */
export type WhatsappAccountHostingTypesOnConflict = {
  constraint: WhatsappAccountHostingTypesConstraint;
  update_columns?: Array<WhatsappAccountHostingTypesUpdateColumn>;
  where?: InputMaybe<WhatsappAccountHostingTypesBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_account_hosting_types". */
export type WhatsappAccountHostingTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_account_hosting_types */
export type WhatsappAccountHostingTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "whatsapp_account_hosting_types" */
export enum WhatsappAccountHostingTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "whatsapp_account_hosting_types" */
export type WhatsappAccountHostingTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_account_hosting_types" */
export type WhatsappAccountHostingTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappAccountHostingTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappAccountHostingTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_account_hosting_types" */
export enum WhatsappAccountHostingTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type WhatsappAccountHostingTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappAccountHostingTypesSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappAccountHostingTypesBoolExp;
};

/** columns and relationships of "whatsapp_account_providers" */
export type WhatsappAccountProviders = {
  __typename?: 'whatsapp_account_providers';
  provider: Scalars['String'];
};

/** aggregated selection of "whatsapp_account_providers" */
export type WhatsappAccountProvidersAggregate = {
  __typename?: 'whatsapp_account_providers_aggregate';
  aggregate?: Maybe<WhatsappAccountProvidersAggregateFields>;
  nodes: Array<WhatsappAccountProviders>;
};

/** aggregate fields of "whatsapp_account_providers" */
export type WhatsappAccountProvidersAggregateFields = {
  __typename?: 'whatsapp_account_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappAccountProvidersMaxFields>;
  min?: Maybe<WhatsappAccountProvidersMinFields>;
};


/** aggregate fields of "whatsapp_account_providers" */
export type WhatsappAccountProvidersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappAccountProvidersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "whatsapp_account_providers". All fields are combined with a logical 'AND'. */
export type WhatsappAccountProvidersBoolExp = {
  _and?: InputMaybe<Array<WhatsappAccountProvidersBoolExp>>;
  _not?: InputMaybe<WhatsappAccountProvidersBoolExp>;
  _or?: InputMaybe<Array<WhatsappAccountProvidersBoolExp>>;
  provider?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_account_providers" */
export enum WhatsappAccountProvidersConstraint {
  /** unique or primary key constraint on columns "provider" */
  WhatsappAccountProvidersPkey = 'whatsapp_account_providers_pkey'
}

export enum WhatsappAccountProvidersEnum {
  ThreeSixty = 'THREE_SIXTY',
  Vonage = 'VONAGE'
}

/** Boolean expression to compare columns of type "whatsapp_account_providers_enum". All fields are combined with logical 'AND'. */
export type WhatsappAccountProvidersEnumComparisonExp = {
  _eq?: InputMaybe<WhatsappAccountProvidersEnum>;
  _in?: InputMaybe<Array<WhatsappAccountProvidersEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WhatsappAccountProvidersEnum>;
  _nin?: InputMaybe<Array<WhatsappAccountProvidersEnum>>;
};

/** input type for inserting data into table "whatsapp_account_providers" */
export type WhatsappAccountProvidersInsertInput = {
  provider?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhatsappAccountProvidersMaxFields = {
  __typename?: 'whatsapp_account_providers_max_fields';
  provider?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhatsappAccountProvidersMinFields = {
  __typename?: 'whatsapp_account_providers_min_fields';
  provider?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "whatsapp_account_providers" */
export type WhatsappAccountProvidersMutationResponse = {
  __typename?: 'whatsapp_account_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappAccountProviders>;
};

/** on_conflict condition type for table "whatsapp_account_providers" */
export type WhatsappAccountProvidersOnConflict = {
  constraint: WhatsappAccountProvidersConstraint;
  update_columns?: Array<WhatsappAccountProvidersUpdateColumn>;
  where?: InputMaybe<WhatsappAccountProvidersBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_account_providers". */
export type WhatsappAccountProvidersOrderBy = {
  provider?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_account_providers */
export type WhatsappAccountProvidersPkColumnsInput = {
  provider: Scalars['String'];
};

/** select columns of table "whatsapp_account_providers" */
export enum WhatsappAccountProvidersSelectColumn {
  /** column name */
  Provider = 'provider'
}

/** input type for updating data in table "whatsapp_account_providers" */
export type WhatsappAccountProvidersSetInput = {
  provider?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_account_providers" */
export type WhatsappAccountProvidersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappAccountProvidersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappAccountProvidersStreamCursorValueInput = {
  provider?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_account_providers" */
export enum WhatsappAccountProvidersUpdateColumn {
  /** column name */
  Provider = 'provider'
}

export type WhatsappAccountProvidersUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappAccountProvidersSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappAccountProvidersBoolExp;
};

/** columns and relationships of "whatsapp_accounts" */
export type WhatsappAccounts = {
  __typename?: 'whatsapp_accounts';
  businessAbout?: Maybe<Scalars['String']>;
  businessAddress?: Maybe<Scalars['String']>;
  businessDescription?: Maybe<Scalars['String']>;
  businessEmail?: Maybe<Scalars['String']>;
  /** An object relationship */
  businessProfilePhotoFile?: Maybe<Files>;
  businessProfilePhotoFileId?: Maybe<Scalars['uuid']>;
  businessWebsite?: Maybe<Scalars['String']>;
  /** An object relationship */
  channel?: Maybe<Channels>;
  hostingType: WhatsappAccountHostingTypesEnum;
  id: Scalars['uuid'];
  insertion_timestamp: Scalars['timestamptz'];
  /** A computed field, executes function "whatsapp_accounts_is_active" */
  isActive?: Maybe<Scalars['Boolean']>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: Maybe<Scalars['timestamptz']>;
  phoneNumber: Scalars['String'];
  provider: WhatsappAccountProvidersEnum;
  threeSixtyApiKey?: Maybe<Scalars['String']>;
  threeSixtyChannelIdentifier?: Maybe<Scalars['String']>;
  threeSixtyHubStatus?: Maybe<Scalars['String']>;
  vonageAppId?: Maybe<Scalars['String']>;
  vonageAppPrivateKey?: Maybe<Scalars['String']>;
  /** An object relationship */
  whatsappBusinessAccount: WhatsappBusinessAccounts;
  whatsappBusinessAccountId: Scalars['uuid'];
  whatsappBusinessVertical?: Maybe<WhatsappBusinessVerticalsEnum>;
};

/** aggregated selection of "whatsapp_accounts" */
export type WhatsappAccountsAggregate = {
  __typename?: 'whatsapp_accounts_aggregate';
  aggregate?: Maybe<WhatsappAccountsAggregateFields>;
  nodes: Array<WhatsappAccounts>;
};

export type WhatsappAccountsAggregateBoolExp = {
  count?: InputMaybe<WhatsappAccountsAggregateBoolExpCount>;
};

export type WhatsappAccountsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WhatsappAccountsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<WhatsappAccountsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "whatsapp_accounts" */
export type WhatsappAccountsAggregateFields = {
  __typename?: 'whatsapp_accounts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappAccountsMaxFields>;
  min?: Maybe<WhatsappAccountsMinFields>;
};


/** aggregate fields of "whatsapp_accounts" */
export type WhatsappAccountsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappAccountsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "whatsapp_accounts" */
export type WhatsappAccountsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WhatsappAccountsMaxOrderBy>;
  min?: InputMaybe<WhatsappAccountsMinOrderBy>;
};

/** input type for inserting array relation for remote table "whatsapp_accounts" */
export type WhatsappAccountsArrRelInsertInput = {
  data: Array<WhatsappAccountsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<WhatsappAccountsOnConflict>;
};

/** Boolean expression to filter rows from the table "whatsapp_accounts". All fields are combined with a logical 'AND'. */
export type WhatsappAccountsBoolExp = {
  _and?: InputMaybe<Array<WhatsappAccountsBoolExp>>;
  _not?: InputMaybe<WhatsappAccountsBoolExp>;
  _or?: InputMaybe<Array<WhatsappAccountsBoolExp>>;
  businessAbout?: InputMaybe<StringComparisonExp>;
  businessAddress?: InputMaybe<StringComparisonExp>;
  businessDescription?: InputMaybe<StringComparisonExp>;
  businessEmail?: InputMaybe<StringComparisonExp>;
  businessProfilePhotoFile?: InputMaybe<FilesBoolExp>;
  businessProfilePhotoFileId?: InputMaybe<UuidComparisonExp>;
  businessWebsite?: InputMaybe<StringComparisonExp>;
  channel?: InputMaybe<ChannelsBoolExp>;
  hostingType?: InputMaybe<WhatsappAccountHostingTypesEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertion_timestamp?: InputMaybe<TimestamptzComparisonExp>;
  isActive?: InputMaybe<BooleanComparisonExp>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  provider?: InputMaybe<WhatsappAccountProvidersEnumComparisonExp>;
  threeSixtyApiKey?: InputMaybe<StringComparisonExp>;
  threeSixtyChannelIdentifier?: InputMaybe<StringComparisonExp>;
  threeSixtyHubStatus?: InputMaybe<StringComparisonExp>;
  vonageAppId?: InputMaybe<StringComparisonExp>;
  vonageAppPrivateKey?: InputMaybe<StringComparisonExp>;
  whatsappBusinessAccount?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
  whatsappBusinessAccountId?: InputMaybe<UuidComparisonExp>;
  whatsappBusinessVertical?: InputMaybe<WhatsappBusinessVerticalsEnumComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_accounts" */
export enum WhatsappAccountsConstraint {
  /** unique or primary key constraint on columns "phone_number" */
  WhatsappAccountsPhoneNumberKey = 'whatsapp_accounts_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  WhatsappAccountsPkey = 'whatsapp_accounts_pkey'
}

/** input type for inserting data into table "whatsapp_accounts" */
export type WhatsappAccountsInsertInput = {
  businessAbout?: InputMaybe<Scalars['String']>;
  businessAddress?: InputMaybe<Scalars['String']>;
  businessDescription?: InputMaybe<Scalars['String']>;
  businessEmail?: InputMaybe<Scalars['String']>;
  businessProfilePhotoFile?: InputMaybe<FilesObjRelInsertInput>;
  businessProfilePhotoFileId?: InputMaybe<Scalars['uuid']>;
  businessWebsite?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<ChannelsObjRelInsertInput>;
  hostingType?: InputMaybe<WhatsappAccountHostingTypesEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  insertion_timestamp?: InputMaybe<Scalars['timestamptz']>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: InputMaybe<Scalars['timestamptz']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<WhatsappAccountProvidersEnum>;
  threeSixtyApiKey?: InputMaybe<Scalars['String']>;
  threeSixtyChannelIdentifier?: InputMaybe<Scalars['String']>;
  threeSixtyHubStatus?: InputMaybe<Scalars['String']>;
  vonageAppId?: InputMaybe<Scalars['String']>;
  vonageAppPrivateKey?: InputMaybe<Scalars['String']>;
  whatsappBusinessAccount?: InputMaybe<WhatsappBusinessAccountsObjRelInsertInput>;
  whatsappBusinessAccountId?: InputMaybe<Scalars['uuid']>;
  whatsappBusinessVertical?: InputMaybe<WhatsappBusinessVerticalsEnum>;
};

/** aggregate max on columns */
export type WhatsappAccountsMaxFields = {
  __typename?: 'whatsapp_accounts_max_fields';
  businessAbout?: Maybe<Scalars['String']>;
  businessAddress?: Maybe<Scalars['String']>;
  businessDescription?: Maybe<Scalars['String']>;
  businessEmail?: Maybe<Scalars['String']>;
  businessProfilePhotoFileId?: Maybe<Scalars['uuid']>;
  businessWebsite?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertion_timestamp?: Maybe<Scalars['timestamptz']>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: Maybe<Scalars['timestamptz']>;
  phoneNumber?: Maybe<Scalars['String']>;
  threeSixtyApiKey?: Maybe<Scalars['String']>;
  threeSixtyChannelIdentifier?: Maybe<Scalars['String']>;
  threeSixtyHubStatus?: Maybe<Scalars['String']>;
  vonageAppId?: Maybe<Scalars['String']>;
  vonageAppPrivateKey?: Maybe<Scalars['String']>;
  whatsappBusinessAccountId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "whatsapp_accounts" */
export type WhatsappAccountsMaxOrderBy = {
  businessAbout?: InputMaybe<OrderBy>;
  businessAddress?: InputMaybe<OrderBy>;
  businessDescription?: InputMaybe<OrderBy>;
  businessEmail?: InputMaybe<OrderBy>;
  businessProfilePhotoFileId?: InputMaybe<OrderBy>;
  businessWebsite?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertion_timestamp?: InputMaybe<OrderBy>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  threeSixtyApiKey?: InputMaybe<OrderBy>;
  threeSixtyChannelIdentifier?: InputMaybe<OrderBy>;
  threeSixtyHubStatus?: InputMaybe<OrderBy>;
  vonageAppId?: InputMaybe<OrderBy>;
  vonageAppPrivateKey?: InputMaybe<OrderBy>;
  whatsappBusinessAccountId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WhatsappAccountsMinFields = {
  __typename?: 'whatsapp_accounts_min_fields';
  businessAbout?: Maybe<Scalars['String']>;
  businessAddress?: Maybe<Scalars['String']>;
  businessDescription?: Maybe<Scalars['String']>;
  businessEmail?: Maybe<Scalars['String']>;
  businessProfilePhotoFileId?: Maybe<Scalars['uuid']>;
  businessWebsite?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insertion_timestamp?: Maybe<Scalars['timestamptz']>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: Maybe<Scalars['timestamptz']>;
  phoneNumber?: Maybe<Scalars['String']>;
  threeSixtyApiKey?: Maybe<Scalars['String']>;
  threeSixtyChannelIdentifier?: Maybe<Scalars['String']>;
  threeSixtyHubStatus?: Maybe<Scalars['String']>;
  vonageAppId?: Maybe<Scalars['String']>;
  vonageAppPrivateKey?: Maybe<Scalars['String']>;
  whatsappBusinessAccountId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "whatsapp_accounts" */
export type WhatsappAccountsMinOrderBy = {
  businessAbout?: InputMaybe<OrderBy>;
  businessAddress?: InputMaybe<OrderBy>;
  businessDescription?: InputMaybe<OrderBy>;
  businessEmail?: InputMaybe<OrderBy>;
  businessProfilePhotoFileId?: InputMaybe<OrderBy>;
  businessWebsite?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertion_timestamp?: InputMaybe<OrderBy>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  threeSixtyApiKey?: InputMaybe<OrderBy>;
  threeSixtyChannelIdentifier?: InputMaybe<OrderBy>;
  threeSixtyHubStatus?: InputMaybe<OrderBy>;
  vonageAppId?: InputMaybe<OrderBy>;
  vonageAppPrivateKey?: InputMaybe<OrderBy>;
  whatsappBusinessAccountId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "whatsapp_accounts" */
export type WhatsappAccountsMutationResponse = {
  __typename?: 'whatsapp_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappAccounts>;
};

/** input type for inserting object relation for remote table "whatsapp_accounts" */
export type WhatsappAccountsObjRelInsertInput = {
  data: WhatsappAccountsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<WhatsappAccountsOnConflict>;
};

/** on_conflict condition type for table "whatsapp_accounts" */
export type WhatsappAccountsOnConflict = {
  constraint: WhatsappAccountsConstraint;
  update_columns?: Array<WhatsappAccountsUpdateColumn>;
  where?: InputMaybe<WhatsappAccountsBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_accounts". */
export type WhatsappAccountsOrderBy = {
  businessAbout?: InputMaybe<OrderBy>;
  businessAddress?: InputMaybe<OrderBy>;
  businessDescription?: InputMaybe<OrderBy>;
  businessEmail?: InputMaybe<OrderBy>;
  businessProfilePhotoFile?: InputMaybe<FilesOrderBy>;
  businessProfilePhotoFileId?: InputMaybe<OrderBy>;
  businessWebsite?: InputMaybe<OrderBy>;
  channel?: InputMaybe<ChannelsOrderBy>;
  hostingType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertion_timestamp?: InputMaybe<OrderBy>;
  isActive?: InputMaybe<OrderBy>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  provider?: InputMaybe<OrderBy>;
  threeSixtyApiKey?: InputMaybe<OrderBy>;
  threeSixtyChannelIdentifier?: InputMaybe<OrderBy>;
  threeSixtyHubStatus?: InputMaybe<OrderBy>;
  vonageAppId?: InputMaybe<OrderBy>;
  vonageAppPrivateKey?: InputMaybe<OrderBy>;
  whatsappBusinessAccount?: InputMaybe<WhatsappBusinessAccountsOrderBy>;
  whatsappBusinessAccountId?: InputMaybe<OrderBy>;
  whatsappBusinessVertical?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_accounts */
export type WhatsappAccountsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "whatsapp_accounts" */
export enum WhatsappAccountsSelectColumn {
  /** column name */
  BusinessAbout = 'businessAbout',
  /** column name */
  BusinessAddress = 'businessAddress',
  /** column name */
  BusinessDescription = 'businessDescription',
  /** column name */
  BusinessEmail = 'businessEmail',
  /** column name */
  BusinessProfilePhotoFileId = 'businessProfilePhotoFileId',
  /** column name */
  BusinessWebsite = 'businessWebsite',
  /** column name */
  HostingType = 'hostingType',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertion_timestamp',
  /** column name */
  LastThreeSixtyMessagesWebhookRegistrationTimestamp = 'lastThreeSixtyMessagesWebhookRegistrationTimestamp',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Provider = 'provider',
  /** column name */
  ThreeSixtyApiKey = 'threeSixtyApiKey',
  /** column name */
  ThreeSixtyChannelIdentifier = 'threeSixtyChannelIdentifier',
  /** column name */
  ThreeSixtyHubStatus = 'threeSixtyHubStatus',
  /** column name */
  VonageAppId = 'vonageAppId',
  /** column name */
  VonageAppPrivateKey = 'vonageAppPrivateKey',
  /** column name */
  WhatsappBusinessAccountId = 'whatsappBusinessAccountId',
  /** column name */
  WhatsappBusinessVertical = 'whatsappBusinessVertical'
}

/** input type for updating data in table "whatsapp_accounts" */
export type WhatsappAccountsSetInput = {
  businessAbout?: InputMaybe<Scalars['String']>;
  businessAddress?: InputMaybe<Scalars['String']>;
  businessDescription?: InputMaybe<Scalars['String']>;
  businessEmail?: InputMaybe<Scalars['String']>;
  businessProfilePhotoFileId?: InputMaybe<Scalars['uuid']>;
  businessWebsite?: InputMaybe<Scalars['String']>;
  hostingType?: InputMaybe<WhatsappAccountHostingTypesEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  insertion_timestamp?: InputMaybe<Scalars['timestamptz']>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: InputMaybe<Scalars['timestamptz']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<WhatsappAccountProvidersEnum>;
  threeSixtyApiKey?: InputMaybe<Scalars['String']>;
  threeSixtyChannelIdentifier?: InputMaybe<Scalars['String']>;
  threeSixtyHubStatus?: InputMaybe<Scalars['String']>;
  vonageAppId?: InputMaybe<Scalars['String']>;
  vonageAppPrivateKey?: InputMaybe<Scalars['String']>;
  whatsappBusinessAccountId?: InputMaybe<Scalars['uuid']>;
  whatsappBusinessVertical?: InputMaybe<WhatsappBusinessVerticalsEnum>;
};

/** Streaming cursor of the table "whatsapp_accounts" */
export type WhatsappAccountsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappAccountsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappAccountsStreamCursorValueInput = {
  businessAbout?: InputMaybe<Scalars['String']>;
  businessAddress?: InputMaybe<Scalars['String']>;
  businessDescription?: InputMaybe<Scalars['String']>;
  businessEmail?: InputMaybe<Scalars['String']>;
  businessProfilePhotoFileId?: InputMaybe<Scalars['uuid']>;
  businessWebsite?: InputMaybe<Scalars['String']>;
  hostingType?: InputMaybe<WhatsappAccountHostingTypesEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  insertion_timestamp?: InputMaybe<Scalars['timestamptz']>;
  lastThreeSixtyMessagesWebhookRegistrationTimestamp?: InputMaybe<Scalars['timestamptz']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<WhatsappAccountProvidersEnum>;
  threeSixtyApiKey?: InputMaybe<Scalars['String']>;
  threeSixtyChannelIdentifier?: InputMaybe<Scalars['String']>;
  threeSixtyHubStatus?: InputMaybe<Scalars['String']>;
  vonageAppId?: InputMaybe<Scalars['String']>;
  vonageAppPrivateKey?: InputMaybe<Scalars['String']>;
  whatsappBusinessAccountId?: InputMaybe<Scalars['uuid']>;
  whatsappBusinessVertical?: InputMaybe<WhatsappBusinessVerticalsEnum>;
};

/** update columns of table "whatsapp_accounts" */
export enum WhatsappAccountsUpdateColumn {
  /** column name */
  BusinessAbout = 'businessAbout',
  /** column name */
  BusinessAddress = 'businessAddress',
  /** column name */
  BusinessDescription = 'businessDescription',
  /** column name */
  BusinessEmail = 'businessEmail',
  /** column name */
  BusinessProfilePhotoFileId = 'businessProfilePhotoFileId',
  /** column name */
  BusinessWebsite = 'businessWebsite',
  /** column name */
  HostingType = 'hostingType',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertion_timestamp',
  /** column name */
  LastThreeSixtyMessagesWebhookRegistrationTimestamp = 'lastThreeSixtyMessagesWebhookRegistrationTimestamp',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Provider = 'provider',
  /** column name */
  ThreeSixtyApiKey = 'threeSixtyApiKey',
  /** column name */
  ThreeSixtyChannelIdentifier = 'threeSixtyChannelIdentifier',
  /** column name */
  ThreeSixtyHubStatus = 'threeSixtyHubStatus',
  /** column name */
  VonageAppId = 'vonageAppId',
  /** column name */
  VonageAppPrivateKey = 'vonageAppPrivateKey',
  /** column name */
  WhatsappBusinessAccountId = 'whatsappBusinessAccountId',
  /** column name */
  WhatsappBusinessVertical = 'whatsappBusinessVertical'
}

export type WhatsappAccountsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappAccountsSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappAccountsBoolExp;
};

/** columns and relationships of "whatsapp_business_accounts" */
export type WhatsappBusinessAccounts = {
  __typename?: 'whatsapp_business_accounts';
  /** An object relationship */
  fileAttachmentWhatsappMessageTemplate?: Maybe<WhatsappMessageTemplates>;
  fileAttachmentWhatsappMessageTemplateId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  insertion_timestamp: Scalars['timestamptz'];
  /** An object relationship */
  merchant: Merchants;
  merchantId: Scalars['uuid'];
  namespace: Scalars['String'];
  wabaIdentifier?: Maybe<Scalars['String']>;
  /** An array relationship */
  whatsappAccounts: Array<WhatsappAccounts>;
  /** An aggregate relationship */
  whatsappAccounts_aggregate: WhatsappAccountsAggregate;
  /** An array relationship */
  whatsappMessageTemplates: Array<WhatsappMessageTemplates>;
  /** An aggregate relationship */
  whatsappMessageTemplates_aggregate: WhatsappMessageTemplatesAggregate;
};


/** columns and relationships of "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsWhatsappAccountsArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountsOrderBy>>;
  where?: InputMaybe<WhatsappAccountsBoolExp>;
};


/** columns and relationships of "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsWhatsappAccountsAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappAccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappAccountsOrderBy>>;
  where?: InputMaybe<WhatsappAccountsBoolExp>;
};


/** columns and relationships of "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsWhatsappMessageTemplatesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplatesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};


/** columns and relationships of "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsWhatsappMessageTemplatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplatesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};

/** aggregated selection of "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsAggregate = {
  __typename?: 'whatsapp_business_accounts_aggregate';
  aggregate?: Maybe<WhatsappBusinessAccountsAggregateFields>;
  nodes: Array<WhatsappBusinessAccounts>;
};

export type WhatsappBusinessAccountsAggregateBoolExp = {
  count?: InputMaybe<WhatsappBusinessAccountsAggregateBoolExpCount>;
};

export type WhatsappBusinessAccountsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WhatsappBusinessAccountsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsAggregateFields = {
  __typename?: 'whatsapp_business_accounts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappBusinessAccountsMaxFields>;
  min?: Maybe<WhatsappBusinessAccountsMinFields>;
};


/** aggregate fields of "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappBusinessAccountsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WhatsappBusinessAccountsMaxOrderBy>;
  min?: InputMaybe<WhatsappBusinessAccountsMinOrderBy>;
};

/** input type for inserting array relation for remote table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsArrRelInsertInput = {
  data: Array<WhatsappBusinessAccountsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<WhatsappBusinessAccountsOnConflict>;
};

/** Boolean expression to filter rows from the table "whatsapp_business_accounts". All fields are combined with a logical 'AND'. */
export type WhatsappBusinessAccountsBoolExp = {
  _and?: InputMaybe<Array<WhatsappBusinessAccountsBoolExp>>;
  _not?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
  _or?: InputMaybe<Array<WhatsappBusinessAccountsBoolExp>>;
  fileAttachmentWhatsappMessageTemplate?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
  fileAttachmentWhatsappMessageTemplateId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insertion_timestamp?: InputMaybe<TimestamptzComparisonExp>;
  merchant?: InputMaybe<MerchantsBoolExp>;
  merchantId?: InputMaybe<UuidComparisonExp>;
  namespace?: InputMaybe<StringComparisonExp>;
  wabaIdentifier?: InputMaybe<StringComparisonExp>;
  whatsappAccounts?: InputMaybe<WhatsappAccountsBoolExp>;
  whatsappAccounts_aggregate?: InputMaybe<WhatsappAccountsAggregateBoolExp>;
  whatsappMessageTemplates?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
  whatsappMessageTemplates_aggregate?: InputMaybe<WhatsappMessageTemplatesAggregateBoolExp>;
};

/** unique or primary key constraints on table "whatsapp_business_accounts" */
export enum WhatsappBusinessAccountsConstraint {
  /** unique or primary key constraint on columns "namespace" */
  WhatsappBusinessAccountsNamespaceKey = 'whatsapp_business_accounts_namespace_key',
  /** unique or primary key constraint on columns "id" */
  WhatsappBusinessAccountsPkey = 'whatsapp_business_accounts_pkey'
}

/** input type for inserting data into table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsInsertInput = {
  fileAttachmentWhatsappMessageTemplate?: InputMaybe<WhatsappMessageTemplatesObjRelInsertInput>;
  fileAttachmentWhatsappMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertion_timestamp?: InputMaybe<Scalars['timestamptz']>;
  merchant?: InputMaybe<MerchantsObjRelInsertInput>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  namespace?: InputMaybe<Scalars['String']>;
  wabaIdentifier?: InputMaybe<Scalars['String']>;
  whatsappAccounts?: InputMaybe<WhatsappAccountsArrRelInsertInput>;
  whatsappMessageTemplates?: InputMaybe<WhatsappMessageTemplatesArrRelInsertInput>;
};

/** aggregate max on columns */
export type WhatsappBusinessAccountsMaxFields = {
  __typename?: 'whatsapp_business_accounts_max_fields';
  fileAttachmentWhatsappMessageTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertion_timestamp?: Maybe<Scalars['timestamptz']>;
  merchantId?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  wabaIdentifier?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsMaxOrderBy = {
  fileAttachmentWhatsappMessageTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertion_timestamp?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  namespace?: InputMaybe<OrderBy>;
  wabaIdentifier?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WhatsappBusinessAccountsMinFields = {
  __typename?: 'whatsapp_business_accounts_min_fields';
  fileAttachmentWhatsappMessageTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  insertion_timestamp?: Maybe<Scalars['timestamptz']>;
  merchantId?: Maybe<Scalars['uuid']>;
  namespace?: Maybe<Scalars['String']>;
  wabaIdentifier?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsMinOrderBy = {
  fileAttachmentWhatsappMessageTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertion_timestamp?: InputMaybe<OrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  namespace?: InputMaybe<OrderBy>;
  wabaIdentifier?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsMutationResponse = {
  __typename?: 'whatsapp_business_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappBusinessAccounts>;
};

/** input type for inserting object relation for remote table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsObjRelInsertInput = {
  data: WhatsappBusinessAccountsInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<WhatsappBusinessAccountsOnConflict>;
};

/** on_conflict condition type for table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsOnConflict = {
  constraint: WhatsappBusinessAccountsConstraint;
  update_columns?: Array<WhatsappBusinessAccountsUpdateColumn>;
  where?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_business_accounts". */
export type WhatsappBusinessAccountsOrderBy = {
  fileAttachmentWhatsappMessageTemplate?: InputMaybe<WhatsappMessageTemplatesOrderBy>;
  fileAttachmentWhatsappMessageTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insertion_timestamp?: InputMaybe<OrderBy>;
  merchant?: InputMaybe<MerchantsOrderBy>;
  merchantId?: InputMaybe<OrderBy>;
  namespace?: InputMaybe<OrderBy>;
  wabaIdentifier?: InputMaybe<OrderBy>;
  whatsappAccounts_aggregate?: InputMaybe<WhatsappAccountsAggregateOrderBy>;
  whatsappMessageTemplates_aggregate?: InputMaybe<WhatsappMessageTemplatesAggregateOrderBy>;
};

/** primary key columns input for table: whatsapp_business_accounts */
export type WhatsappBusinessAccountsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "whatsapp_business_accounts" */
export enum WhatsappBusinessAccountsSelectColumn {
  /** column name */
  FileAttachmentWhatsappMessageTemplateId = 'fileAttachmentWhatsappMessageTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertion_timestamp',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  WabaIdentifier = 'wabaIdentifier'
}

/** input type for updating data in table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsSetInput = {
  fileAttachmentWhatsappMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertion_timestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  namespace?: InputMaybe<Scalars['String']>;
  wabaIdentifier?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_business_accounts" */
export type WhatsappBusinessAccountsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappBusinessAccountsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappBusinessAccountsStreamCursorValueInput = {
  fileAttachmentWhatsappMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  insertion_timestamp?: InputMaybe<Scalars['timestamptz']>;
  merchantId?: InputMaybe<Scalars['uuid']>;
  namespace?: InputMaybe<Scalars['String']>;
  wabaIdentifier?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_business_accounts" */
export enum WhatsappBusinessAccountsUpdateColumn {
  /** column name */
  FileAttachmentWhatsappMessageTemplateId = 'fileAttachmentWhatsappMessageTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  InsertionTimestamp = 'insertion_timestamp',
  /** column name */
  MerchantId = 'merchantId',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  WabaIdentifier = 'wabaIdentifier'
}

export type WhatsappBusinessAccountsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappBusinessAccountsSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappBusinessAccountsBoolExp;
};

/** columns and relationships of "whatsapp_business_verticals" */
export type WhatsappBusinessVerticals = {
  __typename?: 'whatsapp_business_verticals';
  metaOnPremiseApiName?: Maybe<Scalars['String']>;
  vertical: Scalars['String'];
};

/** aggregated selection of "whatsapp_business_verticals" */
export type WhatsappBusinessVerticalsAggregate = {
  __typename?: 'whatsapp_business_verticals_aggregate';
  aggregate?: Maybe<WhatsappBusinessVerticalsAggregateFields>;
  nodes: Array<WhatsappBusinessVerticals>;
};

/** aggregate fields of "whatsapp_business_verticals" */
export type WhatsappBusinessVerticalsAggregateFields = {
  __typename?: 'whatsapp_business_verticals_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappBusinessVerticalsMaxFields>;
  min?: Maybe<WhatsappBusinessVerticalsMinFields>;
};


/** aggregate fields of "whatsapp_business_verticals" */
export type WhatsappBusinessVerticalsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappBusinessVerticalsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "whatsapp_business_verticals". All fields are combined with a logical 'AND'. */
export type WhatsappBusinessVerticalsBoolExp = {
  _and?: InputMaybe<Array<WhatsappBusinessVerticalsBoolExp>>;
  _not?: InputMaybe<WhatsappBusinessVerticalsBoolExp>;
  _or?: InputMaybe<Array<WhatsappBusinessVerticalsBoolExp>>;
  metaOnPremiseApiName?: InputMaybe<StringComparisonExp>;
  vertical?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_business_verticals" */
export enum WhatsappBusinessVerticalsConstraint {
  /** unique or primary key constraint on columns "vertical" */
  WhatsappBusinessVerticalsPkey = 'whatsapp_business_verticals_pkey'
}

export enum WhatsappBusinessVerticalsEnum {
  /** Clothing and Apparel */
  Apparel = 'APPAREL',
  /** Automotive */
  Auto = 'AUTO',
  /** Beauty, Spa and Salon */
  Beauty = 'BEAUTY',
  /** Education */
  Edu = 'EDU',
  /** Entertainment */
  Entertain = 'ENTERTAIN',
  /** Event Planning and Service */
  EventPlan = 'EVENT_PLAN',
  /** Finance and Banking */
  Finance = 'FINANCE',
  /** Public Service */
  Govt = 'GOVT',
  /** Food and Grocery */
  Grocery = 'GROCERY',
  /** Medical and Health */
  Health = 'HEALTH',
  /** Hotel and Lodging */
  Hotel = 'HOTEL',
  /** Non-profit */
  Nonprofit = 'NONPROFIT',
  NotABiz = 'NOT_A_BIZ',
  /** Other */
  Other = 'OTHER',
  /** Professional Services */
  ProfServices = 'PROF_SERVICES',
  /** Restaurant */
  Restaurant = 'RESTAURANT',
  /** Shopping and Retail */
  Retail = 'RETAIL',
  /** Travel and Transportation */
  Travel = 'TRAVEL',
  Undefined = 'UNDEFINED'
}

/** Boolean expression to compare columns of type "whatsapp_business_verticals_enum". All fields are combined with logical 'AND'. */
export type WhatsappBusinessVerticalsEnumComparisonExp = {
  _eq?: InputMaybe<WhatsappBusinessVerticalsEnum>;
  _in?: InputMaybe<Array<WhatsappBusinessVerticalsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WhatsappBusinessVerticalsEnum>;
  _nin?: InputMaybe<Array<WhatsappBusinessVerticalsEnum>>;
};

/** input type for inserting data into table "whatsapp_business_verticals" */
export type WhatsappBusinessVerticalsInsertInput = {
  metaOnPremiseApiName?: InputMaybe<Scalars['String']>;
  vertical?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhatsappBusinessVerticalsMaxFields = {
  __typename?: 'whatsapp_business_verticals_max_fields';
  metaOnPremiseApiName?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhatsappBusinessVerticalsMinFields = {
  __typename?: 'whatsapp_business_verticals_min_fields';
  metaOnPremiseApiName?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "whatsapp_business_verticals" */
export type WhatsappBusinessVerticalsMutationResponse = {
  __typename?: 'whatsapp_business_verticals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappBusinessVerticals>;
};

/** on_conflict condition type for table "whatsapp_business_verticals" */
export type WhatsappBusinessVerticalsOnConflict = {
  constraint: WhatsappBusinessVerticalsConstraint;
  update_columns?: Array<WhatsappBusinessVerticalsUpdateColumn>;
  where?: InputMaybe<WhatsappBusinessVerticalsBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_business_verticals". */
export type WhatsappBusinessVerticalsOrderBy = {
  metaOnPremiseApiName?: InputMaybe<OrderBy>;
  vertical?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_business_verticals */
export type WhatsappBusinessVerticalsPkColumnsInput = {
  vertical: Scalars['String'];
};

/** select columns of table "whatsapp_business_verticals" */
export enum WhatsappBusinessVerticalsSelectColumn {
  /** column name */
  MetaOnPremiseApiName = 'metaOnPremiseApiName',
  /** column name */
  Vertical = 'vertical'
}

/** input type for updating data in table "whatsapp_business_verticals" */
export type WhatsappBusinessVerticalsSetInput = {
  metaOnPremiseApiName?: InputMaybe<Scalars['String']>;
  vertical?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_business_verticals" */
export type WhatsappBusinessVerticalsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappBusinessVerticalsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappBusinessVerticalsStreamCursorValueInput = {
  metaOnPremiseApiName?: InputMaybe<Scalars['String']>;
  vertical?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_business_verticals" */
export enum WhatsappBusinessVerticalsUpdateColumn {
  /** column name */
  MetaOnPremiseApiName = 'metaOnPremiseApiName',
  /** column name */
  Vertical = 'vertical'
}

export type WhatsappBusinessVerticalsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappBusinessVerticalsSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappBusinessVerticalsBoolExp;
};

/** columns and relationships of "whatsapp_conversation_types" */
export type WhatsappConversationTypes = {
  __typename?: 'whatsapp_conversation_types';
  type: Scalars['String'];
};

/** aggregated selection of "whatsapp_conversation_types" */
export type WhatsappConversationTypesAggregate = {
  __typename?: 'whatsapp_conversation_types_aggregate';
  aggregate?: Maybe<WhatsappConversationTypesAggregateFields>;
  nodes: Array<WhatsappConversationTypes>;
};

/** aggregate fields of "whatsapp_conversation_types" */
export type WhatsappConversationTypesAggregateFields = {
  __typename?: 'whatsapp_conversation_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappConversationTypesMaxFields>;
  min?: Maybe<WhatsappConversationTypesMinFields>;
};


/** aggregate fields of "whatsapp_conversation_types" */
export type WhatsappConversationTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappConversationTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "whatsapp_conversation_types". All fields are combined with a logical 'AND'. */
export type WhatsappConversationTypesBoolExp = {
  _and?: InputMaybe<Array<WhatsappConversationTypesBoolExp>>;
  _not?: InputMaybe<WhatsappConversationTypesBoolExp>;
  _or?: InputMaybe<Array<WhatsappConversationTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_conversation_types" */
export enum WhatsappConversationTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  WhatsappConversationTypesPkey = 'whatsapp_conversation_types_pkey'
}

/** input type for inserting data into table "whatsapp_conversation_types" */
export type WhatsappConversationTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhatsappConversationTypesMaxFields = {
  __typename?: 'whatsapp_conversation_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhatsappConversationTypesMinFields = {
  __typename?: 'whatsapp_conversation_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "whatsapp_conversation_types" */
export type WhatsappConversationTypesMutationResponse = {
  __typename?: 'whatsapp_conversation_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappConversationTypes>;
};

/** on_conflict condition type for table "whatsapp_conversation_types" */
export type WhatsappConversationTypesOnConflict = {
  constraint: WhatsappConversationTypesConstraint;
  update_columns?: Array<WhatsappConversationTypesUpdateColumn>;
  where?: InputMaybe<WhatsappConversationTypesBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_conversation_types". */
export type WhatsappConversationTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_conversation_types */
export type WhatsappConversationTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "whatsapp_conversation_types" */
export enum WhatsappConversationTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "whatsapp_conversation_types" */
export type WhatsappConversationTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_conversation_types" */
export type WhatsappConversationTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappConversationTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappConversationTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_conversation_types" */
export enum WhatsappConversationTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type WhatsappConversationTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappConversationTypesSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappConversationTypesBoolExp;
};

/** columns and relationships of "whatsapp_conversations" */
export type WhatsappConversations = {
  __typename?: 'whatsapp_conversations';
  customerIdentifier: Scalars['String'];
  expirationTimestamp: Scalars['timestamptz'];
  id: Scalars['uuid'];
  identifier: Scalars['String'];
  merchantPhoneNumber: Scalars['String'];
  price: Scalars['Int'];
  type: Scalars['String'];
  whatsapp_account_id: Scalars['uuid'];
};

/** aggregated selection of "whatsapp_conversations" */
export type WhatsappConversationsAggregate = {
  __typename?: 'whatsapp_conversations_aggregate';
  aggregate?: Maybe<WhatsappConversationsAggregateFields>;
  nodes: Array<WhatsappConversations>;
};

/** aggregate fields of "whatsapp_conversations" */
export type WhatsappConversationsAggregateFields = {
  __typename?: 'whatsapp_conversations_aggregate_fields';
  avg?: Maybe<WhatsappConversationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<WhatsappConversationsMaxFields>;
  min?: Maybe<WhatsappConversationsMinFields>;
  stddev?: Maybe<WhatsappConversationsStddevFields>;
  stddev_pop?: Maybe<WhatsappConversationsStddevPopFields>;
  stddev_samp?: Maybe<WhatsappConversationsStddevSampFields>;
  sum?: Maybe<WhatsappConversationsSumFields>;
  var_pop?: Maybe<WhatsappConversationsVarPopFields>;
  var_samp?: Maybe<WhatsappConversationsVarSampFields>;
  variance?: Maybe<WhatsappConversationsVarianceFields>;
};


/** aggregate fields of "whatsapp_conversations" */
export type WhatsappConversationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappConversationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type WhatsappConversationsAvgFields = {
  __typename?: 'whatsapp_conversations_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "whatsapp_conversations". All fields are combined with a logical 'AND'. */
export type WhatsappConversationsBoolExp = {
  _and?: InputMaybe<Array<WhatsappConversationsBoolExp>>;
  _not?: InputMaybe<WhatsappConversationsBoolExp>;
  _or?: InputMaybe<Array<WhatsappConversationsBoolExp>>;
  customerIdentifier?: InputMaybe<StringComparisonExp>;
  expirationTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  identifier?: InputMaybe<StringComparisonExp>;
  merchantPhoneNumber?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  whatsapp_account_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_conversations" */
export enum WhatsappConversationsConstraint {
  /** unique or primary key constraint on columns "identifier" */
  WhatsappConversationsIdentifierKey = 'whatsapp_conversations_identifier_key',
  /** unique or primary key constraint on columns "id" */
  WhatsappConversationsPkey = 'whatsapp_conversations_pkey'
}

/** input type for incrementing numeric columns in table "whatsapp_conversations" */
export type WhatsappConversationsIncInput = {
  price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "whatsapp_conversations" */
export type WhatsappConversationsInsertInput = {
  customerIdentifier?: InputMaybe<Scalars['String']>;
  expirationTimestamp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  merchantPhoneNumber?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  whatsapp_account_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type WhatsappConversationsMaxFields = {
  __typename?: 'whatsapp_conversations_max_fields';
  customerIdentifier?: Maybe<Scalars['String']>;
  expirationTimestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['String']>;
  merchantPhoneNumber?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  whatsapp_account_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type WhatsappConversationsMinFields = {
  __typename?: 'whatsapp_conversations_min_fields';
  customerIdentifier?: Maybe<Scalars['String']>;
  expirationTimestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['String']>;
  merchantPhoneNumber?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  whatsapp_account_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "whatsapp_conversations" */
export type WhatsappConversationsMutationResponse = {
  __typename?: 'whatsapp_conversations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappConversations>;
};

/** on_conflict condition type for table "whatsapp_conversations" */
export type WhatsappConversationsOnConflict = {
  constraint: WhatsappConversationsConstraint;
  update_columns?: Array<WhatsappConversationsUpdateColumn>;
  where?: InputMaybe<WhatsappConversationsBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_conversations". */
export type WhatsappConversationsOrderBy = {
  customerIdentifier?: InputMaybe<OrderBy>;
  expirationTimestamp?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  identifier?: InputMaybe<OrderBy>;
  merchantPhoneNumber?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  whatsapp_account_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_conversations */
export type WhatsappConversationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "whatsapp_conversations" */
export enum WhatsappConversationsSelectColumn {
  /** column name */
  CustomerIdentifier = 'customerIdentifier',
  /** column name */
  ExpirationTimestamp = 'expirationTimestamp',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  MerchantPhoneNumber = 'merchantPhoneNumber',
  /** column name */
  Price = 'price',
  /** column name */
  Type = 'type',
  /** column name */
  WhatsappAccountId = 'whatsapp_account_id'
}

/** input type for updating data in table "whatsapp_conversations" */
export type WhatsappConversationsSetInput = {
  customerIdentifier?: InputMaybe<Scalars['String']>;
  expirationTimestamp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  merchantPhoneNumber?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  whatsapp_account_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type WhatsappConversationsStddevFields = {
  __typename?: 'whatsapp_conversations_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WhatsappConversationsStddevPopFields = {
  __typename?: 'whatsapp_conversations_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WhatsappConversationsStddevSampFields = {
  __typename?: 'whatsapp_conversations_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "whatsapp_conversations" */
export type WhatsappConversationsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappConversationsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappConversationsStreamCursorValueInput = {
  customerIdentifier?: InputMaybe<Scalars['String']>;
  expirationTimestamp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  merchantPhoneNumber?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  whatsapp_account_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type WhatsappConversationsSumFields = {
  __typename?: 'whatsapp_conversations_sum_fields';
  price?: Maybe<Scalars['Int']>;
};

/** update columns of table "whatsapp_conversations" */
export enum WhatsappConversationsUpdateColumn {
  /** column name */
  CustomerIdentifier = 'customerIdentifier',
  /** column name */
  ExpirationTimestamp = 'expirationTimestamp',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  MerchantPhoneNumber = 'merchantPhoneNumber',
  /** column name */
  Price = 'price',
  /** column name */
  Type = 'type',
  /** column name */
  WhatsappAccountId = 'whatsapp_account_id'
}

export type WhatsappConversationsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WhatsappConversationsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappConversationsSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappConversationsBoolExp;
};

/** aggregate var_pop on columns */
export type WhatsappConversationsVarPopFields = {
  __typename?: 'whatsapp_conversations_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WhatsappConversationsVarSampFields = {
  __typename?: 'whatsapp_conversations_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WhatsappConversationsVarianceFields = {
  __typename?: 'whatsapp_conversations_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "whatsapp_media_types" */
export type WhatsappMediaTypes = {
  __typename?: 'whatsapp_media_types';
  type: Scalars['String'];
};

/** aggregated selection of "whatsapp_media_types" */
export type WhatsappMediaTypesAggregate = {
  __typename?: 'whatsapp_media_types_aggregate';
  aggregate?: Maybe<WhatsappMediaTypesAggregateFields>;
  nodes: Array<WhatsappMediaTypes>;
};

/** aggregate fields of "whatsapp_media_types" */
export type WhatsappMediaTypesAggregateFields = {
  __typename?: 'whatsapp_media_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappMediaTypesMaxFields>;
  min?: Maybe<WhatsappMediaTypesMinFields>;
};


/** aggregate fields of "whatsapp_media_types" */
export type WhatsappMediaTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappMediaTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "whatsapp_media_types". All fields are combined with a logical 'AND'. */
export type WhatsappMediaTypesBoolExp = {
  _and?: InputMaybe<Array<WhatsappMediaTypesBoolExp>>;
  _not?: InputMaybe<WhatsappMediaTypesBoolExp>;
  _or?: InputMaybe<Array<WhatsappMediaTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_media_types" */
export enum WhatsappMediaTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  WhatsappMediaTypesPkey = 'whatsapp_media_types_pkey'
}

export enum WhatsappMediaTypesEnum {
  Audio = 'audio',
  Document = 'document',
  Image = 'image',
  Sticker = 'sticker',
  Video = 'video'
}

/** Boolean expression to compare columns of type "whatsapp_media_types_enum". All fields are combined with logical 'AND'. */
export type WhatsappMediaTypesEnumComparisonExp = {
  _eq?: InputMaybe<WhatsappMediaTypesEnum>;
  _in?: InputMaybe<Array<WhatsappMediaTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WhatsappMediaTypesEnum>;
  _nin?: InputMaybe<Array<WhatsappMediaTypesEnum>>;
};

/** input type for inserting data into table "whatsapp_media_types" */
export type WhatsappMediaTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhatsappMediaTypesMaxFields = {
  __typename?: 'whatsapp_media_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhatsappMediaTypesMinFields = {
  __typename?: 'whatsapp_media_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "whatsapp_media_types" */
export type WhatsappMediaTypesMutationResponse = {
  __typename?: 'whatsapp_media_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappMediaTypes>;
};

/** on_conflict condition type for table "whatsapp_media_types" */
export type WhatsappMediaTypesOnConflict = {
  constraint: WhatsappMediaTypesConstraint;
  update_columns?: Array<WhatsappMediaTypesUpdateColumn>;
  where?: InputMaybe<WhatsappMediaTypesBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_media_types". */
export type WhatsappMediaTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_media_types */
export type WhatsappMediaTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "whatsapp_media_types" */
export enum WhatsappMediaTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "whatsapp_media_types" */
export type WhatsappMediaTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_media_types" */
export type WhatsappMediaTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappMediaTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappMediaTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_media_types" */
export enum WhatsappMediaTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type WhatsappMediaTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappMediaTypesSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappMediaTypesBoolExp;
};

/** columns and relationships of "whatsapp_message_directions" */
export type WhatsappMessageDirections = {
  __typename?: 'whatsapp_message_directions';
  direction: Scalars['String'];
};

/** aggregated selection of "whatsapp_message_directions" */
export type WhatsappMessageDirectionsAggregate = {
  __typename?: 'whatsapp_message_directions_aggregate';
  aggregate?: Maybe<WhatsappMessageDirectionsAggregateFields>;
  nodes: Array<WhatsappMessageDirections>;
};

/** aggregate fields of "whatsapp_message_directions" */
export type WhatsappMessageDirectionsAggregateFields = {
  __typename?: 'whatsapp_message_directions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappMessageDirectionsMaxFields>;
  min?: Maybe<WhatsappMessageDirectionsMinFields>;
};


/** aggregate fields of "whatsapp_message_directions" */
export type WhatsappMessageDirectionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappMessageDirectionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "whatsapp_message_directions". All fields are combined with a logical 'AND'. */
export type WhatsappMessageDirectionsBoolExp = {
  _and?: InputMaybe<Array<WhatsappMessageDirectionsBoolExp>>;
  _not?: InputMaybe<WhatsappMessageDirectionsBoolExp>;
  _or?: InputMaybe<Array<WhatsappMessageDirectionsBoolExp>>;
  direction?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_message_directions" */
export enum WhatsappMessageDirectionsConstraint {
  /** unique or primary key constraint on columns "direction" */
  WhatsappMessagesDirectionsPkey = 'whatsapp_messages_directions_pkey'
}

export enum WhatsappMessageDirectionsEnum {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

/** Boolean expression to compare columns of type "whatsapp_message_directions_enum". All fields are combined with logical 'AND'. */
export type WhatsappMessageDirectionsEnumComparisonExp = {
  _eq?: InputMaybe<WhatsappMessageDirectionsEnum>;
  _in?: InputMaybe<Array<WhatsappMessageDirectionsEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WhatsappMessageDirectionsEnum>;
  _nin?: InputMaybe<Array<WhatsappMessageDirectionsEnum>>;
};

/** input type for inserting data into table "whatsapp_message_directions" */
export type WhatsappMessageDirectionsInsertInput = {
  direction?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhatsappMessageDirectionsMaxFields = {
  __typename?: 'whatsapp_message_directions_max_fields';
  direction?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhatsappMessageDirectionsMinFields = {
  __typename?: 'whatsapp_message_directions_min_fields';
  direction?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "whatsapp_message_directions" */
export type WhatsappMessageDirectionsMutationResponse = {
  __typename?: 'whatsapp_message_directions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappMessageDirections>;
};

/** on_conflict condition type for table "whatsapp_message_directions" */
export type WhatsappMessageDirectionsOnConflict = {
  constraint: WhatsappMessageDirectionsConstraint;
  update_columns?: Array<WhatsappMessageDirectionsUpdateColumn>;
  where?: InputMaybe<WhatsappMessageDirectionsBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_message_directions". */
export type WhatsappMessageDirectionsOrderBy = {
  direction?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_message_directions */
export type WhatsappMessageDirectionsPkColumnsInput = {
  direction: Scalars['String'];
};

/** select columns of table "whatsapp_message_directions" */
export enum WhatsappMessageDirectionsSelectColumn {
  /** column name */
  Direction = 'direction'
}

/** input type for updating data in table "whatsapp_message_directions" */
export type WhatsappMessageDirectionsSetInput = {
  direction?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_message_directions" */
export type WhatsappMessageDirectionsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappMessageDirectionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappMessageDirectionsStreamCursorValueInput = {
  direction?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_message_directions" */
export enum WhatsappMessageDirectionsUpdateColumn {
  /** column name */
  Direction = 'direction'
}

export type WhatsappMessageDirectionsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappMessageDirectionsSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappMessageDirectionsBoolExp;
};

/** columns and relationships of "whatsapp_message_statuses" */
export type WhatsappMessageStatuses = {
  __typename?: 'whatsapp_message_statuses';
  status: Scalars['String'];
};

/** aggregated selection of "whatsapp_message_statuses" */
export type WhatsappMessageStatusesAggregate = {
  __typename?: 'whatsapp_message_statuses_aggregate';
  aggregate?: Maybe<WhatsappMessageStatusesAggregateFields>;
  nodes: Array<WhatsappMessageStatuses>;
};

/** aggregate fields of "whatsapp_message_statuses" */
export type WhatsappMessageStatusesAggregateFields = {
  __typename?: 'whatsapp_message_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappMessageStatusesMaxFields>;
  min?: Maybe<WhatsappMessageStatusesMinFields>;
};


/** aggregate fields of "whatsapp_message_statuses" */
export type WhatsappMessageStatusesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappMessageStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "whatsapp_message_statuses". All fields are combined with a logical 'AND'. */
export type WhatsappMessageStatusesBoolExp = {
  _and?: InputMaybe<Array<WhatsappMessageStatusesBoolExp>>;
  _not?: InputMaybe<WhatsappMessageStatusesBoolExp>;
  _or?: InputMaybe<Array<WhatsappMessageStatusesBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_message_statuses" */
export enum WhatsappMessageStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  WhatsappMessagesStatusesPkey = 'whatsapp_messages_statuses_pkey'
}

export enum WhatsappMessageStatusesEnum {
  AwaitingSend = 'AWAITING_SEND',
  Canceled = 'CANCELED',
  Deleted = 'DELETED',
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Read = 'READ',
  Received = 'RECEIVED',
  Sending = 'SENDING',
  Sent = 'SENT'
}

/** Boolean expression to compare columns of type "whatsapp_message_statuses_enum". All fields are combined with logical 'AND'. */
export type WhatsappMessageStatusesEnumComparisonExp = {
  _eq?: InputMaybe<WhatsappMessageStatusesEnum>;
  _in?: InputMaybe<Array<WhatsappMessageStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WhatsappMessageStatusesEnum>;
  _nin?: InputMaybe<Array<WhatsappMessageStatusesEnum>>;
};

/** input type for inserting data into table "whatsapp_message_statuses" */
export type WhatsappMessageStatusesInsertInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhatsappMessageStatusesMaxFields = {
  __typename?: 'whatsapp_message_statuses_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhatsappMessageStatusesMinFields = {
  __typename?: 'whatsapp_message_statuses_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "whatsapp_message_statuses" */
export type WhatsappMessageStatusesMutationResponse = {
  __typename?: 'whatsapp_message_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappMessageStatuses>;
};

/** on_conflict condition type for table "whatsapp_message_statuses" */
export type WhatsappMessageStatusesOnConflict = {
  constraint: WhatsappMessageStatusesConstraint;
  update_columns?: Array<WhatsappMessageStatusesUpdateColumn>;
  where?: InputMaybe<WhatsappMessageStatusesBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_message_statuses". */
export type WhatsappMessageStatusesOrderBy = {
  status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_message_statuses */
export type WhatsappMessageStatusesPkColumnsInput = {
  status: Scalars['String'];
};

/** select columns of table "whatsapp_message_statuses" */
export enum WhatsappMessageStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "whatsapp_message_statuses" */
export type WhatsappMessageStatusesSetInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_message_statuses" */
export type WhatsappMessageStatusesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappMessageStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappMessageStatusesStreamCursorValueInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_message_statuses" */
export enum WhatsappMessageStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export type WhatsappMessageStatusesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappMessageStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappMessageStatusesBoolExp;
};

/** columns and relationships of "whatsapp_message_template_statuses" */
export type WhatsappMessageTemplateStatuses = {
  __typename?: 'whatsapp_message_template_statuses';
  status: Scalars['String'];
};

/** aggregated selection of "whatsapp_message_template_statuses" */
export type WhatsappMessageTemplateStatusesAggregate = {
  __typename?: 'whatsapp_message_template_statuses_aggregate';
  aggregate?: Maybe<WhatsappMessageTemplateStatusesAggregateFields>;
  nodes: Array<WhatsappMessageTemplateStatuses>;
};

/** aggregate fields of "whatsapp_message_template_statuses" */
export type WhatsappMessageTemplateStatusesAggregateFields = {
  __typename?: 'whatsapp_message_template_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappMessageTemplateStatusesMaxFields>;
  min?: Maybe<WhatsappMessageTemplateStatusesMinFields>;
};


/** aggregate fields of "whatsapp_message_template_statuses" */
export type WhatsappMessageTemplateStatusesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappMessageTemplateStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "whatsapp_message_template_statuses". All fields are combined with a logical 'AND'. */
export type WhatsappMessageTemplateStatusesBoolExp = {
  _and?: InputMaybe<Array<WhatsappMessageTemplateStatusesBoolExp>>;
  _not?: InputMaybe<WhatsappMessageTemplateStatusesBoolExp>;
  _or?: InputMaybe<Array<WhatsappMessageTemplateStatusesBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_message_template_statuses" */
export enum WhatsappMessageTemplateStatusesConstraint {
  /** unique or primary key constraint on columns "status" */
  ChatMessageTemplateApprovalStatusesPkey = 'chat_message_template_approval_statuses_pkey'
}

export enum WhatsappMessageTemplateStatusesEnum {
  Approved = 'APPROVED',
  Created = 'CREATED',
  Deleted = 'DELETED',
  InAppeal = 'IN_APPEAL',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

/** Boolean expression to compare columns of type "whatsapp_message_template_statuses_enum". All fields are combined with logical 'AND'. */
export type WhatsappMessageTemplateStatusesEnumComparisonExp = {
  _eq?: InputMaybe<WhatsappMessageTemplateStatusesEnum>;
  _in?: InputMaybe<Array<WhatsappMessageTemplateStatusesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WhatsappMessageTemplateStatusesEnum>;
  _nin?: InputMaybe<Array<WhatsappMessageTemplateStatusesEnum>>;
};

/** input type for inserting data into table "whatsapp_message_template_statuses" */
export type WhatsappMessageTemplateStatusesInsertInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhatsappMessageTemplateStatusesMaxFields = {
  __typename?: 'whatsapp_message_template_statuses_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhatsappMessageTemplateStatusesMinFields = {
  __typename?: 'whatsapp_message_template_statuses_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "whatsapp_message_template_statuses" */
export type WhatsappMessageTemplateStatusesMutationResponse = {
  __typename?: 'whatsapp_message_template_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappMessageTemplateStatuses>;
};

/** on_conflict condition type for table "whatsapp_message_template_statuses" */
export type WhatsappMessageTemplateStatusesOnConflict = {
  constraint: WhatsappMessageTemplateStatusesConstraint;
  update_columns?: Array<WhatsappMessageTemplateStatusesUpdateColumn>;
  where?: InputMaybe<WhatsappMessageTemplateStatusesBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_message_template_statuses". */
export type WhatsappMessageTemplateStatusesOrderBy = {
  status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_message_template_statuses */
export type WhatsappMessageTemplateStatusesPkColumnsInput = {
  status: Scalars['String'];
};

/** select columns of table "whatsapp_message_template_statuses" */
export enum WhatsappMessageTemplateStatusesSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "whatsapp_message_template_statuses" */
export type WhatsappMessageTemplateStatusesSetInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_message_template_statuses" */
export type WhatsappMessageTemplateStatusesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappMessageTemplateStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappMessageTemplateStatusesStreamCursorValueInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_message_template_statuses" */
export enum WhatsappMessageTemplateStatusesUpdateColumn {
  /** column name */
  Status = 'status'
}

export type WhatsappMessageTemplateStatusesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappMessageTemplateStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappMessageTemplateStatusesBoolExp;
};

/** columns and relationships of "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValues = {
  __typename?: 'whatsapp_message_template_values';
  id: Scalars['uuid'];
  index: Scalars['Int'];
  value: Scalars['String'];
  whatsappMessageId: Scalars['uuid'];
};

/** aggregated selection of "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesAggregate = {
  __typename?: 'whatsapp_message_template_values_aggregate';
  aggregate?: Maybe<WhatsappMessageTemplateValuesAggregateFields>;
  nodes: Array<WhatsappMessageTemplateValues>;
};

export type WhatsappMessageTemplateValuesAggregateBoolExp = {
  count?: InputMaybe<WhatsappMessageTemplateValuesAggregateBoolExpCount>;
};

export type WhatsappMessageTemplateValuesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WhatsappMessageTemplateValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesAggregateFields = {
  __typename?: 'whatsapp_message_template_values_aggregate_fields';
  avg?: Maybe<WhatsappMessageTemplateValuesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<WhatsappMessageTemplateValuesMaxFields>;
  min?: Maybe<WhatsappMessageTemplateValuesMinFields>;
  stddev?: Maybe<WhatsappMessageTemplateValuesStddevFields>;
  stddev_pop?: Maybe<WhatsappMessageTemplateValuesStddevPopFields>;
  stddev_samp?: Maybe<WhatsappMessageTemplateValuesStddevSampFields>;
  sum?: Maybe<WhatsappMessageTemplateValuesSumFields>;
  var_pop?: Maybe<WhatsappMessageTemplateValuesVarPopFields>;
  var_samp?: Maybe<WhatsappMessageTemplateValuesVarSampFields>;
  variance?: Maybe<WhatsappMessageTemplateValuesVarianceFields>;
};


/** aggregate fields of "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappMessageTemplateValuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesAggregateOrderBy = {
  avg?: InputMaybe<WhatsappMessageTemplateValuesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WhatsappMessageTemplateValuesMaxOrderBy>;
  min?: InputMaybe<WhatsappMessageTemplateValuesMinOrderBy>;
  stddev?: InputMaybe<WhatsappMessageTemplateValuesStddevOrderBy>;
  stddev_pop?: InputMaybe<WhatsappMessageTemplateValuesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<WhatsappMessageTemplateValuesStddevSampOrderBy>;
  sum?: InputMaybe<WhatsappMessageTemplateValuesSumOrderBy>;
  var_pop?: InputMaybe<WhatsappMessageTemplateValuesVarPopOrderBy>;
  var_samp?: InputMaybe<WhatsappMessageTemplateValuesVarSampOrderBy>;
  variance?: InputMaybe<WhatsappMessageTemplateValuesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesArrRelInsertInput = {
  data: Array<WhatsappMessageTemplateValuesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<WhatsappMessageTemplateValuesOnConflict>;
};

/** aggregate avg on columns */
export type WhatsappMessageTemplateValuesAvgFields = {
  __typename?: 'whatsapp_message_template_values_avg_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "whatsapp_message_template_values". All fields are combined with a logical 'AND'. */
export type WhatsappMessageTemplateValuesBoolExp = {
  _and?: InputMaybe<Array<WhatsappMessageTemplateValuesBoolExp>>;
  _not?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
  _or?: InputMaybe<Array<WhatsappMessageTemplateValuesBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
  whatsappMessageId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_message_template_values" */
export enum WhatsappMessageTemplateValuesConstraint {
  /** unique or primary key constraint on columns "index", "whatsapp_message_id" */
  WhatsappMessageTemplateValuesIndexWhatsappMessageIdKey = 'whatsapp_message_template_values_index_whatsapp_message_id_key',
  /** unique or primary key constraint on columns "id" */
  WhatsappMessageTemplateValuesPkey = 'whatsapp_message_template_values_pkey'
}

/** input type for incrementing numeric columns in table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesIncInput = {
  index?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
  whatsappMessageId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type WhatsappMessageTemplateValuesMaxFields = {
  __typename?: 'whatsapp_message_template_values_max_fields';
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  whatsappMessageId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  whatsappMessageId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WhatsappMessageTemplateValuesMinFields = {
  __typename?: 'whatsapp_message_template_values_min_fields';
  id?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  whatsappMessageId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  whatsappMessageId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesMutationResponse = {
  __typename?: 'whatsapp_message_template_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappMessageTemplateValues>;
};

/** on_conflict condition type for table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesOnConflict = {
  constraint: WhatsappMessageTemplateValuesConstraint;
  update_columns?: Array<WhatsappMessageTemplateValuesUpdateColumn>;
  where?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_message_template_values". */
export type WhatsappMessageTemplateValuesOrderBy = {
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  whatsappMessageId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_message_template_values */
export type WhatsappMessageTemplateValuesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "whatsapp_message_template_values" */
export enum WhatsappMessageTemplateValuesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Value = 'value',
  /** column name */
  WhatsappMessageId = 'whatsappMessageId'
}

/** input type for updating data in table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
  whatsappMessageId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type WhatsappMessageTemplateValuesStddevFields = {
  __typename?: 'whatsapp_message_template_values_stddev_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type WhatsappMessageTemplateValuesStddevPopFields = {
  __typename?: 'whatsapp_message_template_values_stddev_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type WhatsappMessageTemplateValuesStddevSampFields = {
  __typename?: 'whatsapp_message_template_values_stddev_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappMessageTemplateValuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappMessageTemplateValuesStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']>;
  index?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
  whatsappMessageId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type WhatsappMessageTemplateValuesSumFields = {
  __typename?: 'whatsapp_message_template_values_sum_fields';
  index?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesSumOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** update columns of table "whatsapp_message_template_values" */
export enum WhatsappMessageTemplateValuesUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Value = 'value',
  /** column name */
  WhatsappMessageId = 'whatsappMessageId'
}

export type WhatsappMessageTemplateValuesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WhatsappMessageTemplateValuesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappMessageTemplateValuesSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappMessageTemplateValuesBoolExp;
};

/** aggregate var_pop on columns */
export type WhatsappMessageTemplateValuesVarPopFields = {
  __typename?: 'whatsapp_message_template_values_var_pop_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type WhatsappMessageTemplateValuesVarSampFields = {
  __typename?: 'whatsapp_message_template_values_var_samp_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WhatsappMessageTemplateValuesVarianceFields = {
  __typename?: 'whatsapp_message_template_values_variance_fields';
  index?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "whatsapp_message_template_values" */
export type WhatsappMessageTemplateValuesVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** columns and relationships of "whatsapp_message_templates" */
export type WhatsappMessageTemplates = {
  __typename?: 'whatsapp_message_templates';
  /** An object relationship */
  chatMessageTemplate: ChatMessageTemplates;
  chatMessageTemplateId: Scalars['uuid'];
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  status: WhatsappMessageTemplateStatusesEnum;
  /** An object relationship */
  whatsappBusinessAccount: WhatsappBusinessAccounts;
  whatsappBusinessAccountId: Scalars['uuid'];
};

/** aggregated selection of "whatsapp_message_templates" */
export type WhatsappMessageTemplatesAggregate = {
  __typename?: 'whatsapp_message_templates_aggregate';
  aggregate?: Maybe<WhatsappMessageTemplatesAggregateFields>;
  nodes: Array<WhatsappMessageTemplates>;
};

export type WhatsappMessageTemplatesAggregateBoolExp = {
  count?: InputMaybe<WhatsappMessageTemplatesAggregateBoolExpCount>;
};

export type WhatsappMessageTemplatesAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "whatsapp_message_templates" */
export type WhatsappMessageTemplatesAggregateFields = {
  __typename?: 'whatsapp_message_templates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappMessageTemplatesMaxFields>;
  min?: Maybe<WhatsappMessageTemplatesMinFields>;
};


/** aggregate fields of "whatsapp_message_templates" */
export type WhatsappMessageTemplatesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappMessageTemplatesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WhatsappMessageTemplatesMaxOrderBy>;
  min?: InputMaybe<WhatsappMessageTemplatesMinOrderBy>;
};

/** input type for inserting array relation for remote table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesArrRelInsertInput = {
  data: Array<WhatsappMessageTemplatesInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<WhatsappMessageTemplatesOnConflict>;
};

/** Boolean expression to filter rows from the table "whatsapp_message_templates". All fields are combined with a logical 'AND'. */
export type WhatsappMessageTemplatesBoolExp = {
  _and?: InputMaybe<Array<WhatsappMessageTemplatesBoolExp>>;
  _not?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
  _or?: InputMaybe<Array<WhatsappMessageTemplatesBoolExp>>;
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesBoolExp>;
  chatMessageTemplateId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<WhatsappMessageTemplateStatusesEnumComparisonExp>;
  whatsappBusinessAccount?: InputMaybe<WhatsappBusinessAccountsBoolExp>;
  whatsappBusinessAccountId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_message_templates" */
export enum WhatsappMessageTemplatesConstraint {
  /** unique or primary key constraint on columns "chat_message_template_id", "whatsapp_business_account_id" */
  WhatsappMessageTemplatesBusinessAccountIdChatTemplatKey = 'whatsapp_message_templates_business_account_id_chat_templat_key',
  /** unique or primary key constraint on columns "id", "whatsapp_business_account_id" */
  WhatsappMessageTemplatesIdWhatsappBusinessAccountIdKey = 'whatsapp_message_templates_id_whatsapp_business_account_id_key',
  /** unique or primary key constraint on columns "id" */
  WhatsappMessageTemplatesPkey = 'whatsapp_message_templates_pkey',
  /** unique or primary key constraint on columns "whatsapp_business_account_id", "name" */
  WhatsappMessageTemplatesWhatsappBusinessAccountIdNamKey = 'whatsapp_message_templates_whatsapp_business_account_id_nam_key',
  /** unique or primary key constraint on columns "whatsapp_business_account_id", "name" */
  WhatsappMessageTemplatesWhatsappBusinessAccountNameKey = 'whatsapp_message_templates_whatsapp_business_account_name_key'
}

/** input type for inserting data into table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesInsertInput = {
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesObjRelInsertInput>;
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WhatsappMessageTemplateStatusesEnum>;
  whatsappBusinessAccount?: InputMaybe<WhatsappBusinessAccountsObjRelInsertInput>;
  whatsappBusinessAccountId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type WhatsappMessageTemplatesMaxFields = {
  __typename?: 'whatsapp_message_templates_max_fields';
  chatMessageTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  whatsappBusinessAccountId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesMaxOrderBy = {
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  whatsappBusinessAccountId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WhatsappMessageTemplatesMinFields = {
  __typename?: 'whatsapp_message_templates_min_fields';
  chatMessageTemplateId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  whatsappBusinessAccountId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesMinOrderBy = {
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  whatsappBusinessAccountId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesMutationResponse = {
  __typename?: 'whatsapp_message_templates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappMessageTemplates>;
};

/** input type for inserting object relation for remote table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesObjRelInsertInput = {
  data: WhatsappMessageTemplatesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<WhatsappMessageTemplatesOnConflict>;
};

/** on_conflict condition type for table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesOnConflict = {
  constraint: WhatsappMessageTemplatesConstraint;
  update_columns?: Array<WhatsappMessageTemplatesUpdateColumn>;
  where?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_message_templates". */
export type WhatsappMessageTemplatesOrderBy = {
  chatMessageTemplate?: InputMaybe<ChatMessageTemplatesOrderBy>;
  chatMessageTemplateId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  whatsappBusinessAccount?: InputMaybe<WhatsappBusinessAccountsOrderBy>;
  whatsappBusinessAccountId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_message_templates */
export type WhatsappMessageTemplatesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "whatsapp_message_templates" */
export enum WhatsappMessageTemplatesSelectColumn {
  /** column name */
  ChatMessageTemplateId = 'chatMessageTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  WhatsappBusinessAccountId = 'whatsappBusinessAccountId'
}

/** input type for updating data in table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesSetInput = {
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WhatsappMessageTemplateStatusesEnum>;
  whatsappBusinessAccountId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "whatsapp_message_templates" */
export type WhatsappMessageTemplatesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappMessageTemplatesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappMessageTemplatesStreamCursorValueInput = {
  chatMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WhatsappMessageTemplateStatusesEnum>;
  whatsappBusinessAccountId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "whatsapp_message_templates" */
export enum WhatsappMessageTemplatesUpdateColumn {
  /** column name */
  ChatMessageTemplateId = 'chatMessageTemplateId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  WhatsappBusinessAccountId = 'whatsappBusinessAccountId'
}

export type WhatsappMessageTemplatesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappMessageTemplatesSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappMessageTemplatesBoolExp;
};

/** columns and relationships of "whatsapp_message_types" */
export type WhatsappMessageTypes = {
  __typename?: 'whatsapp_message_types';
  type: Scalars['String'];
};

/** aggregated selection of "whatsapp_message_types" */
export type WhatsappMessageTypesAggregate = {
  __typename?: 'whatsapp_message_types_aggregate';
  aggregate?: Maybe<WhatsappMessageTypesAggregateFields>;
  nodes: Array<WhatsappMessageTypes>;
};

/** aggregate fields of "whatsapp_message_types" */
export type WhatsappMessageTypesAggregateFields = {
  __typename?: 'whatsapp_message_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WhatsappMessageTypesMaxFields>;
  min?: Maybe<WhatsappMessageTypesMinFields>;
};


/** aggregate fields of "whatsapp_message_types" */
export type WhatsappMessageTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappMessageTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "whatsapp_message_types". All fields are combined with a logical 'AND'. */
export type WhatsappMessageTypesBoolExp = {
  _and?: InputMaybe<Array<WhatsappMessageTypesBoolExp>>;
  _not?: InputMaybe<WhatsappMessageTypesBoolExp>;
  _or?: InputMaybe<Array<WhatsappMessageTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "whatsapp_message_types" */
export enum WhatsappMessageTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  WhatsappMessageTypesPkey = 'whatsapp_message_types_pkey'
}

export enum WhatsappMessageTypesEnum {
  Audio = 'AUDIO',
  Contacts = 'CONTACTS',
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Location = 'LOCATION',
  Reaction = 'REACTION',
  Sticker = 'STICKER',
  Template = 'TEMPLATE',
  Text = 'TEXT',
  Unknown = 'UNKNOWN',
  Video = 'VIDEO',
  Voice = 'VOICE'
}

/** Boolean expression to compare columns of type "whatsapp_message_types_enum". All fields are combined with logical 'AND'. */
export type WhatsappMessageTypesEnumComparisonExp = {
  _eq?: InputMaybe<WhatsappMessageTypesEnum>;
  _in?: InputMaybe<Array<WhatsappMessageTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WhatsappMessageTypesEnum>;
  _nin?: InputMaybe<Array<WhatsappMessageTypesEnum>>;
};

/** input type for inserting data into table "whatsapp_message_types" */
export type WhatsappMessageTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WhatsappMessageTypesMaxFields = {
  __typename?: 'whatsapp_message_types_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WhatsappMessageTypesMinFields = {
  __typename?: 'whatsapp_message_types_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "whatsapp_message_types" */
export type WhatsappMessageTypesMutationResponse = {
  __typename?: 'whatsapp_message_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappMessageTypes>;
};

/** on_conflict condition type for table "whatsapp_message_types" */
export type WhatsappMessageTypesOnConflict = {
  constraint: WhatsappMessageTypesConstraint;
  update_columns?: Array<WhatsappMessageTypesUpdateColumn>;
  where?: InputMaybe<WhatsappMessageTypesBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_message_types". */
export type WhatsappMessageTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: whatsapp_message_types */
export type WhatsappMessageTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "whatsapp_message_types" */
export enum WhatsappMessageTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "whatsapp_message_types" */
export type WhatsappMessageTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "whatsapp_message_types" */
export type WhatsappMessageTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappMessageTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappMessageTypesStreamCursorValueInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "whatsapp_message_types" */
export enum WhatsappMessageTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

export type WhatsappMessageTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappMessageTypesSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappMessageTypesBoolExp;
};

/** columns and relationships of "whatsapp_messages" */
export type WhatsappMessages = {
  __typename?: 'whatsapp_messages';
  button0Parameter?: Maybe<Scalars['String']>;
  /** An array relationship */
  chatMessageWhatsappMessages: Array<ChatMessageWhatsappMessages>;
  /** An aggregate relationship */
  chatMessageWhatsappMessages_aggregate: ChatMessageWhatsappMessagesAggregate;
  customerIdentifier: Scalars['String'];
  customerName?: Maybe<Scalars['String']>;
  customerPhoneNumber: Scalars['String'];
  direction: WhatsappMessageDirectionsEnum;
  id: Scalars['uuid'];
  lastSendAttemptErrorCode?: Maybe<Scalars['String']>;
  lastSendAttemptErrorDetails?: Maybe<Scalars['String']>;
  lastSendAttemptErrorResponse?: Maybe<Scalars['jsonb']>;
  lastSendAttemptErrorSource?: Maybe<Scalars['String']>;
  lastSendAttemptTimestamp?: Maybe<Scalars['timestamptz']>;
  lastStatusUpdateTimestamp?: Maybe<Scalars['timestamptz']>;
  locationAddress?: Maybe<Scalars['String']>;
  locationLatitude?: Maybe<Scalars['numeric']>;
  locationLongitude?: Maybe<Scalars['numeric']>;
  locationName?: Maybe<Scalars['String']>;
  mediaCaption?: Maybe<Scalars['String']>;
  mediaFilename?: Maybe<Scalars['String']>;
  mediaIdentifier?: Maybe<Scalars['String']>;
  mediaLink?: Maybe<Scalars['String']>;
  mediaMimeType?: Maybe<Scalars['String']>;
  merchantPhoneNumber: Scalars['String'];
  messageIdentifier?: Maybe<Scalars['String']>;
  reactedWhatsappMessageIdentifier?: Maybe<Scalars['String']>;
  reactionEmoji?: Maybe<Scalars['String']>;
  receivingErrorCode?: Maybe<Scalars['Int']>;
  receivingErrorTitle?: Maybe<Scalars['String']>;
  repliedWhatsappMessageIdentifier?: Maybe<Scalars['String']>;
  status: WhatsappMessageStatusesEnum;
  templateHeaderMediaKey?: Maybe<Scalars['String']>;
  templateHeaderMediaMimeType?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  timestamp: Scalars['timestamptz'];
  type: WhatsappMessageTypesEnum;
  whatsappConversationId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  whatsappMessageTemplate?: Maybe<WhatsappMessageTemplates>;
  whatsappMessageTemplateId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  whatsappMessageTemplateValues: Array<WhatsappMessageTemplateValues>;
  /** An aggregate relationship */
  whatsappMessageTemplateValues_aggregate: WhatsappMessageTemplateValuesAggregate;
};


/** columns and relationships of "whatsapp_messages" */
export type WhatsappMessagesChatMessageWhatsappMessagesArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageWhatsappMessagesOrderBy>>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};


/** columns and relationships of "whatsapp_messages" */
export type WhatsappMessagesChatMessageWhatsappMessagesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChatMessageWhatsappMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ChatMessageWhatsappMessagesOrderBy>>;
  where?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
};


/** columns and relationships of "whatsapp_messages" */
export type WhatsappMessagesLastSendAttemptErrorResponseArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "whatsapp_messages" */
export type WhatsappMessagesWhatsappMessageTemplateValuesArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
};


/** columns and relationships of "whatsapp_messages" */
export type WhatsappMessagesWhatsappMessageTemplateValuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<WhatsappMessageTemplateValuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<WhatsappMessageTemplateValuesOrderBy>>;
  where?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
};

/** aggregated selection of "whatsapp_messages" */
export type WhatsappMessagesAggregate = {
  __typename?: 'whatsapp_messages_aggregate';
  aggregate?: Maybe<WhatsappMessagesAggregateFields>;
  nodes: Array<WhatsappMessages>;
};

/** aggregate fields of "whatsapp_messages" */
export type WhatsappMessagesAggregateFields = {
  __typename?: 'whatsapp_messages_aggregate_fields';
  avg?: Maybe<WhatsappMessagesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<WhatsappMessagesMaxFields>;
  min?: Maybe<WhatsappMessagesMinFields>;
  stddev?: Maybe<WhatsappMessagesStddevFields>;
  stddev_pop?: Maybe<WhatsappMessagesStddevPopFields>;
  stddev_samp?: Maybe<WhatsappMessagesStddevSampFields>;
  sum?: Maybe<WhatsappMessagesSumFields>;
  var_pop?: Maybe<WhatsappMessagesVarPopFields>;
  var_samp?: Maybe<WhatsappMessagesVarSampFields>;
  variance?: Maybe<WhatsappMessagesVarianceFields>;
};


/** aggregate fields of "whatsapp_messages" */
export type WhatsappMessagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WhatsappMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WhatsappMessagesAppendInput = {
  lastSendAttemptErrorResponse?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type WhatsappMessagesAvgFields = {
  __typename?: 'whatsapp_messages_avg_fields';
  locationLatitude?: Maybe<Scalars['Float']>;
  locationLongitude?: Maybe<Scalars['Float']>;
  receivingErrorCode?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "whatsapp_messages". All fields are combined with a logical 'AND'. */
export type WhatsappMessagesBoolExp = {
  _and?: InputMaybe<Array<WhatsappMessagesBoolExp>>;
  _not?: InputMaybe<WhatsappMessagesBoolExp>;
  _or?: InputMaybe<Array<WhatsappMessagesBoolExp>>;
  button0Parameter?: InputMaybe<StringComparisonExp>;
  chatMessageWhatsappMessages?: InputMaybe<ChatMessageWhatsappMessagesBoolExp>;
  chatMessageWhatsappMessages_aggregate?: InputMaybe<ChatMessageWhatsappMessagesAggregateBoolExp>;
  customerIdentifier?: InputMaybe<StringComparisonExp>;
  customerName?: InputMaybe<StringComparisonExp>;
  customerPhoneNumber?: InputMaybe<StringComparisonExp>;
  direction?: InputMaybe<WhatsappMessageDirectionsEnumComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  lastSendAttemptErrorCode?: InputMaybe<StringComparisonExp>;
  lastSendAttemptErrorDetails?: InputMaybe<StringComparisonExp>;
  lastSendAttemptErrorResponse?: InputMaybe<JsonbComparisonExp>;
  lastSendAttemptErrorSource?: InputMaybe<StringComparisonExp>;
  lastSendAttemptTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  lastStatusUpdateTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  locationAddress?: InputMaybe<StringComparisonExp>;
  locationLatitude?: InputMaybe<NumericComparisonExp>;
  locationLongitude?: InputMaybe<NumericComparisonExp>;
  locationName?: InputMaybe<StringComparisonExp>;
  mediaCaption?: InputMaybe<StringComparisonExp>;
  mediaFilename?: InputMaybe<StringComparisonExp>;
  mediaIdentifier?: InputMaybe<StringComparisonExp>;
  mediaLink?: InputMaybe<StringComparisonExp>;
  mediaMimeType?: InputMaybe<StringComparisonExp>;
  merchantPhoneNumber?: InputMaybe<StringComparisonExp>;
  messageIdentifier?: InputMaybe<StringComparisonExp>;
  reactedWhatsappMessageIdentifier?: InputMaybe<StringComparisonExp>;
  reactionEmoji?: InputMaybe<StringComparisonExp>;
  receivingErrorCode?: InputMaybe<IntComparisonExp>;
  receivingErrorTitle?: InputMaybe<StringComparisonExp>;
  repliedWhatsappMessageIdentifier?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<WhatsappMessageStatusesEnumComparisonExp>;
  templateHeaderMediaKey?: InputMaybe<StringComparisonExp>;
  templateHeaderMediaMimeType?: InputMaybe<StringComparisonExp>;
  templateName?: InputMaybe<StringComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  timestamp?: InputMaybe<TimestamptzComparisonExp>;
  type?: InputMaybe<WhatsappMessageTypesEnumComparisonExp>;
  whatsappConversationId?: InputMaybe<UuidComparisonExp>;
  whatsappMessageTemplate?: InputMaybe<WhatsappMessageTemplatesBoolExp>;
  whatsappMessageTemplateId?: InputMaybe<UuidComparisonExp>;
  whatsappMessageTemplateValues?: InputMaybe<WhatsappMessageTemplateValuesBoolExp>;
  whatsappMessageTemplateValues_aggregate?: InputMaybe<WhatsappMessageTemplateValuesAggregateBoolExp>;
};

/** unique or primary key constraints on table "whatsapp_messages" */
export enum WhatsappMessagesConstraint {
  /** unique or primary key constraint on columns "message_identifier" */
  WhatsappMessagesMessageIdentifierKey = 'whatsapp_messages_message_identifier_key',
  /** unique or primary key constraint on columns "id" */
  WhatsappMessagesPkey = 'whatsapp_messages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WhatsappMessagesDeleteAtPathInput = {
  lastSendAttemptErrorResponse?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WhatsappMessagesDeleteElemInput = {
  lastSendAttemptErrorResponse?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WhatsappMessagesDeleteKeyInput = {
  lastSendAttemptErrorResponse?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "whatsapp_messages" */
export type WhatsappMessagesIncInput = {
  locationLatitude?: InputMaybe<Scalars['numeric']>;
  locationLongitude?: InputMaybe<Scalars['numeric']>;
  receivingErrorCode?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "whatsapp_messages" */
export type WhatsappMessagesInsertInput = {
  button0Parameter?: InputMaybe<Scalars['String']>;
  chatMessageWhatsappMessages?: InputMaybe<ChatMessageWhatsappMessagesArrRelInsertInput>;
  customerIdentifier?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  customerPhoneNumber?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<WhatsappMessageDirectionsEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  lastSendAttemptErrorCode?: InputMaybe<Scalars['String']>;
  lastSendAttemptErrorDetails?: InputMaybe<Scalars['String']>;
  lastSendAttemptErrorResponse?: InputMaybe<Scalars['jsonb']>;
  lastSendAttemptErrorSource?: InputMaybe<Scalars['String']>;
  lastSendAttemptTimestamp?: InputMaybe<Scalars['timestamptz']>;
  lastStatusUpdateTimestamp?: InputMaybe<Scalars['timestamptz']>;
  locationAddress?: InputMaybe<Scalars['String']>;
  locationLatitude?: InputMaybe<Scalars['numeric']>;
  locationLongitude?: InputMaybe<Scalars['numeric']>;
  locationName?: InputMaybe<Scalars['String']>;
  mediaCaption?: InputMaybe<Scalars['String']>;
  mediaFilename?: InputMaybe<Scalars['String']>;
  mediaIdentifier?: InputMaybe<Scalars['String']>;
  mediaLink?: InputMaybe<Scalars['String']>;
  mediaMimeType?: InputMaybe<Scalars['String']>;
  merchantPhoneNumber?: InputMaybe<Scalars['String']>;
  messageIdentifier?: InputMaybe<Scalars['String']>;
  reactedWhatsappMessageIdentifier?: InputMaybe<Scalars['String']>;
  reactionEmoji?: InputMaybe<Scalars['String']>;
  receivingErrorCode?: InputMaybe<Scalars['Int']>;
  receivingErrorTitle?: InputMaybe<Scalars['String']>;
  repliedWhatsappMessageIdentifier?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WhatsappMessageStatusesEnum>;
  templateHeaderMediaKey?: InputMaybe<Scalars['String']>;
  templateHeaderMediaMimeType?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<WhatsappMessageTypesEnum>;
  whatsappConversationId?: InputMaybe<Scalars['uuid']>;
  whatsappMessageTemplate?: InputMaybe<WhatsappMessageTemplatesObjRelInsertInput>;
  whatsappMessageTemplateId?: InputMaybe<Scalars['uuid']>;
  whatsappMessageTemplateValues?: InputMaybe<WhatsappMessageTemplateValuesArrRelInsertInput>;
};

/** aggregate max on columns */
export type WhatsappMessagesMaxFields = {
  __typename?: 'whatsapp_messages_max_fields';
  button0Parameter?: Maybe<Scalars['String']>;
  customerIdentifier?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerPhoneNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastSendAttemptErrorCode?: Maybe<Scalars['String']>;
  lastSendAttemptErrorDetails?: Maybe<Scalars['String']>;
  lastSendAttemptErrorSource?: Maybe<Scalars['String']>;
  lastSendAttemptTimestamp?: Maybe<Scalars['timestamptz']>;
  lastStatusUpdateTimestamp?: Maybe<Scalars['timestamptz']>;
  locationAddress?: Maybe<Scalars['String']>;
  locationLatitude?: Maybe<Scalars['numeric']>;
  locationLongitude?: Maybe<Scalars['numeric']>;
  locationName?: Maybe<Scalars['String']>;
  mediaCaption?: Maybe<Scalars['String']>;
  mediaFilename?: Maybe<Scalars['String']>;
  mediaIdentifier?: Maybe<Scalars['String']>;
  mediaLink?: Maybe<Scalars['String']>;
  mediaMimeType?: Maybe<Scalars['String']>;
  merchantPhoneNumber?: Maybe<Scalars['String']>;
  messageIdentifier?: Maybe<Scalars['String']>;
  reactedWhatsappMessageIdentifier?: Maybe<Scalars['String']>;
  reactionEmoji?: Maybe<Scalars['String']>;
  receivingErrorCode?: Maybe<Scalars['Int']>;
  receivingErrorTitle?: Maybe<Scalars['String']>;
  repliedWhatsappMessageIdentifier?: Maybe<Scalars['String']>;
  templateHeaderMediaKey?: Maybe<Scalars['String']>;
  templateHeaderMediaMimeType?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  whatsappConversationId?: Maybe<Scalars['uuid']>;
  whatsappMessageTemplateId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type WhatsappMessagesMinFields = {
  __typename?: 'whatsapp_messages_min_fields';
  button0Parameter?: Maybe<Scalars['String']>;
  customerIdentifier?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerPhoneNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastSendAttemptErrorCode?: Maybe<Scalars['String']>;
  lastSendAttemptErrorDetails?: Maybe<Scalars['String']>;
  lastSendAttemptErrorSource?: Maybe<Scalars['String']>;
  lastSendAttemptTimestamp?: Maybe<Scalars['timestamptz']>;
  lastStatusUpdateTimestamp?: Maybe<Scalars['timestamptz']>;
  locationAddress?: Maybe<Scalars['String']>;
  locationLatitude?: Maybe<Scalars['numeric']>;
  locationLongitude?: Maybe<Scalars['numeric']>;
  locationName?: Maybe<Scalars['String']>;
  mediaCaption?: Maybe<Scalars['String']>;
  mediaFilename?: Maybe<Scalars['String']>;
  mediaIdentifier?: Maybe<Scalars['String']>;
  mediaLink?: Maybe<Scalars['String']>;
  mediaMimeType?: Maybe<Scalars['String']>;
  merchantPhoneNumber?: Maybe<Scalars['String']>;
  messageIdentifier?: Maybe<Scalars['String']>;
  reactedWhatsappMessageIdentifier?: Maybe<Scalars['String']>;
  reactionEmoji?: Maybe<Scalars['String']>;
  receivingErrorCode?: Maybe<Scalars['Int']>;
  receivingErrorTitle?: Maybe<Scalars['String']>;
  repliedWhatsappMessageIdentifier?: Maybe<Scalars['String']>;
  templateHeaderMediaKey?: Maybe<Scalars['String']>;
  templateHeaderMediaMimeType?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  whatsappConversationId?: Maybe<Scalars['uuid']>;
  whatsappMessageTemplateId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "whatsapp_messages" */
export type WhatsappMessagesMutationResponse = {
  __typename?: 'whatsapp_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WhatsappMessages>;
};

/** input type for inserting object relation for remote table "whatsapp_messages" */
export type WhatsappMessagesObjRelInsertInput = {
  data: WhatsappMessagesInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<WhatsappMessagesOnConflict>;
};

/** on_conflict condition type for table "whatsapp_messages" */
export type WhatsappMessagesOnConflict = {
  constraint: WhatsappMessagesConstraint;
  update_columns?: Array<WhatsappMessagesUpdateColumn>;
  where?: InputMaybe<WhatsappMessagesBoolExp>;
};

/** Ordering options when selecting data from "whatsapp_messages". */
export type WhatsappMessagesOrderBy = {
  button0Parameter?: InputMaybe<OrderBy>;
  chatMessageWhatsappMessages_aggregate?: InputMaybe<ChatMessageWhatsappMessagesAggregateOrderBy>;
  customerIdentifier?: InputMaybe<OrderBy>;
  customerName?: InputMaybe<OrderBy>;
  customerPhoneNumber?: InputMaybe<OrderBy>;
  direction?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastSendAttemptErrorCode?: InputMaybe<OrderBy>;
  lastSendAttemptErrorDetails?: InputMaybe<OrderBy>;
  lastSendAttemptErrorResponse?: InputMaybe<OrderBy>;
  lastSendAttemptErrorSource?: InputMaybe<OrderBy>;
  lastSendAttemptTimestamp?: InputMaybe<OrderBy>;
  lastStatusUpdateTimestamp?: InputMaybe<OrderBy>;
  locationAddress?: InputMaybe<OrderBy>;
  locationLatitude?: InputMaybe<OrderBy>;
  locationLongitude?: InputMaybe<OrderBy>;
  locationName?: InputMaybe<OrderBy>;
  mediaCaption?: InputMaybe<OrderBy>;
  mediaFilename?: InputMaybe<OrderBy>;
  mediaIdentifier?: InputMaybe<OrderBy>;
  mediaLink?: InputMaybe<OrderBy>;
  mediaMimeType?: InputMaybe<OrderBy>;
  merchantPhoneNumber?: InputMaybe<OrderBy>;
  messageIdentifier?: InputMaybe<OrderBy>;
  reactedWhatsappMessageIdentifier?: InputMaybe<OrderBy>;
  reactionEmoji?: InputMaybe<OrderBy>;
  receivingErrorCode?: InputMaybe<OrderBy>;
  receivingErrorTitle?: InputMaybe<OrderBy>;
  repliedWhatsappMessageIdentifier?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  templateHeaderMediaKey?: InputMaybe<OrderBy>;
  templateHeaderMediaMimeType?: InputMaybe<OrderBy>;
  templateName?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  timestamp?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  whatsappConversationId?: InputMaybe<OrderBy>;
  whatsappMessageTemplate?: InputMaybe<WhatsappMessageTemplatesOrderBy>;
  whatsappMessageTemplateId?: InputMaybe<OrderBy>;
  whatsappMessageTemplateValues_aggregate?: InputMaybe<WhatsappMessageTemplateValuesAggregateOrderBy>;
};

/** primary key columns input for table: whatsapp_messages */
export type WhatsappMessagesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WhatsappMessagesPrependInput = {
  lastSendAttemptErrorResponse?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "whatsapp_messages" */
export enum WhatsappMessagesSelectColumn {
  /** column name */
  Button0Parameter = 'button0Parameter',
  /** column name */
  CustomerIdentifier = 'customerIdentifier',
  /** column name */
  CustomerName = 'customerName',
  /** column name */
  CustomerPhoneNumber = 'customerPhoneNumber',
  /** column name */
  Direction = 'direction',
  /** column name */
  Id = 'id',
  /** column name */
  LastSendAttemptErrorCode = 'lastSendAttemptErrorCode',
  /** column name */
  LastSendAttemptErrorDetails = 'lastSendAttemptErrorDetails',
  /** column name */
  LastSendAttemptErrorResponse = 'lastSendAttemptErrorResponse',
  /** column name */
  LastSendAttemptErrorSource = 'lastSendAttemptErrorSource',
  /** column name */
  LastSendAttemptTimestamp = 'lastSendAttemptTimestamp',
  /** column name */
  LastStatusUpdateTimestamp = 'lastStatusUpdateTimestamp',
  /** column name */
  LocationAddress = 'locationAddress',
  /** column name */
  LocationLatitude = 'locationLatitude',
  /** column name */
  LocationLongitude = 'locationLongitude',
  /** column name */
  LocationName = 'locationName',
  /** column name */
  MediaCaption = 'mediaCaption',
  /** column name */
  MediaFilename = 'mediaFilename',
  /** column name */
  MediaIdentifier = 'mediaIdentifier',
  /** column name */
  MediaLink = 'mediaLink',
  /** column name */
  MediaMimeType = 'mediaMimeType',
  /** column name */
  MerchantPhoneNumber = 'merchantPhoneNumber',
  /** column name */
  MessageIdentifier = 'messageIdentifier',
  /** column name */
  ReactedWhatsappMessageIdentifier = 'reactedWhatsappMessageIdentifier',
  /** column name */
  ReactionEmoji = 'reactionEmoji',
  /** column name */
  ReceivingErrorCode = 'receivingErrorCode',
  /** column name */
  ReceivingErrorTitle = 'receivingErrorTitle',
  /** column name */
  RepliedWhatsappMessageIdentifier = 'repliedWhatsappMessageIdentifier',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateHeaderMediaKey = 'templateHeaderMediaKey',
  /** column name */
  TemplateHeaderMediaMimeType = 'templateHeaderMediaMimeType',
  /** column name */
  TemplateName = 'templateName',
  /** column name */
  Text = 'text',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  WhatsappConversationId = 'whatsappConversationId',
  /** column name */
  WhatsappMessageTemplateId = 'whatsappMessageTemplateId'
}

/** input type for updating data in table "whatsapp_messages" */
export type WhatsappMessagesSetInput = {
  button0Parameter?: InputMaybe<Scalars['String']>;
  customerIdentifier?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  customerPhoneNumber?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<WhatsappMessageDirectionsEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  lastSendAttemptErrorCode?: InputMaybe<Scalars['String']>;
  lastSendAttemptErrorDetails?: InputMaybe<Scalars['String']>;
  lastSendAttemptErrorResponse?: InputMaybe<Scalars['jsonb']>;
  lastSendAttemptErrorSource?: InputMaybe<Scalars['String']>;
  lastSendAttemptTimestamp?: InputMaybe<Scalars['timestamptz']>;
  lastStatusUpdateTimestamp?: InputMaybe<Scalars['timestamptz']>;
  locationAddress?: InputMaybe<Scalars['String']>;
  locationLatitude?: InputMaybe<Scalars['numeric']>;
  locationLongitude?: InputMaybe<Scalars['numeric']>;
  locationName?: InputMaybe<Scalars['String']>;
  mediaCaption?: InputMaybe<Scalars['String']>;
  mediaFilename?: InputMaybe<Scalars['String']>;
  mediaIdentifier?: InputMaybe<Scalars['String']>;
  mediaLink?: InputMaybe<Scalars['String']>;
  mediaMimeType?: InputMaybe<Scalars['String']>;
  merchantPhoneNumber?: InputMaybe<Scalars['String']>;
  messageIdentifier?: InputMaybe<Scalars['String']>;
  reactedWhatsappMessageIdentifier?: InputMaybe<Scalars['String']>;
  reactionEmoji?: InputMaybe<Scalars['String']>;
  receivingErrorCode?: InputMaybe<Scalars['Int']>;
  receivingErrorTitle?: InputMaybe<Scalars['String']>;
  repliedWhatsappMessageIdentifier?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WhatsappMessageStatusesEnum>;
  templateHeaderMediaKey?: InputMaybe<Scalars['String']>;
  templateHeaderMediaMimeType?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<WhatsappMessageTypesEnum>;
  whatsappConversationId?: InputMaybe<Scalars['uuid']>;
  whatsappMessageTemplateId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type WhatsappMessagesStddevFields = {
  __typename?: 'whatsapp_messages_stddev_fields';
  locationLatitude?: Maybe<Scalars['Float']>;
  locationLongitude?: Maybe<Scalars['Float']>;
  receivingErrorCode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type WhatsappMessagesStddevPopFields = {
  __typename?: 'whatsapp_messages_stddev_pop_fields';
  locationLatitude?: Maybe<Scalars['Float']>;
  locationLongitude?: Maybe<Scalars['Float']>;
  receivingErrorCode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type WhatsappMessagesStddevSampFields = {
  __typename?: 'whatsapp_messages_stddev_samp_fields';
  locationLatitude?: Maybe<Scalars['Float']>;
  locationLongitude?: Maybe<Scalars['Float']>;
  receivingErrorCode?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "whatsapp_messages" */
export type WhatsappMessagesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: WhatsappMessagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type WhatsappMessagesStreamCursorValueInput = {
  button0Parameter?: InputMaybe<Scalars['String']>;
  customerIdentifier?: InputMaybe<Scalars['String']>;
  customerName?: InputMaybe<Scalars['String']>;
  customerPhoneNumber?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<WhatsappMessageDirectionsEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  lastSendAttemptErrorCode?: InputMaybe<Scalars['String']>;
  lastSendAttemptErrorDetails?: InputMaybe<Scalars['String']>;
  lastSendAttemptErrorResponse?: InputMaybe<Scalars['jsonb']>;
  lastSendAttemptErrorSource?: InputMaybe<Scalars['String']>;
  lastSendAttemptTimestamp?: InputMaybe<Scalars['timestamptz']>;
  lastStatusUpdateTimestamp?: InputMaybe<Scalars['timestamptz']>;
  locationAddress?: InputMaybe<Scalars['String']>;
  locationLatitude?: InputMaybe<Scalars['numeric']>;
  locationLongitude?: InputMaybe<Scalars['numeric']>;
  locationName?: InputMaybe<Scalars['String']>;
  mediaCaption?: InputMaybe<Scalars['String']>;
  mediaFilename?: InputMaybe<Scalars['String']>;
  mediaIdentifier?: InputMaybe<Scalars['String']>;
  mediaLink?: InputMaybe<Scalars['String']>;
  mediaMimeType?: InputMaybe<Scalars['String']>;
  merchantPhoneNumber?: InputMaybe<Scalars['String']>;
  messageIdentifier?: InputMaybe<Scalars['String']>;
  reactedWhatsappMessageIdentifier?: InputMaybe<Scalars['String']>;
  reactionEmoji?: InputMaybe<Scalars['String']>;
  receivingErrorCode?: InputMaybe<Scalars['Int']>;
  receivingErrorTitle?: InputMaybe<Scalars['String']>;
  repliedWhatsappMessageIdentifier?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WhatsappMessageStatusesEnum>;
  templateHeaderMediaKey?: InputMaybe<Scalars['String']>;
  templateHeaderMediaMimeType?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<WhatsappMessageTypesEnum>;
  whatsappConversationId?: InputMaybe<Scalars['uuid']>;
  whatsappMessageTemplateId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type WhatsappMessagesSumFields = {
  __typename?: 'whatsapp_messages_sum_fields';
  locationLatitude?: Maybe<Scalars['numeric']>;
  locationLongitude?: Maybe<Scalars['numeric']>;
  receivingErrorCode?: Maybe<Scalars['Int']>;
};

/** update columns of table "whatsapp_messages" */
export enum WhatsappMessagesUpdateColumn {
  /** column name */
  Button0Parameter = 'button0Parameter',
  /** column name */
  CustomerIdentifier = 'customerIdentifier',
  /** column name */
  CustomerName = 'customerName',
  /** column name */
  CustomerPhoneNumber = 'customerPhoneNumber',
  /** column name */
  Direction = 'direction',
  /** column name */
  Id = 'id',
  /** column name */
  LastSendAttemptErrorCode = 'lastSendAttemptErrorCode',
  /** column name */
  LastSendAttemptErrorDetails = 'lastSendAttemptErrorDetails',
  /** column name */
  LastSendAttemptErrorResponse = 'lastSendAttemptErrorResponse',
  /** column name */
  LastSendAttemptErrorSource = 'lastSendAttemptErrorSource',
  /** column name */
  LastSendAttemptTimestamp = 'lastSendAttemptTimestamp',
  /** column name */
  LastStatusUpdateTimestamp = 'lastStatusUpdateTimestamp',
  /** column name */
  LocationAddress = 'locationAddress',
  /** column name */
  LocationLatitude = 'locationLatitude',
  /** column name */
  LocationLongitude = 'locationLongitude',
  /** column name */
  LocationName = 'locationName',
  /** column name */
  MediaCaption = 'mediaCaption',
  /** column name */
  MediaFilename = 'mediaFilename',
  /** column name */
  MediaIdentifier = 'mediaIdentifier',
  /** column name */
  MediaLink = 'mediaLink',
  /** column name */
  MediaMimeType = 'mediaMimeType',
  /** column name */
  MerchantPhoneNumber = 'merchantPhoneNumber',
  /** column name */
  MessageIdentifier = 'messageIdentifier',
  /** column name */
  ReactedWhatsappMessageIdentifier = 'reactedWhatsappMessageIdentifier',
  /** column name */
  ReactionEmoji = 'reactionEmoji',
  /** column name */
  ReceivingErrorCode = 'receivingErrorCode',
  /** column name */
  ReceivingErrorTitle = 'receivingErrorTitle',
  /** column name */
  RepliedWhatsappMessageIdentifier = 'repliedWhatsappMessageIdentifier',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateHeaderMediaKey = 'templateHeaderMediaKey',
  /** column name */
  TemplateHeaderMediaMimeType = 'templateHeaderMediaMimeType',
  /** column name */
  TemplateName = 'templateName',
  /** column name */
  Text = 'text',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  WhatsappConversationId = 'whatsappConversationId',
  /** column name */
  WhatsappMessageTemplateId = 'whatsappMessageTemplateId'
}

export type WhatsappMessagesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<WhatsappMessagesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<WhatsappMessagesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<WhatsappMessagesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<WhatsappMessagesDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<WhatsappMessagesIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<WhatsappMessagesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<WhatsappMessagesSetInput>;
  /** filter the rows which have to be updated */
  where: WhatsappMessagesBoolExp;
};

/** aggregate var_pop on columns */
export type WhatsappMessagesVarPopFields = {
  __typename?: 'whatsapp_messages_var_pop_fields';
  locationLatitude?: Maybe<Scalars['Float']>;
  locationLongitude?: Maybe<Scalars['Float']>;
  receivingErrorCode?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type WhatsappMessagesVarSampFields = {
  __typename?: 'whatsapp_messages_var_samp_fields';
  locationLatitude?: Maybe<Scalars['Float']>;
  locationLongitude?: Maybe<Scalars['Float']>;
  receivingErrorCode?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type WhatsappMessagesVarianceFields = {
  __typename?: 'whatsapp_messages_variance_fields';
  locationLatitude?: Maybe<Scalars['Float']>;
  locationLongitude?: Maybe<Scalars['Float']>;
  receivingErrorCode?: Maybe<Scalars['Float']>;
};
