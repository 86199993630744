import _env from '@beam-australia/react-env'

const env = (key: string, defaultValue?: string | null) => {
  const value = _env(key)
  if (value === undefined && defaultValue === undefined) {
    // Explicitly fail to prevent app from continuing to run
    throw new Error(`Environment variable ${key} is not defined`)
  }
  return value ?? defaultValue
}

const isLocalhost = () => ['localhost', '127.0.0.1'].includes(window.location.hostname)

const isReview = () => (/^https?:\/\/app.review-.+\.review\.flinkit\.de$/).test(
  window.location.hostname
)

export default env
export {isLocalhost, isReview}
