import {TouchEventHandler, useCallback, useRef} from "react"

import {Scalars} from '../types/graphqlSchema'

// adapted from:
// https://stackoverflow.com/questions/48048957/react-long-press-event
const useLongPressEvent = ({
  eventDuration = 500,
  longPressDelay = 500,
  onEnter = undefined,
  onExit = undefined,
}: UseLongPressEventProps) => {
  const enterTimerRef = useRef<NodeJS.Timeout>()
  const exitTimerRef = useRef<NodeJS.Timeout>()
  const clearEventTimers = useCallback(() => {
    enterTimerRef.current && clearTimeout(enterTimerRef.current)
    exitTimerRef.current && clearTimeout(exitTimerRef.current)
    enterTimerRef.current = undefined
    exitTimerRef.current = undefined
  }, [])
  const disableEvent = useCallback(e => {
    clearEventTimers()
    onExit?.(e)
  }, [clearEventTimers, onExit])
  const enableEvent = useCallback(e => {
    clearEventTimers()
    onEnter?.(e)
  }, [clearEventTimers, onEnter])
  const startEnterEventTimer = useCallback(e => {
    enterTimerRef.current = setTimeout(() => enableEvent(e), longPressDelay)
  }, [enableEvent, longPressDelay])
  const startExitEventTimer = useCallback(e => {
    clearEventTimers()
    exitTimerRef.current = setTimeout(() => disableEvent(e), eventDuration)
  }, [clearEventTimers, disableEvent, eventDuration])
  return {
    cancelTouch: e => disableEvent(e),
    onTouchEnd: e => startExitEventTimer(e),
    onTouchStart: e => startEnterEventTimer(e),
  }
}

interface UseLongPressEventProps {
  eventDuration?: Scalars['Int']
  longPressDelay?: Scalars['Int']
  onEnter?: TouchEventHandler,
  onExit?: TouchEventHandler,
}

export default useLongPressEvent
