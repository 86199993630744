import {QueryResultWrapper, useQuery} from '@apollo/client'
import gql from 'graphql-tag'
import {createContext, useEffect} from 'react'
import {usePermissions} from 'react-admin'
import {usePageVisibility} from 'react-page-visibility'

import {QueryRoot} from '../types/graphqlSchema'

const SandboxContext = createContext<SandboxContextValues>({
  isSandbox: false, shouldOnboard: false,
})

const SandboxProvider = ({children}) => {
  const {permissions: {allowedRoles = []} = {}} = usePermissions<Permissions>()
  const isSandbox = allowedRoles.includes('sandbox_merchant_user')
  const isPageVisible = usePageVisibility() ?? true
  const {data, subscribeToMore} = useQuery<QueryRoot['chats']>(
    gql`query${CHATS_QUERY}`,
    {skip: !(isSandbox && isPageVisible)},
  )
  const shouldOnboard = data ? !data.chats.length : false
  useEffect(() => {
    if (!(isSandbox && isPageVisible) || (data && data.chats.length)) return
    return subscribeToMore({
      document: gql`subscription${CHATS_QUERY}`,
      updateQuery: (_, {subscriptionData: {data}}) => ({
        chats: data?.chats,
      } as QueryResultWrapper<QueryRoot['chats']>),
    })
  }, [data, isPageVisible, isSandbox, subscribeToMore])
  return (
    <SandboxContext.Provider value={{isSandbox, shouldOnboard}}>
      {children}
    </SandboxContext.Provider>
  )
}

const CHATS_QUERY = '{chats(where: {type: {_eq: EXTERNAL}}){id}}'

interface SandboxContextValues {
  isSandbox: boolean,
  shouldOnboard: boolean,
}

export {SandboxContext}
export default SandboxProvider
