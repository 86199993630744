import {Box} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC} from 'react'

import Dot from '../../components/Dot'
import {Maybe} from '../../types/graphqlSchema'

const MerchantCustomerUserAutocompleteOption: FC<
  MerchantCustomerUserAutocompleteOptionProps
> = ({
  firstName,
  isTypingPhoneNumber,
  lastName,
  whatsappPhoneNumber,
}) => {
  const styles = useStyles()
  return (
    <Box className={styles.root} display="flex" justifyContent="center">
      {isTypingPhoneNumber && <>
        <b>{whatsappPhoneNumber}</b>
        <Dot className={styles.dot} />
      </>}
      <span>{[firstName, lastName].join(" ")}</span>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  dot: {
    backgroundColor: theme.palette.primary.main,
    height: theme.remSpacing(1),
    margin: theme.remSpacing(1),
    width: theme.remSpacing(1),
  },
  root: {
    '& *': {
      marginLeft: theme.remSpacing(1),
      marginRight: theme.remSpacing(2),
    },
  },
}))

interface MerchantCustomerUserAutocompleteOptionProps {
  firstName?: Maybe<string>
  isTypingPhoneNumber?: boolean
  lastName?: Maybe<string>
  whatsappPhoneNumber?: string
}

export default MerchantCustomerUserAutocompleteOption
