import {Remote} from 'comlink'
import {createContext, useMemo} from 'react'

// @ts-ignore
const WorkerContext = createContext<Worker>()

const WorkerProvider = ({children}) => {
  const worker = useMemo(
    () => new ComlinkWorker<Worker>(new URL('../utils/worker.ts', import.meta.url)),
    [],
  )
  return (
    <WorkerContext.Provider value={worker}>
      {children}
    </WorkerContext.Provider>
  )
}

type Worker = Remote<typeof import('../utils/worker')>

export {WorkerContext}
export default WorkerProvider
