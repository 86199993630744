import {createContext, useMemo} from 'react'

import env from '../utils/env'

const GRAPHQL_API_READ_REPLICA_URL = env('GRAPHQL_API_READ_REPLICA_URL')
const FILES_API_PORT = env('FILES_API_PORT', null)
const GRAPHQL_API_PORT = env('GRAPHQL_API_PORT', null)

const getFilesApiUrl = baseHostname => `${import.meta.env.DEV ? 'http' : 'https'}://${
  FILES_API_PORT ? `${baseHostname}:${FILES_API_PORT}` : `files.${baseHostname}`
}/v1`

const getGraphqlApiUrl = baseHostname => `${import.meta.env.DEV ? 'http' : 'https'}://${
  GRAPHQL_API_PORT ? `${baseHostname}:${GRAPHQL_API_PORT}` : `api.${baseHostname}`
}/v1/graphql`

const getGraphqlWebsocketApiUrl = baseHostname => (
  // Use the graphql read replica (if available) so that all graphql subscriptions load
  // gets distributed to all available read-replicas.
  // Currently we only distribute websocket based graphql subscriptions (which
  // contribute most significantly to our database load), we can however move the
  // re-routing to our apollo client instead so that it alternately routes graphql post
  // requests to either the master or its read-replicas depending on whether the
  // request only contains queries but no mutations.
  // As we allow dynamic `baseHostname`s, we currently only permit using the graphql
  // read replica if `baseHostname` resolves to its environment variable counter part.
  // That is because the (temporary) dynamic base hostnames coming from the
  // `mobile_app_releases` table don't yet have a setting for whether they provide a
  // read replica, too.
  (
    GRAPHQL_API_READ_REPLICA_URL &&
    // Only use replica when base hostname is not overwritten (see comment above)
    (baseHostname === env('BASE_HOSTNAME'))
  ) ? GRAPHQL_API_READ_REPLICA_URL : getGraphqlApiUrl(baseHostname)
).replace(/^http/, 'ws')

const ApiUrlsContext = createContext({
  getFilesApiUrl: path => `${getFilesApiUrl(env('BASE_HOSTNAME'))}/${path}`,
  graphqlApiUrl: getGraphqlApiUrl(env('BASE_HOSTNAME')),
  graphqlWebsocketApiUrl: getGraphqlWebsocketApiUrl(env('BASE_HOSTNAME')),
})

const ApiUrlsProvider = ({baseHostname, children}) => {
  const value = useMemo(() => ({
    getFilesApiUrl: path => `${getFilesApiUrl(baseHostname)}/${path}`,
    graphqlApiUrl: getGraphqlApiUrl(baseHostname),
    graphqlWebsocketApiUrl: getGraphqlWebsocketApiUrl(baseHostname),
  }), [baseHostname])
  return (
    <ApiUrlsContext.Provider value={value}>
      {children}
    </ApiUrlsContext.Provider>
  )
}

export {ApiUrlsContext, ApiUrlsProvider, getGraphqlApiUrl}
export default ApiUrlsContext
