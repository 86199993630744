import {makeStyles} from '@material-ui/core/styles'
import _ from 'lodash'
import {FC, useContext} from 'react'
import {Link} from 'react-admin'
import {useHistory} from 'react-router-dom'

import HistoryStackContext from '../contexts/HistoryStackContext'
import {isNativeIosApp} from '../utils/platform'
import {ArrowLeftIcon} from './icons'

const BackNavigationLink: FC<BackNavigationLinkProps> = ({className, fallbackPath}) => {
  const styles = useStyles()
  const {goBack} = useHistory()
  // The 2nd last history item is the previous location which we want to render as the
  // link's `href`. This allows the user to see the URL the browser will navigate to,
  // although we are going to call `goBack()` to pop from the history stack in case there
  // is a previous location. Otherwise `Link` will history-push the `fallbackPath`.
  const previousLocation = _.nth(useContext(HistoryStackContext), -2)
  const previousPath = previousLocation &&
    `${previousLocation.pathname}${previousLocation.hash}${previousLocation.search}`
  return (
    <Link
      className={`${styles.root} ${className ?? ''}`}
      onClick={
        // TODO: Temporary fix in iOS where calling `goBack` was causing tha viewport
        //       to be distorted. We now rely on the path passed to the `to` prop rather
        //       than calling `goBack` on clicking the `Link`.
        //       Root cause is still unknown.
        (previousLocation && !isNativeIosApp) ?
          (e => {e.preventDefault(); goBack()}) : undefined
      }
      to={previousPath ?? fallbackPath}
    >
      <ArrowLeftIcon />
    </Link>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-box',
    paddingTop: theme.remSpacing(0.5),
    width: theme.typography.pxToRem(32),
  },
}))


interface BackNavigationLinkProps {
  className: string,
  fallbackPath: string
}

export default BackNavigationLink
