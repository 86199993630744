import {usePermissions} from 'react-admin'

const useIsChannelAdmin = (channelId: string) => {
  const {
    permissions: {allowedRoles = [], channelAdminChannelIds = []} = {},
  } = usePermissions<Permissions>()
  return allowedRoles.includes('merchant_channel_admin') &&
    channelAdminChannelIds.includes(channelId)
}

export default useIsChannelAdmin
