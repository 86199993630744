import {App} from '@capacitor/app'
import {Capacitor} from '@capacitor/core'
import {History} from 'history'

// Adds an app listener which pushes deep links onto the history stack.
// Returns a function which, when called, removes the added listener.
const installDeepLinkHandler = (history: History) => {
  if (!Capacitor.isNativePlatform()) return () => {}
  return App.addListener('appUrlOpen', ({url}) => {
    const {hostname, pathname, search} = new URL(url)
    // Deep links pointing to the app's host shall be resolved by pushing
    // the link URL's path onto the history stack
    if (hostname === window.location.hostname) {
      history.push(`${pathname}${search}`)
    }
  }).remove
}

export default installDeepLinkHandler
