import {ApolloClient, from, HttpLink, InMemoryCache, split} from '@apollo/client'
import {NormalizedCacheObject} from '@apollo/client/cache'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'
import {RetryLink} from '@apollo/client/link/retry'
import {GraphQLWsLink} from '@apollo/client/link/subscriptions'
import {getMainDefinition} from '@apollo/client/utilities'
import {OperationDefinitionNode} from 'graphql/language/ast'
import {createClient} from 'graphql-ws'
import {DataProvider} from 'ra-core'
import buildHasuraProvider from 'ra-data-hasura'

import buildFields from './dataProviderCustomBuildFields'

let _apolloClient: ApolloClient<NormalizedCacheObject>

const buildDataProvider = async ({getToken, uri, websocketUri}: DataProviderOptions) => {
  /**
   * Apollo in-memory cache, customized for the flinkit domain by allowing to configure
   * cache keys for types which don't have an `id` field such as with many-to-many
   * relationship types. By configuring custom id paths for such types, this cache allows
   * for an easy cache setup for such types without a "standard" `id` field.
   */
  const apolloCache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          chats: {
            merge: (_, incoming) => incoming,
          },
          merchant_customer_user_contact_groups: {
            merge: (_, incoming) => incoming,
          },
          merchant_customer_user_tags: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      Subscription: {
        fields: {
          chat_messages_event_logs_stream: {
            merge: (_, incoming) => incoming,
          },
          chats: {
            merge: (_, incoming) => incoming,
          },
          chats_event_logs_stream: {
            merge: (_, incoming) => incoming,
          },
        },
      },
      user_chats: {
        keyFields: ['chatId'],
      },
    },
  })
  const authLink = setContext(() => getToken()
    .then(token => (
      token ? {headers: {authorization: `Bearer ${token}`}} : {}
    ))
    .catch(() => {})
  )
  const httpLink = new HttpLink({uri})
  const wsLink = new GraphQLWsLink(createClient({
    connectionParams: () => getToken().then(token =>
      token ? {headers: {Authorization: `Bearer ${token}`}} : {}
    ),
    lazy: true,
    url: websocketUri,
  }))
  const requestLink = split(
    ({query}) => {
      const {kind, operation} = getMainDefinition(query) as OperationDefinitionNode
      return (kind === 'OperationDefinition') && (operation === 'subscription')
    },
    wsLink,
    httpLink,
  )
  // Documentation on retryLink:
  // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
  const retryLink = new RetryLink({
    attempts: {
      // retries at most 5 times
      max: 5,
      // a retry will happen for any error.
      retryIf: error => !!error,
    },
    delay: {
      // minimum delay of 300ms
      initial: 300,
      // delays between request retries will be randomized
      jitter: true,
      // maximum delay of 5000ms
      max: 5000,
    },
  })
  const errorLink = onError(({
    graphQLErrors, networkError, operation: {getContext, query, variables},
  }) => {
    const context = getContext()
    const queryDocument = query.loc?.source.body
    graphQLErrors?.forEach(e => {
      // Ignore missing query names where there's no auth header
      // TODO: Avoid making automatic queries/subscription in the app when the
      //       user isn't authenticated yet e.g. for channels on sidebar etc.
      if (
        context.headers?.authorization ||
        !(/^field '([a-zA-Z]|_)+' not found in type: '([a-zA-Z]|_)+'$/)
          .test(e.message)
      ) {
        logError(e, "GraphQL", queryDocument, variables)
      }
    })
    // TODO: errors should be intercepted by authProvider.checkError()
    //       We want the user session to finish if network repsonse is 401
    if (networkError) {
      logError(networkError, "Network", queryDocument, variables)
    }
  })
  _apolloClient = new ApolloClient({
    cache: apolloCache,
    link: from([retryLink, errorLink, authLink, requestLink]),
  })
  const dataProvider = await buildHasuraProvider({client: _apolloClient}, {buildFields})
  const customizedDataProvider = {
    ...dataProvider,
    async create(resourceType, params) {
      if (resourceType === 'merchant_customer_user_tags') {
        const {
          data: {merchantCustomerUserId, tagId},
        } = await dataProvider.create(resourceType, params)
        return {
          data: {id: `${merchantCustomerUserId}|${tagId}`, merchantCustomerUserId, tagId},
        }
      }
      if (resourceType === 'merchant_customer_user_contact_groups') {
        const {
          data: {contactGroupId, merchantCustomerUserId},
        } = await dataProvider.create(resourceType, params)
        return {
          data: {
            contactGroupId,
            id: `${merchantCustomerUserId}|${contactGroupId}`,
            merchantCustomerUserId,
          },
        }
      }
      if (resourceType === 'merchant_user_contact_groups') {
        const {
          data: {contactGroupId, merchantCustomerUserId},
        } = await dataProvider.create(resourceType, params)
        return {
          data: {
            contactGroupId,
            id: `${merchantCustomerUserId}|${contactGroupId}`,
            merchantCustomerUserId,
          },
        }
      }
      return dataProvider.create(resourceType, params)
    },
    async getList(resourceType, params) {
      if (resourceType === 'aggregated_merchant_customer_user_tags') {
        const {data, ...metadata} = await dataProvider.getList(resourceType, params)
        return {
          ...metadata,
          data: data.map(row => ({
            ...row, id: `${row.merchantCustomerUserId}|${row.tagType}|${row.tagName}`,
          })),
        }
      }
      if (resourceType === 'merchant_customer_user_contact_groups') {
        const {data, ...metadata} = await dataProvider.getList(resourceType, params)
        return {
          ...metadata,
          data: data.map(row => ({
            ...row, id: `${row.contactGroupId}|${row.merchantCustomerUserId}`,
          })),
        }
      }
      if (resourceType === 'merchant_customer_user_tags') {
        const {data, ...metadata} = await dataProvider.getList(resourceType, params)
        return {
          ...metadata,
          data: data.map(row => ({...row, id: row.tagId})),
        }
      }
      if (resourceType === 'merchant_user_contact_groups') {
        const {data, ...metadata} = await dataProvider.getList(resourceType, params)
        return {
          ...metadata,
          data: data.map(row => ({
            ...row, id: `${row.merchantUserId}|${row.contactGroupId}`,
          })),
        }
      }
      if (resourceType === 'merchant_customer_user_tags') {
        const {data, ...metadata} = await dataProvider.getList(
          resourceType,
          {
            ...params,
            filter: {'merchantCustomerUserId@_in': params.filter.ids},
          }
        )
        return {
          ...metadata,
          data: data.map(row => ({
            ...row, id: `${row.merchantCustomerUserId}|${row.tagId}`,
          })),
        }
      }
      return await dataProvider.getList(resourceType, params)
    },
    async getMany(resourceType, params) {
      if (resourceType === 'merchant_customer_user_contact_groups') {
        const {data, ...metadata} = await dataProvider.getList(
          resourceType,
          {
            filter: {'merchantCustomerUserId@_in': params.ids},
            pagination: {page: 1, perPage: params.ids?.length},
            sort: {field: 'contactGroupId', order: 'ASC'},
          }
        )
        return {
          ...metadata,
          data: data.map(row => ({
            ...row, id: `${row.merchantCustomerUserId}|${row.contactGroupId}`,
          })),
        }
      }
      if (resourceType === 'merchant_customer_user_tags') {
        const {data, ...metadata} = await dataProvider.getList(
          resourceType,
          {
            filter: {'merchantCustomerUserId@_in': params.ids},
            pagination: {page: 1, perPage: params.ids?.length},
            sort: {field: 'tagId', order: 'ASC'},
          }
        )
        return {
          ...metadata,
          data: data.map(row => ({
            ...row, id: `${row.merchantCustomerUserId}|${row.tagId}`,
          })),
        }
      }
      return await dataProvider.getMany(resourceType, params)
    },
    async getManyReference(resourceType, params) {
      if (resourceType === 'merchant_user_contact_groups') {
        const {data, ...metadata} = await dataProvider.getManyReference(
          resourceType,
          {...params, sort: {field: 'contactGroupId', order: 'ASC'}},
        )
        return {
          ...metadata,
          data: data.map(row => ({
            ...row, id: `${row.merchantCustomerUserId}|${row.contactGroupId}`,
          })),
        }
      }
      if (resourceType === 'channel_merchant_users') {
        const {
          data, ...metadata
        } = await dataProvider.getManyReference(resourceType, params)
        return {
          ...metadata,
          data: data.map(row => ({
            ...row, id: `${row.channelId}|${row.merchantUserId}`,
          })),
        }
      }
      return await dataProvider.getManyReference(resourceType, params)
    },
  } as DataProvider
  return customizedDataProvider
}

const logError = (error, errorType, queryDocument, variables) => {
  console.error(
    `${errorType} error: ${JSON.stringify({error, queryDocument, variables})}`
  )
}

interface DataProviderOptions {
  getToken: () => Promise<string | undefined>,
  uri: string,
  websocketUri: string,
}

export default buildDataProvider
export {_apolloClient}
