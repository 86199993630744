import {FC} from 'react'
import {useTranslate} from 'react-admin'

import env from '../../../utils/env'
import platform from '../../../utils/platform'

const LocationChatMessageFileAttachment: FC<LocationChatMessageFileAttachmentProps> = ({
  value,
}) => {
  const {latitude, longitude} = value ?? {}
  const translate = useTranslate()
  if (!latitude || !longitude) {
    return <>{translate(
      'chat.chatMessageBubble.chatMessageFileAttachmentList.' +
      'chatMessageFileAttachment.locationChatMessageFileAttachment.cannotShowGpsPosition'
    )}</>
  }
  const link = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
  return (
    <a href={link} rel="noreferrer" target="_blank">
      {
        GMAPS_API_KEY ? (
          <img
            alt="In Google Maps öffnen"
            loading="lazy"
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=17&size=300x200&maptype=roadmap&key=${GMAPS_API_KEY}`}
          />
        ) : link
      }
    </a>
  )
}

const GMAPS_API_KEY = {
  'android': env('GMAPS_ANDROID_API_KEY'),
  'ios': env('GMAPS_IOS_API_KEY'),
  'web': env('GMAPS_BROWSER_API_KEY'),
}[platform]

interface LocationChatMessageFileAttachmentProps {
  value: {
    latitude: string
    longitude: string
  }
}

export default LocationChatMessageFileAttachment


