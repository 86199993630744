import {FC} from 'react'

import {ChatMessageFileAttachments} from '../../../types/graphqlSchema'
import ChatMessageFileAttachment from './ChatMessageFileAttachment'

const ChatMessageFileAttachmentList: FC<ChatMessageFileAttachmentListProps> = ({
  chatId, fileAttachments, isTranslationVisible,
}) => <>{
  fileAttachments.map(a => (
    <ChatMessageFileAttachment
      chatId={chatId} isTranslationVisible={isTranslationVisible} key={a.id} value={a}
    />
  ))
}</>

interface ChatMessageFileAttachmentListProps {
  chatId: string
  fileAttachments: ChatMessageFileAttachments[]
  isTranslationVisible?: boolean
}

export default ChatMessageFileAttachmentList
