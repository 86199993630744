import 'swiper/css'
import 'swiper/css/navigation'

import {useSubscription} from '@apollo/client'
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {
  CloudDownload as DownloadIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@material-ui/icons'
import {Alert} from '@material-ui/lab'
import gql from 'graphql-tag'
import {FC, Fragment, useCallback, useContext, useMemo, useState} from 'react'
import {useTranslate} from 'react-admin'
import {usePageVisibility} from 'react-page-visibility'
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch'
import {Lazy, Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'

import ApiUrlsContext from '../../../contexts/ApiUrlsContext'
import useIsDesktop from '../../../hooks/useIsDesktop'
import theme from '../../../theme'
import {ChatMessageFileAttachments, SubscriptionRoot} from '../../../types/graphqlSchema'
import {isNativeIosApp} from '../../../utils/platform'
import DialogCloseButton from '../../DialogCloseButton'
import ExtendedDialog from '../../ExtendedDialog'
import RichTranslationText from '../../RichTranslationText'
import AudioPlayer from '../ChatToolbar/AudioPlayer'
import ChatMessageTranslation from './ChatMessageTranslation'
import LocationChatMessageFileAttachment from './LocationChatMessageFileAttachment'

const ChatMessageFileAttachment: FC<ChatMessageFileAttachmentProps> = ({
  chatId,
  isTranslationVisible,
  shouldDisplayFileName = true,
  shouldShowImagePreview = true,
  value: a,
}) => {
  const translate = useTranslate()
  const isDesktop = useIsDesktop()
  const isPageVisible = usePageVisibility() ?? true
  const [currentAttachmentId, setCurrentAttachmentId] = useState<string | undefined>(a.id)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [isFullscreen, setFullscreen] = useState(!isDesktop)
  const {getFilesApiUrl} = useContext(ApiUrlsContext)
  const getUrl = useCallback<GetUrl>(({attachment: a, shouldStartDownload = false}) =>
    a && `${getFilesApiUrl(a.key)}${shouldStartDownload ? `/${a.filename}` : ''}`
  , [getFilesApiUrl])
  const isImageFile = (/image\/\w+/).test(a.mimeType)
  const isAudioFile = (/audio\/\w+/).test(a.mimeType)
  const thumbnailUrl = useMemo<string|undefined>(() => {
    if (isImageFile) return getUrl({attachment: a})
    if (a?.thumbnailMediaKey) return getFilesApiUrl(a.thumbnailMediaKey)
    return undefined
  }, [a, getFilesApiUrl, getUrl, isImageFile])
  const classes = useStyles({isAudioFile})
  const {
    data: {chat_message_file_attachments: allChatMessageFileAttachments = [a]} = {},
  } = useSubscription<SubscriptionRoot['chat_message_file_attachments']>(
    ALL_CHAT_MESSAGE_FILE_ATTACHMENTS_SUBSCRIPTION,
    {skip: !(isDialogOpen && isPageVisible), variables: {chatId}}
  )
  const initialIndex = useMemo(
    () => allChatMessageFileAttachments
      .findIndex(attachment => attachment.id === a.id),
    [a, allChatMessageFileAttachments]
  )
  // `currentAttachment` is nullable as it may not find the current attachment; that
  // happens when the subscription (above) is skipped due to dialog close.
  const currentAttachment = useMemo(
    () => allChatMessageFileAttachments.find(a => a.id === currentAttachmentId),
    [allChatMessageFileAttachments, currentAttachmentId]
  )
  // XXX: If there are multiple attachments, they all render hovered at the same time
  return (
    <Grid className={classes.chatMessageFileAttachment} item xs={12}>
      <ListItemText>
        {
          (a.mimeType === 'application/location+json') ?
            (<LocationChatMessageFileAttachment value={a.metadata} />) :
            (
              isAudioFile ? (
                <AudioPlayer fileAttachmentUrl={getUrl({attachment: a})} />
              ) : (
                <Link
                  className={`${classes.link} ${classes.attachmentLinkContainer}`}
                  onClick={() => setDialogOpen(shouldShowImagePreview)}
                >
                  {
                    !!thumbnailUrl &&
                    <img
                      alt={a.filename}
                      className={classes.thumbnailImage}
                      loading="lazy"
                      src={thumbnailUrl}
                    />
                  }
                  {!isImageFile && shouldDisplayFileName && <div>{a.filename}</div>}
                </Link>
              )
            )
        }
      </ListItemText>
      {a.transcribedText && (
        <>
          <ListItemText primaryTypographyProps={{variant: 'body2'}}>
            {translate(
              'chat.chatMessageBubble.chatMessageFileAttachmentList.' +
              'chatMessageFileAttachment.transcribedTextWarning',
            )}
          </ListItemText>
          <ListItemText className={classes.transcribedText}>
            {a.transcribedText}
          </ListItemText>
        </>
      )}
      {isTranslationVisible &&
        <ChatMessageTranslation
          translations={a.chatMessageFileAttachmentTranslations}
        />
      }
      <ExtendedDialog
        className={classes.dialog}
        fullScreen={isFullscreen}
        onClose={() => setDialogOpen(false)}
        open={isDialogOpen}
      >
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography className={classes.title}>
            {currentAttachment?.filename ?? a.filename}
          </Typography>
          <IconButton
            className={classes.fullscreenButton}
            onClick={() => setFullscreen(f => !f)}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <DialogCloseButton
            className={classes.closeDialogButton}
            onClick={() => setDialogOpen(false)}
          />
        </DialogTitle>
        <DialogContent>
          {
            // TODO: This is a temporary fix for iOS and safari, where .ogg file formats
            //       are not supported
            // @ts-ignore
            (!!window.safari || isNativeIosApp) &&
            (/audio\/ogg/).test(a.mimeType) &&
            <Alert severity="warning">
              <RichTranslationText
                rootElement={Fragment}
                tags={{
                  a: match => (
                    <a href={getUrl({attachment: a, shouldStartDownload: true})}>
                      {match}
                    </a>
                  ),
                }}
                translationKey={'chat.chatMessageBubble.chatMessageFileAttachmentList.' +
                  'chatMessageFileAttachment.downloadAudioMessage'
                }
              />
            </Alert>
          }
          {
            isImageFile ?
              /*
                Only once all file attachments are loaded, `initialSlide` can be assigned
                the right prop value. However `<Swiper />` doesn't re-initialize since it
                has already rendered before all attachments were loaded. That's why `key`
                is being reset to instruct React to remount this component with the new
                initial value. See also: https://stackoverflow.com/a/48451229/543875
               */
              <Swiper
                centeredSlides={true}
                className={classes.swiper}
                initialSlide={initialIndex}
                key={allChatMessageFileAttachments.length === 1 ?
                  'attachments-loading-swiper' :
                  'attachments-loaded-swiper'}
                lazy={{
                  enabled: true,
                  loadPrevNext: true,
                  loadPrevNextAmount: 1,
                }}
                modules={[Navigation, Lazy]}
                navigation={true}
                onSlideChange={({activeIndex}) => {
                  setCurrentAttachmentId(allChatMessageFileAttachments
                    .find((_, index) => index === activeIndex)?.id
                  )
                }}
                slidesPerView={1}
                spaceBetween={50}
              >
                {allChatMessageFileAttachments.map(attachment => (
                  <SwiperSlide className={classes.swiperSlide} key={attachment.id}>
                    <SwiperImage
                      alt={attachment.filename}
                      attachment={attachment}
                      isFullscreenDialog={isFullscreen}
                      src={getUrl({attachment})}
                    />
                    {/* Required by the swiper component */}
                    <div className="swiper-lazy-preloader" />
                  </SwiperSlide>
                ))}
              </Swiper> :
              <iframe
                className={classes.iframe}
                src={getUrl({attachment: a})}
                title={a.filename}
              />
          }
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            component="a"
            href={
              getUrl({attachment: currentAttachment ?? a, shouldStartDownload: true})
            }
            startIcon={<DownloadIcon />}
          >
            {translate('actions.download')}
          </Button>
        </DialogActions>
      </ExtendedDialog>
    </Grid>
  )
}

const SwiperImage = ({alt, attachment: a, isFullscreenDialog, src}) => {
  const styles = useStyles({})
  return (
    /*
      The `maxWidth` condition allows a better display of images on large screens whose
      width is greater than the height.
      Without this condition they appear too small on large screens.
      Compare with these two images:
      h > w: https://unsplash.com/photos/1SPcjA1W8M4
      w > h: https://unsplash.com/photos/GncCCBNpWMU
     */
    <Container
      className={styles.swiperImageContainer}
      maxWidth={a.width > a.height ? 'xl' : 'sm'}
    >
      <TransformWrapper>
        <TransformComponent
          contentClass={styles.swiperImageTransformComponentContent}
          wrapperClass={styles.swiperImageTransformComponentWrapper}
        >
          <img
            alt={alt}
            className={`
              swiper-lazy
              ${styles.swiperImage}
              ${isFullscreenDialog ?
              styles.swiperImageDialogFullScreen :
              styles.swiperImageDialogNormalScreen}
            `}
            data-src={src}
            width={a.width ? `${a.width}px` : undefined}
          />
        </TransformComponent>
      </TransformWrapper>
    </Container>
  )
}

const ALL_CHAT_MESSAGE_FILE_ATTACHMENTS_SUBSCRIPTION = gql`
  subscription($chatId: uuid!) {
    chat_message_file_attachments(
      order_by: {chatMessage: {insertionTimestamp: asc}}
      where: {
        mimeType: {_ilike: "image/%"}
        chatMessage: {_or: [
          {chatId: {_eq: $chatId}}
          {forwardingChatMessages: {chatId: {_eq: $chatId}}}
        ]}
      }
    ){
      filename
      height
      id
      key
      mimeType
      width
    }
  }
`

const useStyles = makeStyles<typeof theme, MakeStylesProps>(theme => ({
  attachmentLinkContainer: ({isAudioFile}) => ({
    ...(isAudioFile && {
      '& img': {
        width: '24px',
      },
      alignItems: 'center',
      columnGap: '1rem',
      display: 'flex',
    }),
  }),
  chatMessageFileAttachment: {
    padding: '0',
  },
  closeDialogButton: {
    position: 'unset',
  },
  dialog: {
    '& .MuiDialog-paper:not(.MuiDialog-paperFullScreen)': {
      height: '100%',
      maxHeight: 'min(800px, calc(100% - 64px))',
      maxWidth: 'min(1024px, calc(100% - 64px))',
      width: '100%',
    },
    '& .MuiDialogContent-root': {
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
    },
  },
  dialogActions: {
    '& .MuiButtonBase-root': {
      marginTop: '8px',
    },
    borderTop: `solid 1px ${theme.palette.grey[300]}`,
  },
  dialogTitle: {
    alignItems: 'center',
    borderBottom: `solid 1px ${theme.palette.grey[300]}`,
    display: 'flex',
    margin: 0,
    paddingBottom: theme.remSpacing(1),
    paddingRight: theme.remSpacing(3.5),
    paddingTop: theme.remSpacing(1),
  },
  fullscreenButton: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
    marginRight: theme.remSpacing(.5),
  },
  iframe: {
    border: 0,
    display: 'block', // https://stackoverflow.com/a/12726445/543875
    height: '100%',
    width: '100%',
  },
  link: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontWeight: 700,
  },
  swiper: {
    '& .swiper-button-next': {
      color: theme.palette.secondary.main,
    },
    '& .swiper-button-prev': {
      color: theme.palette.secondary.main,
    },
    '& .swiper-lazy-loading': {
      display: 'none',
    },
    '& .swiper-lazy-preloader': {
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
    },
    height: '100%',
  },
  swiperImage: {
    maxHeight: '10vh',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  swiperImageContainer: {
    padding: 0,
  },
  swiperImageDialogFullScreen: {
    '@media (min-height: 300px)': {
      maxHeight: '40vh',
    },
    '@media (min-height: 400px)': {
      maxHeight: '50vh',
    },
    '@media (min-height: 500px)': {
      maxHeight: '60vh',
    },
    '@media (min-height: 600px)': {
      maxHeight: '70vh',
    },
    '@media (min-height: 700px)': {
      maxHeight: '80vh',
    },
  },
  swiperImageDialogNormalScreen: {
    '@media (min-height: 300px)': {
      maxHeight: '30vh',
    },
    '@media (min-height: 400px)': {
      maxHeight: '40vh',
    },
    '@media (min-height: 500px)': {
      maxHeight: '50vh',
    },
    '@media (min-height: 600px)': {
      maxHeight: '60vh',
    },
    '@media (min-height: 700px)': {
      maxHeight: '70vh',
    },
  },
  swiperImageTransformComponentContent: {
    justifyContent: 'center',
    width: '100%',
  },
  swiperImageTransformComponentWrapper: {
    width: '100%',
  },
  swiperSlide: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  thumbnailImage: {
    borderRadius: '3px',
    maxHeight: 'calc(min(500px, 40vh))',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  title: {
    flexGrow: 1,
  },
  transcribedText: {
    fontStyle: 'italic',
  },
}))

interface ChatMessageFileAttachmentProps {
  chatId: string
  isTranslationVisible?: boolean
  shouldDisplayFileName?: boolean
  shouldShowImagePreview?: boolean
  value: ChatMessageFileAttachments
}

interface GetUrl {
  (options: {attachment?: ChatMessageFileAttachments, shouldStartDownload?: boolean}):
    string|undefined
}

interface MakeStylesProps {
  isAudioFile?: boolean
}

export default ChatMessageFileAttachment
