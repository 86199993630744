import {PushNotifications} from '@capacitor/push-notifications'
import {createContext, FC, useCallback, useEffect, useState} from 'react'
import {useTranslate} from 'react-admin'

const PushNotificationContext = createContext<PushNotificationContextValues>({})

const PushNotificationsProvider: FC<PushNotificationsProviderProps> = ({
  children,
  disabled,
  onBackgroundPushNotificationReceived,
  onForegroundPushNotificationReceived,
  onReceiveDeviceToken,
}) => {
  const translate = useTranslate()
  const [deviceToken, setDeviceToken] = useState<string | undefined>()
  const [registrationError, setRegistrationError] = useState<Error | undefined>()
  const [hasPermission, setHasPermission] = useState(false)
  // TODO: Fix causes of this error message in case we still observe it:
  // APNS device token not set before retrieving FCM Token for Sender ID '263588350977'.
  // Notifications to this FCM Token will not be delivered over APNS.
  // Be sure to re-retrieve the FCM token
  // once the APNS device token is set.
  const requestPermissions = useCallback(
    () => {
      // We could get the `{receive}` result already from the `requestPermissions()`
      // promise. However there is a bug in the push notification framework where the user
      // would tap "grant permissions" when request to grant push notification permission
      // but the callback here may still gave us surprisingly 'rejected'. Turns out that
      // when the app is then restarted, we properly get 'granted' reported back. Since we
      // want to know whether permissions have been granted already in the first app run,
      // we additionally call `checkPermissions()` which _will_ report the proper user
      // decision. This bug is prevalant on Lars' iPhone 8 with iOS 14.5.1 (he refuses to
      // upgrade to newer major iOS versions to avoid seeing these disturbing emojicons
      // depicting a pregnant man (a man!) in the iOS emoji picker).
      PushNotifications.requestPermissions()
        .then(() => PushNotifications.checkPermissions())
        .then(({receive}) => setHasPermission(receive === 'granted'))
        .catch(e => setRegistrationError((typeof e === 'string') ? new Error(e) : e))
    }, [setRegistrationError]
  )
  useEffect(
    () => {
      if (!hasPermission) return
      setDeviceToken(undefined)
      setRegistrationError(undefined)
      registerPushNotifications({
        onBackgroundPushNotificationReceived,
        onForegroundPushNotificationReceived,
        onRegistrationError: setRegistrationError,
        onRegistrationSuccess: setDeviceToken,
      })
      return () => {PushNotifications.removeAllListeners()}
    },
    [
      hasPermission,
      onBackgroundPushNotificationReceived,
      onForegroundPushNotificationReceived,
      setDeviceToken,
      setRegistrationError,
    ],
  )
  useEffect(
    () => {if (!disabled && !deviceToken) requestPermissions()},
    [deviceToken, disabled, requestPermissions],
  )
  useEffect(
    () => {if (deviceToken) onReceiveDeviceToken(deviceToken)},
    [deviceToken, onReceiveDeviceToken],
  )
  useEffect(
    () => {
      registrationError &&
      console.error("Failed to request push notification permission", registrationError)
    },
    [registrationError, translate]
  )
  return (
    <PushNotificationContext.Provider
      value={{deviceToken, error: registrationError, hasPermission}}
    >
      {children}
    </PushNotificationContext.Provider>
  )
}

const registerPushNotifications = ({
  onBackgroundPushNotificationReceived,
  onForegroundPushNotificationReceived,
  onRegistrationError,
  onRegistrationSuccess,
}) => {
  PushNotifications.addListener(
    'registration', ({value}) => onRegistrationSuccess(value)
  )
  PushNotifications.addListener(
    'registrationError', ({error}) => onRegistrationError(error)
  )
  PushNotifications.addListener(
    'pushNotificationReceived',
    notification => onForegroundPushNotificationReceived(notification)
  )
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    ({notification: {data}}) => onBackgroundPushNotificationReceived(data)
  )
  PushNotifications.register()
}

interface PushNotificationContextValues {
  deviceToken?: string
  error?: Error
  hasPermission?: boolean
}

interface PushNotificationsProviderProps {
  disabled: boolean
  onBackgroundPushNotificationReceived: (data: any) => void
  onForegroundPushNotificationReceived: (data: any) => void
  onReceiveDeviceToken: (deviceToken: string | undefined) => void
}

export {PushNotificationContext, PushNotificationsProvider}
