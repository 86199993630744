import {format, parseISO} from 'date-fns'
import {useCallback} from 'react'
import {useLocale} from 'react-admin'

import {isValidDateTimeString} from '../../utils/dates'
import {DATE_FNS_LOCALES_FROM_I18N_LANGUAGES} from '../../utils/i18nProvider'

const useFormatTimestamp = () => {
  const locale = useLocale()
  return useCallback(
    (timestamp: string, {dateFormat = 'PPPPp'} = {}): string | undefined =>
      !isValidDateTimeString(timestamp) ? undefined : format(
        parseISO(timestamp),
        dateFormat,
        {locale: DATE_FNS_LOCALES_FROM_I18N_LANGUAGES[locale]}
      ),
    [locale]
  )
}

export default useFormatTimestamp
