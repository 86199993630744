import {useQuery} from '@apollo/client'
import {ReferenceManyToManyField} from '@flinkit/ra-relationships'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {FC} from 'react'
import {
  Datagrid,
  FunctionField,
  ResourceComponentPropsWithId,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'

import EditFormAutoSave from '../components/EditFormAutoSave'
import ExtendedEdit from '../components/ExtendedEdit'
import useHasPermission from '../hooks/useHasPermission'
import {QueryRoot} from '../types/graphqlSchema'

const ChannelEdit: FC<ChannelProps> = ({channelId}) => {
  const styles = useStyles()
  const {data: {channels_by_pk: channel} = {}} = useQuery<QueryRoot['channels_by_pk']>(
    CHANNEL_WHATSAPP_NUMBER_QUERY, {skip: !channelId, variables: {channelId}}
  )
  return (
    <ExtendedEdit
      basePath="/channels"
      className={styles.editRoot}
      id={channelId}
      resource="channels"
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SimpleForm redirect={false} toolbar={false}>
        <EditFormAutoSave />
        <Typography variant="subtitle2">
          {useTranslate()('resources.channels.edit.inputs.name.subtitle')}
        </Typography>
        <TextInput
          disabled={!useHasPermission('edit', 'channels')}
          label={
            <Typography variant="body1">
              {useTranslate()(
                'resources.channels.edit.inputs.name.label',
                {phoneNumber: channel?.whatsappAccount?.phoneNumber}
              )}
            </Typography>
          }
          source="name"
        />
      </SimpleForm>
    </ExtendedEdit>
  )
}

const ChannelMerchantUsersShow: FC<ChannelProps> = ({channelId}) => {
  const styles = useStyles()
  return (
    <Show
      actions={false}
      basePath="/channels"
      className={styles.showRoot}
      id={channelId}
      resource="channels"
    >
      <SimpleShowLayout>
        {/* ts-ignore */}
        <ReferenceManyToManyField
          addLabel={false}
          reference="merchant_users"
          sort={{field: "merchantUserId", order: 'DESC'}}
          through="channel_merchant_users"
          using="channelId,merchantUserId"
        >
          <Datagrid>
            <TextField
              source="emailAddress"
              variant="body1"
            />
            <FunctionField
              label="Name"
              render={record => `${record.firstName} ${record.lastName}`}
              variant="body1"
            />
          </Datagrid>
        </ReferenceManyToManyField>
      </SimpleShowLayout>
    </Show>
  )
}

const CHANNEL_WHATSAPP_NUMBER_QUERY = gql`query($channelId: uuid!){
  channels_by_pk(id: $channelId){id whatsappAccount{phoneNumber}}
}`

const useStyles = makeStyles(theme => ({
  editRoot: {
    '& .MuiCardContent-root': {
      paddingBottom: theme.remSpacing(1),
    },
    '& > div': {
      marginTop: 0,
    },
    maxWidth: `${theme.typography.pxToRem(384)} !important`,
  },
  showRoot: {
    '& .MuiTableCell-sizeSmall': {
      maxWidth: theme.remSpacing(4),
      overflow: 'hidden',
      padding: theme.remSpacing(1),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& > div ': {
      marginTop: 0,
    },
  },
}))

interface ChannelProps extends ResourceComponentPropsWithId {
  channelId?: string
}

export {
  ChannelEdit,
  ChannelMerchantUsersShow,
}
