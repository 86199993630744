import {
  Button, CircularProgress, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, ReactNode} from 'react'
import {useTranslate} from 'react-admin'

import DialogCloseButton from '../components/DialogCloseButton'
import ExtendedDialog from './ExtendedDialog'

const ProgressDialog: FC<ProgressProps> = ({
  children,
  isProgressing,
  onClose,
  open,
  title,
}) => {
  const styles = useStyles()
  return (
    <ExtendedDialog
      className={styles.root}
      onClose={() => {!isProgressing && onClose()}}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {children}
        {isProgressing &&
          <CircularProgress
            className={styles.circularProgress}
            size={60}
          />
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isProgressing}
          onClick={onClose}
          variant="contained"
        >
          {useTranslate()('ra.action.close')}
        </Button>
      </DialogActions>
      <DialogCloseButton disabled={isProgressing} onClick={onClose} />
    </ExtendedDialog>
  )
}

const useStyles = makeStyles(theme => ({
  circularProgress: {
    color: theme.palette.info.main,
    display: 'flex',
    margin: 'auto',
    marginTop: theme.remSpacing(2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  root: {
    '& .MuiDialog-paper': {
      padding: theme.remSpacing(1),
      [theme.breakpoints.up('md')]: {
        height: '35%',
      },
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}))

interface ProgressProps {
  children: ReactNode,
  isProgressing: boolean
  onClose: () => void,
  open: boolean,
  title: string,
}

export default ProgressDialog
