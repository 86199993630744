import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, useMemo} from 'react'

import {useUserDisplayName} from '../../../hooks/useUserDisplayName'
import Theme from '../../../theme'
import {ChatMessages, WhatsappMessageStatusesEnum} from '../../../types/graphqlSchema'
import ChatMessageFileAttachmentList from './ChatMessageFileAttachmentList'
import ChatMessageStatusLine from './ChatMessageStatusLine'
import ChatMessageTemplateActionButton from './ChatMessageTemplateActionButton'
import ChatMessageText from './ChatMessageText'
import ChatMessageTranslation from './ChatMessageTranslation'
import ChatMessageTranslationBanner from './ChatMessageTranslationBanner'
import ForwardedChatMessageBanner from './ForwardedChatMessageBanner'
import RepliedChatMessageBanner from './RepliedChatMessageBanner'
import TemplateHeaderMedia from './TemplateHeaderMedia'

const ChatMessageBubble: FC<ChatMessageBubbleProps> = ({
  displayText,
  isRight,
  isShowingTranslations,
  status,
  value: {
    authorUser,
    chat,
    chatId,
    chatMessageFileAttachments,
    chatMessageTemplate,
    chatMessageTemplateValues,
    chatMessageTranslations,
    chatMessageWhatsappMessages,
    detectedLanguage,
    forwardedChatMessage,
    lastReactionChatMessage,
    receivingErrorCode,
    repliedChatMessage,
    templateHeaderMediaKey,
    timestamp,
  },
  ...props
}) => {
  const isWhatsappMessageTypeUnknown =
    chatMessageWhatsappMessages?.[0]?.whatsappMessage.type === 'UNKNOWN'
  const styles = useStyles({
    hasFailure: (status === 'FAILED') || isWhatsappMessageTypeUnknown,
    isRight,
  })
  const fileAttachments = useMemo(
    () => forwardedChatMessage ?
      forwardedChatMessage.chatMessageFileAttachments : chatMessageFileAttachments,
    [chatMessageFileAttachments, forwardedChatMessage]
  )
  const userDisplayName = useUserDisplayName()
  return (
    <div className={styles.root} {...props}>
      {lastReactionChatMessage?.reactionEmoji && (
        <div className={styles.reactionEmoji}>
          {lastReactionChatMessage?.reactionEmoji}
        </div>
      )}
      <div
        className={[
          styles.chatMessageBubble,
          isRight ? styles.right : styles.left,
          forwardedChatMessage && styles.forwarded,
        ].filter(Boolean).join(' ')}
      >
        {repliedChatMessage && (
          <RepliedChatMessageBanner repliedChatMessage={repliedChatMessage} />
        )}
        {isShowingTranslations && !!chatMessageTranslations.length && (
          <ChatMessageTranslationBanner
            detectedLanguage={detectedLanguage}
            translatedLanguages={chatMessageTranslations.map(({language}) => language)}
          />
        )}
        {forwardedChatMessage && (
          <ForwardedChatMessageBanner forwardedChatMessage={forwardedChatMessage} />
        )}
        <ChatMessageFileAttachmentList
          chatId={chatId}
          fileAttachments={fileAttachments}
          isTranslationVisible={isShowingTranslations}
        />
        {templateHeaderMediaKey && (
          <TemplateHeaderMedia templateHeaderMediaKey={templateHeaderMediaKey} />
        )}
        {chatMessageTemplate?.headline && (
          <Typography variant="h6">{chatMessageTemplate?.headline}</Typography>
        )}
        <ChatMessageText
          chatMessageTemplate={chatMessageTemplate}
          chatMessageTemplateValues={chatMessageTemplateValues}
          forwardedChatMessage={forwardedChatMessage}
          text={displayText}
        />
        {isShowingTranslations && !!chatMessageTranslations.length && (
          <ChatMessageTranslation translations={chatMessageTranslations} />
        )}
        {chatMessageTemplate?.buttonText && (
          <ChatMessageTemplateActionButton template={chatMessageTemplate} />
        )}
      </div>
      <ChatMessageStatusLine
        authorUserDisplayName={
          (
            isRight || !chat.channelId || authorUser?.channelChatBotUser
          ) ? userDisplayName(authorUser) : undefined
        }
        chatMessageReceivingErrorCode={receivingErrorCode}
        failedWhatsappMessage={chatMessageWhatsappMessages?.find(
          c => c.whatsappMessage.status === 'FAILED'
        )?.whatsappMessage}
        insertionTimestamp={timestamp}
        whatsappMessageReceivingErrorCode={chatMessageWhatsappMessages?.find(
          c => c.whatsappMessage.type === 'UNKNOWN'
        )?.whatsappMessage.receivingErrorCode}
        whatsappMessageStatus={status}
      />
    </div>
  )
}

const useStyles = makeStyles<typeof Theme, MakeStylesOptions>(theme => ({
  chatMessageBubble: {
    '&$forwarded': {
      background: theme.palette.background.default,
    },
    '&$left': {
      background: theme.palette.background.paper,
    },
    '&$right': {
      background: theme.palette.chatBubble.main,
    },
    borderBottom: ({hasFailure}) =>
      hasFailure ?
        `1px ${theme.palette.error.main} solid` :
        'none',
    borderRadius: ({hasFailure}) =>
      `8px 8px ${hasFailure ? '2px 2px' : ''}`,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: theme.remSpacing(2),
    paddingBottom: theme.remSpacing(1),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  forwarded: {},
  left: {},
  reactionEmoji: ({isRight}) => ({
    position: 'absolute',
    top: '0',
    [isRight ? 'left' : 'right']: theme.remSpacing(-4),
  }),
  right: {},
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(min(60%, 400px))',
    paddingBottom: theme.typography.pxToRem(5),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(min(75%, 400px))',
    },
    position: 'relative',
  },
}))

interface ChatMessageBubbleProps {
  displayText: string | undefined
  isRight: boolean
  isShowingTranslations: boolean
  status: WhatsappMessageStatusesEnum
  value: ChatMessages
}

interface MakeStylesOptions {
  hasFailure: boolean
  isRight: boolean
}

export default ChatMessageBubble
