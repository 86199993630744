import 'emoji-mart/css/emoji-mart.css'

import {useQuery} from '@apollo/client'
import {
  Chip, IconButton, TextField, TextFieldProps, Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {useTranslate} from 'react-admin'

import {MobileKeyboardContext} from '../../../contexts/MobileKeyboardContext'
import useFeatureLock from '../../../hooks/useFeatureLock'
import useIsDesktop from '../../../hooks/useIsDesktop'
import {QueryRoot} from '../../../types/graphqlSchema'
import {CHAT_MESSAGE_TEMPLATE_TEXT_MAX_LENGTH} from '../../../utils/consts'
import {elapsedTimeFromSeconds} from '../../../utils/dates'
import {isLocalhost, isReview} from '../../../utils/env'
import {isNativeMobileApp} from '../../../utils/platform'
import {EventCategory, trackEvent} from '../../../utils/tracking'
import {AttachmentIcon, InfoIcon} from '../../icons'
import AudioPlayer from './AudioPlayer'
import AudioRecorderButton from './AudioRecorderButton'
import CameraButton from './CameraButton'
import EmojiPickerButton from './EmojiPickerButton'
import SendButton from './SendButton'
import TemplateMessageComposerDialog from './TemplateMessageComposerDialog'
import TranslationPopper from './TranslationPopper'

const ChatToolbar: FC<ChatToolbarProps> = ({
  buttons,
  chatId,
  fileAttachments,
  isInsertingChatMessage = false,
  isReplyWindowExpired,
  isTemplateDialogOpen,
  onChangeTemplateDialogOpen,
  onChangeText,
  onFilesButtonClick,
  onSelectFileAttachments,
  onSendTemplateChatMessage,
  onSendTextChatMessage,
  text,
}) => {
  const translate = useTranslate()
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  const {keyboard} = useContext(MobileKeyboardContext)
  const canRecordAudio = useFeatureLock([
    /@flinkit.de$/i,
    /@kao.com$/i,
    /@vr-wk.de$/i,
  ])
  const inputRef = useRef<HTMLInputElement>()
  const [isRecordingAudio, setIsRecordingAudio] = useState(false)
  const [audioRecorderElapsedTime, setAudioRecorderElapsedTime] = useState(0)
  const areToolbarButtonsDisabled = (
    isRecordingAudio || (
      !!fileAttachments.length &&
      fileAttachments[0].filename.startsWith('flinkit-app-recording')
    )
  )
  const handleEmojiSelect = useCallback(emoji => {
    onChangeText(`${text}${emoji}`)
    isDesktop && inputRef.current?.focus()
  }, [onChangeText, isDesktop, inputRef, text])
  useEffect(() => {isDesktop && inputRef.current?.focus()}, [inputRef, isDesktop])
  const {
    data: {chats_by_pk: chat} = {},
  } = useQuery<QueryRoot['chats_by_pk']>(
    CHAT_QUERY,
    {variables: {id: chatId}}
  )
  const isSendButtonEnabled = !isInsertingChatMessage && (
    (
      !!text.trim() &&
      (
        !isReplyWindowExpired ||
        (text.length <= CHAT_MESSAGE_TEMPLATE_TEXT_MAX_LENGTH) // magic message
      )
    ) || !!fileAttachments.length
  )
  useEffect(() => {
    // Focus input on file upload allowing for the 'Enter' keypress event to be initiated
    // to send the chat message w/ the file attachment.
    isDesktop && !!fileAttachments.length && inputRef.current?.focus()
  }, [fileAttachments, isDesktop])
  const notification = useMemo(() => {
    if (
      isReplyWindowExpired &&
      // Keep the expired window in dev mode so that the developer is aware that he is
      // outside the 24 window while testing, because the magic templates are not auto
      // synced updated in dev.
      (isLocalhost() || isReview())
    ) return <ExpiredReplyWindowNotification />
  }, [isReplyWindowExpired])
  const sendTextChatMessage = useCallback(async () => {
    await onSendTextChatMessage()
    trackEvent('SEND_MESSAGE', EventCategory.CHAT)
    isReplyWindowExpired && isNativeMobileApp && keyboard.hide()
    isDesktop && inputRef.current?.focus()
  }, [isDesktop, isReplyWindowExpired, keyboard, onSendTextChatMessage])
  useEffect(() => {
    if (!isRecordingAudio) {
      setAudioRecorderElapsedTime(0)
    }
    else {
      const interval = setInterval(() => setAudioRecorderElapsedTime(s => s + 1), 1000)
      return () => clearInterval(interval)
    }
  }, [isRecordingAudio])
  return (
    <div className={styles.root}>
      {notification}
      <div className={styles.toolbarContainer}>
        <div className={styles.messageInput}>
          <ChatMessageTextField
            disabled={isInsertingChatMessage || areToolbarButtonsDisabled}
            helperText={
              isRecordingAudio ? (
                <Typography className={styles.audioRecorderElapsedTime} variant="body2">
                  {elapsedTimeFromSeconds(audioRecorderElapsedTime)}
                </Typography>
              ) : (
                (isReplyWindowExpired && !fileAttachments.length) &&
                `${text.length} / ${CHAT_MESSAGE_TEMPLATE_TEXT_MAX_LENGTH}`
              )
            }
            inputRef={inputRef}
            onChange={e => onChangeText(e.target.value)}
            onKeyPress={e => {
              if (
                (e.key === 'Enter') && !e.shiftKey && isDesktop && isSendButtonEnabled
              ) {
                e.preventDefault()
                sendTextChatMessage()
              }
            }}
            placeholder={translate('chat.toolbar.chatMessageTextFieldPlaceholder')}
            value={text}
            variant="standard"
          />
        </div>
        <FileAttachments
          disabled={isInsertingChatMessage}
          onChange={onSelectFileAttachments}
          value={fileAttachments}
        />
        {chat?.autoTranslationLanguage &&
          <TranslationPopper
            inputElement={inputRef.current}
            inputText={text}
            targetLanguage={chat.autoTranslationLanguage}
          />
        }
        <TemplateMessageComposerDialog
          isOpen={isTemplateDialogOpen}
          onClose={() => onChangeTemplateDialogOpen(false)}
          onSendTemplateChatMessage={onSendTemplateChatMessage}
        />
        <div
          className={styles.buttonsContainer}
          onClick={() => inputRef.current?.focus()}
        >
          <IconButton
            color="primary"
            disabled={areToolbarButtonsDisabled}
            onClick={e => {
              e.stopPropagation()
              trackEvent('SELECT_FILE_ATTACHMENT', EventCategory.CHAT, 'CHAT_TOOLBAR')
              onFilesButtonClick()
            }}
          >
            <AttachmentIcon />
          </IconButton>
          {(!isDesktop || isNativeMobileApp) ?
            <CameraButton
              disabled={areToolbarButtonsDisabled}
              fileEncoding="hex"
              onTakePicture={picture => onSelectFileAttachments([picture])}
            /> :
            <EmojiPickerButton
              disabled={areToolbarButtonsDisabled}
              onSelect={handleEmojiSelect}
            />
          }
          {!areToolbarButtonsDisabled && buttons}
          {(text.trim() || fileAttachments.length || !canRecordAudio) ? (
            <SendButton
              className={styles.sendButton}
              disabled={!isSendButtonEnabled}
              onClick={sendTextChatMessage}
            />
          ) : (
            <AudioRecorderButton
              elapsedTime={audioRecorderElapsedTime}
              isRecordingAudio={isRecordingAudio}
              onStartRecordingAudio={() => setIsRecordingAudio(true)}
              onStopRecordingAudio={audioFile => {
                setIsRecordingAudio(false)
                setAudioRecorderElapsedTime(0)
                onSelectFileAttachments([audioFile])
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const ChatMessageTextField = ({
  disabled = false,
  inputRef = undefined,
  onChange = () => {},
  onKeyPress = () => {},
  placeholder = '',
  FormHelperTextProps = undefined,
  value = '',
  helperText,
}: Partial<TextFieldProps>) => {
  const styles = useStyles()
  return (
    <TextField
      FormHelperTextProps={FormHelperTextProps}
      InputProps={{disableUnderline: true}}
      className={styles.messageTextField}
      disabled={disabled}
      fullWidth={true}
      helperText={helperText}
      inputRef={inputRef}
      maxRows={3}
      minRows={1}
      multiline={true}
      onChange={onChange}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      value={value}
      variant="standard"
    />
  )
}

const ExpiredReplyWindowNotification = () => {
  const translate = useTranslate()
  return (
    <Notification>
      <Typography variant="caption">
        {translate('chat.external.toolbar.expiredReplyWindowNotification.' +
          'message.magicTemplate'
        )}
      </Typography>
    </Notification>
  )
}

const Notification = ({children}) => {
  const styles = useStyles()
  return (
    <div className={styles.notification}>
      <InfoIcon className={styles.notificationIcon} color="info" />
      <div>
        {children}
      </div>
    </div>
  )
}

const FileAttachments: FC<FileAttachmentsProps> = ({disabled, onChange, value}) => {
  const styles = useStyles()
  return useMemo(() => (
    <div className={styles.fileAttachments}>
      {value?.map((fileAttachment, index) => {
        // For smaller displays and long filenames, we always want to show
        // the file extensions of the filename so that we only abbreviate
        // the filename's base name. We do so because the user might have
        // multiple file formats of the same document and in case of too
        // long filenames they want to assert themselves that they have
        // picked the correct file format, i.e. `.pdf` instead of `.docx`
        const {filename} = fileAttachment
        const segments = filename.split('.')
        const extension = (segments.length > 1) && segments.pop()
        const baseFilename = extension ?
          filename.slice(0, -(extension.length + 1)) : filename
        return fileAttachment.mimeType.includes('audio') ? (
          <AudioPlayer
            className={styles.voiceFileAttachment}
            disabled={disabled}
            fileAttachmentUrl={URL.createObjectURL(fileAttachment.file)}
            onDelete={() => onChange(value.filter(a => a !== fileAttachment))}
          />
        ) : (
          <Chip
            className={styles.fileAttachmentChip}
            disabled={disabled}
            icon={<AttachmentIcon />}
            key={index}
            label={<>
              <span>{baseFilename}</span>
              <span>{extension ? `.${extension}` : null}</span>
            </>}
            onDelete={() => onChange(value.filter(a => a !== fileAttachment))}
          />
        )
      })}
    </div>
  ), [disabled, onChange, styles, value])
}

const CHAT_QUERY = gql`
  query($id: uuid!) {
    chats_by_pk(id: $id) {autoTranslationLanguage id isProductNews}
  }
`

const useStyles = makeStyles(theme => ({
  audioRecorderElapsedTime: {
    color: theme.palette.info.main,
  },
  buttonsContainer: {
    '& button:last-of-type': {
      marginLeft: 'auto',
    },
    alignItems: 'center',
    cursor: 'text',
    display: 'flex',
    height: theme.remSpacing(6),
    margin: `0 ${theme.remSpacing(0.5)}`,
  },
  fileAttachmentChip: {
    '& > .MuiChip-label': {
      '& > span:first-child': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      display: 'inline-flex', // Enforces the first <span> to be width-constrained
    },
    height: 'fit-content',
    maxWidth: '100%',
  },
  fileAttachments: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.remSpacing(0.5),
    paddingLeft: theme.remSpacing(1.5),
    paddingRight: theme.remSpacing(1.5),
  },
  messageInput: {
    '& textarea.MuiInputBase-input': {
      backgroundColor: 'transparent',
      border: '0',
      padding: '0 !important',
    },
    padding: theme.remSpacing(2),
    paddingBottom: 0,
    paddingTop: 0,
  },
  messageTextField: {
    backgroundColor: 'transparent',
    border: '0',
  },
  notification: {
    alignItems: 'center',
    background: theme.palette.info.light,
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: '8px',
    display: 'flex',
    margin: theme.remSpacing(2),
    marginBottom: theme.remSpacing(1),
    padding: theme.remSpacing(2),
  },
  notificationIcon: {
    marginRight: theme.remSpacing(1),
  },
  notificationLink: {
    color: theme.palette.info.main,
    cursor: 'pointer',
  },
  root: {
    backgroundColor: theme.palette.background.default,
  },
  sendButton: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto !important',
    },
  },
  toolbarContainer: {
    '&:focus-within': {
      borderColor: 'blue', // TODO: Use color from palette
    },
    backgroundColor: 'white',
    border: '2px solid',
    borderColor: theme.palette.disabled.main,
    borderRadius: '8px',
    margin: theme.remSpacing(2),
    marginTop: '0',
    transition: 'border-color 0.2s',
  },
  voiceFileAttachment: {
    backgroundColor: theme.palette.chatBubble.main,
  },
}))

interface ChatToolbarProps {
  ChatToolbarModifier?: FC<any>,
  buttons: ReactNode,
  chatId: string,
  fileAttachments: FileAttachment[],
  isInsertingChatMessage?: boolean,
  isReplyWindowExpired?: boolean,
  isTemplateDialogOpen?: boolean,
  onChangeTemplateDialogOpen: (isOpen: boolean) => void
  onChangeText: (text: string) => void
  onFilesButtonClick: () => void
  onSelectFileAttachments: (attachments: FileAttachment[]|CameraFileAttachment[]) => void
  onSendTemplateChatMessage: OnSendTemplateChatMessage
  onSendTextChatMessage: () => Promise<void>
  text: string
}

interface FileAttachmentsProps {
  disabled?: boolean
  onChange: (attachments: FileAttachment[]|CameraFileAttachment[]) => void
  value: FileAttachment[]
}

export default ChatToolbar
