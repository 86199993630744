import {Keyboard, KeyboardPlugin} from '@capacitor/keyboard'
import {createContext, useEffect, useState} from 'react'

import {isNativeIosApp} from '../utils/platform'

const MobileKeyboardContext = createContext<MobileKeyboardState>({
  isKeyboardOpen: false,
  keyboard: Keyboard,
})

const MobileKeyboardProvider = ({children}) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)
  useEffect(() => {
    if (!isNativeIosApp) return
    const onShowKeyboard = () => {
      setIsKeyboardOpen(true)
      document.activeElement?.scrollIntoView({block: 'end'})
    }
    const onHideKeyboard = () => {
      setIsKeyboardOpen(false)
    }
    Keyboard.addListener('keyboardDidShow', onShowKeyboard)
    Keyboard.addListener('keyboardDidHide', onHideKeyboard)
    return () => {
      Keyboard.removeAllListeners()
    }
  }, [setIsKeyboardOpen])
  return (
    <MobileKeyboardContext.Provider value={{isKeyboardOpen, keyboard: Keyboard}}>
      {children}
    </MobileKeyboardContext.Provider>
  )
}

interface MobileKeyboardState {
  isKeyboardOpen: boolean
  keyboard: KeyboardPlugin,
}

export {MobileKeyboardContext, MobileKeyboardProvider}
