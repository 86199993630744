import {Capacitor} from '@capacitor/core'

const platform = Capacitor.getPlatform()

const isNativeAndroidApp = platform === 'android'
const isNativeIosApp = platform === 'ios'
const isNativeMobileApp = isNativeAndroidApp || isNativeIosApp

export {isNativeAndroidApp, isNativeIosApp, isNativeMobileApp, platform}
export default platform
