import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

import {isEmbedded, isEmbeddedMobileDisplay} from '../utils/embedded'

export default createBreakpoints({
  values: {
    lg: 992,
    md: 768,
    sm: 576,
    xl: 1200,
    xs: 0,
    // We override the breakpoints in embedded view because we want to enforce the mobile
    // design in all screen widths when the app is embedded. By using values larger than
    // conventional screen widths, we force the lowest breakpoint 'xs' to be used by
    // all media queries in the app. The iframe user enable is by add
    // ?flinkit-embedded-display=mobile in the iframe URL or in the parent iframe URL.
    ...(isEmbedded && isEmbeddedMobileDisplay && Object.fromEntries(
      ['lg', 'md', 'sm', 'xl', 'xs'].map(b => [b, 10000])
    )),
  },
})
