import {ChatMessages, WhatsappMessageStatusesEnum} from '../types/graphqlSchema'

const displayChatMessageStatus = (chatMessage: ChatMessages) => {
  const whatsappMessageStatus =
    chatMessage.whatsappMessagesStatus as WhatsappMessageStatusesEnum
  if (chatMessage.chatMessageTemplate?.type !== 'MAGIC') return whatsappMessageStatus
  const magicTemplateStatus =
    chatMessage.chatMessageTemplate.whatsappMessageTemplates[0].status
  if (magicTemplateStatus === 'CREATED') {
    return WhatsappMessageStatusesEnum.Sending
  }
  if (
    ['PENDING', 'SENDING', null].includes(whatsappMessageStatus) &&
    ['PENDING', 'APPROVED'].includes(magicTemplateStatus)
  ) {
    return WhatsappMessageStatusesEnum.Sent
  }
  if (magicTemplateStatus === 'REJECTED') return WhatsappMessageStatusesEnum.Failed
  return whatsappMessageStatus
}

export default displayChatMessageStatus
