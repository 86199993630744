import {Button, Card, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslate} from 'react-admin'
import {useHistory} from 'react-router-dom'

import NotFoundIllustration from '../assets/imgs/notFoundIllustration.png'

const NotFoundPage = () => (
  <Card className={useStyles().root}>
    <img
      alt="not-found-illustration"
      className={useStyles().notFoundImage}
      src={NotFoundIllustration}
    />
    <Typography variant="subtitle1">
      {useTranslate()('pages.notFound.title')}
    </Typography>
    <Typography color="textSecondary">
      {useTranslate()('pages.notFound.message')}
    </Typography>
    <Button onClick={useHistory().goBack} variant="contained">
      {useTranslate()('ra.action.back')}
    </Button>
  </Card>
)

const useStyles = makeStyles(theme => ({
  notFoundImage: {
    marginBottom: theme.remSpacing(8),
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.remSpacing(1),
    height: '100%',
    justifyContent: 'center',
  },
}))

export default NotFoundPage
