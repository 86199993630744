import {Box, Typography} from '@material-ui/core'
import {Children, cloneElement, FC, ReactChild} from 'react'

// TODO: Instead of passing subtitle explicitly, get it implicitly from translations
//       by appending the suffix 'Subtitle' to the resource field, i.e.:
//       useTranslate(`resources.${resource}.fields.${source}Subtitle`)
const SubtitledInput: FC<SubtitledInputProps> = ({children, source, subtitle}) => (
  <Box display="flex" flexDirection="column">
    <Typography color="textSecondary" variant="caption">{subtitle}</Typography>
    {Children.map(children, ((c: any) => cloneElement(c, {source})))}
  </Box>
)

interface SubtitledInputProps {
  addLabel?: boolean,
  children: ReactChild | ReactChild[],
  source: string,
  subtitle: string
}

export default SubtitledInput
