import {useQuery} from '@apollo/client'
import gql from 'graphql-tag'
import {useEffect} from 'react'

import {QueryRoot} from '../../types/graphqlSchema'
import useSessionMerchantUser from '../useSessionMerchantUser'

const useGetUnreadMessageCount = ({disabled = false} = {}) => {
  const {merchantUser: {id: sessionMerchantUserId} = {}} = useSessionMerchantUser()
  const queryDocument = gql`query${UNREAD_CHAT_MESSAGES_QUERY}`
  const subscriptionDocument = gql`subscription${UNREAD_CHAT_MESSAGES_QUERY}`
  const {
    data: {merchant_users_by_pk: {
      unreadExternalChatMessagesCount = 0, unreadInternalChatMessagesCount = 0,
    } = {}} = {},
    subscribeToMore,
  } = useQuery<QueryRoot['merchant_users_by_pk']>(
    queryDocument,
    {skip: (disabled || !sessionMerchantUserId), variables: {sessionMerchantUserId}},
  )
  useEffect(
    () => {
      if (disabled || !sessionMerchantUserId) return
      return subscribeToMore({
        document: subscriptionDocument,
        updateQuery: (_, {subscriptionData: {data}}) => ({...data}),
        variables: {sessionMerchantUserId},
      })
    },
    [disabled, subscribeToMore, sessionMerchantUserId, subscriptionDocument],
  )
  return unreadExternalChatMessagesCount + unreadInternalChatMessagesCount
}

const UNREAD_CHAT_MESSAGES_QUERY = `($sessionMerchantUserId: uuid!){
  merchant_users_by_pk(id: $sessionMerchantUserId) {
    id
    unreadExternalChatMessagesCount
    unreadInternalChatMessagesCount
  }
}`

export default useGetUnreadMessageCount
