import {App} from '@capacitor/app'
import {useEffect, useState} from 'react'

import {getGraphqlApiUrl} from '../contexts/ApiUrlsContext'
import env from '../utils/env'
import {isNativeMobileApp} from '../utils/platform'

// TODO: Move this hook directly into ApiUrlsContext.jsx as it is only used there
//       * Also store again all entire service hostnames instead of the ports
const useBaseHostname = () => {
  const [baseHostname, setBaseHostname] = useState(env('BASE_HOSTNAME'))
  useEffect(() => {
    if (!isNativeMobileApp) return
    (async () => {
      const reviewAppBaseHostName = await getReviewAppBaseHostName()
      if (reviewAppBaseHostName) setBaseHostname(reviewAppBaseHostName)
    })()
  }, [])
  return baseHostname
}

const getReviewAppBaseHostName = async () => {
  const {version} = await App.getInfo()
  let response: Response
  try {
    response = await fetch(
      getGraphqlApiUrl(env('BASE_HOSTNAME')),
      {
        body: JSON.stringify({query: MOBILE_APP_RELEASE_QUERY, variables: {version}}),
        headers: {'content-type': 'application/json'},
        method: 'POST',
      },
    )
  }
  catch (error) {
    // User is most likely offline
    console.error(error)
    return
  }
  if (!response.ok) {
    console.error(`Failed to fetch mobile app release: ${response.status}`)
    return
  }
  const {
    data: {mobile_app_releases: [mobileAppRelease]} = {mobile_app_releases: []},
    errors,
  } = await response.json()
  if (errors?.length) {
    console.error(`Failed to fetch mobile app releases: ${JSON.stringify(errors)}`)
    return
  }
  if (!mobileAppRelease) {
    console.error(`Did not find mobile app release for version: ${version}`)
    return
  }
  // If the app is currently being reviewed by the app stores then it should be using
  // the review api url assigned to it. This is so that we can submit apps for review
  // with the api undergoing schema changes that we haven't deployed yet to production
  if (
    (mobileAppRelease.status === 'REVIEWING') &&
    (mobileAppRelease.reviewBaseHostname)
  ) {
    return mobileAppRelease.reviewBaseHostname
  }
}

const MOBILE_APP_RELEASE_QUERY = `query($version: String!){
  mobile_app_releases(where: {version: {_eq: $version}}){
    reviewBaseHostname
    status
  }
}`

export default useBaseHostname
export {getReviewAppBaseHostName}
