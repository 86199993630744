import {useMutation, useQuery, useSubscription} from '@apollo/client'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {FC, useCallback, useMemo, useState} from 'react'
import {
  regex, required, SimpleForm, TextInput, useLocale, useNotify, useRedirect, useTranslate,
} from 'react-admin'
import {QRCode} from 'react-qrcode-logo'

import AppIconLogoSvg from '../../assets/imgs/appIconLogo.svg'
import WhatsappLogoSvg from '../../assets/imgs/whatsappLogo.svg'
import useIsDesktop from '../../hooks/useIsDesktop'
import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import theme from '../../theme'
import {
  ChatMessagesEventLogs, QueryRoot, SubscriptionRoot, WhatsappAccounts,
} from '../../types/graphqlSchema'
import {PHONE_NUMBER_REGEX} from '../../utils/consts'
import {hashIdFromUuid} from '../../utils/uuid'
import DialogCloseButton from '../DialogCloseButton'
import ExtendedAlert from '../ExtendedAlert'
import ExtendedDialog from '../ExtendedDialog'

const SandboxSettings = () => {
  const styles = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const notify = useNotify()
  const translate = useTranslate()
  const [requestSandboxConversion, {loading}] = useMutation(
    REQUEST_SANDBOX_CONVERSION_MUTATION,
    {
      onCompleted: () => notify('sandboxSettings.requestConversion.response', 'success'),
    }
  )
  return (
    <div className={styles.root}>
      <SandboxCardItem
        actionButton={
          <Button color="default" onClick={() => setIsOpen(true)} variant="outlined">
            {translate('sandboxSettings.testSandbox.button')}
          </Button>
        }
        icon={<WhatsappLogoSvg />}
        subtitle={translate('sandboxSettings.testSandbox.subtitle')}
        title={translate('sandboxSettings.testSandbox.title')}
      />
      <SandboxCardItem
        actionButton={
          <Button
            color="default"
            disabled={loading}
            onClick={() => requestSandboxConversion()}
            variant="outlined"
          >
            {loading ?
              <CircularProgress size={20} /> :
              translate('sandboxSettings.requestConversion.button')
            }
          </Button>
        }
        icon={<WhatsappLogoSvg />}
        subtitle={translate('sandboxSettings.requestConversion.subtitle')}
        title={
          <>
            {translate('sandboxSettings.requestConversion.title')}
            <Typography className={styles.titleCaption} variant="caption">
              {translate('sandboxSettings.requestConversion.caption')}
            </Typography>
          </>
        }
      />
      <SandboxCardItem
        actionButton={
          <Button
            color="default"
            href="mailto:support@flinkit.de"
            target="_blank"
            variant="outlined"
          >
            {translate('sandboxSettings.contactUs.button')}
          </Button>
        }
        icon={<AppIconLogoSvg />}
        subtitle={translate('sandboxSettings.contactUs.subtitle')}
        title={translate('sandboxSettings.contactUs.title')}
      />
      <TestSandboxDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
}

const TestSandboxDialog = ({isOpen, onClose}) => {
  const styles = useStyles()
  const isDesktop = useIsDesktop()
  const redirect = useRedirect()
  const notify = useNotify()
  const locale = useLocale()
  const translate = useTranslate()
  const chatSubscriptionCursor = useMemo(() => new Date().toISOString(), [])
  const [customerUserPhoneNumber, setCustomerUserPhoneNumber] = useState('')
  const [hasSentChatMessage, setHasSentChatMessage] = useState(false)
  const {merchantUser} = useSessionMerchantUser()
  const {
    data: {
      whatsapp_accounts: [
        {channel, phoneNumber: merchantPhoneNumber} = {} as WhatsappAccounts,
      ] = [],
    } = {},
  } = useQuery<QueryRoot['whatsapp_accounts']>(WHATSAPP_ACCOUNTS_QUERY)
  const hashIdEncodedMerchantUserId = useMemo(() => {
    if (!(merchantUser && merchantUser.user)) return
    return hashIdFromUuid(merchantUser.user.id)
  }, [merchantUser])
  const whatsappUrl = useMemo(() => {
    if (!(hashIdEncodedMerchantUserId || merchantPhoneNumber)) return
    const text = translate(
      'sandboxSettings.testSandboxDialog.sandboxCodeMessage',
      {code: hashIdEncodedMerchantUserId},
    )
    return `https://wa.me/${merchantPhoneNumber}?text=${encodeURIComponent(text)}`
  }, [hashIdEncodedMerchantUserId, merchantPhoneNumber, translate])
  useSubscription<SubscriptionRoot['chat_messages_event_logs_stream']>(
    CHAT_MESSAGES_EVENT_LOGS_SUBSCRIPTION,
    {
      onSubscriptionData: ({subscriptionData: {data = {}}}) => {
        const {chat_messages_event_logs_stream: chatMessagesEventLogs} = data
        if (!chatMessagesEventLogs) return
        const [{chatMessage: {chat = undefined} = {}}] = (
          chatMessagesEventLogs as unknown as ChatMessagesEventLogs[]
        )
        onClose()
        notify(
          'sandboxSettings.testSandboxDialog.onIncomingChatMessageNotification',
          'info'
        )
        setTimeout(() => redirect(`/inbox/${chat?.channelId}/${chat?.id}`), 2000)
      },
      skip: !channel,
      variables: {cursor: chatSubscriptionCursor},
    }
  )
  const {
    data: {chat_message_templates: chatMessageTemplates} = {},
  } = useQuery<QueryRoot['chat_message_templates']>(CHAT_MESSAGE_TEMPLATE_QUERY)
  const welcomeTemplate = useMemo(() => chatMessageTemplates?.find(
    t => t.language === (merchantUser?.language ?? locale ?? 'de')
  ), [chatMessageTemplates, locale, merchantUser])
  const [mutate, {loading: isSendingChatMessage}] = useMutation(
    INSERT_CHAT_MESSAGE_MUTATION,
    {
      onCompleted: () => setHasSentChatMessage(true),
      onError: e => notify(
        e.graphQLErrors?.[0].extensions?.[0].code ===
          'CHAT_ALREADY_OWNED_BY_ANOTHER_SANDBOX_USER' ?
          'sandboxSettings.errorMessages.CHAT_ALREADY_OWNED_BY_ANOTHER_SANDBOX_USER' :
          'ra.message.error',
        'error'
      ),
    }
  )
  const insertChatMessage = useCallback(() => {
    const chatMessageTemplateValues = (
      welcomeTemplate?.chatMessageTemplatePlaceholders.map(
        ({id}) => ({chatMessageTemplatePlaceholderId: id, value: merchantUser?.firstName})
      )
    )
    mutate({
      variables: {
        chatMessageTemplateId: welcomeTemplate?.id,
        chatMessageTemplateValues,
        customerUserWhatsappPhoneNumber: customerUserPhoneNumber,
        merchantWhatsappPhoneNumber: merchantPhoneNumber,
      },
    })
  }, [
    mutate,
    merchantUser,
    welcomeTemplate,
    customerUserPhoneNumber,
    merchantPhoneNumber,
  ])
  const canSendChatMessage = (
    !!customerUserPhoneNumber &&
    PHONE_NUMBER_REGEX.test(customerUserPhoneNumber) &&
    !isSendingChatMessage
  )
  return (
    <ExtendedDialog
      className={styles.dialog}
      fullScreen={!isDesktop}
      keepMounted={false}
      onClose={onClose}
      open={isOpen}
    >
      <DialogContent>
        {!isDesktop && (
          <DialogTitle>
            <DialogCloseButton onClick={onClose}/>
          </DialogTitle>
        )}
        <Grid alignContent="center" alignItems="center" justifyContent="center">
          <Grid item>
            <Typography align="center" variant="h3">
              {translate('sandboxSettings.testSandboxDialog.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center" color="textSecondary" variant="body1">
              {translate('sandboxSettings.testSandboxDialog.subtitle1')}
            </Typography>
          </Grid>
          <Grid item>
            <Box alignItems="center" display="flex" justifyContent="center" m={1}>
              {whatsappUrl && (
                <QRCode
                  bgColor={theme.palette.background.paper}
                  fgColor={theme.palette.primary.main}
                  logoImage="../../assets/imgs/qrCodeLogo.png"
                  removeQrCodeBehindLogo
                  size={200}
                  value={whatsappUrl}
                />
              )}
            </Box>
          </Grid>
          <Grid item>
            <DividerWithText>
              <Typography align="center" variant="body1">
                {translate('sandboxSettings.testSandboxDialog.subtitle2')}
              </Typography>
            </DividerWithText>
          </Grid>
          <Grid item>
            {hasSentChatMessage ? (
              <Box mb={2}>
                <ExtendedAlert severity="success">
                  {translate('sandboxSettings.testSandboxDialog.onSentChatMessage')}
                </ExtendedAlert>
              </Box>
            ) : (
              <SimpleForm toolbar={<></>}>
                <TextInput
                  label={
                    translate('sandboxSettings.testSandboxDialog.fields.phoneNumber')
                  }
                  onChange={e => setCustomerUserPhoneNumber(e.target.value)}
                  source="phoneNumber"
                  validate={[
                    required(),
                    regex(PHONE_NUMBER_REGEX, translate('validations.phoneNumber')),
                  ]}
                />
                <Box alignItems="flex-end" display="flex" justifyContent="flex-end" m={1}>
                  <Button
                    color="primary"
                    disabled={!canSendChatMessage}
                    onClick={insertChatMessage}
                    variant="contained"
                  >
                    {isSendingChatMessage ?
                      <CircularProgress size={20} /> :
                      translate('sandboxSettings.testSandboxDialog.button')
                    }
                  </Button>
                </Box>
              </SimpleForm>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </ExtendedDialog>
  )
}

const DividerWithText = ({children, ...props}) => (
  <Grid alignItems="center" container spacing={1} {...props}>
    <Grid item xs>
      <Divider />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs>
      <Divider />
    </Grid>
  </Grid>
)

const SandboxCardItem: FC<SandboxCardItemProps> = ({
  actionButton, icon, subtitle, title,
}) => {
  const styles = useStyles()
  return (
    <Card className={styles.card}>
      <CardContent>
        <Grid alignItems="center" container direction="row" justifyContent="center">
          <Grid className={styles.cardIcon} item>{icon}</Grid>
          <Grid item md={8} xs={8}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body1">{subtitle}</Typography>
            <Hidden lgUp>
              <Grid container justifyContent="flex-end">
                {actionButton}
              </Grid>
            </Hidden>
          </Grid>
          <Hidden mdDown>
            <Grid
              className={styles.cardActionButton}
              container
              item
              justifyContent="flex-end"
              md={4}
              xs={4}
            >
              {actionButton}
            </Grid>
          </Hidden>
        </Grid>
      </CardContent>
    </Card>
  )
}

const WHATSAPP_ACCOUNTS_QUERY = gql`query{whatsapp_accounts{channel{id} id phoneNumber}}`
const CHAT_MESSAGES_EVENT_LOGS_SUBSCRIPTION = gql`
  subscription($cursor: timestamptz) {
    chat_messages_event_logs_stream(
      batch_size: 1
      cursor: {initial_value: {insertionTimestamp: $cursor} ordering: ASC}
      where: {
        chatMessage: {
          chatMessageWhatsappMessages: {whatsappMessage: {direction: {_eq: INBOUND}}}
        }
      }
    ){id chatMessage{chat{channelId id} id}}
  }
`
const CHAT_MESSAGE_TEMPLATE_QUERY = gql`
  query{
    chat_message_templates(
      where: {
        whatsappMessageTemplates: {
          name: {
            _ilike: "%connected_to_flinkit_whatsapp_test_sandbox%"
          }
        }
      }
    ) {
      chatMessageTemplatePlaceholders{id index}
      id
      language
    }
  }
`
const INSERT_CHAT_MESSAGE_MUTATION = gql`
  mutation(
    $chatMessageTemplateId: uuid
    $customerUserWhatsappPhoneNumber: String
    $merchantWhatsappPhoneNumber: String
    $chatMessageTemplateValues: [ChatMessageTemplateValue]
  ) {
    insertChatMessage(
      chat: {
        merchantWhatsappPhoneNumber: $merchantWhatsappPhoneNumber
        customerUserWhatsappPhoneNumber: $customerUserWhatsappPhoneNumber
      }
      chatMessageTemplateId: $chatMessageTemplateId
      chatMessageTemplateValues: $chatMessageTemplateValues
      type_: "TEMPLATE"
    ){chatMessageId chatMessage{chatId id}}
  }
`

const REQUEST_SANDBOX_CONVERSION_MUTATION = gql`mutation{requestSandboxConversion}`

interface SandboxCardItemProps {
  actionButton: JSX.Element,
  icon: JSX.Element,
  subtitle: string,
  title: string | React.ReactNode,
}

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiButton-label': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '& .MuiButton-root': {
        borderRadius: '0.375rem',
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 400,
        lineHeight: theme.typography.pxToRem(16),
        padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(8)}`,
      },
    },

    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
    '& .MuiGrid-container': {
      flexWrap: 'unset',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& .MuiGrid-item': {
      padding: 0,
    },
    '& .MuiTypography-body1': {
      color: theme.palette.secondary.main,
    },
    '& .MuiTypography-root:not(:last-child)': {
      marginBottom: theme.remSpacing(1),
    },
    border: `solid 1px ${theme.palette.disabled.main}`,
    flexWrap: 'unset',
    marginBottom: theme.remSpacing(3),
    marginTop: theme.remSpacing(3),
    padding: theme.remSpacing(3),
  },
  cardActionButton: {
    '&.MuiGrid-container': {
      margin: 0,
    },
  },
  cardIcon: {
    alignSelf: 'center',
    marginRight: theme.remSpacing(2),
  },
  dialog: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
        position: 'fixed',
        top: theme.typography.pxToRem(50),
      },
      '& .MuiDialogContent-root': {
        padding: `${theme.remSpacing(1)} ${theme.remSpacing(4)}`,
      },
    },
    '& .MuiTypography-root': {
      marginBottom: theme.remSpacing(3),
      marginTop: theme.remSpacing(3),
    },
  },
  root: {
    '& > .MuiTypography-root:nth-child(2)': {
      color: theme.palette.secondary.main,
    },
  },
  titleCaption: {
    color: theme.palette.warning.main,
    marginLeft: theme.remSpacing(2),
  },
}))

export default SandboxSettings
