import {useEffect, useState} from 'react'
import Favicon from 'react-favicon'
import {Helmet} from 'react-helmet'

import useGetUnreadMessageCount from '../hooks/chat/useGetUnreadMessageCount'
import {isNativeMobileApp} from '../utils/platform'

const AppFavicon = () => {
  const unreadMessageCount = useGetUnreadMessageCount({disabled: isNativeMobileApp})
  const [
    shouldRenderUnreadMessagesFavicon,
    setShouldRenderUnreadMessagesFavicon,
  ] = useState(false)
  useEffect(() => {
    if (isNativeMobileApp) return
    if (unreadMessageCount === 0) {
      setShouldRenderUnreadMessagesFavicon(false)
      return
    }
    setShouldRenderUnreadMessagesFavicon(true)
  }, [unreadMessageCount])
  const canvasSize = 14
  return (
    <div>
      <Favicon
        iconSize={canvasSize}
        keepIconLink={() => true}
        renderOverlay={(_: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
          if (!shouldRenderUnreadMessagesFavicon) return
          // Draw the red dot
          ctx.fillStyle = 'red'
          ctx.beginPath()
          // Position at the top right
          ctx.arc(
            canvasSize - (canvasSize * 0.2),
            canvasSize / 4.5,
            3,
            0,
            Math.PI * 2,
            true,
          )
          ctx.fill()
        }}
        url="/favicon.ico"
      />
      <Helmet defer={false}>
        <title>{unreadMessageCount > 0 ? `(${unreadMessageCount}) ` : ''}Flinkit</title>
      </Helmet>
    </div>
  )
}

export default AppFavicon
