import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Skeleton} from '@material-ui/lab'
import _ from 'lodash'
import {FC} from 'react'
import {useTranslate} from 'react-admin'
import {useHistory, useLocation} from 'react-router-dom'

import {useCompileChatMessageText} from '../../hooks/useCompileChatMessageText'
import {useDelayRemoveChatMessageIdSearchParam} from '../../hooks/useSearchParams'
import useSimplifyAge from '../../hooks/useSimplifyAge'
import {ChatMessages, Chats} from '../../types/graphqlSchema'
import {userInitials} from '../../utils/users'
import Empty from '../Empty'
import {FlagIcon} from '../icons'
import WordsHighlighter from '../WordsHighlighter'

const SearchInboxChatMessageList: FC<SearchInboxChatMessageListProps> = ({
  chatMessages,
  chatTitle: ChatTitle,
  isShowingAssignedMerchantUser,
  listTitle,
  loading,
  onChatMessageClicked,
  searchWords,
  ...props
}) => {
  const {pathname} = useLocation()
  const styles = useStyles()
  if (loading) return (
    <Box display="flex" flexDirection="column" gridGap="1rem" paddingX="1rem">
      {[...Array(6).keys()]
        .map(i => <Skeleton height="3.5rem" key={i} variant="rect" />)}
    </Box>
  )
  if (!chatMessages.length) return <Empty resource="chat_messages.search" />
  return (
    <List {...props}>
      {listTitle && (
        <Typography
          className={styles.listTitle}
          paragraph
          variant="subtitle2"
        >
          {listTitle}
        </Typography>
      )}
      {chatMessages.map(m => (
        <ChatMessageListItem
          {...{ChatTitle}}
          chatMessage={m}
          isShowingAssignedMerchantUser={isShowingAssignedMerchantUser}
          key={m.id}
          onChatMessageClicked={onChatMessageClicked}
          pathname={pathname}
          searchWords={searchWords}
        />
      ))}
    </List>
  )
}

const ChatMessageListItem: FC<ChatMessageListItemProps> = ({
  chatMessage,
  ChatTitle,
  isShowingAssignedMerchantUser,
  onChatMessageClicked,
  pathname,
  searchWords,
}) => {
  const translate = useTranslate()
  const history = useHistory()
  const simplifyAge = useSimplifyAge()
  const styles = useStyles()
  const {compileChatMessageText} = useCompileChatMessageText()
  const [, basePath] = pathname.split('/')
  const delayRemoveChatMessageIdSearchParam = useDelayRemoveChatMessageIdSearchParam()
  const path = `/${basePath}/${[
    chatMessage.chat.channelId, chatMessage.chatId, /* chatMessage.id, */
  ].filter(Boolean).join('/')}?chatMessageId=${chatMessage.id}`
  return (
    <ListItem
      button
      className={`
        ${styles.chatListItem}
        ${(isShowingAssignedMerchantUser && !chatMessage.chat.assignedMerchantUser) ?
        styles.isUnassignedExternalMultiMerchantUserChat : ''}
      `}
      onClick={() => {
        history.replace(path)
        onChatMessageClicked?.(chatMessage)
        delayRemoveChatMessageIdSearchParam()
      }}
    >
      <Grid className={styles.chatListItemGrid} container>
        {isShowingAssignedMerchantUser && (
          <Grid className={styles.leftComponentsGridItem} item>
            {
              _.first(chatMessage.chat.userChats)?.isFlagged && (
                <FlagIcon color="warning" size="small" />
              )
            }
            {chatMessage.chat.assignedMerchantUser && (
              <Chip
                className={styles.assignedMerchantUserChip}
                label={userInitials(chatMessage.chat.assignedMerchantUser)}
                size="small"
                style={{background: chatMessage.chat.assignedMerchantUser.userColor}}
              />
            )}
          </Grid>
        )}
        <Grid className={styles.chatMessageGridItem} item>
          <ListItemText
            className={styles.chatMessageListItemText}
            primary={ChatTitle ?
              <ChatTitle chat={chatMessage.chat} /> :
              <Typography>{simplifyAge(chatMessage.timestamp as string)}</Typography>
            }
            secondary={
              <WordsHighlighter
                searchWords={searchWords?.split(' ') ?? []}
                textToHighlight={compileChatMessageText(chatMessage) ||
                  translate('chat.chatList.withoutText')}
              />
            }
            secondaryTypographyProps={{
              variant: "caption",
            }}
          />
        </Grid>
        <Grid className={styles.chatMessageAgeGridItem} item>
          <Typography color="textSecondary" variant="caption">
            {simplifyAge(chatMessage.timestamp as string)}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  )
}

const useStyles = makeStyles(theme => ({
  assignedMerchantUserChip: {
    '& > span.MuiChip-label': {
      textOverflow: 'clip', // Overwrite default 'ellipsis' value
    },
    color: theme.palette.background.paper,
    cursor: 'inherit', // overwrite default behavior of `<Chip>`
    height: '18px',
    width: '32px',
    ...theme.typography.overline,
  },
  chatListItem: {
    borderTop: `1px solid ${theme.palette.disabled.main}`,
    display: 'flex',
    height: '3.75rem',
  },
  chatListItemGrid: {
    '& > .MuiGrid-item': {
      padding: 0,
    },
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: 0,
    width: '100%',
  },
  chatMessageAgeGridItem: {
    textAlign: 'end',
    width: '10rem',
  },
  chatMessageGridItem: {
    marginLeft: theme.remSpacing(1),
    overflow: 'hidden',
    width: '100%',
  },
  chatMessageListItemText: {
    '& .MuiListItemText-primary > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .MuiListItemText-secondary': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  isUnassignedExternalMultiMerchantUserChat: {
    '&:hover': {
      backgroundColor: '#FBEFEF',
    },
    backgroundColor: '#FBEFEF',
  },
  leftComponentsGridItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: theme.remSpacing(8),
  },
  listTitle: {
    color: theme.palette.info.main,
    marginLeft: theme.remSpacing(7),
  },
}))

interface SearchInboxChatMessageListProps {
  chatMessages: ChatMessages[]
  chatTitle?: FC<{chat: Chats}>
  className?: string
  isShowingAssignedMerchantUser?: boolean
  listTitle?: string
  loading: boolean
  onChatMessageClicked?: (chatMessage: ChatMessages) => void
  searchWords?: string
}

interface ChatMessageListItemProps {
  ChatTitle: FC<{chat: Chats}> | undefined
  chatMessage: ChatMessages
  isShowingAssignedMerchantUser?: boolean
  onChatMessageClicked?: (chatMessage: ChatMessages) => void
  pathname: string
  searchWords?: string
}

export default SearchInboxChatMessageList
