import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, useState} from 'react'
import {useTranslate} from 'react-admin'

import {ChatMessageTemplateValues} from '../../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../../utils/tracking'
import DialogCloseButton from '../../DialogCloseButton'
import ExtendedDialog from '../../ExtendedDialog'
import {AddIcon, SendIcon} from '../../icons'
import ChatMessageTemplateBuilder from '../ChatMessageTemplateBuilder'

const TemplateMessageComposerDialog: FC<TemplateMessageComposerDialogProps> = ({
  isOpen = false,
  onClose,
  onSendTemplateChatMessage,
}) => {
  const translate = useTranslate()
  const styles = useStyles()
  const [canSendTemplate, setCanSendTemplate] = useState(false)
  const [chatMessageTemplateId, setChatMessageTemplateId] = useState<string>()
  const [chatMessageTemplateValues, setChatMessageTemplateValues] =
    useState<ChatMessageTemplateValues[]>([])
  return (
    <ExtendedDialog
      maxWidth="md"
      onClose={onClose}
      open={isOpen}
      scroll="body"
    >
      <DialogTitle>{translate('dialogs.templateMessageComposer.title')}</DialogTitle>
      <DialogCloseButton className={styles.dialogCloseButton} onClick={onClose} />
      <DialogContent>
        {translate('dialogs.templateMessageComposer.subtitle')}
        <ChatMessageTemplateBuilder
          onChangePlaceholders={setChatMessageTemplateValues}
          onChangeTemplateId={setChatMessageTemplateId}
          onValidatePlaceholders={setCanSendTemplate}
          templateId={chatMessageTemplateId}
        />
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button
          color="primary"
          href="/chat_message_templates/create"
          onClick={() => trackEvent(
            'CREATE_TEMPLATE',
            EventCategory.TEMPLATE,
            'TEMPLATE_MESSAGE_COMPOSER_DIALOG',
          )}
          startIcon={<AddIcon />}
          variant="outlined"
        >
          {translate('resources.chat_message_templates.action.create')}
        </Button>
        <Button
          color="primary"
          disabled={!canSendTemplate}
          onClick={() => {
            chatMessageTemplateId && onSendTemplateChatMessage({
              chatMessageTemplateId: chatMessageTemplateId,
              chatMessageTemplateValues,
            })
            trackEvent('SEND_MESSAGE', EventCategory.CHAT)
          }}
          startIcon={<SendIcon />}
          variant="contained"
        >
          {translate('actions.send')}
        </Button>
      </DialogActions>
    </ExtendedDialog>
  )
}

const useStyles = makeStyles(theme => ({
  dialogActions: {
    paddingRight: theme.remSpacing(1.5),
  },
  dialogCloseButton: {
    [theme.breakpoints.up('lg')]: {
      right: theme.spacing(0),
    },
  },
}))

interface TemplateMessageComposerDialogProps {
  isOpen?: boolean
  onClose: () => void
  onSendTemplateChatMessage: OnSendTemplateChatMessage
}

export default TemplateMessageComposerDialog
