import {useLazyQuery, useQuery} from '@apollo/client'
import {Box, Button, Link as MuiLink, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {debounce} from 'lodash'
import {
  FC,
  Fragment,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {useGetIdentity, useTranslate} from 'react-admin'
import {Link, Redirect, useLocation, useParams} from 'react-router-dom'

import {Chat, ChatContext, ChatList, ChatPage} from '../../components/chat'
import ChatFilterTabs from '../../components/chat/ChatFilterTabs'
import ChatHeader from '../../components/chat/ChatHeader'
import ChatMessageSearchInboxSidebar, {
  ChatMessageSearchInboxSidebarRef,
} from '../../components/chat/ChatMessageSearchInboxSidebar'
import ChatSearchInboxInput from '../../components/chat/ChatSearchInboxInput'
import SearchInboxChatMessageList from '../../components/chat/SearchInboxChatMessageList'
import {SearchIcon, SettingsIcon} from '../../components/icons'
import WhatsappAccountDisconnectionAlert
  from '../../components/WhatsappAccountDisconnectionAlert'
import WordsHighlighter from '../../components/WordsHighlighter'
import {SandboxContext} from '../../contexts/SandboxContext'
import useChatsQueryAndSubscription from '../../hooks/chat/useChatsQueryAndSubscription'
import useHasUnreadChatMessages from '../../hooks/chat/useHasUnreadChatMessages'
import useIsShowingTranslations from '../../hooks/chat/useIsShowingTranslations'
import useFeatureLock from '../../hooks/useFeatureLock'
import useIsDesktop from '../../hooks/useIsDesktop'
import useMostRecentInboundChatMessageElapsedIntervals
  from '../../hooks/useMostRecentInboundChatMessageElapsedIntervals'
import useSearchParams from '../../hooks/useSearchParams'
import useSessionMerchantUser from '../../hooks/useSessionMerchantUser'
import {useUserDisplayName} from '../../hooks/useUserDisplayName'
import theme from '../../theme'
import {
  Chats,
  ChatTypesEnum,
  CustomerUsers,
  QueryRoot,
  Users,
} from '../../types/graphqlSchema'
import {REPLY_EXPIRATION_INTERVAL} from '../../utils/consts'
import formatPhoneNumber from '../../utils/phoneNumber'
import {isNativeMobileApp} from '../../utils/platform'
import {EventCategory, trackEvent} from '../../utils/tracking'
import ChannelSettingsDialog from './ChannelSettingsDialog'
import ChatAssignment from './ChatAssignment'
import ChatContextMenuButton from './ChatContextMenuButton'
import StartNewChatButton from './StartNewChatButton'

const ExternalChatPage = () => {
  const styles = useStyles(theme)
  const translate = useTranslate()
  const {searchParams} = useSearchParams()
  const {channelId, chatId} = useParams<{channelId?: string, chatId?: string}>()
  const chatMessageId = searchParams.chatMessageId || undefined
  const isDesktop = useIsDesktop()
  const {merchantUser: {id: sessionMerchantUserId} = {}} = useSessionMerchantUser()
  const {identity: {isImpersonatedUser} = {}} = useGetIdentity()
  const isSearchFeatureEnabled = useFeatureLock([/@flinkit.de$/i]) || isImpersonatedUser
  const {data: {channels_by_pk: channel} = {}} = useQuery<QueryRoot['channels_by_pk']>(
    CHANNEL_QUERY, {skip: !channelId, variables: {id: channelId}}
  )
  const {
    data: {channels: [firstChannel]} = {channels: []},
  } = useQuery<QueryRoot['channels']>(
    FIRST_CHANNEL_QUERY,
    {
      skip: !!channelId || !sessionMerchantUserId,
      variables: {merchantUserId: sessionMerchantUserId},
    }
  )
  const [
    {[chatId as string]: mostRecentInboundChatMessageElapsedInterval = null},
    {refresh: refreshMostRecentInboundChatMessageElapsedInterval},
  ] = useMostRecentInboundChatMessageElapsedIntervals({chatId})
  const isReplyWindowExpired = useMemo(
    () => (mostRecentInboundChatMessageElapsedInterval === null) ||
      (mostRecentInboundChatMessageElapsedInterval > REPLY_EXPIRATION_INTERVAL),
    [mostRecentInboundChatMessageElapsedInterval]
  )
  const hashValue = useLocation().hash.replace('#', '')
  const isMultiMerchantUserChannel = channel &&
    ((channel.channelMerchantUsers_aggregate?.aggregate?.count ?? 0) > 1)
  const chatFilters = isMultiMerchantUserChannel ?
    MULTI_USER_CHAT_FILTERS : SINGLE_USER_CHAT_FILTERS
  const chatFilter = chatFilters.find(f => f === hashValue) ?? chatFilters[0]
  const {
    data: {
      // @ts-ignore: Property 'firstMineChat' does not exist on type 'QueryResultWrapper
      firstMineChat: [firstMineChat], firstUnassignedChat: [firstUnassignedChat],
    } = {firstMineChat: [], firstUnassignedChat: []},
  } = useQuery<{data: {firstMineChat: Chats[], firstUnassignedChat: Chats[]}}>(
    FIRST_MINE_AND_FIRST_UNASSIGNED_CHAT_QUERY,
    {
      skip:
        !!chatId || !channelId || !sessionMerchantUserId || !isMultiMerchantUserChannel,
      variables: {channelId, sessionMerchantUserId},
    }
  )
  const [isSearchInboxVisible, setIsSearchInboxVisible] = useState(false)
  const chatsBoolExp = useMemo(() => (
    {
      channelId: {_eq: channelId},
      isArchived: {_eq: false},
      isSpam: {_eq: false},
      ...({
        all: {},
        archived: {isArchived: {_eq: true}},
        mine: {assignedMerchantUserId: {_eq: sessionMerchantUserId}},
        unassigned: {assignedMerchantUserId: {_is_null: true}},
      }[chatFilter]),
    }
  ), [channelId, chatFilter, sessionMerchantUserId])
  const {
    chats,
    fetchEarlierChats,
    hasEarlierChats,
    isFetchingMore,
    loading,
    onResetFetchEarlierChatsOffset,
  } = useChatsQueryAndSubscription(
    chatsBoolExp,
    CHAT_FIELDS,
    chatFilter,
    {
      // We want to check for "isMultiMerchantUserChannel === undefined" to prevent
      // fetching "all" chats filter when the proper value of
      // isMultiMerchantUserChannel isn't known yet.
      skip: !channelId || !sessionMerchantUserId ||
        (isMultiMerchantUserChannel === undefined) || isSearchInboxVisible,
    },
  )
  useEffect(
    // When the user switch to another filter tab we reinitialize the chat load offset.
    // We also scroll back to the top of the chat list to fix the issue where the
    // "fetch more" function was immediately called on switch.
    onResetFetchEarlierChatsOffset, [chatFilter, onResetFetchEarlierChatsOffset]
  )
  const {data: {chats_by_pk: selectedChat} = {}} = useQuery<QueryRoot['chats_by_pk']>(
    CHAT_QUERY, {skip: !chatId, variables: {chatId}}
  )
  const [
    isChannelSettingsDialogOpen, setIsChannelSettingsDialogOpen,
  ] = useState(false)
  const [
    isShowingTranslations,
    setIsShowingTranslations,
  ] = useIsShowingTranslations(chatId)
  const {isSandbox, shouldOnboard} = useContext(SandboxContext)
  const chatSearchInboxInputRef = useRef<HTMLInputElement>()
  const chatMessageSearchInboxSidebarRef = useRef<ChatMessageSearchInboxSidebarRef>()
  const [searchInboxText, setSearchInboxText] = useState("")
  const [
    // @ts-ignore
    // eslint-disable-next-line
    searchChats,
    {
      data: {chats: searchedChats = []} = {},
      loading: isSearchingChats,
    },
  ] = useLazyQuery<QueryRoot['chats']>(CHATS_INBOX_SEARCH_QUERY)
  const [
    searchChatMessages,
    {
      data: {chat_messages: searchedChatMessages = []} = {},
      loading: isSearchingChatMessages,
    },
  ] = useLazyQuery<QueryRoot['chat_messages']>(CHAT_MESSAGES_INBOX_SEARCH_QUERY)
  const debounceSearchChatsAndChatMessages = useMemo(
    () => debounce((text: string) => {
      if (!text.trim()) return
      const variables = {
        pattern: text.trim() ?
          `%${text.trim()
            .split(' ')
            // Eliminate empty strings(otherwise, the pattern always matches everything)
            .filter(Boolean)
            .join('%')
          }%` : '',
      }
      // searchChats({variables})
      // Because we are using a trigram index on the search inbox field
      // we start the message full text search only if the search pattern
      // is at least 3 characters length. Otherwise, a full database scan is done
      // and, we don't benefit from the trigram indexing.
      if (text.trim().length >= 3) searchChatMessages({variables})
    }, 500), [searchChatMessages])
  const [
    isShowingChatMessageSearchInboxSideBar,
    setIsShowingChatMessageSearchInboxSideBar,
  ] = useState(false)
  const isSearching = isSearchingChats || isSearchingChatMessages
  if (isSandbox && shouldOnboard) {
    return <Redirect to="/sandbox" />
  }
  if (!channelId && firstChannel) {
    return <Redirect to={`/inbox/${firstChannel.id}`} />
  }
  if (isDesktop && channelId && !chatId && (firstMineChat || firstUnassignedChat)) {
    // As no chat has been selected yet, we want to automatically redirect to the first
    // available chat.
    return <Redirect
      to={
        `/inbox/${channelId}/${(firstMineChat || firstUnassignedChat).id}#${
        firstMineChat ? 'mine' : 'unassigned'}`
      }
    />
  }
  if (
    isDesktop && channelId && !chatId && chats?.length &&
    (isMultiMerchantUserChannel === false)
  ) {
    return <Redirect to={`/inbox/${channelId}/${chats[0].id}#all`} />
  }
  return (
    <ChatPage
      chatListHeader={
        <>
          {channelId && (
            <WhatsappAccountDisconnectionAlert
              channelId={channelId}
              className={styles.whatsappAccountDisconnectionAlert}
            />
          )}
          {!isSearchInboxVisible && (
            <ChatListHeader
              channelId={channelId}
              chatFilter={chatFilter}
              chatFilters={chatFilters}
              isMultiMerchantUserChannel={!!isMultiMerchantUserChannel}
            />
          )}
        </>
      }
      chatMessageSearchInboxSideBar={chatId && (
        <ChatMessageSearchInboxSidebar
          chatId={chatId}
          onChatMessageClicked={() => {
            !isDesktop && setIsShowingChatMessageSearchInboxSideBar(false)
          }}
          onClose={() => setIsShowingChatMessageSearchInboxSideBar(false)}
          ref={chatMessageSearchInboxSidebarRef as MutableRefObject<any>}
        />
      )}
      isShowingChatMessageSearchInboxSideBar={isShowingChatMessageSearchInboxSideBar}
      searchInbox={isSearchInboxVisible && (
        <ChatSearchInboxInput
          inputRef={chatSearchInboxInputRef}
          isShowingArrowLeftIcon
          onChange={v => {
            setSearchInboxText(v)
            debounceSearchChatsAndChatMessages(v)
          }}
          onLeftArrowIconClicked={() => {
            setIsSearchInboxVisible(false)
            setSearchInboxText("")
          }}
          placeholder={translate('searchInbox.searchInAllChatsPlaceholder')}
          value={searchInboxText}
        />
      )}
      startNewChatButton={
        <StartNewChatButton channelId={channelId as string} disabled={!channelId} />
      }
      title={
        <Box alignItems="center" display="flex" justifyContent="space-between">
          {translate('chat.external.title')}
          <div>
            {isSearchFeatureEnabled && (
              <Button
                className={styles.channelSettingsButton}
                color="primary"
                onClick={() => {
                  setIsSearchInboxVisible(v => {
                    const isActivatingSearchInbox = !v
                    if (isActivatingSearchInbox) {
                      setTimeout(() => chatSearchInboxInputRef.current?.focus(), 0)
                    }
                    else {
                      setSearchInboxText("")
                    }
                    return isActivatingSearchInbox
                  })
                  trackEvent('ACTIVATE_CHAT_INBOX_SEARCH', EventCategory.CHAT)
                }}
                variant={isSearchInboxVisible ? 'contained' : 'outlined'}
              >
                <SearchIcon />
              </Button>
            )}
            <Button
              className={styles.channelSettingsButton}
              color="primary"
              onClick={() => {
                setIsChannelSettingsDialogOpen(true)
                trackEvent('OPEN_CHANNEL_SETTINGS', EventCategory.CHANNEL)
              }}
              variant="outlined"
            >
              <SettingsIcon />
            </Button>
          </div>
          <ChannelSettingsDialog
            channelId={channelId}
            onClose={() => setIsChannelSettingsDialogOpen(false)}
            open={isChannelSettingsDialogOpen}
          />
        </Box>
      }
    >
      <>
        {(!isSearchInboxVisible || (isSearchInboxVisible && searchInboxText)) && (
          <ChatList
            chatTitle={
              ({...props}) => (
                <ChatOwningUserDisplayName
                  {...props}
                  highlightWords={searchInboxText.trim()}
                />
              )
            }
            chats={isSearchInboxVisible ? searchedChats : (chats ?? [])}
            fetchMoreChats={fetchEarlierChats}
            hasMessagePreview
            hasMoreChats={hasEarlierChats}
            isChatSearchInbox={isSearchInboxVisible}
            isExternalMultiMerchantUserChat={!!isMultiMerchantUserChannel}
            isFetchingMore={isFetchingMore}
            listTitle={isSearchInboxVisible ?
              translate('searchInbox.chatsResultTitle').toUpperCase() :
              undefined
            }
            loading={(!isSearchInboxVisible && loading) || isSearching}
            rightComponent={ChatContextMenuButton}
            selectedChatId={chatId as string}
          />
        )}
        {isSearchInboxVisible && !isSearching && searchInboxText.length >= 3 && (
          <SearchInboxChatMessageList
            chatMessages={searchedChatMessages}
            chatTitle={({...props}) => (
              <ChatOwningUserDisplayName {...props} highlightWords="" />
            )}
            isShowingAssignedMerchantUser={!!isMultiMerchantUserChannel}
            listTitle={translate('searchInbox.messagesResultTitle').toUpperCase()}
            loading={false}
            searchWords={searchInboxText.trim()}
          />
        )}
      </>
      {!!chatId && (
        <ChatContext.Provider value={{channelId, chatId, chatMessageId}}>
          <Chat
            header={
              <ChatHeader
                isSpam={selectedChat?.isSpam}
                redirectTo={
                  !!selectedChat?.owningUser?.customerUser?.merchantCustomerUsers.length ?
                    `/merchant_customer_users/${
                      selectedChat.owningUser.customerUser.merchantCustomerUsers[0].id}` :
                    undefined
                }
                subtitle={<ChatSubtitle owningUser={selectedChat?.owningUser} />}
                title={<ChatTitle owningUser={selectedChat?.owningUser} />}
              >
                <Button
                  className={styles.chatMessageSearchInboxButton}
                  onClick={() => {
                    setIsShowingChatMessageSearchInboxSideBar(true)
                    setTimeout(
                      () => chatMessageSearchInboxSidebarRef.current
                        ?.focusSearchInboxInput(),
                      0,
                    )
                    trackEvent('ACTIVATE_CHAT_MESSAGE_INBOX_SEARCH', EventCategory.CHAT)
                  }}
                  size="small"
                  variant="text"
                >
                  <SearchIcon />
                </Button>
                {(isMultiMerchantUserChannel && selectedChat) && (
                  <ChatAssignment chat={selectedChat} className={styles.chatAssignment} />
                )}
                {selectedChat &&
                  <ChatContextMenuButton
                    chat={selectedChat}
                    isExternalMultiMerchantUserChat={!!isMultiMerchantUserChannel}
                  />
                }
              </ChatHeader>
            }
            isReplyWindowExpired={isReplyWindowExpired}
            isShowingTranslations={isShowingTranslations}
            onChangeIsShowingTranslations={setIsShowingTranslations}
            onExternalInboundChatMessageReceived={
              refreshMostRecentInboundChatMessageElapsedInterval
            }
            type={ChatTypesEnum.External}
          />
        </ChatContext.Provider>
      )}
    </ChatPage>
  )
}

const ChatListHeader: FC<ChatListHeaderProps> = ({
  channelId,
  chatFilter,
  chatFilters,
  isMultiMerchantUserChannel,
}) => {
  const styles = useStyles(theme)
  const hasMineUnreadChatMessages = useHasUnreadChatMessages({
    channelId,
    chatType: ChatTypesEnum.External,
    isMineOnly: true,
    skip: !isMultiMerchantUserChannel,
  })
  const hasUnassignedUnreadChatMessages = useHasUnreadChatMessages({
    channelId,
    chatType: ChatTypesEnum.External,
    isUnassignedOnly: true,
    skip: !isMultiMerchantUserChannel,
  })
  const hasUnreadChatMessages = useHasUnreadChatMessages({
    channelId,
    chatType: ChatTypesEnum.External,
    skip: isMultiMerchantUserChannel,
  })
  return (<>
    <ChatFilterTabs
      chatType={ChatTypesEnum.External}
      className={styles.chatFilterTabs}
      filters={chatFilters.map(f => ({
        hasUnreadChatMessages: {
          'all': hasUnreadChatMessages,
          'mine': hasMineUnreadChatMessages,
          'unassigned': hasUnassignedUnreadChatMessages,
        }[f],
        name: f,
      }))}
      value={chatFilter}
    />
  </>)
}

const ChatOwningUserDisplayName: FC<ChatOwningUserDisplayNameProps> = ({
  chat, highlightWords,
}) => {
  const styles = useStyles(theme)
  return (
    <Typography
      className={`
        ${styles.chatOwningUserDisplayName}
        ${chat.unreadMessageCount ? styles.unreadMessageItem : ''}
      `}
      color="textPrimary"
      variant="body1"
    >
      <ChatTitle highlightWords={highlightWords} owningUser={chat?.owningUser} />
    </Typography>
  )
}

const ChatTitle: FC<ChatTitleProps> = ({highlightWords, owningUser}) => {
  const styles = useStyles()
  const userDisplayName = useUserDisplayName()
  const hasMerchantCustomerUser =
    !!owningUser?.customerUser?.merchantCustomerUsers?.length
  const displayName = userDisplayName(owningUser)?.trim()
  return (
    <span className={`${styles.chatTitle} notranslate`}>
      {(hasMerchantCustomerUser && displayName) ? (
        <WordsHighlighter
          searchWords={highlightWords?.split(' ')}
          textToHighlight={displayName}
        />
      ) : (
        <>
          <WordsHighlighter
            searchWords={highlightWords?.split(' ')}
            textToHighlight={
              formatPhoneNumber(owningUser?.customerUser?.whatsappPhoneNumber) ?? ''
            }
          />
          <small>
            ~
            <WordsHighlighter
              searchWords={highlightWords?.split(' ')}
              textToHighlight={displayName ?? ''}
            />
          </small>
        </>
      )}
    </span>
  )
}

const ChatSubtitle = ({owningUser}) => {
  const hasMerchantCustomerUser = !!owningUser?.customerUser.merchantCustomerUsers.length
  return (
    hasMerchantCustomerUser ?
      <MerchantCustomerUserSubtitle value={owningUser?.customerUser} /> :
      <CustomerUserSubtitle value={owningUser?.customerUser} />
  )
}

const MerchantCustomerUserSubtitle: FC<MerchantCustomerUserSubtitleProps> = ({value}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const Divider = useMemo(
    () => () => <span className={styles.merchantCustomerUserSubtitleDivider}></span>
    , [styles])
  const data = [
    value.merchantCustomerUsers[0].companyName?.trim(),
    value.merchantCustomerUsers[0].role?.trim(),
    value.merchantCustomerUsers[0].customerCode?.trim() &&
      `${
        translate('chat.external.header.merchantCustomerUserSubtitle.customerCodePrefix')
      } ${value.merchantCustomerUsers[0].customerCode}`,
    value.merchantCustomerUsers[0].companyCity?.trim(),
  ].filter(Boolean).filter((_, i) => i < 3)
  return (
    <div className={styles.merchantCustomerUserSubtitle}>
      {data.map((v, i) => (
        <Fragment key={i}>{i > 0 && <Divider />}<span>{v}</span></Fragment>
      ))}
    </div>
  )
}

const CustomerUserSubtitle = ({value}) => (
  <MuiLink
    className={useStyles().contactLink}
    component={Link}
    to={
      `/merchant_customer_users/create?whatsappPhoneNumber=${
        encodeURIComponent(value?.whatsappPhoneNumber ?? '')
      }&firstName=${encodeURIComponent(value?.whatsappDisplayName ?? '')}`
    }
  >
    {useTranslate()('chat.external.header.saveContact')}
  </MuiLink>
)

const EmbeddedExternalChatPage = ({channelId, chatId}) => {
  const translate = useTranslate()
  const styles = useStyles(theme)
  const [
    {[chatId]: mostRecentInboundChatMessageElapsedInterval},
    {refresh: refreshMostRecentInboundChatMessageElapsedInterval},
  ] = useMostRecentInboundChatMessageElapsedIntervals({chatId})
  const {
    data: {chats_by_pk: chat} = {}, loading: isLoadingChats,
  } = useQuery<QueryRoot['chats_by_pk']>(
    CHAT_QUERY, {variables: {chatId}}
  )
  const {data: {channels_by_pk: channel} = {}} = useQuery<QueryRoot['channels_by_pk']>(
    CHANNEL_QUERY, {variables: {id: channelId}}
  )
  const isMultiMerchantUserChannel = (
    channel?.channelMerchantUsers_aggregate?.aggregate?.count ?? 0
  ) > 1
  const isReplyWindowExpired = useMemo(
    () => !mostRecentInboundChatMessageElapsedInterval ||
      (mostRecentInboundChatMessageElapsedInterval > REPLY_EXPIRATION_INTERVAL),
    [mostRecentInboundChatMessageElapsedInterval]
  )
  // TODO: `useCallback` has been used assuming that it's needed to stabilize the
  // reference in `openChatInNewWindow` and prevent rerenderings of the `Chat` component.
  // Tests are needed to validate if `useCallback` is really needed in order to prevent
  // such rerenderings
  const openChatInNewWindow = useCallback(() => {
    window.open(`/inbox/${channelId}/${chatId}`, '_blank')
  }, [channelId, chatId])
  const [
    isShowingTranslations,
    setIsShowingTranslations,
  ] = useIsShowingTranslations(chatId)
  if (isLoadingChats || !chat) return null
  return (
    <>
      <Button
        className={styles.openChatInNewWindowButton}
        color="primary"
        onClick={openChatInNewWindow}
        variant="contained"
      >
        {translate('pages.startExternalChat.embeddedExternalChatPage.openInFlinkit')}
      </Button>
      <ChatContext.Provider value={{channelId, chatId}}>
        <Chat
          header={
            <ChatHeader
              redirectTo={
                !!chat?.owningUser?.customerUser?.merchantCustomerUsers.length ?
                `/merchant_customer_users/${
                  chat.owningUser.customerUser.merchantCustomerUsers[0].id}` :
                  undefined
              }
              subtitle={
                (
                  !!chat.owningUser?.customerUser?.merchantCustomerUsers.length
                ) && (<ChatSubtitle owningUser={chat.owningUser} />)
              }
              title={<ChatTitle owningUser={chat.owningUser} />}
            >
              {isMultiMerchantUserChannel && (
                <ChatAssignment chat={chat} className={styles.chatAssignment} />
              )}
            </ChatHeader>
          }
          isReplyWindowExpired={isReplyWindowExpired}
          isShowingTranslations={isShowingTranslations}
          onChangeIsShowingTranslations={setIsShowingTranslations}
          onExternalInboundChatMessageReceived={
            refreshMostRecentInboundChatMessageElapsedInterval
          }
          type={ChatTypesEnum.External}
        />
      </ChatContext.Provider>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  channelSettingsButton: {
    ...(isNativeMobileApp && {
      '&:hover': {
        '& .MuiSvgIcon-root': {
          color: theme.palette.info.main,
        },
      },
    }),
  },
  chatAssignment: {
    maxWidth: theme.typography.pxToRem(168),
  },
  chatFilterTabs: {
    marginTop: theme.remSpacing(1),
  },
  chatHeaderSettings: {
    borderTop: '0.025rem solid #CDD5DF',
    // TODO: Use the right components+props to achieve desired layouting, don't 100%
    width: '100%',
  },
  chatMessageSearchInboxButton: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
    borderRadius: '50px',
  },
  chatOwningUserDisplayName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  chatTitle: {
    '& small': {
      color: theme.palette.text.secondary,
      marginLeft: theme.remSpacing(1),
      whiteSpace: 'nowrap',
      ...theme.typography.body2,
    },
    display: 'inline',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  contactLink: {
    display: 'inline-block',
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  logo: {
    '& svg': {
      height: theme.typography.pxToRem(32),
      width: theme.typography.pxToRem(32),
    },
    display: 'inline-block',
    transform: `translateY(${theme.remSpacing(-2)})`,
  },
  merchantCustomerUserSubtitle: {
    '& $merchantCustomerUserSubtitleDivider': {
      borderLeft: '1px solid #ccc',
      display: 'inline-block',
      height: '2.5ch',
      marginLeft: theme.remSpacing(1),
      marginRight: theme.remSpacing(1),
    },
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.remSpacing(0.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  merchantCustomerUserSubtitleDivider: {},
  openChatInNewWindowButton: {
    left: theme.remSpacing(3),
    position: 'absolute',
    top: theme.remSpacing(11),
    zIndex: 1004,
  },
  subtitleTooltip: {
    maxWidth: theme.typography.pxToRem(250),
    padding: theme.remSpacing(1.5),
  },
  unreadMessageItem: {
    fontWeight: theme.typography.subtitle2.fontWeight,
  },
  whatsappAccountDisconnectionAlert: {
    marginLeft: theme.remSpacing(2),
    marginRight: theme.remSpacing(2),
  },
}))

const MULTI_USER_CHAT_FILTERS: ChatFilter[] = ['mine', 'unassigned', 'all', 'archived']
const SINGLE_USER_CHAT_FILTERS: ChatFilter[] = ['all', 'archived']

const CHANNEL_QUERY = gql`query($id: uuid!){
  channels_by_pk(id: $id){channelMerchantUsers_aggregate{aggregate{count}} id}
}`

const FIRST_CHANNEL_QUERY = gql`query($merchantUserId: uuid!){
  channels(
    limit: 1,
    order_by: {name: asc}
    where: {channelMerchantUsers: {merchantUserId: {_eq: $merchantUserId}}}
  ){id}
}`

const CHAT_FIELDS = `
  assignedMerchantUser{
    firstName
    id
    lastName
    userColor
  }
  channelId
  chatMessages(
    limit: 1
    order_by: {insertionTimestamp: desc}
    where: {whatsappMessagesStatus: {_neq: CANCELED}}
  ){
    chatMessageTemplate{id text}
    chatMessageTemplateValues{chatMessageTemplatePlaceholder{index type} value}
    id
    text
    timestamp
  }
  hasUnreadFailedChatMessage
  id
  insertionTimestamp
  isArchived
  isSpam
  lastActivityTimestamp
  owningUser{
    customerUser{
      id
      merchantCustomerUsers{
        companyName companyCity customerCode firstName id lastName role
      }
      whatsappDisplayName
      whatsappPhoneNumber
    }
    id
  }
  type
  unreadMessageCount
  userChats{chatId isFlagged}
`

const CHAT_QUERY = gql`query($chatId: uuid!){
  chats_by_pk(id: $chatId){${CHAT_FIELDS}}
}`

const FIRST_MINE_AND_FIRST_UNASSIGNED_CHAT_QUERY = gql`
  query($channelId: uuid!, $sessionMerchantUserId: uuid!){
    firstMineChat: chats(
      limit: 1
      order_by: {lastActivityTimestamp: desc},
      where: {
        assignedMerchantUserId: {_eq: $sessionMerchantUserId}
        channelId: {_eq: $channelId}
        chatMessages_aggregate: {count: {predicate: {_gt: 0}}}
        isArchived: {_eq: false}
        isSpam: {_eq: false}
      }
    ){id chatMessages{id}}
    firstUnassignedChat: chats(
      limit: 1
      order_by: {lastActivityTimestamp: desc},
      where: {
        assignedMerchantUserId: {_is_null: true}
        channelId: {_eq: $channelId}
        chatMessages_aggregate: {count: {predicate: {_gt: 0}}}
        isArchived: {_eq: false}
        isSpam: {_eq: false}
      }
    ){id}
  }
`

const CHATS_INBOX_SEARCH_QUERY = gql`query($pattern: String!){
  chats(
    limit: 50
    order_by: {lastActivityTimestamp: desc}
    where: {
      _or: [
        {name: {_ilike: $pattern}}
        {
          owningUser: {
            customerUser: {merchantCustomerUsers: {fullText: {_ilike: $pattern}}}
          }
        }
        {
          owningUser: {customerUser: {whatsappPhoneNumber: {_ilike: $pattern}}}
        }
      ]
      isSpam: {_eq: false}
      type: {_eq: EXTERNAL}
    }
  ){${CHAT_FIELDS}}
}`

const CHAT_MESSAGES_INBOX_SEARCH_QUERY = gql`query($pattern: String!){
  chat_messages(
    limit: 50
    order_by: {insertionTimestamp: desc}
    where: {
      fullText: {_ilike: $pattern}
      chat: {
        isSpam: {_eq: false}
        type: {_eq: EXTERNAL}
      }
    }
  ){
    chatId
    chatMessageTemplate{id text}
    chatMessageTemplateValues{chatMessageTemplatePlaceholder{index type} value}
    id
    text
    timestamp
    chat{
      assignedMerchantUser{
        firstName
        id
        lastName
        userColor
      }
      channelId
      id
      insertionTimestamp
      isArchived
      isSpam
      owningUser{
        customerUser{
          id
          merchantCustomerUsers{
            companyName companyCity customerCode firstName id lastName role
          }
          whatsappDisplayName
          whatsappPhoneNumber
        }
        id
      }
      type
      userChats{chatId isFlagged}
    }
  }
}`

interface ChatListHeaderProps {
  channelId: string | undefined
  chatFilter: ChatFilter
  chatFilters: ChatFilter[]
  isMultiMerchantUserChannel: boolean
}

interface ChatOwningUserDisplayNameProps {
  chat: Chats
  highlightWords: string
}

interface ChatTitleProps {
  highlightWords?: string
  owningUser?: Users
}

interface MerchantCustomerUserSubtitleProps {
  value: CustomerUsers
}

export {ExternalChatPage as default, EmbeddedExternalChatPage}
