import {useMutation, useQuery} from '@apollo/client'
import FacebookLogin from '@greatsumini/react-facebook-login'
import {Button, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {useNotify, useTranslate} from 'react-admin'

import useHasPermission from '../../hooks/useHasPermission'
import useIsOwner from '../../hooks/useIsOwner'
import {QueryRoot} from '../../types/graphqlSchema'
import env from '../../utils/env'
import {isNativeMobileApp} from '../../utils/platform'
import {authenticate as authenticateSalesforce} from '../../utils/salesforce'
import {
  HubspotIcon, MicrosoftDynamicsIcon, SalesforceIcon, SapIcon, WhatsappIcon,
} from '../icons'

const ThirdPartyConnections = () => {
  const styles = useStyles()
  const translate = useTranslate()
  const isOwner = useIsOwner()
  /* TODO: On mobile we need to load the page in a nested webview and then
            intercept the redirect back to the app to close the webview. */
  const canAuthenticateSalesforce = useHasPermission('edit', 'merchant_users')
  const {
    data: {merchants: [{salesforceConnectionStatus = 'CONNECTED'} = {}] = []} = {},
  } = useQuery<QueryRoot['merchants']>(MERCHANTS_QUERY)
  const isConnectedToSalesforce = salesforceConnectionStatus === 'CONNECTED'
  if (!canAuthenticateSalesforce) {
    return null
  }
  return (<>
    <Typography className={styles.thirdPartyConnectionsTitle} variant="subtitle2">
      {translate('dialogs.profile.merchantUsersSettings.thirdPartyConnections.title')}
    </Typography>
    <div className={styles.thirdPartyConnectionsButtons}>
      {canAuthenticateSalesforce && (
        <SalesforceConnectionButton
          disabled={isConnectedToSalesforce}
          isConnectedToSalesforce={isConnectedToSalesforce}
        />
      )}
      {isOwner && (
        <>
          <Button
            disabled
            startIcon={<HubspotIcon />}
            variant="outlined"
          >
            {translate(
              'dialogs.profile.merchantUsersSettings.thirdPartyConnections.' +
              'buttons.hubspot.synchronize'
            )}
          </Button>
          <Button
            disabled
            startIcon={<SapIcon />}
            variant="outlined"
          >
            {translate(
              'dialogs.profile.merchantUsersSettings.thirdPartyConnections.' +
              'buttons.sap.synchronize'
            )}
          </Button>
          <Button
            disabled
            startIcon={<MicrosoftDynamicsIcon />}
            variant="outlined"
          >
            {translate(
              'dialogs.profile.merchantUsersSettings.thirdPartyConnections.' +
              'buttons.microsoftDynamics.synchronize'
            )}

          </Button>
        </>
      )}
    </div>
  </>)
}

const SalesforceConnectionButton = ({disabled, isConnectedToSalesforce}) => (
  <Button
    disabled={disabled}
    onClick={() => authenticateSalesforce({
      clientId: SALESFORCE_CONNECTED_APP_CLIENT_ID,
      redirectPath: '/salesforce-authentication',
    })}
    startIcon={<SalesforceIcon />}
    variant="outlined"
  >
    {useTranslate()(
      'dialogs.profile.merchantUsersSettings.thirdPartyConnections.buttons.' +
        `${isConnectedToSalesforce ?
          'salesforce.synchronized' :
          'salesforce.synchronize'}`,
    )}
  </Button>
)

const ThreeSixtyConnectionButton = () => {
  /* TODO: On mobile we need to load the page in a nested webview and then
            intercept the redirect back to the app to close the webview. */
  const canConnectThreeSixtyChannels =
    useHasPermission('connect', 'whatsapp_accounts') && !isNativeMobileApp
  const translate = useTranslate()
  if (!canConnectThreeSixtyChannels) return <></>
  return (
    <Button
      onClick={() => {
        window.location.href =
        `https://hub.360dialog.com/dashboard/app/${THREE_SIXTY_PARTNER_ID}/permissions` +
          '?plan_selection=basic'
      }}
      startIcon={<WhatsappIcon />}
      variant="outlined"
    >
      {translate(
        'dialogs.profile.companySettings.createNumber.whatsappConnectionButton',
        {provider: '360Dialog'},
      )}
    </Button>
  )
}

const VonageConnectionButton = () => {
  // const translate = useTranslate()
  const notify = useNotify()
  const translate = useTranslate()
  const [connectVonageChannels, {loading}] = useMutation(
    CONNECT_VONAGE_CHANNELS_MUTATION,
  )
  return (
    <FacebookLogin
      appId={FACEBOOK_APP_CLIENT_ID}
      initParams={{
        cookie: true,
        // @ts-ignore
        version: FACEBOOK_API_VERSION,
        xfbml: true,
      }}
      loginOptions={{
        // @ts-ignore
        config_id: FACEBOOK_APP_CONFIG_ID,
        extras: {setup: {solutionID: VONAGE_PARTNER_SOLUTION_ID}},
        override_default_response_type: true,
        response_type: 'code',
        return_scopes: true,
      }}
      onFail={err => {
        if (err.status === "facebookNotLoaded" || err.status === 'loginCancelled') {
          return
        }
        console.error(err)
        notify('pages.whatsappChannelsConnection.connectionError', 'error')
      }}
      onSuccess={
        // @ts-ignore
        res => connectVonageChannels({variables: {code: res.code}})
          .then(() => notify(
            'pages.whatsappChannelsConnection.connectionSuccess',
            'success',
          ))
          .catch(err => notify(err, 'error'))
      }
      render={({onClick}) => (
        <Button
          disabled={loading}
          onClick={onClick}
          startIcon={<WhatsappIcon />}
          variant="outlined"
        >
          {translate(
            'dialogs.profile.companySettings.createNumber.whatsappConnectionButton',
            {provider: 'Vonage'},
          )}
        </Button>
      )}
    />
  )
}

const MERCHANTS_QUERY = gql`query{
  merchants{id salesforceConnectionStatus}
}`

const CONNECT_VONAGE_CHANNELS_MUTATION = gql`
  mutation($code: String!){
    connectVonageChannels(code: $code){whatsappAccount{id phoneNumber}}
  }
`

const useStyles = makeStyles(theme => ({
  thirdPartyConnectionsButtons: {
    // TODO: Move this style to the global theme
    '& .MuiButton-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.button.fontSize,
      },
    },
  },
  thirdPartyConnectionsTitle: {
    padding: `${theme.remSpacing(1)} 0`,
  },
}))


const FACEBOOK_APP_CLIENT_ID = env('FACEBOOK_APP_CLIENT_ID')
const FACEBOOK_APP_CONFIG_ID = env('FACEBOOK_APP_CONFIG_ID')
const FACEBOOK_API_VERSION = 'v20.0'
const SALESFORCE_CONNECTED_APP_CLIENT_ID = env('SALESFORCE_CONNECTED_APP_CLIENT_ID')
const THREE_SIXTY_PARTNER_ID = env('THREE_SIXTY_PARTNER_ID')
const VONAGE_PARTNER_SOLUTION_ID = env('VONAGE_PARTNER_SOLUTION_ID')

export default ThirdPartyConnections
export {SalesforceConnectionButton, ThreeSixtyConnectionButton, VonageConnectionButton}
