import {useEffect, useState} from 'react'

const useHasFocus = (element?: HTMLElement) => {
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  useEffect(() => {
    const focusController = new AbortController()
    const blurController = new AbortController()
    element?.addEventListener(
      'focus',
      () => setHasFocus(true),
      {signal: focusController.signal}
    )
    element?.addEventListener(
      'blur',
      () => setHasFocus(false),
      {signal: blurController.signal}
    )
    return () => {
      focusController.abort()
      blurController.abort()
    }
  }, [element])
  return hasFocus
}

export default useHasFocus
