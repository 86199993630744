import _, {DebouncedFunc} from 'lodash'
import {useEffect, useRef} from 'react'
import {useEditContext} from 'react-admin'
import {useForm, useFormState} from 'react-final-form'

const EditFormAutoSave = ({
  shouldValidateOnChange = false, waitInterval = 1000,
}: EditFormAutoSaveProps) => {
  const {dirty, errors, valid, values} = useFormState({subscription: {
    dirty: true, errors: true, valid: true, values: true,
  }})
  const {blur} = useForm()
  const {save, saving} = useEditContext()
  const shouldSaveRef = useRef<boolean>()
  const saveDebouncedRef = useRef<DebouncedFunc<any>>()
  const firstRenderRef = useRef(true)
  /*
   * Call `blur` for each input in the form when the inputs are `dirty` and `invalid`
   * to ensure validation error messages are shown on input change
   */
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      return
    }
    if (
      dirty && !valid && shouldValidateOnChange && !firstRenderRef.current
    ) {
      Object.keys(errors ?? {}).forEach(blur)
    }
  }, [blur, dirty, errors, shouldValidateOnChange, valid, values])
  /*
   * Determine whether 'save' should be called by any of the following effects. Use a
   * 'ref' instead of a 'state' so that the an unmount effect can be set up which musn't
   * have state dependencies.
   */
  useEffect(() => {
    shouldSaveRef.current = dirty && valid && !saving && !firstRenderRef.current
  }, [dirty, saving, valid])
  /*
   * Debounce the 'save()' function and store it in a 'ref' for the same reason as
   * above (it needs to be called on unmount which musn't have state dependencies).
   */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    saveDebouncedRef.current = _.debounce(save!, waitInterval)
  }, [save, waitInterval])
  /*
   * Whenever the form data got dirty, schedule saving data
   */
  useEffect(() => {
    if (shouldSaveRef.current) {
      saveDebouncedRef.current?.({...values}, /* redirectTo= */false)
    }
  }, [dirty, save, saving, valid, values])
  /*
   * On component unmount submit any unsubmitted changes so that changed ("dirty") fields
   * get persisted. Said differently this effects prevents data loss of unsaved changes.
   */
  useEffect(() => () => {
    shouldSaveRef.current && saveDebouncedRef.current?.flush()
  }, [])
  return null
}

interface EditFormAutoSaveProps {
  shouldValidateOnChange?: boolean,
  waitInterval?: number,
}

export default EditFormAutoSave
