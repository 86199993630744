import {IconButton} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Mic} from '@material-ui/icons'
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {useNotify} from 'react-admin'

import {arrayBufferToHex} from '../../../utils/buffers'

const AudioRecorderButton: FC<AudioRecorderButtonProps> = ({
  elapsedTime,
  isRecordingAudio,
  onStartRecordingAudio,
  onStopRecordingAudio,
}) => {
  const notify = useNotify()
  const styles = useStyles()
  const audioRecorderRef = useRef<MediaRecorder>()
  const [
    isRequestingMicrophoneAccess,
    setIsRequestingMicrophoneAccess,
  ] = useState(false)
  const startRecording = useCallback(
    async () => {
      try {
        setIsRequestingMicrophoneAccess(true)
        const audioStream = await navigator.mediaDevices.getUserMedia({audio: true})
        const audioChunks: Blob[] = []
        const audioRecorder = new MediaRecorder(audioStream, {mimeType: MIME_TYPE})
        audioRecorderRef.current = audioRecorder
        audioRecorderRef.current.onstart = () => {
          setIsRequestingMicrophoneAccess(false)
          onStartRecordingAudio()
        }
        audioRecorderRef.current.ondataavailable = event => {
          event.data && event.data.size && audioChunks.push(event.data)
        }
        audioRecorderRef.current.onstop = () => {
          const audioFile = new File(audioChunks, AUDIO_FILENAME, {type: MIME_TYPE})
          audioFile.arrayBuffer()
            .then(arrayBuffer => {
              onStopRecordingAudio({
                encodedContent: arrayBufferToHex(arrayBuffer),
                file: audioFile,
                filename: AUDIO_FILENAME,
                mimeType: MIME_TYPE,
              })
              // Stop stream for all tracks in the stream so that the browser releases
              // its microphone access.
              audioStream.getTracks().forEach(t => t.stop())
              audioRecorderRef.current = undefined
            })
            .catch(e => {
              console.error(e)
              notify(e.message, 'error')
            })
        }
        // Specifically for safari(but possibly could happen in other browsers); we record
        // in 1-second timeslices so that the resulting combined chunk can be properly
        // converted to mp3 on events-api. If this is ommitted, ffmpeg somehow cuts the
        // entire clip to only 1 second during conversion.
        audioRecorderRef.current.start(1000)
      }
      catch (e) {
        setIsRequestingMicrophoneAccess(false)
        if (e instanceof DOMException) {
          notify(e.message, 'warning')
        }
        else {
          console.error(e)
          notify('ra.message.error')
        }
      }
    },
    [notify, onStartRecordingAudio, onStopRecordingAudio],
  )
  const stopRecording = useCallback(
    () => audioRecorderRef.current?.stop(),
    [],
  )
  useEffect(() => {
    if (isRecordingAudio && elapsedTime >= (5 * 60)) {
      notify('chat.external.toolbar.audioRecorderTimeLimit')
      stopRecording()
    }
  }, [elapsedTime, isRecordingAudio, notify, stopRecording])
  return (
    <IconButton
      className={`${styles.microphone} ${isRecordingAudio ? styles.active : ''}`}
      disabled={isRequestingMicrophoneAccess}
      onClick={e => {
        e.stopPropagation()
        isRecordingAudio ? stopRecording() : startRecording()
      }}
    >
      <Mic/>
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  '@keyframes animateMicrophone': {
    '0%': {
      boxShadow: `inset 0 0 0 24px rgba(
        ${theme.hexToRgb(theme.palette.info.main)},
        0.5
      )`,
      transform: 'scale(0.75)',
    },
    '50%': {
      boxShadow: 'none',
      transform: 'scale(1)',
    },
    '100%': {
      boxShadow: `inset 0 0 0 24px rgba(
        ${theme.hexToRgb(theme.palette.info.main)},
        0.5
      )`,
      transform: 'scale(0.75)',
    },
  },
  active: {},
  microphone: {
    '&$active': {
      animation: `$animateMicrophone 1.5s ${theme.transitions.easing.easeInOut} infinite`,
    },
    color: theme.palette.info.dark,
  },
}))

const MIME_TYPE = `audio/${
  navigator.userAgent.includes('Firefox') ? 'ogg; codecs=opus' : 'mp4'
}`

const AUDIO_FILENAME = `flinkit-app-recording.${
  navigator.userAgent.includes('Firefox') ? 'ogg' : 'mp4'
}`

interface AudioRecorderButtonProps {
  elapsedTime: number,
  isRecordingAudio: boolean,
  onStartRecordingAudio: () => void,
  onStopRecordingAudio: (_: FileAttachment) => void
}

export default AudioRecorderButton
