import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Switch,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
// TODO: Use custom icon from our figma designs
import UploadIcon from '@material-ui/icons/CloudUpload'
import {FC, ReactNode, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {required, ShowBase, TextInput, useRecordContext, useTranslate}
  from 'react-admin'
import {useForm, useFormState} from 'react-final-form'

import TemplateEditorChatBackgroundImage
  from '../../assets/imgs/templateEditorChatBackground.png'
import ApiUrlsContext from '../../contexts/ApiUrlsContext'
import {useCompileChatMessageText} from '../../hooks/useCompileChatMessageText'
import useIsDesktop from '../../hooks/useIsDesktop'
import Theme from '../../theme'
import {CHAT_MESSAGE_TEMPLATE_TEXT_MAX_LENGTH} from '../../utils/consts'
import ChatMessageTemplateHeaderMediaFilePicker
  from '../chat/ChatMessageTemplateHeaderMediaFilePicker'
import {ArrowDownIcon, PictureIcon, WhatsappBubbleTailIcon} from '../icons'

const ChatMessageTemplateFormPreview: FC<ChatMessageTemplateFormPreviewProps> = ({
  source, ...props
}) => {
  const {values: formValues} = useFormState({subscription: {values: true}})
  return (
    <ChatMessageTemplatePreview chatMessageTemplateId={formValues?.[source]} {...props} />
  )
}

const ChatMessageTemplatePreview: FC<ChatMessageTemplatePreviewProps> = ({
  chatMessageTemplateId, ...props
}) => (
  <ShowBase
    basePath=""
    id={chatMessageTemplateId}
    resource="chat_message_templates"
  >
    {/* @ts-ignore -- ignoring children missing */}
    <ChatMessageTemplateField {...props} />
  </ShowBase>
)

const ChatMessageTemplateField: FC<ChatMessageTemplateFieldProps> = ({
  className, isPropertiesPaneVisible = true, text,
}) => {
  const styles = useStyles()
  const {getFilesApiUrl} = useContext(ApiUrlsContext)
  const templateRecord = useRecordContext()
  return (
    <div className={`${styles.root} ${className ?? ''}`}>
      <WhatsappSkin isSkeleton={!templateRecord}>
        {!!templateRecord?.headline &&
          <span className={styles.viewerHeadline}>
            {templateRecord.headline}
          </span>
        }
        {!!templateRecord?.headerMediaKey && (
          <img
            alt="Template image"
            className={styles.templateImagePreview}
            src={getFilesApiUrl(templateRecord.headerMediaKey)}
          />
        )}
        <span className={styles.viewerText}>{text ?? templateRecord?.text}</span>
        {!!templateRecord?.buttonText &&
          <div className={styles.button}>
            <span
              className={styles.viewerButtonText}
            >
              {templateRecord.buttonText}
            </span>
          </div>
        }
      </WhatsappSkin>
      {isPropertiesPaneVisible && (
        <PreviewPaper
          buttonUrl={templateRecord?.buttonUrl}
          isChatMessageTemplateSelected={!!templateRecord?.id}
        />
      )}
    </div>
  )
}

const ChatMessageTemplateInput: FC<ChatMessageTemplateInputProps> = ({className}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const textInputRef = useRef<HTMLTextAreaElement>(null)
  const form = useForm()
  const {values: formValues} = useFormState({subscription: {values: true}})
  const [templateHeaderMediaFile, setTemplateHeaderMediaFile] = useState<FileAttachment>()
  // final-form already has a built-in max length validation but
  // the validation message is not updated properly as new input comes
  const [hasButton, setHasButton] = useState(!!formValues.buttonText)
  const [hasHeadline, setHasHeadline] = useState(!!formValues.headline)
  const [hasHeaderMedia, setHasHeaderMedia] = useState(!!formValues.headerMediaKey)
  const [placeholderButtonAnchorEl, setPlaceholderButtonAnchorEl] = useState(null)
  const {placeholderTypeToLabel} = useCompileChatMessageText()
  const openPlaceholderMenu = e => {
    setPlaceholderButtonAnchorEl(e.currentTarget)
  }
  const closePlaceholderMenu = () => {
    setPlaceholderButtonAnchorEl(null)
  }
  const insertFreeTextPlaceholder = () => {
    insertPlaceholder(translate('chat_message_template_placeholder_types.FREE_TEXT'))
  }
  const insertSmartPlaceholder = placeholderCode => {
    insertPlaceholder(
      `%${translate(
        `chat_message_template_placeholder_types.${placeholderCode}`
      )}%`
    )
  }
  const insertPlaceholder = useCallback(placeholder => {
    const selectionStart = textInputRef.current?.selectionStart as number
    const text: string = formValues.text ?? ''
    const startText = text.slice(0, selectionStart)
    const endText = text.slice(selectionStart)
    form.change(
      'text',
      // Prepend a space before and after the placeholder if none is present yet.
      // We do this because users often overlook that placeholders, and such as words,
      // should be separated by spaces.
      // Otherwise, they end up with this: "Hello {{firstName}}{{lastName}}".
      `${startText}${(startText.length && startText.substr(-1) !== ' ') ? ' ' : ''}` +
      `{{${placeholder}}}` +
      `${
        (
          endText.length &&
          (endText.substr(0, 1) !== ' ') &&
          // Do not insert a trailing space after the placeholder if the next character is
          // a punctuation(., ,, !, ?, " and so on). The punctuation "{" is excluded
          // because we want a space between consecutive placeholders.
          // There is no leading space before punctuations as in :
          // "Hello {{firstname}}! How are you? Greetings {{merchant}}, from Berlin"
          !PUNCTUATION_REGEX.test(endText.substr(0, 1))
        ) ? ' ' : ''
      }${endText}`
    )
    // The text input is not set immediately, hence it's not possible to set the
    // new "selectionStart". A workaround is to set it asynchronously.
    setTimeout(
      () => {
        // Set the new cursor position to allow the user to set multiple placeholders on
        // the fly. The new cursor position is the position where
        // the currently set placeholder ends.
        (textInputRef.current as HTMLTextAreaElement).selectionStart =
          `${startText}{{${placeholder}}}`.length + 1
        textInputRef.current?.focus()
      },
      0
    )
    closePlaceholderMenu()
  }, [form, formValues])
  useEffect(() => {
    if (!templateHeaderMediaFile) {
      form.change('headerMediaBase64', null)
      form.change('headerMediaMimeType', null)
      return
    }
    const {encodedContent, mimeType} = templateHeaderMediaFile
    form.change('headerMediaBase64', encodedContent)
    form.change('headerMediaMimeType', mimeType)
  }, [form, templateHeaderMediaFile])
  useEffect(() => {
    if (hasHeadline) {
      setHasHeaderMedia(false)
      setTemplateHeaderMediaFile(undefined)
    }
    else {
      form.change('headline', null)
    }
  }, [hasHeadline, form, setHasHeaderMedia])
  useEffect(() => {
    form.change('hasHeaderMedia', hasHeaderMedia)
    if (hasHeaderMedia) {
      setHasHeadline(false)
    }
    else {
      setTemplateHeaderMediaFile(undefined)
    }
  }, [form, hasHeaderMedia, setHasHeadline])
  useEffect(() => {
    if (!hasButton) {
      form.change('buttonUrl', null)
      form.change('buttonText', null)
    }
  }, [form, hasButton])
  const type = formValues.type
  return (
    <div className={`${styles.root} ${className ?? ''}`}>
      <input name="hasHeaderMedia" type="hidden" />
      <input name="headerMediaBase64" type="hidden" />
      <input name="headerMediaMimeType" type="hidden" />
      <WhatsappSkin>
        {hasHeadline &&
          <TextInput
            InputProps={{disableUnderline: true}}
            className={styles.headlineTextInput}
            defaultValue={formValues.headline}
            fullWidth={true}
            inputProps={{maxLength: 50}}
            label={false}
            margin="dense"
            multiline
            placeholder={
              translate('chatMessageTemplateEditor.templateInput.headlinePlaceholder')
            }
            source="headline"
            validate={hasHeadline && required()}
            variant="standard"
          />
        }
        {hasHeaderMedia && (
          <ChatMessageTemplateHeaderMediaFilePicker
            defaultImage={
              <div className={styles.mediaFilePickerDefaultImage}>
                <PictureIcon />
                <UploadIcon />
                <span>{translate(
                  'chatMessageTemplateEditor.templateInput.' +
                  'headerMediaFilePickerDefaultText'
                )}</span>
              </div>
            }
            isChangeButtonVisible={false}
            isRemoveButtonVisible={false}
            onChange={setTemplateHeaderMediaFile}
            value={templateHeaderMediaFile}
          />
        )}
        <TextInput
          InputProps={{disableUnderline: true}}
          className={styles.textTextInput}
          defaultValue={formValues.text}
          helperText={
            `${formValues.text?.length ?? 0}/${CHAT_MESSAGE_TEMPLATE_TEXT_MAX_LENGTH}`
          }
          inputProps={{maxLength: CHAT_MESSAGE_TEMPLATE_TEXT_MAX_LENGTH}}
          inputRef={textInputRef}
          label={false}
          minRows="3"
          multiline
          placeholder={
            translate('chatMessageTemplateEditor.templateInput.textInputPlaceholder')
          }
          source="text"
          validate={required()}
          variant="standard"
        />
        {hasButton &&
          <div className={styles.button}>
            <TextInput
              InputProps={{
                classes: {
                  input: `
                    ${styles.buttonInput}
                    ${form.getFieldState('buttonUrl')?.valid ?
                    styles.buttonValidLink : ''
                    }
                  `,
                },
                disableUnderline: true,
              }}
              defaultValue={formValues.buttonText}
              inputProps={{maxLength: 25}}
              label={false}
              placeholder={translate(
                'chatMessageTemplateEditor.templateInput.buttonTextInputPlaceholder'
              )}
              source="buttonText"
              validate={hasButton && required()}
              variant="standard"
            />
          </div>
        }
      </WhatsappSkin>
      {
        <Paper className={styles.configurationsPanel}>
          <div>
            <Button
              color="primary"
              onClick={openPlaceholderMenu}
              variant="contained"
            >
              {translate('chatMessageTemplateEditor.insertBlockButton')}
            </Button>
            <Popover
              anchorEl={placeholderButtonAnchorEl}
              anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
              onClose={closePlaceholderMenu}
              open={!!placeholderButtonAnchorEl}
              transformOrigin={{horizontal: 'left', vertical: 'top'}}
            >
              <MenuList>
                {
                  (type === 'UTILITY') && (
                    <MenuItem onClick={() => insertFreeTextPlaceholder()}>
                      {translate('chatMessageTemplateEditor.freeText')}
                    </MenuItem>
                  )
                }
                {
                  Object.entries(placeholderTypeToLabel).map(
                    ([type, label]) => (
                      type !== 'FREE_TEXT' && (
                        <MenuItem key={type} onClick={() => insertSmartPlaceholder(type)}>
                          {label}
                        </MenuItem>
                      )
                    )
                  )
                }
              </MenuList>
            </Popover>
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={hasHeaderMedia}
                onChange={e => setHasHeaderMedia(e.target.checked)}
              />
            }
            label={translate(
              'chatMessageTemplateEditor.templateInput.addHeaderMediaFileSwitchLabel'
            )}
          />
          <Typography>oder</Typography><br/>
          <FormControlLabel
            control={
              <Switch
                checked={hasHeadline}
                onChange={e => setHasHeadline(e.target.checked)}
              />
            }
            label={translate(
              'chatMessageTemplateEditor.templateInput.addHeadLineSwitchLabel'
            )}
          />
          <Divider className={styles.divider} />
          <FormControlLabel
            control={
              <Switch
                checked={hasButton}
                onChange={e => setHasButton(e.target.checked)}
              />
            }
            label={translate(
              'chatMessageTemplateEditor.templateInput.addButtonUrlSwitchLabel'
            )}
          />
          {hasButton &&
            <TextInput
              fullWidth
              inputProps={{maxLength: 2000}}
              label={false}
              placeholder={translate(
                'chatMessageTemplateEditor.templateInput.buttonLinkInputPlaceholder'
              )}
              source="buttonUrl"
              validate={hasButton && [required()]}
              variant="outlined"
            />
          }
        </Paper>
      }
    </div>
  )
}

const WhatsappSkin: FC<WhatsappSkinProps> = ({children, isSkeleton}) => {
  const styles = useStyles()
  return (
    <div className={styles.whatsappSkin}>
      <Box position="relative" width="100%">
        <div className={styles.whatsappSkinBubble}>
          {isSkeleton ? (
            <Box className={styles.whatsappSkinBubbleSkeleton}>
              <div></div><div></div><div></div><div></div>
            </Box>
          ) : children}
        </div>
        <WhatsappBubbleTailIcon
          className={styles.whatsappBubbleTailIcon}
          color="white"
        />
      </Box>
    </div>
  )
}

const PreviewPaper = ({buttonUrl, isChatMessageTemplateSelected}) => {
  const styles = useStyles()
  const translate = useTranslate()
  const isDesktop = useIsDesktop()
  const [isOpen, setIsOpen] = useState(isDesktop)
  useEffect(() => setIsOpen(isDesktop), [isDesktop])
  return (
    <Paper className={styles.previewPaper}>
      {isChatMessageTemplateSelected && buttonUrl && (
        <>
          <Typography className={styles.previewPaperTitle} variant="h4">
            <Button
              disableRipple={isDesktop}
              onClick={() => !isDesktop && setIsOpen(o => !o)}
            >
              <Typography variant="subtitle1">
                {translate('chatMessageTemplateEditor.paperPreview.links')}
              </Typography>
              {!isDesktop &&
                <ArrowDownIcon
                  className={isOpen ? styles.previewPaperArrowDownIconIsOpen : ''}
                />
              }
            </Button>
          </Typography>
          {isOpen &&
            <div>
              <Typography variant="h6">
                {translate('chatMessageTemplateEditor.paperPreview.button')}
              </Typography>
              <div className={styles.previewPaperDecorationValue}>{buttonUrl}</div>
            </div>
          }
        </>
      )}
    </Paper>
  )
}

// @ts-ignore
const useStyles = makeStyles<typeof Theme, Record<string, any>|undefined, any>(theme => {
  const buttonText = {
    display: 'block',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
    height: theme.typography.pxToRem(40),
    padding: 0,
    textAlign: 'center',
  }
  const headline = {
    display: 'block',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    height: theme.typography.pxToRem(25),
    paddingLeft: 0,
    width: '100%',
  }
  const text = {
    color: theme.palette.text.secondary,
    display: 'block',
    fontWeight: 500,
  }
  return {
    addPlaceholderButton: {
      color: theme.palette.info.main,
      fontSize: 'smaller',
      fontWeight: 500,
      marginBottom: theme.remSpacing(1.5),
      marginLeft: 0,
      marginRight: 0,
      padding: theme.remSpacing(1),
      width: '100%',
    },
    button: {
      '& .MuiFormHelperText-root': {
        textAlign: 'center',
      },
      '& .MuiTextField-root': {
        '& .MuiFormHelperText-root': {
          margin: 0,
        },
        '& .MuiFormHelperText-root :not(Mui-error)': {
          display: 'none',
        },
        margin: 0,
        padding: 0,
        width: '60% !important',
      },
      alignItems: 'center',
      borderTop: `3px solid ${theme.palette.background.default}`,
      display: 'flex',
      fill: theme.palette.info.main,
      justifyContent: 'center',
    },
    buttonInput: {
      '&$buttonValidLink': {
        color: theme.palette.info.main,
      },
      ...buttonText,
    },
    buttonValidLink: {},
    configurationsPanel: {
      '& .MuiFormControlLabel-root > *': {
        color: `${theme.palette.text.primary} !important`,
      },
      boxSizing: 'border-box',
      padding: theme.remSpacing(3),
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '45%',
      },
    },
    divider: {
      marginBottom: theme.remSpacing(2),
      marginTop: theme.remSpacing(2),
    },
    headlineTextInput: {
      '& textarea': headline,
    },
    mediaFilePickerDefaultImage: {
      '& > :nth-child(3)': {
        marginBottom: theme.remSpacing(3),
      },
      '& > svg:first-of-type': {
        flexBasis: '100%',
        marginTop: theme.remSpacing(3),
        transform: 'scale(1.5)',
      },
      '& > svg:nth-of-type(2)': {
        marginBottom: theme.remSpacing(3),
        marginRight: theme.remSpacing(1),
        width: theme.typography.pxToRem(20),
      },
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.typography.pxToRem(6),
      color: theme.palette.text.secondary,
      display: 'flex',
      flexWrap: 'wrap',
      fontWeight: '500',
      height: theme.typography.pxToRem(136),
      justifyContent: 'center',
      width: '100%',
    },
    previewPaper: {
      '& > div:not(hidden)': {
        marginBottom: theme.remSpacing(3),
        marginTop: theme.remSpacing(3),
      },
      boxSizing: 'border-box',
      padding: theme.remSpacing(3),
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '40%',
      },
    },
    previewPaperArrowDownIconIsOpen: {
      rotate: '180deg',
    },
    previewPaperDecorationValue: {
      border: `1px solid ${theme.palette.disabled.main}`,
      borderRadius: theme.typography.pxToRem(2),
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 500,
      marginTop: theme.remSpacing(1),
      padding: theme.remSpacing(1),
      textAlign: 'center',
    },
    previewPaperTitle: {
      '& button': {
        padding: 0,
      },
      '& button :first-child': {
        marginRight: theme.remSpacing(1),
      },
      '& svg': {
        transition: 'rotate 150ms',
        width: theme.typography.pxToRem(12),
      },
      borderBottom: `1px solid ${theme.palette.background.default}`,
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: theme.remSpacing(.5),
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },
    templateImagePreview: {
      borderRadius: theme.typography.pxToRem(6),
      marginBottom: theme.typography.pxToRem(16),
      width: '100%',
    },
    textTextInput: {
      '& .MuiInput-root': {
        width: '100%',
      },
      '& textarea': text,
    },
    viewerButtonText: {
      ...buttonText,
      color: theme.palette.info.main,
      lineHeight: theme.typography.pxToRem(40),
      verticalAlign: 'middle',
    },
    viewerHeadline: {
      ...headline,
      marginBottom: theme.typography.pxToRem(16),
    },
    viewerText: {
      ...text,
      marginBottom: theme.typography.pxToRem(25),
      whiteSpace: 'break-spaces',
    },
    whatsappBubbleTailIcon: {
      backgroundColor: 'transparent',
      bottom: theme.typography.pxToRem(16),
      color: theme.palette.background.paper,
      left: theme.typography.pxToRem(-16),
      position: 'absolute',
    },
    whatsappSkin: {
      alignItems: 'center',
      backgroundImage: `url(${TemplateEditorChatBackgroundImage})`,
      display: 'flex',
      fill: theme.palette.text.secondary,
      [theme.breakpoints.up('lg')]: {
        width: '55%',
      },
      margin: 0,
      paddingBottom: theme.remSpacing(5),
      paddingLeft: theme.remSpacing(4),
      paddingRight: theme.remSpacing(4.5),
      paddingTop: theme.remSpacing(2.5),
    },
    whatsappSkinBubble: {
      '& .MuiInputBase-input': {
        border: 0,
      },
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.typography.pxToRem(10),
      maxWidth: theme.typography.pxToRem(350),
      padding: theme.remSpacing(2),
      width: '80%',
    },
    whatsappSkinBubbleSkeleton: {
      '& > *': {
        backgroundColor: theme.palette.background.default,
        height: theme.typography.pxToRem(14),
        marginBottom: theme.remSpacing(1),
      },
      '& > :nth-child(1)': {
        width: '60%',
      },
      '& > :nth-child(2)': {
        marginBottom: theme.remSpacing(5),
      },
      '& > :nth-child(4)': {
        width: '90%',
      },
      padding: theme.remSpacing(3),
      paddingBottom: theme.remSpacing(8),
    },
  }
})

// The following regex matches any punctuation expect "{".
// See:
// https://www.freecodecamp.org/news/what-is-punct-in-regex-how-to-match-all-punctuation-marks-in-regular-expressions
// https://stackoverflow.com/a/3973782
const PUNCTUATION_REGEX = /^(?![{])\p{P}$/u

interface ChatMessageTemplateFormPreviewProps {
  [p: string]: any
  source: string
}

interface ChatMessageTemplatePreviewProps {
  [p: string]: any
  chatMessageTemplateId: string
}

interface ChatMessageTemplateFieldProps {
  className?: string
  isPropertiesPaneVisible?: boolean
  text?: string
}

interface WhatsappSkinProps {
  children: ReactNode
  isSkeleton?: boolean
}

interface ChatMessageTemplateInputProps {
  className?: string
}

export {
  ChatMessageTemplateField,
  ChatMessageTemplateFormPreview,
  ChatMessageTemplateInput,
  ChatMessageTemplatePreview,
}
