import {createContext} from 'react'

interface ChatContextType {
  channelId?: string // chats of type INTERNAL have no channel
  chatId: string
  chatMessageId?: string
}

const ChatContext = createContext<ChatContextType>({} as ChatContextType)

export default ChatContext
