import {makeStyles} from '@material-ui/core/styles'
import {FC, useMemo} from 'react'

import {
  ChatMessageFileAttachmentTranslations,
  ChatMessageTranslations,
} from '../../../types/graphqlSchema'

const ChatMessageTranslation: FC<ChatMessageTranslationProps> = ({translations}) => {
  const classes = useStyles()
  return <>{
    useMemo(() => translations.map(({language, text}) => (
      <div className={classes.root} key={`translation-${language}`}>
        {text}
      </div>
    )), [classes, translations])
  }</>
}

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.disabled.main}`,
    fontStyle: 'italic',
    paddingTop: theme.remSpacing(1),
  },
}))

interface ChatMessageTranslationProps {
  translations: ChatMessageTranslations[]|ChatMessageFileAttachmentTranslations[]
}

export default ChatMessageTranslation
