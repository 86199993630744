import './index.css'

import {
  browserProfilingIntegration,
  captureConsoleIntegration,
  init as initSentry,
  reactRouterV5BrowserTracingIntegration,
} from '@sentry/react'
import {createBrowserHistory} from 'history'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import env from './utils/env'
import platform from './utils/platform'

if (import.meta.env.MODE === 'development') {
  const originalErrorFunction = console.error
  console.error = (...args) => {
    if (args.some(arg => CONSOLE_ERROR_FILTERS.some(f => f.test(arg)))) return null
    originalErrorFunction(...args)
  }
  // Add apollo  messages only in a dev environment
  // import('@apollo/client/dev').then(dev => {
  //   dev.loadDevMessages()
  //   dev.loadErrorMessages()
  // })
}

if (env('SENTRY_DSN')) {
  initSentry({
    beforeSend(event) {
      try {
        const errorBag = (event.exception?.values?.reduce((bag, e) =>
          `${bag}${e.value};`, ''
        ) ?? '') + (event.message ?? '')
        if (errorBag && SENTRY_ERROR_FILTERS.some(f => f.test(errorBag))) return null
      }
      catch (e) {
        console.warn("Error on error filter", e)
      }
      return event
    },
    dist: platform,
    dsn: env('SENTRY_DSN'),
    environment: env('SENTRY_ENVIRONMENT'),
    integrations: [
      captureConsoleIntegration({levels: ['error']}),
      reactRouterV5BrowserTracingIntegration({
        history: createBrowserHistory(),
      }),
      browserProfilingIntegration(),
    ],
    profilesSampleRate: 1,
    release: env('SENTRY_RELEASE'),
    // TODO: Adjust this value if we are receiving more events than we need
    //       See also: https://docs.sentry.io/platforms/javascript/performance/
    tracesSampleRate: 0.1,
  })
}

/* eslint-disable */
if (['true', 't', '1'].includes(`${env('HOTJAR_ENABLED')}`)) {
  (function(h, o, t, j, a, r) {
    // @ts-ignore
    h.hj = h.hj || function() {(h.hj.q = h.hj.q || []).push(arguments)}
    // @ts-ignore
    h._hjSettings = {hjid: 2664407, hjsv: 6}
    // @ts-ignore
    a = o.getElementsByTagName('head')[0]
    // @ts-ignore
    r = o.createElement('script');r.async = 1
    // @ts-ignore
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    // @ts-ignore
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

const SENTRY_ERROR_FILTERS = [
  (/networkerror when attempting to fetch resource/i),
  (/network\s*error[: ]*/i),
  (/intentional error/i),
  (/load failed/i),
  (/.*.currentObservable.query.fetchMore/i), // Known issue on WebKit
  (/Login required/i), // Access token has expired
  (/Amplitude Logger \[Error]: TypeError: Failed to fetch/i),
  (/Uniqueness violation. duplicate key value violates unique constraint \\?"merchant_users_email_address_key\\?"/i),
  (/INVALID_WHATSAPP_BUSINESS_PROFILE_SIZE/),
]

const CONSOLE_ERROR_FILTERS = [
  (/is deprecated/i),
]
