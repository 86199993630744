const authenticate = ({clientId, redirectPath}: AuthenticateOptions) => {
  window.location.href = `https://d5i000003dgmpuas--testdev.sandbox.my.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${REDIRECT_BASE_URI}/${redirectPath.replace(/^\//, '')}`
}

const {hostname, port, protocol} = window.location
const REDIRECT_BASE_URI = `${protocol}//${hostname}${port ? `:${port}` : ''}`

interface AuthenticateOptions {
  clientId: string
  redirectPath: string
}

export {authenticate}
