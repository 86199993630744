import {useApolloClient} from '@apollo/client'
import {Button} from '@material-ui/core'
import {FC} from 'react'
import {
  DeleteButtonProps,
  useDeleteWithConfirmController,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin'

import ConfirmationDialog from '../../components/ConfirmationDialog'
import {DeleteIcon} from '../../components/icons'

const DeleteWithConfirmButton: FC<DeleteWithConfirmProps> = ({
  basePath,
  children,
  confirmContent,
  confirmTitle,
  mutationMode,
  onConfirmDelete,
  record,
  redirect,
  resource,
  ...props
}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const handleRedirect = useRedirect()
  const apolloClient = useApolloClient()
  const {
    handleDelete,
    handleDialogClose,
    handleDialogOpen,
    open,
  } = useDeleteWithConfirmController({
    basePath,
    mutationMode: mutationMode ?? 'pessimistic',
    onSuccess: () => {
      apolloClient.cache.evict({id: `${resource}:${record?.id}`})
      apolloClient.cache.gc()
      handleRedirect(`/${resource}`)
      notify('ra.notification.deleted', 'info', {smart_count: 1}, false)
    },
    record,
    redirect,
    resource,
  })
  return (
    <>
      <Button
        color="secondary"
        onClick={handleDialogOpen}
        startIcon={<DeleteIcon color="background" />}
        variant="contained"
        {...props}
      >
        {children ?? translate(
          `resources.${resource}.action.delete`, {_: translate('actions.delete')}
        )}
      </Button>
      <ConfirmationDialog
        confirmationText={translate('actions.confirm')}
        onCancel={handleDialogClose}
        onConfirm={onConfirmDelete ?? handleDelete}
        open={open}
        title={typeof confirmTitle === 'function' ? confirmTitle(record) : confirmTitle}
      >
        {confirmContent}
      </ConfirmationDialog>
    </>
  )
}

interface DeleteWithConfirmProps extends Partial<DeleteButtonProps> {
  confirmContent: string,
  confirmTitle: string | any,
  onConfirmDelete?: () => any
}

export default DeleteWithConfirmButton
