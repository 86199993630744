import {Button, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, ReactNode, SyntheticEvent} from 'react'
import {useTranslate} from 'react-admin'

import DialogCloseButton from '../components/DialogCloseButton'
import {CheckIcon, CloseIcon} from '../components/icons'
import ExtendedDialog from './ExtendedDialog'

const ConfirmationDialog: FC<ConfirmationProps> = ({
  children,
  confirmationText,
  onCancel,
  onConfirm,
  open,
  title,
}) => {
  const styles = useStyles()
  return (
    <ExtendedDialog className={styles.root} onClose={onCancel} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onCancel}
          startIcon={<CloseIcon />}
          variant="outlined"
        >
          {useTranslate()('actions.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={onConfirm}
          startIcon={<CheckIcon />}
          variant="contained"
        >
          {confirmationText}
        </Button>
      </DialogActions>
      <DialogCloseButton onClick={onCancel} />
    </ExtendedDialog>
  )
}

interface ConfirmationProps {
  children: ReactNode,
  confirmationText: string,
  onCancel: (e: SyntheticEvent) => void,
  onConfirm: (e: SyntheticEvent) => void,
  open: boolean,
  title: string,
}

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 'fit-content',
      padding: theme.remSpacing(1),
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}))

export default ConfirmationDialog
