/*
Function that converts from hex color to rgb color
Example: input = #9c27b0 => output = 156, 39, 176
Example: input = 9c27b0 => output = 156, 39, 176
Example: input = #999 => output = 153, 153, 153
Example: input = 999 => output = 153, 153, 153
*/

const hexToRgb = (inputHex: string) => {
  let input = `${inputHex }`.replace("#", "")
  const hexRegex = /[0-9A-Fa-f]/g
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error(`Input hex "${inputHex}" is not a valid hex color.`)
  }
  if (input.length === 3) {
    const [first, second, last] = input
    input = (first + second + last).repeat(2)
  }
  input = input.toUpperCase()
  const first = input[0] + input[1]
  const second = input[2] + input[3]
  const last = input[4] + input[5]
  return (
    `${parseInt(first, 16)
    }, ${
    parseInt(second, 16)
    }, ${
    parseInt(last, 16)}`
  )
}

export default hexToRgb
