import {useMutation} from '@apollo/client'
import {
  ManyToManyReferenceContextProvider,
  ReferenceManyToManyField,
  ReferenceManyToManyInput,
} from '@flinkit/ra-relationships'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {sortBy} from 'lodash'
import {FC, useCallback, useState} from 'react'
import {
  AutocompleteArrayInput,
  Create,
  FunctionField,
  RadioButtonGroupInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextInput,
  useGetList,
  useLocale,
  useNotify,
  useRefresh,
  useSetLocale,
  useTranslate,
} from 'react-admin'
import {useFormState} from 'react-final-form'

import EditFormAutoSave from '../components/EditFormAutoSave'
import ExtendedEdit from '../components/ExtendedEdit'
import useSessionMerchantUser from '../hooks/useSessionMerchantUser'
import {Channels} from '../types/graphqlSchema'
import {EMAIL_ADDRESS_REGEX} from '../utils/consts'
import {actionErrorCode} from '../utils/errors'
import {SUPPORTED_I18N_LANGUAGES} from '../utils/i18nProvider'

const MerchantUserCreate = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const styles = useStyles()
  const {merchantUser} = useSessionMerchantUser()
  const translate = useTranslate()
  const [isLimitedToContactGroups, setIsLimitedToSpecificContactGroups] = useState(false)
  const {ids: contactGroupIds} = useGetList('contact_groups')
  return (
    <Create
      basePath="/merchant_users"
      className={styles.merchantUserCreateRoot}
      onSuccess={() => {
        notify('resources.merchant_users.successMessages.created')
        refresh()
      }}
      resource="merchant_users"
    >
      <ManyToManyReferenceContextProvider>
        <SimpleForm
          initialValues={{merchantId: merchantUser?.merchant?.id}}
          redirect={false}
          toolbar={<MerchantUserSaveOrRestoreButton />}
        >
          <TextInput source="firstName" validate={required()}/>
          <TextInput source="lastName" validate={required()} />
          <TextInput
            parse={v => v?.toLowerCase()}
            source="emailAddress"
            validate={[regex(
              EMAIL_ADDRESS_REGEX,
              translate('resources.merchant_users.errorMessages.invalidEmailAddress')
            ), required()]}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={isLimitedToContactGroups}
                disabled={!contactGroupIds.length}
                onChange={
                  event => setIsLimitedToSpecificContactGroups(event.target.checked)
                }
              />
            )}
            label={translate(
              'dialogs.profile.companySettings.addMerchantUser.restrictContactGroups'
            )}
          />
          {isLimitedToContactGroups && (
            <ReferenceManyToManyInput
              filterToQuery={q => ({'name@_ilike': q})}
              label="resources.contactGroups.name"
              // @ts-ignore
              perPage={null}
              reference="contact_groups"
              sort={{field: 'name', order: 'ASC'}}
              through="merchant_user_contact_groups"
              using="merchantUserId,contactGroupId"
            >
              <AutocompleteArrayInput
                optionText="name"
                resettable={true}
              />
            </ReferenceManyToManyInput>
          )}
        </SimpleForm>
      </ManyToManyReferenceContextProvider>
    </Create>
  )
}

const MerchantUserSaveOrRestoreButton = ({...props}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()
  const {values: {emailAddress, firstName, lastName}} = useFormState()
  const [restoreMerchantUser] = useMutation(
    RESTORE_DELETED_MERCHANT_USER_MUTATION, {
      onCompleted: ({restoreMerchantUser: {isRestored}}) => {
        if (isRestored) {
          notify('resources.merchant_users.successMessages.created')
          refresh()
        }
        else {
          notify(
            'resources.merchant_users.errorMessages.' +
            'MERCHANT_USER_EMAIL_ADDRESS_ALREADY_EXISTS',
            'error',
          )
        }
      },
      variables: {emailAddress, firstName, lastName},
    }
  )
  return (
    <SaveButton
      label={translate('resources.merchant_users.actions.create')}
      onFailure={e => {
        if (e.message.endsWith(
          'Uniqueness violation. duplicate key value violates ' +
          'unique constraint "merchant_users_email_address_key"'
        )) {
          restoreMerchantUser()
        }
      }}
      {...props}
    />
  )
}

const MerchantUserEdit: FC<{id: string}> = ({id}) => {
  const styles = useStyles()
  return (
    <ExtendedEdit
      basePath="/merchant_users"
      id={id}
      resource="merchant_users"
      title={useTranslate()('resources.merchant_users.edit.title')}
    >
      <SimpleForm redirect={false} toolbar={<></>}>
        <EditFormAutoSave />
        <TextInput source="firstName"/>
        <TextInput source="lastName" />
        <MerchantUserEmailEdit />
        <ReferenceManyToManyField
          addLabel
          label={
            <Typography color="textPrimary" variant="subtitle2">
              {useTranslate()(
                'resources.merchant_users.customFields.myFlinkitNumbers.label'
              )}
            </Typography>
          }
          reference="channels"
          sort={{field: "channel.whatsappAccount.phoneNumber", order: "DESC"}}
          through="channel_merchant_users"
          using="merchantUserId,channelId"
        >
          <SingleFieldList className={styles.phoneNumbers} linkType={false}>
            <FunctionField<Channels> render={r =>
              <TextField
                // Shrink the input label when the initial value of r is nullish.
                // See: https://github.com/mui/material-ui/issues/13013
                InputLabelProps={{shrink: !!r?.name}}
                disabled
                label={(r?.name !== r?.whatsappAccount?.phoneNumber) ? r?.name : ''}
                margin="dense"
                value={r?.whatsappAccount?.phoneNumber}
                variant="filled"
              />
            }
            />
          </SingleFieldList>
        </ReferenceManyToManyField>
        <LanguageSwitcher />
        <Typography
          className={styles.emailNotificationPreferenceOptionsTitle}
          color="textPrimary"
          variant="subtitle2"
        >
          {useTranslate()('resources.merchant_users.emailNotification.title')}
        </Typography>
        <EmailNotificationPreferenceOptions />
      </SimpleForm>
    </ExtendedEdit>
  )
}

const MerchantUserEmailEdit = () => {
  const notify = useNotify()
  const styles = useStyles()
  const translate = useTranslate()
  const {
    merchantUser: {emailAddress: currentEmailAddress = ''} = {},
    refetch,
  } = useSessionMerchantUser()
  const [updateMerchantUserEmailAddress, {loading}] = useMutation(
    UPDATE_MERCHANT_USER_EMAIL_ADDRESS_MUTATION,
    {
      onCompleted: () => notify('ra.notification.updated', 'info'),
      onError(e) {
        const errorCode = actionErrorCode(e)
        notify(
          `resources.merchant_users.errorMessages.${errorCode}`,
          'error',
          {_: translate('ra.message.error')}
        )
      },
    }
  )
  const save = useCallback(async ({emailAddress}) => {
    if (currentEmailAddress === emailAddress) return
    await updateMerchantUserEmailAddress({variables: {newEmailAddress: emailAddress}})
    await refetch()
  }, [currentEmailAddress, updateMerchantUserEmailAddress, refetch])
  return (
    <Create
      basePath="/merchant_users"
      className={styles.updateMerchantUserEmailAddressForm}
      resource="merchant_users"
    >
      <SimpleForm
        initialValues={{emailAddress: currentEmailAddress}}
        redirect={false}
        save={save}
        toolbar={(
          <MerchantUserEmailAddressSaveButton
            currentEmailAddress={currentEmailAddress}
            disabled={loading}
          />
        )}
      >
        <Typography color="textPrimary" variant="subtitle2">
          {translate('dialogs.profile.merchantUsersSettings.updateEmailAddress.title')}
        </Typography>
        <TextInput
          parse={v => v?.toLowerCase()}
          source="emailAddress"
          validate={[
            regex(
              EMAIL_ADDRESS_REGEX,
              translate('resources.merchant_users.errorMessages.invalidEmailAddress')
            ),
            required(),
          ]}
        />
      </SimpleForm>
    </Create>
  )
}

const MerchantUserEmailAddressSaveButton = ({
  currentEmailAddress = '', disabled = false, ...props
} = {}) => {
  const {values: {emailAddress}} = useFormState({subscription: {values: true}})
  return (
    <SaveButton
      disabled={disabled || (emailAddress === currentEmailAddress)}
      variant="outlined"
      {...props}
    />
  )
}

const LanguageSwitcher = () => {
  const styles = useStyles()
  const locale = useLocale()
  const setLocale = useSetLocale()
  const translate = useTranslate()
  return <Box marginTop="1rem">
    <Typography color="textPrimary" variant="subtitle2">
      {translate('resources.merchant_users.fields.language')}
    </Typography>
    <SelectInput
      choices={
        sortBy(
          SUPPORTED_I18N_LANGUAGES
            .map(l => ({id: l, label: translate(`languages.${l}`)})),
          'label',
        )
      }
      className={styles.languageSwitcherSelectInput}
      defaultValue={locale}
      label={translate('resources.merchant_users.chooseLanguage')}
      onChange={e => setLocale(e.target.value)}
      optionText={({id: language, label}) => (
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography>{label}</Typography>
          {locale === language && <Typography className={styles.checkMark}>✓</Typography>}
        </Box>
      )}
      source="language"
      variant="filled"
    />
  </Box>
}

const EmailNotificationPreferenceOptions = () => {
  const styles = useStyles()
  const choices = [
    {
      description: useTranslate()(
        'resources.merchant_users.emailNotification.choices.reminders.description'
      ),
      id: 'REMINDERS',
      label: useTranslate()(
        'resources.merchant_users.emailNotification.choices.reminders.label'
      ),
    },
    {
      description: useTranslate()(
        'resources.merchant_users.emailNotification.choices.instant.description'
      ),
      id: 'INSTANT',
      label: useTranslate()(
        'resources.merchant_users.emailNotification.choices.instant.label'
      ),
    },
    {
      description: useTranslate()(
        'resources.merchant_users.emailNotification.choices.none.description'
      ),
      id: 'NONE',
      label: useTranslate()(
        'resources.merchant_users.emailNotification.choices.none.label'
      ),
    },
  ]
  return (
    <Paper className={styles.emailNotificationPreferenceOptionsPaper} elevation={2}>
      <RadioButtonGroupInput
        choices={choices.map(choice => ({
          id: choice.id,
          name: (<>
            <Typography variant="body1">{choice.label}</Typography>
            <Typography color="textSecondary" variant="caption">
              {choice.description}
            </Typography>
          </>),
        }))}
        label={false}
        options={{className: styles.emailNotificationPreferenceOptions}}
        row={false}
        source="emailNotificationPreference"
      />
    </Paper>
  )
}

const UPDATE_MERCHANT_USER_EMAIL_ADDRESS_MUTATION = gql`
  mutation($newEmailAddress: String!){
    updateMerchantUserEmailAddress(newEmailAddress: $newEmailAddress)
  }
`

const RESTORE_DELETED_MERCHANT_USER_MUTATION = gql`
  mutation($emailAddress: String!, $firstName: String!, $lastName: String!){
    restoreMerchantUser(
      emailAddress: $emailAddress
      firstName: $firstName
      lastName: $lastName
    ){isRestored}
  }
`

const useStyles = makeStyles(theme => ({
  checkMark: {},
  contactFilterAll: {
    border: `1.5px solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
  },
  emailNotificationPreferenceOptions: {
    '& .Mui-checked': {
      color: theme.palette.info.main,
    },
    gap: theme.remSpacing(2),
  },
  emailNotificationPreferenceOptionsPaper: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    padding: `0 ${theme.remSpacing(2)}`,
    width: theme.remSpacing(48),
  },
  emailNotificationPreferenceOptionsTitle: {
    padding: `${theme.remSpacing(2)} 0`,
  },
  languageSwitcherSelectInput: {
    // Hide the check mark on the input field itself so that its only displayed
    // on the popup list.
    '& $checkMark': {
      display: 'none',
    },
  },
  merchantUserCreateRoot: {
    '& .MuiCardContent-root': {
      paddingTop: 0,
    },
    '& > div': {
      marginTop: 0,
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
  phoneNumbers: {
    display: 'grid',
  },
  updateMerchantUserEmailAddressForm: {
    maxWidth: '100%',
    padding: 0,
  },
}))

export {
  MerchantUserCreate,
  MerchantUserEdit,
}
