import {useMutation} from '@apollo/client'
import {DialogContentText, Fab} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import gql from 'graphql-tag'
import {FC, useState} from 'react'
import {useRedirect, useTranslate} from 'react-admin'

import {EditFilledIcon} from '../../components/icons'
import {MutationRoot, MutationRootStartChatArgs} from '../../types/graphqlSchema'
import {EventCategory, trackEvent} from '../../utils/tracking'
import StartExternalChatDialog from './StartExternalChatDialog'


const StartNewChatButton: FC<StartNewChatButtonProps> = ({
  channelId, disabled = false,
}) => {
  const styles = useStyles()
  const redirect = useRedirect()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [startChat, {loading: isLoadingChat}] = useMutation<
    MutationRoot['startChat'], MutationRootStartChatArgs
  >(
    START_CHAT_MUTATION,
    {
      onCompleted: ({startChat: {chatId} = {}}) => {
        setIsDialogOpen(false)
        redirect(`/inbox/${channelId}/${chatId}`)
      },
    }
  )
  return (
    <>
      <Fab
        className={styles.root}
        color="primary"
        disabled={disabled}
        onClick={
          () => {
            setIsDialogOpen(true)
            trackEvent('START_CHAT', EventCategory.CHAT, 'START_CHAT_BUTTON')
          }
        }
      >
        <EditFilledIcon />
      </Fab>
      <StartExternalChatDialog
        actionButtonText={useTranslate()('actions.open')}
        channelId={channelId}
        loading={isLoadingChat}
        onClose={() => setIsDialogOpen(false)}
        onStartChat={startChat}
        open={isDialogOpen}
        title={useTranslate()('dialogs.startChat.external.subtitle')}
      >
        <DialogContentText>
          {useTranslate()('dialogs.startChat.external.subtitle')}
        </DialogContentText>
      </StartExternalChatDialog>
    </>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiButton-label': {
      alignItems: 'center',
    },
  },
}))

const START_CHAT_MUTATION = gql`
  mutation($chat: Chat!){startChat(chat: $chat){chatId}}
`

interface StartNewChatButtonProps {
  channelId: string
  disabled?: boolean
}

export default StartNewChatButton
