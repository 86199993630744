import React, {FC, ReactNode} from 'react'
import {SimpleForm, SimpleFormProps} from 'react-admin'

/* Extends `SimpleForm` with additional features. Currently there is just one:
 *   - `disabled`: its value will be propagated into all contained form fields, unless
 *     a form field has its own `disabled` property already defined
 */
const ExtendedSimpleForm: FC<ExtendedSimpleFormProps> = ({
  children, disabled, ...props
}) => (
  <SimpleForm {...props}>
    {React.Children.map(children, ((c: any) =>
      c && React.cloneElement(c, c.props.hasOwnProperty('disabled') ? {} : {disabled})
    ))}
  </SimpleForm>
)

interface ExtendedSimpleFormProps extends SimpleFormProps {
  children: ReactNode,
  disabled?: boolean
}

export default ExtendedSimpleForm
