import {CircularProgress, IconButton} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {FC, MouseEventHandler} from 'react'

import {SendIcon} from '../../icons'

const SendButton: FC<SendButtonProps> = ({className, disabled, loading, onClick}) => {
  const styles = useStyles()
  return (
    <IconButton
      aria-label="add"
      className={`${styles.root} ${className ?? ''}`}
      color="primary"
      disabled={disabled}
      onClick={onClick}
    >
      <SendIcon color={disabled ? 'primary' : 'info'} size="large" />
      {loading && (
        <CircularProgress className={styles.progress} color="inherit" size={20} />
      )}
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
  },
  root: {
    '&:not(.Mui-disabled) $progress': {
      color: theme.palette.secondary.dark,
    },
  },
}))

interface SendButtonProps {
  className?: string
  disabled?: boolean
  loading?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export default SendButton
