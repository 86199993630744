import {useLocation} from 'react-router-dom'

// Returns the query params (location.search) as a regular js object which allows for
// easy destructuring, such as: `const {someParam} = useQueryQueryParameters()` given
// that there is a query parameter which goes by the name `someParam`.
// Be aware that "false" or "true" parameter values are not yet automatically converted
// to their boolean type, they are handed out like everything else as string values.
const useQueryParameters = () =>
  Object.fromEntries(new URLSearchParams(useLocation().search))

export default useQueryParameters
